import { stringify } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { VirtualTimeScheduler } from 'rxjs';
import { ClientApiService } from 'src/app/shared/API-Service/client-api.service';
import { ClientTypeApiService } from 'src/app/shared/API-Service/client-type-api.service';
import { GovernorateApiService } from 'src/app/shared/API-Service/governorate-api.service';
import { getCities } from 'src/app/shared/Models/getCities';
import { GetClient } from 'src/app/shared/Models/GetClient';
import { GetCompanyType } from 'src/app/shared/Models/GetClientType';
import { GetGovernorate } from 'src/app/shared/Models/GetGovernorate';
import { InsertCities } from 'src/app/shared/Models/InsertCities';
import { InsertClient } from 'src/app/shared/Models/InsertClient';
import { Roles } from 'src/app/shared/Models/Roles';
import Swal from 'sweetalert2';
import { CitiesApiService } from '../../../../../shared/API-Service/cities-api.service';
import { environment } from 'src/environments/environment.prod';
import { Color } from 'ngx-color';


@Component({
  selector: 'app-InsertClient',
  templateUrl: './InsertClient.component.html',
  styleUrls: ['./InsertClient.component.css']
})
export class InsertClientComponent implements OnInit {
  selectedColor: Color = { hex: '#ffffff' ,
  rgb: {a:3, r: 255, g: 255, b: 255 },
    hsl: {a:3, h: 0, s: 0, l: 100 },
    hsv: {a:3, h: 0, s: 0, v: 100 },
    oldHue: 0,
    source: null};
  //#region Decalre varaibles
  InsertForm: FormGroup;
  maxDate: Date;
  update: boolean;
  validated: boolean;
  Governorate_Dictionary: { [Id: number]: string } = {}
  Governorate_List: GetGovernorate[];
  Cities_List: getCities[];
  ClientType_List: GetCompanyType[];
  Govern_id: number;
  clientType_id: number;
  Governorate: string;
  City: string;
  clientType: string;
  imgURL: any;
  imagePath: any;
  message: string;
  cities_List: getCities[];
  Filtered_cities_List: getCities[];
  Client_Type_List: GetCompanyType[];
  logoForm = new FormData();
  pass: string;
  companyInfo = new InsertClient();
  GovernoratId: any;
  governoratId: number;
  ShowColor: boolean = false;
  Clientid: any;
  response!: { data: any, success: any, message: any };
  //#endregion
  form = {
    name: '',
    username: '',
    PasswordHash: '',
    mobile: '',
    address: '',
    email: '',
    GovernoratId: '',
    cityId: '',
    companyTypeId: '',
    companyImg: '',
    ShortName: '',
    CompanyIcon: '',
    Color: ''
  };
  id: any;
  imgIconURL: string | ArrayBuffer;
  imageIconPath: any;
  ChooseIcon: boolean;
  ChooseImage: boolean;
  //#region  constructor
  constructor(private _formBuilder: FormBuilder,
    private toaster: ToastrService,
    private citiesApiService: CitiesApiService,
    private governorateApiService: GovernorateApiService,
    private ApiService: ClientApiService,
    private clientTypeApiService: ClientTypeApiService,
    private router: Router,

    private route: ActivatedRoute) {
      this.maxDate = new Date()
    this._InitForm();
    const param = this.router.getCurrentNavigation().extras.state;
    if (param) {

      this.Clientid = param.Clientid
      this.ApiService.GetClientById(this.Clientid).subscribe(response => {

        let result = response;

        this.ApiService.Client = result["data"];
        this.companyInfo = result["data"];

        this.GetCities(this.companyInfo.governoratId);
        this.InitForm(result["data"])

      })
      this.update = true;
    }
    else {
      this.update = false;
      this._InitForm();

    }


  }
  //#endregion
  containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }
  changeComplete(e) {
    this.companyInfo.color = e.color.hex;
    
  }

  //#region  ng OnInit
  ngOnInit(): void {


    this.getGovernoate();

    this.getClientType();



  }
  //#endregion

  //#region  Init Form
  InitForm(client: GetClient) {
    this.Governorate = client.governoratName;
    this.City = client.cityName;
    this.clientType = client.companyTypeName;
    this.governoratId = client.governoratId;
    this.id = client.id
    this.imgURL = environment.Server_URL_Image + client.logoPath
    this.imgIconURL = environment.Server_URL_Image + client.icon
    this.InsertForm = this._formBuilder.group({
      name: [client.name || '', Validators.required],
      username: [client.userName || '', Validators.required],
      PasswordHash: [client.PasswordHash || ''],
      mobile: [client.mobile || '', Validators.required],
      address: [client.address || '', Validators.required],
      // email:[client.Email, Validators.email],
      email: [client.email || '', Validators.required],
      GovernoratId: [client.governoratId || '', Validators.required],
      cityId: [client.cityId || '', Validators.required],
      companyTypeId: [client.companyTypeId || '', Validators.required],
      companyImg: [''],
      CompanyIcon: [''],
      ShortName: [client.shortName || '', Validators.required],
      color: [client.color || ''],
      smsSender:[client.smsSender||'']
    });
    this.InsertForm.patchValue({

      GovernoratId: client.governoratId
    })
  }
  _InitForm() {
    this.InsertForm = this._formBuilder.group({
      name: ['', Validators.required],
      username: ['', Validators.required],
      email: [''],
      PasswordHash: ['', Validators.required],
      mobile: ['', Validators.required],
      address: ['', Validators.required],
      GovernoratId: [''],
      cityId: [''],
      companyTypeId: [''],
      companyImg: [''],
      CompanyIcon: [''],
      ShortName: [''],
      color: [''],
      smsSender:['']
    });
    // this.imgURL = "./assets/images/statics/personAvatar.png";
    // console.log("imgURL : ",this.imgURL);

  }
  //#endregion
  get fc() {
    return this.InsertForm.controls;
  }
  //#region  Insert Client Method
  InsertClient() {
   
    if (this.InsertForm.status == "VALID") {
     
      this.AppendFormData();

      this.ApiService.InsertClient(this.logoForm).
        subscribe(

          (res: any) => {
            debugger
            this.response = res;
            if (this.response.success) {


              Swal.fire({
                icon: 'success',
                title: "تم إدخال العميل بنجاح",
                showConfirmButton: false,
                timer: 1500
              })
              this.router.navigateByUrl("content/admin/GetClient");
            }
            else {
              alert(this.response.message)
            }
          },
          (err) => {
          
            alert(err.error.message)
          }
        );


    }
     else {
      if(!this.ChooseImage ){
        Swal.fire({
          icon: 'warning',
          title: "من فضلك اختار صوره البروفايل",
        })
      }
      if(!this.ChooseIcon){
        Swal.fire({
          icon: 'warning',
          title: "من فضلك اختار لوجو",
        })
      }
      this.fc.GovernoratId.markAsTouched();

      this.InsertForm.markAllAsTouched();
    }
    
    // if (this.containsSpecialChars(this.InsertForm.get("name").value) == true) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'خطأ',
    //     text: "لا يمكن كتابه علامات ",
    //   })
    // }
    // else if (this.InsertForm.get('cityId').value == null) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'خطأ',
    //     text: "أختر المدينة أولا",
    //   })
    // }
    // else if (this.InsertForm.get('companyTypeId').value == null) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'خطأ',
    //     text: "أختر نوع العميل أولا",
    //   })
    // }
    // else if (!this.InsertForm.get('companyImg').value) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'خطأ',
    //     text: "أختر الصوره أولا",
    //   })
    // }

    // else {

    // }

  }
  //#endregion
  UpdateClient() {
    this.InsertForm.controls['companyImg'].setErrors(null);
    this.InsertForm.controls['CompanyIcon'].setErrors(null);
    if (this.InsertForm.status == "VALID") {
      let id = this.route.snapshot.paramMap.get('id');

      this.AppendFormData();

      if (!this.logoForm.has("companyImg"))
        this.logoForm.append("companyImg", null)
      this.ApiService.UpdateClient(this.logoForm).subscribe(
        (res: any) => {

          this.response = res;
          if (this.response.success) {


            Swal.fire({
              icon: 'success',
              title: "تم تعديل العميل بنجاح",
              showConfirmButton: false,
              timer: 1500
            })
            this.router.navigateByUrl("content/admin/GetClient");
          }
          else {
            alert(this.response.message)
          }
        },
        (err) => {
     
          alert(err.error.message)
        }
      );

      

    }
     else {
      // if(!this.ChooseImage){
      //   Swal.fire({
      //     icon: 'warning',
      //     title: "من فضلك اختار صوره البروفايل",
      //   })
      // }
      // if(!this.ChooseIcon){
      //   Swal.fire({
      //     icon: 'warning',
      //     title: "من فضلك اختار لوجو",
      //   })
      // }
      this.fc.GovernoratId.markAsTouched();
      this.InsertForm.markAllAsTouched();
    }
   

  }
  // region AppendFormData
  AppendFormData() {
    Object.keys(this.companyInfo).forEach((key) => {
      if (typeof this.companyInfo[key] !== "object") {
        this.logoForm.set(key, this.companyInfo[key]);

      }
      else {
        if (this.companyInfo[key] != null) {
          Object.keys(this.companyInfo[key]).forEach((subkey) => {
            this.logoForm.set(key, this.companyInfo[key][subkey]);
          });
        }

      }

    });

    this.logoForm.append("Role", Roles.Client)
  }
  //endregion

  //#region Update Client
 
  //#endregion

  //#region Selected Governorate
  SelectedGovernorate(event: any) {
this.InsertForm.get('cityId').reset();

    if (event != "null")
      this.GetCities(this.companyInfo.governoratId)
    else
      this.cities_List = null
  }
  //#endregion

  //#region  Selected City
  SelectedCity(event: any) {
    this.InsertForm.patchValue({
      cityId: +event.target.value
    })
  }
  //#endregion






  //#region  get Governoate
  getGovernoate() {
    this.governorateApiService.GetGovernorate().subscribe(
      response => {
        this.Governorate_List = response.data;


      }
    )
  }
  //#endregion

  //#region Get Cities
  GetCities(GovernoateId) {
    this.citiesApiService.GetCitiesWithGovernorate(GovernoateId).subscribe(
      response => {

        this.cities_List = response.data;


      }


    )
  }
  //#endregion

  //#region  Get Client Types
  getClientType() {
    this.clientTypeApiService.GetClientType().subscribe(
      response => {
        // this.response = response;
        this.Client_Type_List = response.data;

      }
    )
  }
  //#endregion

  //#region Deal With Image
  preview(files: any) {
    this.ChooseImage=true;
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
    this.logoForm.set("companyImg", files[0])
  }
  previewIcon(files: any) {
    this.ChooseIcon=true;
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader2 = new FileReader();
    this.imageIconPath = files;
    reader2.readAsDataURL(files[0]);
    reader2.onload = (_event) => {
      this.imgIconURL = reader2.result;
    }
    this.logoForm.set("CompanyIcon", files[0])
  }
  //#endregion

  onSubmit() {



    this.validated = false;


    if (this.update == true) {
      this.UpdateClient();
    } else {
      this.InsertClient();
    }

  }

}
