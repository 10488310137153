import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { GovernorateApiService } from '../../../../../shared/API-Service/governorate-api.service';
import { InsertGovernorate,UpdateGovernorate } from '../../../../../shared//Models/InsertGovernorate';


@Component({
  selector: 'app-insert-governorate',
  templateUrl: './insert-Governorate.component.html',
  styleUrls: ['./insert-Governorate.component.css']
})
export class GovernorateComponent implements OnInit {

  //#region Decalre varaibles
  InsertForm: FormGroup;
  maxDate: Date;
  update:boolean;
  validated:boolean;
  response: any[]=[];
  Response_List: any[]=[]
  //#endregion

  //#region  constructor
  constructor(private _formBuilder: FormBuilder,
              private toaster: ToastrService,
              private governorateApiService:GovernorateApiService,
              private router:Router,
              private route: ActivatedRoute) 
  { this.maxDate = new Date(); }
  //#endregion
  // containsNumber(str) {
  //   const specialChars = /[/\d/٠١٢٣٤٥٦٧٨٩]/;
  //   return specialChars.test(str);
  // }
  //#endregion
  // containsSpecialChars(str) {
  //   const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  //   return specialChars.test(str);
  // }
  //#region  ng OnInit
  ngOnInit(): void {
   this.getGovernoate();
    if(this.route.snapshot.paramMap.get('id')){
      this.governorateApiService.title = localStorage.getItem("riskgovernorate");
      this.InitForm(this.governorateApiService.title)
      this.update = true;
    }else
    {
      this.update = false;
      
      this._InitForm();
    }
  }
  //#endregion
  getGovernoate() {
    this.governorateApiService.GetGovernorate().subscribe(
      response => {
     //   this.response = response;
        this.Response_List = response.data;
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }
  //#region  Init Form
  InitForm(title:string){
    this.InsertForm = this._formBuilder.group({
      Title: [title, Validators.required],
    });
  }
  _InitForm(){
    this.InsertForm = this._formBuilder.group({
      Title: ['', Validators.required],
    });
  }
  //#endregion
 
  //#region  Insert Client-Type Method
  InsertGovernorate(){     
  const governorate=  this.Response_List.filter(x=>x.title==this.InsertForm.get('Title').value) ;
  if(governorate.length!=0){
    Swal.fire({
      icon: 'error',
      title: 'خطأ',
      text: "المحافظة مضافه مسبقا",
    })
  } 
  else{
    this.governorateApiService.InsertGovernorate({Title:this.InsertForm.get('Title').value} as InsertGovernorate  ).subscribe(
      response=>{
        Swal.fire({
          icon: 'success',
          title: "تم إدخال المحافظة بنجاح",
          showConfirmButton: false,
          timer: 1500
        })
        this.router.navigateByUrl("content/admin/Get-governorate");
      },
      err=>{
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }
    
  }
  //#endregion

  //#region Update Client
  UpdateGovernorate(){
    let id = +this.route.snapshot.paramMap.get('id');
    this.governorateApiService.UpdateGovernorate({Id: id ,Title:this.InsertForm.get('Title').value} as UpdateGovernorate).subscribe(
      response=>{
        Swal.fire({
          icon: 'success',
          title: "تم تعديل المحافظة بنجاح",
          showConfirmButton: false,
          timer: 1500
        })
        this.router.navigateByUrl("content/admin/Get-governorate");
        localStorage.removeItem("riskgovernorate");
      },
      err=>{
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }
  //#endregion

 
onSubmit() {
  // this.validated = !this.validated
  // this.validated = !this.validated
  // if(this.containsSpecialChars(this.InsertForm.get("Title").value)==true){
  //   Swal.fire({
  //     icon: 'error',
  //     title: 'خطأ',
  //     text: "لا يمكن كتابه علامات ",
  //   })
  // }
  // else if (this.containsNumber(this.InsertForm.get("Title").value)==true){
  //   Swal.fire({
  //     icon: 'error',
  //     title: 'خطأ',
  //     text: "لا يمكن كتابه ارقام ",
  //   })
  // }
// else 
 if (this.InsertForm.status == "VALID") {
    this.validated = false;

    if (this.update == true) {
      this.UpdateGovernorate();
    } else {
      this.InsertGovernorate();
    }
  } else {
    this.validated = true;
  }
}
}
