import { Customer } from '../Models/Customer';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
import { environment } from '../../../environments/environment.prod';


@Injectable({
  providedIn: 'root'
})

export class CustomerApiService {

    //#region Declare variables
    title:string;
    CustomerData:any;
    mobile:string;
    //#endregion
    
    //#region  constructor
    constructor(private http:HttpClient) { }
    //#endregion
  
      //#region Options
      httpOptionsWithTocken = { headers: new HttpHeaders({ 'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhaG1lZGFiZHVsbGFoMjUwIiwianRpIjoiZDIwZjU0MGUtMjhiNy00YmNjLWE4ZDgtNzkxNzA2YzJmZDRhIiwiZW1haWwiOiJhaG1lZGFiZHVsbGFoQHlhaG9vLmNvbSIsInVpZCI6IjBiMzg5N2FiLTQ2ZmMtNGM0Yy04MTYyLTRiNDExZTY4OWE1NCIsInJvbGVzIjoiVVNFUiIsImV4cCI6MTYzODM2OTM3NSwiaXNzIjoiU2VjdXJlQXBpIiwiYXVkIjoiU2VjdXJlQXBpVXNlciJ9.55LorE6Fclj3buy1Qw8wZ6CEe_ifW5jxwHe25wHRWsQ', 'Accept': ' */*' }) };
      //#endregion
  
      GetCustomerByphone(phone:string): Observable<GenericResponseSingle<any>> {
        return this.http.get<GenericResponseSingle<any>>(`${environment.Server_URL}/Customer/GetByMobile?mobile=${phone}`);
      }
      GetByMobilePagedCalls(data:any): Observable<GenericResponseSingle<any>> {
        return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/Customer/GetByMobilePagedCalls`,data);
      }
      GetByIdCustomer(id:string): Observable<GenericResponseSingle<any>> {
        return this.http.get<GenericResponseSingle<any>>(`${environment.Server_URL}/Customer/GetById?id=${id}`);
      }
      GetCustomerPhones(id:string): Observable<GenericResponseSingle<any>> {
        return this.http.get<GenericResponseSingle<any>>(`${environment.Server_URL}/Customer/GetCustomerPhones?id=${id}`);
      }
      GetCustomerDonations(customerId:string , donationMainType:string): Observable<GenericResponseSingle<any>> {
        return this.http.get<GenericResponseSingle<any>>(`${environment.Server_URL}/Customer/GetCustomerDonations?customerId=${customerId}&&donationMainType=${donationMainType}`);
      }
      GetCustomerDonationsList(data:any): Observable<GenericResponseSingle<any>> {
        return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/Customer/GetCustomerDonationsList`,data);
      }

      GetDonationsHasNoCollector(data:any): Observable<GenericResponseSingle<any>> {
        return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/Donation/GetDonationsHasNoCollector`,data);
      }
      //   InsertCustomer(Data:any): Observable<GenericResponseSingle<Customer>> {
      //   return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/Customer/Create/Try`,Data);
      // }
      InsertCustomer(Data:any): Observable<GenericResponseSingle<Customer>> {
        return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/Customer/Create`,Data);
      }
      UpdateCustomer(data:any): Observable<GenericResponseSingle<Customer>> {
        return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/Customer/Update`,data);
      }

      InsertCustomerPhones(Data:any): Observable<GenericResponseSingle<any>> {
        return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/Customer/CustomerPhones`,Data);
      }

    Getcustomer(){
     return this.CustomerData = localStorage.getItem('Risk_Customer_Data')
    }


}
