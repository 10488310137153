import { SectorLocationApiService } from 'src/app/shared/API-Service/sector-location-api.service';
import { SectorTypeApiService } from './../../../../shared/API-Service/sector-type-api.service';
import { pieChart } from './../../../../shared/data/charts/ngx-charts';
import { FormGroup, FormControl } from '@angular/forms';
import { SourceOfmarketing } from '../../../../shared//Models/source-ofmarketing';
import { SourceOfmarketingService } from './../../../../shared/API-Service/source-ofmarketing.service';
import { SourceMarketApiService } from './../../../../shared/API-Service/source-market-api.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';
import Swal from 'sweetalert2';
import { error } from '@angular/compiler/src/util';
import { times } from 'chartist';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-sourceofmarketing',
  templateUrl: './sourceofmarketing.component.html',
  styleUrls: ['./sourceofmarketing.component.css']
})
export class SourceofmarketingComponent implements OnInit {
  GetLookupsGetDonation
  DonationType
  SectorGet : any[]=[]
  Governorate_Dictionary:{[Id:number]:string} = {}
  getType:any = []
  ReportsInfo = new SourceOfmarketing()
  SectorLocation:any = []
  getsourcemarket
  currentUser: any;
  Companies: any[];
  CompanyValid: boolean;
  
  constructor(public datepipe: DatePipe,private CompanyDataService:CompanyDataService,private SourceOfmarketingService:SourceOfmarketingService,   private router: Router,private SectorTypeApiService:SectorTypeApiService,
    private SectorLocationApiService:SectorLocationApiService) {
      this.currentUser=JSON.parse(localStorage.getItem("RiskCurrentUser"))
    //  this.currentUser.roleId=10
      if(this.currentUser.roleId==10){
        this.ReportsInfo.companyId=this.currentUser.id
        this.GetSector(this.currentUser.id)
      }
      this.ReportsInfo.reportCreatedBy=this.currentUser.name;
      const date = new Date();
      this.ReportsInfo.creationDateFrom=this.datepipe.transform(date, 'yyyy-MM-dd');
      this.ReportsInfo.creationDateTo=this.datepipe.transform(date,'yyyy-MM-dd')
     }

  ngOnInit(): void {
    this.GetLookupsGetDonationStatu()

    this.GetDonationType()
    this.GetCompanies()
    this.getsourcemarketing()
    
  }

  GetLookupsGetDonationStatu(){
    this.SourceOfmarketingService.GetLookupsGetDonationStatu().subscribe(response=>{
     
      this.GetLookupsGetDonation = response.data
    })
  }


  GetDonationType(){
    this.SourceOfmarketingService.GetDonationType().subscribe(response=>{
        this.DonationType = response.data
    
    })
  }

  GetSector(companyId:any){
     this.SourceOfmarketingService.getSectorsBycompanyId(companyId).subscribe(res=>{
      res.data.push({id:0,title:'All'})

      this.SectorGet=res.data ;
      

      // res.data.forEach(element => {
      //   this.Governorate_Dictionary[element.id] = element.title;            
      // });
     })

  }

  GetSectorGetById(event:any){
 if(this.ReportsInfo.sectorId==null){
      this.ReportsInfo.sectorTypeId=null
     this.ReportsInfo.sectorTypeName=''
     this.ReportsInfo.sectorLocationId=null
     this.ReportsInfo.sectorLocationName=''
     this.getType=[];
     this.SectorLocation=[];
 }
  
 else{
   this.SectorTypeApiService.getSectorType(this.ReportsInfo.sectorId,this.ReportsInfo.companyId).subscribe((response: any)=>{
    
      this.getType = response.data
  
    })

    this.ReportsInfo.sectorId =parseInt(event)
    this.ReportsInfo.sectorName = this.SectorGet.find(x=>x.id == event).title
 }
    
  }

  GetSectorTypeGetById(event:any){
    if(this.ReportsInfo.sectorTypeId==null){
       this.ReportsInfo.sectorLocationId=null
      this.ReportsInfo.sectorLocationName='';
      this.SectorLocation=[];
    }
    else{
 this.SectorLocationApiService.GetSectorLocationReportFilter(this.ReportsInfo.sectorTypeId).subscribe((response:any)=>{
          this.SectorLocation = response.data
    })

    this.ReportsInfo.sectorTypeId =parseInt(event)
    this.ReportsInfo.sectorTypeName = this.getType.find(x=>x.id == event).title

    }
    
  }

  GetSectorLocationGetById(event:any){
    this.ReportsInfo.sectorLocationId =parseInt(event) 
    this.ReportsInfo.sectorLocationName = this.SectorLocation.find(x=>x.id == event).title
  }


  getsourcemarketing(){
    this.SourceOfmarketingService.GetAllsourceOfMarketingForReport().subscribe(response=>{
     
          this.getsourcemarket = response.data
    })
  }

  donationStatus(event:any){
    this.ReportsInfo.donationStatus =parseInt(event) 
    this.ReportsInfo.donationStatusName = this.GetLookupsGetDonation.find(x=>x.id == event).title
  }
  donationTypeid(event:any){
    this.ReportsInfo.donationTypeId =parseInt(event) 
    this.ReportsInfo.donationTypeName = this.DonationType.find(x=>x.id == event).title
  }

  GetAnnouncementSourceGetById(event:any){
    this.ReportsInfo.sourceMarketId =parseInt(event) 
    this.ReportsInfo.sourceMarketName = this.getsourcemarket.find(x=>x.id == event).title
  }
  SelectCompany(CompanyId){
    this.ReportsInfo.companyId=parseInt(CompanyId);
    this.SectorGet =[];
    this.ReportsInfo.sectorId=null;
    this.ReportsInfo.sectorTypeId=null;
    this.ReportsInfo.sectorLocationId=null;
     this.GetSector(CompanyId);
  }
  GetCompanies(){
    this.CompanyDataService.GetCompanies().subscribe(res=>{
      this.Companies=res.data
    })
  }
 
  Viewreports(){
    if(this.ReportsInfo.companyId!=null&& ((this.ReportsInfo.creationDateFrom  &&this.ReportsInfo.creationDateTo) || (this.ReportsInfo.collectionDateFrom  &&this.ReportsInfo.collectionDateTo))){
      if (this.ReportsInfo.sectorId ==null) {
        this.ReportsInfo.sectorId=null
        this.ReportsInfo.sectorName=''
      } 
  
      if(this.ReportsInfo.sectorTypeId == null){
        this.ReportsInfo.sectorTypeId=null
        this.ReportsInfo.sectorTypeName=''
    
      }
  
      if(this.ReportsInfo.sectorLocationId == null){
        this.ReportsInfo.sectorLocationId=null
        this.ReportsInfo.sectorLocationName=''
      }
  
      if (this.ReportsInfo.donationStatus==null) {
        this.ReportsInfo.donationStatus=null
        this.ReportsInfo.donationStatusName=''
      }
  
      if (this.ReportsInfo.donationTypeId ==null) {
        this.ReportsInfo.donationTypeId=null
        this.ReportsInfo.donationTypeName=''
   
      }
  
      if(this.ReportsInfo.sourceMarketId ==null){
        this.ReportsInfo.sourceMarketId=null
        this.ReportsInfo.sourceMarketName=''
       
      }
      localStorage["ReportName"]="SourceMarketDonationsReport";
      localStorage.setItem('ReportFilter',JSON.stringify(this.ReportsInfo));
    
      this.router.navigate([]).then((result) => {
        window.open("/content/admin/call-start-end-report", '_blank');
      });
  
    }
  else{
    this.CompanyValid=true
    
    if(!this.ReportsInfo.companyId){
      Swal.fire({
        icon:'error',
        title:'خطأ',
        text:'يجب اختيار الشركة'
  
      })
    }
  else   if (!this.ReportsInfo.creationDateFrom){
      Swal.fire({
        icon:'error',
        title:'خطأ',
        text:'يجب اختيار التبرعات المنشأة من تاريخ'
  
      })
    }
    else  if (!this.ReportsInfo.creationDateTo ){
      Swal.fire({
        icon:'error',
        title:'خطأ',
        text:'يجب اختيار التبرعات المنشأة الي تاريخ'
  
      })
    }
   
  }

    

   
  }

}
