import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SectorTypeApiService } from 'src/app/shared/API-Service/sector-type-api.service';
import { SectorLocationApiService } from '../../../../../shared/API-Service/sector-location-api.service';
import { GenericResponse } from 'src/app/shared//Models/GenericResponse';
import {getSectorLocation  } from 'src/app/shared//Models/GetSectorLocation';
import { insertSectorLocation,UpdateSectorLocation } from 'src/app/shared//Models/InsertSectorLocation';
import { getSectorType } from 'src/app/shared//Models/get-sector-type';
import{GetSectorLocationInSectorTypes} from 'src/app/shared//Models/GetSectorLocationInSectorTypes'
import Swal from 'sweetalert2';import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { SectorsApiService } from 'src/app/shared/API-Service/sectors-api.service';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';
import { environment } from 'src/environments/environment.prod';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-list-sector-location',
  templateUrl: './list-sector-location.component.html',
  styleUrls: ['./list-sector-location.component.css']
})
export class ListSectorLocationComponent extends BaseComponent implements OnInit {
  response: GetSectorLocationInSectorTypes[];
  Response_List: getSectorLocation[];
  Filtered_List: any;
  Sector_Type_id: number;
  Sector_Type_List: any;
  paginationview: boolean;
  Sector_List: any;
  Companies: any;
  CompanyId: any;
  imgUrlServer=environment.Server_URL_Image+'/'
  SectorId: any;
  @ViewChild('sectorSelect') sectorSelect: NgSelectComponent;
  @ViewChild('sectorTypeSelect') sectorTypeSelect: NgSelectComponent;
  Sector_id:any
  Sectortype_id:any
  
  constructor(private sectorTypeApiService: SectorTypeApiService,
    private sectorLocationApiService: SectorLocationApiService,
    private sectorsApiService: SectorsApiService,
    private router: Router,
    private CompanyDataService :CompanyDataService) {
      super() 
      this.paginationview=true
    }

  ngOnInit(): void {
    this.Response_List = [];
    this.GetCompanies();
    // this.getSectorType();
    this.GetSectorLocation();
  }
  Search(){
    this.pageNumber=1
    this.pager.skipCount=0
    // if(this.Sector_Type_id){
      this.GetSectorLocation();
    // }
    // else{
    //   Swal.fire({
    //     icon: 'warning',
    //     title: 'تنبية',
    //     text: 'يجب اختيار نوع النشاط',
    //   })
    // }
    
  }
  pageChanged(event:any){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.sectorLocationApiService.getSectorLocation(null,this.pager).subscribe(
      response => {
        this.response = response.data;
        // console.log("----- : ",this.response[0].governorate.title);
        this.paginationview=true
        this.Response_List = response.data;
        this.Filtered_List = response.data;
           this.totalCount = this.Filtered_List.totalCount;
           this.Filtered_List=this.Filtered_List.items;
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
    // newLocl._ShipmentService.Get(data).subscribe((res)=>{
    //   this.response=res.data;
    //   this.totalItems = this.response.totalCount;
    //   this.response=this.response.items;
    // })
  }
  GetSectorLocation() {
    this.pager.sectorTypeId=this.Sector_Type_id
    this.pager.companyId=this.CompanyId
 
    this.sectorLocationApiService.getSectorLocation(null,this.pager).subscribe(
      response => {
        this.response = response.data;
        
        this.Response_List = response.data;
        this.Filtered_List = response.data;
        this.totalCount = this.Filtered_List.totalCount;
           this.Filtered_List=this.Filtered_List.items;
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }
  GoToMedia(SectorLocationid){
    this.router.navigate(['content/admin/list-project-accomplishment/',SectorLocationid]);
  }
  SelectCompany(CompanyId){
    this.CompanyId=CompanyId
    this.sectorSelect.clearModel();
    this.Sector_List=null
    this.sectorTypeSelect.clearModel();
    this.Sector_Type_List=null
    if(CompanyId){
      this.getSector(CompanyId);

    }
  }
  GetCompanies(){
    this.CompanyDataService.GetCompanies().subscribe(res=>{
      this.Companies=res.data
    })
  }

  getSector(CompanyId:any) {
    this.sectorsApiService.getSectors(CompanyId).subscribe(
      response => {
        
        this.Sector_List = response.data;
        // response.data.forEach(element => {
        //   this.Governorate_List[element.id] = element.title;
        // });
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }
  SelectedSector(event: any){
   
    this.SectorId=event
    if(event){
      this.getSectorType(event)

    }
  }
  getSectorType(sectorId) {
    this.sectorTypeApiService.getSectorType(sectorId,this.CompanyId,null).subscribe(
      response => {
        
        this.Sector_Type_List = response.data;
        // response.data.forEach(element => {
        //   this.Governorate_List[element.id] = element.title;
        // });
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }
  Delete(id: number) {
    Swal.fire({
      title: ' تحذير !',
      text: "هل انت متأكد من حذف هذا العنصر ؟ ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7364f6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'حذف',
      cancelButtonText: 'إنهاء',
    })
      .then((result) => {

        if (result.isConfirmed) {
          
          this.sectorLocationApiService.DeleteSectorLocation(id).subscribe(
            response => {
              
              Swal.fire({
                icon: 'success',
                title: "تم حذف نوع التبرع بنجاح",
                showConfirmButton: false,
                timer: 1500
              })
             return this.GetSectorLocation();
            },
            err => {
              Swal.fire({
                icon: 'error',
                title: 'خطأ',
                text: err.error.message,
              })
            }
          )

        } else {
          // Swal.fire(
          //   'Your appointment still active ',
          // );
        }
      });
      
}



AddNew() {
this.router.navigateByUrl("content/admin/Insert-Sector-Location");
}
update(sectorLocationId: number, title: string,sectorTypeId: number,employeeId:number) {
this.sectorLocationApiService.title = title;
this.sectorLocationApiService.sectorTypeId = sectorTypeId;
localStorage.setItem("SectorTypetitle",title);
localStorage.setItem("sectorTypeId",JSON.stringify(sectorTypeId));
localStorage.setItem("employeeId",JSON.stringify(employeeId));
// this.router.navigate(['content/admin/Update-Sector-type/${id}']);
this.router.navigate(['content/admin/Update-Sector-Location/',sectorLocationId]);
}
//#endregion

//#region Selected Governorate
// SelectedSectorType(event: any) {
// this.Sector_Type_id = event.target.value;
// if (event.target.value == -1)
//   this.Filtered_List = this.Response_List;
// else
//   this.Filtered_List = this.Response_List.filter(x => x.sectorTypeId == event.target.value);
// }


SelectedSectorType(event: any) {
  
  this.Sector_Type_id = event;

  // if(this.Sector_Type_id !=-1){
  //   this.paginationview=false
  // }
  // else{
  //   this.paginationview=true
  //   this.GetSectorLocation();
  // }
  //     this.sectorLocationApiService.getSectorLocation(this.Sector_Type_id == -1 ? null : this.Sector_Type_id).subscribe(
  //      (res)=>{this.Filtered_List = res.data;
  //    },
      
  //    )
     
   }

}



