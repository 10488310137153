import { GetGovernorate } from "../../../../../shared//Models/GetGovernorate";
import { GetRole } from "../../../../../shared//Models/GetRole";
import { LookupsServiceService } from "./../../../../../shared/API-Service/lookups-service.service";
import { JobApiService } from "src/app/shared/API-Service/job-api.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { EmployeeApiService } from "src/app/shared/API-Service/employee-api.service";
import { GetEmployee } from "src/app/shared/Models/GetEmployee";
import { InsertClientType } from "src/app/shared/Models/insert-client-type";
import { InsertEmployee } from "src/app/shared/Models/InsertEmployee";
import { Roles } from "src/app/shared/Models/Roles";
import Swal from "sweetalert2";
import { GetJob } from "src/app/shared/Models/GetJob";
import { CitiesApiService } from "src/app/shared/API-Service/cities-api.service";
import { GovernorateApiService } from "src/app/shared/API-Service/governorate-api.service";
import { InsertCities } from "src/app/shared/Models/InsertCities";
import { getCities } from "src/app/shared/Models/getCities";
import { ClientTypeApiService } from "src/app/shared/API-Service/client-type-api.service";
import { ClientApiService } from "src/app/shared/API-Service/client-api.service";
import { DatePipe } from "@angular/common";
import { OfficesService } from "src/app/shared/API-Service/offices.service";
import { environment } from "src/environments/environment.prod";
import { NgSelectComponent } from "@ng-select/ng-select";

@Component({
selector: "app-insert-employee",
templateUrl: "./insert-employee.component.html",
styleUrls: ["./insert-employee.component.css"],
})
export class InsertEmployeeComponent implements OnInit {
// imge src view
imgemployeePic: string;
imgResumePic: string;
imgMilitaryCertificatePic: string;
imgGraduationCertificatePic: string;
imgOtherPaperPic: string;

EmployeeForm: FormGroup;
_InsertClientType: InsertClientType;
maxDate: Date;
update: boolean;
validated: boolean;
pass: string;
employeeInfo = new InsertEmployee();
Jobs_List: GetJob[];
Job_Dictionary: { [Id: number]: string } = {};
Job_id: number;

Marital_List: GetJob[];
Marital_Dictionary: { [Id: number]: string } = {};
Marital_id: number;

Military_List: GetJob[];
Military_Dictionary: { [Id: number]: string } = {};
Military_id: number;

Religion_List: GetJob[];
Religion_Dictionary: { [Id: number]: string } = {};
Religion_id: number;

Nationality_List: GetJob[];
Nationality_Dictionary: { [Id: number]: string } = {};
Nationality_id: number;

Governorate_List: GetGovernorate[];
Governorate_Dictionary: { [Id: number]: string } = {};
Governorate_id: number;
Governorate: string;

City_list: getCities[];
Role_List: GetRole[];
Role_Dictionary: { [Id: number]: string } = {};
Role_id: number;
message: string;
imagePath: any;
imgURL: any;
gradimg: any;
////// images files
employeePic: File;
resumePic: File;
militaryCertificatePic: File;
graduationCertificatePic: File;
otherPaperPic: File;
//////////form initialize
data = new FormData();
inactive:boolean;
//#endregion
employeeForm = new FormData();
//client Types
// dropdownList
dropdownSettings: IDropdownSettings = {};
dropdownList: any = [];
selectedItems: any = [];
ClientTypes: any = [];
companyTypeId: any = [];
@ViewChild('citySelect') citySelect: NgSelectComponent;

returnedObjectCompany: any = {};
    date: string;
    Hiringdate: string;
    title: any;
    gender: any[];
    Offices: any[];
    imgUrlServer=environment.Server_URL_Image+'/'
//#region  constructor
constructor(
private _formBuilder: FormBuilder,
private toaster: ToastrService,
private ApiService: EmployeeApiService,
private router: Router,
private route: ActivatedRoute,
private JobApiService: JobApiService,
private citiesApiService: CitiesApiService,
private governorateApiService: GovernorateApiService,
private clientTypeApiService: ClientTypeApiService,
private clientApiService: ClientApiService,
public datepipe: DatePipe,
private OfficesService:OfficesService,
private lookup: LookupsServiceService
) {

    this.inactive=false;
    this.gender=[
        {
        id:1,
        title:"ذكر"
        },
        {
            id:2,
            title:"أنثى"
        }

]
   // this.getClientType();
 //  this.getClientType()


this.maxDate = new Date();
}
GetOffices(){
    this.OfficesService.GetOffices().subscribe(res=>{
        this.Offices=res.data
    })
}
//#endregion
//#region  ng OnInit
async ngOnInit() {  
      this._InitForm();

    await this.getClientData();
this.GetOffices();
this.getJob();
this.getMaritalStatus();
this.getMilitaryStatus();
this.getRole();
this.getReligion();
this.getNationality();
this.getGovernorate();

 //this.getClientType();

if (this.route.snapshot.paramMap.get("id")) {
let id = +this.route.snapshot.paramMap.get("id");
this.getById(id);



this.update = true;
} else {



this.update = false;
this._InitForm();
}

this.dropdownSettings = {
singleSelection: false,
idField: "id",
textField: "name",
selectAllText: "تحديد الكل",
unSelectAllText: "الغاء الكل",
itemsShowLimit: 5,
allowSearchFilter: true,
};
}
//#endregion

//#region  Init Form
InitForm(employee: GetEmployee) {
this.EmployeeForm = this._formBuilder.group({
name: [employee.name, Validators.required],
userName: [employee.userName, Validators.required],
password: ['***', Validators.nullValidator],
nationalId: [employee.nationalId, Validators.required],
mobile: [employee.mobile, Validators.required],
address: [employee.address, Validators.required],
passwordHash: [employee.password, Validators.required],
graduationCertificatePic: [, Validators.nullValidator],
OfficeId:[""],

////
});
}
CityForm(title: string) {
this.EmployeeForm = this._formBuilder.group({
Title: [title, Validators.required],
GovernorateId: ["-1", Validators.nullValidator],
});
}
_CityForm() {
this.EmployeeForm = this._formBuilder.group({
Title: ["", Validators.required],
GovernorateId: ["", Validators.nullValidator],
});
}

_InitForm() {
this.EmployeeForm = this._formBuilder.group({
name: ["", Validators.required],
userName: ["", Validators.required],
password: ["", Validators.nullValidator],
nationalId: ["", Validators.required],
mobile: ["", Validators.required],
address: ["", Validators.required],
roleId: ["", Validators.required],
cityId: ["", Validators.required],
governorateId: ["", Validators.required],
departmentId: [""],
jobId: ["", Validators.required],
hiringDate: ["", Validators.required],
comment: [""],
genderId: ["", Validators.required],
religionId: ["", Validators.required],
maritalStatusId: ["", Validators.required],
militaryStatusId: ["", Validators.required],
birthdate: ["", Validators.required],
nationalityId: ["", Validators.required],
mobile1: [""],
mobile2: [""],
mobile3: [""],
otherData: [""],
passwordHash: [""],
employeePic: [""],
resumePic: [""],
militaryCertificatePic: [""],
graduationCertificatePic: ["", Validators.nullValidator],
otherPaperPic: [""],
OfficeId:[""],
picture: [""],
resumeUrl: [""],
militaryCertificateUrl: [""],
graduationCertificateUrl: [""],
otherPaperUrl: [""],
Companies: [],
});
}
//#endregion

//#region  Insert Client-Type Method
InsertClientType() {

    if(this.selectedItems.length!=0){
        this.selectedItems.forEach((element) => {
            if(element){
              this.ClientTypes.push(element.id);  
            }
            
            });
    }

this.loopform();
this.ApiService.InsertEmployee(this.data).subscribe(
(response) => {
Swal.fire({
icon: "success",
title: "تم إضافة الموظف بنجاح",
showConfirmButton: false,
timer: 1500,
});
this.router.navigateByUrl("content/admin/GetEmployee");
},
(err) => {
Swal.fire({
  icon: 'error',
  title: 'خطأ',
  text: err.error.message,
})
}
);
}
//#endregion

//#region Update Client
UpdateClientType() {
    

let id = this.route.snapshot.paramMap.get("id");
if(this.selectedItems.length!=0){
    this.selectedItems.forEach((element) => {
        if(element){
        this.ClientTypes.push(element.id);
        }
        });
}

this.loopform();
this.ApiService.UpdateEmployee(this.data).subscribe(
(response) => {
Swal.fire({
icon: "success",
title: "تم تعديل بيانات الموظف بنجاح",
showConfirmButton: false,
timer: 1500,
});
this.router.navigateByUrl("content/admin/GetEmployee");
//  localStorage.removeItem("RiskEmployeeData")
},
(err) => {
Swal.fire({
  icon: 'error',
  title: 'خطأ',
  text: err.message,
})
}
);
// }
}
// Job Region
getJob() {
this.JobApiService.GetJob().subscribe(
(response) => {
this.Jobs_List = response.data;
response.data.forEach((element) => {
this.Job_Dictionary[element.id] = element.title;
});
},
(err) => {
    Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: err.error.message,
      })
}
);
}
SelectedJob(event: any) {
this.Job_id = event.target.value; /////////
}
//#endregion

// Governorate and City Region

getGovernorate() {
this.governorateApiService.GetGovernorate().subscribe(
(response) => {
this.Governorate_List = response.data;
response.data.forEach((element) => {
this.Governorate_Dictionary[element.id] = element.title;
});
},
(err) => {
    Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: err.error.message,
      })
}
);
}

SelectedGovernorate(event: any) {
  this.citySelect.clearModel();
  this.City_list=null
  if(event){
 this.GetCityByGovernateId(event)
  }

}
GetCityByGovernateId(GovernateId){
  this.citiesApiService
  .GetCitiesWithGovernorate(GovernateId)
  .subscribe(
  (response) => {
  this.City_list = response.data;
  },
  (err) => {
      Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
  }
  );
}
// EndRegion

// Military Status Region

getMilitaryStatus() {
this.lookup.GetMilitaryStatus().subscribe(
(response) => {
this.Military_List = response.data;
response.data.forEach((element) => {
this.Military_Dictionary[element.id] = element.title;
});
},
(err) => {
    Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: err.error.message,
      })
}
);
}

selectedMilitaryStatus(event: any) {
this.Military_id = event.target.value;
}

// End Region
inactiveChange(){
    if(this.inactive){
      this.inactive=false;
    }
    else{
          this.inactive=true;
    }   
  }
// Marital Status Region

getMaritalStatus() {
this.lookup.GetMaritalStatus().subscribe(
(response) => {
this.Marital_List = response.data;
response.data.forEach((element) => {
this.Marital_Dictionary[element.id] = element.title;
});
},
(err) => {
    Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: err.error.message,
      })
}
);
}
selectedMaritalStatus(event: any) {
this.Marital_id = event.target.value;
}
// EndRegion

// Religion Region

getReligion() {
this.lookup.GetReligion().subscribe(
(response) => {
this.Religion_List = response.data;
response.data.forEach((element) => {
this.Religion_Dictionary[element.id] = element.title;
});
},
(err) => {
    Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: err.error.message,
      })
}
);
}
selectedReligion(event: any) {
this.Religion_id = event.target.value;
}
// EndRegion

// Religion Region

getNationality() {
this.lookup.GetNationality().subscribe(
(response) => {
this.Nationality_List = response.data;
response.data.forEach((element) => {
this.Nationality_Dictionary[element.id] = element.title;
});
},
(err) => {
    Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: err.error.message,
      })
}
);
}
selectedNationality(event: any) {
this.Nationality_id = event.target.value;
}
// EndRegion

// Role Region
getRole() {
this.lookup.GetRole().subscribe(
(response) => {
this.Role_List = response.data;
response.data.forEach((element) => {
this.Role_Dictionary[element.id] = element.name;
});
},
(err) => {
    Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: err.error.message,
      })
}
);
}

// getClientType() {
// this.clientTypeApiService.GetClientType().subscribe(
// (response) => {
// console.log(response);
// this.dropdownList = response.data;
getClientData(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.clientApiService.GetClient('false').subscribe(
        (response) => {
          this.dropdownList = response.data; // Update dropdown data when API responds
          resolve();
        },
        (err) => {
          reject(err);
          // Handle error
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: err.error.message,
          })
        }
      );
    });
  }

     getById(id) {
       // this.getClientType()

this.ApiService.GetEmployeeById(id).subscribe(async (response) => {
let result = response;
this.GetCityByGovernateId(result["data"].governoratId)

this.employeeInfo = result["data"];
this.date =this.datepipe.transform(this.employeeInfo.birthdate, 'yyyy-MM-dd');
this.Hiringdate =this.datepipe.transform(this.employeeInfo.hiringDate, 'yyyy-MM-dd');
this.employeeInfo.birthdate=this.date;
this.employeeInfo.hiringDate=this.Hiringdate;

if(result["data"].inactive){
    this.inactive=true;
}
else{
    this.inactive=false;
}
this.imgemployeePic=
this.imgUrlServer+ result["data"].employeePic;
this.imgResumePic =
this.imgUrlServer+ result["data"].resumePic;
this.imgMilitaryCertificatePic =
this.imgUrlServer+ result["data"].militaryCertificatePic;
this.imgGraduationCertificatePic =
this.imgUrlServer + result["data"].graduationCertificatePic;
this.imgOtherPaperPic =
this.imgUrlServer + result["data"].otherPaperPic;
console.log(this.employeeInfo.companies)
this.employeeInfo.companies.forEach(element => {
    const company=    this.dropdownList.filter(
        (sub: any) => sub.id == element
        )[0]
this.selectedItems.push(
    company
);
});
   





this.EmployeeForm.patchValue({
Companies: this.selectedItems,
});
this.EmployeeForm.patchValue({
    Companies: this.selectedItems,
    });


this.EmployeeForm.patchValue({
    OfficeId: this.employeeInfo.officeId,
    });

});
}
//#endregion
SelectedRole(event: any) {
this.Role_id = event.target.value;
}
getEmployeePhoto(event: any) {
const reader = new FileReader();

if (event.target.files && event.target.files.length) {
const [file] = event.target.files;

this.employeePic = event.target.files[0];

reader.readAsDataURL(file);

reader.onload = () => {
this.imgemployeePic = reader.result as string;

// this.InsertForm.patchValue({
//   fileSource: reader.result,
// });
};
}
// this.employeePic=event.target.files[0];
}
getresumePic(event: any) {
const reader = new FileReader();

if (event.target.files && event.target.files.length) {
const [file] = event.target.files;

this.resumePic = event.target.files[0];

reader.readAsDataURL(file);

reader.onload = () => {
this.imgResumePic = reader.result as string;

// this.InsertForm.patchValue({
//   fileSource: reader.result,
// });
};
}
}
getmilitaryCertificatePic(event: any) {
const reader = new FileReader();

if (event.target.files && event.target.files.length) {
const [file] = event.target.files;

this.militaryCertificatePic = event.target.files[0];

reader.readAsDataURL(file);

reader.onload = () => {
this.imgMilitaryCertificatePic = reader.result as string;

// this.InsertForm.patchValue({
//   fileSource: reader.result,
// });
};
}
}

getgraduationCertificatePic(event: any) {
const reader = new FileReader();

if (event.target.files && event.target.files.length) {
const [file] = event.target.files;

this.graduationCertificatePic = event.target.files[0];

reader.readAsDataURL(file);

reader.onload = () => {
this.imgGraduationCertificatePic = reader.result as string;

// this.InsertForm.patchValue({
//   fileSource: reader.result,
// });
};
}
}
getotherPaperPic(event: any) {
this.otherPaperPic = event.target.files[0];

const reader = new FileReader();

if (event.target.files && event.target.files.length) {
const [file] = event.target.files;

this.otherPaperPic = event.target.files[0];

reader.readAsDataURL(file);

reader.onload = () => {
this.imgOtherPaperPic = reader.result as string;

// this.InsertForm.patchValue({
//   fileSource: reader.result,
// });
};
}
}
// EndRegion
//Deal with images
///loop form
loopform() {
    this.data = new FormData();
// console.log(this.selectedItems);
// this.selectedItems.forEach((element) => {
// this.ClientTypes.push(element.id);
// });

this.employeeInfo.companies = this.ClientTypes;
Object.keys(this.employeeInfo).forEach((key) => {
if (typeof this.employeeInfo[key] !== "object"){
    this.data.append(key, this.employeeInfo[key]);

}
else{
    if(this.employeeInfo[key]!=null){
        Object.keys(this.employeeInfo[key]).forEach((subkey) => {
this.data.append(key, this.employeeInfo[key][subkey]);
});
    }

}

});
//  data.append("graduationCertificatePic", this.gradimg)
if(this.update){
this.data.set("birthdate", this.employeeInfo.birthdate);
this.data.set("hiringDate", this.employeeInfo.hiringDate);
this.data.set("employeePic", this.employeePic);
this.data.set("resumePic", this.resumePic);
this.data.set("militaryCertificatePic", this.militaryCertificatePic);
this.data.set("graduationCertificatePic", this.graduationCertificatePic);
this.data.set("otherPaperPic", this.otherPaperPic);
this.data.set("inactive",JSON.stringify(this.inactive))
}
else{
    this.data.append("birthdate", this.date);
this.data.append("hiringDate", this.Hiringdate);
this.data.append("employeePic", this.employeePic);
this.data.append("resumePic", this.resumePic);
this.data.append("militaryCertificatePic", this.militaryCertificatePic);
this.data.append("graduationCertificatePic", this.graduationCertificatePic);
this.data.append("otherPaperPic", this.otherPaperPic);
    this.data.append("inactive", JSON.stringify(this.inactive));

}


}

onSubmit() {
// this.validated = !this.validated

// this.validated = !this.validated

if (this.EmployeeForm.status == "VALID") {
 
this.validated = false;



if (this.update == true) {
this.UpdateClientType();
} else {
this.InsertClientType();
}
} else {
 
        Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: "يرجى ادخال جميع الحقول المطلوبة",
          })
    
this.validated = true;
}
}
}
