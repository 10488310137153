<app-breadcrumb [title]="'     اضافه مشروع جديد '"  [active_item]="'Form Wizard'"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding-bottom:5px !important ;">
            <div *ngIf="update; else elseBlock1">
              <h5 class="pb-2">تعديل نوع مشروع موجود</h5>
              يمكنك تعديل نوع مشروع أو أكثر فى قائمة انواع المشروعات
            </div>
          <ng-template #elseBlock1>
            <h5 class="pb-2">إضافة  نوع مشروع جديد</h5>
              يمكنك إضافة نوع مشروع أو أكثر فى قائمة انواع المشروعات  
            </ng-template>

        </div>
        <div class="card-body">
            <form class="needs-validation"  [formGroup]="InsertForm" (ngSubmit)="  onSubmit()" [ngClass]="{'was-validated': validated}">



              <div class="form-row">
                <div class="col-12 mb-3">
                  <label for="name"><span class="validation-required">*</span>    نوع المشروع</label>
                  <input class="form-control"         id="validationCustom01"
                   type="text"      required=""
                  formControlName="Title" placeholder="ادخل  نوع المشروع"
                  [(ngModel)]="insertSectorType.title"
                  >
                  
                 
                  
                </div>
                </div>
{{insertSectorType.title}}

                <!-- <div class="form-group">
                 
                  <ng-container class="invalid" style="color: red;"
                    *ngIf="InsertForm.get('Title').hasError('required') && InsertForm.get('Title').touched">
                    أسم نوع التبرع  مطلوب</ng-container>
                </div> -->

                <!-- <div class="col-12 mb-3"> -->
                  <div class="form-group">
                  <label for="name"><span class="validation-required">*</span>اسم الشركه</label>
                  <ng-select class="js-example-basic-single"
                          formControlName="CompanyId"
                          aria-label="Default select example"
                          (change)="SelectCompany($event.id)"
                          required="required"
                          [items]="Companies"
                          bindLabel="name"
                          bindValue="id"
                           >

                  </ng-select>
                </div>
              <!-- </div> -->
                <div class="form-group">
                    <label for="validationCustom02"><span class="validation-required">*</span>   أختر  النشاط</label>
                    <ng-select
                    (change)="SelectedSector($event.id)"
                    aria-label="Default select example"
                    required="required"
                    [items]="Sector_List"
                    bindLabel="title"
                    bindValue="id"
                    formControlName="SectorId"
                  >
                  </ng-select>


 
             
                    
               
                  
                  </div>
                  <div class="form-group">
                    
                    <label >أختر  مدير المشروع </label>
                      <!-- <ng-select class="js-example-basic-single"
                              formControlName="employeeId"
                              (change)="SelectedEmployee($event.id)"
                              aria-label="Default select example"
                              required="required"
                              [items]="Employees"
                              bindLabel="name"
                              bindValue="id"
                               >
                      </ng-select> -->
                      <ng-multiselect-dropdown
                      formControlName="employeeId"
                      id="validationCustom02=3"
                      required="required"
                      (change)="SelectedEmployee($event)"
                        placeholder="أختر مدير للمشروع أو أكتر من القائمة"
                        [settings]="dropdownSettings"
                        [data]="ProjectManagerEmployees"
                                           
                      >
                      <ng-option  [value="{{employee.id}}" *ngFor="let employee of ProjectManagerEmployees" >
                        {{ employee.name }}
                      </ng-option>
                      </ng-multiselect-dropdown>
                  </div>
                  <div class="form-group">
                    <label for="validationCustom02"> <span class="validation-required">*</span>   الوقت المستغرق  </label>
          
                      
                        <input class="form-control"
                         id="validationCustom02" type="text"
                          placeholder="  الوقت المستغرق   " required=""
                          [(ngModel)]="insertSectorType.numberofDays"
                        formControlName="numberofDays" onlyNumbers><!-- onlyAlphabets -->
                      
                      
                      </div>

                      <div class="form-group">
                        <label for="validationCustom02"> التكلفة  </label>
              
                          
                            <input class="form-control"
                             id="validationCustom02" type="text"
                              placeholder="  التكلفة   " required=""
                              [(ngModel)]="insertSectorType.cost"
                            formControlName="Cost" onlyNumbers><!-- onlyAlphabets -->
                          
                          
                          </div>
                <div *ngIf="update; else elseBlock">
                    <button  class="btn btn-primary pull-right" 
                    type="submit"
                
                   >
                    تعديل

                    <!-- (click)="UpdateSectorType()"" -->
                 </button>
                </div>
                <ng-template #elseBlock>
                    <button  class="btn btn-primary pull-right" 
                    type="submit"
                   
                   >
                    إضافة
                    <!-- (click)="InsertSectorTypes()" -->
                 </button>
                </ng-template>
               
              </form>
        </div>
      </div>
    </div>
  </div>
</div>