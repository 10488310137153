import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChangeDonationCreatorService } from 'src/app/shared/API-Service/change-donation-creator.service';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { environment } from 'src/environments/environment.prod';
import { EmployeeApiService } from 'src/app/shared/API-Service/employee-api.service';
import { CollectorManagerService } from './../../../shared/API-Service/collector-manager.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';

@Component({
  selector: 'app-change-donation-creator',
  templateUrl: './change-donation-creator.component.html',
  styleUrls: ['./change-donation-creator.component.css']
})

export class ChangeDonationCreatorComponent  extends BaseComponent implements OnInit {
  paginationview: boolean;
  imgUrlServer=environment.Server_URL_Image+'/'
  DonationForChangeCreatorList: any;
  Governorate_List:any
  FilterData={
    donationSerial: null,
    donationCreatedBy: null,
    donationDateFrom: null,
    donationDateTo: null,
    CompanyId:null
    
  }
  FilterDataCallHistory={
    customerId: null,
    dateFrom: null,
    dateTo: null,
    companyId:null
  }
  Employees: any;
  closeResult: string;
  DonationId: any;
  EmployeeId:any
  details: boolean;
  DonationForChangeCreator: any;
  CustomerCallHistory: any;
  totalCountCallHistory: any;
  pageNumberCallHistory: any;
  maxResultCountCallHistory: number;
  skipCountCallHistory: number;
  customerId: any;
  AllEmployees: any;
  CompanyId: any;
  Companies: any[];
  
  constructor(private CompanyDataService :CompanyDataService,public modalService: NgbModal, private CollectorManagerService: CollectorManagerService,private ChangeDonationCreatorService: ChangeDonationCreatorService,
    private router: Router,private EmployeeApiService : EmployeeApiService) { 
      super();
      this.paginationview=true;
      this.maxResultCountCallHistory=5;
      this.skipCountCallHistory=0;
      this.GetEmployees();
      this.GetCompanies();
    }
    open(content,DonationId,companyId,EmployeeId) {
      this.GetEmployeesCompany(companyId)
      this.EmployeeId=EmployeeId
      this.DonationId=DonationId
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
    }
    GetCompanies(){
      this.CompanyDataService.GetCompanies().subscribe(res=>{
        this.Companies=res.data
      })
    }
  //#endregion
  GetEmployeesCompany(companyId){
    this.EmployeeApiService.GetEmployee(companyId).subscribe((res:any)=>{
      this.Employees=res.data;
    })
  }
  GetEmployees(){
    this.EmployeeApiService.GetEmployee().subscribe((res:any)=>{
      this.AllEmployees=res.data;
    })
  }
  //#region  ng OnInit
  ngOnInit(): void {
    this.GetDonationForChangeCreatorList();
  }
  ChangeDonationCreator(){
    const data={
      "donationId": this.DonationId,
      "createdBy": this.EmployeeId
    }
    this.ChangeDonationCreatorService.ChangeDonationCreator(data).subscribe(response => {
      if (response) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'تم تغير منشئ التبرع',
          showConfirmButton: false,
          timer: 1500
        })
        this.EmployeeId=null;
        this.GetDonationForChangeCreatorList();
        this.DonationId=null;
        this.modalService.dismissAll()
      }
    })
  }
  //#endregion
  Search(){
    this.pageNumber=1
    this.pager.skipCount=0
    if(!this.FilterData.donationDateFrom){
      this.FilterData.donationDateFrom=null
    }
    if(!this.FilterData.donationDateTo){
      this.FilterData.donationDateTo=null
    } 
    this.pager.donationSerial=this.FilterData.donationSerial
    this.pager.donationCreatedBy=this.FilterData.donationCreatedBy
    this.pager.donationDateFrom=this.FilterData.donationDateFrom
    this.pager.donationDateTo=this.FilterData.donationDateTo
    this.GetDonationForChangeCreatorList();
  }
  //#region Consume API's

  pageChanged(event:any){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.pager.donationSerial=this.FilterData.donationSerial
    this.pager.donationCreatedBy=this.FilterData.donationCreatedBy
    this.pager.donationDateFrom=this.FilterData.donationDateFrom
    this.pager.donationDateTo=this.FilterData.donationDateTo
    this.ChangeDonationCreatorService.GetDonationForChangeCreatorList(this.pager).subscribe(
      (response:any) => {
         this.totalCount = response.data.totalCount;
         this.DonationForChangeCreatorList=response.data.items;
       },
       err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
       }
     )
  }
  pageChangedCallHistory(event:any){
    this.pageNumberCallHistory = event.page;// -1 * pageSize;
    this.skipCountCallHistory = (this.pageNumberCallHistory - 1) * this.maxResultCountCallHistory;
    this.FilterDataCallHistory['maxResultCount']=this.maxResultCountCallHistory;
    this.FilterDataCallHistory['skipCount']=this.skipCountCallHistory;
    if(!this.FilterDataCallHistory.dateFrom){
      this.FilterDataCallHistory.dateFrom=null
    }
    if(!this.FilterDataCallHistory.dateTo){
      this.FilterDataCallHistory.dateTo=null
    }
    this.FilterDataCallHistory.customerId=this.customerId

    this.ChangeDonationCreatorService.GetCustomerCallHistory(this.FilterDataCallHistory).subscribe(
     (response:any) => {
        // this.totalCount = response.data.totalCount;
        this.CustomerCallHistory=response.data.items;
        this.totalCountCallHistory=response.data.totalCount
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }
  //#region  get Governoate
  GetDonationForChangeCreatorList() {
    if(!this.FilterData.donationDateFrom){
      this.FilterData.donationDateFrom=null
    }
    if(!this.FilterData.donationDateTo){
      this.FilterData.donationDateTo=null
    } 
    this.pager.donationSerial=this.FilterData.donationSerial
    this.pager.donationCreatedBy=this.FilterData.donationCreatedBy
    this.pager.donationDateFrom=this.FilterData.donationDateFrom
    this.pager.donationDateTo=this.FilterData.donationDateTo
    this.pager.companyId=this.FilterData.CompanyId
    
    this.ChangeDonationCreatorService.GetDonationForChangeCreatorList(this.pager).subscribe(
     (response:any) => {
        this.totalCount = response.data.totalCount;
        this.DonationForChangeCreatorList=response.data.items;
        this.DonationForChangeCreator = response.data.items
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }

  OpenDetailes(index,customerId) {
this.pageNumberCallHistory=1
this.skipCountCallHistory=0
this.CompanyId=this.DonationForChangeCreatorList[index].companyId
    this.CustomerCallHistory=[]
    this.customerId=customerId
    if (this.DonationForChangeCreatorList[index]["ShowDetails"]) {
      this.details = false;
       this.DonationForChangeCreatorList[index]["ShowDetails"] = false;
  //     this.DonationForChangeCreatorList = this.DonationForChangeCreator
       this.pageNumberCallHistory=1;
       this.skipCountCallHistory=0
    }
    else {
      this.details = true;
      this.DonationForChangeCreatorList.forEach(element => {
        if(element.ShowDetails){
          element.ShowDetails=false
        }
      });
      this.DonationForChangeCreatorList[index]["ShowDetails"] = true;
    //  this.DonationForChangeCreatorList = this.DonationForChangeCreator
      this.GetCustomerCallHistory()
    }
  }
  GetCustomerCallHistory() {
    this.FilterDataCallHistory['maxResultCount']=this.maxResultCountCallHistory;
    this.FilterDataCallHistory['skipCount']=this.skipCountCallHistory;
    if(!this.FilterDataCallHistory.dateFrom){
      this.FilterDataCallHistory.dateFrom=null
    }
    if(!this.FilterDataCallHistory.dateTo){
      this.FilterDataCallHistory.dateTo=null
    }
    this.FilterDataCallHistory.customerId=this.customerId
    this.FilterDataCallHistory.companyId=this.CompanyId
    this.ChangeDonationCreatorService.GetCustomerCallHistory(this.FilterDataCallHistory).subscribe(
     (response:any) => {
        // this.totalCount = response.data.totalCount;
        this.CustomerCallHistory=response.data.items;
        this.totalCountCallHistory=response.data.totalCount
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }
}
