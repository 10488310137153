export class Customer {
    id?:number
    name:string;
    governoratId:number;
    cityId:number;
    address:string;
    gender:string;
    dateOfBirth:string;
    mobile:string;
    mobile1:string;
    mobile2:string;
    mobile3:string;
    customerPhones?:any[]=[];
    customerCalls?:any[]=[];
    sourceOfMarketingId:number;
    startCallUTC:string;


}