export class SourceOfmarketing {
    creationDateFrom:any= ''
    creationDateTo:any= ''
    collectionDateTo:string= ''
    collectionDateFrom:string= ''
    companyId:number=null
    donationStatus:number=null
    donationStatusName:string= ''
    donationTypeId:number=null
    donationTypeName:string= ''
    sectorId:number = null
    sectorName:string= ''
    sectorLocationId :number=null
    sectorLocationName :string= ''
    sectorTypeId :number=null
    sectorTypeName:string= ''
    sourceMarketId :number=null
    sourceMarketName:string= ''
    reportCreatedBy:string=''
}
