export class DonationRequests {

    collectorId:number=null;
    collectorName:string='';
    isReceivedApproved:boolean;
    creationDateFrom:string = ''
    creationDateTo:string = ''
    collectionDateFrom:string=''
    collectionDateTo:string=''
    governoratId:number= null
    governoratName:string=''
    cityId:number= null
    cityName:string=''
    donationStatus:number=null;
    donationStatusName:string = ''
    donationTypeId:number=null
    donationTypeName:string=''
    companyId:number = null;
    sectorId:number = null;
    sectorName:string='';

    sectorTypeId:number = null;
    sectorTypeName:string='';

    sectorLocationId:number=null
    sectorLocationName:string='';

    reportCreatedBy:string='';
}
