
<div>
  <app-breadcrumb [title]="'قائمة الرسائل'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'">
</app-breadcrumb>
<div class="card">
    <div class="card-body">
      <div class="row">
<div class="col-6">
   <div class="form-group">
  <label for="name">اسم الشركة</label>
  <ng-select class="js-example-basic-single"
        [(ngModel)]="CompanyId"
          aria-label="Default select example"
          required="required"
          [items]="Companies"
          bindLabel="name"
          bindValue="id"
           >

  </ng-select>
</div>
</div>
<div class="col-6">
  <button  type="button"  class="btn btn-primary searchMT"(click)="Search()"><span _ngcontent-gki-c179="" class="m-2">بحث</span></button>
  
</div>
      </div>
     
        <button _ngcontent-gki-c179="" appActionNew type="button" class="btn btn-success pull-right mb-2" (click)="newmessage()"><span _ngcontent-gki-c179="" class="m-2">جديد</span><i _ngcontent-gki-c179="" class="fa fa-plus"></i></button>
       
        <div class="table-responsive">
    <table class="table" style="width: 100%;">
        <thead>
            <tr class="bg-dark ">
                <th scope="col" class="font-style-header" >#</th>
                <th scope="col" class="font-style-header" >ش</th>
                <th scope="col" class="font-style-header">الموضوع </th>
                <th scope="col" class="font-style-header" >محتوي الرساله </th>
                <th scope="col" class="font-style-header" > حاله التبرع</th>
                <th scope="col" class="font-style-header" > </th>
       
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of Filtered_List;let i=index">
                <td scope="row" class="font-style" >{{i+1}}</td>
                <td scope="row" class="font-style"><img width="30px" height="30px" *ngIf="!item.isSystemSms" [src]="imgUrlServer+item.companyIcon">
                  <img width="30px" height="30px" *ngIf="item.isSystemSms" src="../../../../../assets/Risk Logo 200 x 200/Risk-Logo-200-x-200.png">
                </td>
                <!-- <td style="width: 35%; text-align: center;" class="font-style">{{item.donationStatusName}} </td> -->
                <td  class="font-style">{{item.smscontent}}</td>
                <td style="width: 35%; " class="font-style">{{item.description}}</td>
                <td  class="font-style">{{item.donationStatusName}}</td>
                <!-- <td style="width: 35%; text-align: center; cursor: pointer;" class="font-style" (click)="deleteitem(item.id)">
                  <i class="fa fa-trash " style="color: red;"></i>

                  
                </td>     -->
                
                
                <td class="font-style " style="width: 10%; text-align: center;"  [ngStyle]="{ 'background-color': item.companyColor  }">

                    <button
                    appActionDelete
                    class="btn " *ngIf="!item.isSystemSms"
                    
                    (click)="deleteitem(item.id)"
                    style="color: red; padding: 7px; background-color: transparent;"
  
                  
                    >
                    <i class="fa fa-trash"></i>
  
                  </button>
             
                    <button
                    class="btn  "
                    appActionEdit
                      style="color: rgba(35, 118, 241, 0.667); padding: 7px; background-color: transparent;"
                      (click)="Update(item)"
                    >
                      <i class="fa fa-pencil"></i>
                    </button>
                  </td>
            </tr>
        </tbody>
    </table>
    <div class="d-flex justify-content-center">
      <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
        firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
        (pageChanged)="pageChanged($event)">
      </pagination>
    </div>
</div>
    </div>
</div>
</div>

