import { Router } from '@angular/router';
import { ReceiptBookReport } from '../../../../shared//Models/ReceiptBookReport';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ReceiptBookService } from 'src/app/shared/API-Service/receipt-book.service';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-receipt-book-report',
  templateUrl: './receipt-book-report.component.html',
  styleUrls: ['./receipt-book-report.component.css']
})
export class ReceiptBookReportComponent implements OnInit {
  GetReceiptBooks
  ReceiptBookReport = new ReceiptBookReport()
  currentUser: any;
  Companies: any[];
  constructor(private CompanyDataService:CompanyDataService, private ReceiptBookService:ReceiptBookService,private router:Router) {
    this.currentUser=JSON.parse(localStorage.getItem("RiskCurrentUser"))
    if(this.currentUser.roleId==10){
      this.ReceiptBookReport.companyId=this.currentUser.id
    }
    if(this.currentUser.roleId==8){
      this.ReceiptBookReport.collectorId=this.currentUser.id
    }
    else{
      this.ReceiptBookReport.collectorId=null
    }
    this.ReceiptBookReport.reportCreatedBy=this.currentUser.name;
   
  }
  

  ngOnInit(): void {

    this.GetCompanies();
  }

  getReceiptBooks(companyId){
    this.ReceiptBookService.GetReceiptBookByCompanyId(companyId).subscribe(response=>{
          
      this.GetReceiptBooks = response.data
    })
  }

  SelectCompany(CompanyId){
    this.ReceiptBookReport.companyId=parseInt(CompanyId);
    this.GetReceiptBooks=[];
    this.ReceiptBookReport.receiptBookId=null;
    this.getReceiptBooks(CompanyId);
  }
  GetCompanies(){
    this.CompanyDataService.GetCompanies().subscribe(res=>{
      this.Companies=res.data
    })
  }
  getReceiptBook(event){
    this.ReceiptBookReport.receiptBookId = parseInt(event) 
    this.ReceiptBookReport.receiptbookName = this.GetReceiptBooks.find(x => x.id == event).bookNumber
   }


  batchsubmit(){
if(this.ReceiptBookReport.companyId!=null && this.ReceiptBookReport.receiptBookId){
    if (this.ReceiptBookReport.receiptBookId==null) {
      this.ReceiptBookReport.receiptBookId=null
      this.ReceiptBookReport.receiptbookName=''
     }

     localStorage["ReportName"]="ReceiptBookReport";
     localStorage.setItem('ReportFilter',JSON.stringify(this.ReceiptBookReport));
   
     this.router.navigate([]).then((result) => {
       window.open("/content/admin/call-start-end-report", '_blank');
     });
  }
  else{
    if(!this.ReceiptBookReport.companyId){
    Swal.fire({
    icon:'error',
    title:'خطأ',
    text:'يجب اختيار الشركة'

  })    
    }
    else if (!this.ReceiptBookReport.receiptBookId){
      Swal.fire({
        icon:'error',
        title:'خطأ',
        text:'يجب اختيار رقم الدفتر'
    
      }) 
    }

}
}

}
