import { analyzeAndValidateNgModules } from "@angular/compiler";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { IDropdownSettings } from "ng-multiselect-dropdown";

import { ToastrService } from "ngx-toastr";
import { CallReasonApiService } from "src/app/shared/API-Service/call-reason-api.service";
import { ClientTypeApiService } from "src/app/shared/API-Service/client-type-api.service";
import { Assign_ClientCustomer } from "src/app/shared/Models/Assign_ClientCustomer";
import { CallClient, CompanyType } from "src/app/shared/Models/CallClient";
import { GetCallReason } from "src/app/shared/Models/get-call-reason";
import {
InsertCallReason,
UpdateCallReason,
} from "src/app/shared/Models/insert-call-reason";
import Swal from "sweetalert2";

@Component({
selector: "app-InsertCallReason",
templateUrl: "./InsertCallReason.component.html",
styleUrls: ["./InsertCallReason.component.css"],
})
export class InsertCallReasonComponent implements OnInit {
//#region Decalre varaibles
InsertForm: FormGroup;
maxDate: Date;
update: boolean;
dropdownSettings: IDropdownSettings = {};
dropdownList: any = [];
selectedItems: any = [];
callClient: CallClient[] = [];
companyTypeId: any = [];
returnedObject: any = {};
validated: boolean;

//retobj: any;
//#endregion

//#region  constructor
constructor(
private _formBuilder: FormBuilder,
private toaster: ToastrService,
private ApiService: CallReasonApiService,
private clientTypeApiService: ClientTypeApiService,
private router: Router,
private route: ActivatedRoute
) {
this.maxDate = new Date();
}
//#endregion

//#region  ng OnInit
async ngOnInit() {
await this.getClientType();

if (this.route.snapshot.paramMap.get("id")) {


this.InitForm(
localStorage.getItem("reasonTitle"),
+localStorage.getItem("reasonOrder")
);
this.update = true;

} else {
this.update = false;

this._InitForm();
this.GetCallReason();
}

this.dropdownSettings = {
singleSelection: false,
idField: "id",
textField: "title",
selectAllText: "تحديد الكل",
unSelectAllText: "الغاء الكل",
itemsShowLimit: 3,
allowSearchFilter: true,
};
}
onSubmit() {
// this.validated = !this.validated

// this.validated = !this.validated
// if(this.containsSpecialChars(this.InsertForm.get("Title").value)==true){
//     Swal.fire({
//       icon: 'error',
//       title: 'خطأ',
//       text: "لا يمكن كتابه علامات ",
//     })
//   }
//   else if (this.containsNumber(this.InsertForm.get("Title").value)==true){
//     Swal.fire({
//       icon: 'error',
//       title: 'خطأ',
//       text: "لا يمكن كتابه ارقام ",
//     })
//   }
// else
 if (this.InsertForm.status == "VALID") {
this.validated = false;

if (this.update == true) {
this.UpdateCallReason();
} else {
this.InsertCallReason();
}
} else {
this.validated = true;
}
}

//#endregion

//#region  Init Form
InitForm(title: string, order: number) {

this.InsertForm = this._formBuilder.group({
Title: [title, Validators.required],
Order: [order, Validators.required],
ClientTypes: [this.selectedItems, Validators.required],
});
}
_InitForm() {
this.InsertForm = this._formBuilder.group({
Title: ["", Validators.required],
Order: ["", Validators.required],
ClientTypes: [this.selectedItems, Validators.required],
});
}
//#endregion

//#region  Get Client Types
getClientType() {
this.clientTypeApiService.GetClientType().subscribe(
(response) => {
this.dropdownList = response.data;
let id = +this.route.snapshot.paramMap.get("id");
this.ApiService.GetReasonById(id).subscribe((data) => {
this.returnedObject = data.data;
this.selectedItems = [];
for (let index = 0; index < this.returnedObject.companytype.length; index++) {
this.selectedItems.push(
this.dropdownList.filter(
(sub: any) => sub.id === this.returnedObject.companytype[index]
)[0]
);
}
this.InitForm(this.returnedObject.title, this.returnedObject.order);
});
},
(err) => {
  Swal.fire({
    icon: 'error',
    title: 'خطأ',
    text: err.error.message,
  })
}
);
}
//#endregion
containsNumber(str) {
    const specialChars = /[/\d/٠١٢٣٤٥٦٧٨٩]/;
    return specialChars.test(str);
  }
  //#endregion
  containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }
//#region  Insert Call Reason Method
InsertCallReason() {
  const companyTypeId=[]

this.InsertForm.get("ClientTypes").value.forEach((element) => {
  companyTypeId.push(element.id);
});
this.ApiService.InsertCallReason({
companytype: companyTypeId,
title: this.InsertForm.get("Title").value,
order: this.InsertForm.get("Order").value,
} as InsertCallReason).subscribe(
// response=>{

(response) => {
Swal.fire({
icon: "success",
title: "تم إدخال السبب بنجاح",
showConfirmButton: false,
timer: 1500,
});
this.router.navigateByUrl("content/admin/Get-Call-Reason");
},
(err) => {}

// this.callClient =[];
);
}
//#endregion

//#region Update Call Reason
UpdateCallReason() {
let Id = +this.route.snapshot.paramMap.get("id");
const companyTypeId=[]
this.InsertForm.get("ClientTypes").value.forEach((element) => {
  companyTypeId.push(element.id);
});
this.ApiService.UpdateCallReason({
id: Id,
title: this.InsertForm.get("Title").value,
order: this.InsertForm.get("Order").value,
companytype: companyTypeId,
} as UpdateCallReason).subscribe((Response) => {
Swal.fire({
icon: "success",
title: "تم تعديل السبب بنجاح",
showConfirmButton: false,
//timer: 1500
});

this.router.navigateByUrl("content/admin/Get-Call-Reason");
});
}
//   this.ApiService.UpdateCallReason({ id:this.InsertForm.get('id').value,title:this.InsertForm.get('Title').value,order:this.InsertForm.get('Order').value} as UpdateCallReason).subscribe(
//     response=>{
//       // console.log(response);

//       this.InsertForm.get('ClientTypes').value.forEach(element => {

//         this.callClient.push({ClientTypeId:element.typeId,CallReasonId:id}as CallClient);
//       });
//       this.ApiService.CallReasonClientType(this.callClient).subscribe(
//         (data)=>{
//           Swal.fire({
//             icon: 'success',
//             title: "تم تعديل السبب  بنجاح",
//             showConfirmButton: false,
//             timer: 1500
//           })
//           this.router.navigateByUrl("content/admin/Get-Call-Reason");
//           localStorage.removeItem("reasonTitle");
//           localStorage.removeItem("reasonOrder");

//         },
//         (err)=>{

//         }
//       )
//     },
//     err=>{
//       // Swal.fire({
//       //   icon: 'error',
//       //   title: 'خطأ',
//       //   text: err.error,
//       // })
//     }
//   )

//#endregion

//#region  Get Call Reason
GetCallReason() {
this.ApiService.GetCallReason().subscribe(
(response) => {
this.InsertForm.patchValue({ Order: response.data.length + 1 });
},
(err) => {
  Swal.fire({
    icon: 'error',
    title: 'خطأ',
    text: err.error.message,
  })
}
);
}
//#endregion

onItemSelect(item: any) {
// console.log("---",this.EmployeeForm.get('Clients').value)
}

onSelectAll(items: any) {
//console.log(items);
}
}
