import { DatePipe } from "@angular/common";
import { StateService } from "./../../../shared/API-Service/state.service";
import { Customer } from "../../../shared//Models/Customer";
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CallApiService } from "src/app/shared/API-Service/call-api.service";
import { CallReasonApiService } from "src/app/shared/API-Service/call-reason-api.service";
import { CitiesApiService } from "src/app/shared/API-Service/cities-api.service";
import { CustomerApiService } from "src/app/shared/API-Service/customer-api.service";
import { EmployeeApiService } from "src/app/shared/API-Service/employee-api.service";
import { CompanyDataService } from "src/app/shared/API-Service/company-data.service";
import { GovernorateApiService } from "src/app/shared/API-Service/governorate-api.service";
import { SourceMarketApiService } from "src/app/shared/API-Service/source-market-api.service";
import { GenericResponse } from "src/app/shared/Models/GenericResponse";
import { GetCallReason } from "src/app/shared/Models/get-call-reason";
import { GetSourceMarket } from "src/app/shared/Models/get-source-market";
import { getCities } from "src/app/shared/Models/getCities";
import { GetEmployee } from "src/app/shared/Models/GetEmployee";
import { GetGovernorate } from "src/app/shared/Models/GetGovernorate";
import { InsertClientType } from "src/app/shared/Models/insert-client-type";
import { InsertCall } from "src/app/shared/Models/InsertCall";
import Swal from "sweetalert2";
import { Asset } from "./Asset";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { ClientApiService } from "src/app/shared/API-Service/client-api.service";
import { filter, pairwise } from "rxjs/operators";
@Component({
  selector: "app-display-customer-data",
  templateUrl: "./display-customer-data.component.html",
  styleUrls: ["./display-customer-data.component.css"],
})
export class DisplayCustomerDataComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  //#region Decalre varaibles
  EmployeeForm: FormGroup;
  formvalue: FormGroup;
  Form: FormGroup;
  _InsertClientType: InsertClientType;
  maxDate: Date;
  update: boolean;
  pass: string;
  Governorate_Dictionary: { [Id: number]: string } = {};
  Governorate_List: GetGovernorate[];
  Govern_id: number;
  Governorate: string;
  City: string;
  response: GenericResponse<GetGovernorate>;
  Response_List: any[];
  Filtered_List: any[];
  SourceOfMarkting: getCities[];
  CityId: number;
  cityId: number;
  selectMarcet: number;
  selectgender: number;
  Gender: number;
  CallReason_List: GetCallReason[];
  SourceMarket_List: GetSourceMarket[];
  start_Call: any;
  End_Call: any;
  _InsertCall: any = {};
  _InsertCustomer: any = {};
  customerInfo = new Customer();
  bitrhdate: any;
  callDetails: any[] = [];
  // _InsertCustomerid:Customer = new Customer  ;
  satis: boolean;
  //#endregion
  formData = new FormData();
  modales: Asset = new Asset();
  customerData: any;
  addcustomer: boolean;
  editcustomer: boolean;
  activePM: boolean = false;
  passdata: any;
  donationMainTypeId: any;
  cityid: any;
  callid: any;
  customerPhoneslenght: number;
  id: string;
  companyId: number;
  gender: { id: number; title: string }[];
  callType: { id: number; title: string }[];
  addcustomerDisapled: boolean;
  editcustomerDisapled: boolean;
  StartCallDate: any;
  customerPhones: any[] = [];
  EndCallDate: string;

  //#region  constructor
  constructor(
    private ClientApiService: ClientApiService,
    private _formBuilder: FormBuilder,
    private toaster: ToastrService,
    private ApiService: EmployeeApiService,
    private governorateApiService: GovernorateApiService,
    private citiesApiService: CitiesApiService,
    private customerApiService: CustomerApiService,
    private callReasonApiService: CallReasonApiService,
    private sourceMarketApiService: SourceMarketApiService,
    private callApiService: CallApiService,
    private router: Router,
    private route: ActivatedRoute,
    private StateService: StateService,
    private _ActivatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private CompanyDataService: CompanyDataService
  ) {
    super();
     this.sourceMarketGet();
    this.citiesApiService.GetSourceOfMarkting().subscribe((res) => {
      this.SourceOfMarkting = res.data;
    });
    this.InitForm()

    this.CompanyDataService.CompanyInfo.CompanyId = 1;
    //this.StartCallDate = new Date().toISOString()
   

    
    this.gender = [
      {
        id: 1,
        title: "ذكر",
      },
      {
        id: 2,
        title: "أنثى",
      },
    ];

    this.callType = [
      {
        id: 1,
        title: "صادرة",
      },
      {
        id: 2,
        title: "واردة",
      },
    ];
    this.maxDate = new Date();
    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof NavigationEnd),
        pairwise()
      )
      .subscribe((events: NavigationEnd[]) => {
        const previousUrl = events[0].urlAfterRedirects;
        this.CompanyDataService.previousUrl = previousUrl
          .split("/")
          .slice(0, 4)
          .join("/");
      });
  }

  ngOnDestroy(): void {
    localStorage.removeItem("RiskuserId");
    localStorage.removeItem("ClientData");
  }

  //#endregion

  //#region  ng OnInit
  ngOnInit(): void {

    this.companyId = Number(this.route.snapshot.paramMap.get("id"));
    // this.ClientApiService.GetClientById(this.companyId).subscribe(
    //   (companyRes: any) => {
    //     this.CompanyDataService.CompanyInfo.CompanyIcon =
    //       companyRes.data.logoPath;
    //     this.CompanyDataService.CompanyInfo.CompanyName = companyRes.data.name;
    //   }
    // );
    this.CompanyDataService.CompanyInfo.CompanyId = this.companyId;
    this.pager.mobile = this.route.snapshot.paramMap.get("Mobile");
    this.pager.companyId = this.companyId;
    this.customerApiService
      .GetByMobilePagedCalls(this.pager)
      .subscribe((res: any) => {
        if (res.success ) {
          
          this.customerInfo = res.data;
          this.StartCallDate =this.customerInfo.startCallUTC;
          this.callDetails = res.data.customerCalls.items;
          this.totalCount = res.data.customerCalls.totalCount;
          this.StartCallDate=res.data.startCallUTC;
   
          this.customerInfo.dateOfBirth = this.datepipe.transform(
            this.customerInfo.dateOfBirth,
            "yyyy-MM-dd"
          );
          this.customerInfo["mobile1"] = this.customerInfo.customerPhones[0];
          this.customerInfo["mobile2"] = this.customerInfo.customerPhones[1];
          this.customerInfo["mobile3"] = this.customerInfo.customerPhones[2];
          this.EmployeeForm.get('gender').setValue(this.customerInfo.gender)
          this.EmployeeForm.get('gender').updateValueAndValidity()
          this.EmployeeForm.get('sourceOfMarketingId').setValue(this.customerInfo.sourceOfMarketingId)
          this.EmployeeForm.get('sourceOfMarketingId').updateValueAndValidity()
          
          if (this.customerInfo.governoratId != null) {
            this.GetCities(this.customerInfo.governoratId);
          }
          this.addcustomer = false;
        } else {
          // this.customerInfo.mobile=this.passdata;
          this.StartCallDate=res.data.startCallUTC;
          this.customerInfo.mobile = this.route.snapshot.paramMap.get("Mobile");

          this.addcustomer = true;
        }
      });

    this.callDetails = JSON.parse(localStorage.getItem("Risk_CallDetals"));
    this.getGovernoate();

    this.GetCallReason(this.companyId);
    this.InitCallForm();

    

    // this.formvalue = new FormGroup({
    //   name: new FormControl(Validators.required),
    //   governoratId: new FormControl(Validators.required),
    //   cityId: new FormControl(),
    //   address: new FormControl(Validators.required),
    //   gender: new FormControl(Validators.required),
    //   mobile: new FormControl(Validators.required),
    //   mobile1: new FormControl(""),
    //   mobile2: new FormControl(""),
    //   mobile3: new FormControl(""),
    //   dateOfBirth: new FormControl(""),
    //   sourceOfMarketingId: new FormControl(Validators.required),

    //   customerPhones: this._formBuilder.array([
    //     // this._formBuilder.group ({
    //     new FormControl(),
    //     new FormControl(),
    //     new FormControl(),
    //     // })
    //   ]),
    // });

    // if(this.customerApiService.CustomerData != null){
    // this.customerApiService.CustomerData =JSON.parse(localStorage.getItem("Risk_Customer_Data")) ;
    // this.formvalue.contains(this.customerApiService.CustomerData)

    this.InitCallForm();
    // this.Governorate = "أختر المحافظة";

    this._InsertCall.startCall =
      new Date().toLocaleDateString() + "" + new Date().toLocaleTimeString();
  }
  pageChanged(event: any) {
    this.pageNumber = event.page; // -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.pager.mobile = this.route.snapshot.paramMap.get("Mobile");
    this.pager.companyId = this.companyId;
    this.customerApiService
      .GetByMobilePagedCalls(this.pager)
      .subscribe((res: any) => {
        if (res.data != null) {
          this.customerInfo = res.data;
          this.callDetails = res.data.customerCalls.items;
          this.totalCount = res.data.customerCalls.totalCount;

          this.customerInfo.dateOfBirth = this.datepipe.transform(
            this.customerInfo.dateOfBirth,
            "yyyy-MM-dd"
          );
          this.customerInfo["mobile1"] = this.customerInfo.customerPhones[0];
          this.customerInfo["mobile2"] = this.customerInfo.customerPhones[1];
          this.customerInfo["mobile3"] = this.customerInfo.customerPhones[2];
          if (this.customerInfo.governoratId != null) {
            this.GetCities(this.customerInfo.governoratId);
          }
          this.addcustomer = false;
        } else {
          this.customerInfo.mobile = this.passdata;
          this.addcustomer = true;
        }
      });
  }

  //#endregion

  // #region  Init Form
  InitForm(data?: any) {
    this.EmployeeForm = this._formBuilder.group({
      name: [, Validators.required],
      gender: [, Validators.required],
      mobile: [
        '',Validators.required,
      ],
      mobile1: [''],
      mobile2: [''],
      mobile3: [''],
      cityId: [
'',Validators.required,
      ],
      address: [
        '',
        Validators.required,
      ],
      governoratId: [
        '',
        Validators.required,
      ],
      sourceOfMarketingId: [
        '',
        Validators.required,
      ],
      dateOfBirth: [
      '',
       
      ],
    });

  //   if (this.customerApiService?.CustomerData["gender"] == 1)
  //     this.EmployeeForm.patchValue({ Gender: "ذكر" });
  //   else this.EmployeeForm.patchValue({ Gender: "أنثى" });
   }

   get fc() {
    return this.EmployeeForm.controls;
  }

  //#region Form
  InitCallForm() {
    this.Form = this._formBuilder.group({
      reason: [, Validators.nullValidator],
      callType: [, Validators.required],
      description: [""],
      notes: [, Validators.nullValidator],
      // callType: [, Validators.required],
      sourceMarketId: [, Validators.required],
      satisfy: [, Validators.nullValidator],
      // start: [, Validators.required],
      // end: [, Validators.required],
      callReasonId: [, Validators.nullValidator],
      followUpDate: [,],
    });
  }
  insertCustomerss() {
    
    this.addcustomerDisapled = true;
this.customerPhones=[]
    this.customerPhoneslenght = this.customerInfo.customerPhones.length;
    if (this.EmployeeForm.get("mobile1").value) {
      this.customerPhones.push(this.EmployeeForm.get("mobile1").value) ;
    }
    if (this.EmployeeForm.get("mobile2").value) {
      this.customerPhones.push(this.EmployeeForm.get("mobile2").value) ;
    }
    if (this.EmployeeForm.get("mobile3").value) {
      this.customerPhones.push(this.EmployeeForm.get("mobile3").value) ;
    }
    this.customerInfo.customerPhones = this.customerPhones;

    this.customerApiService.InsertCustomer(this.customerInfo).subscribe(
      (response) => {
        if (response.success) {
          Swal.fire({
            icon: "success",
            title: "تم الاضافه",

            timer: 1500,
          });
          this.addcustomer = false;
          this.customerInfo = response.data;
          this.customerInfo["mobile1"] = this.customerInfo.customerPhones[0];
          this.customerInfo["mobile2"] = this.customerInfo.customerPhones[1];
          this.customerInfo["mobile3"] = this.customerInfo.customerPhones[2];
          this.customerInfo.dateOfBirth = this.datepipe.transform(
            this.customerInfo.dateOfBirth,
            "yyyy-MM-dd"
          );
    
        } else {
          Swal.fire({
            icon: "error",
            title: response.Message,
            text: response["message"],
          });
          this.addcustomerDisapled = false;
          localStorage.setItem("data", response.data.id);
        }
      },
      (error) => {
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: "هناك خطأ ! تأكد من إدخال البيانات بشكل كامل ثم حاول مرة اخرى ",
        });
        this.addcustomerDisapled = false;
      }
    );
  
  
}

  //#region Update Client
  UpdateClientType() {
    this.editcustomerDisapled = true;
this.customerPhones=[]

    if (this.EmployeeForm.get("mobile1").value) {
      this.customerPhones.push(this.EmployeeForm.get("mobile1").value) ;
    }
   

    if (this.EmployeeForm.get("mobile2").value) {
      this.customerPhones.push(this.EmployeeForm.get("mobile2").value) ;

    }

    if (this.EmployeeForm.get("mobile3").value) {
      this.customerPhones.push(this.EmployeeForm.get("mobile3").value) ;
    }

    this.customerInfo.customerPhones = this.customerPhones;
    this.customerApiService.UpdateCustomer(this.customerInfo).subscribe(
      (response: any) => {
        Swal.fire({
          icon: "success",
          title: "تم تعديل بيانات العميل بنجاح",
          showConfirmButton: false,
          timer: 1500,
        });
        this.editcustomerDisapled = false;
      },
      (err) => {
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: "هناك خطأ ! تأكد من إدخال البيانات بشكل كامل ثم حاول مرة اخرى ",
        });
        this.editcustomerDisapled = false;
      }
    );
  }
  //#endregion

  //#region  get Governoate
  getGovernoate() {
    this.governorateApiService.GetGovernorate().subscribe((response) => {
      // console.log("-----",response.data);

      this.Governorate_List = response.data;
      response.data.forEach((element) => {
        this.Governorate_Dictionary[element.id] = element.title;
      });
    });
  }
  //#endregion

  submitCall() {
    let acivePMCheck = <HTMLInputElement>document.getElementById("pmActive");
    if (acivePMCheck.checked != true) {
      this._InsertCall.satisfy = false;
    } else {
      this._InsertCall.satisfy = true;
    }

    this._InsertCall.startCall = this.StartCallDate;
   
 
    

    this._InsertCall.createdBy = parseInt(localStorage.getItem("RiskuserId"));

    this._InsertCall.reason = this.Form.get("reason")?.value;
    this._InsertCall.description = this.Form.get("description")?.value;
    this._InsertCall.notes = this.Form.get("notes").value;
    this._InsertCall.customerId = this.customerInfo.id;
    this._InsertCall.companyId = this.companyId;
    if (this.Form.get("followUpDate").value) {
      this._InsertCall.followUpDate = new Date(
        this.Form.get("followUpDate").value
      );
    } else {
      this._InsertCall.followUpDate = null;
    }
    if (!(this._InsertCall.callType == 1 || this._InsertCall.callType == 2)) {
      Swal.fire({
        icon: "error",
        title: "خطأ",
        text: "أختر نوع المكالمة",
      });
    } else if (this._InsertCall.sourceMarketId == null) {
      Swal.fire({
        icon: "error",
        title: "خطأ",
        text: "أختر مصد اعلان",
      });
    } else if (this._InsertCall.callReasonId == null) {
      Swal.fire({
        icon: "error",
        title: "خطأ",
        text: "أختر سبب المكالمه",
      });
    } else {
      this.callApiService.InsertCall(this._InsertCall).subscribe(
        (response) => {
          Swal.fire({
            title: "تم حفظ ملخص المكالمه",
            text: "هل تريد التبرع",
            showCancelButton: true,
            confirmButtonText: "نعم",
            cancelButtonText: "لا",
            showLoaderOnConfirm: true,
            preConfirm: (id) => {
              this.passdata = id;
              localStorage.setItem(
                "apeersearch",
                JSON.stringify(response["data"])
              );
              this.callid = response.data.id;

              this.router.navigateByUrl("content/agent/CustomerDonation", {
                state: {
                  customerid: this.customerInfo.id,
                  callid: this.callid,
                },
              });
            },
          }).then((result) => {
            if (result.isDismissed) {
              var pagedata={page:1};
              this.pageChanged(pagedata);
              // response["data"]["callReasonTitle"] = this.CallReason_List.filter(
              //   (x) => x.id == response["data"].callReasonId
              // )[0].title;
              // response["data"]["sourceMarketName"] =
              //   this.SourceMarket_List.filter(
              //     (x) => x.id == response["data"].sourceMarketId
              //   )[0].title;
              // response["data"]["createdByName"] = JSON.parse(
              //   localStorage.getItem("RiskCurrentUser")
              // ).name;
              // response["data"]["companyName"] =
              //   this.CompanyDataService.CompanyInfo.CompanyName;
              // response["data"]["creationDate"] = Date.now();
              // if (this.callDetails) {
              //   this.callDetails.unshift(response["data"]);
              // } else {
              //   this.callDetails = [];
              //   this.callDetails.push(response["data"]);
              // }
              this.customerApiService
              .GetByMobilePagedCalls(this.pager)

              this._InsertCall = {};
              this.Form.reset();
            }
          });
        },
        (err) => {
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: "هناك خطأ ! تأكد من إدخال البيانات بشكل كامل ثم حاول مرة اخرى ",
          });
        }
      );
    }
  }

  //#region  get Cities
  GetCities(id) {
    this.citiesApiService.GetCitiesWithGovernorate(id).subscribe((response) => {
      this.Response_List = response.data;
      this.Filtered_List = response.data;
      // this.City = response.data[0].title
      //  let obj = response.data.filter(x=>x.id == this.customerApiService.CustomerData["cityId"] )
    });
  }

  //#region Selected Governorate
  SelectedGovernorate(event: any) {
    this.customerInfo.cityId = null;
    this.GetCities(event);
    // this.Filtered_List = this.Response_List.filter(x => x.governorateId == this.customerInfo.governoratId);
  }
  //#endregion

  back() {
    this.router.navigateByUrl("content/agent/main");
  }
  //#region  Get Call Reason
  GetCallReason(companyId) {
    this.callReasonApiService.GetCallReason(companyId).subscribe((response) => {
      this.response = response;
      this.CallReason_List = response.data;
    });
  }
  //#endregion

  //#region sourceMarketGet
  async sourceMarketGet() {
  await  this.sourceMarketApiService.Get().subscribe(async (response) => {
      this.response = await response;
      this.SourceMarket_List = response.data;
    });
  }
  //#endregion

  SelectedsourceMarket(event: any) {
    this._InsertCall.sourceMarketId = +event;
  }

  SelectedcallReason(event: any) {
    this._InsertCall.callReasonId = +event;
  }

  toggle_click() {
    if (this._InsertCall.satisfy == true) {
      this._InsertCall.satisfy = false;
    } else {
      this._InsertCall.satisfy = true;
    }

    // console.log( this._InsertCall.satisfy);
  }

  SelectedcallType(event: any) {
    this._InsertCall.callType = +event;
  }

  GetById(id) {
    this.customerApiService.GetByIdCustomer(id).subscribe((res) => {
      this.customerInfo = res.data;
      let dateOfBirth = this.datepipe.transform(
        this.customerInfo.dateOfBirth,
        "yyyy-MM-dd"
      );
    });
  }
}
