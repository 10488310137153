import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericResponse } from '../Models/GenericResponse';
import { GetCompanyType } from '../Models/GetClientType';
import { environment } from '../../../environments/environment.prod';
import { InsertClientType } from '../Models/insert-client-type';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
import { getCities } from '../Models/getCities';
import { InsertCities, UpdateCities } from '../Models/InsertCities';
import { getCitiesWithGovernorate } from '../Models/getCitiesWithGovernorate';

@Injectable({
  providedIn: 'root'
})

export class CitiesApiService {

    //#region Declare variables
    title:string;
    GovernorateId:number;
    //#endregion
    
    //#region  constructor
    constructor(private http:HttpClient) { }
    //#endregion
  
      //#region Options
      httpOptionsWithTocken = { headers: new HttpHeaders({ 'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhaG1lZGFiZHVsbGFoMjUwIiwianRpIjoiZDIwZjU0MGUtMjhiNy00YmNjLWE4ZDgtNzkxNzA2YzJmZDRhIiwiZW1haWwiOiJhaG1lZGFiZHVsbGFoQHlhaG9vLmNvbSIsInVpZCI6IjBiMzg5N2FiLTQ2ZmMtNGM0Yy04MTYyLTRiNDExZTY4OWE1NCIsInJvbGVzIjoiVVNFUiIsImV4cCI6MTYzODM2OTM3NSwiaXNzIjoiU2VjdXJlQXBpIiwiYXVkIjoiU2VjdXJlQXBpVXNlciJ9.55LorE6Fclj3buy1Qw8wZ6CEe_ifW5jxwHe25wHRWsQ', 'Accept': ' */*' }) };
      //#endregion
  
      GetCities(): Observable<GenericResponse<getCitiesWithGovernorate>> {
        return this.http.get<GenericResponse<getCitiesWithGovernorate>>(`${environment.Server_URL}/City/Get`);
      }

      GetCitiesById(cityid?:number): Observable<GenericResponse<getCitiesWithGovernorate>> {
        return this.http.get<GenericResponse<getCitiesWithGovernorate>>(`${environment.Server_URL}/City/GetById?id=${cityid}`);
              }
      GetCitiesWithGovernorate(governateid?:number,data?:any): Observable<GenericResponse<getCitiesWithGovernorate>> {
        if(governateid!=null)
        return this.http.get<GenericResponse<getCitiesWithGovernorate>>(`${environment.Server_URL}/City/FilterCitiesByGovernorateId?id=${governateid}`);
        else
        return this.http.post<GenericResponse<getCitiesWithGovernorate>>(`${environment.Server_URL}/City/GetCitylist`,data);
              }

      GetSourceOfMarkting(): Observable<GenericResponse<any>> {
        return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/SourceOfMarkting/Get`);
      }
    
      InsertCities(Data:InsertCities): Observable<GenericResponseSingle<getCities>> {
        return this.http.post<GenericResponseSingle<getCities>>(`${environment.Server_URL}/City/Create`,Data);
      }
  
      UpdateCities(Data:UpdateCities): Observable<GenericResponseSingle<UpdateCities>> {
        return this.http.post<GenericResponseSingle<UpdateCities>>(`${environment.Server_URL}/City/Update/`,Data);
      }
  
      DeleteCities(CityId:number): Observable<GenericResponseSingle<InsertCities>> {
        return this.http.get<GenericResponseSingle<InsertCities>>(`${environment.Server_URL}/City/Delete?id=${CityId}`);
        // change detete
      }
}
