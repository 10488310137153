

<app-breadcrumb [title]="'إدارة موظفي ومدن التحصيل'"  [active_item]="'Form Wizard'"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding-bottom:5px !important ;">
            <div *ngIf="update; else elseBlock1">
              <h5 class="pb-2">تعديل موظف ومدينه التحصيل </h5>
              يمكنك تعديل موظف او مدينة  فى قائمة موظفي ومدن التحصيل
            </div>
          <ng-template #elseBlock1>
            <h5 class="pb-2">إضافة موظف ومدينه التحصيل </h5>
              يمكنك إضافة موظف او مدينة في  قائمة موظفي ومدن التحصيل
            </ng-template>

        </div>
        <div class="card-body" style="overflow: hidden; height: 500px" >
            <form [formGroup]="InsertForm">
              <h3 *ngIf="name" style="text-align: center;">اسم المندوب : {{ name }} </h3>
                    <!-- <div  class="form-group">
                        <label>أختر موظف التحصيل</label>
                        <ng-select  class="form-control "  formControlName="CollectorID"
                            aria-label="Default select example"  [(ngModel)]="form.CollectorID" (change)="SelectedCollector($event)">

                            <ng-option value="" selected disabled></ng-option>
                            <ng-option *ngFor="let obj of Collector_List" [selected]="update&&obj.id==CollectorID" value="{{obj.id}}">
                                <h3  class="selected-value"> {{ obj.name }} </h3>

                            </ng-option>
                        </ng-select>
  
                    </div> -->
                <!-- <div class="form-group">
                  <label >أختر المدينه</label>
                    <ng-select class="form-control"
                            formControlName="CityID"
                            aria-label="Default select example"
                            (change)="SelectedCity($event)" >

                            <ng-option value="" selected disabled></ng-option>
                            <ng-option *ngFor="let obj of CityDropdownlist" value="{{ obj.id }}">
                                <h3 class="selected-value"> {{ obj.title }} </h3>
                            </ng-option>
                    </ng-select> -->

                    <div class="row" >
<div class="col-12">
 <div class="form-group" >
                      <label> خصص مدن  لهذا المحصل </label>

                      <ng-multiselect-dropdown
                        id="validationCustom02=3"
                        formControlName="CityId"
                        placeholder="أختر  مدينه أو أكتر من القائمة"
                        [settings]="dropdownSettings"
                        [data]="CityDropdownlist"
                        [(ngModel)]="selectedCities"
                        (onSelect)="onItemSelect($event)"
                        (onSelectAll)="onSelectAll($event)"
                        (open)="onOpen()"

                      >
                      <ng-option value="{{city.id}}" *ngFor="let city of CityDropdownlist" >
                        {{ city.title }}
                      </ng-option>
                      <!-- <ng-option [value]="item.id" *ngFor="let item of CityDropdownlist">
                        {{item.title}}
                    </ng-option> -->
                      <!-- <ng-option *ngFor="let item of CityDropdownlist" [value]="item.id">{{item.cityId}}</ng-option> -->

                      <!-- <ng-option [ngValue]="city.id" *ngFor="let city of CityDropdownlist">{{city.title}}</ng-option> -->
                      </ng-multiselect-dropdown>
                     
                    </div>
</div>

                    </div>
                   
                    <div *ngIf="update; else elseBlock">
                      <a href="javascript::void(0)" class="btn btn-primary pull-right"
                  
                      [disabled]="!InsertForm.valid"
                      (click)="UpdateCollectorities()">
                      تعديل
                  </a>
                  </div>
                    <ng-template #elseBlock>
                      <a href="javascript::void(0)"
                        class="btn btn-primary pull-right"
                        (click)="InsertCollectorCity()"
                      
                        
                      >
                        إضافة
                    </a>
                    </ng-template>



              </form>
        </div>
      </div>
    </div>
  </div>
</div>