import { CommonModule, DatePipe } from "@angular/common";
import { AgentRoutingModule } from "./agent-routing.module";
import { SharedModule } from '../../shared/shared.module';
import { ArchwizardModule } from 'angular-archwizard';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { RouterModule } from "@angular/router";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgModule } from "@angular/core";
import { StatisticsAgentComponent } from './statistics-agent/statistics-agent.component';
import { DealWithCustomerComponent } from './deal-with-customer/deal-with-customer.component';
import { DisplayCustomerDataComponent } from './display-customer-data/display-customer-data.component';
import { CustomerDonationComponent } from './customer-donation/customer-donation.component';
import { AchievementsComponent } from './achievements/achievements.component';
import { NewCusomterDonationComponent } from './new-cusomter-donation/new-cusomter-donation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from "ngx-pagination";
import { NgSelectModule } from "@ng-select/ng-select";
import { CommentModalComponent } from './comment-modal/comment-modal.component';
import { FollowupCallStatsComponent } from './follow-up-calls-stats/followup-call-stats/followup-call-stats.component';
import { FollowupCallStatDetailsComponent } from './follow-up-calls-stats/followup-call-stat-details/followup-call-stat-details.component';
import { FollowUpCallsStatsAdminComponent } from './follow-up-calls-stats/follow-up-calls-stats-admin/follow-up-calls-stats-admin.component';
import { FollowUpCallStatsdetailsAdminComponent } from './follow-up-calls-stats/follow-up-call-statsdetails-admin/follow-up-call-statsdetails-admin.component';



@NgModule({
  declarations: [
   
    StatisticsAgentComponent,
            DealWithCustomerComponent,
            DisplayCustomerDataComponent,
            CustomerDonationComponent,
            NewCusomterDonationComponent,
            AchievementsComponent,
            CommentModalComponent,
            FollowupCallStatsComponent,
            FollowupCallStatDetailsComponent,
            FollowUpCallsStatsAdminComponent,
            FollowUpCallStatsdetailsAdminComponent
  ],
  imports: [
  
  CommonModule,
    AgentRoutingModule,
    SharedModule,
    ArchwizardModule,
    SweetAlert2Module,
    NgxPaginationModule,
    RouterModule,
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule
  ],
  exports:[]
})
export class AgentModuleModule { }
