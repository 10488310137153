<app-breadcrumb [title]="'قائمة المشروعات'" ></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header px-0" style="padding-bottom: 5px;">
              <div class="row m-0" >
                  <div class="col-12 col-md-9">
                    <h5 class="py-2">المشروعات</h5>
                    <span  >  إعدادت المشروعات موجودة بالنظام ( عرض - تعديل - حذف ) </span>
                  </div>
                  <!-- <div class="col-12 col-md-3 d-flex justify-content-end align-items-center p-0">
                      <div appActionNew class="addnew-btn" (click)="AddNew()">
                           
                            <i class="fa fa-plus "></i> <span class="mt-1">جديد</span>
                            <span class="tooltiptext">  إضافة سبب جديدة</span>
                      </div>
                  </div> -->
              </div>
              <div class="card-body">
                <form class="needs-validation"  [formGroup]="SearchForm" (ngSubmit)="  onSubmit()" >
                    <div class="form-row">
                      <div class="col-lg-4 col-md-6 mb-3">
                        <label >التاريخ من </label>
                        <input type="date" formControlName="DateFrom" class="form-control"  >
    
                    </div>
                    <div class="col-lg-4 col-md-6 mb-3">
                      <label >التاريخ الي </label>
                      <input type="date" formControlName="DateTo" class="form-control" >
    
                  </div>
                  <div class="col-lg-4 col-md-6 mb-3">
                    <label >أختر الشركة </label>
                    <ng-select class="js-example-basic-single"

                            formControlName="CompanyId"
                            aria-label="Default select example"
                            (change)="SelectCompany($event.id)"
                            required="required"
                            [items]="Companies"
                            bindLabel="name"
                            bindValue="id"
                             >

                    </ng-select>
                </div>
                        <div class="col-lg-4 col-md-6 mb-3">
                        <label for="validationCustom02">     موبايل العميل  </label>
              
                          
                            <input class="form-control"
                             id="validationCustom02" type="text"
                              placeholder="   موبايل العميل   " required=""
                            formControlName="customerMobile" onlyNumbers><!-- onlyAlphabets -->
                    </div>
                    <div class="col-lg-4 col-md-6 mb-3">
                        <label for="validationCustom02">    رقم التبرع  </label>
                  
                              
                        <input class="form-control"
                         id="validationCustom02" type="text"
                          placeholder="  رقم التبرع   " required=""
                        formControlName="donationSerial" ><!-- onlyAlphabets -->
                      
                      
                    </div>
                    <div class="col-lg-4 col-md-6 mb-3">
                      <label >أختر المشروع </label>
                      <ng-select class="js-example-basic-single"
  
                              formControlName="SectorId"
                              aria-label="Default select example"
                              (change)="SelectSector($event.id)"
                              required="required"
                              [items]="Sectors"
                              bindLabel="title"
                              bindValue="id"
                               >

                      </ng-select>
                  </div>
                  <div class="col-lg-4 col-md-6 mb-3">
                    <label >أختر  نوع المشروع </label>
                    <ng-select class="js-example-basic-single"
                    
                            formControlName="SectorTypeId"
                            aria-label="Default select example"
                            (change)="SelectedSectorType($event.id)"
                            required="required"
                            [items]="Sector_Type_List"
                            bindLabel="title"
                            bindValue="id"
                             >

                    </ng-select>
                </div>
                    <div class="col-lg-4 col-md-6 mb-3">
                        <label >أختر  مكان المشروع </label>
                        <ng-select class="js-example-basic-single"
    
                                formControlName="SectorLoctaionId"
                                aria-label="Default select example"
                                (change)="SelectedSectorType($event.id)"
                                required="required"
                                [items]="SectorLocation"
                                bindLabel="title"
                                bindValue="id"
                                 >

                        </ng-select>
                    </div>
                    <div class="col-lg-4 col-md-6 mb-3">
                      <label >تم الاتصال بالعميل ؟ </label>
                      <ng-select class="js-example-basic-single"
  
                              formControlName="CallCustomer"
                              aria-label="Default select example"
                              (change)="SelectedCallCustomerList($event.id)"
                              required="required"
                              [items]="CallCustomerList"
                              bindLabel="title"
                              bindValue="id"
                               >

                      </ng-select>
                  </div>
           
              
                          </div>
                         
                    <div >
    <button  class="btn btn-primary pull-right" 
                        type="submit"
                       
                       >
                        بحث
                        <!-- (click)="InsertSectorTypes()" -->
                     </button>
                    </div>
                   
                  </form>
            </div>
          </div>
          <div class="table-responsive ">
            <table class="table " style="width: 100%;">
              <thead>
                <tr class="bg-dark ">
                  <th scope="col" class="font-style-header"  style="color: white;" >#</th>
                  <th scope="col" class="font-style-header"  style="color: white;" >ش</th>
                  <th scope="col" class="font-style-header"   style="color: white;" >  رقم التبرع </th>
                  <th scope="col" class="font-style-header"  style="color: white;" > اسم العميل</th>
                  <th scope="col" class="font-style-header"  style="color: white;" > موبايل العميل</th>
                  <th scope="col" class="font-style-header"  style="color: white;" > المبلغ</th>
                  <th scope="col" class="font-style-header"  style="color: white;"> المدينه</th>
                  <th scope="col" class="font-style-header"  style="color: white;" > تاريخ التحصيل</th>
                  <th scope="col" class="font-style-header"  style="color: white;" > تاريخ الانجاز</th>
                  <th scope="col" class="font-style-header"  style="color: white;" > نوع التبرع</th>
                  <th scope="col" class="font-style-header"  style="color: white;" >  النشاط</th>
                  <th scope="col" class="font-style-header"  style="color: white;" > نوع النشاط</th>
                  <th scope="col" class="font-style-header"  style="color: white;" > مكان المشروع</th>
                  <th scope="col" class="font-style-header"  style="color: white;">الانجازات</th>
                </tr>
              </thead>
              <tbody >
                <tr *ngFor="let item of Response_List; let i = index;">
                  <td scope="row" class="font-style">{{i+1}}</td>
                  <td scope="row" class="font-style"><img width="30px" height="30px" [src]="imgUrlServer+item.companyIcon"></td>
                  <td  class="font-style">{{item.donationSerial}}</td>
                  <td  class="font-style">{{item.customerName}}</td>
                  <td  class="font-style">{{item.customerMobile}}</td>
                  <td  class="font-style">{{item.smallAmount}}</td>
                  <td  class="font-style">{{item.cityName}}</td>
                  <td  class="font-style">{{item.collectedDate | date:"dd/MM/yyyy"}}</td>
                  <td  class="font-style">{{item.accomplishmentDate | date:"dd/MM/yyyy"}}</td>
                  <td  class="font-style">{{item.donationTypeName}}</td>
                  <td  class="font-style">{{item.sectorName}}</td>
                  <td  class="font-style">{{item.sectorTypeName}}</td>
                  <td  class="font-style">{{item.sectorLocationName}}</td>
                  <td class="font-style" style="text-align: center;" [ngStyle]="{ 'background-color': item.companyColor  }"  >
                        <!-- <button appActionDelete href=""style="color: red; padding: 7px; background-color: transparent;" (click)="Delete(item.id)">
                          <i class="fa fa-trash"></i>
                        </button>
                        <button appActionEdit href=""style="color: rgba(35, 118, 241, 0.667);padding: 7px; background-color: transparent;"(click)="update(item.id,item.order, item.title,item.companytype)">
                          <i class="fa fa-pencil"></i>
                        </button> -->

                        <!-- <button  style="color: red; padding: 7px;background-color: transparent;"
                        [routerLink]='["/content/agent/Achievements",item.donationId]' toolTipe="انجازات">
                        <i class="fa fa-address-card-o"></i>
                      </button> -->

                      <button  style="padding: 7px;background-color: transparent;" [ngStyle]="{'color': !item.canAddAccomplishment  ? 'darkgray':'red' }"
                      (click)="GoToCustomerAccomplishment(item.donationId,imgUrlServer+item.companyIcon,item.companyName,item.companyId)" [disabled]="!item.canAddAccomplishment" toolTipe="انجازات">
                      <i class="fa fa-address-card-o"></i>
                    </button>
                    </td>
                </tr>
              </tbody>
            </table>
            <br>
            <div class="d-flex justify-content-center">
              <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
                firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
                (pageChanged)="pageChanged($event)">
              </pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>