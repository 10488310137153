import { GenericResponse } from 'src/app/shared/Models/GenericResponse';
import { DonationCategory } from './../Models/donation-category';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment as evn } from "../../../environments/environment.prod";
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
@Injectable({
  providedIn: 'root'
})
export class DonationCategoryService {

  public Data = new BehaviorSubject(null);
  constructor(private http: HttpClient) {}

  get() {
    return this.http.get(
      `${evn.Server_URL}/DonationCategory/Get`
    ) as Observable<DonationCategory>;
  }
  GetDonationTypesInDonationCategory(donationCategoryId): Observable<GenericResponseSingle<any>> {
    return this.http.get<GenericResponseSingle<any>>(`${evn.Server_URL}/DonationType/GetByDonationCategoryId?donationCategoryId=${donationCategoryId}`);
  }
  GetDonationCategoryList(data:any): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(`${evn.Server_URL}/DonationCategory/GetDonationCategoryList`,data);
  }
  post(data: DonationCategory): Observable<GenericResponse<DonationCategory>> {
    return this.http.post<GenericResponse<DonationCategory>>(
      `${evn.Server_URL}/DonationCategory/Create`,
      data
    );
  }

  Put(data: any): Observable<GenericResponse<DonationCategory>> {
    return this.http.post<GenericResponse<DonationCategory>>(
      `${evn.Server_URL}/DonationCategory/Update`,
      data
    );
  }
  Delete(id: number): Observable<GenericResponse<DonationCategory>> {
    return this.http.get<GenericResponse<DonationCategory>>(
      `${evn.Server_URL}/DonationCategory/Delete?id=${id}`
    );
    // change detete
  }
}