
import { StattsReportsService } from './../../../shared/API-Service/statts-reports.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { RiskAuthenticationService } from '../../../shared/API-Service/RiskAuthentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { Roles } from 'src/app/shared/Models/Roles';
import { PermissionsApiService } from 'src/app/shared/API-Service/Permissions-api.service';
import { DatePipe } from '@angular/common';
import { NgOtpInputConfig } from 'ng-otp-input';
import { CreateSideBarMenuService } from 'src/app/shared/services/create-side-bar-menu.service';
import { async } from 'rxjs/internal/scheduler/async';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  InsertForm: FormGroup;
  err: boolean;
  MenuByRoleId: any[]=[];
  Menu:any[]=[];
  menuRolus: any[]=[];
  MenuArry: any[]=[];
  children:any[]=[];
  ActionID: any;
  showPassword: boolean = false;


  @ViewChild('ngOtpInputRef') ngOtpInputRef:any;
  config :NgOtpInputConfig = {
    allowNumbersOnly: false,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
  };
  time: number = 0;
  display ;
  interval;
  otp:string
  typeOtp:string
  showOtp:boolean=false
  showSendOtp:boolean=false

  constructor(private _formBuilder: FormBuilder,
              private Authentication:RiskAuthenticationService, 
              private toastr:ToastrService,
              private SpinnerService: NgxSpinnerService,
              private router:Router,
              private PermissionsApiService : PermissionsApiService,
              private StattsReportsService:StattsReportsService,
              public datepipe: DatePipe,
              private CreateSideBarMenuService : CreateSideBarMenuService) { }

  ngOnInit(): void {
 
    this.InitForm();

   

    
  }
  showHidePassword() {
    this.showPassword = !this.showPassword;
  }

  InitForm() {
    this.InsertForm = this._formBuilder.group({
      username: ['', Validators.required],
      passowrd: ['', Validators.required],
    });
  }

  get fc(){
    return this.InsertForm.controls
  }
  Login(){
    this.RequestLogin({userName:this.InsertForm.get('username').value,password:this.InsertForm.get('passowrd').value})
  }

     RequestLogin(obj:any){
    // this.SpinnerService.show();

       this.Authentication.RequestLogin(obj).subscribe(
      (response)=>{
        localStorage.setItem('RiskCurrentUser',JSON.stringify(response.data))
        localStorage.setItem('RiskAuthorization',response.data["token"])
        localStorage.setItem('RiskRole',response.data['roleId'] )
        localStorage.setItem('RiskuserId',response.data['id'])
        localStorage.setItem('RiskuserName',response.data['name'])
this.CreateSideBarMenu()
     
     
 
   
          
      },
      (err)=>{
       
        
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: " تأكد من كتابة أسم المستخدم و كلمة المرور بشكل صحيح ثم حاول مرة أخرى",
          
        })

      }
      
    )

  }

 async CreateSideBarMenu ()
  {
        
const data=  await this.CreateSideBarMenuService.CreateSideBarMenu()
      
if(data)
  this.router.navigate([data]);
  }




}
