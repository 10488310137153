import { Component, OnInit } from '@angular/core';
import { ReceiptReport } from '../../../../shared//Models/receipt-report';
import { Router } from '@angular/router';
import { ReceiptBookService } from 'src/app/shared/API-Service/receipt-book.service';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-receipt-report',
  templateUrl: './receipt-report.component.html',
  styleUrls: ['./receipt-report.component.css']
})
export class ReceiptReportComponent implements OnInit {
  ReportsInfo = new ReceiptReport()
  ReceiptBooks: any;
  ReceiptStatus: any;
  currentUser: any;
  Companies: any[];

  constructor(private CompanyDataService:CompanyDataService,private router: Router, private ReceiptBookService: ReceiptBookService) {
    this.currentUser = JSON.parse(localStorage.getItem("RiskCurrentUser"))
    this.ReportsInfo.reportCreatedBy = this.currentUser.name;
  }

  ngOnInit(): void {
    this.GetCompanies();

    this.GetReceiptStatus();
  }
  GetReceiptBook(companyId:any) {
    this.ReceiptBookService.GetReceiptBookByCompanyId(companyId).subscribe(res => {
      this.ReceiptBooks = res.data;
    })
  }
  GetCompanies(){
    this.CompanyDataService.GetCompanies().subscribe(res=>{
      this.Companies=res.data
    })
  }
  GetReceiptStatus() {
    this.ReceiptBookService.GetReceiptStatus().subscribe(res => {
      this.ReceiptStatus = res.data
    })
  }
  selectbook(event) {
    this.ReportsInfo.bookIds = event
    this.ReportsInfo.bookNums = this.ReceiptBooks.filter(x => x.id == event)[0].bookNumber
  }
  selectstatus(event) {
    this.ReportsInfo.receiptStatusId = event
    this.ReportsInfo.receiptStatusName = this.ReceiptStatus.filter(x => x.id == event)[0].status

  }
  SelectCompany(CompanyId){
    this.ReceiptBooks=[];
    this.ReportsInfo.bookIds=null;
      this.ReportsInfo.companyId=CompanyId;
    this.GetReceiptBook(CompanyId);
  }
  Viewreports() {
   
    if(this.ReportsInfo.companyId!=null){
    if (this.ReportsInfo.receiptStatusId == null) {
      this.ReportsInfo.receiptStatusId = null
      this.ReportsInfo.receiptStatusName = ''
    }
    if(!this.ReportsInfo.bookIds){
      this.ReportsInfo.bookNums=null
    }
    localStorage["ReportName"] = "ReceiptReport";
    localStorage.setItem('ReportFilter', JSON.stringify(this.ReportsInfo));
      
    this.router.navigate([]).then((result) => {
      window.open("/content/admin/call-start-end-report", '_blank');
    });
  }
  else{
    Swal.fire({
      icon:'error',
      title:'خطأ',
      text:'يجب اختيار الشركة'

    })
  }
  }
}
