import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericResponse } from './../Models/GenericResponse';
import { environment } from './../../../environments/environment.prod';
import { SmsTemplate } from './../Models/sms-template';
import { GetAllSector } from '../Models/get-all-sector';
import { GetSectorTypesInSector } from '../Models/GetSectorTypesInSector';
import { GetSectorLocationInSectorTypes } from '../Models/GetSectorLocationInSectorTypes';
import { GetGovernorate } from '../Models/GetGovernorate';
import { getCitiesWithGovernorate } from '../Models/getCitiesWithGovernorate';
import { GetSourceMarket } from '../Models/get-source-market';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';

@Injectable({
  providedIn: 'root'
})
export class SmsTemplateService {

  constructor(private _HttpClient: HttpClient, private router: Router) { }

  getSmsTemplates(companyId?:any): Observable<GenericResponse<any>> {
    if(!companyId){
      return this._HttpClient.get<GenericResponse<any>>(
      `${environment.Server_URL}/SMSTemplate/Get`
    );
    }
    else{
      return this._HttpClient.get<GenericResponse<any>>(
        `${environment.Server_URL}/SMSTemplate/Get?companyId=${companyId}`
      );
    }
    
  }

  GetSmsTemplatesPagintion(data:any): Observable<GenericResponse<any>> {
    return this._HttpClient.post<GenericResponse<any>>(`${environment.Server_URL}/SMSTemplate/GetPaged`,data);
  }
  PostSmsTemplates(form: any): Observable<GenericResponse<any>> {
    return this._HttpClient.post<GenericResponse<any>>(
      `${environment.Server_URL}/SMSTemplate/Create`,
      form
    );
  }

  DeleteSmsTemplate(id: number): Observable<GenericResponse<any>> {
    return this._HttpClient.get<GenericResponse<any>>(
      `${environment.Server_URL}/SMSTemplate/Delete?id=${id}`
    );
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });

  }


  GetLookupsDonationStatus(): Observable<GenericResponse<any>> {
    return this._HttpClient.get<GenericResponse<any>>(
      `${environment.Server_URL}/Lookups/GetDonationStatus`
    );
  }



  GetDonationType(): Observable<GenericResponse<any>> {
    return this._HttpClient.get<GenericResponse<any>>(`${environment.Server_URL}/DonationType/Get`);
  }

  getSectors(companyId:any): Observable<GenericResponse<GetAllSector>> {
   
    return this._HttpClient.get<GenericResponse<GetAllSector>>(
      `${environment.Server_URL}/Sector/Get?companyId=${companyId}`
    );
  }

  getSectorType(sectorid?: number): Observable<GenericResponse<GetSectorTypesInSector>> {
    if (sectorid != null)
      return this._HttpClient.get<GenericResponse<GetSectorTypesInSector>>(`${environment.Server_URL}/SectorType/GetSectorTypesInSector?id=${sectorid}`);
    else
      return this._HttpClient.get<GenericResponse<GetSectorTypesInSector>>(`${environment.Server_URL}/SectorType/Get`);



  }

  getSectorLocation(sectorTypeid?: number): Observable<GenericResponse<GetSectorLocationInSectorTypes>> {
    if (sectorTypeid != null)
      return this._HttpClient.get<GenericResponse<GetSectorLocationInSectorTypes>>(`${environment.Server_URL}/SectorLocation/GetSectorLocationInSectorTypes?id=${sectorTypeid}`);
    else
      return this._HttpClient.get<GenericResponse<GetSectorLocationInSectorTypes>>(`${environment.Server_URL}/SectorLocation/Get`);


  }
  GetGovernorate(): Observable<GenericResponse<GetGovernorate>> {
    return this._HttpClient.get<GenericResponse<GetGovernorate>>(`${environment.Server_URL}/Governorat/Get`);
  }


  GetCitiesWithGovernorate(governateid?: number): Observable<GenericResponse<getCitiesWithGovernorate>> {
    if (governateid != null)
      return this._HttpClient.get<GenericResponse<getCitiesWithGovernorate>>(`${environment.Server_URL}/City/FilterCitiesByGovernorateId?id=${governateid}`);
    else
      return this._HttpClient.get<GenericResponse<getCitiesWithGovernorate>>(`${environment.Server_URL}/City/FilterCitiesByGovernorateId`);
  }

  GetSourceMarket(): Observable<GenericResponse<GetSourceMarket>> {
    return this._HttpClient.get<GenericResponse<GetSourceMarket>>(`${environment.Server_URL}/SourceOfMarkting/Get`);
  }

  GetDonatorCustomerPhones(data): Observable<GenericResponse<GenericResponseSingle<any>>> {
    return this._HttpClient.post<GenericResponse<GenericResponseSingle<any>>>(`${environment.Server_URL}/Stats/GetDonatorCustomerPhones`, data);
  }
  SendBulkSMS(data): Observable<GenericResponse<GenericResponseSingle<any>>> {
    return this._HttpClient.post<GenericResponse<GenericResponseSingle<any>>>(`${environment.Server_URL}/SendSMS/SendBulkSMS`, data);
  }
  SendSMS(data): Observable<GenericResponse<GenericResponseSingle<any>>> {
    return this._HttpClient.post<GenericResponse<GenericResponseSingle<any>>>(`${environment.Server_URL}/SendSMS/SendSMS`, data);
  }
}

