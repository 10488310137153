
import { stringify } from '@angular/compiler/src/util'; 
import { Component, OnInit } from '@angular/core'; 
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; 
import { ActivatedRoute, Router } from '@angular/router'; 
import { ToastrService } from 'ngx-toastr'; 
import { GovernorateApiService } from 'src/app/shared/API-Service/governorate-api.service'; 
import { GetGovernorate } from 'src/app/shared/Models/GetGovernorate'; 
import { InsertCities, UpdateCities } from 'src/app/shared/Models/InsertCities'; 
import Swal from 'sweetalert2'; 
import { CitiesApiService } from '../../../../../shared/API-Service/cities-api.service'; 
 
 
@Component({ 
  selector: 'app-insert-cities', 
  templateUrl: './insert-cities.component.html', 
  styleUrls: ['./insert-cities.component.css'] 
}) 
export class InsertCitiesComponent implements OnInit { 
 
  //#region Decalre varaibles 
  InsertForm: FormGroup; 
  maxDate: Date; 
  update: boolean; 
  validated: boolean; 
  Governorate_Dictionary:{[Id:number]:string} = {} 
  Governorate_List:GetGovernorate[]; 
  Govern_id:number; 
  Governorate:string; 
  //#endregion 
 
  //#region  constructor 
  constructor(private _formBuilder: FormBuilder, 
    private toaster: ToastrService, 
    private citiesApiService: CitiesApiService, 
    private governorateApiService:GovernorateApiService, 
    private router: Router, 
    private route: ActivatedRoute) { this.maxDate = new Date(); } 
  //#endregion 
 
  //#region  ng OnInit 
  ngOnInit(): void { 
 
    this.Govern_id = -1; 
    this.getGovernoate(); 
    this.citiesApiService.title = localStorage.getItem("Governoratetitle"); 
    this.citiesApiService.GovernorateId = Number(localStorage.getItem("GovernorateId"))
    

    if (this.route.snapshot.paramMap.get('id')) { 
      this.InitForm(this.citiesApiService.title) 
      this.InsertForm.patchValue({
        GovernorateId:Number(localStorage.getItem("GovernorateId"))
      })
      this.governorateApiService.GetGovernorate().subscribe( 
        response => { 
          this.Governorate = response.data.find(x=>x.id == this.citiesApiService.GovernorateId)?.title; 
        }, 
        err => { 
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: err.error.message,
          })
        } 
      ) 
            
      this.update = true; 
    } else { 
      this.update = false; 
      this._InitForm(); 
      this.Governorate = "أختر المحافظة"; 
    } 
  } 
  //#endregion 
 
  //#region  Init Form 
  InitForm(title: string) { 
    this.InsertForm = this._formBuilder.group({ 
      Title: [title, Validators.required], 
      GovernorateId: ['-1', Validators.nullValidator], 
    }); 
  } 
  _InitForm() { 
    this.InsertForm = this._formBuilder.group({ 
      Title: ['', Validators.required], 
      GovernorateId: ['', Validators.nullValidator], 
    }); 
  } 
  //#endregion 
 
  //#region  Insert Cities Method 
  InsertCities() { 
    // console.log("this.InsertForm.get('GovernorateId') : ",this.InsertForm.get('GovernorateId').value); 
     
    if(this.Govern_id == -1){ 
      Swal.fire({ 
        icon: 'error', 
        title: 'خطأ', 
        text: "أختر المحافظة أولا", 
      }) 
    }else 
    { 
      this.citiesApiService.InsertCities({ 
        title: this.InsertForm.get('Title').value, 
        governorateId: this.Govern_id 
      } as InsertCities).subscribe( 
        response => { 
          Swal.fire({ 
            icon: 'success', 
            title: "تم إدخال المدينة بنجاح", 
            showConfirmButton: false, 
            timer: 1500 
          }) 
          this.router.navigateByUrl("content/admin/Get-cities"); 
        }, 
        err => { 
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: err.error.message,
          })
        } 
      ) 
    } 
   
  } 
  //#endregion 
 
  //#region Update Cities 
  UpdateCities() { 
    let id = +this.route.snapshot.paramMap.get('id'); 
    //    this.clientTypeApiService.UpdateClientType({Id:id,title:this.ClientTypeForm.get('firstName').value} as UpdateCompanyType).subscribe(
     this.Govern_id=this.InsertForm.get('GovernorateId').value
this.citiesApiService.UpdateCities({ 
      id: +this.route.snapshot.paramMap.get('id'), 
      title: this.InsertForm.get('Title').value, 
     // governorateId: this.Govern_id 
      governorateId:this.Govern_id
     //governorateId:this.Govern_id 
 
    } as UpdateCities).subscribe( 
      response => { 
        Swal.fire({ 
          icon: 'success', 
          title: "تم تعديل المدينة بنجاح", 
          showConfirmButton: false, 
          timer: 1500, 
         // Govern_id: response.Data.governorateId 
           
        }) 
        this.router.navigateByUrl("content/admin/Get-cities"); 
        localStorage.removeItem("Governoratetitle"); 
        localStorage.removeItem("GovernorateId"); 
      }, 
      err => { 
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      } 
    ) 
  } 
  //#endregion 
 
  //#region Selected Governorate 
  SelectedGovernorate(event:any){ 
    this.Govern_id = event; 
  } 
  //#endregion 
 
  //#region  get Governoate 
    getGovernoate() { 
      this.governorateApiService.GetGovernorate().subscribe( 
        response => { 
          this.Governorate_List = response.data; 
          response.data.forEach(element => { 
            this.Governorate_Dictionary[element.id] = element.title;             
          }); 
        }, 
        err => { 
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: err.error.message,
          })
        } 
      ) 
    } 
    //#endregion 
 

    onSubmit() {
      // this.validated = !this.validated
  
      // this.validated = !this.validated
  
      if (this.InsertForm.status == "VALID") {
        this.validated = false;
  
        if (this.update == true) {
          this.UpdateCities();
        } else {
          this.InsertCities();
        }
      } else {
        this.validated = true;
      }
    }
  
}


