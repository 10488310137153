import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SectorsApiService } from "src/app/shared/API-Service/sectors-api.service";
import Swal from "sweetalert2";
import { Roles } from "src/app/shared/Models/Roles";
import { GetAllSector } from "../../../shared//Models/get-all-sector";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';

@Component({
  selector: "app-list-sector",
  templateUrl: "./list-sector.component.html",
  styleUrls: ["./list-sector.component.css"],
})
export class ListSectorComponent extends BaseComponent implements OnInit {
  //#region  Declare Variables
  data: Array<any>;

  Sector_List:any;

  //#endregion

  //#region constructor
  constructor(
    private sectorsService: SectorsApiService,
    private router: Router,
    private CompanyDataService:CompanyDataService
  ) {
    super()
  }
  //#endregion

  //#region  ng OnInit
  ngOnInit(): void {
  this.getSectors();
  }

  pageChanged(event:any){
    var res
    let newLocl =this;
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount; 
  
  
    this.sectorsService.getSectorsList(this.pager).subscribe((res) => {
      this.Sector_List = res.data;
      this.totalCount = this.Sector_List.totalCount;
      this.Sector_List=this.Sector_List.items;
    });
    // newLocl._ShipmentService.Get(data).subscribe((res)=>{
    //   this.response=res.data;
    //   this.totalItems = this.response.totalCount;
    //   this.response=this.response.items;
    // })
  }
  getSectors(): void {

    this.sectorsService.getSectorsList(this.pager).subscribe((res) => {
      this.Sector_List = res.data;
      this.totalCount = this.Sector_List.totalCount;
      this.Sector_List=this.Sector_List.items;
    });
    // console.log(res.data)
  }
  //#endregion

  //#region Consume API's

  //#region  get Governoate

  //#endregion

  DeleteSector(id: string) {
    Swal.fire({
      title: " تحذير !",
      text: "هل انت متأكد من حذف هذا العنصر ؟ ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#7364f6',
      cancelButtonColor: "#d33",
      confirmButtonText: "حذف",
      cancelButtonText: "إنهاء",
    }).then((result) => {
      if (result.isConfirmed) {
        this.sectorsService.DeleteSector(id).subscribe(
          (response) => {
            this.getSectors();
            Swal.fire({
              icon: "success",
              title: "تم حذف الموظف بنجاح",
              showConfirmButton: false,
              timer: 1500,
            });
          },
          (err) => {
            Swal.fire({
              icon: 'error',
              title: 'خطأ',
              text: err.error.message,
            })
          }
        );
      } else {
        // Swal.fire(
        //   'Your appointment still active ',
        // );
      }
    });
  }
  // NavigateToInsert(){
  //   this.router.navigateByUrl("content/admin/Insert");
  // }
  updateSector(id: string, model: GetAllSector) {
    this.sectorsService.sectors = model;

    this.sectorsService.userData.next(model);

    localStorage.setItem(
      "risktitle",
      JSON.stringify(this.sectorsService.sectors)
    );
    localStorage.setItem(
      "risknumberofDays",
      JSON.stringify(this.sectorsService.sectors)
    );
    this.router.navigate(["content/admin/updateSector", model.id]);
  }
}
