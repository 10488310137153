import { Router } from '@angular/router';
import { ChangePassWord } from '../../../shared//Models/change-pass-word';
import { ChangepasswordService } from './../../../shared/API-Service/changepassword.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  modelChangepassword = new ChangePassWord
  passdata
  constructor(private ChangepasswordService:ChangepasswordService,private router:Router) { }

  ngOnInit(): void {
  }

  changePassword(){
    Swal.fire({
      position: 'center',   
      title: 'هل تريد تغير كلمه المرور ',
      showCancelButton: true,
      confirmButtonText: 'نعم',
      cancelButtonText: 'لا',
      showLoaderOnConfirm: true,
      preConfirm: (phone) => {
        this.passdata = phone;
          this.ChangepasswordService.changepassword(this.modelChangepassword).subscribe(response=>{
      if (response) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'تم تغير كلمه المرور',
          showConfirmButton: false,
          timer: 1500
        })
        localStorage.clear();

    this.router.navigate(["/login"]);
      }
    },err=>{
      Swal.fire({
        icon: 'error',
        title: 'رقم السر الحاليه خطا',
        text:'برجاء التأكد'
        
       
      })
     })
      }
    })
  
  }


  back(){
    history.back()
  }

}
