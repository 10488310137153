<!-- <app-breadcrumb [title]="'بيانات عميل '" [active_item]="'Form Wizard'"></app-breadcrumb> -->



<div class="container-fluid">
  <div class="row">
    
    <div class="col-12 col-md-6">
      <div class="card" style="margin-top: 30px; height: 1205px;">
        <div class="card-header" style="padding-bottom: 5px !important ">
          <div>
            <h5 class="pb-2">  بيانات العميل  </h5>
            
          </div>
        </div>
        <div class="card-body">
   
          <form class="needs-validation" [formGroup]="EmployeeForm"  >

            
            <!-- name -->
            <div class="form-group" >
              <label for="name"> أسم العميل</label>
              <input
                class="form-control"
                id="name"
                type="text"
                placeholder="الاسم "
                [(ngModel)]="customerInfo.name"
                formControlName="name"
               [value]="_InsertCustomer.name"

              />
              <!-- <ng-container
                class="invalid"
                style="color: red"
                *ngIf="
                EmployeeForm.get('name').hasError('required') &&
                EmployeeForm.get('name').touched
                "
              >
                <span style="color: red">  الاسم مطلوب </span>
              </ng-container> -->
            </div>

            <!-- Gender -->
            <div class="form-group">
              <label>أختر نوع العميل </label>
              <!-- <input
                class="form-control"
                id="gender"
                type="text"
               
                formControlName="gender"
                
              /> -->

              <ng-select name="" id="" class="js-example-basic-single"
              aria-label="Default select example"
              formControlName="gender"
              [(ngModel)]="customerInfo.gender"
              [items]="gender"
              bindLabel="title"
              bindValue="id"
              required
              >
                <!-- <ng-option value="" selected disabled>أختر نوع </ng-option>
                <ng-option value="1" > ذكر</ng-option>
                <ng-option value="2" >انثي</ng-option> -->
              </ng-select>

            </div>

            <!-- mobile -->

            <div class="form-group" >
              

              <label for="mobilenumber"> رقم الموبايل 1 / واتساب</label>
              <input
                class="form-control"
                id="mobilenumber"
                type="text"
                onlyNumbers
                [(ngModel)]="customerInfo.mobile"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="11"
                minlength="11"
                required
                formControlName="mobile"

                      
              />

         

<!-- {{customerInfo.customerPhones[2]=21212121}} -->
 




              
            
            </div>
            <div class="form-group">

              <label for="mobilenumber">رقم الموبايل 1</label>
              <input
                class="form-control"
                id="mobilenumber"
                [(ngModel)]="customerInfo.mobile1"
                type="text"
                onlyNumbers
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="11"
                minlength="11"
                formControlName="mobile1"

              />

            </div>
            <div class="form-group">

              <label for="mobilenumber">رقم الموبايل 2</label>
              <input
                class="form-control"
                id="mobilenumber"
                type="text"
                [(ngModel)]="customerInfo.mobile2"
                onlyNumbers
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="11"
                minlength="11"
                formControlName="mobile2"
              />

            </div>
            <div class="form-group">

              <label for="mobilenumber"> رقم الموبايل 3</label>
              <input
                class="form-control"
                id="mobilenumber"
                [(ngModel)]="customerInfo.mobile3"
                type="text"
                onlyNumbers
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="11"
                minlength="11"
                formControlName="mobile3"
              />

            </div>

         

            <div class="form-group">
              <label>أختر المحافظة </label>
                 
              <ng-select
              class="js-example-basic-single"
                aria-label="Default select example"
                formControlName="governoratId"
                (change)="SelectedGovernorate($event.id)"
                [items]="Governorate_List"
                bindLabel="title"
                bindValue="id"
                [(ngModel)]="customerInfo.governoratId"
            
              >
              </ng-select>
            </div>
            <!-- [(ngModel)]="customerInfo.cityId" -->
            <div class="form-group">
              <label> المدينه</label>
              <ng-select
              class="js-example-basic-single"
                aria-label="Default select example"
                formControlName="cityId"
                [(ngModel)]="customerInfo.cityId"
                [items]="Response_List"
                bindLabel="title"
                bindValue="id"
                
              >
              </ng-select>
            </div>
            <div class="form-group">
              <label> كيف</label>
              <ng-select
              class="js-example-basic-single"
                aria-label="Default select example"
                formControlName="sourceOfMarketingId"
                [items]="SourceOfMarkting"
                bindLabel="title"
                bindValue="id"
                [(ngModel)]="customerInfo.sourceOfMarketingId"
              >
              </ng-select>
            </div>

            <!-- address -->
            <div class="form-group">
              <label for="mobilenumber"> العنوان</label>
              <textarea
                formControlName="address"
                class="form-control textForArabic"
                placeholder="أكتب العنوان بالتفصيل "
                style="height: 120px"
                [(ngModel)]="customerInfo.address"
                
                 
              >
              </textarea>
            </div>

            <!-- DateOfBirth -->
            <div class="form-group">
              <label> تاريخ الميلاد </label>
              <input
                class="form-control"
                type="date"
                formControlName="dateOfBirth"
                [(ngModel)]="customerInfo.dateOfBirth"
              />
            </div>

          </form>
          <button type="submit" appActionNew *ngIf="addcustomer" [disabled]="addcustomerDisapled" class="btn bg-primary pull-right"   (click)="insertCustomerss()"  
                                  
                                  >
                              إضافة
                          </button>
          <button type="submit" appActionEdit *ngIf="addcustomer==false" [disabled]="editcustomerDisapled" class="btn bg-primary pull-right" (click)="UpdateClientType()"  
                                  
                                  >
                              تعديل
                          </button>

        </div>
      </div>
    </div>


    

    <div class="col-12 col-md-6">
      <div class="card" style="margin-top: 30px;  height: 1205px;">
        <div class="card-header" style="padding-bottom: 5px !important ">
          <h5 class="pb-2">ملخص المكالمة</h5>
        </div>
        <div class="card-body" style="padding-top: 9px;">
          <form [formGroup]="Form">

            <div class="form-group">
              <label>نوع المكالمة</label>
              <ng-select
              class="js-example-basic-single"
                formControlName="callType"
                aria-label="Default select example"
                (change)="SelectedcallType($event.id)"
                [items]="callType"
                bindLabel="title"
                bindValue="id"
                >
                <!-- <ng-option value="" selected disabled>أختر نوع المكالمة</ng-option>
                <ng-option value="1" >صادرة</ng-option>
                <ng-option value="2" >واردة</ng-option>
                #name -->
              </ng-select>
              <ng-container
              class="invalid"
              style="color: red"
              *ngIf="
                Form.get('callType').hasError('required') &&
                Form.get('callType').touched
              "
            >
              <span style="color: red"> نوع المكالمة مطلوب </span>
            </ng-container>
            </div>
            
            <div class="form-group">
              <label> سبب المكالمة</label>
              <ng-select
              class="js-example-basic-single"
                formControlName="callReasonId"
                aria-label="Default select example"
                (change)="SelectedcallReason($event.id)"
                [items]="CallReason_List"
                bindLabel="title"
                bindValue="id"
                required
              >
              </ng-select>
           
            </div>

        

            <div class="form-group">
              <label> وصف المكالمة</label>
              <!-- <input class="form-control" type="text" placeholder="أكتب تفاصيل المكالمة هنا "
                                formControlName="description"> -->
              <textarea
                formControlName="description"
                class="form-control textForArabic textearis"
                placeholder="أكتب تفاصيل المكالمة هنا "
                
           
              
              >
              </textarea>

             
            </div>

            <div class="form-group">
              <label> ملاحظات عن المكالمة</label>
              <textarea
              class="form-control textForArabic textearis"
                type="text"
                placeholder="أكتب ملاحظات المكالة هنا "
                formControlName="notes"
              >
            </textarea>
            </div>
            <div class="form-group">
              <label> اخري  </label>
              <textarea
              class="form-control textForArabic textearis"
                type="text"
                placeholder="   معلومات اضافيه "
                formControlName="reason"
              >
            </textarea>

             
            </div>

            <div class="form-group">
              <label> كيف ؟ </label>
              <ng-select
              class="js-example-basic-single"
                formControlName="sourceMarketId"
                aria-label="Default select example"
                (change)="SelectedsourceMarket($event.id)"
                [items]="SourceMarket_List"
                bindLabel="title"
                bindValue="id"
              >
              </ng-select>
            </div>
            <div class="form-group">
              <label> تاريخ المتابعه </label>
              <input
                class="form-control"
                type="date"
                formControlName="followUpDate"
                required
              />
            </div>
            <!-- [checked]="item.Active" -->
            <div class="form-group">
              <br />
              <label> تم الرد</label><br />
              <label class="switch">
                <input type="checkbox" id="pmActive" [checked]="activePM == true" />
                <span class="slider round"></span>
              </label>
            </div>

            <!--update button -->
            <div >
              <button
                class="btn bg-primary pull-right m-1"
                type="submit"
                
                (click)="submitCall()"
              >
                تسجيل
              </button>
              <button class="btn btn-primary pull-right m-1" (click)="back()">
                رجوع
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>



<!--call descration -->

<div class="container-fluid">
  <div class="card">
    <div class="card-body">
      <div class="table-responsive">
        <table class="table  table-striped" style="width: 100%;">
          <thead>
            <tr class="bg-dark " >
              <th scope="col" style="text-align: center;" class="font-style-header">#</th>
              <th scope="col" style="text-align: center;" class="font-style-header">اسم الشركة</th>
              <!-- <th scope="col" style="text-align: center;" class="font-style-header">لوجو الشركة</th> -->
              <th scope="col" style="text-align: center;" class="font-style-header">نوع المكالمه</th>
              <th scope="col" style="text-align: center;" class="font-style-header"> سبب المكالمه </th>
              <th scope="col" style="text-align: center;" class="font-style-header"> وصف المكالمه</th>
              <th scope="col" style="text-align: center;" class="font-style-header"> ملاحظات عن المكالمه </th>
              <th scope="col" style="text-align: center;" class="font-style-header">   اخري
              </th>
              <th scope="col" style="text-align: center;"  class="font-style-header"> كيف  ؟  
              </th>
              <th scope="col" style="text-align: center;"  class="font-style-header">بواسطه
              </th>
              <th scope="col" style="text-align: center;" class="font-style-header"> تاريخ  الانشاء المكالمه </th>
              <th scope="col" style="text-align: center;" class="font-style-header"> تاريخ  المتابعه </th>

         
            </tr>
          </thead>
          <tbody >
            <tr *ngFor="let item of callDetails;index as i">
                <td class="font-style" style="text-align: center;">{{i+1}}</td>
                <td class="font-style" style="text-align: center;">{{item.companyName}}</td>
                <!-- <td class="font-style" style="text-align: center;">{{item.companyIcon}}</td> -->
                <td class="font-style" style="text-align: center;">{{item.callTypeName}}</td>
               <td class="font-style" style="text-align: center;">{{item.callReasonTitle}}</td>
               <td class="font-style" style="text-align: center;">{{item.description}}</td>
               <td class="font-style" style="text-align: center;">{{item.notes}}</td>
               <td class="font-style" style="text-align: center;">{{item.reason}}</td>
               <td class="font-style" style="text-align: center;">{{item.sourceMarketName}}</td>
               <td class="font-style" style="text-align: center;">{{item.createdByName}}</td>
               <td class="font-style" style="text-align: center;">{{item.creationDate | date:'short'}}</td>
               <td class="font-style" style="text-align: center;" >{{item.followUpDate | date:"dd/MM/yyyy"}}</td>

            </tr>
          </tbody>
        </table>
        <br>
        <div class="d-flex justify-content-center">
          <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
            firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
            (pageChanged)="pageChanged($event)">
          </pagination>
        </div> 
      </div>
    </div>
  </div>
</div>