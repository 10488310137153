<app-breadcrumb *ngIf="param=='doneCount'" [title]="'مكالمات تم متابعتها'" ></app-breadcrumb>
<app-breadcrumb *ngIf="param=='notDoneCount'" [title]="'مكالمات لم تتم متابعتها'" ></app-breadcrumb>

<div class="card ">
    <div class="card-body">
  <div class="table-responsive">
    <table class="table" style="width: 100%;">
      <thead>
        <tr class="bg-dark ">
          <th scope="col" class="font-style-header text-center">#</th>
          <th scope="col" class="font-style-header text-center">ش</th>
          <th scope="col" class="font-style-header text-center">نوع المكالمة</th>
          <th scope="col" class="font-style-header text-center">سبب المكالمة</th>
          <th scope="col" class="font-style-header text-center">تاريخ بدايه المكالمة</th>
          <th scope="col" class="font-style-header text-center">تاريخ انتهاء المكالمة </th>
          <th scope="col" class="font-style-header text-center">تاريخ المتابعه </th>
          <th scope="col" class="font-style-header text-center">اسم العميل</th>
          <th scope="col" class="font-style-header text-center">رقم العميل</th>
          <th scope="col" class="font-style-header text-center">اسم موظف الشركة</th>
          <th scope="col" class="font-style-header text-center">بواسطة</th>
          <th scope="col" class="font-style-header text-center"></th>

        </tr>
      </thead>
      <tbody>
      
          <tr style="text-align: center;" *ngFor="let item of TodayFollowUpCalls; let i = index; ">
            <td scope="row" class="font-style">{{i+1}}</td>
            <td scope="row" class="font-style"><img width="30px" height="30px" [src]="imgUrlServer+item.companyIcon"></td>
            <td class="font-style">{{item.callTypeName}} </td>
            <td class="font-style">{{item.callReasonTitle}}</td>
            <td class="font-style">{{item.startCall | date:"dd/MM/yyyy"}}</td>
            <td class="font-style">{{item.endCall | date:"dd/MM/yyyy"}}</td>
            <td class="font-style">{{item.followUpDate | date:"dd/MM/yyyy"}}</td>
            <td class="font-style">{{item.customerName}}</td>
            <td class="font-style">{{item.customerMobile}}</td>
            <td class="font-style">{{item.companyEmployeeName}}</td>
            <td class="font-style">{{item.createdByName}}</td>
            <td class="font-style" style=" text-align: end; width: 8%;"  [ngStyle]="{ 'background-color': item.companyColor  }">
              <!-- <div class="form-check checkbox checkbox-solid-danger mb-3">
                <input  #checkboxRef class="form-check-input" [id]="item.id" type="checkbox"  disabled [checked]="item.followUpDone">
                <label class="form-check-label" [for]="item.id"></label>
              </div> -->
            </td>
            
          </tr>


      </tbody>
    </table>
    <br>
    <div class="d-flex justify-content-center">
      <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
        firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
        (pageChanged)="pageChanged($event)">
      </pagination>
    </div>
  </div>

</div>
    
  </div>