import { SectorLocationApiService } from '../../../../shared/API-Service/sector-location-api.service';
import { SectorTypeApiService } from '../../../../shared/API-Service/sector-type-api.service';
import { Router } from '@angular/router';
import { DonationRequestsService } from '../../../../shared/API-Service/donation-requests.service';
import { CitiesApiService } from 'src/app/shared/API-Service/cities-api.service';
import { GovernorateApiService } from 'src/app/shared/API-Service/governorate-api.service';
import { SourceOfmarketingService } from '../../../../shared/API-Service/source-ofmarketing.service';
import { DonationsReports } from '../../../../shared//Models/doantions-reports';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-small-donation-reports',
  templateUrl: './small-donation-reports.component.html',
  styleUrls: ['./small-donation-reports.component.css']
})
export class SmallDonationReportsComponent implements OnInit {
  doantionreports = new DonationsReports()
  GetLookupsGetDonation
  DonationType
  SectorGet
  Governorate_Dictionary
  getType:any = []
  SectorLocation
  Governorate_List
  City_list
  Getcollectors
  getsourcemarket
  getEmployee
  GetCustomer
  currentUser: any;
  gender: { id: number; title: string; }[];
  Companies: any[];
  
  constructor(private CompanyDataService:CompanyDataService,public datepipe:DatePipe, private SourceOfmarketingService:SourceOfmarketingService,private governorateApiService:GovernorateApiService,private citiesApiService:CitiesApiService,
     private DonationRequestsService:DonationRequestsService,private router:Router,private SectorTypeApiService:SectorTypeApiService,private SectorLocationApiService:SectorLocationApiService
    ) { 
      this.gender=[
        {
        id:1,
        title:"ذكر"
        },
        {
            id:2,
            title:"أنثى"
        }

]
      this.currentUser=JSON.parse(localStorage.getItem("RiskCurrentUser"))
      if(this.currentUser.roleId==10){
        this.doantionreports.companyId=this.currentUser.id
        this.GetSector(this.currentUser.id)
      }
      this.doantionreports.reportCreatedBy=this.currentUser.name;
      const date = new Date();
      this.doantionreports.creationDateFrom=this.datepipe.transform(date, 'yyyy-MM-dd');
      this.doantionreports.creationDateTo=this.datepipe.transform(date,'yyyy-MM-dd');
      this.doantionreports.collectionDateFrom=this.datepipe.transform(date, 'yyyy-MM-dd');
      this.doantionreports.collectionDateTo=this.datepipe.transform(date,'yyyy-MM-dd');
    }

  ngOnInit(): void {
    this.GetLookupsGetDonationStatu()
    this.GetDonationType()
    this.GetCompanies()
let c = document.getElementById("plan")
if (c != null) {

}
    this.getgevernrate()

    this.getcollectors()
    this.getsourcemarketing()
    this.Getemployee()
  }



  GetLookupsGetDonationStatu(){
    this.SourceOfmarketingService.GetLookupsGetDonationStatu().subscribe(response=>{
  
      this.GetLookupsGetDonation = response.data
    })
  }
  GetDonationType(){
    this.SourceOfmarketingService.GetDonationType().subscribe(response=>{
        this.DonationType = response.data
      
    })
  }
  GetSector(CompanyId:any){
    this.SourceOfmarketingService.getSectorsBycompanyId(CompanyId).subscribe(res=>{
     this.SectorGet = res.data
    //  res.data.forEach(element => {
    //    this.Governorate_Dictionary[element.id] = element.title;            
    //  });
    })
 }


 SelectCompany(CompanyId){
  this.doantionreports.companyId=parseInt(CompanyId);
  this.SectorGet =[];
  this.doantionreports.sectorId=null;
  this.doantionreports.sectorTypeId=null;
  this.doantionreports.sectorLocationId=null;
   this.GetSector(CompanyId);
}
GetCompanies(){
  this.CompanyDataService.GetCompanies().subscribe(res=>{
    this.Companies=res.data
  })
}
 GetSectorGetById(event:any){
  if(this.doantionreports.sectorId == null){
    this.doantionreports.sectorId = null
    this.doantionreports.sectorName = ''
    this.doantionreports.sectorTypeId = null
    this.doantionreports.sectorTypeName = ''
    this.doantionreports.sectorLocationId = null
    this.doantionreports.sectorLocationName = ''
    this.getType=[];
    this.SectorLocation=[];
  }
 else{
   this.SectorTypeApiService.getSectorType(this.doantionreports.sectorId,this.doantionreports.companyId).subscribe((response:any)=>{
    this.getType  =response.data
    
  })


  this.doantionreports.sectorId = parseInt(event)
  this.doantionreports.sectorName = this.SectorGet.find(x=>x.id == event).title
 }
  
}

GetSectorTypeGetById(event:any){
if (this.doantionreports.sectorTypeId == null) {
  this.doantionreports.sectorTypeId = null
  this.doantionreports.sectorTypeName = ''
  this.doantionreports.sectorLocationId = null
this.doantionreports.sectorLocationName = ''
this.SectorLocation=[];
}
else{


this.SectorLocationApiService.GetSectorLocationReportFilter(this.doantionreports.sectorTypeId).subscribe((response:any)=>{

  this.SectorLocation = response.data
})

this.doantionreports.sectorTypeId = parseInt(event)
 this.doantionreports.sectorTypeName = this.getType.find(x=>x.id == event).title
}
}
GetSourceGetById(event:any){
  this.doantionreports.sourceMarketId = parseInt(event.target.value)
  this.doantionreports.sourceMarketName = this.getsourcemarket.find(x=>x.id == event.target.value).title
}
getgevernrate(){
  this.governorateApiService.GetGovernorate().subscribe(response=>{
    this.Governorate_List = response.data
  })
}


createdBy(event:any){
  this.doantionreports.createdBy =parseInt(event.target.value) 
  this.doantionreports.createdByName = this.GetCustomer.find(x=>x.id == event.target.value).name
}


  SelectedGovernorate(event:any){
 if (this.doantionreports.customerGovernoratId== null) {
  this.doantionreports.customerGovernoratId=null
  this.doantionreports.customerGovernoratName=''
  this.doantionreports.customerCityId = null
  this.doantionreports.custmerCityName = ''
  this.City_list=[];
 
 } 
 else{    
   this.citiesApiService.GetCitiesWithGovernorate(this.doantionreports.customerGovernoratId)
  .subscribe(
    response => {
      this.City_list = response.data;
     
    },
  )

  this.doantionreports.customerGovernoratId = parseInt(event)
  this.doantionreports.customerGovernoratName = this.Governorate_List.find(x => x.id == event).title
}

    


  }

  SelectedCity(event:any){
    this.doantionreports.customerCityId = parseInt(event) 
    this.doantionreports.custmerCityName = this.City_list.find(x => x.id == event).title
   
  }

  getcollectors(){
    this.DonationRequestsService.Getcollectors().subscribe(response=>{
        
      this.Getcollectors = response.data
    })
  }

  getsourcemarketing(){
    this.SourceOfmarketingService.GetAllsourceOfMarketingForReport().subscribe(response=>{

          this.getsourcemarket = response.data
    })
  }
  Getemployee(){
    this.SourceOfmarketingService.EmployeeGet().subscribe(res=>{
      this.getEmployee = res.data;
    })
   }
   donationStatus(event){
       this.doantionreports.donationStatus = parseInt(event) 
       this.doantionreports.donationStatusName = this.GetLookupsGetDonation.find(x => x.id == event).title
   }

   donationTypee(event){
    this.doantionreports.donationTypeId = parseInt(event) 
    this.doantionreports.donationTypeName = this.DonationType.find(x => x.id == event).title
  
   }

   loaction(event){
    this.doantionreports.sectorLocationId = parseInt(event) 
    this.doantionreports.sectorLocationName = this.SectorLocation.find(x => x.id == event).title
   }

   sourcemarkect(event){
    this.doantionreports.sourceMarketId = parseInt(event) 
    this.doantionreports.sourceMarketName = this.getsourcemarket.find(x => x.id == event).title
   }

   getCollector(event){
    this.doantionreports.collectorId = parseInt(event) 
    this.doantionreports.collectorName = this.Getcollectors.find(x => x.id == event).name
   }
 
   GetClientType(event:any){
    this.doantionreports.customerGender = parseInt(event) 
    var type = event == 1 ? "ذكر" : "أنثى"
    this.doantionreports.customerGenderName = type
   }
   createdEmplyee(event){
    this.doantionreports.createdBy = parseInt(event) 
    this.doantionreports.createdByName = this.getEmployee.find(x => x.id == event).name
   }
   submit(){

    if (this.doantionreports.donationStatus == null) {
      this.doantionreports.donationStatus = null
      this.doantionreports.donationStatusName = ''
 
    }

    if (this.doantionreports.donationTypeId == null) {
      this.doantionreports.donationTypeId = null
      this.doantionreports.donationTypeName = ''
  
    }
      

    if (this.doantionreports.sectorId == null ) {
      this.doantionreports.sectorId=null
      
      this.doantionreports.sectorName=''

    }

    if (this.doantionreports.sectorLocationId==-1) {
      this.doantionreports.sectorLocationId=null
      this.doantionreports.sectorLocationName=''
     
    }
      
    

    if(this.doantionreports.sectorTypeId==null){
      this.doantionreports.sectorTypeId=null
      this.doantionreports.sectorTypeName=''
     
    }

   


    if (this.doantionreports.customerGender==null) {
      this.doantionreports.customerGender = null
      this.doantionreports.customerGenderName = ''
 
    }

    if (this.doantionreports.customerGovernoratId == null) {
      this.doantionreports.customerGovernoratId = null
      this.doantionreports.customerGovernoratName = ''
 
    }

    if (this.doantionreports.customerCityId == null) {
      this.doantionreports.customerCityId = null
      this.doantionreports.custmerCityName = ''
  
    }


    if (this.doantionreports.collectorId == null) {
      this.doantionreports.collectorId = null
      this.doantionreports.collectorName = ''
      
    }

    if (this.doantionreports.sourceMarketId==null) {
      this.doantionreports.sourceMarketId=null
      this.doantionreports.sourceMarketName=''
     
    }

    if (this.doantionreports.createdBy==null) {
      this.doantionreports.createdBy=null
      this.doantionreports.createdByName=''
    
    }
    localStorage["ReportName"]="SmallDonationsReport";
    localStorage.setItem('ReportFilter',JSON.stringify(this.doantionreports));
  
    this.router.navigate([]).then((result) => {
      window.open("/content/admin/call-start-end-report", '_blank');
    });
   }

   viewReport(){
if(this.doantionreports.companyId&&this.doantionreports.creationDateFrom &&this.doantionreports.creationDateTo){
this.submit();

   }
   else if (this.doantionreports.companyId!=null&&this.doantionreports.collectionDateFrom &&this.doantionreports.collectionDateTo){
    this.submit()
  
  }
  else{
    if(!this.doantionreports.companyId){
      Swal.fire({
        icon:'error',
        title:'خطأ',
        text:'يجب اختيار الشركة'
  
      })
    }
  else   if (!this.doantionreports.creationDateFrom){
      Swal.fire({
        icon:'error',
        title:'خطأ',
        text:' يجب اختيار تاريخ من والى تبرعات منشأة او محصلة'
      })
    }
    else  if (!this.doantionreports.creationDateTo ){
      Swal.fire({
        icon:'error',
        title:'خطأ',
        text:' يجب اختيار تاريخ من والى تبرعات منشأة او محصلة'
  
      })
    }
    else  if (!this.doantionreports.collectionDateFrom ){
      Swal.fire({
        icon:'error',
        title:'خطأ',
        text:' يجب اختيار تاريخ من والى تبرعات منشأة او محصلة'
  
      })
    }
    else  if (!this.doantionreports.collectionDateTo ){
      Swal.fire({
        icon:'error',
        title:'خطأ',
        text:' يجب اختيار تاريخ من والى تبرعات منشأة او محصلة'
  
      })
    }
   
  }
  }
 
 
}
