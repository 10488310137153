import {
  DonationMType,
  HttpDonationMType,
  Dletete,
} from "./../Models/donation-m-type";
import { GenericResponse } from "./../Models/GenericResponse";
import { Observable, BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment as evn } from "../../../environments/environment.prod";

import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DonationMTypeApiService {
  public Data = new BehaviorSubject(null);
  constructor(private http: HttpClient) {}
  get() {
    return this.http.get(
      `${evn.Server_URL}/DonationMainType/Get`
    ) as Observable<HttpDonationMType>;
  }
  post(data: DonationMType): Observable<GenericResponse<DonationMType>> {
    return this.http.post<GenericResponse<DonationMType>>(
      `${evn.Server_URL}/DonationMainType/Create`,
      data
    );
  }

  Put(data: any): Observable<GenericResponse<HttpDonationMType>> {
    return this.http.post<GenericResponse<HttpDonationMType>>(
      `${evn.Server_URL}/DonationMainType/Update`,
      data
    );
  }
  Delete(id: number): Observable<GenericResponse<Dletete>> {
    return this.http.get<GenericResponse<Dletete>>(
      `${evn.Server_URL}/DonationMainType/Delete?id=${id}`
    );
    // change detete
  }
}
