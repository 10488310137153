import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SectorsApiService } from "src/app/shared/API-Service/sectors-api.service";
import Swal from "sweetalert2";

import {
  InsertSector,
  UpdateSector,
} from "src/app/shared/Models/insert-sector";
import { ConstantPool } from "@angular/compiler";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';

@Component({
  selector: "app-insert-sector",
  templateUrl: "./insert-sector.component.html",
  styleUrls: ["./insert-sector.component.css"],
})
export class InsertSectorComponent implements OnInit {
  //#region Decalre varaibles
  data: any;
  isSuccess: boolean = false;
  options: Array<string> = [];
  selectorsColor: string = "insert";
  maxDate: Date;
  update: boolean;
  insertSectorForm: FormGroup;
    validated: boolean;
    dropdownSettings: IDropdownSettings = {};
  Sector:InsertSector
  id: any;
  //#region  constructor
  constructor(
    private sectorsService: SectorsApiService,
    private route: ActivatedRoute,
    private router: Router,
    private _formBuilder: FormBuilder,
    private CompanyDataService:CompanyDataService
  ) {
    this.maxDate = new Date();

    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    this.InitForm();

  }
  //#endregion
 
  //#region  ng OnInit
  ngOnInit(): void {
    
    // this.options = ["insert", "update"];
    if (this.route.snapshot.paramMap.get("id")) {
      // this.sectorsService.title = localStorage.getItem("risksector");
      // this.InitForm();
      const id =this.route.snapshot.paramMap.get("id");
      this.id=id
      this.GetSectorsByDetail(id)
      this.update = true;
    } else {
      this.update = false;

      this.InitForm();
    }
    
  }
  GetSectorsByDetail(id){
    this.sectorsService.GetSectorsByDetail(id).subscribe(async (res: any)=>{

      this.Sector=await res.data
    
      this.InitForm(this.Sector);
    })
  }

  //#region  Init Form
  InitForm(InsertSector?:InsertSector) {
    this.insertSectorForm = this._formBuilder.group({
      title: [InsertSector?.title||'', Validators.required],
     // companiesIds: [this.CompaniesselectedItems||"", Validators.required],
    });
  }
 
  
  containsNumber(str) {
    const specialChars = /[/\d/٠١٢٣٤٥٦٧٨٩]/;
    return specialChars.test(str);
  }
  //#endregion
  containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }
  InsertSector() {

// if(this.containsSpecialChars(this.insertSectorForm.get("title").value)==true){
//        Swal.fire({
//             icon: 'error',
//             title: 'خطأ',
//             text: "لا يمكن كتابه علامات ",
//           })
// }
// else if(this.containsNumber(this.insertSectorForm.get("title").value)==true){
//   Swal.fire({
//        icon: 'error',
//        title: 'خطأ',
//        text: "لا يمكن كتابه ارقام ",
//      })
// }
// else {
  const data={
    "title": this.insertSectorForm.get("title").value,
  }
   this.sectorsService
      .InsertSector(data)
      .subscribe(
        (response) => {
          Swal.fire({
            icon: "success",
            title: "تم إدخال النشاط بنجاح",
            showConfirmButton: false,
            timer: 1500,
          });
          this.router.navigateByUrl("content/admin/ListSector");
        },
        (err) => {
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: err.error.message,
          })
        }
      );
// }
   
  }

  UpdateSector() {
//     if(this.containsSpecialChars(this.insertSectorForm.get("title").value)==true){
//       Swal.fire({
//            icon: 'error',
//            title: 'خطأ',
//            text: "لا يمكن كتابه علامات ",
//          })
// }
// else if(this.containsNumber(this.insertSectorForm.get("title").value)==true){
//   Swal.fire({
//        icon: 'error',
//        title: 'خطأ',
//        text: "لا يمكن كتابه ارقام ",
//      })
// }
// else {
  const data={
    "id":this.id,
    "title": this.insertSectorForm.get("title").value,

  }
    this.sectorsService
      .UpdateSector(data)
      .subscribe(
        (response) => {
          Swal.fire({
            icon: "success",
            title: "تم تعديل  النشاط",
            showConfirmButton: false,
            timer: 1500,
          });
          this.insertSectorForm.reset();
          this.router.navigateByUrl("content/admin/ListSector");
        },
        (err) => {
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: err.error.message,
          })
        }
      );
// }


  }


  onSubmit() {
    // this.validated = !this.validated

    // this.validated = !this.validated

    if (this.insertSectorForm.status == "VALID") {
      this.validated = false;

      if (this.update == true) {
        this.UpdateSector();
      } else {
        this.InsertSector();
      }
    } else {
      this.validated = true;
    }
  }

}
