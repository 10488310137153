<app-breadcrumb
  [title]="'تقارير التبرع'"
  [items]="['Tables', 'Bootstrap Tables']"
  [active_item]="'Basic'"
>
</app-breadcrumb>

<div class="container-fluid">
  <div class="card">
    <div class="card-body">
      <div class="row">
    <div class="col-12 col-md-4">
        <div class="form-group">
            <label for="">بدايه تاريخ التبرع </label>
           <input type="date"  class="form-control" [(ngModel)]="collectorReports.collectionDateFrom">
        </div>
    </div>
    <div class="col-12 col-md-4">
        <div class="form-group">

            <label for=""> نهايه تاريخ البرع</label>

          
            <input type="date"  class="form-control"  [(ngModel)]="collectorReports.collectionDateTo">
        </div>
   </div>  
   <div class="col-12 col-md-4">
    <div class="form-group">

      <label for=""> بدايه انشاء التبرع </label>
       <input type="date"  class="form-control"  [(ngModel)]="collectorReports.creationDateFrom">

      


    </div>
</div>

<div class="col-12 col-md-4">
    <div class="form-group">

      <label for="">  نهايه انشاء التبرع </label>
       <input type="date"  class="form-control" [(ngModel)]="collectorReports.creationDateTo">

      <!-- <label for="">    الى تاريخ التبرع </label>
       <input type="date"  class="form-control" [(ngModel)]="ReportsInfo.collectionDateTo"> -->

    </div>
</div>
<div class="col-12 col-md-4">
    <label for="">حالة التبرع   </label>
      <ng-select
      class="js-example-basic-single"
      aria-label="Default select example"
      (change)="donationStatus($event)"
     [(ngModel)]="collectorReports.donationStatus"
     [items]="GetLookupsGetDonation"
     bindLabel="title"
     bindValue="id"
      >                                 
    </ng-select>
  </div>

  <div class="col-12 col-md-4">
    <label for="">نوع التبرع</label>
      <ng-select
      class="js-example-basic-single"
      aria-label="Default select example"
    [(ngModel)]="collectorReports.donationTypeId"
     (change)="donationTypee($event.id)"
     [items]="DonationType"
     bindLabel="title"
     bindValue="id"
    >
    </ng-select>
  </div>

  <div class="col-12 col-md-4">
    <label for="">قطاع التبرع</label>
      <ng-select
      class="js-example-basic-single"
      aria-label="Default select example"
      [(ngModel)]="collectorReports.sectorId"
      (change)="GetSectorGetById($event.id)" 
      [items]="SectorGet"
      bindLabel="title"
      bindValue="id"
      >
    </ng-select>
  </div>
  <div class="col-12 col-md-4 ">
    <label for="">نوع القطاع</label>
      <ng-select
      class="js-example-basic-single"
      aria-label="Default select example"
      [(ngModel)]="collectorReports.sectorTypeId"
      (change)="GetSectorTypeGetById($event.id)" 
      [items]="getType"
      bindLabel="title"
      bindValue="id"
      >
    </ng-select>
  </div>


  <div class="col-12 col-md-4 ">
    <label for="">موقع القطاع</label>
      <ng-select
      class="js-example-basic-single"
      aria-label="Default select example"
   [(ngModel)]="collectorReports.sectorLocationId"
     (change)="loaction($event.id)"
     [items]="SectorLocation"
     bindLabel="title"
     bindValue="id"
      >
    </ng-select>
  </div>
    

  

  
</div>
<div class="d-flex justify-content-end lign-items-center " >

  <button class="addnew-btn" style="margin-top: 41px;" type="submit" (click)="Viewreports()">عرض التقرير</button>
</div>
    </div>
  </div>
  
</div>

