import { Component, OnInit } from '@angular/core';
import { stringify } from '@angular/compiler/src/util';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SectorTypeApiService } from 'src/app/shared/API-Service/sector-type-api.service';
import { GetAllSector } from 'src/app/shared/Models/get-all-sector';
import{getSectorType} from 'src/app/shared/Models/get-sector-type'
import { insertSectorType,UpdateSectorType } from 'src/app/shared/Models/insert-sector-type';
import Swal from 'sweetalert2';
import { SectorsApiService } from '../../../../../shared/API-Service/sectors-api.service';
import { EmployeeApiService } from 'src/app/shared/API-Service/employee-api.service';
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';
@Component({
  selector: 'app-insert-sector-type',
  templateUrl: './insert-sector-type.component.html',
  styleUrls: ['./insert-sector-type.component.css']
})
export class InsertSectorTypeComponent implements OnInit {
  InsertForm: FormGroup;
  maxDate: Date;
  update: boolean;
  Sector_Dictionary:{[Id:number]:string} = {}
  insertSectorType= new insertSectorType();
  Sector_List:GetAllSector[];
  Sector:string;
  returnobj:any={};
  dropdownSettings: IDropdownSettings = {};


  // validated 
  validated:boolean=false
  ProjectManagerEmployees:any[]=[];
  // Employees: import("/Users/ahmedmahmoud/Desktop/My Projects/wecancity/risk-new/Untitled/Risk_Front/src/app/shared/Models/GetEmployee").GetEmployee[];
  Employee_id: any;
  managersIds: any[]=[];
  ProjectManagerEmployeesForEdit: any[]=[];
  Companies: any[];
  constructor(private _formBuilder: FormBuilder,
    private toaster: ToastrService,
    private sectorTypeApiService: SectorTypeApiService,
    private sectorsApiService:SectorsApiService,
    private router: Router,
    private route: ActivatedRoute,
    private EmployeeApiService:EmployeeApiService,
    private CompanyDataService :CompanyDataService
    ) { this.maxDate = new Date();
      this.getSector();
      this.GetCompanies();
      this.GetProjectManagerEmployees(); }
  ngOnInit(): void {
   
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    this.InsertForm = this._formBuilder.group({
      Title: ['', Validators.required],
      numberofDays: ['', Validators.required],
      CompanyId:[null,Validators.required],
      SectorId: ['', Validators.nullValidator],
      employeeId: ['', Validators.required],
      Cost: ['', ],
    });
    
    
    if (this.route.snapshot.paramMap.get('id')) {
      let id=this.route.snapshot.paramMap.get('id')
      this.getById(id)
      this.update = true;
    } else {
      this.update = false;
      this._InitForm();
      this.Sector = "أختر نشاط التبرع";
    }
  }
  SelectCompany(CompanyId){
    // this.getSector(CompanyId);
    this.insertSectorType.companyId = CompanyId;
  }
  GetCompanies(){
    this.CompanyDataService.GetCompanies().subscribe(res=>{
      this.Companies=res.data
    })
  }
  _InitForm() {
    this.InsertForm = this._formBuilder.group({
      Title: ['', Validators.required],
      numberofDays: ['', Validators.required],
      CompanyId:[null,Validators.required],
      SectorId: ['', Validators.nullValidator],
      employeeId: ['', Validators.required],
      Cost: ['', ],
    });
  }
  getSector(CompanyId?:any) {
    
    this.sectorsApiService.getSectors(CompanyId).subscribe(
      response => {
        this.Sector_List = response.data;
        response.data.forEach(element => {
          this.Sector_Dictionary[element.id] = element.title;            
        });
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }

  async GetProjectManagerEmployees(): Promise<void> {
    await new Promise<void>((resolve) => {
      this.EmployeeApiService.GetProjectManagerEmployees().subscribe(res => {
        this.ProjectManagerEmployees = res.data;
        resolve(); // Resolve the promise once data is populated
      });
    });
  }
      SelectedEmployee(event:any){
        this.insertSectorType.managerId = event;
      }
      SelectedSector(event:any){
        this.insertSectorType.sectorId = event;
      }
  InsertSectorTypes() {

      this.InsertForm.get("employeeId").value.forEach(element => {
        this.managersIds.push(element.id)
      });
      this.insertSectorType.managersIds=this.managersIds
      
      this.sectorTypeApiService.InsertSectorTypes(this.insertSectorType as insertSectorType).subscribe(
        response => {
          Swal.fire({
            icon: 'success',
            title: "تم إدخال نوع النشاط بنجاح",
            showConfirmButton: false,
            timer: 1500
          })
          this.router.navigateByUrl("content/admin/Get-sector-type");
        },
        err => {
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: err.error.message,
          })
        }
      )
    // }
  }
  //#region Selected Governorate
 
  UpdateSectorType() {
  this.InsertForm.get("employeeId").value.forEach(element => {
    this.managersIds.push(element.id)
  });
  this.insertSectorType.managersIds=this.managersIds  
  this.sectorTypeApiService.UpdateSectorTypes(this.insertSectorType as UpdateSectorType).subscribe(
      response => {
        Swal.fire({
          icon: 'success',
          title: "تم تعديل نوع النشاط  بنجاح",
          showConfirmButton: false,
          timer: 1500,
          
        })
        this.router.navigateByUrl("content/admin/Get-sector-type");
        localStorage.removeItem("Sectortitle");
        localStorage.removeItem("SectorId");
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }

async getById(id){
  await this.GetProjectManagerEmployees();
  var res;
  this.sectorTypeApiService.GetSectorTypeById(id).subscribe({
    next: (respnose) => {
      res = respnose;
      this.insertSectorType=res.data
      const managersIds = res.data.managersIds;
      // if(this.ProjectManagerEmployees.length==0){
      //   this.GetProjectManagerEmployees()
      // }
     
      managersIds.forEach(element => {
         const managers = this.ProjectManagerEmployees.filter(x=>x.id==element);
         if(managers.length!=0){
                    this.ProjectManagerEmployeesForEdit.push(managers[0])
                    this.InsertForm.patchValue({
                      employeeId:this.ProjectManagerEmployeesForEdit
                      })
         }
      });
      if(this.ProjectManagerEmployeesForEdit.length!=0){
      this.InsertForm.patchValue({
        employeeId:this.ProjectManagerEmployeesForEdit
        })
      }
      this.InsertForm.patchValue({
        CompanyId:res.data.companyId
      })
     
        this.InsertForm.patchValue({
          SectorId:this.insertSectorType.sectorId
          })
          
    },
  });


 
}

  onSubmit(): void {
    // this.validated = !this.validated

    // this.validated = !this.validated

    
  
    this.validated = true;
    if (this.InsertForm.status == "VALID") {
      this.validated = false;

      if (this.update == true) {
        this.UpdateSectorType();
      } else {
        this.InsertSectorTypes();
      }
    } else {
      this.validated = true;
    }
  }

}