import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { DontaionsApiService } from 'src/app/shared/API-Service/donations-api-service';
import { SectorsApiService } from 'src/app/shared/API-Service/sectors-api.service';
import { SectorTypeApiService } from 'src/app/shared/API-Service/sector-type-api.service';
import { SectorLocationApiService } from 'src/app/shared/API-Service/sector-location-api.service';
import { SourceMarketApiService } from 'src/app/shared/API-Service/source-market-api.service';
import { CollectorApiService } from 'src/app/shared/API-Service/collector-api.service';
import { DonationCategoryService } from 'src/app/shared/API-Service/donation-category.service'
import { DOCUMENT, DatePipe, Location } from '@angular/common';
import { SmsTemplateService } from 'src/app/shared/API-Service/sms-template.service';
import {AppComponent} from '../../../app.component'

import Swal from 'sweetalert2';
import { CollectorManager } from 'src/app/shared/Models/collector-manager'
import { WizardComponent } from 'angular-archwizard';
import { filter } from 'rxjs/operators';
import { of } from 'rxjs';
import { ThrowStmt } from '@angular/compiler';
import { SendSMSComponent } from '../../admin/send-sms/send-sms.component';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment.prod';
import { ReceiptBookService } from '../../../shared/API-Service/receipt-book.service';
import {CompanyDataService} from 'src/app/shared/API-Service/company-data.service'

@Component({
  selector: 'app-new-cusomter-donation',
  templateUrl: './new-cusomter-donation.component.html',
  styleUrls: ['./new-cusomter-donation.component.css']
})
export class NewCusomterDonationComponent {
  CustomerDonationForm: FormGroup;
  CancelReceiptForm:FormGroup;
  DonationDetails = [];
  err: boolean;
  DonationTypes: any[];
  sectors: any[];
  SectorType: any[];
  SectorLocation: any[];
  donationMainTypeId: any;
  SourceMarket: any;
  cityid: any;
  CollectorCity: any[];
  EmployeeName: string;
  DateNow: any;
  form = {
    hiringDate: "",
    totalAmount: "",
    how: "",
    collectorname: "",
    comment: "",
    Date: "",
    Employee: "",
    donationtype: "",
    sector: "",
    sectortype: "",
    sectorplace: "",
    MoneyAmount: "",
    receiptNum: "",
    donationcat: "",
    Quantity: "",
    donationtypecat: ""
  };
  totalamount: any;
  MoneyAmount: number;
  sector: any[];
  sectortype: any[];
  donationtype: any;
  totalamoutcheged: boolean;
  DateNowbefore: number;
  edit: boolean;
  donationdetailsindex: any;
  DonationDetailsForm: any[] = [];
  customerid: any;
  customername: any;
  cusomtermobile: void;
  serial: any;
  remaining: any;
  total: any;
  SectorType2: any[];
  SectorTypeOther: any[] = [];
  SectorLocationOther: any[] = [];
  sectorlocation: any[];
  donationid: any;
  customerDonations: any;
  editdonation: boolean;
  pagetitle: any;
  donationdetailsEdit: any[] = [];
  param: any;
  editpage: boolean;
  images: any[] = [];
  element: any;
  allfiles: any;
  CreatDonationForm: any = new FormData();
  @ViewChild(WizardComponent)
  public wizard: WizardComponent;
  @ViewChild('UploadFileInput') uploadFileInput: ElementRef;
  callid: any;
  callid$: any;//Not sending a called$ in case of modification in the New consumer Donation screen

  haveImage: boolean;
  imagesView: any;
  receiptNumView: boolean;
  DonationCat: any[];
  donationcat: any[];
  donationtypecat: any[];
  DonationTypesOther: any[] = [];
  donationtype2: any[];
  status: any[] = [];
  statusid: any;
  statustitle: any;
  condition1: boolean;
  condition2: boolean;
  condition3: boolean;
  condition4: boolean;
  condition5: boolean;
  condition6: boolean;
  currentUser: any;
  disapled: boolean;
  cusomteraddress: any;
  editcustomrdonation: boolean;
  CollectorName: any;
  SmsTemplates: any[];
  CollectorId: any;
  UserId: any;
  receiptNumempty: boolean;
  receiptNumlimit: any;
  clicked: boolean;
  receiptNum: number;
  previousPage: any;
  steps: boolean;
  selectedReceipt:any;
  CollectorAvailableReceipts: any[]=[];
  closeResult: string;
  receiptStatusNew: boolean;
  receipt: any;
  CancelReceiptBefore: boolean;
  CollectorAvailableReceiptsCancel: any[]=[];
  donationDetailId: any;
  receiptNumFromDeatil: any;
  receiptIdFromDeatil: any;
  receiptId: any;
  CancelReceiptBtn: boolean;
  receiptNumSelect: boolean;
  CollectorAvailableReceiptsNew: any[]=[];
  receiptNumInrecAndDetails: boolean;
  num: any;
  receiptIdCancel: any;
  DonationDetailsId:number;
  ClearInEdit: boolean;
  CompanyId: any;  
  companyImage: any;
  imgUrlServer=environment.Server_URL_Image+'/'
  GoToStateDetails: boolean;
  stateDetailsData: any;
  stateDetailsDonations: any;
  GotoReceivedPage: boolean;
  GoToDonationTodayPage: boolean;
  GoToRequisitiondetailsPage: boolean;
  colseRequistionId: any;
  GoToClosedRequisitiondetailsPage: boolean;
  colsedRequistionId: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    
    private CompanyDataService:CompanyDataService,private ReceiptBookService :ReceiptBookService,
    public AppComponent:AppComponent,public modalService: NgbModal, private SmsTemplateService: SmsTemplateService, 
    public fb: FormBuilder, private DonationCategoryService: DonationCategoryService, private DontaionsApiService: DontaionsApiService,
    public datepipe: DatePipe, private router: Router, private _formBuilder: FormBuilder, private route: ActivatedRoute, 
    private DonationApiService: DontaionsApiService, private SectorsApiService: SectorsApiService,
     private SectorTypeApiService: SectorTypeApiService, private SectorLocationApiService: SectorLocationApiService,
      private SourceMarketApiService: SourceMarketApiService, private CollectorApiService: CollectorApiService, private location: Location) {
    this.DonationDetailsId=0;
    this.CancelReceiptBtn=false;
    this.CancelReceiptBefore=false;
    this.steps=false;
    this.receiptNumSelect=false;
    this.clicked = false;
    this.currentUser = JSON.parse(localStorage.getItem("RiskCurrentUser"))
    this.UserId = this.currentUser.id
    this.CompanyId=this.CompanyDataService.CompanyInfo.CompanyId;
    this.companyImage=this.imgUrlServer+this.CompanyDataService.CompanyInfo.CompanyIcon;
    const matches = this.companyImage.match(/https/gi);


if (matches && matches.length === 2) {
  this.companyImage=this.CompanyDataService.CompanyInfo.CompanyIcon
}
    if (this.currentUser.roleId == 8) {
      this.disapled = true;
      
    }
    else if (this.currentUser.roleId==9){
      this.receiptNumSelect=true;
    }
 
   // this.GetSMSTemplate();
    if (!this.status) {
      this.DonationApiService.GetLookupsDonationStatus().subscribe({
        next: (data) => {
          res = data;
          if (res.messageCode == 200) {
            this.err = false;
            this.status = res.data;
          } else {
            this.err = true;
          }
        },
        error: (error) => {
          this.err = true;
          // Swal.fire({
          //   icon: 'error',
          //   title: 'خطأ',
          //   text: error.error.message,
          // })
        },
      });
    }
    this.receiptNumView = false;
    this.haveImage = false;
    this.MoneyAmount = 0;
    this.totalamoutcheged = false;
    this.edit = false;
   
 
    const param = this.router.getCurrentNavigation().extras.state;


    if (param) {
if( param.callid)
{ this.callid = param.callid;}
      if (param.donationid) {
        if(param.statedetails){
          this.GoToStateDetails=true;
          this.stateDetailsData=param.statedetails
          this.stateDetailsDonations=param.DontationsFromStats
        }
        else if (param.FromReceivedPage){
          this.GotoReceivedPage=true;
        }
        else if (param.FromDonationTodayPage){
          this.GoToDonationTodayPage=true;
        }
        else if (param.FromRequisitiondetailsPage){
          this.GoToRequisitiondetailsPage=true
          this.colseRequistionId=param.colseRequistionId
        }
        else if (param.FromClosedRequisitiondetailsPage){
          this.GoToClosedRequisitiondetailsPage=true
          this.colsedRequistionId=param.colsedRequistionId
        }
        
        this.pagetitle = "تعديل تبرع"
        this.editdonation = true;
        this.receiptNumView = true;
        this.donationid = param.donationid;
        this.statusid = param.statues;
        if (this.statusid != undefined) {
          if (this.statusid == 1) {
            this.condition1 = true;
          }
          else if (this.statusid == 2) {
            this.condition1 = true;
            this.condition2 = true;
            this.condition3 = true;
          }
          else if (this.statusid == 3) {
            this.condition1 = true;
            this.condition2 = true;
            this.condition3 = true;
            this.condition4 = true;
            this.condition5 = true;
          }
          else if (this.statusid == 4) {
            this.condition1 = true;
            this.condition2 = true;
            this.condition3 = true;
            this.condition4 = true;
            this.condition5 = true;
            this.condition6 = true;
          }
        }
        var res
        this.DonationApiService.GetDonationByid(this.donationid).subscribe({
          next: (data) => {
            res = data;
            if (res.messageCode == 200) {
              this.statusid = res.data.status;
              this.CompanyId=res.data.companyId
          
              this.GetSector()
              this.steps=true;
              if (this.statusid == 1) {
                this.condition1 = true;
              }
              else if (this.statusid == 2) {
                this.condition1 = true;
                this.condition2 = true;
                this.condition3 = true;
              }
              else if (this.statusid == 3) {
                this.condition1 = true;
                this.condition2 = true;
                this.condition3 = true;
                this.condition4 = true;
                this.condition5 = true;
              }
              else if (this.statusid == 4) {
                this.condition1 = true;
                this.condition2 = true;
                this.condition3 = true;
                this.condition4 = true;
                this.condition5 = true;
                this.condition6 = true;
              }
              this.err = false;
              this.donationMainTypeId = res.data.donationMainTypeId;
              this.CollectorName = res.data.collectorName;
              this.DateNowbefore = res.data.creationDate;
              let DateNow = this.datepipe.transform(this.DateNowbefore, 'yyyy-MM-dd');
              this.DateNow = DateNow;
              this.serial = res.data.serial;
             this.form.Employee = res.data.createdByName
              if (this.donationMainTypeId != 2) {
                 this.GetDonationType(this.donationMainTypeId);
                this.cityid = res.data.customerCityId;
                this.customername = res.data.customerName;
                this.cusomtermobile = res.data.customerMobile;
                this.cusomteraddress = res.data.address;
                this.customerid = res.data.customerId;
     /*callid$*/ //this.callid = res.data.callId;
                this.CollectorId = res.data.collectorId
                this.GetCollectorAvailableReceipts(true);
                // this.GetCollectorCity();
                //  this.customerDonations=param.customerDonations[0]
                const hiringDateform = this.datepipe.transform(res.data.date, 'yyyy-MM-dd');
                this.form.hiringDate = hiringDateform.toString();
                this.form.totalAmount = res.data.totalAmount;
                this.form.how = res.data.sourceMarketId;
                this.form.collectorname = res.data.collectorId;
                this.form.comment = res.data.comment;
                res.data.images.forEach((element,index) => {
                  this.imagesView=environment.Server_URL_Image+'/'+element
    
                });
                this.haveImage = true;
                this.DonationDetails = res.data.donationDetails;
               
                this.DonationDetails.forEach((element, index) => {
                  // res.data.donationDetails[index].receiptStatus=null;
                  if(res.data.donationDetails[index].hasReceipt==true){
                    this.receiptStatusNew=false;
                   // this.receipt=this.CollectorAvailableReceipts[0].number;
                    
                  }
                  else{

                    this.receiptStatusNew=true;
                  }
                  element["donationtype"] = res.data.donationDetails[index].donationTypeName;
                  element["sector"] = res.data.donationDetails[index].sectorName;
                  element["sectortype"] = res.data.donationDetails[index].sectorTypeName;
                  element["sectorplace"] = res.data.donationDetails[index].sectorLocationName;
                  element["totalAmount"] = res.data.donationDetails[index].smallAmount;
                  this.MoneyAmount += res.data.donationDetails[index].smallAmount;
                  this.DonationDetailsForm = res.data.donationDetails;
                  this.DonationDetailsForm[index]["donationtype"] = res.data.donationDetails[index].donationTypeName;
                  this.DonationDetailsForm[index]["sector"] = res.data.donationDetails[index].sectorName;
                  this.DonationDetailsForm[index]["sectortype"] = res.data.donationDetails[index].sectorTypeName;
                  this.DonationDetailsForm[index]["sectorplace"] = res.data.donationDetails[index].sectorLocationName;
                  this.DonationDetailsForm[index]["totalAmount"] = res.data.donationDetails[index].smallAmount;
                });

              }
              else {
                this.GetDonationTypeByCatId(res.data.donationCategoryId);
                this.cityid = res.data.customerCityId;
                this.customername = res.data.customerName;
                this.cusomtermobile = res.data.customerMobile;
                this.cusomteraddress = res.data.address;
                this.customerid = res.data.customerId;
               // this.callid = res.data.callId;
                this.CollectorId = res.data.collectorId;
                this.GetCollectorAvailableReceipts(true);
                const hiringDateform = this.datepipe.transform(res.data.date, 'yyyy-MM-dd');
                this.form.hiringDate = hiringDateform.toString();
                this.form.totalAmount = res.data.totalAmount;
                this.form.how = res.data.sourceMarketId;
                this.form.collectorname = res.data.collectorId;
                this.form.comment = res.data.comment;
                res.data.images.forEach((element,index) => {
                  this.imagesView=environment.Server_URL_Image+'/'+element
                });
                this.haveImage = true;
                this.DonationDetails = res.data.donationDetails;
                this.DonationDetails.forEach((element, index) => {
                  if(res.data.donationDetails[index].hasReceipt==true){
                    this.receiptStatusNew=false;
                   // this.receipt=this.CollectorAvailableReceipts[0].number;
                    
                  }
                  else{

                    this.receiptStatusNew=true;
                  }
                  element["donationcat"] = res.data.donationDetails[index].donationCategoryName
                  element["donationtypecat"] = res.data.donationDetails[index].donationTypeName;
                  element["Quantity"] = res.data.donationDetails[index].quantity;
                  element["receiptNum"] = res.data.donationDetails[index].receiptNum;
                  this.DonationDetails = res.data.donationDetails;
                  this.DonationDetailsForm = res.data.donationDetails;
                  this.DonationDetailsForm[index]["donationCategoryId"]=res.data.donationDetails[index].donationCategoryId
                  this.DonationDetailsForm[index]["donationTypeId"] = res.data.donationDetails[index].donationTypeId;
                  this.DonationDetailsForm[index]["quantity"] = res.data.donationDetails[index].quantity;
                  this.DonationDetailsForm[index]["receiptNum"] = res.data.donationDetails[index].receiptNum;
                });
              }


            } else {
              this.err = true;
            }
          },
          error: (error) => {
            this.err = true;
            // Swal.fire({
            //   icon: 'error',
            //   title: 'خطأ',
            //   text: error.error.message,
            // })
          },
        });
      }
      else {
        this.GetSector();
        this.DateNowbefore = Date.now();
        let DateNow = this.datepipe.transform(this.DateNowbefore, 'yyyy-MM-dd');
        this.DateNow = DateNow;
        this.form.Employee = localStorage.getItem("RiskuserName");

        this.pagetitle = "إضافه تبرع"
        this.editdonation = false;
        this.donationMainTypeId = param.donationMainTypeId;
        if (this.donationMainTypeId != 2) {
          this.GetDonationType(this.donationMainTypeId);
        }
        this.cityid = param.cityid;
        this.callid = param.callid;
        this.GetCollectorCity();
        this.customerid = param.cusomterid;
        this.customername = param.cusomtername;
        this.cusomtermobile = param.cusomtermobile
        this.cusomteraddress = param.address;

      }
    }
    if (!this.DonationCat) {
      this.GetDonationCat();
    }
  }
  back() {
  this.previousPage=this.AppComponent.previousPage.substring(1);
    this.router.navigateByUrl(this.previousPage, { state: { cusomtermobile: this.cusomtermobile, donationMainTypeId: this.donationMainTypeId } });
  }
  onFileSelected(event, element) {
    this.element = element
    this.haveImage = true;
    let filesAmount = event.target.files.length;
    let files = event.target.files;
    if(files[0] && files[0].type.startsWith('image/')){
      var reader = new FileReader();
      // this.imagePath = files;
   
      reader.onload = (_event) => {
        this.images.push(files[0]);
        this.imagesView = reader.result;
      }
      reader.onloadend = (_event) => {
        event.target.value = null; // Reset the value of the file input element
      };
      reader.readAsDataURL(files[0]);
    }
    else{
      // Swal.fire({
      //   icon: "error",
      //   title: "خطأ",
      //   text: 'لا يمكن اختيار فيديو',
      // });
        }
  
  }
  get fc() {
    return this.CancelReceiptForm.controls;
  }
  GetDonationCat() {
    var res
    this.DonationCategoryService.get().subscribe({
      next: (data) => {
        res = data;
        if (res.messageCode == 200) {
          this.err = false;
          this.DonationCat = res.data;
        } else {
          this.err = true;
        }
      },
      error: (error) => {
        this.err = true;
        // Swal.fire({
        //   icon: 'error',
        //   title: 'خطأ',
        //   text: error.error.message,
        // })
      },
    });
  }

  deleteimage(imgindex) {
 
    Swal.fire({
      title: " تحذير !",
      text: "هل انت متأكد من حذف هذا الصورة ؟ ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#7364f6',
      cancelButtonColor: "#d33",
      confirmButtonText: "حذف",
      cancelButtonText: "رجوع",
    }).then((result) => {
      if (result.isConfirmed) {
        
        if(this.editdonation){
          this.DonationApiService.DeleteDonationImage(this.donationid).subscribe(
          (res) => {
            Swal.fire({
              icon: "success",
              title: "تم حذف الصورة بنجاح",
              showConfirmButton: false,
              timer: 1500,
            });
            this.images.splice(imgindex, 1);
            this.imagesView=""
            this.haveImage = false;
          },
          (err) => {
            if(err.error.message=='Not Found'){
              this.images.splice(imgindex, 1);
              this.imagesView=""
              this.haveImage = false;
            }
            else{
              Swal.fire({
                icon: 'error',
                title: 'خطأ',
                text: err.error.message,
              })
            }
           
          }
        );
        }
        else{
          this.images.splice(imgindex, 1);
          this.imagesView=""
          this.haveImage = false;
        }
        
      } else {
        // Swal.fire(
        //   'Your appointment still active ',
        // );

      }
    });
    
  }

  OpenImage(imagesView){
    Swal.fire({
      imageUrl: imagesView,
      imageHeight: 400,
      imageAlt: 'A tall image',
      confirmButtonText:"الغاء"
    })
  }
  Add() {
    this.DonationDetailsId+=1
    if (this.donationMainTypeId != 2) {
      if (this.CustomerDonationForm.get("donationtype").value &&
        this.CustomerDonationForm.get("sector").value
        && this.CustomerDonationForm.get("sectortype").value &&
        this.CustomerDonationForm.get("sectorplace").value &&
        this.CustomerDonationForm.get("MoneyAmount").value &&
        this.CustomerDonationForm.get("totalAmount").value
      ) {
        this.SectorType["sectorid"] = Number(this.form.sector)
        this.SectorTypeOther.push(this.SectorType)
        this.SectorLocation["sectortypeid"] = Number(this.form.sectortype)
        this.SectorLocationOther.push(this.SectorLocation);
        this.sector = this.sectors.filter(x => x.id == this.form.sector)
        this.sectortype = this.SectorType.filter(x => x.id == this.form.sectortype)
        this.sectorlocation = this.SectorLocation.filter(x => x.id == this.form.sectorplace);
        this.donationtype = this.DonationTypes.filter(x => x.id == this.form.donationtype)
        this.MoneyAmount += Number(this.form.MoneyAmount);
        const sectorLocationId = this.SectorLocation.findIndex(x => x.id === this.form.sectorplace);
        if (this.MoneyAmount <= Number(this.form.totalAmount)) {
          if (this.editdonation) {
              if(this.receiptNumView&& this.receiptNumSelect){
                if(this.CollectorAvailableReceipts.length!=0){
                  if(this.CustomerDonationForm.get('receiptNum').value){
                     const receiptindex=this.CollectorAvailableReceipts.findIndex(x=>x.id==this.CustomerDonationForm.get('receiptNum').value)
                    this.num=this.CollectorAvailableReceipts[receiptindex]?.number;
                    this.receiptId=this.CollectorAvailableReceipts[receiptindex]?.id;
                  }
                  else{
                     const receiptindex=0
                    this.num=this.CollectorAvailableReceipts[receiptindex]?.number;
                this.receiptId=this.CollectorAvailableReceipts[receiptindex]?.id;
                  }
                  
                this.CollectorAvailableReceipts=this.CollectorAvailableReceipts.filter(x=>x.number!=this.num)
            if(this.CollectorAvailableReceipts.length!=0){
            this.selectedReceipt=this.CollectorAvailableReceipts[0]?.id;

            }
            else{
              this.selectedReceipt=null;
            }
              }
              else{
                this.num=null;
                this.receiptId=null
              }
              }
              else{
                if(this.CollectorAvailableReceipts.length!=0){
                  this.num=this.CollectorAvailableReceipts[0]?.number
                this.receiptId=this.CollectorAvailableReceipts[0]?.id
                }
                else{
                  this.num=null;
                  this.receiptId=null
                }
                
              }
            let receiptnum=  this.num
         const receiptindex=this.CollectorAvailableReceipts.findIndex(x=>x.id==this.CustomerDonationForm.get('receiptNum').value)
        if(receiptindex!=-1){
          this.selectedReceipt=this.CollectorAvailableReceipts[receiptindex+1].id
          
   }
   else{

// this.CollectorAvailableReceipts=[];
//      this.CustomerDonationForm.get("receiptNum").setValue(null);     
   }
   if(this.CollectorAvailableReceipts.length!=0){
    this.DonationDetailsForm.forEach(element => {
      if(element.receiptNum==receiptnum){
        const index=  this.CollectorAvailableReceipts.findIndex(x=>x.number==receiptnum);
        this.receiptNum=this.CollectorAvailableReceipts[index+1]?.number
        this.receiptId=this.CollectorAvailableReceipts[index+1]?.id;
        receiptnum=this.receiptNum
      }
      else{
        this.receiptNum=receiptnum
  
      }
    });
   }

   else{
    this.receiptNum=receiptnum
   // this.receiptNum=null;
  }
       
        if(this.CollectorAvailableReceipts.length!=0){
           const indexForReceipt=  this.CollectorAvailableReceipts.findIndex(x=>x.number==this.receiptNum);
        this.receipt=this.CollectorAvailableReceipts[indexForReceipt+1]?.number
        }
        else{
         // this.receipt=null
        }
       
            this.DonationDetails.push({DonationDetailsId:this.DonationDetailsId,donationId:this.donationid, donationtype: this.donationtype[0].title, sector: this.sector[0].title, sectortype: this.sectortype[0].title, sectorplace: this.sectorlocation[0].title, totalAmount: Number(this.form.MoneyAmount), smallAmount: Number(this.form.MoneyAmount), receiptNum: this.receiptNum,receiptId:this.receiptId, donationTypeId: this.donationtype[0].id, sectorId: this.sector[0].id, sectorTypeId: this.sectortype[0].id, sectorLocationId: this.sectorlocation[0].id });
            this.DonationDetailsForm.push({DonationDetailsId:this.DonationDetailsId, donationId:this.donationid,donationTypeId: this.donationtype[0].id, sectorId: this.sector[0].id, sectorTypeId: this.sectortype[0].id, sectorLocationId: this.sectorlocation[0].id, smallAmount: Number(this.form.MoneyAmount), receiptNum: this.receiptNum,receiptId:this.receiptId });

            this.DonationDetails.forEach((element, index) => {
              if (!element.sector) {
                this.DonationDetails.splice(index, 1);
              }

            });
            this.CollectorAvailableReceipts=this.CollectorAvailableReceipts.filter(x=>x.number!=this.receiptNum)
          }
          else {
            
            this.DonationDetails.push({ DonationDetailsId:this.DonationDetailsId,donationtype: this.donationtype[0].title, sector: this.sector[0].title, sectortype: this.sectortype[0].title, sectorplace: this.sectorlocation[0].title, totalAmount: Number(this.form.MoneyAmount), smallAmount: 1, receiptNum: null, donationTypeId: this.donationtype[0].id, sectorId: this.sector[0].id, sectorTypeId: this.sectortype[0].id, sectorLocationId: this.sectorlocation[0].id });
            this.DonationDetailsForm.push({DonationDetailsId:this.DonationDetailsId, donationTypeId: this.donationtype[0].id, sectorId: this.sector[0].id, sectorTypeId: this.sectortype[0].id, sectorLocationId: this.sectorlocation[0].id, smallAmount: Number(this.form.MoneyAmount) });

            this.DonationDetails.forEach((element, index) => {
              if (!element.sector) {
                this.DonationDetails.splice(index, 1);
              }

            });
          }
          this.form.donationtype = null;
          this.form.sector = null;
          this.form.sectortype = null;
          this.SectorType = [];
          this.form.sectorplace = null;
          this.form.MoneyAmount = null;
          this.form.receiptNum = null;
        }
        else {
          this.MoneyAmount -= Number(this.form.MoneyAmount)
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: "تخطيت المبلغ الاجمالي المحدد للتبرع",
          })

        }
      
      
      
     
      }

      else {
        if (!this.CustomerDonationForm.get("totalAmount").value) {
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: "من فضلك ادخل  المبلغ الاجمالي المحدد للتبرع",
          })
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: "من فضلك ادخل جميع الحقول",
          })
        }

      }


    }
    else { 
      if (this.CustomerDonationForm.get("donationcat").value &&
        this.CustomerDonationForm.get("donationtypecat").value
        && this.CustomerDonationForm.get("Quantity").value
      ) {
                this.donationcat = this.DonationCat.filter(x => x.id == this.form.donationcat);
                this.donationtypecat = this.DonationTypes.filter(x => x.id == this.form.donationtypecat)
                // this.MoneyAmount += Number(this.form.MoneyAmount);
        
                if (this.editdonation) {
                  if(this.receiptNumSelect){
                    if(this.CollectorAvailableReceipts.length!=0){
                      if(this.CustomerDonationForm.get('receiptNum').value){
                         const receiptindex=this.CollectorAvailableReceipts.findIndex(x=>x.id==this.CustomerDonationForm.get('receiptNum').value)
                        this.num=this.CollectorAvailableReceipts[receiptindex]?.number;
                        this.receiptId=this.CollectorAvailableReceipts[receiptindex]?.id;
                      }
                      else{
                         const receiptindex=0
                        this.num=this.CollectorAvailableReceipts[receiptindex]?.number;
                    this.receiptId=this.CollectorAvailableReceipts[receiptindex]?.id;
                      }
                    this.CollectorAvailableReceipts=this.CollectorAvailableReceipts.filter(x=>x.number!=this.num)
                    if(this.CollectorAvailableReceipts.length!=0){
                      this.selectedReceipt=this.CollectorAvailableReceipts[0]?.id;
          
                      }
                      else{
                        this.selectedReceipt=null;
                      }
                                        }
                    else{
                      this.num=null;
                      this.receiptId=null
                    }
                  }
                  else{
                    if(this.CollectorAvailableReceipts.length!=0){
                    this.num=this.CollectorAvailableReceipts[0]?.number
                    this.receiptId=this.CollectorAvailableReceipts[0]?.id
                    }
                    else{
                      this.num=null;
                      this.receiptId=null
                    }
                  }
                let receiptnum=  this.num
                   const receiptindex=this.CollectorAvailableReceipts.findIndex(x=>x.id==this.CustomerDonationForm.get('receiptNum').value)
                  if(receiptindex!=-1){
                    this.selectedReceipt=this.CollectorAvailableReceipts[receiptindex+1].id
                    
             }
             else{
          // this.CollectorAvailableReceipts=[];
          //      this.CustomerDonationForm.get("receiptNum").setValue(null);     
             }
             if(this.CollectorAvailableReceipts.length!=0){
             this.DonationDetailsForm.forEach(element => {
              if(element.receiptNum==receiptnum){
                const index=  this.CollectorAvailableReceipts.findIndex(x=>x.number==receiptnum);
                this.receiptNum=this.CollectorAvailableReceipts[index+1]?.number;
                this.receiptId=this.CollectorAvailableReceipts[index+1]?.id;
                receiptnum=this.receiptNum
              }
              else{
                this.receiptNum=receiptnum
              }
            });
          }
          else{
            this.receiptNum=receiptnum;
          }
                  if(this.CollectorAvailableReceipts.length!=0){
                    const indexForReceipt=  this.CollectorAvailableReceipts.findIndex(x=>x.number==this.receiptNum);
                 this.receipt=this.CollectorAvailableReceipts[indexForReceipt+1]?.number
                 }
                 else{
                   this.receipt=null
                 }
                  this.DonationDetails.push({DonationDetailsId:this.DonationDetailsId,donationId:this.donationid, donationcat: this.donationcat[0].title, donationtypecat: this.donationtypecat[0].title, quantity: this.form.Quantity, receiptNum: this.receiptNum,receiptId:this.receiptId, donationTypeId: this.donationtypecat[0].id, donationCategoryId: this.donationcat[0].id });
                  this.DonationDetailsForm.push({DonationDetailsId:this.DonationDetailsId, donationId:this.donationid,donationTypeId: this.donationtypecat[0].id, donationCategoryId: this.donationcat[0].id, quantity: this.form.Quantity, receiptNum: this.receiptNum ,receiptId:this.receiptId});
                  this.DonationDetails.forEach((element, index) => {
                    if (!element.donationcat) {
                      this.DonationDetails.splice(index, 1);
                    }
        
                  });
                  this.CollectorAvailableReceipts=this.CollectorAvailableReceipts.filter(x=>x.number!=this.receiptNum)

                }
                else {
        
                  this.DonationDetails.push({donationId:this.donationid, donationcat: this.donationcat[0].title, donationtypecat: this.donationtypecat[0].title, quantity: this.form.Quantity, receiptNum: null, donationTypeId: this.donationtypecat[0].id, donationCategoryId: this.donationcat[0].id });
                  this.DonationDetailsForm.push({donationId:this.donationid, donationTypeId: this.donationtypecat[0].id, donationCategoryId: this.donationcat[0].id, quantity: this.form.Quantity, receiptNum: null });
        
        
                  this.DonationDetailsForm.forEach((element, index) => {
                    if (!element.donationCategoryId)
                      this.DonationDetailsForm.splice(index, 1);
        
                  });
                }
        
                this.form.donationtypecat = null;
                this.form.donationcat = null;
                this.DonationTypes = []
                this.form.Quantity = null
                this.form.receiptNum = null;
              
      
    
       
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: "من فضلك ادخل جميع الحقول",
        })
      }

    }

  }

  EditDonationDetails(index) {
    
    this.edit=true;
    this.donationdetailsindex = index;
    this.donationDetailId=this.DonationDetailsForm[index].id;
    this.receiptNumFromDeatil=this.DonationDetailsForm[index].receiptNum
    this.receiptIdFromDeatil=this.DonationDetailsForm[index].receiptId
    if (this.donationMainTypeId != 2) {
      
      // const donationtypeindex = this.DonationTypes.findIndex(x => x.title == this.DonationDetails[index].donationTypeId);
      const sectorindex = this.sectors.findIndex(x => x.title == this.DonationDetails[index].sector);
      // const sectortypeindex = this.SectorType.findIndex(x => x.title == this.DonationDetails[index].sectortype);
      this.CustomerDonationForm.get("donationtype").setValue(this.DonationDetailsForm[index].donationTypeId)
      this.CustomerDonationForm.get("sector").setValue(this.sectors[sectorindex].id)
      if (this.editdonation) {
        this.GetSectorType(this.DonationDetailsForm[index].sectorId);
        this.GetsectorLoctaion(this.DonationDetailsForm[index].sectorTypeId)
      }
      const sectortype = this.SectorTypeOther.filter(x => x.sectorid == this.DonationDetailsForm[index].sectorId);
      const sectorlocation = this.SectorLocationOther.filter(x => x.sectortypeid == this.DonationDetailsForm[index].sectorTypeId);
      this.SectorType = sectortype[0];
      this.SectorLocation = sectorlocation[0];
      this.CustomerDonationForm.get("sectortype").setValue(this.DonationDetailsForm[index].sectorTypeId);
      this.CustomerDonationForm.get("sectorplace").setValue(this.DonationDetailsForm[index].sectorLocationId);
      this.form.MoneyAmount = this.DonationDetails[index].totalAmount
      if(this.receiptNumSelect){
        debugger
        if(this.DonationDetailsForm[index].receiptNum!=null){
          const filter = this.CollectorAvailableReceipts.filter(x=>x.number==this.DonationDetailsForm[index].receiptNum);
          if(filter.length==0){
               this.CollectorAvailableReceipts.unshift({number:this.DonationDetailsForm[index].receiptNum,id:this.DonationDetailsForm[index].receiptId,DonationDetailsId:this.DonationDetailsForm[index]?.DonationDetailsId})
               if(this.edit){
                this.DonationDetails.forEach(element => {
                  const getNum=this.CollectorAvailableReceipts.filter(x=>x.number==element.receiptNum)
                  const getNumindex=this.CollectorAvailableReceipts.findIndex(x=>x.number==element.receiptNum)
                  if(getNum.length!=0){
                 if(getNum[0].number!=this.DonationDetailsForm[index].receiptNum){
                  this.CollectorAvailableReceipts=this.CollectorAvailableReceipts.filter(x=>x.number!=getNum[0].number)
                }
                  }
               
                });
                
              }
               this.CollectorAvailableReceipts=[...this.CollectorAvailableReceipts]
          }
          else{
           if(this.edit){
             this.CollectorAvailableReceipts=this.CollectorAvailableReceipts.filter(x=>x.number!=this.CollectorAvailableReceipts[0].number)
           }
          }
       
        }   
        else{
          this.CollectorAvailableReceipts=this.CollectorAvailableReceipts.filter(x=>x.bookNumber);
           if(this.CollectorAvailableReceipts.length==0){
                    this.selectedReceipt=null;
  
          }  
           
          }    
        const ReceiptIndex= this.CollectorAvailableReceipts.findIndex(x=>x.number==this.DonationDetails[index].receiptNum)
       if(ReceiptIndex!=-1){
       this.selectedReceipt=this.CollectorAvailableReceipts[ReceiptIndex].id;
        this.CustomerDonationForm.get("receiptNum").setValue(this.CollectorAvailableReceipts[ReceiptIndex].id)
       }
       else{
        //  this.CollectorAvailableReceipts=[]
        //  this.CustomerDonationForm.get("receiptNum").setValue(null)
        // this.CollectorAvailableReceipts.push({number:this.DonationDetails[index].receiptNum,id:this.DonationDetails[index].receiptId})
        // this.CustomerDonationForm.get("receiptNum").setValue(this.DonationDetails[index].receiptId) 
       }
       
      }
      else{
        
        if(this.DonationDetails[index].receiptNum==null){
          if(this.CollectorAvailableReceipts.length!=0){
            this.DonationDetails.forEach(element => {
              if(element.receiptNum==this.CollectorAvailableReceipts[0].number){
                this.receiptNumInrecAndDetails=true;
              }
              
            });
     if(this.receiptNumInrecAndDetails){
         const index= this.CollectorAvailableReceipts.findIndex(x=>x.number==this.CollectorAvailableReceipts[0].number);
         this.receipt=this.CollectorAvailableReceipts[index+1].number
     }
     else{
      this.receipt=this.CollectorAvailableReceipts[0].number
    }
          }
          else{
            // Swal.fire({
            //   icon: 'error',
            //   title: 'خطأ',
            //   text: "لا يوجد لديك ايصالات يمكنك مراجعه المسئول",
            // })
            this.receipt=null;
                      }
   
        }
        else{

     this.receipt= this.DonationDetails[index].receiptNum;


        }
      }
    }
    else {
      const donationCatindex = this.DonationCat.findIndex(x => x.id == this.DonationDetailsForm[index].donationCategoryId);
      this.CustomerDonationForm.get("donationcat").setValue(this.DonationCat[donationCatindex].id)
      if (this.editdonation) {
        this.DonationTypes = [];
        this.GetDonationTypeByCatId(this.DonationDetailsForm[index].donationCategoryId);
        this.CustomerDonationForm.get("donationtypecat").setValue(this.DonationDetailsForm[index].donationTypeId)

      }
      else {
        this.DonationTypes = [];
        this.GetDonationTypeByCatId(this.DonationDetailsForm[index].donationCategoryId);
        this.CustomerDonationForm.get("donationtypecat").setValue(this.DonationDetailsForm[index].donationTypeId)

      }
      this.form.Quantity = this.DonationDetails[index].quantity
      if(this.receiptNumSelect){
        
        if(this.DonationDetailsForm[index].receiptNum!=null){
          const filter = this.CollectorAvailableReceipts.filter(x=>x.number==this.DonationDetailsForm[index].receiptNum);
          if(filter.length==0){
            this.CollectorAvailableReceipts.unshift({number:this.DonationDetailsForm[index].receiptNum,id:this.DonationDetailsForm[index].receiptId,DonationDetailsId:this.DonationDetailsForm[index]?.DonationDetailsId})
            if(this.edit){
             this.DonationDetails.forEach(element => {
               const getNum=this.CollectorAvailableReceipts.filter(x=>x.number==element.receiptNum)
               const getNumindex=this.CollectorAvailableReceipts.findIndex(x=>x.number==element.receiptNum)
               if(getNum.length!=0){
              if(getNum[0].number!=this.DonationDetailsForm[index].receiptNum){
               this.CollectorAvailableReceipts=this.CollectorAvailableReceipts.filter(x=>x.number!=getNum[0].number)
             }
               }
            
             });
             
           }

            this.CollectorAvailableReceipts=[...this.CollectorAvailableReceipts]
       }
       else{
        if(this.edit){
          this.CollectorAvailableReceipts=this.CollectorAvailableReceipts.filter(x=>x.number!=this.CollectorAvailableReceipts[0].number)
        }
       }
        }
        else{
        this.CollectorAvailableReceipts=this.CollectorAvailableReceipts.filter(x=>x.bookNumber);
         if(this.CollectorAvailableReceipts.length==0){
                  this.selectedReceipt=null;

         }  
         
        }
        const ReceiptIndex= this.CollectorAvailableReceipts.findIndex(x=>x.number==this.DonationDetails[index].receiptNum)
       if(ReceiptIndex!=-1){
       this.selectedReceipt=this.CollectorAvailableReceipts[ReceiptIndex].id;
        this.CustomerDonationForm.get("receiptNum").setValue(this.CollectorAvailableReceipts[ReceiptIndex].id)
       }
       else{
        //  this.CollectorAvailableReceipts=[]
        //  this.CustomerDonationForm.get("receiptNum").setValue(null)
        // this.CollectorAvailableReceipts.push({number:this.DonationDetails[index].receiptNum,id:this.DonationDetails[index].receiptId})
        // this.CustomerDonationForm.get("receiptNum").setValue(this.DonationDetails[index].receiptId) 
      }
  
        }
        else{
          if(this.DonationDetails[index].receiptNum==null){
            if(this.CollectorAvailableReceipts.length!=0){
              this.DonationDetails.forEach(element => {
                if(element.receiptNum==this.CollectorAvailableReceipts[0].number){
                  this.receiptNumInrecAndDetails=true;
                }
                
              });
       if(this.receiptNumInrecAndDetails){
           const index= this.CollectorAvailableReceipts.findIndex(x=>x.number==this.CollectorAvailableReceipts[0].number);
           this.receipt=this.CollectorAvailableReceipts[index+1].number
       }
       else{
        this.receipt=this.CollectorAvailableReceipts[0].number
      }
            }
            else{
              this.receipt=null;
              // Swal.fire({
              //   icon: 'error',
              //   title: 'خطأ',
              //   text: "لا يوجد لديك ايصالات يمكنك مراجعه المسئول",
              // })
                        }
     
          }
          else{
             this.receipt= this.DonationDetails[index].receiptNum;

          }
        }
    }
    this.edit = true;
  }
  EditOneDonationDetails() {
    this.CancelReceiptBtn=true;
    this.donationdetailsEdit = [];
    if (this.donationMainTypeId != 2) {
      if (this.CustomerDonationForm.get("donationtype").value &&
        this.CustomerDonationForm.get("sector").value
        && this.CustomerDonationForm.get("sectortype").value &&
        this.CustomerDonationForm.get("sectorplace").value &&
        this.CustomerDonationForm.get("MoneyAmount").value &&
        this.CustomerDonationForm.get("totalAmount").value
      ) {
        // if(this.CustomerDonationForm.get("receiptNum").value){
        //   if (this.CustomerDonationForm.get("receiptNum").value.length>0&&this.CustomerDonationForm.get("receiptNum").value.length<=3&& this.editdonation ){
        // this.receiptNumlimit=true;
        //       }
              // else{
        this.SectorType["sectorid"] = this.form.sector
        this.SectorTypeOther.push(this.SectorType)
        this.SectorLocation["sectortypeid"] = this.form.sectortype
        this.SectorLocationOther.push(this.SectorLocation);
        this.sector = this.sectors.filter(x => x.id == this.form.sector)
        this.sectortype = this.SectorType.filter(x => x.id == this.form.sectortype)
        this.sectorlocation = this.SectorLocation.filter(x => x.id == this.form.sectorplace)
     //   this.receiptNum = Number(this.CustomerDonationForm.get('receiptNum').value)
    if(this.receiptNumView&&this.receiptNumSelect){
         //  const receiptnum=    this.CollectorAvailableReceipts.filter(x=>x.id==this.CustomerDonationForm.get('receiptNum').value)[0].number
      const receiptindex=this.CollectorAvailableReceipts.findIndex(x=>x.id==this.CustomerDonationForm.get('receiptNum').value)
     if(receiptindex!=-1){
       if(this.CollectorAvailableReceipts[receiptindex+1]){
        this.selectedReceipt=this.CollectorAvailableReceipts[receiptindex+1].id


       }
       else{
            this.selectedReceipt=null

       }
            const receitnumber = this.CollectorAvailableReceipts.filter(x=>x.id==this.CustomerDonationForm.get("receiptNum").value)[0].number
            const receitid = this.CollectorAvailableReceipts.filter(x=>x.id==this.CustomerDonationForm.get("receiptNum").value)[0].id
            this.receiptNum=receitnumber
            this.CollectorAvailableReceipts=this.CollectorAvailableReceipts.filter(x=>x.number!=receitnumber);
            this.CollectorAvailableReceipts=[...this.CollectorAvailableReceipts];
            debugger

            this.DonationDetails[this.donationdetailsindex].receiptNum=receitnumber
            this.DonationDetailsForm[this.donationdetailsindex].receiptNum = receitnumber
            this.DonationDetails[this.donationdetailsindex].receiptId=receitid
            this.DonationDetailsForm[this.donationdetailsindex].receiptId = receitid
            // this.CollectorAvailableReceipts.forEach(element => {
            //   if(element.number=this.DonationDetailsForm[this.donationdetailsindex].receiptNum){

            //   }
            // });
           if(this.DonationDetails[this.donationdetailsindex].receiptstate!=0){
            this.CollectorAvailableReceipts=this.CollectorAvailableReceipts.filter(x=>x.bookNumber||x.DonationDetailsId)
            this.CollectorAvailableReceiptsNew=this.CollectorAvailableReceiptsNew.filter(x=>x.bookNumber||x.DonationDetailsId)
            this.DonationDetails[this.donationdetailsindex].receiptstate = 0;
            this.DonationDetailsForm[this.donationdetailsindex].receiptstate = 0;       
          }

            

          }
     else{
      if(this.CollectorAvailableReceipts.length!=0){
        this.selectedReceipt=this.CollectorAvailableReceipts[receiptindex+1].id
       }
      //this.CollectorAvailableReceipts=[];
    //  this.CustomerDonationForm.get("receiptNum").setValue(null);
     }
     if(this.ClearInEdit){
      this.CollectorAvailableReceipts=this.CollectorAvailableReceipts.filter(x=>x.bookNumber)
    }
     }
    else{
      
      const index=  this.CollectorAvailableReceipts.findIndex(x=>x.number==this.receipt);
      if(index!=-1){
        if(!this.CancelReceiptBefore){
          // this.receiptId=1990;
        //  this.receipt=this.CollectorAvailableReceipts[index+1].number
        this.receipt=this.CollectorAvailableReceipts[index]?.number
        this.receiptId=this.CollectorAvailableReceipts[index]?.id
        
        }
        else{
          if(this.DonationDetailsForm[this.donationdetailsindex].receiptNum){
                 this.receipt=this.CollectorAvailableReceipts[index+1]?.number
             this.receiptId=this.CollectorAvailableReceipts[index+1]?.id
          }
          else{      
            this.receipt=this.CollectorAvailableReceipts[0]?.number
            this.receiptId=this.CollectorAvailableReceipts[0]?.id
          }
            this.CancelReceiptBefore=false;
        }
   
      }
      else{
        this.CancelReceiptBefore=false;
        // this.receipt=""
        this.receiptId=this.receiptIdFromDeatil
      }
      this.DonationDetailsForm.forEach(element => {
        if(element.receiptNum==this.receipt&&this.CancelReceiptBefore){
          const index=  this.CollectorAvailableReceipts.findIndex(x=>x.number==this.receipt);
          this.receipt=this.CollectorAvailableReceipts[index+1]?.number
          this.receiptId=this.CollectorAvailableReceipts[index+1]?.id
          this.CancelReceiptBefore=false;

        }
      });
              this.receiptNum=this.receipt;


     this.DonationDetails[this.donationdetailsindex].receiptNum=this.receipt
        this.DonationDetailsForm[this.donationdetailsindex].receiptNum = this.receipt;
        this.DonationDetails[this.donationdetailsindex].receiptId=this.receiptId
        this.DonationDetailsForm[this.donationdetailsindex].receiptId = this.receiptId;
      
        if(this.DonationDetailsForm[this.donationdetailsindex].receiptstate!=0){
                  this.CollectorAvailableReceipts=this.CollectorAvailableReceipts.filter(x=>x.bookNumber||x.DonationDetailsId)
        this.CollectorAvailableReceiptsNew=this.CollectorAvailableReceiptsNew.filter(x=>x.bookNumber||x.DonationDetailsId)
        this.DonationDetails[this.donationdetailsindex].receiptstate = 0;
              this.DonationDetailsForm[this.donationdetailsindex].receiptstate = 0;   
        }
        if(this.CollectorAvailableReceipts.length!=0&&this.receiptStatusNew && this.receiptNumSelect){
          const indexForReceipt=  this.CollectorAvailableReceipts.findIndex(x=>x.number==this.receipt);
          this.receipt=this.CollectorAvailableReceipts[indexForReceipt+1]?.number
          
          
          }
          else{
            if(this.CollectorAvailableReceipts.length!=0){
              this.DonationDetailsForm.forEach(element => {
                if(element.receiptNum==this.receipt){
                  this.CollectorAvailableReceipts=this.CollectorAvailableReceipts.filter(x=>x.number!=this.receipt)
                }
              });
              
               const indexForReceipt=  this.CollectorAvailableReceipts.findIndex(x=>x.number==this.receipt);
                       this.receipt=this.CollectorAvailableReceipts[indexForReceipt+1]?.number
            }
            else{
              this.receipt=null
            }
           
            // this.receipt=null;
          }
      
      }
      this.SectorType["sectorid"] = this.form.sector
      this.SectorTypeOther.push(this.SectorType)
      this.SectorLocation["sectortypeid"] = this.form.sectortype
      this.SectorLocationOther.push(this.SectorLocation);
      this.sector = this.sectors.filter(x => x.id == this.form.sector)
      this.sectortype = this.SectorType.filter(x => x.id == this.form.sectortype)
      this.sectorlocation = this.SectorLocation.filter(x => x.id == this.form.sectorplace)
      this.donationtype = this.DonationTypes.filter(x => x.id == this.form.donationtype)
      this.DonationDetails[this.donationdetailsindex].donationtype = this.donationtype[0].title;
      this.DonationDetails[this.donationdetailsindex].sector = this.sector[0].title;
      this.DonationDetails[this.donationdetailsindex].sectortype = this.sectortype[0].title;
      this.DonationDetails[this.donationdetailsindex].sectorplace = this.sectorlocation[0].title;
      this.DonationDetails[this.donationdetailsindex].totalAmount = this.form.MoneyAmount;
    //  this.DonationDetails[this.donationdetailsindex].receiptNum = Number(this.form.receiptNum);
      
      const sectorLocationId = this.SectorLocation.findIndex(x => x.title === this.form.sectorplace);
      
      this.DonationDetailsForm[this.donationdetailsindex].donationTypeId = this.donationtype[0].id;
      this.DonationDetailsForm[this.donationdetailsindex].sectorId = this.sector[0].id;
      this.DonationDetailsForm[this.donationdetailsindex].sectorTypeId = this.sectortype[0].id;
      this.DonationDetailsForm[this.donationdetailsindex].sectorLocationId = this.sectorlocation[0].id;
      this.DonationDetailsForm[this.donationdetailsindex].smallAmount = Number(this.form.MoneyAmount);
      //this.DonationDetailsForm[this.donationdetailsindex].receiptNum = this.form.receiptNum;
      this.MoneyAmount = this.DonationDetailsForm.reduce((sum, current) => sum + Number(current.smallAmount), 0)
      this.form.donationtype = null;
      this.form.sector = null;
      this.SectorType = []
      this.SectorLocation = []
      this.form.sectortype = null;
      this.form.sectorplace = null;
      this.form.MoneyAmount = null
      this.form.receiptNum = null;
      this.edit = false;
      this.donationDetailId=undefined;
      this.DonationDetailsForm.forEach(element => {
        
        const index=  this.CollectorAvailableReceipts.findIndex(x=>x.number==element.receiptNum);
        if(this.CollectorAvailableReceipts[index].number==element.receiptNum){
           this.CollectorAvailableReceipts.splice(index,1)
        }
       
        });
        
        // this.CollectorAvailableReceipts.forEach((element,i) => {
        //   if(!element.bookNumber){
        //       this.CollectorAvailableReceipts.splice(i,1)
        //   }
        // });
      }

        

      else {
        if (!this.CustomerDonationForm.get("totalAmount").value) {
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: "من فضلك ادخل  المبلغ الاجمالي المحدد للتبرع",
          })
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: "من فضلك ادخل جميع الحقول",
          })
        }

      }
    }
    else {
      if (this.CustomerDonationForm.get("donationcat").value &&
        this.CustomerDonationForm.get("donationtypecat").value
        && this.CustomerDonationForm.get("Quantity").value
      ) {

        if(this.receiptNumView&&this.receiptNumSelect){
             //  const receiptnum=    this.CollectorAvailableReceipts.filter(x=>x.id==this.CustomerDonationForm.get('receiptNum').value)[0].number
          const receiptindex=this.CollectorAvailableReceipts.findIndex(x=>x.id==this.CustomerDonationForm.get('receiptNum').value)
         if(receiptindex!=-1){
           if(this.CollectorAvailableReceipts[receiptindex+1]){
            this.selectedReceipt=this.CollectorAvailableReceipts[receiptindex+1].id


       }
       else{
            this.selectedReceipt=null

       }
                const receitnumber = this.CollectorAvailableReceipts.filter(x=>x.id==this.CustomerDonationForm.get("receiptNum").value)[0].number
                const receitid = this.CollectorAvailableReceipts.filter(x=>x.id==this.CustomerDonationForm.get("receiptNum").value)[0].id
                this.receiptNum=receitnumber
                this.CollectorAvailableReceipts=this.CollectorAvailableReceipts.filter(x=>x.number!=receitnumber);
                this.CollectorAvailableReceipts=[...this.CollectorAvailableReceipts];
                debugger

                this.DonationDetails[this.donationdetailsindex].receiptNum=receitnumber
                this.DonationDetailsForm[this.donationdetailsindex].receiptNum = receitnumber
                this.DonationDetails[this.donationdetailsindex].receiptId=receitid
                this.DonationDetailsForm[this.donationdetailsindex].receiptId = receitid
                if(this.DonationDetails[this.donationdetailsindex].receiptstate!=0){
                                  this.CollectorAvailableReceipts=this.CollectorAvailableReceipts.filter(x=>x.bookNumber||x.DonationDetailsId)
                this.CollectorAvailableReceiptsNew=this.CollectorAvailableReceiptsNew.filter(x=>x.bookNumber||x.DonationDetailsId)
                this.DonationDetails[this.donationdetailsindex].receiptstate = 0;
                this.DonationDetailsForm[this.donationdetailsindex].receiptstate = 0;
                }


              }
         else{
           if(this.CollectorAvailableReceipts.length!=0){
            this.selectedReceipt=this.CollectorAvailableReceipts[receiptindex+1].id
           }
         }
         if(this.ClearInEdit){
          this.CollectorAvailableReceipts=this.CollectorAvailableReceipts.filter(x=>x.bookNumber)
        }
         }
        else{
      
          const index=  this.CollectorAvailableReceipts.findIndex(x=>x.number==this.receipt);
          if(index!=-1){
            if(!this.CancelReceiptBefore){
              //  this.receipt=this.CollectorAvailableReceipts[index+1].number
              this.receipt=this.CollectorAvailableReceipts[index]?.number
              this.receiptId=this.CollectorAvailableReceipts[index]?.id
              }
              else{
                if(this.DonationDetailsForm[this.donationdetailsindex].receiptNum){
                       this.receipt=this.CollectorAvailableReceipts[index+1]?.number
                   this.receiptId=this.CollectorAvailableReceipts[index+1]?.id
                }
                else{
                          this.receipt=this.CollectorAvailableReceipts[0]?.number
                  this.receiptId=this.CollectorAvailableReceipts[0]?.id
                  

                }
              
                   this.CancelReceiptBefore=false;
              }
       
          }
          else{
          //  this.receipt=""
          this.CancelReceiptBefore=false;
          this.receiptId=this.receiptIdFromDeatil
          }
          this.DonationDetailsForm.forEach(element => {
            if(element.receiptNum==this.receipt&&this.CancelReceiptBefore){
              const index=  this.CollectorAvailableReceipts.findIndex(x=>x.number==this.receipt);
              this.receipt=this.CollectorAvailableReceipts[index+1]?.number
              this.receiptId=this.CollectorAvailableReceipts[index+1]?.id
              this.CancelReceiptBefore=false;
            }
          });
                  this.receiptNum=this.receipt;
                  

                  this.DonationDetails[this.donationdetailsindex].receiptNum=this.receipt
            this.DonationDetailsForm[this.donationdetailsindex].receiptNum = this.receipt;
            this.DonationDetails[this.donationdetailsindex].receiptId=this.receiptId
            this.DonationDetailsForm[this.donationdetailsindex].receiptId = this.receiptId;
            if(this.DonationDetails[this.donationdetailsindex].receiptstate!=0){
               this.CollectorAvailableReceipts=this.CollectorAvailableReceipts.filter(x=>x.bookNumber||x.DonationDetailsId)
            this.CollectorAvailableReceiptsNew=this.CollectorAvailableReceiptsNew.filter(x=>x.bookNumber||x.DonationDetailsId)
            this.DonationDetails[this.donationdetailsindex].receiptstate = 0;
            this.DonationDetailsForm[this.donationdetailsindex].receiptstate = 0;
            }
            if(this.CollectorAvailableReceipts.length!=0&&this.receiptStatusNew && this.receiptNumSelect){
            const indexForReceipt=  this.CollectorAvailableReceipts.findIndex(x=>x.number==this.receipt);
            this.receipt=this.CollectorAvailableReceipts[indexForReceipt+1]?.number
            }
            else{
              if(this.CollectorAvailableReceipts.length!=0){
                this.DonationDetailsForm.forEach(element => {
                  if(element.receiptNum==this.receipt){
                    this.CollectorAvailableReceipts=this.CollectorAvailableReceipts.filter(x=>x.number!=this.receipt)
                  }
                });
            const indexForReceipt=  this.CollectorAvailableReceipts.findIndex(x=>x.number==this.receipt);
            this.receipt=this.CollectorAvailableReceipts[indexForReceipt+1]?.number
              }
              else{
                this.receipt=null
              }
           //   this.receipt=null;
            }
            
        }
        this.donationcat = this.DonationCat.filter(x => x.id == this.form.donationcat)
        this.donationtype2 = this.DonationTypes.filter(x => x.id == this.form.donationtypecat)
        this.DonationDetails[this.donationdetailsindex].donationcat = this.donationcat[0].title;;
        this.DonationDetails[this.donationdetailsindex].donationtypecat = this.donationtype2[0].title;
        this.DonationDetails[this.donationdetailsindex].quantity = this.form.Quantity;
        this.DonationDetailsForm[this.donationdetailsindex].donationCategoryId = this.donationcat[0].id;
        this.DonationDetailsForm[this.donationdetailsindex].donationTypeId = this.donationtype2[0].id;
        this.DonationDetailsForm[this.donationdetailsindex].quantity = this.form.Quantity

        this.form.donationtypecat = null;
        this.form.donationcat = null;
        this.DonationTypes = []
        this.form.Quantity = null
        this.form.receiptNum = null;
        this.edit = false;
        this.donationDetailId=undefined;
                this.DonationDetailsForm.forEach(element => {
          const index=  this.CollectorAvailableReceipts.findIndex(x=>x.number==element.receiptNum);
          if(this.CollectorAvailableReceipts[index].number==element.receiptNum){
            this.CollectorAvailableReceipts.splice(index,1)
         }
                  });
          this.CollectorAvailableReceipts.forEach((element,i) => {
            if(!element.bookNumber){
                this.CollectorAvailableReceipts.splice(i,1)
            }
          });
      }
      else {

        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: "من فضلك ادخل جميع الحقول",
        })

      }
    }

  }
  ontotalAmountChange(event) {
    this.total = Number(event);
  }
  ngOnInit(): void {
    this.CustomerDonationForm = new FormGroup({
      hiringDate: new FormControl(['', Validators.required]),
      totalAmount: new FormControl(['', Validators.required]),
      how: new FormControl(['', Validators.required]),
      collectorname: new FormControl(['', Validators.required]),
      comment: new FormControl(['', Validators.required]),
      Date: new FormControl(['', Validators.required]),
      Employee: new FormControl(['', Validators.required]),
      donationtype: new FormControl(['', Validators.required]),
      sector: new FormControl(['', Validators.required]),
      sectortype: new FormControl(['', Validators.required]),
      sectorplace: new FormControl(['', Validators.required]),
      MoneyAmount: new FormControl(['', Validators.required]),
      images: new FormControl([[], Validators.required]),
      receiptNum: new FormControl(['', Validators.required]),
      DonationDetails: new FormControl([[], Validators.required]),
      donationcat: new FormControl([[], Validators.required]),
      donationtypecat: new FormControl([[], Validators.required]),
      Quantity: new FormControl([[], Validators.required]),



    });
    this.CancelReceiptForm = new FormGroup({
      CanceledReceipt:new FormControl(['', Validators.required]),
      Receipts: new FormControl(['', Validators.required]),
      CancelReason: new FormControl(["", Validators.required])
    });
    this.CancelReceiptForm.get("CancelReason").setValue("")
    this.CancelReceiptForm.get('CanceledReceipt').disable();
    if (this.route.snapshot.paramMap.get('id')) {
    this.form.Employee = JSON.parse(localStorage.getItem("RiskuserName"));
    } else {
      // this._InitForm();

      this.GetSourceMarket();
    }
  }
  GetCollectorCity() {
    var res;
    this.CollectorApiService.GetCollectorByCity(this.cityid).subscribe({
      next: (data) => {
        res = data;
        if (res.messageCode == 200) {
          this.err = false;
          this.CollectorCity = res.data;
        } else {
          this.err = true;
        }
      },
      error: (error) => {
        this.err = true;
        // Swal.fire({
        //   icon: 'error',
        //   title: 'خطأ',
        //   text: error.error.message,
        // })
      },
    });
  }
  GetSourceMarket() {
    var res
    this.SourceMarketApiService.Get().subscribe({
      next: (data) => {
        res = data;
        if (res.messageCode == 200) {
          this.err = false;
          this.SourceMarket = res.data;
        } else {
          this.err = true;
        }
      },
      error: (error) => {
        this.err = true;
        // Swal.fire({
        //   icon: 'error',
        //   title: 'خطأ',
        //   text: error.error.message,
        // })
      },
    });
  }
  onChangeSector(id) {
    this.SectorType =[]
    this.SectorType2=[]
    this.SectorLocation=[]
    this.GetSectorType(id);
    this.CustomerDonationForm.patchValue({
      sectortype: null
    })
    this.CustomerDonationForm.patchValue({
      sectorplace: null
    })

  }
  onChangeSectorType(id) {
    this.SectorLocation=[]
    this.GetsectorLoctaion(id);
    this.CustomerDonationForm.patchValue({
      sectorplace: null
    })

  }
  GetDonationType(donationMainTypeId) {
    var res
    this.DonationApiService.GetDonationTypesInDonationMainType(donationMainTypeId).subscribe({
      next: (data) => {
        res = data;
        if (res.messageCode == 200) {
          this.err = false;
          this.DonationTypes = res.data;
        } else {
          this.err = true;
        }
      },
      error: (error) => {
        this.err = true;
        // Swal.fire({
        //   icon: 'error',
        //   title: 'خطأ',
        //   text: error.error.message,
        // })
      },
    });
  }
  GetDonationTypeByCatId(donationCategoryId) {
    var res;
    this.DonationCategoryService.GetDonationTypesInDonationCategory(donationCategoryId).subscribe({
      next: (data) => {
        res = data;
        if (res.messageCode == 200) {
          this.err = false;
          this.DonationTypes = res.data;
        } else {
          this.err = true;
        }
      },
      error: (error) => {
        this.err = true;
        // Swal.fire({
        //   icon: 'error',
        //   title: 'خطأ',
        //   text: error.error.message,
        // })
      },
    });
  }
  GetSector() {


    var res;
    this.SectorsApiService.getSectors(this.CompanyId).subscribe({
      next: (data) => {
        res = data;
        if (res.messageCode == 200) {
          this.err = false;
          this.sectors = res.data;
        } else {
          this.err = true;
        }
      },
      error: (error) => {
        this.err = true;
        // Swal.fire({
        //   icon: 'error',
        //   title: 'خطأ',
        //   text: error.error.message,
        // })
      },
    });
  }

  GetSectorType(SectorId) {
    var res;
    this.SectorTypeApiService.getSectorType(SectorId,this.CompanyId).subscribe({
      next: (data) => {
        res = data;
        if (res.messageCode == 200) {
          this.err = false;
          this.SectorType = res.data;
          this.SectorType2 = res.data;
        } else {
          this.err = true;
        }
      },
      error: (error) => {
        this.err = true;
        // Swal.fire({
        //   icon: 'error',
        //   title: 'خطأ',
        //   text: error.error.message,
        // })
      },
    });
  }

  GetsectorLoctaion(SectorTypeId) {
    var res;
    this.SectorLocationApiService.getSectorLocation(SectorTypeId).subscribe({
      next: (data) => {
        res = data;
        if (res.messageCode == 200) {
          this.err = false;
          this.SectorLocation = res.data;

        } else {
          this.err = true;
        }
      },
      error: (error) => {
        this.err = true;
        // Swal.fire({
        //   icon: 'error',
        //   title: 'خطأ',
        //   text: error.error.message,
        // })
      },
    });
  }
  submitForm() {
    if (!this.CustomerDonationForm.get('hiringDate').value) {
      Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: "ادخل تاريخ تحصيل التبرع المتوقع اولا",
      })
    }
    else if (!this.haveImage && this.donationMainTypeId == 3) {
      Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: "اختار الصوره اولا",
      })
    }
    else if (!this.CustomerDonationForm.get('totalAmount').value) {
      Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: "ادخل مبلغ التبرع اولا",
      })
    }
    else if (!this.CustomerDonationForm.get('how').value) {
      Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: "ادخل كيف اولا",
      })
    }
    else if (!this.CustomerDonationForm.get('collectorname').value && this.donationMainTypeId != 3) {
      Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: "ادخل اسم المندوب اولا",
      })
    }
 
    else {
      if (this.editdonation) {
        this.UpdateCustomerDonation();
      }
      else {

        if (this.MoneyAmount != Number(this.form.totalAmount) && this.donationMainTypeId != 2) {
          const remaining = Number(this.form.totalAmount) - this.MoneyAmount
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: "  المبلغ المحدد للتبرع متبقي منه  " + remaining,
          })
        }
        else {
          var Details = this.DonationDetailsForm
          var DonationDetailsForm = JSON.stringify(Details)
          if (this.donationMainTypeId == 3) {
            this.CreatDonationForm.append('collectorId', "");
          }
          else {
            this.CreatDonationForm.append('collectorId', this.form.collectorname);
            //this.CreatDonationForm.append('collectorId', Number(this.CustomerDonationForm.get('collectorname').value));

          }
            
         this.callid = this.document.defaultView.history.state.callid;
         if(this.callid )
            this.CreatDonationForm.append('callId', this.callid);

       
          
          this.images.forEach(element => {
            this.CreatDonationForm.append('images', element)
          });
          this.CreatDonationForm.set('date', this.CustomerDonationForm.get('hiringDate').value);
          this.CreatDonationForm.set('totalAmount', Number(this.CustomerDonationForm.get('totalAmount').value));
          this.CreatDonationForm.set('sourceMarketId', Number(this.CustomerDonationForm.get('how').value));
          this.CreatDonationForm.set('comment', this.CustomerDonationForm.get('comment').value);
          this.CreatDonationForm.set('donationMainTypeId', this.donationMainTypeId);
          this.CreatDonationForm.set('companyId', this.CompanyId);
          this.CreatDonationForm.set('customerId', this.customerid);
          this.CreatDonationForm.set('DonationDetails', DonationDetailsForm);
          var res
          this.clicked = true;
          this.DonationApiService.InsertCustomrDonationForm(this.CreatDonationForm).subscribe({
            next: (data) => {
              res = data;
              if (res.messageCode == 200) {
                this.err = false;
                Swal.fire({
                  icon: "success",
                  title: "تم إضافه التبرع بنجاح",
                  showConfirmButton: false,
                  timer: 1500,
                });

                this.form.donationtype = null;
                this.form.sector = null;
                this.form.sectortype = null;
                this.form.sectorplace = null;
                this.form.MoneyAmount = null;
                this.form.Date = null;
                this.form.collectorname = null;
                this.form.hiringDate = null;
                this.form.comment = null;
                this.form.how = null;
                this.form.totalAmount = null;
                this.DonationDetails = [];
                this.images = [];
                this.total = 0;
                this.MoneyAmount = 0;
                this.donationMainTypeId
                this.CompanyDataService.NewUpdateCustomerDonation=true;
                this.router.navigateByUrl("content/agent/CustomerDonation", { state: { customerid: this.customerid, callid: this.callid, donationMainTypeId: this.donationMainTypeId } });
                //this.location.back();
              } else {
                this.err = true;
                this.clicked = false;
              }
            },
            error: (error) => {
              this.err = true;
              this.clicked = false;
              Swal.fire({
                icon: 'error',
                title: 'خطأ',
                text: error.error.message,
              })
            },
          });

        }
      }


    }





  }
  InsertCustomerDonation() {
    if (this.CustomerDonationForm.get("hiringDateform").value && this.CustomerDonationForm.get("totalAmountform").value && this.CustomerDonationForm.get("howform").value && this.CustomerDonationForm.get("collectornameform").value && this.CustomerDonationForm.get("commentform").value) {
      if (this.MoneyAmount != Number(this.form.totalAmount)) {
        const remaining = Number(this.form.totalAmount) - this.MoneyAmount
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: "  المبلغ المحدد للتبرع متبقي منه  " + remaining,
        })
      }
      else {
        const collectorid = this.CollectorCity.filter(x => x.employeeName == this.form.collectorname);
        collectorid[0].id;
        var CustomerDonation = {
          "collectorId": Number(collectorid[0].id),
          "customerId": this.customerid,
          "date": this.form.hiringDate.toString(),
          "totalAmount": Number(this.form.totalAmount),
          "sourceMarketId": Number(this.form.how),
          "comment": this.CustomerDonationForm.get("comment").value,
          "donationMainTypeId": this.donationMainTypeId,
          "donationDetails": this.DonationDetailsForm,
          "companyId":this.CompanyId
        }
        var res   
       this.clicked=true;
        this.DonationApiService.InsertCustomrDonation(CustomerDonation).subscribe({
          next: (data) => {
            res = data;
            if (res.messageCode == 200) {
              this.err = false;
              Swal.fire({
                icon: "success",
                title: "تم إضافه التبرع بنجاح",
                showConfirmButton: false,
                timer: 1500,
              });
              this.form.donationtype = null;
              this.form.sector = null;
              this.form.sectortype = null;
              this.form.sectorplace = null;
              this.form.MoneyAmount = null;
              this.form.Date = null;
              this.form.collectorname = null;
              this.form.hiringDate = null;
              this.form.comment = null;
              this.form.how = null;
              this.form.totalAmount = null;
              this.DonationDetails = [];
              this.total = 0;
              this.MoneyAmount = 0;
            } else {
              this.err = true;
            }
          },
          error: (error) => {
            this.err = true;
            this.clicked = false;
            Swal.fire({
              icon: 'error',
              title: 'خطأ',
              text: error.error.message,
            })
          },
        });
      }

    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: "يجب ادخال جميع الحقول",
      })
    }

  }


  UpdateCustomerDonation(updateAndCollect?) {
    this.receiptNumempty = false;
    this.DonationDetailsForm.forEach(element => {
      if (element.receiptNum == null || element.receiptNum == '') {
        this.receiptNumempty = true;
      }
    });
    if (this.MoneyAmount != Number(this.form.totalAmount) && this.donationMainTypeId != 2) {
      const remaining = Number(this.form.totalAmount) - this.MoneyAmount
      Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: "  المبلغ المحدد للتبرع متبقي منه  " + remaining,
      })
    }
    else if (this.receiptNumempty && updateAndCollect) {
      Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: " يجب ادخال جميع ارقام الايصالات  ",
      })
    }
    else if (this.donationMainTypeId != 2 && this.MoneyAmount == Number(this.form.totalAmount)) {
      // if (!this.callid) {
      //   this.callid = null;
      // }
      this.donationdetailsEdit = [];
      this.DonationDetailsForm.forEach(element => {
        if (element.receiptNum == null) {
          if(element.id){
            this.donationdetailsEdit.push({
            "id":element.id,
            "donationTypeId": element.donationTypeId,
            "sectorId": element.sectorId,
            "sectorTypeId": element.sectorTypeId,
            "sectorLocationId": element.sectorLocationId,
            "smallAmount": Number(element.smallAmount),
            "receiptNum": null,
            "receiptId":null,
            "donationId": element.donationId
          })
          }
          else{
            this.donationdetailsEdit.push({
              "id":0,
              "donationTypeId": element.donationTypeId,
              "sectorId": element.sectorId,
              "sectorTypeId": element.sectorTypeId,
              "sectorLocationId": element.sectorLocationId,
              "smallAmount": Number(element.smallAmount),
              "receiptNum": null,
              "receiptId":null,
              "donationId": element.donationId
            })
          }
          

        }
        else {
          let receiptId;
          if(Number(element.receiptId)==0){
            receiptId=null
          }
          else{
            receiptId=Number(element.receiptId)
          }
          if(element.id){
            this.donationdetailsEdit.push({
              "id":element.id,
              "donationTypeId": element.donationTypeId,
              "sectorId": element.sectorId,
              "sectorTypeId": element.sectorTypeId,
              "sectorLocationId": element.sectorLocationId,
              "smallAmount": Number(element.smallAmount),
              "receiptNum": element.receiptNum,
              "receiptId": Number(element.receiptId),
              "donationId": element.donationId
            })
          }
          else{
            this.donationdetailsEdit.push({
              "id":0,
              "donationTypeId": element.donationTypeId,
              "sectorId": element.sectorId,
              "sectorTypeId": element.sectorTypeId,
              "sectorLocationId": element.sectorLocationId,
              "smallAmount": Number(element.smallAmount),
              "receiptNum": element.receiptNum,
              "receiptId": Number(element.receiptId),
              "donationId": element.donationId
            })
          }
         
        }
       


      })
      var DonationDetailsForm = JSON.stringify(this.donationdetailsEdit);
      if (this.donationMainTypeId == 3) {
        this.CreatDonationForm.set('collectorId', "");
      }
      else {
        this.CreatDonationForm.set('collectorId', this.form.collectorname);
        //this.CreatDonationForm.append('collectorId', Number(this.CustomerDonationForm.get('collectorname').value));

      }
      this.images.forEach(element => {
        this.CreatDonationForm.append('images', element)
      });
      this.CreatDonationForm.set('date', this.CustomerDonationForm.get('hiringDate').value);
      this.CreatDonationForm.set('totalAmount', Number(this.CustomerDonationForm.get('totalAmount').value));
      this.CreatDonationForm.set('sourceMarketId', Number(this.CustomerDonationForm.get('how').value));
      this.CreatDonationForm.set('comment', this.CustomerDonationForm.get("comment").value);
      this.CreatDonationForm.set('donationMainTypeId', this.donationMainTypeId);
      this.CreatDonationForm.set('customerId', this.customerid);
      this.CreatDonationForm.set('companyId', this.CompanyId);

      if(this.callid){
              this.CreatDonationForm.set('callId', this.callid);

      }
      this.CreatDonationForm.set('id', this.donationid);
      if (updateAndCollect) {
        this.CreatDonationForm.append('updateAndCollect', true);
      }
      else {
        this.CreatDonationForm.append('updateAndCollect', false);
      }
      // this.CreatDonationForm.append('receiptNum',Number(this.CustomerDonationForm.get('receiptNum').value))
      this.CreatDonationForm.append('DonationDetails', DonationDetailsForm);
      //        var CustomerDonation = {
      //   "collectorId": Number(this.form.collectorname),
      //   "customerId": this.customerid,
      //   "callId": null,
      //   "date": this.form.hiringDate.toString(),
      //   "totalAmount": Number(this.form.totalAmount),
      //   "sourceMarketId":Number(this.form.how),
      //   "comment": this.form.comment,
      //   "donationMainTypeId": this.donationMainTypeId,
      //   "id":this.donationid,
      //   "donationDetails": this.donationdetailsEdit

      // }
      var res
      this.clicked=true;
      this.DonationApiService.UpdateCustomrDonation(this.CreatDonationForm).subscribe({
        next: (data) => {
          res = data;
          if (res.messageCode == 200) {
            this.err = false;
            Swal.fire({
              icon: "success",
              title: "تم تعديل التبرع بنجاح",
              showConfirmButton: false,
              timer: 1500,
            });

            this.form.donationtype = null;
            this.form.sector = null;
            this.form.sectortype = null;
            this.form.sectorplace = null;
            this.form.MoneyAmount = null;
            this.form.Date = null;
            this.form.collectorname = null;
            this.form.hiringDate = null;
            this.form.comment = null;
            this.form.how = null;
            this.form.totalAmount = null;
            this.DonationDetails = [];
            this.donationdetailsEdit = [];
            this.total = 0;
            this.MoneyAmount = 0;
            //this.router.navigateByUrl("content/agent/CustomerDonation",{ state: {customerid:this.customerid,callid:this.callid} });
            this.CompanyDataService.NewUpdateCustomerDonation=true;
            
         if(this.GoToStateDetails){

          this.CompanyDataService.NewUpdateCustomerDonation=null
          this.router.navigateByUrl("content/admin/statsdetails",{state:{Donations:this.stateDetailsDonations,data:this.stateDetailsData,FromDonationEdit:"FromDonationEdit"}});

         }
         else if (this.GotoReceivedPage){
          this.CompanyDataService.NewUpdateCustomerDonation=null
          this.router.navigateByUrl("content/donation/received");

         }
         else if (this.GoToDonationTodayPage){
          this.CompanyDataService.NewUpdateCustomerDonation=null
          this.router.navigateByUrl("content/donation/donationtoday");
         }
         else if (this.GoToRequisitiondetailsPage){
          this.CompanyDataService.NewUpdateCustomerDonation=null
          this.CompanyDataService.CompanyInfo.CompanyIcon=this.companyImage
          this.router.navigate(['content/collectormanager/close-requisition/requisitiondetails/',this.colseRequistionId]);
         }
         
         else if (this.GoToClosedRequisitiondetailsPage){
          this.CompanyDataService.NewUpdateCustomerDonation=null
          this.CompanyDataService.CompanyInfo.CompanyIcon=this.companyImage

          this.router.navigate(['content/collectormanager/closed-requisition/closedrequisitiondetails/',this.colsedRequistionId]);
         }
         else{
                      this.router.navigateByUrl("content/agent/CustomerDonation", { state: { customerid: this.customerid, callid: this.callid, donationMainTypeId: this.donationMainTypeId } });

         }

            // this.location.back();
          } else {
            this.err = true;
            this.clicked = false
          }
        },
        error: (error) => {
   
          this.err = true;
          this.clicked = false
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: error.error.message,
          })
        },
      });
    }
    else {

      if (!this.callid) {
        this.callid = null;
      }
      this.DonationDetailsForm.forEach(element => {
      let receiptId;
      if(Number(element.receiptId)==0){
        receiptId=null
      }
      else{
        receiptId=Number(element.receiptId)
      }
      if(element.id){
        this.donationdetailsEdit.push({
          "id":element.id,
          "donationTypeId": element.donationTypeId,
          "donationCategoryId": element.donationCategoryId,
          "quantity": element.quantity,
          "receiptNum": element.receiptNum,
          "receiptId": receiptId,
          "donationId": element.donationId
        })



      }
      else{
        this.donationdetailsEdit.push({
          "id":0,
          "donationTypeId": element.donationTypeId,
          "donationCategoryId": element.donationCategoryId,
          "quantity": element.quantity,
          "receiptNum": element.receiptNum,
          "receiptId": receiptId,
          "donationId": element.donationId
        })



      }
       
      })
      var DonationDetailsForm = JSON.stringify(this.donationdetailsEdit);
      if (this.donationMainTypeId == 3) {
        this.CreatDonationForm.append('collectorId', "");
      }
      else {
        this.CreatDonationForm.append('collectorId', this.form.collectorname);
        //this.CreatDonationForm.append('collectorId', Number(this.CustomerDonationForm.get('collectorname').value));

      }

      this.images.forEach(element => {
        this.CreatDonationForm.append('images', element)
      });
      this.CreatDonationForm.append('date', this.CustomerDonationForm.get('hiringDate').value);
      this.CreatDonationForm.append('totalAmount', Number(this.CustomerDonationForm.get('totalAmount').value));
      this.CreatDonationForm.append('sourceMarketId', Number(this.CustomerDonationForm.get('how').value));
      this.CreatDonationForm.append('comment', this.CustomerDonationForm.get('comment').value);
      this.CreatDonationForm.append('donationMainTypeId', this.donationMainTypeId);
      this.CreatDonationForm.append('customerId', this.customerid);
      this.CreatDonationForm.append('companyId', this.CompanyId);
      if(this.callid){
        this.CreatDonationForm.append('callId', this.callid);
      }
      this.CreatDonationForm.append('id', this.donationid);
      if (updateAndCollect) {
        this.CreatDonationForm.append('updateAndCollect', true);
      }
      else {
        this.CreatDonationForm.append('updateAndCollect', false);
      }
      
      // this.CreatDonationForm.append('receiptNum',Number(this.CustomerDonationForm.get('receiptNum').value))
      this.CreatDonationForm.append('DonationDetails', DonationDetailsForm);
      var res
      this.clicked=true;
      this.DonationApiService.UpdateCustomrDonation(this.CreatDonationForm).subscribe({
        next: (data) => {
          res = data;
          if (res.messageCode == 200) {
            this.err = false;
            Swal.fire({
              icon: "success",
              title: "تم تعديل التبرع بنجاح",
              showConfirmButton: false,
              timer: 1500,
            });
            this.form.donationtype = null;
            this.form.sector = null;
            this.form.sectortype = null;
            this.form.sectorplace = null;
            this.form.MoneyAmount = null;
            this.form.Date = null;
            this.form.collectorname = null;
            this.form.hiringDate = null;
            this.form.comment = null;
            this.form.how = null;
            this.form.totalAmount = null;
            this.DonationDetails = [];
            this.donationdetailsEdit = [];
            this.total = 0;
            this.MoneyAmount = 0;
            this.CompanyDataService.NewUpdateCustomerDonation=true;
            if(this.GoToStateDetails){
       
             this.CompanyDataService.NewUpdateCustomerDonation=null

             this.router.navigateByUrl("content/admin/statsdetails",{state:{Donations:this.stateDetailsDonations,data:this.stateDetailsData,FromDonationEdit:"FromDonationEdit"}});
   
            }
            else if (this.GotoReceivedPage){
              this.CompanyDataService.NewUpdateCustomerDonation=null
             this.router.navigateByUrl("content/donation/received");
   
            }
            else if (this.GoToDonationTodayPage){
              this.CompanyDataService.NewUpdateCustomerDonation=null
             this.router.navigateByUrl("content/donation/donationtoday");
            }
            else if (this.GoToRequisitiondetailsPage){
              this.CompanyDataService.NewUpdateCustomerDonation=null
              this.CompanyDataService.CompanyInfo.CompanyIcon=this.companyImage

              this.router.navigate(['content/collectormanager/close-requisition/requisitiondetails/',this.colseRequistionId]);
             }
             else if (this.GoToClosedRequisitiondetailsPage){
              this.CompanyDataService.NewUpdateCustomerDonation=null
              this.CompanyDataService.CompanyInfo.CompanyIcon=this.companyImage

              this.router.navigate(['content/collectormanager/closed-requisition/closedrequisitiondetails/',this.colsedRequistionId]);
             }
            else{
                         this.router.navigateByUrl("content/agent/CustomerDonation", { state: { customerid: this.customerid, callid: this.callid, donationMainTypeId: this.donationMainTypeId } });
   
            }
            //this.router.navigateByUrl("content/agent/CustomerDonation",{ state: {customerid:this.customerid,callid:this.callid} });
           // this.location.back();
          } else {
            this.err = true;
            this.clicked=false;

          }
        },
        error: (error) => {
          this.err = true;
          this.clicked=false;
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: error.error.message,
          })
        },
      });
    }
  }

  onChangeDonationCat(id) {
    this.GetDonationTypeByCatId(id);
    this.CustomerDonationForm.patchValue({
      donationtypecat: null
    })
  }
  _InitForm() {
    this.CustomerDonationForm = this._formBuilder.group({
      hiringDateform: ['', Validators.required],
      totalAmountform: ['', Validators.required],
      howform: ['', Validators.required],
      collectornameform: ['', Validators.required],
      commentform: ['', Validators.required],
      Dateform: ['', Validators.required],
      Employeeform: ['', Validators.required],
      donationtypeform: ['', Validators.required],
      sectorform: ['', Validators.required],
      sectortypeform: ['', Validators.required],
      sectorplaceform: ['', Validators.required],
      MoneyAmountform: ['', Validators.required],
    });
  }
  DeleteDonationDetails(i) {

    this.MoneyAmount -= Number(this.DonationDetails[i].totalAmount)
    
    if(this.DonationDetails[i].receiptstate==0){
      
      const receipt= this.CollectorAvailableReceiptsCancel.filter(x=>x.id==this.DonationDetailsForm[i].receiptId);
      if(receipt.length!=0){
               this.CollectorAvailableReceipts.unshift(receipt[0]);

      }
      }
    if(this.DonationDetails[i].id){
          this.DonationDetails=this.DonationDetails.filter(x=>x.id!=this.DonationDetails[i].id);
    this.DonationDetailsForm=this.DonationDetailsForm.filter(x=>x.id!=this.DonationDetailsForm[i].id);

    }
    else{
      // this.DonationDetails.splice(this.DonationDetails[i],1)
      // this.DonationDetailsForm.splice(this.DonationDetailsForm[i],1)

      this.DonationDetails=this.DonationDetails.filter((item, indexForDelete) => indexForDelete !== i)
      this.DonationDetailsForm=this.DonationDetailsForm.filter((item, indexForDelete) => indexForDelete!== i)
    }
   


    if (this.edit) {
      this.edit = false;
    }
    if (this.DonationDetails.length == 0) {
      this.MoneyAmount = 0;
      this.form.donationtype = null;
      this.form.sector = null;
      this.form.sectortype = null;
      this.form.sectorplace = null;
      this.form.MoneyAmount = null;
    }
       this.CollectorAvailableReceipts=this.CollectorAvailableReceipts.filter(x=>x.bookNumber);
       if(this.CollectorAvailableReceipts.length!=0){
        this.selectedReceipt= this.CollectorAvailableReceipts[0]?.id
        this.receipt= this.CollectorAvailableReceipts[0]?.number
       }
       else{
         this.selectedReceipt=null;
         this.receipt=null;
       }
    
  }
  go() {

    //this.router.navigateByUrl("NewCusomterDonation");
    this.location.back();


  }
  GetCollectorAvailableReceipts(inLoad?){
      // this.CollectorId=this.CollectorId
    this.ReceiptBookService.GetCollectorAvailableReceipts(this.donationMainTypeId,this.CompanyId,this.CollectorId).subscribe((res:any)=>{
      this.CollectorAvailableReceipts=res.data;
      this.CollectorAvailableReceiptsCancel=res.data;
    this.CollectorAvailableReceiptsNew=res.data;
    if(inLoad){
        this.receipt=this.CollectorAvailableReceipts[0]?.number

    }
      // this.AllCollectorReceipts.forEach(element => {
      //   this.CollectorAvailableReceipts.push(element.receipt
      //     )  
      // });
      this.DonationDetailsForm.forEach(element => {
       
        const index=  this.CollectorAvailableReceipts.findIndex(x=>x.number==element.receiptNum);
     if(index!=-1){
        if(this.CollectorAvailableReceipts[index]?.number==element.receiptNum){
          this.CollectorAvailableReceipts.splice(index,1)
        }
     }
       
      });
    //  this.CustomerDonationForm.controls['receiptNum'].disable()
        this.selectedReceipt=this.CollectorAvailableReceipts[0].id
      
      

    })
   
    
  }
  open(content) {
    this.DonationDetails.forEach(element => {
      if(element.receiptNum!=null){
       
        const index=this.CollectorAvailableReceiptsNew.findIndex(x=>x.number==element.receiptNum)
       if(index!=-1){
           this.CollectorAvailableReceiptsNew.splice(index,1);
     this.CollectorAvailableReceiptsNew=[...this.CollectorAvailableReceiptsNew]
       }
      
   
      }
    });
 //   this.CreatDonationForm.get('Collector').setValue(this.CollectorId)
 
 if(this.receiptNumSelect){
  
  if(this.receipt!=null){
    if(!this.edit){
  // const receiptindex=  this.CollectorAvailableReceiptsNew.findIndex(x=>x.number==this.receipt);
  const receiptindex=  this.CollectorAvailableReceipts.findIndex(x=>x.id==this.CustomerDonationForm.get('receiptNum').value);
if(receiptindex!=-1){
  const receiptindexnew=  this.CollectorAvailableReceipts.findIndex(x=>x.id==this.CustomerDonationForm.get('receiptNum').value);
  this.receiptIdFromDeatil=this.CustomerDonationForm.get('receiptNum').value
  this.receiptNumFromDeatil= this.CollectorAvailableReceipts[receiptindexnew]?.number
this.CancelReceiptForm.get('CanceledReceipt').setValue(this.CollectorAvailableReceipts[receiptindex].id)
this.CollectorAvailableReceiptsNew=this.CollectorAvailableReceiptsNew.filter(x=>x.id!=this.CollectorAvailableReceipts[receiptindex].id)

if(this.donationdetailsindex){
  if(this.DonationDetails[this.donationdetailsindex].hasReceipt!=false&&!this.DonationDetails[this.donationdetailsindex].DonationDetailsId){
  this.CollectorAvailableReceiptsNew=this.CollectorAvailableReceiptsNew.filter(x=>x.bookNumber||x.DonationDetailsId)
}
}
else{
  this.CollectorAvailableReceiptsNew=this.CollectorAvailableReceiptsNew.filter(x=>x.bookNumber||x.DonationDetailsId)

}

}
    }
    else{
      const receiptindex=  this.CollectorAvailableReceiptsNew.findIndex(x=>x.number==this.receipt);
      if(receiptindex!=-1){
      
        this.receiptIdFromDeatil=this.DonationDetails[this.donationdetailsindex].receiptId
        this.receiptNumFromDeatil= this.DonationDetails[this.donationdetailsindex].receiptNum
   //   this.CancelReceiptForm.get('CanceledReceipt').setValue(this.CollectorAvailableReceipts[receiptindex].id)
      this.CollectorAvailableReceiptsNew=this.CollectorAvailableReceiptsNew.filter(x=>x.id!=this.CollectorAvailableReceipts[receiptindex].id)
      if(this.donationdetailsindex){
      if(this.DonationDetails[this.donationdetailsindex].hasReceipt!=false&&!this.DonationDetails[this.donationdetailsindex].DonationDetailsId){
          this.CollectorAvailableReceiptsNew=this.CollectorAvailableReceiptsNew.filter(x=>x.bookNumber||x.DonationDetailsId)

  }
}
    }
    }

    
  

}
 
 }
 else{
 // this.CancelReceiptForm.get('Receipts').setValue(this.CustomerDonationForm.get("receiptNum").value)
 if(this.CollectorAvailableReceiptsNew.filter(x=>x.number==this.receipt).length!=0){
 this.receiptIdFromDeatil=this.CollectorAvailableReceiptsNew.filter(x=>x.number==this.receipt)[0].id;
 this.receiptNumFromDeatil= this.CollectorAvailableReceiptsNew.filter(x=>x.number==this.receipt)[0].number
//  if(this.edit){
    this.CollectorAvailableReceiptsNew=this.CollectorAvailableReceiptsNew.filter(x=>x.number!=this.receipt)

//  }
 }

 }
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  getDismissReason(reason?: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.CollectorAvailableReceiptsNew=this.CollectorAvailableReceipts
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.CollectorAvailableReceiptsNew=this.CollectorAvailableReceipts
      this.selectedReceipt=this.receiptIdFromDeatil;
      return 'by clicking on a backdrop';
    } else {
  this.CollectorAvailableReceiptsNew=this.CollectorAvailableReceipts
      return `with: ${reason}`;
    }
  }
  GetCollectorReceipts(e){
    if(e==null){
      if(this.edit){
this.ClearInEdit=true;
      }
      this.receiptIdFromDeatil=null;
      this.receiptNumFromDeatil=null;
    }
   
  }
  CancelReceipt(){
    if (this.CancelReceiptForm.status == "VALID") {
      if(this.DonationDetailsForm.length!=0){
        Swal.fire({
          title: " تحذير !",
          text: "هل انت متأكد من الغاء هذا الايصال ؟ ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: '#7364f6',
          cancelButtonColor: "#d33",
          confirmButtonText: "حذف",
          cancelButtonText: "رجوع",
        }).then((result) => {
          if (result.isConfirmed) {

            if(!this.donationDetailId){
              this.donationDetailId=null;
              if(this.CancelReceiptForm.get("Receipts").dirty){
                this.receiptIdCancel=this.CancelReceiptForm.get("Receipts").value
              }
              else{
                this.receiptIdCancel=null
              }
              const data ={
                "id": this.receiptIdFromDeatil,
                "cancellationReason": this.CancelReceiptForm.get("CancelReason").value,
                "donationDetailId": this.donationDetailId,
                "receiptId":  this.receiptIdCancel
              }
                this.ReceiptBookService.CancelReceipt(data).subscribe((res:any)=>{
            //  if(this.receiptNumSelect&&this.receiptNumView){
              
               const index=this.CollectorAvailableReceiptsNew.findIndex(x=>x.id==this.CancelReceiptForm.get("Receipts").value);
               if(index!=-1){
                 this.receipt=this.CollectorAvailableReceiptsNew[index]?.number;
                this.receiptId=this.CollectorAvailableReceiptsNew[index]?.id;
                this.receiptNumFromDeatil=this.receipt
               }
               else{
                  this.receipt=this.CollectorAvailableReceiptsNew[0]?.number;
                this.receiptId=this.CollectorAvailableReceiptsNew[0]?.id;
                this.receiptNumFromDeatil=this.receipt
                if(this.CollectorAvailableReceiptsNew.length==1){
                  this.receipt=null
                }
              
               }
               
  
                this.DonationDetailsForm.forEach(element => {
                  if(element.receiptNum==this.receipt&&this.CancelReceiptBefore){
                    const index=  this.CollectorAvailableReceipts.findIndex(x=>x.number==this.receipt);
                    this.receipt=this.CollectorAvailableReceipts[index+1]?.number
                    this.receiptId=this.CollectorAvailableReceipts[index+1]?.id
                    this.receiptNumFromDeatil=this.receipt
                  }
                  
                  this.CollectorAvailableReceipts=this.CollectorAvailableReceipts.filter(x=>x.number!==this.receiptNumFromDeatil)
  
                },err=>{
              Swal.fire({
                icon: 'error',
                title: 'خطأ',
                text: err.error.message,
              })
                });
              
              if(this.edit){
               this.DonationDetails[this.donationdetailsindex].receiptNum=this.receipt
                this.DonationDetailsForm[this.donationdetailsindex].receiptNum=this.receipt
                this.DonationDetails[this.donationdetailsindex].receiptId=this.receiptId
                this.DonationDetailsForm[this.donationdetailsindex].receiptId=this.receiptId
                const recind=   this.CollectorAvailableReceipts.findIndex(x=>x.number==this.receipt)
              if(this.CollectorAvailableReceipts.length!=0){
                this.receipt=this.CollectorAvailableReceipts[recind+1]?.number;
                this.receiptNumFromDeatil=this.receipt
              }
              else{
                this.receipt=null
              }
                
  
              }
  
           
                this.CancelReceiptBefore=true;
                this.form.donationtype = null;
                this.form.sector = null;
                this.SectorType = []
                this.SectorLocation = []
                this.form.sectortype = null;
                this.form.sectorplace = null;
                this.form.MoneyAmount = null
                this.form.receiptNum = null;
                this.form.donationtypecat = null;
                this.form.donationcat = null;
              //  this.DonationTypes = []
              this.donationDetailId=null;
                this.form.Quantity = null
                this.form.receiptNum = null;
                this.edit = false;
               // this.receipt=null;
                this.CancelReceiptForm.reset()
                this.GetCollectorAvailableReceipts();       
                this.modalService.dismissAll()
                Swal.fire({
                  icon: "success",
                  title: "تم إلغاء الايصال بنجاح",
                  showConfirmButton: false,
                  timer: 1500,
                });
              },err=>{
                Swal.fire({
                  icon: 'error',
                  title: 'خطأ',
                  text: err.error.message,
                })
                this.CollectorAvailableReceipts=this.CollectorAvailableReceipts.filter(x=>x.number!==this.receiptNumFromDeatil)
          this.selectedReceipt=this.CollectorAvailableReceipts[0]?.id;
                // this.modalService.dismissAll()
                  })
            }
            else{
              if(this.CancelReceiptForm.get("Receipts").dirty){
             
                  this.receiptIdCancel=this.CancelReceiptForm.get("Receipts").value
                const data ={
                  "id": this.receiptIdFromDeatil,
                  "cancellationReason": this.CancelReceiptForm.get("CancelReason").value,
                  "donationDetailId": this.donationDetailId,
                  "receiptId":  this.receiptIdCancel
                }
                  this.ReceiptBookService.CancelReceipt(data).subscribe((res:any)=>{
              //  if(this.receiptNumSelect&&this.receiptNumView){
                 const index=this.CollectorAvailableReceiptsNew.findIndex(x=>x.id==this.CancelReceiptForm.get("Receipts").value);
                 if(index!=-1){
                   this.receipt=this.CollectorAvailableReceiptsNew[index]?.number;
                  this.receiptId=this.CollectorAvailableReceiptsNew[index]?.id;
                  this.receiptNumFromDeatil=this.receipt
                 }
                 else{
                  this.receipt=this.CollectorAvailableReceiptsNew[0]?.number;
                  this.receiptId=this.CollectorAvailableReceiptsNew[0]?.id;
                  this.receiptNumFromDeatil=this.receipt
                 }
                  this.DonationDetailsForm.forEach(element => {
                    if(element.receiptNum==this.receipt&&this.CancelReceiptBefore){
                      const index=  this.CollectorAvailableReceipts.findIndex(x=>x.number==this.receipt);
                      this.receipt=this.CollectorAvailableReceipts[index+1]?.number
                      this.receiptId=this.CollectorAvailableReceipts[index+1]?.id
                      this.receiptNumFromDeatil=this.receipt
                    }
                    this.CollectorAvailableReceipts=this.CollectorAvailableReceipts.filter(x=>x.number!==this.receiptNumFromDeatil)
    
                  },err=>{
                Swal.fire({
                  icon: 'error',
                  title: 'خطأ',
                  text: err.error.message,
                })
                  });
                if(this.edit){
                 this.DonationDetails[this.donationdetailsindex].receiptNum=this.receipt
                  this.DonationDetailsForm[this.donationdetailsindex].receiptNum=this.receipt
                  this.DonationDetails[this.donationdetailsindex].receiptId=this.receiptId
                  this.DonationDetailsForm[this.donationdetailsindex].receiptId=this.receiptId
                  const recind=   this.CollectorAvailableReceipts.findIndex(x=>x.number==this.receipt)
                  if(this.CollectorAvailableReceipts.length!=0){
                  this.receipt=this.CollectorAvailableReceipts[recind+1]?.number;
                  this.receiptNumFromDeatil=this.receipt
                  }
                  else{
                    this.receipt=null
                    this.receiptNumFromDeatil=null
                  }
    
                }
    
             
                  this.CancelReceiptBefore=true;
                  this.form.donationtype = null;
                  this.form.sector = null;
                  this.SectorType = []
                  this.SectorLocation = []
                  this.form.sectortype = null;
                  this.form.sectorplace = null;
                  this.form.MoneyAmount = null
                  this.form.receiptNum = null;
                  this.form.donationtypecat = null;
                  this.form.donationcat = null;
                  this.donationDetailId=null;
                //  this.DonationTypes = []
                  this.form.Quantity = null
                  this.form.receiptNum = null;
                  this.edit = false;
                 // this.receipt=null;
                  this.CancelReceiptForm.reset()
                  this.GetCollectorAvailableReceipts();       
                  this.modalService.dismissAll()
                  Swal.fire({
                    icon: "success",
                    title: "تم إلغاء الايصال بنجاح",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                },err=>{
                  Swal.fire({
                    icon: 'error',
                    title: 'خطأ',
                    text: err.error.message,
                  })
                  this.CollectorAvailableReceipts=this.CollectorAvailableReceipts.filter(x=>x.number!==this.receiptNumFromDeatil)
             this.selectedReceipt=this.CollectorAvailableReceipts[0]?.id;
                  // this.modalService.dismissAll()
                    })
              }
              else{
                Swal.fire({
                  icon: 'error',
                  title: 'خطأ',
                  text: "يجب اختيار الايصال الجديد",
                })
              }
            }
            
          
         
            
          } else {
            // Swal.fire(
            //   'Your appointment still active ',
            // );
          }
        });
      }
      else{
        Swal.fire({
                    icon: 'error',
                    title: 'خطأ',
                    text: "لا يوجد تبرعات فرعية",
                  })
                  this.CancelReceiptForm.reset();
                  this.modalService.dismissAll();
      }

    }
    else{
      this.CancelReceiptForm.markAllAsTouched();
    }
    
    

  }
 
  SendSMSTemplate(item?) {
    localStorage.removeItem("donationId");
    localStorage.removeItem("customerid");
    if (item) {
      localStorage.setItem("donationId", this.donationid);
    }
    else {
      localStorage.setItem("customerid", this.customerid);
    }
    //ModalComponent is component name where modal is declare
    const modalRef = this.modalService.open(SendSMSComponent);
     modalRef.componentInstance.CompanyId = this.CompanyId;
    modalRef.result.then((result) => {
    }).catch((error) => {
     
    });
  }
}
