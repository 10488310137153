import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SmsTemplateService } from 'src/app/shared/API-Service/sms-template.service';
import { GenericResponseSingle } from 'src/app/shared/Models/GenericResponseSingle';
import { GetAllSector } from 'src/app/shared/Models/get-all-sector';
import { GetSourceMarket } from 'src/app/shared/Models/get-source-market';
import { getCitiesWithGovernorate } from 'src/app/shared/Models/getCitiesWithGovernorate';
import { GetGovernorate } from 'src/app/shared/Models/GetGovernorate';
import { GetSectorLocationInSectorTypes } from 'src/app/shared/Models/GetSectorLocationInSectorTypes';
import { GetSectorTypesInSector } from 'src/app/shared/Models/GetSectorTypesInSector';
import { InsertBulkSms } from 'src/app/shared/Models/InsertBulkSms';
import Swal from 'sweetalert2';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';

@Component({
  selector: 'app-bulk-sms',
  templateUrl: './bulk-sms.component.html',
  styleUrls: ['./bulk-sms.component.css']
})
export class BulkSMSComponent implements OnInit {
  InsertForm: FormGroup;
  update: boolean;
  validated: boolean;
  Client_List : any[]=[]
  DonationType: any[];
  response: any;
  SmsTemplates: any[];
  sendstate: boolean;
  customers: any[]=[];
  Cities: any;
  Sectors: any;
  SectorLocation: any;
  SourceMarket: any;
  Governorates: any;
  SendResponse: any;
  SectorType:any;
  BulkSms  = new InsertBulkSms();
  constructor(private CompanyDataService:CompanyDataService ,private _formBuilder: FormBuilder,private SmsTemplateService:SmsTemplateService) {
    this.sendstate=false;
    this.GetDonationType();
    this.getSectors();
    this.GetGovernorate();
    this.GetSourceMarket();
    //this.getSmsTemplates();
    this._InitForm();
   }

  ngOnInit(): void {

  }
  _InitForm() {
    this.InsertForm = this._formBuilder.group({
      name: [''],
      DonationType: [''],
      Sectors :[''],
      SectorType: [''],
      SectorLocation: [''],
      Governorates: [''],
      Cities: [''],
      ClientType: [''],
      SourceMarket: [''],
      SmsTemplates:['',Validators.required]
    });
   // this.imgURL = "./assets/images/statics/personAvatar.png";
    // console.log("imgURL : ",this.imgURL);

  }

  onSubmit(){
    let data={
      
        "donationTypeId":this.InsertForm.get("DonationType").value,
        "sectorId": this.InsertForm.get("Sectors").value,
        "sectorTypeId":this.InsertForm.get("SectorType").value,
        "sectorLocationId": this.InsertForm.get("SectorLocation").value,
        "customerGenderId":  this.InsertForm.get("ClientType").value,
        "governorateId": this.InsertForm.get("Governorates").value,
        "cityId": this.InsertForm.get("Cities").value,
        "sourceMarketId":  this.InsertForm.get("SourceMarket").value,
        "customerName":  this.InsertForm.get("name").value,
      
    }
    this.SmsTemplateService.GetDonatorCustomerPhones(data).subscribe(
       
      (res: any) => {
        this.response=res.data;
        if (res.success) {
      this.customers=  this.response.customers
      // this.customers.push(  {
      //   id: 0,
      //   mobile: "string",
      //   name: "string",
      //   address: "string",
      //   dateOfBirth: "2022-08-21T00:14:09.488Z"
      // })
          // Swal.fire({
          //   icon: 'success',
          //   title: "تم إدخال العميل بنجاح",
          //   showConfirmButton: false,
          //   timer: 1500
          // })
       //   this.router.navigateByUrl("content/admin/GetClient");
        }
        else
        {
          // alert(this.response.message)
        }
      },
      (err) => {
   
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    );
  }
  GetDonationType(){
    this.SmsTemplateService.GetDonationType().subscribe(response=>{
      this.DonationType = response.data 
  })
}


getSectors(){
  this.SmsTemplateService.getSectors(this.CompanyDataService.CompanyInfo.CompanyId).subscribe(response=>{
    this.Sectors = response.data 
})
}

getSectorType(id){
  this.SmsTemplateService.getSectorType(id).subscribe(response=>{
    this.SectorType = response.data 
})
}


getSectorLocation(id){
  this.SmsTemplateService.getSectorLocation(id).subscribe(response=>{
    this.SectorLocation = response.data 
})
}
GetSourceMarket(){
  this.SmsTemplateService.GetSourceMarket().subscribe(response=>{
    this.SourceMarket = response.data 
})
}
GetGovernorate(){
  this.SmsTemplateService.GetGovernorate().subscribe(response=>{
    this.Governorates = response.data 
})
}

SelectedGovernorate(e){
this.GetCitiesWithGovernorate(e);
}

SelectedSectors(e){
  this.getSectorType(e)
}
SelectedSectorType(e){
this.getSectorLocation(e);
}
GetCitiesWithGovernorate(id){
  this.SmsTemplateService.GetCitiesWithGovernorate(id).subscribe(response=>{
    this.Cities = response.data 
})
}
// getSmsTemplates(){
//   this.SmsTemplateService.getSmsTemplates().subscribe(response=>{
//     this.SmsTemplates = response.data 
// })
// }
send(){
if(!this.InsertForm.get("SmsTemplates").value){
  Swal.fire({
    icon: 'error',
    title: 'خطأ',
    text: "يجب اختيار الرساله المراد ارسالها",
  })
}
else{
  let data ={
    "smsTemplateId": Number(this.InsertForm.get("SmsTemplates").value),
    "customers": this.customers

  }
  this.SmsTemplateService.SendBulkSMS(data).subscribe(
       
    (res: any) => {
      this.SendResponse=res;
      if (res.success) {
        Swal.fire({
          icon: "success",
          title: "تم ٌإرسال الرسالة بنجاح",
          showConfirmButton: false,
          timer: 1500,
          });
     //   this.router.navigateByUrl("content/admin/GetClient");
     this.BulkSms.SmsTemplates=null;
     this.customers=[];
      }
      else
      {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: this.response.message,
        })
      }
    },
    (err) => {
  
      Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: err.error.message,
      })
    }
  );
}
  
}
  }

