import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment.prod";
import { GetAllSector } from "../Models/get-all-sector";
import { GenericResponseSingle } from "../Models/GenericResponseSingle";
import { GenericResponse } from "../Models/GenericResponse";

GenericResponseSingle;
@Injectable({
  providedIn: "root",
})
export class SectorsApiService {
  userData = new BehaviorSubject(null);
  sectors: GetAllSector;
  title: string;
  numberofDays: number;

  constructor(private _HttpClient: HttpClient) {}

  getSectors(companyId?:any): Observable<GenericResponse<GetAllSector>> {
 
  if(companyId){
     return this._HttpClient.get<GenericResponse<GetAllSector>>(
      `${environment.Server_URL}/Sector/Get?companyId=${companyId}`
    );
  }
  else{
    return this._HttpClient.get<GenericResponse<GetAllSector>>(
      `${environment.Server_URL}/Sector/Get`
    );
  }
   
  }
  getSectorsList(data :any): Observable<GenericResponse<GetAllSector>> {
    
      return this._HttpClient.post<GenericResponse<GetAllSector>>(
        `${environment.Server_URL}/Sector/GetSectorList`,data
      );
    }
  GetSectorsById(id: number): Observable<GenericResponse<GetAllSector>> {
    return this._HttpClient.get<GenericResponse<GetAllSector>>(
      `${environment.Server_URL}/Sector/GetById?id=${id}`
    );
  }
  GetSectorsByDetail(id: number): Observable<GenericResponse<GetAllSector>> {
    return this._HttpClient.get<GenericResponse<GetAllSector>>(
      `${environment.Server_URL}/Sector/GetDetail?id=${id}`
    );
  }
  InsertSector(form: any): Observable<GenericResponseSingle<GetAllSector>> {
    return this._HttpClient.post<GenericResponseSingle<GetAllSector>>(
      `${environment.Server_URL}/Sector/Create`,
      form
    );
  }

  UpdateSector(
    form: any
  ): Observable<GenericResponseSingle<any>> {
    return this._HttpClient.post<GenericResponseSingle<any>>(
      `${environment.Server_URL}/Sector/Update`,
      form
    );
    // return this._HttpClient.put<GenericResponseSingle<Sector>>(`${environment.Server_URL}/Sector`,data,this.httpOptions);
  }

  DeleteSector(id: string): Observable<GenericResponseSingle<GetAllSector>> {
    return this._HttpClient.get<GenericResponseSingle<GetAllSector>>(
      `${environment.Server_URL}/Sector/Delete?id=${id}`
    );
    // change detete
  }
}
