import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClientTypeApiService } from 'src/app/shared/API-Service/client-type-api.service';
import { GetCompanyType } from 'src/app/shared/Models/GetClientType';
import { InsertClientType, UpdateCompanyType } from 'src/app/shared/Models/insert-client-type';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-client-type',
  templateUrl: './client-type.component.html',
  styleUrls: ['./client-type.component.css']
})
export class ClientTypeComponent implements OnInit {

  //#region Decalre varaibles
  ClientTypeForm: FormGroup;
  _InsertClientType:InsertClientType;
  maxDate: Date;
  update:boolean;
  validated:boolean;
  //#endregion

  //#region  constructor
  constructor(private _formBuilder: FormBuilder,
              private toaster: ToastrService,
              private clientTypeApiService:ClientTypeApiService,
              private router:Router,
              private route: ActivatedRoute) 
  { this.maxDate = new Date(); }
  //#endregion

  //#region  ng OnInit
  ngOnInit(): void {
   
    if(this.route.snapshot.paramMap.get('id')){
      this.clientTypeApiService.title=localStorage.getItem("clientTypeTitle");
      this.InitForm(this.clientTypeApiService.title)
      this.update = true;
      //this.InitForm(this.clientTypeApiService.title)

    }else
    {
      this.update = false;
      this._InitForm();
    }
  }
  //#endregion
  containsNumber(str) {
    const specialChars = /[/\d/٠١٢٣٤٥٦٧٨٩]/;
    return specialChars.test(str);
  }
  //#endregion
  containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }
  containsSpecialSpace(str) {
    const first = Array.from(str)[0];
    if(first==" "){
      return true;
    }
    else {
   return false;
    }
    
  }
  //#region  Init Form
  InitForm(title:string){
    this.ClientTypeForm = this._formBuilder.group({
      firstName: [title, Validators.required],
    });
  }
  _InitForm(){
    this.ClientTypeForm = this._formBuilder.group({
      firstName: ['', Validators.required],
    });
  }
  //#endregion

  //#region  Insert Client-Type Method
  InsertClientType(){ 
//     if(this.containsSpecialChars(this.ClientTypeForm.get("firstName").value)==true){
//       Swal.fire({
//            icon: 'error',
//            title: 'خطأ',
//            text: "لا يمكن كتابه علامات ",
//          })
// }
// else if(this.containsNumber(this.ClientTypeForm.get("firstName").value)==true){
//  Swal.fire({
//       icon: 'error',
//       title: 'خطأ',
//       text: "لا يمكن كتابه ارقام ",
//     })
// }
// else if (this.containsSpecialSpace(this.ClientTypeForm.get("firstName").value)==true){
//   Swal.fire({
//     icon: 'error',
//     title: 'خطأ',
//     text: "لا يمكن كتابه مسافات ",
//   })
// }
// else{
    
    this.clientTypeApiService.InsertClientType({title:this.ClientTypeForm.get('firstName').value} as InsertClientType  ).subscribe(
      response=>{
        Swal.fire({
          icon: 'success',
          title: "تم إدخال نوع عميل بنجاح",
          showConfirmButton: false,
          timer: 1500
        })
        this.router.navigateByUrl("content/admin/Get-client-type");
      },
      err=>{
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
// }
   
  }
  //#endregion

  //#region Update Client
  UpdateClientType(){
//     if(this.containsSpecialChars(this.ClientTypeForm.get("firstName").value)==true){
//       Swal.fire({
//            icon: 'error',
//            title: 'خطأ',
//            text: "لا يمكن كتابه علامات ",
//          })
// }
// else if(this.containsNumber(this.ClientTypeForm.get("firstName").value)==true){
//  Swal.fire({
//       icon: 'error',
//       title: 'خطأ',
//       text: "لا يمكن كتابه ارقام ",
//     })
// }
// else if (this.containsSpecialSpace(this.ClientTypeForm.get("firstName").value)==true){
//   Swal.fire({
//     icon: 'error',
//     title: 'خطأ',
//     text: "لا يمكن كتابه مسافات ",
//   })
// }
// else{
     let id = +this.route.snapshot.paramMap.get('id');
    this.clientTypeApiService.UpdateClientType({Id:id,title:this.ClientTypeForm.get('firstName').value} as UpdateCompanyType).subscribe(
      response=>{
        Swal.fire({
          icon: 'success',
          title: "تم تعديل نوع عميل بنجاح",
          showConfirmButton: false,
          timer: 1500
        })
        this.router.navigateByUrl("content/admin/Get-client-type");
        localStorage.removeItem("clientTypeTitle")
      },
      err=>{
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
// }
 
  }
  //#endregion
  
 
onSubmit() {
  // this.validated = !this.validated

  // this.validated = !this.validated

  if (this.ClientTypeForm.status == "VALID") {
    this.validated = false;

    if (this.update == true) {
      this.UpdateClientType();
    } else {
      this.InsertClientType();
    }
  } else {
    this.validated = true;
  }
}

}
