import { Component, OnInit } from '@angular/core';
import {GetAllCollectorEmployeesApiService} from 'src/app/shared/API-Service/get-all-collector-employees-api.service';
import{CollectorCityApiService}from 'src/app/shared/API-Service/collector-city-api.service'
import { Router } from '@angular/router';
import { CitiesApiService } from 'src/app/shared/API-Service/cities-api.service';
import { GenericResponse } from 'src/app/shared//Models/GenericResponse';
import { getCities } from 'src/app/shared/Models/getCities';
import { getAllCollectorEmployees } from 'src/app/shared//Models/Get-All-Collector-Employees';
import{GetCollector, GetCollectorCity} from 'src/app/shared//Models/Get-collector-city'
import Swal from 'sweetalert2';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
@Component({
  selector: 'app-list-collector-city',
  templateUrl: './list-collector-city.component.html',
  styleUrls: ['./list-collector-city.component.css']
})
export class ListCollectorCityComponent extends BaseComponent implements OnInit {
  response: GetCollectorCity[];
  Response_List: GetCollectorCity[];

  Collector_id: number;
  City_id :number
  Collector_List: getAllCollectorEmployees[];

  Returnedcollector : GetCollector[] ;
  AllCollector: any;
  constructor(private getAllCollectorEmployeesApiService :GetAllCollectorEmployeesApiService,private citiesApiService :CitiesApiService ,private collectorCityApiService: CollectorCityApiService,private router: Router) {super() }

  ngOnInit(): void {
    this.Response_List = [];
    //this.getCollector();
   // this.GetCities();
    this.GetCollectorCity();
    //this.GetCollector();
    
    
  }
  GetCollectorCity(){
    this.collectorCityApiService.GetAllCollectorEmployeesList(this.pager).subscribe(
      (response:any) => {
        this.AllCollector = response.data.items;
        this.totalCount=response.data.totalCount
       
       
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
    
  }
  pageChanged(event:any){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.collectorCityApiService.GetAllCollectorEmployeesList(this.pager).subscribe(
      (response:any) => {
        this.AllCollector = response.data.items;
        this.totalCount=response.data.totalCount
       
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  
  }
  

  
  AddNew() {
    this.router.navigateByUrl("content/admin/Insert-Collector-City");
  }
  Delete(id: number) {
    Swal.fire({
      title: ' تحذير !',
      text: "هل انت متأكد من حذف هذا العنصر ؟ ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7364f6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'حذف',
      cancelButtonText: 'إنهاء',
    })
      .then((result) => {

        if (result.isConfirmed) {
          
          this.collectorCityApiService.DeleteCollector(id).subscribe(
            response => {
              this.GetCollectorCity();
              Swal.fire({
                icon: 'success',
                title: "تم حذف نوع موظف ومدينه تحصيله بنجاح",
                showConfirmButton: false,
                timer: 1500
              })
            },
            err => {
              Swal.fire({
                icon: 'error',
                title: 'خطأ',
                text: err.error.message,
              })
            }
          )

        } else {
          // Swal.fire(
          //   'Your appointment still active ',
          // );
        }
      });
  }
  //#endregion

  //#region update
  update(item) {
 
    this.router.navigateByUrl("content/admin/Insert-Collector-City",{state:{name:item.name,id:item.id}});

  }
  //#endregion

  
  }

