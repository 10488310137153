import { DatePipe } from '@angular/common';
import { SourceOfmarketingService } from './../../../../shared/API-Service/source-ofmarketing.service';
import { DonationRequestsService } from './../../../../shared/API-Service/donation-requests.service';
import { Component, OnInit } from '@angular/core';
import { GetCallCustomerDate } from '../../../../shared//Models/CallCustomerDate';
import { CallReasonApiService } from 'src/app/shared/API-Service/call-reason-api.service';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-get-call-customer-date',
  templateUrl: './get-call-customer-date.component.html',
  styleUrls: ['./get-call-customer-date.component.css']
})
export class GetCallCustomerDateComponent implements OnInit {
  Getcollectors: any[];
  GetCallCustomerDate= new GetCallCustomerDate();
  SectorLocation: any;
  getType: any;
  SectorGet: any[];
  City_list: any;
  GetLookupsGetDonation: any;
  DonationType: any;
  getsourcemarket: any[];
  CallReason_List: any[];
  callType: { id: number; title: string; }[];
  currentUser: any;
  Companies: any[];
  getEmployee: any[];
  Answered:boolean=false;
  AnsweredList: any[];
  constructor(private DonationRequestsService:DonationRequestsService,
    private SourceOfmarketingService:SourceOfmarketingService,public datepipe:DatePipe,private router:Router,
    private CallReasonService: CallReasonApiService,
    private CompanyDataService:CompanyDataService
    ) { 
      this.GetCallCustomerDate.answered=null

      this.callType=[
        {
        id:1,
        title:"صادرة"
        },
        {
            id:2,
            title:"واردة"
        }

]
this.AnsweredList=[
  {
    id:1,
    title:"تم الرد"
    },
    {
        id:2,
        title:"لم يتم الرد"
    }
]
this.currentUser=JSON.parse(localStorage.getItem("RiskCurrentUser"))
if(this.currentUser.roleId==10){
  this.GetCallCustomerDate.companyId=this.currentUser.id
}
this.GetCallCustomerDate.reportCreatedBy=this.currentUser.name;
const date = new Date();
      this.GetCallCustomerDate.callDateFrom=this.datepipe.transform(date, 'yyyy-MM-dd');
      this.GetCallCustomerDate.callDateTo=this.datepipe.transform(date,'yyyy-MM-dd');

    }
    SelectAnswered(AnsweredId){
    if(AnsweredId==1){
      this.GetCallCustomerDate.answered=true
    }
    else if (AnsweredId==2){
      this.GetCallCustomerDate.answered=false
    }
    else{
      this.GetCallCustomerDate.answered=null

    }

    }
    SelectCompany(CompanyId){
      this.GetCallCustomerDate.companyId=parseInt(CompanyId);

    }
    GetCompanies(){
      this.CompanyDataService.GetCompanies().subscribe(res=>{
        this.Companies=res.data
      })
    }
  ngOnInit(): void {
    this.GetCompanies()
   this.getcollectors();
   this.getsourcemarketing();
   this.GetCallReason();
   this.Getemployee();

  }
  selectEmplyee(event){
    this.GetCallCustomerDate.createdById = parseInt(event) 
    this.GetCallCustomerDate.createdByName = this.getEmployee.find(x => x.id == event).name
   }
  
  Getemployee(){
    this.SourceOfmarketingService.EmployeeGet().subscribe(res=>{
      this.getEmployee = res.data
    })
   }
  getcollectors(){
    this.DonationRequestsService.Getcollectors().subscribe(response=>{

      this.Getcollectors = response.data
    })
  }
  getsourcemarketing(){
    this.SourceOfmarketingService.GetAllsourceOfMarketingForReport().subscribe(response=>{
     
          this.getsourcemarket = response.data
    })
  }
  
  selectSourceMarket(event){
    if(event){
          this.GetCallCustomerDate.sourceOfMarktingId = parseInt(event) 
    this.GetCallCustomerDate.sourceOfMarketingName = this.getsourcemarket.find(x => x.id == event).title

    }
  }
  
  selectCallReason(event){
    if(event){
          this.GetCallCustomerDate.callReasonId = parseInt(event) 
    this.GetCallCustomerDate.callReasonName = this.CallReason_List.find(x => x.id == event).title

    }
  }
  selectCallType(event){
    if(event){
          this.GetCallCustomerDate.callTypeId = parseInt(event) 
    this.GetCallCustomerDate.callTypeName = this.callType.find(x => x.id == event).title

    }
  }
  GetCallReason() {
    this.CallReasonService.GetCallReason().subscribe(
      response => {
        this.CallReason_List = response.data;        
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }

Viewreports(){
    if(this.GetCallCustomerDate.companyId!=null&&this.GetCallCustomerDate.callDateFrom &&this.GetCallCustomerDate.callDateTo){
  
  if(this.GetCallCustomerDate.callTypeId==null){
    this.GetCallCustomerDate.callTypeId=null;
    this.GetCallCustomerDate.callTypeName=""
  }
  if(this.GetCallCustomerDate.callReasonId==null){
    this.GetCallCustomerDate.callReasonId=null;
    this.GetCallCustomerDate.callReasonName=""
  }
  if(this.GetCallCustomerDate.sourceOfMarktingId==null){
    this.GetCallCustomerDate.sourceOfMarktingId=null;
    this.GetCallCustomerDate.sourceOfMarketingName=""
  }
  if(this.GetCallCustomerDate.createdById==null){
    this.GetCallCustomerDate.createdById=null;
    this.GetCallCustomerDate.createdByName=""
  }
 
  localStorage["ReportName"]="GetCallCustomerData";
  localStorage.setItem('ReportFilter',JSON.stringify(this.GetCallCustomerDate));

  this.router.navigate([]).then((result) => {
    window.open("/content/admin/call-start-end-report", '_blank');
  });
}
else{
  if(!this.GetCallCustomerDate.companyId){
    Swal.fire({
      icon:'error',
      title:'خطأ',
      text:'يجب اختيار الشركة'

    })
  }
else   if (!this.GetCallCustomerDate.callDateFrom){
    Swal.fire({
      icon:'error',
      title:'خطأ',
      text:' يجب اختيار بدايه تاريخ المكالمات'
    })
  }
  else  if (!this.GetCallCustomerDate.callDateTo ){
    Swal.fire({
      icon:'error',
      title:'خطأ',
      text:' يجب اختيار نهايه تاريخ المكالمات'

    })
  }
}
}


}
