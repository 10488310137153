
<app-breadcrumb [title]="'الانجازات '" [active_item]="'Form Wizard'"></app-breadcrumb>


<!-- <div class="container-fluid"> -->
<div class="card" *ngIf="!modal">
  <!-- <div class="card-body"> -->
    <!-- <div class="d-flex justify-content-end">

      <button class="">dd</button>
    </div> -->
      <div class="table-responsive">
        <table class="table table-striped" style="width: 100%;">
          <thead>
            <tr class="bg-dark text-center">
              <th scope="col" class="font-style-header text-white" >#</th>
              <th scope="col" class="font-style-header text-white" > تاريخ الانجاز</th>
              <th scope="col" class="font-style-header text-white" >  وصف الانجاز</th>
              <th scope="col" class="font-style-header text-white">الاتصال بالعميل</th>
              <th scope="col" class="font-style-header text-white"></th>
            </tr>
          </thead>
          <tbody >
            <tr *ngFor="let item of achevement;let i=index" class="text-center">
              <td scope="row" class="font-style" style="width:20% ;">{{i+1}}</td>
              <td  class="font-style" style="width:10% ;">{{item.accomplishmentInformDate ? (item.accomplishmentInformDate | date:"dd/MM/yyyy"): '----' }}</td>
              <td  class="font-style" style="width:50% !important;white-space: pre-wrap;" [(ngModel)]="_InsertAcheivement.accomplishmentDescription">{{item.accomplishmentDescription}}</td>
              <td  class="font-style" style="width:10% ;" *ngIf="item.customerInformed == true" >
                <input type="checkbox" [checked]="activePM == true" disabled>

              </td>
              <td  class="font-style" style="width:10% ;" *ngIf="item.customerInformed == false">
                <input type="checkbox" [checked]="!activePM" disabled >

              </td>
              <td class="font-style " style="width:10% ;" >
                <button data-toggle="modal" (click)="clickitem(item)" *ngIf="item.accomplishmentDescription==null" class="btn btn-primary pull-right" data-target="#exampleModal">اضافه انجاز</button>
                <button type="submit" (click)="clickitem(item)"  class="btn btn-primary pull-right" data-target="#exampleModal" data-toggle="modal" 
                                *ngIf="item.accomplishmentDescription!=null"
                            
                                  >
                               تعديل انجاز
                          </button>
              </td>


             
              
                 <div class="modal fade" id="exampleModal"   tabindex="-1" aria-labelledby="exampleModalLabel" >
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-dark " > هل تريد اضافه انجاز ؟</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <label for="">الانجاز</label>
      <!-- <input type="text" class="form-control" id="accomplishmentDescription" > -->
      <textarea name="accomplishmentDescription" class="form-control" id="accomplishmentDescription" cols="30" rows="10" [(ngModel)]="item.accomplishmentDescription"></textarea>
      <label for="" style="margin-top: 23px;"> تم الاتصال بالعميل ؟</label>
      <br>
      <label class="switch">
        <input type="checkbox" name="customerInformed" id="pmActive" [checked]="activePM == true" [(ngModel)]="item.customerInformed" />
        <span class="slider round"></span>
      </label>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">رجوع</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="addAccomplishment(item)">حفظ</button>
      </div>
    </div>
  </div>
</div>
            </tr>
          </tbody>
        </table>
      </div> 
    </div>


    <div class="container-fluid top-padding" style="height: 400px; " *ngIf="modal">
      <!-- <div class="card-body"> -->
        <!-- <div class="d-flex justify-content-end">
    
          <button class="">dd</button>
        </div> -->
          <div class="table-responsive">
            <table class="table table-striped" style="width: 100%;">
              <thead>
                <tr class="bg-dark text-center">
                  <th scope="col" class="font-style-header text-white" >#</th>
                  <th scope="col" class="font-style-header text-white" > تاريخ الانجاز</th>
                  <th scope="col" class="font-style-header text-white" >  وصف الانجاز</th>
                  <th scope="col" class="font-style-header text-white">الاتصال بالعميل</th>
                  <th scope="col" class="font-style-header text-white">موبايل العميل</th>
                  <th scope="col" class="font-style-header text-white"></th>
                </tr>
              </thead>
              <tbody >
                <tr *ngFor="let item of achevement;let i=index" class="text-center">
                  <td scope="row" class="font-style" style="width:20% ;">{{i+1}}</td>
                  <td  class="font-style" style="width:10% ;">{{item.accomplishmentInformDate ? (item.accomplishmentInformDate | date:"dd/MM/yyyy"): '----' }}</td>
                  <td  class="font-style" style="width:50% !important;white-space: pre-wrap;" [(ngModel)]="_InsertAcheivement.accomplishmentDescription">{{item.accomplishmentDescription}}</td>
                  <td  class="font-style" style="width:10% ;" *ngIf="item.customerInformed == true" >
                    <input type="checkbox" [checked]="activePM == true" disabled>
    
                  </td>
                  <td  class="font-style" style="width:10% ;" *ngIf="item.customerInformed == false">
                    <input type="checkbox" [checked]="!activePM" disabled >
    
                  </td>
                  <td class="font-style" style="text-align: center; width: 100px;">{{customermobile}} 
                    <br>
                    <div class="social-media" >
                      <a [href]="'tel:' + customermobile" ><i class="fa fa-phone" style="color: green;"></i></a>
                     <a [href]="'//wa.me/+2' + customermobile" target="_blank"><i class="fa fa-whatsapp" style="padding: 0 17px; color: #2cc198;" ></i></a> 
                    <a><i class="fa fa-comment" (click)="SendSMSTemplate(item)"></i></a>  
                      
                    </div>
                  </td>
                  <td class="font-style " style="width:10% ;" >
                  <a style="cursor: pointer;" title="اضافة انجاز">  <i class="fa fa-plus" style="color: #2cc198;"  data-toggle="modal"  (click)="clickitem(item)" *ngIf="item.accomplishmentDescription==null" data-target="#exampleModal"></i></a>
                             <a style="cursor: pointer;" title="تعديل انجاز">  <i class="fa fa-pencil" style="color: crimson;" (click)="clickitem(item)"   data-target="#exampleModal" data-toggle="modal" 
                              *ngIf="item.accomplishmentDescription!=null"></i></a>
                  </td>
    
    
                 
                  
                     <div class="modal fade" id="exampleModal"   tabindex="-1" aria-labelledby="exampleModalLabel" >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title text-dark " > هل تريد اضافه انجاز ؟</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label for="">الانجاز</label>
          <!-- <input type="text" class="form-control" id="accomplishmentDescription" > -->
          <textarea name="accomplishmentDescription" class="form-control" id="accomplishmentDescription" cols="30" rows="10" [(ngModel)]="item.accomplishmentDescription"></textarea>
          <label for="" style="margin-top: 23px;"> تم الاتصال بالعميل ؟</label>
          <br>
          <label class="switch">
            <input type="checkbox" id="pmActive" [checked]="activePM == true" [(ngModel)]="item.customerInformed" />
            <span class="slider round"></span>
          </label>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">رجوع</button>
            <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="addAccomplishment(item)">حفظ</button>
          </div>
        </div>
      </div>
    </div>
                </tr>
              </tbody>
            </table>
          </div> 
        </div>
  <!-- </div> -->
    
<!-- </div> -->
