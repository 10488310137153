
export class InsertCall {
    reason          ?: string;
    description     ?: string;
    notes           ?: string;
    callType        ?: number;
    creationDate    ?: Date;
    endCall         ?: string;
    startCall       ?: string;
    satisfy         ?: boolean;
    sourceMarketId  ?: number;
    callReasonId    ?: number;
    customerId      ?: string;
    AgentId         ?: string;
    
    createdBy       ?: number;
    companyId       ?:number
}
