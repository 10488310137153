import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientApiService } from 'src/app/shared/API-Service/client-api.service';
import { GenericResponse } from 'src/app/shared/Models/GenericResponse';
import { GetClient } from 'src/app/shared/Models/GetClient';
import { GetCompanyType } from 'src/app/shared/Models/GetClientType';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { environment } from 'src/environments/environment.prod';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-Client',
  templateUrl: './Client.component.html',
  styleUrls: ['./Client.component.css']
})
export class ClientComponent extends BaseComponent implements OnInit {
img:string=environment.Server_URL_Image
  //#region  Declare Variables
  response: GenericResponse<GetClient>;
  Client_List: any[]=[];
  SeeMoreView: boolean;
  disableBtn: boolean;
  //#endregion

  //#region constructor
  constructor(private ApiService: ClientApiService , private router:Router) {
    super()
   }
  //#endregion

  //#region  ng OnInit
  ngOnInit(): void {
    this.Client_List = [];
    this.GetClient();
    
  }
  //#endregion

  //#region Consume API's

  //#region  Get Client
  GetClient() {
    this.SeeMoreView=false
    this.ApiService.GetClientPagintaion(this.pager).subscribe(
      (response:any) => {
        this.response = response;
        this.Client_List = response.data.items;   
        this.totalCount = response.data.totalCount;     
        this.SeeMoreView=true;
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }
  pageChanged(){
    this.disableBtn=true
    this.pager.skipCount = this.Client_List.length;
    this.ApiService.GetClientPagintaion(this.pager).subscribe(
      (response:any) => {
        
        // this.response = response;
        response.data.items.map(x=>this.Client_List.push(x)) ;
        if(response.data.items.length==0){
          this.SeeMoreView=false
        }  
        // if(response.data.items){
        //   this.Client_List.push(response.data.items);   
        // }
        this.totalCount = response.data.totalCount;
     this.disableBtn=false
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )

  }
  //#endregion

  //#region  Delete Client 
  DeleteClient(id){    
    Swal.fire({
      title: ' تحذير !',
      text: "هل انت متأكد من حذف هذا العنصر ؟ ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7364f6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'حذف',
      cancelButtonText: 'إنهاء',
    })
    .then((result) => {

      if (result.isConfirmed) {
          this.ApiService.DeleteClient(id).subscribe(
            response=>{
              this.Client_List=this.Client_List.filter(x=>x.id!=id);
               Swal.fire({
                    icon: 'success',
                    title: "تم حذف العميل بنجاح",
                    showConfirmButton: false,
                    timer: 1500}) 
                    this.router.navigateByUrl("content/admin/GetClient");
                  },
            err=>{
              Swal.fire({
                icon: 'error',
                title: 'خطأ',
                text: err.error.message,
              })
            }
          )
        
      } else {
        // Swal.fire(
        //   'Your appointment still active ',
        // );
      }
    }); 
  }
  //#endregion
  
  //#endregion

  //#region Add New
  NavigateToInsertClient(){
    this.router.navigateByUrl("content/admin/InsertClient");
  }
  //#endregion

  //#region update Client
  // updateClient(Client:GetClient){
  //   this.ApiService.Client = Client;
    
  //   this.router.navigate(['content/admin/updateClient',Client.clientId]);
  // }

  // updateClient(id:number){
  // //  this.ApiService.Client = Client;
    
  //   this.router.navigate(['content/admin/updateClient',id]);
  //   //    this.router.navigate(['content/admin/update-city', cityid]);

  // }


  updateClient(id:number){

    //  this.ApiService.Employee = model;
    
     this.router.navigateByUrl("content/admin/updateClient",{state:{Clientid:id}});
      //this.router.navigate(['content/admin/updateClient',id]);
    }
  //#endregion

}
