<app-breadcrumb [title]="'أمر توريد المحصلين'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'">
</app-breadcrumb>
<div class="card">
    <div class="container-fluid">
        <!-- <form action="" [formGroup]="createform"> -->
    <div class="row">
      <div class="col-4 col-12">
        <label for="validationCustom02"><span class="validation-required">*</span>  اسم الشركة</label>
        <br>
        <label *ngIf="currentUser.roleId==10">{{currentUser.name}}</label>
  <ng-select *ngIf="currentUser.roleId!=10"
  class="js-example-basic-single col-sm-12 job-select2 form-group"
    required=""
    [(ngModel)]="BatchesReport.companyId"
    aria-label="Default select example"
    (change)="SelectCompany($event.id)"
    [items]="Companies"
    bindLabel="name"
    bindValue="id"
     >
  
  </ng-select>
      </div>
        <div class="col-md-4 col-12">
            <label for="" style="margin: 5px;">المحصل</label>
            <ng-select
            class="js-example-basic-single"
            aria-label="Default select example"
          [(ngModel)]="BatchesReport.collectorId"
          [items]="Getcollectors"
          bindLabel="name"
          bindValue="id"
            
          
          (change)="getCollector($event.id)"
          >

          </ng-select>
        </div>
        <div class="col-md-4 col-12">
 <div class="d-flex justify-content-center align-items-center buttom-text">
            <button class="addnew-btn" type="submit" (click)="batchsubmit()">عرض التقرير</button>
          </div>
        </div>
        
    </div>
<!-- </form> -->
</div>       
</div>
