<app-breadcrumb [title]="'تفاصيل عن تقرير المكالمات'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'">
</app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header px-0 " style="padding-bottom: 5px;">
                    <div class="row m-0 mb-2">
                        <div class="col-12 col-md-9">
                        </div>
                        <div class="col-12 col-md-3 mt-3 d-flex justify-content-end align-items-center p-0">
                         <button class="addnew-btn" [print]="['DataTable']">طباعة البيانات</button> 
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                     
                    <table class="table" style="width: 100%;" id="DataTable">
                            <tr style="width: 90%;">  
                                <td class="font-style-header " style="width: 20%;" >
                                    <span class="form-control">أسم الموظف </span> 
                                </td>
                                <td>
                                    <span class="form-control text-left">{{Client_Call_list?.agentName}}</span>                                  
                                </td>
                            </tr>
                            <tr style="width: 90%;">  
                                <td class="font-style-header " style="width: 20%;" >
                                    <span class="form-control">الموبايل </span> 
                                </td>
                                <td>
                                    <span class="form-control">{{Client_Call_list?.mobileNumber}}</span>                                  
                                </td>
                            </tr>
                            <tr style="width: 90%;">  
                                <td class="font-style-header " style="width: 20%;" >
                                    <span class="form-control">أسم المتصل </span> 
                                </td>
                                <td>
                                    <span class="form-control">{{Client_Call_list?.customerName}}</span>                                  
                                </td>
                            </tr>
                            <tr style="width: 90%;">  
                                <td class="font-style-header " style="width: 20%;" >
                                    <span class="form-control">المدينة  </span> 
                                </td>
                                <td>
                                    <span class="form-control">{{Client_Call_list?.cityName}}</span>                                  
                                </td>
                            </tr>
                            <tr style="width: 90%;">  
                                <td class="font-style-header " style="width: 20%;" >
                                    <span class="form-control">العنوان  </span> 
                                </td>
                                <td>
                                    <span class="form-control">{{Client_Call_list?.address}}</span>                                  
                                </td>
                            </tr>
                            <tr style="width: 90%;">  
                                <td class="font-style-header " style="width: 20%;" >
                                    <span class="form-control">النوع  </span> 
                                </td>
                                <td>
                                    <span class="form-control" *ngIf="Client_Call_list?.gender == 1;else genblock" >ذكر</span> 
                                    <ng-template #genblock>أنثى</ng-template>                                 
                                </td>
                            </tr>
                            <tr style="width: 90%;">  
                                <td class="font-style-header " style="width: 20%;" >
                                    <span class="form-control">تاريخ الميلاد  </span> 
                                </td>
                                <td>
                                    <span class="form-control">{{Client_Call_list?.dob | date:'shortDate' }}</span>                                  
                                </td>
                            </tr>
                            <tr style="width: 90%;">  
                                <td class="font-style-header " style="width: 20%;" >
                                    <span class="form-control">نوع المكالمة   </span> 
                                </td>
                                <td>
                                    <span class="form-control" *ngIf="Client_Call_list?.callType == 1;else genblockout" >صادرة</span> 
                                    <ng-template #genblockout>واردة</ng-template>                                 
                                </td>
                            </tr>
                            <tr style="width: 90%;">  
                                <td class="font-style-header " style="width: 20%;" >
                                    <span class="form-control">رضاء العميل   </span> 
                                </td>
                                <td>
                                    <span class="form-control" *ngIf="Client_Call_list?.satisfy == true;else genblock" >راضى</span> 
                                    <ng-template #genblock>غير راضى</ng-template>                              
                                </td>
                            </tr>
                            <tr style="width: 90%;">  
                                <td class="font-style-header " style="width: 20%;" >
                                    <span class="form-control">وصف المكالمة  </span> 
                                </td>
                                <td>
                                    <span class="form-control">{{Client_Call_list?.callDescription}}</span>                                  
                                </td>
                            </tr>
                            <tr style="width: 90%;">  
                                <td class="font-style-header " style="width: 20%;" >
                                    <span class="form-control">ملاحظات المكالمة  </span> 
                                </td>
                                <td>
                                    <span class="form-control">{{Client_Call_list?.notes}}</span>                                  
                                </td>
                            </tr>
                            <tr style="width: 90%;">  
                                <td class="font-style-header " style="width: 20%;" >
                                    <span class="form-control">بداية المكالمة  </span> 
                                </td>
                                <td>
                                    <span class="form-control">{{Client_Call_list?.callStart | date:'mediumTime'}}</span>                                  
                                </td>
                            </tr>
                            <tr style="width: 90%;">  
                                <td class="font-style-header " style="width: 20%;" >
                                    <span class="form-control">نهاية المكالمة  </span> 
                                </td>
                                <td>
                                    <span class="form-control">{{Client_Call_list?.callEnd | date:'mediumTime' }}</span>                                  
                                </td>
                            </tr>

                            <tr style="width: 90%;">  
                                <td class="font-style-header " style="width: 20%;" >
                                    <span class="form-control">مدة  المكالمة  </span> 
                                </td>
                                <td>
                                    <span class="form-control">{{Client_Call_list?.duration }}</span>                                  
                                </td>
                            </tr>  
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>