import { GetRole } from '../Models/GetRole';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericResponse } from '../Models/GenericResponse';
import { GetGovernorate } from '../Models/GetGovernorate';
import { environment } from '../../../environments/environment.prod';
import { InsertGovernorate, UpdateGovernorate } from '../Models/InsertGovernorate';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
import { GetJob } from '../Models/GetJob';

@Injectable({
  providedIn: 'root'
})
export class LookupsServiceService {

  constructor(private http: HttpClient) { }
  GetMaritalStatus(): Observable<GenericResponse<GetJob>> {
    return this.http.get<GenericResponse<GetJob>>(`${environment.Server_URL}/Lookups/GetMaritalStatus`);
  }
  GetMilitaryStatus(): Observable<GenericResponse<GetJob>> {
    return this.http.get<GenericResponse<GetJob>>(`${environment.Server_URL}/Lookups/GetMilitarylStatus`);
  }
  GetNationality(): Observable<GenericResponse<GetJob>> {
    return this.http.get<GenericResponse<GetJob>>(`${environment.Server_URL}/Nationality/Get`);
  }
  GetReligion(): Observable<GenericResponse<GetJob>> {
    return this.http.get<GenericResponse<GetJob>>(`${environment.Server_URL}/Lookups/GetReligion`);
  }
  GetRole(): Observable<GenericResponse<GetRole>> {
    return this.http.get<GenericResponse<GetRole>>(`${environment.Server_URL}/Lookups/GetRole`)
  }
  GetActions(): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/Lookups/GetActions`)
  }
}
