import { SectorLocationApiService } from '../../../../shared/API-Service/sector-location-api.service';
import { SectorTypeApiService } from '../../../../shared/API-Service/sector-type-api.service';
import { Router } from '@angular/router';
import { SourceOfmarketingService } from '../../../../shared/API-Service/source-ofmarketing.service';
import { Component, OnInit } from '@angular/core';
import { ProjectDonations } from '../../../../shared//Models/ProjectDonations';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-donations-report',
  templateUrl: './donations-report.component.html',
  styleUrls: ['./donations-report.component.css']
})
export class DonationsReportComponent implements OnInit {
  ProjectDonations= new ProjectDonations();
  GetLookupsGetDonation
  DonationType
  SectorGet
  getType
  SectorLocation
  currentUser: any;
  Companies: any[];
  constructor(private CompanyDataService:CompanyDataService,public datepipe:DatePipe, private SourceOfmarketingService:SourceOfmarketingService,private router:Router,private SectorTypeApiService:SectorTypeApiService,private SectorLocationApiService:SectorLocationApiService) {
    this.currentUser=JSON.parse(localStorage.getItem("RiskCurrentUser"))
    if(this.currentUser.roleId==10){
      this.ProjectDonations.companyId=this.currentUser.id
      this.GetSector(this.currentUser.id)
    }
    const date = new Date();
    this.ProjectDonations.creationDateFrom=this.datepipe.transform(date, 'yyyy-MM-dd');
    this.ProjectDonations.creationDateTo=this.datepipe.transform(date,'yyyy-MM-dd');
    this.ProjectDonations.collectionDateFrom=this.datepipe.transform(date, 'yyyy-MM-dd');
    this.ProjectDonations.collectionDateTo=this.datepipe.transform(date,'yyyy-MM-dd');
   }

   GetLookupsGetDonationStatu(){
    this.SourceOfmarketingService.GetLookupsGetDonationStatu().subscribe(response=>{
  
      this.GetLookupsGetDonation = response.data
    })
  }
  ngOnInit(): void {
    this.GetLookupsGetDonationStatu()
    this.GetDonationType()
    this.GetCompanies()
  }
  donationStatus(event){
    this.ProjectDonations.donationStatus = parseInt(event) 
    this.ProjectDonations.donationStatusName = this.GetLookupsGetDonation.find(x => x.id == event).title
}
  GetDonationType(){
    this.SourceOfmarketingService.GetDonationType().subscribe(response=>{
        this.DonationType = response.data
      
    })
  }
  SelectCompany(CompanyId){
    this.ProjectDonations.companyId=parseInt(CompanyId);
    this.SectorGet =[];
    this.ProjectDonations.sectorId=null;
    this.ProjectDonations.sectorTypeId=null;
    this.ProjectDonations.sectorLocationId=null;
     this.GetSector(CompanyId);
  }
  GetCompanies(){
    this.CompanyDataService.GetCompanies().subscribe(res=>{
      this.Companies=res.data
    })
  }
  GetSector(CompanyId:any){
    this.SourceOfmarketingService.getSectorsBycompanyId(CompanyId).subscribe(res=>{
     this.SectorGet = res.data
    
    })
 }

 GetSectorGetById(event:any){
  if (this.ProjectDonations.sectorId==null) {
    this.ProjectDonations.sectorId=null
    this.ProjectDonations.sectorName=''
     this.ProjectDonations.sectorTypeId = null
   this.ProjectDonations.sectorTypeName=''
   this.ProjectDonations.sectorLocationId = null
   this.ProjectDonations.sectorLocationName = '';
   this.getType=[];
   this.SectorLocation=[]
   } 
 else{
      this.SectorTypeApiService.getSectorType(this.ProjectDonations.sectorId,this.ProjectDonations.companyId).subscribe(response=>{

    this.getType = response.data
  })

  this.ProjectDonations.sectorId = parseInt(event)
  this.ProjectDonations.sectorName = this.SectorGet.find(x => x.id == event).title
 }

}

GetSectorTypeGetById(event:any){

  if (this.ProjectDonations.sectorTypeId==null) {
    this.ProjectDonations.sectorTypeId=null
    this.ProjectDonations.sectorTypeName=''
      this.ProjectDonations.sectorLocationId = null
   this.ProjectDonations.sectorLocationName = ''
   this.SectorLocation=[];
   } 
   else{
     this.SectorLocationApiService.GetSectorLocationReportFilter(this.ProjectDonations.sectorTypeId).subscribe((response:any)=>{

   this.SectorLocation = response.data
 
 })

 this.ProjectDonations.sectorTypeId = parseInt(event)
  this.ProjectDonations.sectorTypeName = this.getType.find(x => x.id == event).title
   }


   
}




donationTypee(event){
  this.ProjectDonations.donationTypeId = parseInt(event) 
  this.ProjectDonations.donationTypeName = this.DonationType.find(x => x.id == event).title

 }

 loaction(event){
  this.ProjectDonations.sectorLocationId = parseInt(event) 
  this.ProjectDonations.sectorLocationName = this.SectorLocation.find(x => x.id == event).title
 }


  submit(){
    this.ProjectDonations.reportCreatedBy=this.currentUser.name;
    if (this.ProjectDonations.donationTypeId==null) {
      this.ProjectDonations.donationTypeId =null
      this.ProjectDonations.donationTypeName=''
    }
    
    if (this.ProjectDonations.sectorId ==null ) {
      this.ProjectDonations.sectorId=null 
      this.ProjectDonations.sectorName=''
    }
    
    if(this.ProjectDonations.sectorTypeId==null){
      this.ProjectDonations.sectorTypeId=null
      this.ProjectDonations.sectorTypeName=''
    }
    
    if(this.ProjectDonations.sectorLocationId==null){
      this.ProjectDonations.sectorLocationId=null
      this.ProjectDonations.sectorLocationName=''
    
    }
    if(this.ProjectDonations.donationStatus==null){
      this.ProjectDonations.donationStatus=null
      this.ProjectDonations.donationStatusName=''
    }
        localStorage["ReportName"]="DonationsReport";
        localStorage.setItem('ReportFilter',JSON.stringify(this.ProjectDonations));
      
        this.router.navigate([]).then((result) => {
          window.open("/content/admin/call-start-end-report", '_blank');
        });
  }

  Viewreports(){
    if(this.ProjectDonations.companyId!=null&&this.ProjectDonations.creationDateFrom &&this.ProjectDonations.creationDateTo){
    this.submit()
    }
    else if (this.ProjectDonations.companyId!=null&&this.ProjectDonations.collectionDateFrom &&this.ProjectDonations.collectionDateTo){
      this.submit()
    
    }
    else{
      if(!this.ProjectDonations.companyId){
        Swal.fire({
          icon:'error',
          title:'خطأ',
          text:'يجب اختيار الشركة'
    
        })
      }
    else   if (!this.ProjectDonations.creationDateFrom){
        Swal.fire({
          icon:'error',
          title:'خطأ',
          text:' يجب اختيار تاريخ من والى تبرعات منشأة او محصلة'
        })
      }
      else  if (!this.ProjectDonations.creationDateTo ){
        Swal.fire({
          icon:'error',
          title:'خطأ',
          text:' يجب اختيار تاريخ من والى تبرعات منشأة او محصلة'
    
        })
      }
      else  if (!this.ProjectDonations.collectionDateFrom ){
        Swal.fire({
          icon:'error',
          title:'خطأ',
          text:' يجب اختيار تاريخ من والى تبرعات منشأة او محصلة'
    
        })
      }
      else  if (!this.ProjectDonations.collectionDateTo ){
        Swal.fire({
          icon:'error',
          title:'خطأ',
          text:' يجب اختيار تاريخ من والى تبرعات منشأة او محصلة'
    
        })
      }
    }
  }
}
