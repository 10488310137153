import { SectorLocationApiService } from 'src/app/shared/API-Service/sector-location-api.service';
import { SectorTypeApiService } from './../../../../shared/API-Service/sector-type-api.service';
import { Router } from '@angular/router';
import { SearchReports } from '../../../../shared//Models/search-reports';
import { SourceOfmarketingService } from './../../../../shared/API-Service/source-ofmarketing.service';
import { Component, OnInit } from '@angular/core';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-search-reports',
  templateUrl: './search-reports.component.html',
  styleUrls: ['./search-reports.component.css']
})
export class SearchReportsComponent implements OnInit {
  DonationType
  SectorGet
  searchreports = new SearchReports()
  getType
  SectorLocation
  getEmployee
  currentUser: any;
  Companies: any[];
  constructor(private CompanyDataService:CompanyDataService,public datepipe:DatePipe,private SourceOfmarketingService:SourceOfmarketingService,private router:Router,private SectorTypeApiService:SectorTypeApiService,private SectorLocationApiService:SectorLocationApiService) { 

    this.currentUser=JSON.parse(localStorage.getItem("RiskCurrentUser"))
    if(this.currentUser.roleId==10){
      this.searchreports.companyId=this.currentUser.id
      this.GetSector(this.currentUser.id)
    }
    this.searchreports.reportCreatedBy=this.currentUser.name;
    const date = new Date();
    this.searchreports.creationDateFrom=this.datepipe.transform(date, 'yyyy-MM-dd');
    this.searchreports.creationDateTo=this.datepipe.transform(date,'yyyy-MM-dd');
  }

  ngOnInit(): void {
    this.GetDonationType()
    this.GetCompanies()
    this.Getemployee()
  }

  SelectCompany(CompanyId){
    this.searchreports.companyId=parseInt(CompanyId);
    this.SectorGet =[];
    this.searchreports.sectorId=null;
     this.GetSector(CompanyId);
  }
  GetCompanies(){
    this.CompanyDataService.GetCompanies().subscribe(res=>{
      this.Companies=res.data
    })
  }

  GetDonationType(){
    this.SourceOfmarketingService.GetDonationType().subscribe(response=>{
        this.DonationType = response.data        
    })
  }

  GetSector(CompanyId:any){
    this.SourceOfmarketingService.getSectorsBycompanyId(CompanyId).subscribe(res=>{

     this.SectorGet = res.data
    
    })
  }

  GetSectorGetById(event){
     this.searchreports.sectorTypeId = null
     this.searchreports.sectorTypeName = ''
     this.searchreports.sectorLocationId = null
     this.searchreports.sectorLocationName = ''
    this.SectorTypeApiService.getSectorType(this.searchreports.sectorId,this.searchreports.companyId).subscribe((response: any)=>{

      this.getType = response.data
    })

    this.searchreports.sectorId = parseInt(event)
    this.searchreports.sectorName = this.SectorGet.find(x => x.id == event).title
  }

 

  GetSectorTypeGetById(event:any){
    
     this.searchreports.sectorLocationId = null
     this.searchreports.sectorLocationName = ''


    this.SectorLocationApiService.GetSectorLocationReportFilter(this.searchreports.sectorTypeId).subscribe((response:any)=>{
   
         this.SectorLocation = response.data
   })
   this.searchreports.sectorTypeId = parseInt(event)
    this.searchreports.sectorTypeName = this.getType.find(x => x.id == event).title
 }


 Getemployee(){
  this.SourceOfmarketingService.EmployeeGet().subscribe(res=>{
    this.getEmployee = res.data
  })
 }

 createdEmplyee(event){
  this.searchreports.employeeId = parseInt(event) 
  this.searchreports.employeeName = this.getEmployee.find(x => x.id == event).name
 }

 donationTypee(event){
  this.searchreports.donationTypeId = parseInt(event) 
  this.searchreports.donationTypeName = this.DonationType.find(x => x.id == event).title

 }

 GetSectorLocationGetById(event:any){
  this.searchreports.sectorLocationId = parseInt(event) 
  this.searchreports.sectorLocationName = this.SectorLocation.find(x => x.id == event).title
 }


 Viewreports(){
  if(this.searchreports.companyId!=null&& ((this.searchreports.creationDateFrom  &&this.searchreports.creationDateTo) || (this.searchreports.collectionDateFrom  &&this.searchreports.collectionDateTo))){
  if (this.searchreports.sectorId ==null ) {
    this.searchreports.sectorId=null
    this.searchreports.sectorName=''
  
  }

  if(this.searchreports.sectorTypeId==null){
    this.searchreports.sectorTypeId =null
    this.searchreports.sectorTypeName=''
  
  }

  if( this.searchreports.sectorLocationId==null){
    this.searchreports.sectorLocationId =null
    this.searchreports.sectorLocationName=''
  
  }

  if (this.searchreports.donationTypeId == null) {
    this.searchreports.donationTypeId=null
    this.searchreports.donationTypeName='' 
  }
  if (this.searchreports.employeeId == null) {
    this.searchreports.employeeId=null
    this.searchreports.employeeName=''
    
    
  }
   localStorage["ReportName"]="EmployeesDonationRequestsReport";
   localStorage.setItem('ReportFilter',JSON.stringify(this.searchreports));
 
   this.router.navigate([]).then((result) => {
     window.open("/content/admin/call-start-end-report", '_blank');
   });

 }
 else{
  if(!this.searchreports.companyId){
    Swal.fire({
      icon:'error',
      title:'خطأ',
      text:'يجب اختيار الشركة'

    })
  }
else   if (!this.searchreports.creationDateFrom){
    Swal.fire({
      icon:'error',
      title:'خطأ',
      text:'يجب اختيار التبرعات المنشأة من تاريخ'

    })
  }
  else  if (!this.searchreports.creationDateTo ){
    Swal.fire({
      icon:'error',
      title:'خطأ',
      text:'يجب اختيار التبرعات المنشأة الي تاريخ'

    })
  }
 }
 }

}
