<app-breadcrumb [title]="' متابعه العملاء'" ></app-breadcrumb>

<div class="container-fluid">
  <div class="row">
      <div class="col-sm-12">
          <div class="card">
          <!-- < action="" [formGroup]="createReports"> -->
              <div class="card-header px-0 " style="padding-bottom: 5px;">
                  <div class="row m-0 mb-2">
                       <div class="col-12 col-md-12">
                           
                          
                            <div class="row">
                                 <div class="col-12 col-md-6">
                                      <div class="form-group">
                                          <label for=""> من تاريخ </label>
                                         <input type="date"  class="form-control"  [(ngModel)]="form.datefrom">
                                      </div>
                                  </div>
                                  
                                  <div class="col-12 col-md-6">
                                      <div class="form-group">

                                          <label for="">  الي تاريخ</label>

                                        
                                          <input type="date"  class="form-control"  [(ngModel)]="form.dateto">
                                      </div>
                                 </div>           
                    
                        

                      
              </div>
              <div class="d-flex justify-content-end align-items-center buttom-text">
                <button class="addnew-btn ng-star-inserted" style="margin-top: 4rem;" type="button" (click)="Search()" >بحث</button>
              </div>
              
            </div>
          
        </div>
  </div>
</div>
      </div>
  </div>
</div>
<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
              <div class="card-body ">
                <div class="row">
                    <div class="col-xl-4 col-sm-6 box-col-6">
                        <div class="card ">
                          <div class="card-body support-ticket-font" style="height: 200px;
                          width: 400px;">
                            <div   style="text-align: right !important;"><h3 class="text-success">جميع المكالمات </h3></div>
                            <div class="row">
                              <div class="col-12 numbers" >
                                <div class="text-md-right" >
                                  <ul style="text-align: right !important; ">
                                    <li class="" >عدد المكالمات 
                                      <span class="product-stts  ml-2" style="font-size: 15px !important; font-weight:bold;">
                                      {{thedata?.callsStats?.count}}
                                    </span>
                                  </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                            <div class="progress-showcase mt-0">
                              <div class="progress sm-progress-bar">
                                <div class="progress-bar bg-success" role="progressbar" style="width: 100%" aria-valuenow="25"
                                  aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                              <!-- <button (click)="goToDetails(4)" class="btn btn-success  btn-lg btn-block">التفاصيل</button> -->
    
                            </div>
                          </div>
                        </div>
  <!-- ===================================================================================================== -->
  <div class="col-xl-4 col-sm-6 box-col-6">
    <div class="card ">
      <div class="card-body support-ticket-font" style="height: 200px;
      width: 400px;">
        <div   style="text-align: right !important;"><h3 class="text-primary">المكالمات الصادرة </h3></div>
        <div class="row">
          <div class="col-12 numbers" >
            <div class="text-md-right" >
              <ul style="text-align: right !important; ">
                <li class="" >عدد المكالمات 
                  <span class="product-stts  ml-2" style="font-size: 15px !important; font-weight:bold;">
                  {{thedata?.callsStats?.outCallsCount}}
                </span>
              </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
        <div class="progress-showcase mt-0">
          <div class="progress sm-progress-bar">
            <div class="progress-bar bg-primary" role="progressbar" style="width: 100%" aria-valuenow="25"
              aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <!-- <button (click)="goToDetails(1)" class="btn  btn-primary  btn-lg btn-block">التفاصيل</button> -->
  
        </div>
      </div>
    </div>
  
  
                    <!-- ============================================ -->
                  
                    <div class="col-xl-4 col-sm-6 box-col-6">
                      <div class="card ">
                        <div class="card-body support-ticket-font" style="height: 200px;
                        width: 400px;">
                          <div   style="text-align: right !important;"><h3 class="text-danger">المكالمات الواردة </h3></div>
                          <div class="row">
                            <div class="col-12 numbers" >
                              <div class="text-md-right" >
                                <ul style="text-align: right !important; ">
                                  <li class="" >عدد المكالمات 
                                    <span class="product-stts  ml-2" style="font-size: 15px !important; font-weight:bold;">
                                    {{thedata?.callsStats?.inCallsCount}}
                                  </span>
                                </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                          <div class="progress-showcase mt-0">
                            <div class="progress sm-progress-bar">
                              <div class="progress-bar bg-danger" role="progressbar" style="width: 100%" aria-valuenow="25"
                                aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <!-- <button (click)="goToDetails(2)" class="btn btn-danger  btn-lg btn-block">التفاصيل</button> -->
  
                          </div>
  
                        </div>
  
                      </div>
           </div>
           </div>
                  <!-- ======================================================================= -->
                  
  
                  <!-- ================================== -->
                
  
  
  
  
  
  <!-- ======================================================================================== -->
  
  
                 
  
                  
                  
              <!-- ======================================================================================================== -->
              <!-- ============================== FINISHED THE CARDS ====================================================== -->
              <!-- ======================================================================================================== -->
  
              <!-- <div class="col-xl-3 col-sm-4 box-col-6">
                <div class="card  ecommerce-widget">
                  <div class="card-body support-ticket-font">
                    <div class="row">
                      <div class="col-lg-5 text-primary">
                        <h5>التبرعات المحصلة </h5>
                       </div> 
                       <div class="col-lg-5 offset-lg-1">
                        <p >عدد التبرعات {{thedata?.donationsStats.totalCount}}</p>
                        <p >اجمالي التبرعات {{thedata?.donationsStats.totalAmount}}</p>
                       </div>
                       <div class="col-lg-4">
                        <span style="font-size: 20px;" class="p-1">{{thedata?.donationsStats.collectedCount}}</span><span style="font-size: 12px;color: grey;">تبرع</span>
                       </div>
                       <div class="col-lg-1">
                        <p style="font-size: 30px;">/</p>
                       </div>
                       <div class="col-lg-6 ">
                        <span style="font-size: 20px;">{{thedata?.donationsStats.collectedAmount}}</span> <span style="font-size: 15px;color: grey;">ج . م</span> 
                       </div>
  
                    </div>
                    <div class="progress-showcase mt-0">
                      <div class="progress sm-progress-bar">
                        <div class="progress-bar bg-primary" role="progressbar" style="width: 100%" aria-valuenow="25"
                          aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
  
  
              <!-- ======================================================================================================== -->
              <!-- ============================== START THE GRAPH (محافظات)====================================================== -->
              <!-- ======================================================================================================== -->
  
  
              <div class="container mt-5">
                <div class="row ">
                  <div class="col-md-12">
                    <h1>محافظات اكثر تبرعاً</h1><br>
                    <div class=" " id="chartContainer"><br>
                      
                      <canvas id="myChart2" ></canvas>
                    </div>
  
                  </div>
                </div>
               </div>
  
                
                <!-- ======================================================================================================== -->
              <!-- ============================== START THE GRAPH (كيف)====================================================== -->
              <!-- ======================================================================================================== -->
       
               <div class="container ">
                <div class="row">
                  <div class="col-md-12">
                    <h1>كيف</h1><br>
                    <div class=" " id="chartContainer1"><br>
                     
                      <canvas  id="myChart1" width="1200" height="700"></canvas>
                    </div>
  
                  </div>
                </div>
               </div>
              <!-- ======================================================================================================== -->
              <!-- ============================== FINISHED THE GRAPH ====================================================== -->
              <!-- ======================================================================================================== -->
              <div class="container ">
                <div class="row">
                  <div class="col-md-12">
                    <h1>اسباب المكالمة</h1><br>
                    <div class=" " id="chartContainer1"><br>
                     
                      <canvas  id="myChart3" width="1200" height="700"></canvas>
                    </div>
  
                  </div>
                </div>
               </div>
              <!-- <div class="col-sm-12 mt-5">
                <div class="card">
                  <div class="card-header">
                    <h3>الموظفين</h3>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-dark">
                      <thead >
                        <tr>
                          <th scope="col"> </th>
                          <th scope="col">الأسم</th>
                          <th scope="col">رقم الهاتف</th>
                          <th scope="col">اجمالي المكالمات</th>
                          <th scope="col">المكالمات الواردة</th>
                          <th scope="col">المكالمات  الصادرة </th>
                          <th scope="col">  عدد التبرعات </th>
                          <th scope="col">  اجمالي التبرعات </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let one of employeesstats; let y = index" >
                          <td scope="row" >{{y + 1}}</td>
                          <td>{{one.name}}</td>
                          <td>{{one.mobile}}</td>
                          <td>{{one.allCalls}}</td>
                          <td>{{one.inCalls}}</td>
                          <td>{{one.outCalls}}</td>
                          <td>{{one.donationCount}}</td>
                          <td>{{one.donationAmount}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div> -->
                
  
  
  
  
  
  
  