<app-breadcrumb [title]="'إدارة مصادر التسويق'" [active_item]="'Form Wizard'"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding-bottom:5px !important ;">
          <div *ngIf="update; else elseBlock1">
            <h5 class="pb-2">تعديل مصدر تسويق موجود</h5>
            يمكنك تعديل مصدر تسويق أو أكثر فى قائمة مصادر التسويق
          </div>
          <ng-template #elseBlock1>
            <h5 class="pb-2">إضافة مصدر تسويق جديد</h5>
            يمكنك إضافة مصدر تسويق أو أكثر فى قائمة مصادر التسويق
          </ng-template>

        </div>
        <div class="card-body">
          <form class="needs-validation"  [formGroup]="InsertForm"  (ngSubmit)="onSubmit()" novalidate [ngClass]="{'was-validated': validated}">
            <div class="form-row">

              <div class="col-12 mb-3">

    
              <label for="validationCustom01">   من اين عرفتنا </label>
              <input class="form-control" id="validationCustom01" type="text" placeholder="عن طريق ..."   required=""
                formControlName="Title">
                <div class="valid-feedback">حسنًا</div>
              
              
            </div></div>  



            <div class="form-row">

              <div class="col-12 mb-3">

              <label for="validationCustom02">الترتيب</label>
              <input class="form-control" id="validationCustom02" type="text"  required=""
                formControlName="Order">
                <div class="valid-feedback">حسنًا</div>
              
              
            </div></div>
          
              <div *ngIf="update; else elseBlock">
                <button class="btn btn-primary pull-right" type="submit"  
                  >
                  تعديل
                </button>
              </div>
              <ng-template #elseBlock>
                <button class="btn btn-primary pull-right" type="submit"  
                  >
                  إضافة
                </button>
              </ng-template>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>