
<app-breadcrumb [title]="'تفاصيل التبرعات'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'">
</app-breadcrumb>
<div class="container-fluid">
  
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
            <!-- < action="" [formGroup]="createReports"> -->
                <div class="card-header px-0 " style="padding-bottom: 5px;">
                    <div class="row m-0 mb-2">
                         <div class="col-12 col-md-12">
                             
                            
                              <div class="row">
                                <div class="col-12 col-md-6">
                                  <label for="validationCustom02"><span class="validation-required">*</span>  اسم الشركة</label>
                                  <br>
                                  <label *ngIf="currentUser.roleId==10">{{currentUser.name}}</label>
                            <ng-select *ngIf="currentUser.roleId!=10"
                            class="js-example-basic-single col-sm-12 job-select2 form-group"
                              required=""
                              [(ngModel)]="doantionreports.companyId"
                              aria-label="Default select example"
                              (change)="SelectCompany($event.id)"
                              [items]="Companies"
                              bindLabel="name"
                              bindValue="id"
                               >
                            
                            </ng-select>
                                </div>
                                   <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <label for="">بدايه التبرعات المنشأه </label>
                                           <input type="date"  class="form-control" [(ngModel)]="doantionreports.creationDateFrom">
                                        </div>
                                    </div>
                                    
                                    <div class="col-12 col-md-6">
                                        <div class="form-group">

                                            <label for=""> نهايه التبرعات المنشأه</label>

                                          
                                            <input type="date"  class="form-control"  [(ngModel)]="doantionreports.creationDateTo">
                                        </div>
                                   </div>    
                                   <div class="col-12 col-md-6">
                                    <div class="form-group">

                                      <label for=""> بدايه التبرعات المحصله </label>
                                       <input type="date"  class="form-control"  [(ngModel)]="doantionreports.collectionDateFrom">

                                      <!-- <label for=""> من تاريخ التبرع </label>
                                       <input type="date"  class="form-control"  [(ngModel)]="ReportsInfo.collectionDateFrom"> -->


                                    </div>
                                </div>
                                
                                <div class="col-12 col-md-6">
                                    <div class="form-group">

                                      <label for="">  نهايه التبرعات المحصله </label>
                                       <input type="date"  class="form-control" [(ngModel)]="doantionreports.collectionDateTo">

                                      <!-- <label for="">    الى تاريخ التبرع </label>
                                       <input type="date"  class="form-control" [(ngModel)]="ReportsInfo.collectionDateTo"> -->

                                    </div>
                                </div>       
                                <div class="col-12 col-md-6">
                                  <label for="">حالة التبرع   </label>
                                    <ng-select
                                    class="js-example-basic-single"
                                    aria-label="Default select example"
                                    (change)="donationStatus($event.id)"
                                   [(ngModel)]="doantionreports.donationStatus"
                                   [items]="GetLookupsGetDonation"
                                   bindLabel="title"
                                   bindValue="id"
                                   >
                                  </ng-select>
                                </div>
                        <div class="col-12 col-md-12">
                            <div class="row">
                            
                              
                                  <div class="col-12 col-md-4">
                                    <label for="">نوع التبرع</label>
                                      <ng-select
                                      class="js-example-basic-single"
                                      aria-label="Default select example"
                                    [(ngModel)]="doantionreports.donationTypeId"
                                     (change)="donationTypee($event.id)"
                                     [items]="DonationType"
                                     bindLabel="title"
                                     bindValue="id"
                                    >
                                    </ng-select>
                                  </div>

                                  <div class="col-12 col-md-4">
                                    <label for="">قطاع التبرع</label>
                                      <ng-select
                                      class="js-example-basic-single"
                                      aria-label="Default select example"
                                     [(ngModel)]="doantionreports.sectorId"
                                      (change)="GetSectorGetById($event?.id)" 
                                      [items]="SectorGet"
                                      bindLabel="title"
                                      bindValue="id"
                                      >
                                    </ng-select>
                                  </div>
                                  <div class="col-12 col-md-4 ">
                                    <label for="">نوع القطاع</label>
                                      <ng-select
                                      class="js-example-basic-single"
                                      aria-label="Default select example"
                                      [(ngModel)]="doantionreports.sectorTypeId"
                                      (change)="GetSectorTypeGetById($event?.id)" 
                                      [items]="getType"
                                      bindLabel="title"
                                      bindValue="id"
                                      >
                                    
                                    </ng-select>
                                  </div>
                                  <div class="col-12 col-md-4 mt-4">
                                    <label for="">موقع القطاع</label>
                                      <ng-select
                                      class="js-example-basic-single"
                                      aria-label="Default select example"
                                      [(ngModel)]="doantionreports.sectorLocationId"
                                     (change)="loaction($event.id)"
                                     [items]="SectorLocation"
                                     bindLabel="title"
                                     bindValue="id"
                                      >
                                    </ng-select>
                                  </div>
                                  <!-- <div class="col-12 col-md-4 mt-4">
                                    <label for="">اسم العميل</label>
                                  <input type="text" class="form-control" [(ngModel)]="doantionreports.ClientName">
                                  </div> -->
                                  <div class="col-12 col-md-4 mt-4">
                                    <div class="" style="display: flex; justify-content: start;"><label for="">اسم العميل</label></div>
                                    <input type="text"  class="form-control" [(ngModel)]="doantionreports.customerName">

                                    <!-- <ng-select name="" id=""  class="js-example-basic-single" (change)="customer($event.id)" [(ngModel)]="doantionreports.customerId"
                                    [items]="GetCustomer"
                                    bindLabel="name"
                                    bindValue="id"
                                    >
                                    </ng-select> -->
                                </div>
                                  <div class="col-12 col-md-4 mt-4">
                                    <label for=""> رقم الموبايل العميل</label>
                                  <input type="text" onlyNumbers class="form-control" [(ngModel)]="doantionreports.customerMobile">
                                  </div>
                                  <div class="col-12 col-md-4 mt-4">
                                    <label for="">  نوع العميل</label>
                                    <ng-select name="" id="" class="js-example-basic-single"
                                    aria-label="Default select example"
                                    [(ngModel)]="doantionreports.customerGender"
                                    (change)="GetClientType($event.id)"
                                    [items]="gender"
                                    bindLabel="title"
                                    bindValue="id"
                                    >
                                      <!-- <ng-option value=null  selected >أختر نوع </ng-option>
                                      <ng-option value ="{{1}}" >ذكر </ng-option>
                                      <ng-option value="{{2}}" >انثي</ng-option> -->
                                    </ng-select>
                                  </div>
                                  <div class="col-md-4 col-12 mt-4">

 

 


                                    <div class="form-group">
                                        <label>أختر المحافظة </label>
                                           
                                        <ng-select
                                        class="js-example-basic-single"
                                          aria-label="Default select example"
                                         
                                         [(ngModel)]="doantionreports.customerGovernoratId"
                                          (change)="SelectedGovernorate($event?.id)"
                                          name="plan" id="plan"
                                          [items]="Governorate_List"
                                          bindLabel="title"
                                          bindValue="id"
                                        >
                                         
                                        <ng-option value=null selected >اسم المحافظه</ng-option>
                                          <ng-option
                                            *ngFor="let obj of Governorate_List"
                                            value="{{obj.id}}"
                                          >
                                            {{ obj.title }}
                                          </ng-option>
                                        </ng-select>
                                      </div>
                                    
                                        
                                   
                                </div>
                                <div class="col-md-4 col-12 mt-4">
                                    <div class="form-group">
                                        <label>أختر مدينه </label>
                                           
                                        <ng-select
                                        class="js-example-basic-single"
                                          aria-label="Default select example"
                                          [(ngModel)]="doantionreports.customerCityId"
                                          [items]="City_list"
                                          bindLabel="title"
                                          bindValue="id"
                                          (change)="SelectedCity($event.id)"
                                          
                                        >
                                        </ng-select>
                                      </div>
                                    
                                        
                                   
                                </div>

                                <div class="col-md-4 col-12 mt-4">
                                    <label for="" style="margin: 5px;">المحصل</label>
                                    <ng-select
                                    class="js-example-basic-single"
                                    aria-label="Default select example"
                                  [(ngModel)]="doantionreports.collectorId"
                                    (change)="getCollector($event.id)"
                                    [items]="Getcollectors"
                                    bindLabel="name"
                                    bindValue="id"
                                  >
                                  </ng-select>
                                </div>
                                <div class="col-12 col-md-4 mt-4">
                                    <label for=""> مصدر اعلان</label>
                                      <ng-select
                                      class="js-example-basic-single"
                                      aria-label="Default select example"
                                         (change)="sourcemarkect($event.id)"
                                       [(ngModel)]="doantionreports.sourceMarketId"
                                       [items]="getsourcemarket"
                                       bindLabel="title"
                                       bindValue="id"
                                      >
                                    </ng-select>
                                  </div>
                                  <div class="col-12 col-md-4 mt-4">
                                    <div class="" style="display: flex; justify-content: start;"><label for="">اسم الموظف</label></div>
                                    
                                    <ng-select name="" id="" class="js-example-basic-single" (change)=" createdEmplyee($event.id)" [(ngModel)]="doantionreports.createdBy"
                                    [items]="getEmployee"
                                    bindLabel="name"
                                    bindValue="id"
                                    >
                                    </ng-select>
                                </div>
                                  
                              
                            </div>
                        </div>         

                        
                </div>
                <div class="d-flex justify-content-end align-items-center buttom-text">
                  <button class="addnew-btn" style="margin-top: 3rem;" type="submit" (click)="viewReport()">عرض التقرير</button>
                </div>
                
              </div>
            
          </div>
    </div>
</div>