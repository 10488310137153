import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GenericResponse } from '../Models/GenericResponse';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
import { environment } from './../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CommentServicesService {

  constructor(private http:HttpClient,private router:Router) { }
  GetDonationComments(donationId): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/DonationComment/Get?donationId=${donationId}`);
  }

  InsertDonationComment(Data): Observable<GenericResponseSingle<any>> {
    return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/DonationComment/Create`,Data);
  }
}
