import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { EmployeeApiService } from 'src/app/shared/API-Service/employee-api.service';
import Swal from 'sweetalert2';
import { RiskAuthenticationService } from '../../../../../shared/API-Service/RiskAuthentication.service';

@Component({
  selector: 'app-change-employeepassword',
  templateUrl: './change-employeepassword.component.html',
  styleUrls: ['./change-employeepassword.component.css']
})
export class ChangeEmployeePasswordComponent implements OnInit {
  insertForm: FormGroup;
  Employees:any[]=[]
  ChangePassForm:{
    Employee:'',
    Password:'',
    ConfirmPassword:''
  }
  EmpId: any;
  constructor(private _formBuilder: FormBuilder,private EmployeeApiService :EmployeeApiService,private RiskAuthenticationService : RiskAuthenticationService) { }
  get fc() {
    return this.insertForm.controls;
  }
  ngOnInit(): void {
    this.initForm();
    this.GetEmplyee();
  }
  initForm() {
    this.insertForm = this._formBuilder.group({
      Employee: [this.ChangePassForm?.Employee || '', Validators.required],
      Password: [this.ChangePassForm?.Password || '', Validators.required],
      ConfirmPassword: [this.ChangePassForm?.ConfirmPassword || '', Validators.required],
    }, { 
      validator: this.ConfirmedValidator('Password', 'ConfirmPassword')
    });

  }
  ConfirmedValidator(controlName: string, matchingControlName: string){
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
            return;
        }
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ confirmedValidator: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
  }
  GetEmplyee(){
this.EmployeeApiService.GetEmployee().subscribe(res=>{
  this.Employees=res.data;
})
  }
  ChangeEmplyee(event){
this.EmpId=event
  }
  ChangePassword(){
    const data = {
      "id": this.EmpId,
      "password": this.insertForm.get('Password').value
    }
    this.RiskAuthenticationService.ChangeEmpPass(data).subscribe(res=>{
      Swal.fire({
        icon: "success",
        title: "تم الحفظ بنجاح",
        showConfirmButton: false,
        timer: 1500,
      });
      this.insertForm.reset();
    },err=>{
      Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: err.error.message,
      })
    
    })
  }
  onSubmit(){
    if (this.insertForm.status == "VALID") {
      this.ChangePassword()
    } else {
      this.insertForm.markAllAsTouched();
    }
  }
}
