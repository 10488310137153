import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { DOCUMENT, Location } from "@angular/common";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { NgbModal, NgbTabset } from "@ng-bootstrap/ng-bootstrap";
import { CitiesApiService } from "src/app/shared/API-Service/cities-api.service";
import { CustomerApiService } from "src/app/shared/API-Service/customer-api.service";
import { DontaionsApiService } from "src/app/shared/API-Service/donations-api-service";
import { SearchCustomerDonation } from "src/app/shared/Models/search-customer-donation";
import Swal from "sweetalert2";
import { CommentModalComponent } from "../comment-modal/comment-modal.component";
import { SmsTemplateService } from "src/app/shared/API-Service/sms-template.service";
import { SendSMSComponent } from "../../admin/send-sms/send-sms.component";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ChoosecompanyModalComponent } from "src/app/shared/components/ChooseCompanyModal/choosecompany-modal/choosecompany-modal.component";
import { environment } from "src/environments/environment.prod";
import { CompanyDataService } from "src/app/shared/API-Service/company-data.service";
import { filter, pairwise } from "rxjs/operators";
import { ReceiptBookService } from "src/app/shared/API-Service/receipt-book.service";

@Component({
  selector: "app-customer-donation",
  templateUrl: "./customer-donation.component.html",
  styleUrls: ["./customer-donation.component.css"],
})
export class CustomerDonationComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  // @ViewChild('ctdTabset') ctdTabset;
  tabs = [];
  @ViewChild("tabset", { static: false }) tabset: any;
  imgUrlServer = environment.Server_URL_Image + "/";
  selected = new FormControl(0);
  DontationMainType: any;
  DontationType: any[];
  err: boolean;
  donationMainTypeId: any;
  customerinfo: any;
  city: any;
  age: number;
  dateOfBirth: Date;
  customerDonations: any[];
  customer: any[];
  AllInfo: any[] = [];
  customerid: any;
  callid: any;
  disApeer: any;
  condition: true;
  modalRef: BsModalRef;

  // private tabset: NgbTabset;
  TabshDonation = new SearchCustomerDonation(); //Used request Api only
  searchDonation = new SearchCustomerDonation(); //for searching only obj
  details: boolean;
  cusomtermobile: Promise<boolean>;
  AllDontationType: any;
  status: any;
  SmsTemplates: any;
  pageNumberCash: any;
  pageNumberPhysical: any;
  pageNumberOutDoor: any;
  totalCountCach: any;
  totalCountPhysical: any;
  totalCountOutDoor: any;
  LoadPage: boolean;
  CompanyId: any;
  param: any;
  companyImage: any;
  companyName: any;
  previousUrl: string;
  DisplayDataPage: any;
  HaveCompany: boolean;
  receiptNumDisable: boolean = true;
  searchBookNum: any; //for searching only book Number
  BookNum: any; //Used request Api only  book Number
  GetReceiptBooks: any;
  receiptBookId: string;
  FullReceiptNum: any;
  ReceiptRequierd: boolean;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private location: Location,
    private ReceiptBookService: ReceiptBookService,
    private CompanyDataService: CompanyDataService,
    private ChooseCompanyModalService: BsModalService,
    private SmsTemplateService: SmsTemplateService,
    public modalService: NgbModal,
    private DonationApiService: DontaionsApiService,
    private CustomerApiService: CustomerApiService,
    private CityApiService: CitiesApiService,
    private DonationsApiSevice: DontaionsApiService,
    private router: Router
  ) {
    super();

    this.DisplayDataPage = this.document.defaultView.history.state.callid;
    if (this.DisplayDataPage) {
      this.HaveCompany = true;
      this.getReceiptBooks(this.CompanyDataService.CompanyInfo.CompanyId);
    }
    if (this.CompanyDataService.NewUpdateCustomerDonation) {
      this.HaveCompany = true;
      this.getReceiptBooks(this.CompanyDataService.CompanyInfo.CompanyId);
    }

    if (!this.HaveCompany) {
      const modalConfig = {
        class: "modal-dialog-centered modal-lg", // Add your desired width class here
        ignoreBackdropClick: true,
      };
      this.modalRef = this.ChooseCompanyModalService.show(
        ChoosecompanyModalComponent,
        modalConfig
      );
      this.modalRef.content.modalClosed.subscribe((company: any) => {
        this.LoadPage = true;
        this.CompanyId = company.companyId;
        this.CompanyDataService.CompanyInfo.CompanyId = company.companyId;
        this.companyImage = this.imgUrlServer + company.companyLogoPath;
        this.CompanyDataService.CompanyInfo.CompanyIcon =
          company.companyLogoPath;
        this.companyName = company.companyName;
        this.CompanyDataService.CompanyInfo.CompanyName = company.companyName;
        this.donationMainTypeId = 1;
        this.details = false;
        this.condition = true;
        this.getReceiptBooks(this.CompanyId);
        //this.GetSMSTemplate(this.CompanyId);
        //this.param = this.router.getCurrentNavigation().extras.state;
        const currentNavigation = this.router.getCurrentNavigation();
        if (
          currentNavigation &&
          currentNavigation.extras &&
          currentNavigation.extras.state
        ) {
          this.param = this.router.getCurrentNavigation().extras.state;
        } else {
        //  this.param = company.RouteData;
        }

        if (this.param) {
    
          this.customerid = this.param.customerid;
          this.callid = this.param.callid;
          this.donationMainTypeId = this.param.donationMainTypeId;
          if (this.param.cusomtermobile) {
            this.TabshDonation.mobile = this.param.cusomtermobile;
            this.TabshDonation.donationMainType = this.param.donationMainTypeId;
            this.donationMainTypeId = this.param.donationMainTypeId;
            this.searchbutton();
          } else {
            this.donationMainTypeId = 1;
            const data = {
              maxResultCount: this.pager.maxResultCount,
              skipCount: 0,
              customerId: this.customerid,
              donationMainType: this.donationMainTypeId,
              companyId: this.CompanyId,
            };
            this.GetCustomerDonations(data);
          }
          if (this.donationMainTypeId == undefined) {
            this.donationMainTypeId = 1;
          }

          // this.tabset.select(this.donationMainTypeId);
        }
        var res2;
        this.DonationApiService.GetLookupsDonationStatus().subscribe({
          next: (data) => {
            res2 = data;
            if (res2.messageCode == 200) {
              this.err = false;
              this.status = res2.data;
            } else {
              this.err = true;
            }
          },
          error: (error) => {
            this.err = true;
            Swal.fire({
              icon: "error",
              title: "خطأ",
              text: error.error.message,
            });
          },
        });
        // this.GetDonationMainType();
      });
    } else {
      this.LoadPage = true;

      this.CompanyId = this.CompanyDataService.CompanyInfo.CompanyId;
      this.companyImage =
        this.imgUrlServer + this.CompanyDataService.CompanyInfo.CompanyIcon;
      this.getReceiptBooks(this.CompanyId);
      const matches = this.companyImage.match(/https/gi);

      if (matches && matches.length === 2) {
        this.companyImage = this.CompanyDataService.CompanyInfo.CompanyIcon;
      }
   
      this.companyName = this.CompanyDataService.CompanyInfo.CompanyName;
      this.donationMainTypeId = 1;
      this.details = false;
      this.condition = true;
      //this.GetSMSTemplate(this.CompanyId);
      //this.param = this.router.getCurrentNavigation().extras.state;
      const currentNavigation = this.router.getCurrentNavigation();
      if (
        currentNavigation &&
        currentNavigation.extras &&
        currentNavigation.extras.state
      ) {
        this.param = this.router.getCurrentNavigation().extras.state;
      } else {
        //    this.param=company.RouteData
      }

      if (this.param) {
        this.customerid = this.param.customerid;
        this.callid = this.param.callid;
        this.donationMainTypeId = this.param.donationMainTypeId;
        if (this.param.cusomtermobile) {
          this.TabshDonation.mobile = this.param.cusomtermobile;
          this.TabshDonation.donationMainType = this.param.donationMainTypeId;
          this.donationMainTypeId = this.param.donationMainTypeId;
          this.searchbutton();
        } else {
          this.donationMainTypeId = 1;
          const data = {
            maxResultCount: this.pager.maxResultCount,
            skipCount: 0,
            customerId: this.customerid,
            donationMainType: this.donationMainTypeId,
            companyId: this.CompanyId,
          };
          this.GetCustomerDonations(data);
        }
        if (this.donationMainTypeId == undefined) {
          this.donationMainTypeId = 1;
        }

        // this.tabset.select(this.donationMainTypeId);
      }
      var res2;
      this.DonationApiService.GetLookupsDonationStatus().subscribe({
        next: (data) => {
          res2 = data;
          if (res2.messageCode == 200) {
            this.err = false;
            this.status = res2.data;
          } else {
            this.err = true;
          }
        },
        error: (error) => {
          this.err = true;
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: error.error.message,
          });
        },
      });
      this.GetDonationMainType();
    }
  }
  pageChanged(event: any) {
    if (this.donationMainTypeId == 1) {
      this.pageNumberCash = event.page; // -1 * pageSize;
      this.pager.skipCount =
        (this.pageNumberCash - 1) * this.pager.maxResultCount;
    } else if (this.donationMainTypeId == 2) {
      this.pageNumberPhysical = event.page; // -1 * pageSize;
      this.pager.skipCount =
        (this.pageNumberPhysical - 1) * this.pager.maxResultCount;
    } else if (this.donationMainTypeId == 3) {
      this.pageNumberOutDoor = event.page; // -1 * pageSize;
      this.pager.skipCount =
        (this.pageNumberOutDoor - 1) * this.pager.maxResultCount;
    }
    if (this.BookNum) {
      this.FullReceiptNum = this.BookNum + "-" + this.TabshDonation.receiptNum;
    }
    else
    {
      this.FullReceiptNum =null;
    }
    const data = {
      maxResultCount: this.pager.maxResultCount,
      skipCount: this.pager.skipCount,
      donationMainType: this.donationMainTypeId,
      mobile: this.TabshDonation.mobile,
      receiptNum: this.FullReceiptNum,
      companyId: this.CompanyId,
      customerId: this.customerid,
    };
    this.GetCustomerDonations(data);
    // this.StateService.GetGetCollectorReceivedList(this.pager).subscribe((res:any)=>{
    //  this.getDonation = res.data.items
    //  this.totalCount = res.data.totalCount;
    //  this.getDonationfilter = res.data.items
    //  this.AllDontation = res.data.items

    // })
  }
  ngAfterViewInit(): void {
    this.GetDonationMainType();
  }

  ngOnInit(): void {}
  // GetSMSTemplate(companyId) {
  //   this.SmsTemplateService.getSmsTemplates(companyId).subscribe((res) => {
  //     this.SmsTemplates = res.data;
  //   });
  // }
  SendSMSTemplate(item?) {
    localStorage.removeItem("donationId");
    localStorage.removeItem("customerid");
    if (item) {
      localStorage.setItem("donationId", item.donationId);
    } else {
      localStorage.setItem("customerid", this.customerid);
    }
    //ModalComponent is component name where modal is declare

    const modalRef = this.modalService.open(SendSMSComponent);
    modalRef.componentInstance.CompanyId = this.CompanyId;

    modalRef.result
      .then((result) => {
    
      })
      .catch((error) => {
        
      });
  }
  GetDonationMainTypeId(event) {
    this.details = false;
    this.pageNumberCash = 1;
    this.pageNumberPhysical = 1;
    this.pageNumberOutDoor = 1;
    this.donationMainTypeId = event.nextId;
    if (this.BookNum && this.TabshDonation.receiptNum) {
      this.FullReceiptNum = this.BookNum + "-" + this.TabshDonation.receiptNum;
    } else {
      this.FullReceiptNum = null;
    }
    const data = {
      maxResultCount: this.pager.maxResultCount,
      skipCount: 0,
      customerId: this.customerid,
      donationMainType: this.donationMainTypeId,
      mobile: null,
      receiptNum: this.FullReceiptNum,
      companyId: this.CompanyId,
    };
    if (this.customerid != null) this.GetCustomerDonations(data);
  }
  GoToAccomplishment(id) {
    this.CompanyDataService.CompanyInfo.CompanyIcon = this.companyImage;
    this.CompanyDataService.CompanyInfo.CompanyName = this.companyName;
    localStorage.setItem("CompanyIcon", this.companyImage);
    localStorage.setItem("CompanyName", this.companyName);
    localStorage.setItem("CompanyId", this.CompanyId);
    this.router.navigate(["/content/admin/customer-accomplishment/", id]);
    // this.router.navigateByUrl( ['/content/admin/customer-accomplishment', {id: id}]);
  }
  GoToAccomplishmentMediaCustomer(id, companyId) {
    this.CompanyDataService.CompanyInfo.CompanyIcon = this.companyImage;
    this.CompanyDataService.CompanyInfo.CompanyId = companyId;
    this.CompanyDataService.NewUpdateCustomerDonation = null;
    this.router.navigate(["/content/admin/accomplishmentmedia-customer", id]);
    // this.router.navigate( ['/content/admin/accomplishmentmedia-customer', {id: id}]);
  }
  go() {
    if (this.donationMainTypeId == undefined) {
      this.donationMainTypeId = 1;
    }
    debugger;
    const donationMainTypeId = this.donationMainTypeId;
    if (this.city) {
      const cityid = this.city.id;
      const cusomterid = this.customerinfo.id;
      const cusomtername = this.customerinfo.name;
      const cusomtermobile = this.customerinfo.mobile;

      this.router.navigateByUrl("content/agent/NewCusomterDonation", {
        state: {
          donationMainTypeId: this.donationMainTypeId,
          callid: this.callid,
          cityid: cityid,
          cusomterid: cusomterid,
          cusomtername: cusomtername,
          cusomtermobile: cusomtermobile,
        },
      });
    } else {
      const cityid = this.customerinfo.cityId;
      const cusomterid = this.customerinfo.id;
      const cusomtername = this.customerinfo.name;
      const cusomtermobile = this.customerinfo.mobile;
      this.cusomtermobile = this.router.navigateByUrl(
        "content/agent/NewCusomterDonation",
        {
          state: {
            donationMainTypeId: donationMainTypeId,
            callid: this.callid,
            cityid: cityid,
            cusomterid: cusomterid,
            cusomtername: cusomtername,
            cusomtermobile: cusomtermobile,
          },
        }
      );
    }
  }
  GetCustomerDonations(data) {
    this.DontationType = [];
    var res;
    // const data={
    //   "maxResultCount": 10,
    //   "skipCount":0,
    //   "customerId": customerId,
    //  "donationMainType": donationMainTypeId,
    //  "mobile":  this.cusomtermobile
    // }
    this.CustomerApiService.GetCustomerDonationsList(data).subscribe({
      next: (data) => {
        res = data;
        if (res.data != null)
          if (res.messageCode == 200 && res.data.customerDonations.items) {
            this.err = false;
            //
            if (this.BookNum && this.TabshDonation.receiptNum) {
              //         this.tabs=this.DontationMainType;
              //     this.tabset.tabs=this.tabs;
              //      this.tabset.activeId=res.data.customerDonations.items[0].donationMainTypeId;
              this.tabset.select(
                res.data.customerDonations?.items[0]?.donationMainTypeId
              );
            }

            this.DontationType = res.data.customerDonations.items;
            if (this.donationMainTypeId == 1) {
              this.totalCountCach = res.data.customerDonations.totalCount;
            } else {
              this.totalCountCach = 0;
            }

            if (this.donationMainTypeId == 2) {
              this.totalCountPhysical = res.data.customerDonations.totalCount;
            } else this.totalCountPhysical = 0;
            if (this.donationMainTypeId == 3) {
              this.totalCountOutDoor = res.data.customerDonations.totalCount;
            } else {
              this.totalCountOutDoor = 0;
            }

            this.AllDontationType = res.data.customerDonations.items;
            this.customerinfo = res.data.cutomerInfo;
            this.customerid = this.customerinfo?.id;
            this.dateOfBirth = new Date(this.customerinfo?.dateOfBirth);
            var timeDiff = Math.abs(Date.now() - Number(this.dateOfBirth));
            this.age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
          } else {
            this.err = true;
          }
        else {
          //#region If the data is empty, the old data must be emptied, and reset pagination must be performed and remove customer info
          this.DontationType = [];
          this.totalCountCach = 0;
          this.totalCountPhysical = 0;
          this.totalCountOutDoor = 0;
          this.customerinfo = null;
          //#endregion  "Mustafa 8/21/23"
        }
      },
      error: (error) => {
        this.err = true;
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: error.error.message,
        });
      },
    });
  }
  getReceiptBook(event) {
    if (event) {
      this.receiptNumDisable = false;
      this.searchBookNum = event.bookNumber; //In case of a change in the recipe Book
    } else {
      this.ReceiptRequierd = false;
      this.receiptNumDisable = true;
      this.searchBookNum = null;
      this.searchDonation.receiptNum = null;
      //this.BookNum = null;
    }
  }
  getReceiptBooks(companyId) {
    // this.receiptBookId=null;
    this.ReceiptBookService.GetReceiptBookByCompanyId(companyId).subscribe(
      (response) => {
        this.GetReceiptBooks = response.data;
      }
    );
  }
  GetDonationMainType() {
    var res;
    this.DonationsApiSevice.GetDonationsMainType().subscribe({
      next: (data) => {
        res = data;
        if (res.messageCode == 200) {
          this.err = false;
          this.DontationMainType = res.data;
          this.tabs = this.DontationMainType;
          // if(this.tabset){
          this.tabset.tabs = this.tabs;
          this.tabset.activeId = this.donationMainTypeId;
          // }
        } else {
          this.err = true;
        }
      },
      error: (error) => {
        this.err = true;
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: error.error.message,
        });
      },
    });
  }
  // DeleteDonationDetails(index){
  //   this.DontationType["customerDonations"].splice(index, 1);
  // }
  EditDonationDetails(index) {
    this.customerDonations = this.DontationType["customerDonations"];
    // this.customer=this.DontationType["cutomer"];
    if (this.DontationType["customerDonations"]) {
      const DonationId = this.customerDonations[index].donationId;
      this.router.navigateByUrl("content/agent/updateCusomterDonation", {
        state: { donationid: DonationId,
        callid:this.callid },
      });
    } else {
      const statues = this.DontationType[index].status;
      const DonationId = this.DontationType[index].donationId;
      this.router.navigateByUrl("content/agent/updateCusomterDonation", {
        state: { donationid: DonationId ,callid:this.callid},
      });
    }
  }
  OpenDetailes(index) {
    if (this.DontationType[index]["ShowDetails"]) {
      this.details = false;
      this.DontationType[index]["ShowDetails"] = false;
      this.DontationType = this.AllDontationType;
    } else {
      this.details = true;
      this.DontationType[index]["ShowDetails"] = true;
      this.DontationType = this.AllDontationType;
    }
  }
  // searchbutton(){
  //   this.DonationsApiSevice.GetSearchcustomerdonation(this.searchDonation).subscribe(res=>{
  //     // console.log("cccc",res.data)
  //     this.city = res.data.cutomer
  //     this.DontationType = res.data.customerDonations
  //   })
  // }

  openModal(donationId) {
    //ModalComponent is component name where modal is declare
    localStorage.removeItem("ModalData");
    localStorage.setItem("ModalData", donationId);
    const modalRef = this.modalService.open(CommentModalComponent);
    modalRef.result
      .then((result) => {
        
      })
      .catch((error) => {
     
      });
  }
  ngOnDestroy(): void {
    this.CompanyDataService.NewUpdateCustomerDonation = null;
  }
  receiptNumChange() {
    if (this.searchDonation.receiptNum) {
      this.ReceiptRequierd = false;
    }
  }
  searchbutton() {
    //#region Delete the caller ID and change the status in URL
    this.callid = null;
    this.replaceBrowserState();
    //#endregion  "Mustafa"

    //#region searchDonation ==> searchbutton  If mobile number = NULL && Book number == NULL =>>Deleting customer data && customerid ==null
    if (!this.searchDonation.mobile && !this.searchDonation.receiptNum) {
      // this.customerinfo = null;
      this.customerid = this.customerid;
    }
    //#endregion  "Mustafa"

    if (this.pager.receiptNum) this.pager.receiptNum = null;

    if (this.searchBookNum && !this.searchDonation.receiptNum) {
      //this.customerid = null;
      this.ReceiptRequierd = true;
    } else {
      this.pager.skipCount=0;
      this.BookNum = this.searchBookNum;
      this.customerid = null;
        this.TabshDonation.receiptNum=this.searchDonation.receiptNum;
      if (!this.searchDonation.mobile) {
        this.searchDonation.mobile = null;
        this.TabshDonation.mobile = null;
      }
      if (!this.searchDonation.receiptNum) {
        this.searchDonation.receiptNum = null;
      }

      if (this.tabset) {
        this.donationMainTypeId = this.tabset.activeId;
      }

      //- Validate the input field
      if (this.searchDonation.mobile != null) {
        this.TabshDonation.mobile = this.searchDonation.mobile;
        this.customerid = null; // customerid = null ==>And start searching again and they scan the customer id
      } else if (this.searchDonation.receiptNum && this.searchBookNum &&this.receiptBookId) {
        this.TabshDonation.receiptNum = this.searchDonation.receiptNum; 
        this.BookNum = this.searchBookNum;
        this.customerid = null; // customerid = null ==>And start searching again and they scan the customer id
      }
      this.pager.customerId = null;
      this.pager.donationMainType = this.donationMainTypeId;
      this.pager.mobile = this.TabshDonation.mobile;
      if (this.BookNum) {
        this.pager.receiptNum =
          this.BookNum + "-" + this.TabshDonation.receiptNum;
        this.pager.donationMainType = null;
      }

      this.pager.companyId = this.CompanyId;

      // const data= {
      //   "maxResultCount": this.pager.maxResultCount,
      //   "customerId": this.customerid,
      //   "skipCount": 0,
      //   "donationMainType":this.donationMainTypeId,
      //   "mobile": this.searchDonation.mobile,
      //   "receiptNum": this.searchDonation.receiptNum,
      //   "companyId": this.CompanyId
      // }

      this.GetCustomerDonations(this.pager);
    }
  }

  private replaceBrowserState() {
    //#region When the search box is used it must replaceState
    const state = { customerid: null, callid: null };
    // Use replaceState method to replace the browser's history state
    this.location.replaceState(this.location.path(), "", state);
    //#endregion  "Mustafa" 8/20/2023
  }
}
