<div class="container-fluid top-padding" style="height: 250px; ">
    <div class="text-right" style="padding: 5px; margin-top: 25px;">
        <label for="comment"> اختر الرسالة </label>
    <!-- <textarea   [(ngModel)]="form.comment"
    required="required" class="form-control textForArabic txtAria" placeholder="   ">
    </textarea> -->
    <ng-select class="js-example-basic-single"
    aria-label="Default select example"
    (change)="SelectedMessage($event?.id)"
    required="required"
    [items]="SmsTemplates"
    bindLabel="smscontent"
    bindValue="id"
     >
    
    <!-- <ng-option value=null > أختر  نوع المشروع </ng-option>
    <ng-option *ngFor="let obj of Sector_Type_List"  [selected]="update&&obj.id==SectorLocationInfo.sectorTypeId" value="{{obj.id}}">
        {{ obj.title }}
    </ng-option> -->
    </ng-select>
    <br>
    
    <button (click)="send()"  class="btn btn-danger  btn-lg btn-block" 
    > ارسال </button>
    </div>
    
    </div>
    

