 

<div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header" style="padding-bottom: 5px !important">
            <div *ngIf="update; else elseBlock1">
              <h5 class="pb-2">تعديل الفئه الموجوده</h5>
             
              تعديل الفئه  فى القائمة   
            </div>
            <ng-template #elseBlock1>
              <h5 class="pb-2">إضافة التبرع العيني</h5>
              <span style="font-size: 14px">
                يمكنك إضافة تبرع عيني    فى القائمة  
              </span>
            </ng-template>
          </div>
          <div class="card-body">
            <form class="needs-validation" [formGroup]="InsertForm" (ngSubmit)="onSubmit()" novalidate [ngClass]="{'was-validated': validated}">
   
              <div class="form-row">
                <!-- title -->
            
                <div class="col-12 mb-3">
                  <label for="validationCustom01"><span class="validation-required">*</span>  فئه التبرع العيني</label>
                  <input
                    class="form-control"
                    id="validationCustom01"
                    type="text"
                    formControlName="title"
                    placeholder=" اكتب  نوع التبرع العيني"
                    required=""
                    [(ngModel)]="donationCategory.title" 
                  />
                </div>
          
            </div>
         
        
            
    
 
     
              <ng-template #elseBlock>
                <button class="btn btn-primary pull-right" type="submit" >إضافة</button>
              </ng-template>
              <div *ngIf="update; else elseBlock">
                <button class="btn btn-primary pull-right" type="submit">تعديل</button>
              </div>
              </form>
             
          </div>
        </div>
      </div>
    </div>
  </div>
  