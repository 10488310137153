import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { SectorLocationApiService } from 'src/app/shared/API-Service/sector-location-api.service';
import {MediaApiService} from 'src/app/shared/API-Service/media-api.service'
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-list-project-accomplishment',
  templateUrl: './list-project-accomplishment.component.html',
  styleUrls: ['./list-project-accomplishment.component.css']
})
export class ListProjectAccomplishmentComponent extends BaseComponent implements OnInit {
  totalCount:any;
  MediaTypes:any[]=[];
  pageNumber:any;
  SectorLocation: any[]=[];
  ProjectMedia: any;
  SectorLocationid: string;
  SectorLocationTitle: any;
  serverImageUrl: string = environment.Server_URL_Image;
  constructor(private route: ActivatedRoute,private router:Router,private SectorLocationApiService:SectorLocationApiService,private MediaApiService:MediaApiService) {super() }
  pageChanged(e){

  }
  ngOnInit(): void {
    this.SectorLocationid =    this.route.snapshot.paramMap.get('id')
    this.GetMediaType(this.SectorLocationid)
    this.GetSectorLocations();
  }
  GetMediaType(id){
    this.MediaApiService.Get(id).subscribe((res:any)=>{
   //   this.ProjectMedia=res.data.projectMedia
   this.ProjectMedia=res.data

    })
  }
  AddNew(){
    this.router.navigate([`content/admin/insert-project-media`,this.SectorLocationid]);

  }
  Update(id){
    this.router.navigate([`content/admin/update-project-media`, id,this.SectorLocationid]);
  }
  SelectSectorLocation(e){
    const id = e.id;
    this.MediaApiService.Get(id).subscribe((res:any)=>{
   //   this.ProjectMedia=res.data.projectMedia
      this.ProjectMedia=res.data


    })
  }
  GetSectorLocations(){
    this.SectorLocationApiService.GetSectorAllLocation().subscribe(res=>{
      this.SectorLocation=res.data
      this.SectorLocationTitle=this.SectorLocation.filter(x=>x.id==this.SectorLocationid)[0].title
      
    })
  }

  Delete(id: number) {

    Swal.fire({
      title: " تحذير !",
      text: "هل انت متأكد من حذف هذا العنصر ؟ ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#7364f6',
      cancelButtonColor: "#d33",
      confirmButtonText: "حذف",
      cancelButtonText: "رجوع",
    }).then((result) => {
      if (result.isConfirmed) {
        this.MediaApiService.DeleteProjectMedia(id).subscribe(
          (res) => {
            this.GetMediaType(this.SectorLocationid)
            Swal.fire({
              icon: "success",
              title: "تم حذف الميديا بنجاح",
              showConfirmButton: false,
              timer: 1500,
            });
          },
          (err) => {
            Swal.fire({
              icon: "error",
              title: "خطأ",
              text: err.error.message,
            });
          }
        );
      } else {
 
      }
    });
  }
  OpenImage(imagesView){
     
    Swal.fire({
      imageUrl: this.serverImageUrl+imagesView,
      imageHeight: 400,
      imageAlt: 'A tall image',
      confirmButtonText:"الغاء"
    })
  }
}
