export class SearchReports {
    creationDateFrom:string = '';
    creationDateTo:string = '';
    collectionDateFrom:string = '';
    collectionDateTo:string = '';
    companyId:number=null;
    donationTypeId:number=null;
    donationTypeName:string='';

    sectorId:number=null
    sectorName:string='';

    sectorTypeId:number =null;
    sectorTypeName:string='';

    sectorLocationId:number=null;
    sectorLocationName:string='';
    
    employeeId:number=null;
    employeeName:string='';
    reportCreatedBy:string=''
}
