import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CollectorManagerService } from 'src/app/shared/API-Service/collector-manager.service';
import { DontaionsApiService } from 'src/app/shared/API-Service/donations-api-service';
import { EmployeeApiService } from 'src/app/shared/API-Service/employee-api.service';
import { FollowDonations } from '../../../shared//Models/FollowDonations';
import { CommentModalComponent } from '../../Agent/comment-modal/comment-modal.component';
import { BaseComponent } from '../../../shared/components/base/base.component';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ChoosecompanyModalComponent } from 'src/app/shared/components/ChooseCompanyModal/choosecompany-modal/choosecompany-modal.component';
import { environment } from 'src/environments/environment.prod';
import { SendSMSComponent } from '../send-sms/send-sms.component';

@Component({
  selector: 'app-follow-donations',
  templateUrl: './follow-donations.component.html',
  styleUrls: ['./follow-donations.component.css']
})
export class FollowDonationsComponent extends BaseComponent implements OnInit {
  modalRef: BsModalRef;
  Dontations: any[];
  err: boolean;
  CollectedClosedDonations= new FollowDonations()
  Employees:any[]=[]
  Collector:any[]=[]
  details: any;
  AllDontationType: any[];
  CompanyId: number;
  LoadPage: boolean;
  imgUrlServer=environment.Server_URL_Image+'/'
  constructor(private ChooseCompanyModalService: BsModalService,private router:Router,public datepipe: DatePipe,public modalService: NgbModal,private DontaionsApiService : DontaionsApiService,private EmployeeApiService : EmployeeApiService,private CollectorManagerService:CollectorManagerService) {
    super();
   }
  
  ngOnInit(): void {
    const modalConfig = {
      class: 'modal-dialog-centered modal-lg', // Add your desired width class here
      ignoreBackdropClick: true
    };
    this.modalRef=this.ChooseCompanyModalService.show(ChoosecompanyModalComponent, modalConfig);
    this.modalRef.content.modalClosed.subscribe((company: any) => {
      this.LoadPage=true;
      this.CompanyId=company.companyId
      this.pager.companyId=this.CompanyId;
      this.GetEmployees();
      this.GetCollectors();
      const date = new Date();
      this.CollectedClosedDonations.donationDateFrom=this.datepipe.transform(date, 'yyyy-MM-dd');
      this.CollectedClosedDonations.donationDateTo=this.datepipe.transform(date, 'yyyy-MM-dd');
    });
  
   
  }
  SendSMSTemplate(item?){
    localStorage.removeItem("donationId");
    localStorage.removeItem("customerid");
    if(item){

    localStorage.setItem("donationId",item.donationId);
    }
    
      //ModalComponent is component name where modal is declare
      const modalRef = this.modalService.open(SendSMSComponent)
      modalRef.componentInstance.CompanyId = this.CompanyId;
      modalRef.result.then((result) => {

      }).catch((error) => {
     
      });
  }

  track(index, item) {
    return item.id;
  }
  OpenDetailes(index){
    if(this.Dontations[index]["ShowDetails"]){
      this.details=false;
      this.Dontations[index]["ShowDetails"]=false;
    this.Dontations= this.AllDontationType 
    }
    else{
      this.details=true;
        this.Dontations[index]["ShowDetails"]=true;
        this.Dontations= this.AllDontationType;
    }
  }
  Search(){
this.GetCollectedClosedDonations();
  }
  GetEmployees(){
    this.EmployeeApiService.GetEmployee().subscribe((res:any)=>{
      this.Employees=res.data;
    })
  }
  openAddress(address){
    Swal.fire({
      position: 'center',
      title: address,
    })
   }
  pageChanged(event: any){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.pager.donationDateFrom=this.CollectedClosedDonations.donationDateFrom
    this.pager.donationDateTo=this.CollectedClosedDonations.donationDateTo
    this.pager.createdBy=this.CollectedClosedDonations.createdBy
    this.pager.collectorId=this.CollectedClosedDonations.collectorId
    var res;
        this.DontaionsApiService.GetCollectedClosedDonations(this.pager).subscribe(
            {
      next: (data) => {
        res = data;
        if (res.messageCode == 200) {
          this.err = false;
          this.Dontations = res.data.items;
          this.totalCount = res.data.totalCount;
          this.AllDontationType=res.data.items;
      
        } else {
          this.err = true;
        }
      },
      error: (error) => {
        this.err = true;
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: error.error.message,
        })
      },
    });  

  }
  openModal(donationId) {
    //ModalComponent is component name where modal is declare
    localStorage.removeItem("ModalData");
    localStorage.setItem("ModalData",donationId);
    const modalRef = this.modalService.open(CommentModalComponent);
    modalRef.result.then((result) => {
    }).catch((error) => {
   
    });
  }
  GetCollectedClosedDonations(){
    this.Dontations = [];
    var res;
    if(!this.CollectedClosedDonations.donationDateFrom){
      this.CollectedClosedDonations.donationDateFrom=null;
    }
    if(!this.CollectedClosedDonations.donationDateTo){
      this.CollectedClosedDonations.donationDateTo=null;
    }
    if(!this.CollectedClosedDonations.createdBy){
      this.CollectedClosedDonations.createdBy=null;
    }
    if(!this.CollectedClosedDonations.collectorId){
      this.CollectedClosedDonations.collectorId=null;
    }
    this.pager.donationDateFrom=this.CollectedClosedDonations.donationDateFrom
    this.pager.donationDateTo=this.CollectedClosedDonations.donationDateTo
    this.pager.createdBy=this.CollectedClosedDonations.createdBy
    this.pager.collectorId=this.CollectedClosedDonations.collectorId
        this.DontaionsApiService.GetCollectedClosedDonations(this.pager).subscribe({
      next: (data) => {
        res = data;
        if (res.messageCode == 200) {
          this.err = false;
          this.Dontations = res.data.items;
          this.totalCount = res.data.totalCount;
          this.AllDontationType=res.data.items;
        } else {
          this.err = true;
        }
      },
      error: (error) => {
        this.err = true;
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: error.error.message,
        })
      },
    });  
  }
  GoToAccomplishment(id){
    // this.router.navigate( ['/content/admin/customer-accomplishment', {id: id}]);
    this.router.navigate(['/content/admin/customer-accomplishment/',id]);
}
  GetCollectors(){
    this.CollectorManagerService.GetAllCollectorEmployees().subscribe(res => {
      this.Collector = res.data;
    })
  }
}
