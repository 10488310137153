

 

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding-bottom: 5px !important">
          <div *ngIf="update; else elseBlock1">
            <h5 class="pb-2">تعديل نوع التبرعات موجود</h5>
            يمكنك تعديل نوع   التبرع
          </div>
          <ng-template #elseBlock1>
            <h5 class="pb-2">إضافة النوع  التبرع جديد</h5>
            <span style="font-size: 14px">
              يمكنك إضافة النوع تبرع  جديد
            </span>
          </ng-template>
        </div>
        <div class="card-body">
 

          
          <div>


            
            <!-- [class.is-valid]="signUp.get('first_name')?.status=='VALID'"  -->
            <div class="form-group" novalidate [ngClass]="{'was-validated': validate}" >
              
            <label for="title" class="type-label"><span class="validation-required">*</span>  تحديد التبرع</label>
            <!-- [class.is-valid]="!title.invalid -->

              <input type="text" [formControl]="title"   class="form-control"    placeholder="اضافه نوع  تبرع جديد" id="title" id="validationCustom01"    required=""   />
      
            </div>


            <div class="form-group">
          
            </div>
            <ng-template #elseBlock>
              <button
                class="btn btn-primary pull-right"
                type="submit"
         
                (click)="onSubmit()"
              >
                إضافة
              </button>
            </ng-template>
            <div *ngIf="update; else elseBlock">
              <button
                class="btn btn-primary pull-right"
                type="submit"
               
                (click)="onSubmit()"
              >
                تعديل
              </button>
            </div>
          </div>
          
          
          
        </div>
      </div>
    </div>
  </div>
</div>




