 



<app-breadcrumb [title]="'إدارة الرسائل'"  [active_item]="'Form Wizard'"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding-bottom:5px !important ;">
            <h3 class="card-title">إدارة الرسائل</h3>

        </div>
        <div class="card-body">
            <!-- <form [formGroup]="InsertForm"> -->
          <form class="needs-validation" [formGroup]="InsertForm" (ngSubmit)="onSubmit()" novalidate >

          

            <div class="form-row">
              <div class="col-12 mb-3">
                <label ><span class="validation-required">*</span> أختر الشركة</label>
                
                <ng-select class="js-example-basic-single"
                [(ngModel)]="form.companyId"
                        formControlName="companyId"
                        aria-label="Default select example"
                        (change)="SelectCompany($event.id)"
                        required="required"
                        [items]="Companies"
                        bindLabel="name"
                        bindValue="id"
                        [tooltip]="fc.companyId.errors?.required ? ' الشركة مطلوبه' : null" required
                        [isDisabled]="!((fc.companyId.errors?.required && fc.companyId.touched))"
                        containerClass="" [class.is-valid]="fc.companyId.valid && fc.companyId.touched"
  [class.is-invalid]="fc.companyId.invalid && fc.companyId.touched"   [style.border]="fc.companyId.touched ? (fc.companyId.invalid ? '1px solid #dc3545 !important' : (fc.companyId.valid && fc.companyId.touched ? '1px solid #28a745' : 'none')) : 'none'">
                         >
                </ng-select>
            </div>
              <div class="col-12 mb-3">
                  <label for="validationCustom01"> <span class="validation-required">*</span> 
                    موضوع الرساله</label>
                  <input class="form-control"  [(ngModel)]="form.smscontent" id="validationCustom01" type="text" placeholder="" required=""
                    formControlName="smscontent" 
                    [tooltip]="fc.smscontent.errors?.required ? ' موضوع الرساله مطلوب' : null" required
                    [isDisabled]="!((fc.smscontent.errors?.required && fc.smscontent.touched))"
                    containerClass="" [class.is-valid]="fc.smscontent.valid && (fc.smscontent.touched)"
                    [class.is-invalid]="fc.smscontent.invalid && (fc.smscontent.touched)">
                    
              
                </div>
                <!-- <div class="col-12 mb-3">
                  <label for="validationCustom02"><span class="validation-required">*</span> حاله التبرع  </label>

                  <ng-select name="" id="" class="js-example-basic-single" formControlName="donationStatusId"
                  [items]="donationStatus"
                  bindLabel="title"
                  bindValue="id"
                  >
                  </ng-select>
                </div> -->

          
                      
                  <div class="col-12 mb-3">
                   
                    <label for="validationCustom02"><span class="validation-required">*</span> وصف الرساله </label>
                  <textarea type="text" class="form-control" [(ngModel)]="form.description" formControlName="description"
                  [tooltip]="fc.description.errors?.required ? ' وصف الرساله مطلوب' : null" required
                  [isDisabled]="!((fc.description.errors?.required && fc.description.touched))"
                  containerClass="" [class.is-valid]="fc.description.valid && (fc.description.touched)"
                  [class.is-invalid]="fc.description.invalid && (fc.description.touched)"
                  style="height: 124px;"></textarea>
                </div>
                
                </div>
                       
                 <!-- <div *ngIf="update; else elseBlock">
                  <button
                    class="btn btn-primary pull-right"
                    type="submit"

                  >
                    تعديل
                  </button>
                </div> -->
                
                    <button #elseBlock class="btn btn-primary pull-right" 
                    type="submit"
                    >
                    إضافة
                 </button>
               
                
              </form>
        </div>
      </div>
    </div>
  </div>
</div>

