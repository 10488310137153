export class CallReasonsCalls{
    companyId:number=null  as any;
    sourceOfMarktingId:number=null  as any;
    callTypeId:number=null  as any;
    callDateFrom:string = '';
    callDateTo:string = '';
    callTypeName:string = '';
    sourceOfMarketingName:string = '';
    reportCreatedBy:string='';
    createdByName:string='';
    createdById:number=null  as any;
    answered:boolean
}