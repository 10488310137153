import { Dination } from "../../../../shared//Models/dination";
import { ActivatedRoute, Router } from "@angular/router";
import {
  DonationType,
  HttpDonationTypeCreate,
  HttpDonationTypeRespons,
} from "../../../../shared//Models/http-donation-type-select";
import { DonationTypeApiService } from "./../../../../shared/API-Service/donation-type-api.service";
import { HttpDonationMType } from "../../../../shared//Models/donation-m-type";
import {
 
  FormGroup,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { Component, OnInit ,OnDestroy} from "@angular/core";
import { DonationMTypeApiService } from "./../../../../shared/API-Service/donation-m-type-api.service";
import Swal from "sweetalert2";

// ActivatedRoute

@Component({
  selector: "app-insert-donation-type",
  templateUrl: "./insert-donation-type.component.html",
  styleUrls: ["./insert-donation-type.component.css"],
})
export class InsertDonationTypeComponent implements OnInit   ,OnDestroy  {
  InsertForm: FormGroup;

  update: boolean = false;
  // isNotify: boolean = false;
  items: HttpDonationMType[] = [];
  dataValue: HttpDonationTypeRespons[];
  donationMType = new Dination();
  // donationMType:any={}
  id: number;
  item: any;
  chenge: boolean = false;
  Categorys: any;
  validated: boolean;

  constructor(
    private _DonationMTypeApiService: DonationMTypeApiService,
    private _DonationTypeApiService: DonationTypeApiService,
    private _router: Router,
    private fb: FormBuilder,
    private _ActivatedRoute: ActivatedRoute
  ) {}
 
  ngOnInit(): void {
    // form init
    this.InsertForm = this.fb.group({
      title: ["", Validators.required],
      isNotify: [],
      donationCategoryId: ["",Validators.required],
      donationMainTypeId: ["", Validators.required],
    });


    // call data viwe
    this.getData();
    this.getCategory();
    this._DonationTypeApiService.Data.subscribe((res) => {
  
      if (this._DonationTypeApiService.Data.getValue() != null) {
        this.donationMType = res;

        this.update = true;

        this.chenge = res.donationMainTypeId == 2 ? true : false;
        if(res.donationMainTypeId==2){
          const donationCategoryIdControl = this.InsertForm.get('donationCategoryId');
          donationCategoryIdControl?.setValidators(Validators.required);
          donationCategoryIdControl?.updateValueAndValidity();
        }
        else{
          const donationCategoryIdControl = this.InsertForm.get('donationCategoryId');
          donationCategoryIdControl?.clearValidators();
          donationCategoryIdControl?.updateValueAndValidity();
        }
      } else {

        // this.InsertForm.reset();
      }
    });
    const type = this._ActivatedRoute.snapshot.params["type"];
    


    // === ngOnDestroy
    // if (type == "create") {
    //   this.update = false;
    //   this.InsertForm.reset();
    // } else {
    //   this.update = true;

    // }
  }
  containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }
  ngOnDestroy() {
    this._DonationTypeApiService.Data.next(null);

  }
  onSubmit() {
    // this.validated = !this.validated
console.log(this.InsertForm.value)
const control = this.InsertForm.get('donationCategoryId');
console.log(control?.errors?.required);
    // this.validated = !this.validated
  //   if(this.containsSpecialChars(this.InsertForm.get("title").value)==true){
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'خطأ',
  //       text: "لا يمكن كتابه علامات ",
  //     })
  //   }
  //  else 
   if (this.InsertForm.status == "VALID") {
      this.validated = false;

      if (this.update == true) {
        this.Edit();
      } else {
        this.insertData();
      }
    } else {
      this.validated = true;
    }
  }

  // Get-Donation-Main-Type-Reason
  getData() {
    var res;
    this._DonationMTypeApiService.get().subscribe({
      next: (respnose) => {
        res = respnose;
        this.items = res.data;
      },
    });
  }
  // Get-Donation-Category-reaspons
  getCategory() {
    var res;

    this._DonationTypeApiService.getDonationCategory().subscribe({
      next: (respnose) => {
        res = respnose;
        this.Categorys = res.data;
      },
    });
  }
  // Insert-Donation-Type
  insertData() {

    this._DonationTypeApiService.post(this.donationMType).subscribe((res) => {
      if (res.success) {
        this.InsertForm.reset();

        Swal.fire({
          icon: "success",
          title: "تم إدخال النوع بنجاح",
          showConfirmButton: false,
          timer: 1500,
        });

        this._router.navigate(["/content/admin/ViewDonationType"]);
      } else {
    

        Swal.fire({
          icon: "error",
          title: "خطاء في إدخال البيانات ",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  }
  // Update-Donation-Type

  Edit() {
    this._DonationTypeApiService.Put(this.donationMType).subscribe((res) => {
      if (res) {
        this.InsertForm.reset();
        this._DonationMTypeApiService.Data.next(null);

        Swal.fire({
          icon: "success",
          title: "تم التعديل   بنجاح",
          showConfirmButton: false,
          timer: 1500,
        });

        this._router.navigate(["/content/admin/ViewDonationType"]);
        this._DonationMTypeApiService.Data.next(null);
      } else {


        Swal.fire({
          icon: "error",
          title: "خطاء في تعديل البيانات ",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  }

  chenged(id) {
    //  let x = item
    if (id == 2) {
      this.chenge = true;
      const donationCategoryIdControl = this.InsertForm.get('donationCategoryId');
      donationCategoryIdControl?.setValidators(Validators.required);
      donationCategoryIdControl?.updateValueAndValidity();
    } else {
      this.chenge = false;
      const donationCategoryIdControl = this.InsertForm.get('donationCategoryId');
      donationCategoryIdControl?.clearValidators();
      donationCategoryIdControl?.updateValueAndValidity();

    }
  }
} 

