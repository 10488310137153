<!-- <app-breadcrumb [title]="'إضافة عميل جديد'" [active_item]="'Form Wizard'"></app-breadcrumb> -->
<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="card" style="margin-top: 30px">
        <!-- <div class="card-header" style="padding-bottom:5px !important ;">
                    <div *ngIf="update; else elseBlock1">
                        <h5 class="pb-2">تعديل بيانات عميل موجود</h5>
                        يمكنك تعديل بيانات عميل 
                    </div>
                    <ng-template #elseBlock1>
                        <h5 class="pb-2">إضافة عميل جديد</h5>
                        يمكنك إضافة عميل أو أكثر فى قائمة العملاء
                    </ng-template>

                </div> -->
        <div class="card-body">
          <form [formGroup]="EmployeeForm">
            <!-- name -->
            <div class="form-group">
              <label for="name"> أسم ألعميل</label>
              <input
                class="form-control"
                id="name"
                type="text"
                placeholder="أحمد"
                required="required"
                formControlName="name"
              /><!-- onlyAlphabets -->

              <ng-container
                class="invalid"
                style="color: red"
                *ngIf="
                  EmployeeForm.get('name').hasError('required') &&
                  EmployeeForm.get('name').touched
                "
              >
                <span style="color: red"> أسم العميل مطلوب </span>
              </ng-container>
            </div>

            <!-- Gender -->
            <div class="form-group">
              <label>أختر نوع العميل </label>
              <ng-select
              class="js-example-basic-single"
                formControlName="Gender"
                aria-label="Default select example"
                (change)="SelectedItem($event.id)"
                [items]="gender"
                bindLabel="title"
                bindValue="id"
              >
                <!-- <ng-option value="" selected disabled>أختر نوع العميل</ng-option>
                <ng-option value="1">ذكر</ng-option>
                <ng-option value="0">أنثى</ng-option> -->
              </ng-select>
            </div>

            <!-- mobile -->
            <div class="form-group">
              <label for="mobilenumber"> رقم الموبايل</label>
              <input
                class="form-control"
                id="mobilenumber"
                type="text"
                placeholder="رقم فقط 11"
                required="required"
                formControlName="mobile"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="11"
                minlength="11"
              />

              <ng-container
                class="invalid"
                style="color: red"
                *ngIf="
                  EmployeeForm.get('mobile').hasError('required') &&
                  EmployeeForm.get('mobile').touched
                "
              >
                <span style="color: red"> رقم الموبايل مطلوب </span>
              </ng-container>
            </div>

            <!-- mobile 2 -->
            <div class="form-group">
              <label> رقم الموبايل أخر </label>
              <input
                class="form-control"
                type="text"
                placeholder="رقم فقط 11"
                required="required"
                formControlName="mobile2"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="11"
                minlength="11"
              />

              <ng-container
                class="invalid"
                style="color: red"
                *ngIf="
                  EmployeeForm.get('mobile2').hasError('required') &&
                  EmployeeForm.get('mobile2').touched
                "
              >
                <span style="color: red"> رقم الموبايل مطلوب </span>
              </ng-container>
            </div>

            <!-- phone -->
            <div class="form-group">
              <label> رقم تليفون</label>
              <input
                class="form-control"
                type="text"
                placeholder="رقم فقط 11"
                required="required"
                formControlName="phone"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="11"
                minlength="11"
              />

              <ng-container
                class="invalid"
                style="color: red"
                *ngIf="
                  EmployeeForm.get('phone').hasError('required') &&
                  EmployeeForm.get('phone').touched
                "
              >
                <span style="color: red"> رقم الموبايل مطلوب </span>
              </ng-container>
            </div>

            <!-- governorate -->
            <div class="form-group">
              <label>أختر المحافظة</label>
              <ng-select
              class="js-example-basic-single"
                aria-label="Default select example"
                (change)="SelectedGovernorate($event.id)"
                [items]="Governorate_List"
                bindLabel="title"
                bindValue="id"
              >
              </ng-select>
            </div>

            <!-- city -->
            <div class="form-group">
              <label>أختر المدينة</label>
              <ng-select
              class="js-example-basic-single"
                aria-label="Default select example"
                formControlName="CityId"
                (change)="SelectedCity($event.id)"
                [items]="Filtered_List"
                bindLabel="title"
                bindValue="id"
              >
              </ng-select>
            </div>

            <!-- address -->
            <div class="form-group">
              <label for="mobilenumber"> العنوان</label>
              <textarea
                formControlName="address"
                class="form-control textForArabic"
                placeholder="أكتب العنوان بالتفصيل "
                style="height: 160px"
              >
              </textarea>

              <ng-container
                class="invalid"
                style="color: red"
                *ngIf="
                  EmployeeForm.get('address').hasError('required') &&
                  EmployeeForm.get('address').touched
                "
              >
                <span style="color: red"> العنوان مطلوب </span>
              </ng-container>
            </div>

            <!-- DateOfBirth -->
            <div class="form-group">
              <label> تاريخ الميلاد </label>
              <input
                class="form-control"
                type="date"
                required="required"
                formControlName="DateOfBirth"
              />

              <ng-container
                class="invalid"
                style="color: red"
                *ngIf="
                  EmployeeForm.get('DateOfBirth').hasError('required') &&
                  EmployeeForm.get('DateOfBirth').touched
                "
              >
                <span style="color: red"> تاريخ الميلاد مطلوب </span>
              </ng-container>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="card" style="margin-top: 30px">
        <div class="card-header" style="padding-bottom: 5px !important ">
          <h5 class="pb-2">ملخص المكالمة</h5>
        </div>
        <div class="card-body">
          <form [formGroup]="Form">

            <div class="form-group">
              <label>نوع المكالمة</label>
              <ng-select
              class="js-example-basic-single"
                formControlName="callType"
                aria-label="Default select example"
                (change)="SelectedcallType($event.id)"
                [items]="callType"
                bindLabel="title"
                bindValue="id"
                >
                <!-- <ng-option value="" selected disabled>أختر نوع المكالمة</ng-option>
                <ng-option value="0" >صادرة</ng-option>
                <ng-option value="1" >واردة</ng-option> -->
               
              </ng-select>
            </div>

            <div class="form-group">
              <label> سبب المكالمة</label>
              <ng-select
              class="js-example-basic-single"
                formControlName="callReasonId"
                aria-label="Default select example"
                (change)="SelectedcallReason($event.id)"
                [items]="CallReason_List"
                bindLabel="name"
                bindValue="id"
              >
              </ng-select>
            </div>

            <div class="form-group">
              <label> سبب المكالمة</label>
              <input
                class="form-control"
                type="text"
                placeholder="أكتب سبب المكالمة هنا"
                formControlName="reason"
              />

              <ng-container
                class="invalid"
                style="color: red"
                *ngIf="
                  Form.get('reason').hasError('required') &&
                  Form.get('reason').touched
                "
              >
                <span style="color: red"> سبب المكالمة مطلوب </span>
              </ng-container>
            </div>

            <div class="form-group">
              <label> وصف المكالمة</label>
              <!-- <input class="form-control" type="text" placeholder="أكتب تفاصيل المكالمة هنا "
                                formControlName="description"> -->
              <textarea
                formControlName="description"
                class="form-control textForArabic"
                placeholder="أكتب تفاصيل المكالمة هنا "
                style="height: 75px"
              >
              </textarea>

              <ng-container
                class="invalid"
                style="color: red"
                *ngIf="
                  Form.get('description').hasError('required') &&
                  Form.get('description').touched
                "
              >
                <span style="color: red"> تفاصيل المكالمة مطلوبة </span>
              </ng-container>
            </div>

            <div class="form-group">
              <label> ملاحظات عن المكالمة</label>
              <input
                class="form-control"
                type="text"
                placeholder="أكتب ملاحظات المكالة هنا "
                formControlName="notes"
              />
            </div>

            <div class="form-group">
              <label> مصدر الاعلان</label>
              <ng-select
              class="js-example-basic-single"
                formControlName="sourceMarketId"
                aria-label="Default select example"
                (change)="SelectedsourceMarket($event.id)"
                [items]="SourceMarket_List"
                bindLabel="title"
                bindValue="id"
              >
              </ng-select>
            </div>

            <!-- [checked]="item.Active" -->
            <div class="form-group">
              <br />
              <label> رضاء العميل عن المكالة</label><br />
              <label class="switch">
                <input type="checkbox" (click)="toggle_click()" />
                <span class="slider round"></span>
              </label>
            </div>

            <!--update button -->
            <div>
              <button
                class="btn btn-primary pull-right m-1"
                type="submit"
                [disabled]="!Form.valid && !EmployeeForm.valid"
                (click)="submitData()"
              >
                تسجيل
              </button>
              <button class="btn btn-primary pull-right m-1" (click)="back()">
                رجوع
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
