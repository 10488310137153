
import { ListJobsComponent } from './users/jobs/list-jobs/list-jobs.component';
import { InsertJobsComponent } from './users/jobs/insert-jobs/insert-jobs.component';
import { CollectorDonationsComponent } from './Reports/collector-donations/collector-donations.component';
import { SmallDonationReportsComponent } from './Reports/small-donation-reports/small-donation-reports.component';
import { SearchReportsComponent } from './Reports/search-reports/search-reports.component';
import { CollectorBatchesComponent } from './Reports/collector-batches/collector-batches.component';
import { DonationRequestsComponent } from './Reports/donation-requests/donation-requests.component';
import { SourceofmarketingComponent } from './Reports/sourceofmarketing/sourceofmarketing.component';
import { InsertDonationCategoryComponent } from './DonationCategory/insert-donation-category/insert-donation-category.component';
import { DonationCategoryComponent } from './DonationCategory/donation-category/donation-category.component';
import { DonationMainTypeComponentComponent } from "./Donation/donation-main-type-component/donation-main-type-component.component";
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientTypeComponent } from './lookups/ClientType/client-type/client-type.component';
import { GovernorateComponent } from './lookups/Governorate/insert-Governorate/insert-Governorate.component';
import { ListClientTypeComponent } from './lookups/ClientType/list-client-type/list-client-type.component';
import { ListGovernorateComponent } from './lookups/Governorate/list-governorate/list-governorate.component';
import { InsertCitiesComponent } from './lookups/Cities/insert-cities/insert-cities.component';
import { ListCitiesComponent } from './lookups/Cities/list-cities/list-cities.component';
import { CallReasonComponent } from './lookups/CallReason/CallReason/CallReason.component';
import { InsertCallReasonComponent } from './lookups/CallReason/InsertCallReason/InsertCallReason.component';
import { SourceMarketComponent } from './lookups/SourceMarketing/source-market/source-market.component';
import { InsertSourceMarketComponent } from './lookups/SourceMarketing/insert-source-market/insert-source-market.component';
import { ClientComponent } from './users/clients/Client/Client.component';
import { InsertClientComponent } from './users/clients/InsertClient/InsertClient.component';
import { EmployeeComponent } from './users/employee/List-Employees/employee.component';
import { InsertEmployeeComponent } from './users/employee/insert-employee/insert-employee.component';
import { InsertCustomerServiceComponent } from './users/Customer-Service/InsertCustomerService/InsertCustomerService.component';
import { CustomerServiceComponent } from './users/Customer-Service/customer-service/customer-service.component';
import { ClientAgentComponent } from './Reports/client-agent/client-agent.component';
import { ClientsComponent } from './Reports/clients/clients.component';
import { ClientCallComponent } from './Reports/client-call/client-call.component';
import { ClientCallDetailsComponent } from './Reports/client-call-details/client-call-details.component';
import { CallStartEndReportComponent } from './Reports/Call_Start_End/call-start-end-report.component';
import { StatsComponent } from './statistics/stats/stats.component';
import { AgentListStatComponent } from './statistics/agent-list-stat/agent-list-stat.component';
import { CallReasonStatComponent } from './statistics/call-reason-stat/call-reason-stat.component';
import { InsertSectorComponent } from '../sector/insert-sector/insert-sector.component';
import { ListSectorComponent } from '../sector/list-sector/list-sector.component';
import { InsertSectorTypeComponent } from './lookups/SectorType/insert-sector-type/insert-sector-type.component';
import { ListSectorTypeComponent } from './lookups/SectorType/list-sector-type/list-sector-type.component';
import { InsertSectorLocationComponent } from './lookups/SectorLocation/insert-sector-location/insert-sector-location.component';
import { ListSectorLocationComponent } from './lookups/SectorLocation/list-sector-location/list-sector-location.component';
import { ListCollectorCityComponent } from './lookups/CollectorCity/list-collector-city/list-collector-city.component';
import { InsertCollectorCityComponent } from './lookups/CollectorCity/insert-collector-city/insert-collector-city.component';
import { InsertDonationMTypeComponent } from "./Donation/insert-donation-m-type/insert-donation-m-type.component";
import { InsertDonationTypeComponent } from "./DonationType/insert-donation-type/insert-donation-type.component";
import { ViewDonationTypeComponent } from "./DonationType/view-donation-type/view-donation-type.component";
import { AddsmsTemplateComponent } from './sms/addsms-template/addsms-template.component';
import { SmsTemplateComponent } from './sms/sms-template/sms-template.component';
import { AllPermissionComponent } from './Permissions/all-permission/all-permission.component';
import { AddPermissionComponent } from './Permissions/add-permission/add-permission.component';
import { AuthGuardGuard } from '../../shared/services/auth-guard.guard'
import { BulkSMSComponent } from './sms/bulk-sms/bulk-sms.component';
import { FollowDonationsComponent } from './follow-donations/follow-donations.component'
import { StatdetailsComponent } from './statistics/statdetails/statdetails.component';
import { CollectorSmallDonationComponent } from './Reports/collector-small-donation/collector-small-donation.component';
import { GetCallCustomerDateComponent } from './Reports/get-call-customer-date/get-call-customer-date.component';
import { AllBatchesComponent } from './Reports/all-batches/all-batches.component';
import { DefaultComponent } from './default/default.component';
import { MyProjectsComponent } from './Projects/my-projects/my-projects.component';
import { AllProjectsComponent } from './Projects/all-projects/all-projects.component';
import { DonationsReportComponent } from './Reports/donations-report/donations-report.component';
import { CustomerStatsComponent } from './statistics/customer-stats/customer-stats.component';
import { SourcemarketDonationsReportComponent } from './Reports/sourcemarket-donations-report/sourcemarket-donations-report.component';
import { ProjectDonationsReportComponent } from './Reports/project-donations-report/project-donations-report.component';
import { InsertReceiptBookComponent } from './ReceiptBook/insert-receipt-book/insert-receipt-book.component';
import { ListReceiptBookComponent } from './ReceiptBook/list-receipt-book/list-receipt-book.component';
import { CollectorReceiptsComponent } from './ReceiptBook/collector-receipts/collector-receipts.component';
import { ChangeEmployeePasswordComponent } from '../admin/users/employee/change-employeepassword/change-employeepassword.component'
import { ReceiptReportComponent } from './Reports/receipt-report/receipt-report.component';
import { SourceMarketCustomersComponent } from './Reports/source-market-customers/source-market-customers.component';
import { CompaniesReportComponent } from './Reports/companies-report/companies-report.component';
import { CollectorReceiptsEditComponent } from './ReceiptBook/collector-receipts-edit/collector-receipts-edit.component';
import { MediaTypeComponent } from './Media/media-type/media-type.component';
import { InsertMediaTypeComponent } from './Media/insert-media-type/insert-media-type.component';
import { InsertProjectMediaComponent } from './Media/project-accomplishment/insert-project-media/insert-project-media.component';
import { ListProjectAccomplishmentComponent } from './Media/project-accomplishment/list-project-accomplishment/list-project-accomplishment.component'
import { CustomerAccomplishmentComponent } from './Media/customer-accomplishment/customer-accomplishment.component';
import { AccomplishmentmediaCustomerComponent } from './Media/accomplishmentmedia-customer/accomplishmentmedia-customer.component';
import { CustomerInfoReportComponent } from './Reports/customer-info-report/customer-info-report.component';
import { DonationsStatusReportComponent } from './Reports/donations-status-report/donations-status-report.component';
import { ReceiptBookReportComponent } from './Reports/receipt-book-report/receipt-book-report.component';
import { ListOfficesComponent } from './Offices/list-offices/list-offices.component';
import { InsertOfficeComponent } from './Offices/insert-office/insert-office.component';
import { CommissionSettingComponent } from './commission-setting/commission-setting.component';
import { EmployeesCommissionReportComponent } from './Reports/employees-commission-report/employees-commission-report/employees-commission-report.component';
import { ChangeDonationCreatorComponent } from './change-donation-creator/change-donation-creator.component';
import { followUpCallReportComponent } from './Reports/follow-up-call-report/follow-up-call-report.component';
import { CallReasonsCallsReportComponent } from './Reports/call-reasons-calls-report/call-reasons-calls-report.component';
import { CustomerServicesCallsComponent } from './Reports/customer-services-calls/customer-services-calls.component';
import { ProjectFullStateReportComponent } from './Reports/project-full-state-report/project-full-state-report.component';
const routes: Routes = [
  {
    path: "",
    children: [
      {
        canActivate: [AuthGuardGuard],
        path: "stats",
        component: StatsComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "statistics/agents",
        component: AgentListStatComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "statistics/CallReason",
        component: CallReasonStatComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "client-agent-report",
        component: ClientAgentComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "client-report",
        component: ClientsComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "receipt-report",
        component: ReceiptReportComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "sourcemarket-customers-report",
        component: SourceMarketCustomersComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "companies-report",
        component: CompaniesReportComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "CollectorDonations-reports",
        component: CollectorDonationsComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "sourceOfMarketing-report",
        component: SourceofmarketingComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "search-reports",
        component: SearchReportsComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "donation-requests",
        component: DonationRequestsComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "customer-info-report",
        component: CustomerInfoReportComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "collector-batches",
        component: CollectorBatchesComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "small-donations-report",
        component: SmallDonationReportsComponent,
      },

      {
        canActivate: [AuthGuardGuard],
        path: "client-call-report",
        component: ClientCallComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "call-start-end-report",
        component: CallStartEndReportComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "client-call-detail",
        component: ClientCallDetailsComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "collector-small-donation-report",
        component: CollectorSmallDonationComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "donation-report",
        component: DonationsReportComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "sourcemarket-donation-report",
        component: SourcemarketDonationsReportComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "project-donation-report",
        component: ProjectDonationsReportComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "call-customer-date-report",
        component: GetCallCustomerDateComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "customer-services-calls-report",
        component: CustomerServicesCallsComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "project-full-state-report",
        component: ProjectFullStateReportComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "all-batches-report",
        component: AllBatchesComponent,
      },
      {
       canActivate: [AuthGuardGuard],
        path: "donations-status-report",
        component: DonationsStatusReportComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "receipt-book-report",
        component: ReceiptBookReportComponent,
      },
    {
       canActivate: [AuthGuardGuard],
        path: "employees-commission-report",
        component: EmployeesCommissionReportComponent,
    },
    {
       canActivate: [AuthGuardGuard],
       path: "follow-up-call-report",
       component: followUpCallReportComponent,
   },
   {
    canActivate: [AuthGuardGuard],
    path: "call-reasons-calls-report",
    component: CallReasonsCallsReportComponent,
},
      {
        canActivate: [AuthGuardGuard],
        path: "DefaultComponent",
        component: DefaultComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "client-type",
        component: ClientTypeComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "Get-client-type",
        component: ListClientTypeComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "update-client-type/:id",
        component: ClientTypeComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "insert-governorate",
        component: GovernorateComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "update-governorate/:id",
        component: GovernorateComponent,

      },
      {
        canActivate: [AuthGuardGuard],
        path: "Get-governorate",
        component: ListGovernorateComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "Get-cities",
        component: ListCitiesComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "insert-city",
        component: InsertCitiesComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "update-city/:id",
        component: InsertCitiesComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "insert-call-reason",
        component: InsertCallReasonComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "update-call-reason/:id",
        component: InsertCallReasonComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "Get-Call-Reason",
        component: CallReasonComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "InsertSourceMarket",
        component: InsertSourceMarketComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "updateSourceMarket/:id",
        component: InsertSourceMarketComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "GetSourceMarket",
        component: SourceMarketComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "GetClient",
        component: ClientComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "InsertClient",
        component: InsertClientComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "updateClient",
        component: InsertClientComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "GetEmployee",
        component: EmployeeComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "InsertEmployee",
        component: InsertEmployeeComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "updateEmployee/:id",
        component: InsertEmployeeComponent,
      },
      // jobs

      {
        canActivate: [AuthGuardGuard],
        path: "InsertJobs",
        component: InsertJobsComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "ListJobs",
        component: ListJobsComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "updateJobs/:id",
        component: InsertJobsComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "GetCustomerService",
        component: CustomerServiceComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "InsertCustomerService",
        component: InsertCustomerServiceComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "updateCustomerService/:id",
        component: InsertCustomerServiceComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "InsertSector",
        component: InsertSectorComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "ListSector",
        component: ListSectorComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "updateSector/:id",
        component: InsertSectorComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "Get-sector-type",
        component: ListSectorTypeComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "Insert-Sector-type",
        component: InsertSectorTypeComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "Update-Sector-type/:id",
        component: InsertSectorTypeComponent,
      },
      // DonationMainType

      {
        canActivate: [AuthGuardGuard],
        path: 'Get-sector-Location', component: ListSectorLocationComponent
      },
      {
        canActivate: [AuthGuardGuard],
        path: 'Insert-Sector-Location', component: InsertSectorLocationComponent
      },
      {
        canActivate: [AuthGuardGuard],
        path: 'Update-Sector-Location/:id', component: InsertSectorLocationComponent
      },
      ////Insert-Collector-City  Get-Collector-City
      {
        canActivate: [AuthGuardGuard],
        path: 'Get-Collector-City', component: ListCollectorCityComponent
      },
      {
        canActivate: [AuthGuardGuard],
        path: 'Insert-Collector-City', component: InsertCollectorCityComponent
      },
      {
        canActivate: [AuthGuardGuard],
        path: 'Update-Collector-City/:id', component: InsertCollectorCityComponent
      },

      {
        canActivate: [AuthGuardGuard],
        path: "Inser-Donation-Reason/:id",
        component: InsertDonationMTypeComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "Get-Donation-Reason",
        component: DonationMainTypeComponentComponent,
      },

      // DonationType
      {
        canActivate: [AuthGuardGuard],
        path: "CreateDonationType",
        component: InsertDonationTypeComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "updateDonationType",
        component: InsertDonationTypeComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "ViewDonationType",
        component: ViewDonationTypeComponent,
      },
      // DonationCategoryComponent


      {
        canActivate: [AuthGuardGuard],
        path: "CreateDonationCategory",
        component: InsertDonationCategoryComponent,
      },

      {
        canActivate: [AuthGuardGuard],
        path: "updateDonationCategory",
        component: InsertDonationCategoryComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "ViewDonationCategory",
        component: DonationCategoryComponent,
      },

      // SmsTemplateComponent
      {
        canActivate: [AuthGuardGuard],
        path: "SMSTemplate",
        component: SmsTemplateComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "addSMSTemplate",
        component: AddsmsTemplateComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "bulksms",
        component: BulkSMSComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "project-manager",
        component: MyProjectsComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "all-projects",
        component: AllProjectsComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "Permissions",
        component: AllPermissionComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "AddPermissions",
        component: AddPermissionComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "updatePermissions/:id",
        component: AddPermissionComponent,

      },
      {
        canActivate: [AuthGuardGuard],
        path: "statsdetails",
        component: StatdetailsComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "customerstats",
        component: CustomerStatsComponent,
      },
      
      {
        canActivate: [AuthGuardGuard],
        path: "followdonations",
        component: FollowDonationsComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "insert-receipt-book",
        component: InsertReceiptBookComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "update-receipt-book/:id",
        component: InsertReceiptBookComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "receipts-book",
        component: ListReceiptBookComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "collector-receipts",
        component: CollectorReceiptsComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "edit-collector-receipts/:id",
        component: CollectorReceiptsEditComponent,
      },

      {
        canActivate: [AuthGuardGuard],
        path: "change-employeepassword",
        component: ChangeEmployeePasswordComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "media-types",
        component: MediaTypeComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "insert-media-types",
        component: InsertMediaTypeComponent,
      },

      {
        canActivate: [AuthGuardGuard],
        path: "list-project-accomplishment/:id",
        component: ListProjectAccomplishmentComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "insert-project-media/:sectorlocationid",
        component: InsertProjectMediaComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "update-media-types/:id",
        component: InsertMediaTypeComponent,
      },

      {
        canActivate: [AuthGuardGuard],
        path: "update-project-media/:id/:sectorlocationid",
        component: InsertProjectMediaComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "customer-accomplishment",
        component: CustomerAccomplishmentComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "customer-accomplishment/:id",
        component: CustomerAccomplishmentComponent,
      },
      {
         canActivate: [AuthGuardGuard],
        path: "accomplishmentmedia-customer/:id",
        component: AccomplishmentmediaCustomerComponent,
      },
      {
       canActivate: [AuthGuardGuard],
        path: "GetOffices",
        component: ListOfficesComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "InsertOffice",
        component: InsertOfficeComponent,
      },
      {
        canActivate: [AuthGuardGuard],
        path: "update-office/:id",
        component: InsertOfficeComponent,
      },
      {
         canActivate: [AuthGuardGuard],
         path: "commission-setting",
         component: CommissionSettingComponent,
       },
       {
        canActivate: [AuthGuardGuard],
        path: "change-donation-creator",
        component: ChangeDonationCreatorComponent,
      },
   
      
       
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],

})
export class AdminRoutingModule {
  constructor() {
    const a = routes[0].children.filter(x => x.path == "stats");
    a[0]["data"] = { roles: ["admin"] }
  }
}
