// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
 
  // Server_URL:'https://localhost:7296/api',
 
};

 export const Server_URL='https://systemapi.azurewebsites.net/api'
// Server_URL_Image:'https://systemapi.azurewebsites.net/',
//export const Server_URL='https://systemapi.azurewebsites.net/api'
//export const Server_URL='https://systemapi.azurewebsites.net/api'

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
