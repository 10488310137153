import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CallReasonApiService } from 'src/app/shared/API-Service/call-reason-api.service';
import { SourceMarketApiService } from 'src/app/shared/API-Service/source-market-api.service';
import { InsertCallReason } from 'src/app/shared/Models/insert-call-reason';
import { InsertSourceMarket, UpdateSourceMarket } from 'src/app/shared/Models/InsertSourceMarket';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-insert-source-market',
  templateUrl: './insert-source-market.component.html',
  styleUrls: ['./insert-source-market.component.css']
})
export class InsertSourceMarketComponent implements OnInit {

  //#region Decalre varaibles
  InsertForm: FormGroup;
  maxDate: Date;
  update:boolean;
  validated:boolean;
  //callId:number;
  //#endregion

  //#region  constructor
  constructor(private _formBuilder: FormBuilder,
              private toaster: ToastrService,
              private ApiService:SourceMarketApiService,
              private router:Router,
              private route: ActivatedRoute) 
  { 
    this.maxDate = new Date();
  }
  //#endregion
  containsNumber(str) {
    const specialChars = /[/\d/٠١٢٣٤٥٦٧٨٩]/;
    return specialChars.test(str);
  }
  //#endregion
  containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }
  //#region  ng OnInit
  ngOnInit(): void {
   
    if(this.route.snapshot.paramMap.get('id')){
      // MARKETTitle
      // MARKETOrder
      this.ApiService.title = localStorage.getItem("MARKETTitle");
      this.ApiService.order = +localStorage.getItem("MARKETOrder");
      //this.ApiService.CallId=+localStorage.getItem("CallID")
      this.InitForm(this.ApiService.title , this.ApiService.order)
      this.update = true;
    }else
    {
      this.update = false;
      
      this._InitForm();
    }
  }
  //#endregion

  //#region  Init Form
  InitForm(title:string,order:number){
    this.InsertForm = this._formBuilder.group({
      Title: [title, Validators.required],
      Order: [order, Validators.required],
   
    });
  }
  _InitForm(){
    
    this.InsertForm = this._formBuilder.group({
      Title: ['', Validators.required],
      Order: ['', Validators.required],
     
    });
    this.Get()
  }
  //#endregion

  //#region  Insert Call Reason Method
  Insert(){        
    this.ApiService.Insert({title:this.InsertForm.get('Title').value , order:this.InsertForm.get('Order').value} as InsertSourceMarket  ).subscribe(
      response=>{
        Swal.fire({
          icon: 'success',
          title: "تم إضافة مصدر  بنجاح",
          showConfirmButton: false,
          timer: 1500
        })
        this.router.navigateByUrl("content/admin/GetSourceMarket");
      },
      err=>{
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }
  //#endregion

  //#region Update Call Reason
  Update(){
    let Id = +this.route.snapshot.paramMap.get('id');
    this.ApiService.Update({id:Id,title:this.InsertForm.get('Title').value,order:this.InsertForm.get('Order').value} as UpdateSourceMarket).subscribe(
      response=>{
        Swal.fire({
          icon: 'success',
          title: "تم تعديل المصدر بنجاح",
          showConfirmButton: false,
          timer: 1500
        })
        this.router.navigateByUrl("content/admin/GetSourceMarket");
        localStorage.removeItem("MARKETTitle");
        localStorage.removeItem("MARKETOrder");

      },
      err=>{
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }
  //#endregion

    //#region  Get Call Reason
    Get() {
      this.ApiService.Get().subscribe(
        response => {
         this.InsertForm.patchValue({Order:response.data.length+1});
        },
        err => {
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: err.error.message,
          })
        }
      )
    }
    //#endregion


    onSubmit() {
      // this.validated = !this.validated
  
      // this.validated = !this.validated
  // if(this.containsSpecialChars(this.InsertForm.get("Title").value)==true){
  //   Swal.fire({
  //     icon: 'error',
  //     title: 'خطأ',
  //     text: "لا يمكن كتابه علامات ",
  //   })
  // }
  // else if (this.containsNumber(this.InsertForm.get("Title").value)==true){
  //   Swal.fire({
  //     icon: 'error',
  //     title: 'خطأ',
  //     text: "لا يمكن كتابه ارقام ",
  //   })
  // }
    // else 
     if (this.InsertForm.status == "VALID") {
        this.validated = false;
        if (this.update == true) {
          this.Update();
        } else {
          this.Insert();
        }
      } else {
        this.validated = true;
      }
    }
  

}
