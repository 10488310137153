import { Router } from '@angular/router';
import { HttpDonationTypeRespons } from '../../../../shared//Models/http-donation-type-select';
import { DonationTypeApiService } from './../../../../shared/API-Service/donation-type-api.service';
import { Component, OnInit } from '@angular/core';
import Swal from "sweetalert2";
import { BaseComponent } from 'src/app/shared/components/base/base.component';



@Component({
  selector: 'app-view-donation-type',
  templateUrl: './view-donation-type.component.html',
  styleUrls: ['./view-donation-type.component.css']
})
export class ViewDonationTypeComponent extends BaseComponent implements OnInit {
  response:HttpDonationTypeRespons[]
  constructor(private  _DonationTypeApiService :DonationTypeApiService,private _router:Router) {super() }

  ngOnInit(): void {
    this.getData()
  }
  pageChanged(event:any){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    var res;
    this._DonationTypeApiService.GetDonationTypeList(this.pager).subscribe({
      next: (respnose) => {
        res = respnose;
        this.response = res.data.items;
        this.totalCount=res.data.totalCount
        
      },
    });
   
  }
  getData() {
    var res;
    
    this._DonationTypeApiService.GetDonationTypeList(this.pager).subscribe({
      next: (respnose) => {
        res = respnose;
        this.response = res.data.items;
        this.totalCount=res.data.totalCount
        
      },
    });
  }
  track(index, item) {
    return item.id;
  }
  
  Delete(id: number) {
    Swal.fire({
      title: " تحذير !",
      text: "هل انت متأكد من حذف هذا العنصر ؟ ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#7364f6',
      cancelButtonColor: "#d33",
      confirmButtonText: "حذف",
      cancelButtonText: "رجوع",
    }).then((result) => {
      if (result.isConfirmed) {
        this._DonationTypeApiService.Delete(id).subscribe(
          (res) => {
            this.getData();
            Swal.fire({
              icon: "success",
              title: "تم حذف الموظف بنجاح",
              showConfirmButton: false,
              timer: 1500,
            });
          },
          (err) => {
            Swal.fire({
              icon: "error",
              title: "خطأ",
              text: err.error,
            });
          }
        );
      } else {
        // Swal.fire(
        //   'Your appointment still active ',
        // );
      }
    });

}
Update(data) {
  this._router.navigate([`/content/admin/updateDonationType`]);
  this._DonationTypeApiService.Data.next(data);
}

}
