import { Component, OnInit } from '@angular/core';
import { ReceiptBook } from '../../../../shared//Models/ReceiptBook';
import { ReceiptBookService } from '../../../../shared/API-Service/receipt-book.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DonationMTypeApiService } from 'src/app/shared/API-Service/donation-m-type-api.service';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';

@Component({
  selector: 'app-insert-receipt-book',
  templateUrl: './insert-receipt-book.component.html',
  styleUrls: ['./insert-receipt-book.component.css']
})
export class InsertReceiptBookComponent implements OnInit {
  ReceiptBook = new ReceiptBook()
  update: boolean;
  insertForm: FormGroup;
  ReceiptPicForm: FormData;
  Receipt: any;
  id: string;
  DonationMTypes: any[]=[];
  Companies: any[];
  constructor(private CompanyDataService:CompanyDataService,private DonationMTypeApiService:DonationMTypeApiService,private _formBuilder: FormBuilder,private ReceiptBookService :ReceiptBookService,private router: Router,private route: ActivatedRoute) {
    this.GetDonationMType();
    this.GetCompanies();
  }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id")) {
      this.id =this.route.snapshot.paramMap.get("id")
    this.ReceiptBookService.GetReceiptBookByid(this.id).subscribe(async (res:any)=>{
    this.Receipt=  await res.data;
      this.initForm(this.Receipt);

    })
      this.update = true;
    } else {
      this.initForm()
      this.update = false;
    }
  }
  SelectCompany(CompanyId){
    // this.getSector(CompanyId);
  }
  GetCompanies(){
    this.CompanyDataService.GetCompanies().subscribe(res=>{
      this.Companies=res.data
    })
  }
  GetDonationMType(){
    this.DonationMTypeApiService.get().subscribe((res:any)=>{
      this.DonationMTypes=res.data
      this.DonationMTypes=this.DonationMTypes.filter(x=>x.id!=3)
    })
  }
  get fc() {
    return this.insertForm.controls;
  }
  initForm(ReceiptBook?: ReceiptBook) {
    this.insertForm = this._formBuilder.group({
      companyId: [ReceiptBook?.companyId || '', Validators.required],
      bookNumber: [ReceiptBook?.bookNumber || '', Validators.required],
      prefix: [ReceiptBook?.prefix || '', Validators.required],
      receiptFrom: [ReceiptBook?.receiptFrom || '', Validators.required],
      receiptTo: [ReceiptBook?.receiptTo || '', Validators.required],
      donationMainTypeId: [ReceiptBook?.donationMainTypeId||[] , Validators.required],
    });


  }
  // loopform() {
  //   this.ReceiptPicForm = new FormData();
  //   Object.keys(this.insertForm.value).forEach((key) => {
  //     if (typeof this.insertForm.value[key] != "object")
  //       this.ReceiptPicForm.append(key, this.insertForm.value[key]);
  //     else if (typeof this.insertForm.value[key] == "object")
  //       Object.keys(this.insertForm.value[key]).forEach((subkey) => {
  //         this.ReceiptPicForm.append(key, this.insertForm.value[key][subkey].id);
  //       });
  //   });
  //   this.ReceiptPicForm.append('imagePath', this.file);
  //   this.ReceiptPicForm.append('Visable',JSON.stringify(this.Visable))
  //   this.ReceiptPicForm.append('Inactive',JSON.stringify(this.inactive))
  // }
  Submit(){
    if (this.insertForm.status == "VALID") {
      if (this.update == true) {
        this.UpdateReceiptBook();
      } else {
        this.InsertReceiptBook();
      }
    }
     else {
      this.insertForm.markAllAsTouched();
    }
  

  }
  UpdateReceiptBook(){
    const prefix = this.insertForm.get("prefix").value.trim();
    const data ={
      "id":this.id,
      "bookNumber": this.insertForm.get("bookNumber").value,
      "prefix": prefix,
      "receiptFrom":String(this.insertForm.get("receiptFrom").value),
      "receiptTo":String(this.insertForm.get("receiptTo").value),
      "donationMainTypeId": this.insertForm.get("donationMainTypeId").value
    }
    this.ReceiptBookService.UpdateReceiptBook(data).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم التعديل بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
       this.ReceiptBook.bookNumber="";
       this.ReceiptBook.prefix="";
       this.ReceiptBook.receiptFrom="";
       this.ReceiptBook.receiptTo="";
       this.router.navigateByUrl("content/admin/receipts-book");
    },
      (err) => {
        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
      
      // this.callClient =[];
      );
  }
  InsertReceiptBook(){
    const prefix = this.insertForm.get("prefix").value.trim();
    const data ={
      "companyId":this.insertForm.get("companyId").value,
      "bookNumber": this.insertForm.get("bookNumber").value,
      "prefix": prefix,
      "receiptFrom":String(this.insertForm.get("receiptFrom").value),
      "receiptTo":String(this.insertForm.get("receiptTo").value),
      "donationMainTypeId": this.insertForm.get("donationMainTypeId").value

    }
    this.ReceiptBookService.InsertReceiptBook(data).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم الحفظ بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/receipts-book");
       this.ReceiptBook.bookNumber="";
       this.ReceiptBook.prefix="";
       this.ReceiptBook.receiptFrom="";
       this.ReceiptBook.receiptTo=""
    },
      (err) => {
        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
            );
  }

  
}
