import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import Swal from 'sweetalert2';
import { ReceiptBookService } from '../../../../shared/API-Service/receipt-book.service';
import { environment } from 'src/environments/environment.prod';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';

@Component({
  selector: 'app-list-receipt-book',
  templateUrl: './list-receipt-book.component.html',
  styleUrls: ['./list-receipt-book.component.css']
})
export class ListReceiptBookComponent extends BaseComponent implements  OnInit {
  Receipts: any;
  update: boolean;
  imgUrlServer=environment.Server_URL_Image+'/'
  Companies: any[];
  CompanyId: any;

  constructor(private CompanyDataService:CompanyDataService, private ReceiptBookService :ReceiptBookService,private router:Router) {
    super()
   }
   SelectCompany(CompanyId){
    this.CompanyId=CompanyId
  }
  GetCompanies(){
    this.CompanyDataService.GetCompanies().subscribe(res=>{
      this.Companies=res.data
    })
  }
  ngOnInit(): void {
  this.GetCompanies();
    this.GetReceipts();
  }
  pageChanged(event:any){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.ReceiptBookService.GetReceiptBookList(this.pager).subscribe(res=>{
      this.Receipts=res.data;
      this.totalCount = this.Receipts.totalCount;
      this.Receipts=this.Receipts.items
    })
  
  }
  Search(){
this.GetReceipts()
  }
GetReceipts(){
  this.pager.companyId=this.CompanyId
  this.ReceiptBookService.GetReceiptBookList(this.pager).subscribe((res:any)=>{
    this.Receipts=res.data;
    this.totalCount = this.Receipts.totalCount;
    this.Receipts=this.Receipts.items
  })
}
Update(id){
  this.router.navigate([`content/admin/update-receipt-book`, id]);
}
Delete(id: number) {
  Swal.fire({
    title: ' تحذير !',
    text: "هل انت متأكد من حذف هذا الدفتر ؟ ",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#7364f6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'حذف',
    cancelButtonText: 'إنهاء',
  })
    .then((result) => {

      if (result.isConfirmed) {

        this.ReceiptBookService.DeleteReceiptBook(id).subscribe(
          response => {
            this.GetReceipts();
            Swal.fire({
              icon: 'success',
              title: "تم حذف الدفتر  بنجاح",
              showConfirmButton: false,
              timer: 1500
            })
          },
      (err) => {
        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
        )

      } else {

      }
    });
}
AddNew(){
  this.router.navigateByUrl("content/admin/insert-receipt-book");
}
}
