<app-breadcrumb [title]="'بيانات العميل'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'">
</app-breadcrumb>
<div class="container-fluid">
  <div class="card">
    <div class="card-body">
      <div class="row">
            <!-- <form action="" [formGroup]="createReports"> -->
              <div class="col-4 col-md-4">
                <label for="validationCustom02"><span class="validation-required">*</span>  اسم الشركة</label>
                <br>
                <label *ngIf="currentUser.roleId==10">{{currentUser.name}}</label>
            <ng-select *ngIf="currentUser.roleId!=10"
            class="js-example-basic-single col-sm-12 job-select2 form-group"
            required=""
            [(ngModel)]="CustomerInfo.companyId"
            aria-label="Default select example"
            (change)="SelectCompany($event.id)"
            [items]="Companies"
            bindLabel="name"
            bindValue="id"
             >
            
            </ng-select>
              </div>
          <div class="col-4 col-md-4">
              <div class="form-group">
                  <label for="">  تاريخ التحصيل الفعلى من  </label>
                 <input type="date"  class="form-control" [(ngModel)]="CustomerInfo.fromDate">
              </div>
          </div>
          <div class="col-4 col-md-4">
              <div class="form-group">
      
                  <label for="">   تاريخ التحصيل الفعلى الى </label>
      
                
                  <input type="date"  class="form-control"  [(ngModel)]="CustomerInfo.toDate">
              </div>
         </div>  
         <div class="col-4 col-md-4">
                  
                       
                            <div class="form-group">
                                <label>أختر المحافظة </label>
                                   
                                <ng-select
                                class="js-example-basic-single"
                                  aria-label="Default select example"
                                 
                                  [(ngModel)]="CustomerInfo.governorateId"
                                  (change)="SelectedGovernorate($event?.id)"
                                  [items]="Governorate_List"
                                  bindLabel="title"
                                  bindValue="id"
                                >
                                </ng-select>
                            
                            
                                
                           
                        </div>
                        </div>
                        <div class="col-4 col-md-4">
                            <div class="form-group">
                                <label>أختر مدينه </label>
                                   
                                <ng-select
                                class="js-example-basic-single"
                                  aria-label="Default select example"
                                  
                                  [(ngModel)]="CustomerInfo.cityId"
                                  (change)="SelectedCity($event.id)"
                                  [items]="City_list"
                                  bindLabel="title"
                                  bindValue="id"
                                >
                                </ng-select>
                              </div>
                            
                                
                           
                        </div>
                        <div class="col-4 col-md-4">
                          <label for="" >العملاء المميزون</label>
                          <br>
                          <label class="switch">
                            <input type="checkbox"  [(ngModel)]="CustomerInfo.vip" />
                            <span class="slider round"></span>
                          </label>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end align-items-center buttom-text">
                      <button class="addnew-btn" type="submit" (click)="Viewreports()">عرض التقرير</button>
                    </div>
                </div>
            <!-- </form> -->
    </div>
</div>
    

