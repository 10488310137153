import { Component, OnInit } from '@angular/core';
import {  Router } from '@angular/router';
import { StatisticsService } from 'src/app/shared/API-Service/statistics.service';
@Component({
  selector: 'app-followup-call-stats',
  templateUrl: './followup-call-stats.component.html',
  styleUrls: ['./followup-call-stats.component.css']
})
export class FollowupCallStatsComponent implements OnInit {

  counts: any;

  constructor(private StatisticsService:StatisticsService,private router:Router) { }
  ngOnInit(): void {
    this.GetCollectorDonationsCounts();
  }
  goToDetails(type){
    this.router.navigateByUrl("content/agent/follow-up-call-statsdetails", {state:type});
  }
  GetCollectorDonationsCounts(){
    this.StatisticsService.GetTodayFollowUpCallsCount().subscribe((res:any)=>{
      
      this.counts=res.data
    })
  }

}
