import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom, filter, pairwise } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
styleUrls: [
    // "../../node_modules/jquery-ui/themes/base/all.css",
  
    // "../../node_modules/devextreme/dist/css/dx.light.css",
    // "../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css",
    // "../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.light.css",
    // "../../node_modules/devexpress-reporting/dist/css/dx-webdocumentviewer.css"
]
})
export class AppComponent {
  previousPage: any;

  // For Progressbar
  // loaders = this.loader.progress$.pipe(
  //   delay(1000),
  //   withLatestFrom(this.loader.progress$),
  //   map(v => v[1]),
  // );
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService, translate: TranslateService ,public router: Router) {
      this.router.events
.pipe(filter((evt: any) => evt instanceof NavigationEnd), pairwise())
.subscribe((events: NavigationEnd[]) => {
  this.previousPage= events[0].urlAfterRedirects
});
    // if (isPlatformBrowser(this.platformId)) {
    //   translate.setDefaultLang('en');
    //   translate.addLangs(['en', 'de', 'es', 'fr', 'pt', 'cn', 'ae']);
    // }
     document.getElementsByTagName('html')[0].setAttribute('dir', "rtl")
  }

}
