<app-breadcrumb
  [title]="'تبرعات من خلال خدمة العملاء'"
  [items]="['Tables', 'Bootstrap Tables']"
  [active_item]="'Basic'"
>
</app-breadcrumb>

<div class="container-fluid">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-4">
          <label for="validationCustom02"><span class="validation-required">*</span>  اسم الشركة</label>
          <br>
          <label *ngIf="currentUser.roleId==10">{{currentUser.name}}</label>
    <ng-select *ngIf="currentUser.roleId!=10"
    class="js-example-basic-single col-sm-12 job-select2 form-group"
      required=""
      [(ngModel)]="searchreports.companyId"
      aria-label="Default select example"
      (change)="SelectCompany($event.id)"
      [items]="Companies"
      bindLabel="name"
      bindValue="id"
       >
    
    </ng-select>
        </div>
    
    <div class="col-12 col-md-4">
        <div class="" style="display: flex; justify-content: start;"><label for=""> تاريخ انشاء التبرع من</label></div>
          <div class="form-group">
              
             <input type="date"  class="form-control" [(ngModel)]="searchreports.creationDateFrom">
          </div>
    </div>
    <div class="col-12 col-md-4">
        <div class="" style="display: flex; justify-content: start;"><label for=""> الى تاريخ انشاء التبرع</label></div>
          <div class="form-group">
              
             <input type="date"  class="form-control"  [(ngModel)]="searchreports.creationDateTo">
          </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-group">

        <label for=""> التبرعات المحصله من تاريخ </label>
         <input type="date"  class="form-control"  [(ngModel)]="searchreports.collectionDateFrom">



      </div>
  </div>
  
  <div class="col-12 col-md-6">
      <div class="form-group">
        
        <label for="">  التبرعات  المحصله  إلي  تاريخ </label>
         <input type="date"  class="form-control" [(ngModel)]="searchreports.collectionDateTo">

       

      </div>
  </div>   
    <div class="col-12 col-md-6">
      <div class="" style="display: flex; justify-content: start;"><label for="">اسم الموظف</label></div>
      
      <ng-select  class="js-example-basic-single" (change)="createdEmplyee($event.id)" [(ngModel)]="searchreports.employeeId"
      
      [items]="getEmployee"
      bindLabel="name"
      bindValue="id"
      >
      </ng-select>
  </div>
    <div class="col-12 col-md-6">
        
        
        <!-- <label for="">نوع التبرع</label> -->
        <div class="" style="display: flex; justify-content: start;"><label for=""> فئه التبرع  </label></div>
              <ng-select
              class="js-example-basic-single"
              aria-label="Default select example"
              [(ngModel)]="searchreports.donationTypeId"
              (change)="donationTypee($event.id)"
              [items]="DonationType"
              bindLabel="title"
              bindValue="id"
            >
            </ng-select>
   
         
    </div>

    <div class="col-12 col-md-4">
        <!-- <label for="">قطاع التبرع</label> -->
        <div class="" style="display: flex; justify-content: start;"><label for="">  النشاط </label></div>
          <ng-select
          class="js-example-basic-single"
          aria-label="Default select example"
          (change)="GetSectorGetById($event.id)" 
          [(ngModel)]="searchreports.sectorId"
          [items]="SectorGet"
          bindLabel="title"
          bindValue="id"
          >
        </ng-select>
      </div>
      
      <div class="col-12 col-md-4 ">
        <!-- <label for="">نوع القطاع</label> -->
        <div class="" style="display: flex; justify-content: start;"><label for="">المشروعات </label></div>
          <ng-select
          class="js-example-basic-single"
          aria-label="Default select example"
          [(ngModel)]="searchreports.sectorTypeId"
          (change)="GetSectorTypeGetById($event.id)" 
          [items]="getType"
          bindLabel="title"
          bindValue="id"
        
          >
        </ng-select>
      </div>

      <div class="col-12 col-md-4  ">
        <!-- <label for="">موقع القطاع</label> -->
        <div class="" style="display: flex; justify-content: start;"><label for=""> اماكن المشروعات</label></div>

          <ng-select
          class="js-example-basic-single"
          aria-label="Default select example"
          [(ngModel)]="searchreports.sectorLocationId"
           (change)="GetSectorLocationGetById($event.id)"
           [items]="SectorLocation"
           bindLabel="title"
           bindValue="id"
          >
        </ng-select>
      </div>
      <div class="d-flex justify-content-end align-items-center col-12 col-md-4" >

        <button class="addnew-btn" style="margin-top: 41px; margin-left: 6rem;" type="submit" (click)="Viewreports()">عرض التقرير</button>
      </div>

  </div>
    </div>
  </div>
  
</div>

