 



<app-breadcrumb [title]="'إدارة المدن'"  [active_item]="'Form Wizard'"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding-bottom:5px !important ;">
            <div *ngIf="update; else elseBlock1">
              <h5 class="pb-2">تعديل مدينة موجودة</h5>
              يمكنك تعديل مدينة أو أكثر فى قائمة المدن 
            </div>
          <ng-template #elseBlock1>
            <h5 class="pb-2">إضافة مدينة جديد</h5>
              يمكنك إضافة مدينة أو أكثر فى قائمة المدن 
            </ng-template>

        </div>
        <div class="card-body">
            <!-- <form [formGroup]="InsertForm"> -->
          <form class="needs-validation" [formGroup]="InsertForm" (ngSubmit)="onSubmit()" novalidate [ngClass]="{'was-validated': validated}">

          

            <div class="form-row">
                      
              <div class="col-12 mb-3">
                  <label for="validationCustom01"> <span class="validation-required">*</span> 
                      المدينة</label>
                  <input class="form-control" id="validationCustom01" type="text" placeholder="اختر المدينة" required=""
                    formControlName="Title" >
                    
                  
                </div>

          
                      
                  <div class="col-12 mb-3">
                    <label for="validationCustom02"><span class="validation-required">*</span>  المحافظة</label>
                    <ng-select class="js-example-basic-single"
                            formControlName="GovernorateId"
                            aria-label="Default select example"
                            (change)="SelectedGovernorate($event.id)"
                            required=""
                            id="validationCustom02" 
                            [items]="Governorate_List"
                            bindLabel="title"
                            bindValue="id"
                            >
                    </ng-select>
            
                </div>
                
                </div>
                       
                <div *ngIf="update; else elseBlock">
                    <button  class="btn btn-primary pull-right" 
                    type="submit"
                 
                >
                    تعديل
                 </button>
                </div>
                <ng-template #elseBlock>
                    <button  class="btn btn-primary pull-right" 
                    type="submit"
                    >
                    إضافة
                 </button>
                </ng-template>
               
              </form>
        </div>
      </div>
    </div>
  </div>
</div>
