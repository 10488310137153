<app-breadcrumb [title]="'إدارة اماكن المشروعات '"  [active_item]="'Form Wizard'"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding-bottom:5px !important ;">
            <div *ngIf="update; else elseBlock1">
              <h5 class="pb-2">تعديل مكان المشروع موجود</h5>
              يمكنك تعديل نوع المشروع أو أكثر فى قائمة انواع المشروعات
            </div>
          <ng-template #elseBlock1>
            <h5 class="pb-2">إضافة  مكان  مشروع جديد</h5>
              يمكنك إضافة مكان مشروع أو أكثر فى قائمة اماكن المشروعات  
            </ng-template>

        </div>
        <div class="card-body">
            <form [formGroup]="InsertForm">

                <div class="form-group">
                  <label for="name">أسم مكان المشروع</label>
                  <input class="form-control" id="name" type="text" placeholder="ادخل مكان المشروع" required="required"
                    formControlName="Title" >
                    
                  
                </div>
                <div class="form-group">
                  <label for="name">اسم الشركة</label>
                  <ng-select class="js-example-basic-single"
                          formControlName="CompanyId"
                          aria-label="Default select example"
                          (change)="SelectCompany($event.id)"
                          required="required"
                          [items]="Companies"
                          bindLabel="name"
                          bindValue="id"
                           >

                  </ng-select>
                </div>
                <div class="form-group">
                  <label for="name">أختر النشاط</label>
                  <ng-select class="js-example-basic-single" formControlName="SectorId"
                      aria-label="Default select example" (change)="SelectedSector($event.id)"
                      [items]="Sector_List"
                      bindLabel="title"
                      bindValue="id"
                      >
                  </ng-select>
                </div>
                <div class="form-group">
                  <label >أختر  نوع المشروع </label>
                    <ng-select class="js-example-basic-single"

                            formControlName="SectorTypeId"
                            aria-label="Default select example"
                            (change)="SelectedSectorType($event.id)"
                            required="required"
                            [items]="Sector_Type_List"
                            bindLabel="title"
                            bindValue="id"
                             >

                            <!-- <ng-option value=null > أختر  نوع المشروع </ng-option>
                            <ng-option *ngFor="let obj of Sector_Type_List"  [selected]="update&&obj.id==SectorLocationInfo.sectorTypeId" value="{{obj.id}}">
                                {{ obj.title }}
                            </ng-option> -->
                    </ng-select>
                </div>
                <div class="form-group">
                  <label for="" >غير نشط</label>
                  <br>
                  <label class="switch">
                            <input type="checkbox" id="inactive" (change)="inactiveChange()" [checked]="inactive == true"  />
                            <span class="slider round"></span>
                          </label>
                        
                </div>
                <div *ngIf="update; else elseBlock">
                    <button  class="btn btn-primary pull-right" 
                    type="submit"
                    [disabled]="!InsertForm.valid"
                    (click)="UpdateSectorLocation()">
                    تعديل
                 </button>
                </div>
                <ng-template #elseBlock>
                    <button  class="btn btn-primary pull-right" 
                    type="submit"
                    [disabled]="InsertForm.invalid"
                    (click)="InsertSectorLocation()">
                    إضافة
                 </button>
                </ng-template>
               
              </form>
        </div>
      </div>
    </div>
  </div>
</div>