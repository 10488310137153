import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericResponse } from '../Models/GenericResponse';
import { environment } from '../../../environments/environment.prod';
import { getAllCollectorEmployees } from '../Models/Get-All-Collector-Employees';
import { getCities } from '../Models/getCities';

import { GenericResponseSingle } from '../Models/GenericResponseSingle';
import{CitiesApiService} from '../API-Service/cities-api.service';
import{GetAllCollectorEmployeesApiService} from '../API-Service/get-all-collector-employees-api.service'
import { GetCollectorCity,GetCollectorsInCity, UpdateCity } from '../Models/Get-collector-city';
import{InsertCollectorCity,UpdateCollectorCity} from '../Models/InsertCollectorCity'
@Injectable({
  providedIn: 'root'
})
export class CollectorCityApiService {
  title:string;
  CollectorId:number;
  CityId:number[]=[];

  constructor(private http:HttpClient) { }
  // GetCollectorCity(): Observable<GenericResponse<GetCollectorCity>> {
  //   return this.http.get<GenericResponse<GetCollectorCity>>(`${environment.Server_URL}/CollectorCity/Get`);
  // }
  // getSectorLocation(sectorTypeid?:number): Observable<GenericResponse<GetSectorLocationInSectorTypes>> {
  //   if(sectorTypeid!=null)
  //   return this.http.get<GenericResponse<GetSectorLocationInSectorTypes>>(`${environment.Server_URL}/SectorLocation/GetSectorLocationInSectorTypes?id=${sectorTypeid}`);
  //   else
  //   return this.http.get<GenericResponse<GetSectorLocationInSectorTypes>>(`${environment.Server_URL}/SectorLocation/SectorLocation`);
    

  // }
  GetCollectorCity(CityId? :number): Observable<GenericResponse<GetCollectorCity>> {
    if(CityId!=null)
    return this.http.get<GenericResponse<GetCollectorCity>>(`${environment.Server_URL}/CollectorCity/GetAllCollectorsInCity?cityId=${CityId}`);
    else
       return this.http.get<GenericResponse<GetCollectorCity>>(`${environment.Server_URL}/Employee/GetAllCollectorEmployees`);

  }
  GetAllCollectorEmployeesList(data): Observable<GenericResponse<any>> {

       return this.http.post<GenericResponse<any>>(`${environment.Server_URL}/Employee/GetAllCollectorEmployeesList`,data);

  }
 
  GetAllCitiesCollector(Id:number): Observable<GenericResponse<UpdateCity>> {
    return this.http.get<GenericResponse<UpdateCity>>(`${environment.Server_URL}/CollectorCity/GetAllCitiesCollector?collectorId=${Id}`);

  }
  InsertCollectorCity(Data:InsertCollectorCity): Observable<GenericResponseSingle<GetCollectorCity>> {
    return this.http.post<GenericResponseSingle<GetCollectorCity>>(`${environment.Server_URL}/CollectorCity/Create`,Data);
  }

  UpdateCollectorCity(Data:UpdateCollectorCity): Observable<GenericResponseSingle<UpdateCollectorCity>> {
    return this.http.post<GenericResponseSingle<UpdateCollectorCity>>(`${environment.Server_URL}/CollectorCity/Create`,Data);
  }

  DeleteCollectorCity(Id:number): Observable<GenericResponseSingle<InsertCollectorCity>> {
    return this.http.get<GenericResponseSingle<InsertCollectorCity>>(`${environment.Server_URL}/CollectorCity/Delete?id=${Id}`);
    // change detete
  }
  DeleteCollector(Id:number): Observable<GenericResponseSingle<InsertCollectorCity>> {
    return this.http.get<GenericResponseSingle<InsertCollectorCity>>(`${environment.Server_URL}/Employee/Delete?id=${Id}`);
    // change detete
  }
}
