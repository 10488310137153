<app-breadcrumb [title]="' التبرعات الخاصه بالعمیل'" ></app-breadcrumb>

<div *ngIf=" LoadPage">
  <div class="container-fluid top-padding" ></div>
    <div class="card">
      <div class="card-body">
        <div class="row" >
          <div class="col-lg-3 col-sm-12 ">
            <div class="form-group">
              <label for="">بحث برقم الموبايل</label>
              <input type="text"  class="form-control" (keyup.enter)="searchbutton()" [(ngModel)]="searchDonation.mobile"
                id="serial">
            </div>
          </div>
          <div class="col-lg-3 col-sm-12 ">
            <div class="form-group">
              <label for="">بحث برقم الايصال</label>
              <input type="text" onlyNumbers class="form-control text-end"  [disabled]="receiptNumDisable"  (keyup.enter)="searchbutton()" [(ngModel)]="searchDonation.receiptNum"
                id="serial"
                (ngModelChange)="receiptNumChange()"
                [style.border]="ReceiptRequierd ? (ReceiptRequierd ? '1px solid #dc3545 !important' : (!ReceiptRequierd  ? '1px solid #28a745' : '1px solid #ced4da')) : '1px solid #ced4da'">

            </div>
          </div>
          <div class="receiptDash">
            <span>-</span>
          </div>
          <div class="col-lg-3 col-sm-12 ">
            <div class="form-group">
              <label for="" style="margin: 5px;">رقم الدفتر</label>
              <ng-select
              class="js-example-basic-single"
              aria-label="Default select example"
            [(ngModel)]="receiptBookId"
            [items]="GetReceiptBooks"
            bindLabel="bookNumber"
            bindValue="id"
              
            
            (change)="getReceiptBook($event)"
            >
  
            </ng-select>
            </div>
          </div>
          <div class="col-lg-1 col-sm-12" style="text-align: center; padding: 23px; padding-top: 29px;">
            <button id="search" style="    color: #fff;
          background: #7366ff;
          font-size: 19px;
          padding: 5px 26px;" (click)="searchbutton()">بحث</button>
          </div>
  
        </div>
  
      </div>
    </div>
  </div>
  
  
  
  
  
<div *ngIf="  this.LoadPage">
    
  <app-breadcrumb [title]="'البيانات   الخاصه بالعميل'+' ('+companyName+') '" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'">
  </app-breadcrumb>
  
  
  
  
  
  <!-- Container-fluid starts-->
  <div class="container-fluid">
    <div class="user-profile">
      <div class="row">
        <!-- user profile first-style start-->
  
        <div class="col-sm-12">
          <div class="card hovercard text-center">
            <div class="user-image">
              <div class="avatar">
                <!-- <img alt="Risk Logo" src="../../../../assets/Risk Logo 200 x 200/Risk-Logo-200-x-200.png"> -->
                <img alt="Risk Logo" [src]="companyImage">
                <!-- <img alt="Risk Logo" src="../../../../assets/Risk Logo 200 x 200/Risk-Logo-200-x-200-02.png"> -->
  
              </div>
              <!-- <div class="icon-wrapper"><i class="icofont icofont-pencil-alt-5"><input class="upload" type="file"
                     /></i></div> -->
            </div>
            <div class="info">
              <div class="row d-flex justify-content-center">
                <div class="col-lg-2 col-md-6">
                  <div class="ttl-info " style="width: max-content;">
                    <h6> المحافظه <i class="fa fa-home" aria-hidden="true"></i>
  
                    </h6>
                    <span>{{customerinfo?.governoratName}}</span>
  
                  </div>
                </div>
                <div class="col-lg-2 col-md-6">
                  <div class="ttl-info t" style="width: max-content;">
                    <h6>المدينه <i class="fa fa-home" aria-hidden="true"></i>
                    </h6>
                    <span>{{customerinfo?.cityName}}</span>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 ">
                  <div class="user-designation">
  
                    <i class="icofont icofont-business-man-alt-2 fa-3x mt-2"></i>
  
                    <div class="title ">
                      <span *ngIf="customerinfo?.vip"><i  class="fa fa-star Gold" aria-hidden="true"></i></span>

                      <span>
                        {{customerinfo?.name}}     
                      </span>

  
                    </div>
  
                    <div class="desc mt-2">
  
                      <!-- <h6 class="font-weight-bold fa-2x"><span class="pr-2">{{age}}</span>{{customerinfo?.name}}  </h6> -->
                    </div>
                  </div>
                </div>
                 <div ngbDropdown class="col-lg-2 col-md-6">
                  <div class="ttl-info number-div">
                    <h6> الرقم
                    <br> <br>{{customerinfo?.mobile}}    
                           <br>
                           <br>
                    <span class="contact-bttn">
                      <a [href]="'//wa.me/+2' + customerinfo?.mobile" *ngIf="customerinfo?.mobile" target="_blank" class="whatssapp-iconA"><i class="fa fa-whatsapp whatssapp-icon" aria-hidden="true"></i></a>
                    <!-- <a [href]="'tel:' + customerinfo?.mobile" *ngIf="customerinfo?.mobile"
                       class="phone-iconA"><i class="fa fa-phone phone-icon" ></i></a> -->
                       <a ngbDropdownToggle (click)="$event.stopPropagation()" *ngIf="customerinfo?.mobile"><i class="fa fa-phone" style="color: green;"></i></a>
                       <div ngbDropdownMenu aria-labelledby="dropdownConfig" >
                         <a  (click)="$event.stopPropagation()"[href]="'tel:' + customerinfo?.mobile" ngbDropdownItem>{{customerinfo?.mobile}}</a>
                             <ng-container  *ngFor="let mobile of customerinfo?.customerPhones">
                               <a (click)="$event.stopPropagation()" [href]="'tel:' + mobile" ngbDropdownItem>{{mobile}}</a>
                             </ng-container>
                       </div>
                    <i class="fa fa-comment" style="color: black; font-size: 20px; cursor: pointer;" *ngIf="customerinfo?.mobile" (click)="SendSMSTemplate()"></i>  
                 
                    </span> 
                     </h6>
                    
                    <br>
                 
                    <!-- <span *ngIf="customerinfo?.mobile">              
                        <a [href]="'//wa.me/' + customerinfo?.mobile" target="_blank"><i class="fa fa-whatsapp" style=" color: #2cc198;  font-size: 25px; " ></i></a>               
           
                        </span> -->
  
                  </div>
                </div>
                <div class="col-lg-2 col-md-6">
  
                </div>
               
  
  
              </div>
              <div class="row justify-content-center">
                <div class="col-xxl-12 ">
                  <div class="ttl-info " style="width: max-content;">
                    <h6> العنوان<i class="fa fa-address-card-o" aria-hidden="true"></i>
                    </h6>
                    <span class="responsive-font-example">{{customerinfo?.address}}</span>
  
                  </div>
                </div>
              </div>
            </div>
            <!-- <aw-wizard navBarLayout='large-filled-symbols'>
              <aw-wizard-step   [navigationSymbol]="{ symbol: '44' }">
                  <ng-template awWizardStepTitle>
                    <p>Step 1</p>
                  </ng-template>
              </aw-wizard-step>
              <aw-wizard-step [awCompletedStep]="condition" [navigationSymbol]="{ symbol: '2' }">
                  <ng-template awWizardStepTitle>
                    <p>Step 2</p>
                  </ng-template>
              </aw-wizard-step>
              <aw-wizard-step [awCompletedStep]="condition" [navigationSymbol]="{ symbol: '3' }">
                  <ng-template awWizardStepTitle>
                    <p>Step 3</p>
                  </ng-template>
              </aw-wizard-step>
              <aw-wizard-step [awCompletedStep]="condition" [navigationSymbol]="{ symbol: '4' }">
                  <ng-template awWizardStepTitle>
                    <p>Step 4</p>
                  </ng-template>
              </aw-wizard-step>
             
            </aw-wizard> -->
          </div>
  
          <!-- user profile first-style end-->
  
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  
  
    <div class="container-fluid">
      <div class="row m-0">
        <div class="col-12 col-md-9 text-left">
          <h5 class="py-2">بيانات التبرع </h5>
  
        </div>
      </div>
      <div class="row">
  
  
  
        <div class="col-sm-12">
  
  
  
          <div class="card">
            <div class="card-header px-0" style="padding-bottom: 5px;">
  
  
  
              <div class="col-xl-12 xl-100 col-lg-12">
                <div>
                  <div>
                    <!-- <ngb-tabset (tabChange)="GetDonationMainTypeId($event)"  [destroyOnHide]="false" type="pills" class=" w-100 mt-5 bg-primary"> -->

                      <ngb-tabset class="tabset" #tabset id="tabset" #ctdTabset="ngbTabset"
                        (tabChange)="GetDonationMainTypeId($event)">
    
                        <ngb-tab *ngFor="let tab of tabs; let index = index" [id]="tab.id" [title]="tab.title"
                          class=" justify-content-around nav nav-pills nav-primary w-25">
                          <!-- <ngb-tab title="Simple"> -->
                          <ng-template ngbTabContent>
                            <div class="btn btn-success pull-right my-4" (click)="go()" appActionNew>
                              <i class="fa fa-plus "></i> <span class="mx-3"> إضافة تبرع جديد</span>
                            </div>
                            <div class="table-responsive">
                              <table class="table" style="width: 100%">
                                <thead>
                                  <tr class="bg-dark " >
                                    <th scope="col" class="font-style-header text-center">#</th>
                                    <th scope="col" class="font-style-header">ش</th>
                                    <th scope="col" class="font-style-header text-center">رقم التبرع</th>
                                    <th scope="col" class="font-style-header text-center">تاريخ الإنشاء </th>
                                    <th scope="col" class="font-style-header text-center">تاريخ التحصيل المتوقع </th>
                                    <th scope="col" class="font-style-header text-center">تاريخ التحصيل الفعلي </th>
                                    <th scope="col" class="font-style-header text-center">المبلغ</th>
                                    <th scope="col" class="font-style-header text-center">حاله التبرع</th>
                                    <th scope="col" class="font-style-header text-center">اسم المحصل</th>
                                    <th scope="col" class="font-style-header text-center">بواسطة</th>
                                    <!-- <th scope="col" class="font-style-header text-center">التعليقات</th>
                                    <th scope="col" class="font-style-header text-center">الانجازات</th> -->
                                    <th scope="col" class="font-style-header text-center"></th>
    
                                  </tr>
                                </thead>
                                <tbody>
                                  <ng-container *ngFor="let item of DontationType;let i=index">
                                    <tr (click)="OpenDetailes(i)" style="cursor:pointer; background-color: unset;">
                                      <td scope="row" class="font-style">{{i+1}}</td>
                                      <td scope="row" class="font-style"><img width="30px" height="30px" [src]="imgUrlServer+item.companyIcon"></td>
                                      <td class="font-style text-center">{{item.serial}}</td>
                                      <td class="font-style text-center">{{item.creationDate | date:"dd/MM/yyyy"}}</td>
                                      <td class="font-style text-center">{{item.date | date:"dd/MM/yyyy"}}</td>
                                      <td  class="font-style text-center">{{item.collectedDate ? (item.collectedDate | date:"dd/MM/yyyy"): '----' }}</td>
                                      <td class="font-style text-center">{{item.totalAmount}}</td>
                                      <td class="font-style text-center">{{item.donationStatus}}</td>
                                      <td class="font-style text-center">{{item.collectorName}}</td>
                                      <td class="font-style text-center">{{item.createdByName}}</td>
                                      <td class="font-style " style="text-align: end;"   [ngStyle]="{'width': item.status==3  ? '120px':'155px' ,'background-color': item.companyColor }" >
                                        <button style="color: #000;padding: 1px 2px;  background: transparent !important;" (click)="openModal(item.donationId);$event.stopPropagation()">
                                          <i class="fa fa-commenting-o" style="font-size: 25px !important"></i>
                                        </button>
                                          <button *ngIf="donationMainTypeId!=2&&item.status==3 || item.status==4" style="color: red; padding: 7px;background-color: transparent;"
                                    (click)="GoToAccomplishment(item.donationId)" toolTipe="انجازات">
                                          <i class="fa fa-address-card-o"></i>
                                        </button>
                                        <button  style="color: rgba(35, 118, 241, 0.667); padding: 7px;background-color: transparent;"
                                        (click)="GoToAccomplishmentMediaCustomer(item.donationId,item.companyId)" toolTipe="عرض الانجازات">
                                        <i class="fa fa-picture-o"></i>
                                      </button>
                                        <button style="color: rgba(35, 118, 241, 0.667);padding: 7px;background-color: transparent;"
                                          (click)="EditDonationDetails(i)" appActionEdit>
                                          <i class="fa fa-pencil"></i>
                                        </button> 
                                        <i *ngIf="!item.ShowDetails" class="fa fa-angle-down colapse-icon" ></i>
                                        <i *ngIf="item.ShowDetails" class="fa fa-angle-up colapse-icon" ></i>
                                      </td>
                                      <!-- <td class="font-style">
                                      
                                      </td> -->
    
                                    </tr>
                                    <tr style="text-align: center;">
                                      <td style="border-left: none; "></td>
                                      <td style="border-left: none;border-right: none;"></td>
                                      <td colspan="12" *ngIf="item.ShowDetails" style="border-right: none;">
                                        <table *ngIf="donationMainTypeId!=2" style="width: 102.59%;
                                        margin-right: -8px;
                                        margin-bottom: 10px;">
                                          <thead>
                                          </thead>
                                          <tr class="bg-danger" style="color: black !important">
                                            <th scope="col" class="font-style-header text-center">#</th>
                                            <th scope="col" class="font-style-header text-center">فئه التبرع</th>
                                            <th scope="col" class="font-style-header text-center">نوع النشاط</th>
                                            <th scope="col" class="font-style-header text-center">نوع المشروع </th>
                                            <th scope="col" class="font-style-header text-center"> مكان المشروع</th>
                                            <th scope="col" class="font-style-header text-center">المبلغ</th>
                                            <th *ngIf="receiptNumView" scope="col" class="font-style-header text-center">رقم الفاتوره
                                            </th>
                                          </tr>
                                          <tbody >
                                            <tr *ngFor="let donation of item.details let i = index" style=" background-color: #e7e6e6;">
                                              <td scope="row" class="font-style text-center txtBlack">{{i+1}}</td>
                                              <td class="font-style text-center txtBlack">{{donation.donationTypeName}}</td>
                                              <td class="font-style text-center txtBlack"> {{donation.sectorName}}</td>
                                              <td class="font-style text-center txtBlack">{{donation.sectorTypeName}}</td>
                                              <td class="font-style text-center txtBlack">{{donation.sectorLocationName}}</td>
                                              <td class="font-style text-center txtBlack">{{donation.smallAmount}}</td>
                                              <td class="font-style text-center txtBlack" *ngIf="receiptNumView" >{{donation.receiptNum}}</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table *ngIf="donationMainTypeId==2" style="width: 102.59%;
                                        margin-right: -8px;
                                      margin-bottom: 10px;">
                                          <thead>
                                          </thead>
                                          <tr class="bg-danger" style="color: black;">
                                            <th scope="col" class="font-style-header text-center">#</th>
                                            <th scope="col" class="font-style-header text-center">فئه التبرع
                                            </th>
                                            <th scope="col" class="font-style-header text-center">نوع التبرع
                                            </th>
                                            <th scope="col" class="font-style-header text-center">العدد</th>
                                            <th *ngIf="receiptNumView" scope="col" class="font-style-header text-center">رقم الفاتوره
                                            </th>
                                          </tr>
                                          <tbody style="background-color: white !important;">
                                            <tr style="justify-content:initial"
                                              *ngFor="let donation of item.details let i = index" style=" background-color: #e7e6e6;">
                                              <td class="font-style text-center txtBlack" scope="row">{{i+1}}</td>
                                              <td class="font-style text-center txtBlack">{{donation.donationCategoryName}}</td>
                                              <td class="font-style text-center txtBlack">{{donation.donationTypeName}}</td>
                                              <td class="font-style text-center txtBlack">{{donation.quantity}}</td>
                                              <td class="font-style text-center txtBlack" *ngIf="receiptNumView">{{donation.receiptNum}}</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </ng-container>
                                </tbody>
                              </table>
                              <div *ngIf="donationMainTypeId==1" class="d-flex justify-content-center">
                                <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCountCach" previousText="&lsaquo;" nextText="&rsaquo;"
                                  firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumberCash"
                                  (pageChanged)="pageChanged($event)">
                                </pagination>
                              </div>
                              <div *ngIf="donationMainTypeId==2" class="d-flex justify-content-center">
                                <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCountPhysical" previousText="&lsaquo;" nextText="&rsaquo;"
                                  firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumberPhysical"
                                  (pageChanged)="pageChanged($event)">
                                </pagination>
                              </div>
                              <div *ngIf="donationMainTypeId==3" class="d-flex justify-content-center">
                                <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCountOutDoor" previousText="&lsaquo;" nextText="&rsaquo;"
                                  firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumberOutDoor"
                                  (pageChanged)="pageChanged($event)">
                                </pagination>
                              </div>
                            </div>
                          </ng-template>
                        </ngb-tab>
                      </ngb-tabset>
                  </div>
                </div>
              </div>
            </div>
  
          </div>
        </div>
      </div>
    </div>
</div>

</div>