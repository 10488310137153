
  
  
  
  <app-breadcrumb [title]="'تفاصيل التبرعات'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'"></app-breadcrumb>

  <!-- Container-fluid Ends-->
  
  
  <div class="container-fluid">
    <div class="row m-0" >
      <div class="col-12 col-md-9 text-left">
        <h5 class="py-2">بيانات التبرع </h5>
  
      </div>
    </div>
      <div class="row">
        
        
  
        <div class="col-sm-12">
       
  
          
          <div class="card">
            <div class="card-header px-0" style="padding-bottom: 5px;">
            
  
  
                <div class="col-xl-12 xl-100 col-lg-12">
                        <div >
                    <div  >
                      <div class="table-responsive">
                        <table class="table table-striped"   style="width: 100%;">
                          <thead>
                            <tr class="bg-dark ">
                              <th scope="col" class="font-style-header text-center" >#</th>
                              <th scope="col" class="font-style-header"  style="color: white;" >ش</th>
                              <th scope="col" class="font-style-header text-center" >رقم التبرع</th>
                              <th scope="col" (click)="sortData('creationDate')" class="font-style-header" ><i class="fa fa-sort"></i> تاريخ إنشاء التبرع</th>
                              <th scope="col" (click)="sortData('date')" class="font-style-header" ><i class="fa fa-sort"></i> تاريخ التحصيل المتوقع</th>
                              <th scope="col" (click)="sortData('totalAmount')" class="font-style-header"><i class="fa fa-sort"></i> المبلغ</th>
                              <th scope="col" class="font-style-header text-center" >حاله التبرع</th>
                              <th scope="col" class="font-style-header text-center" >اسم العميل</th>
                              <th scope="col" class="font-style-header text-center" >اسم المحصل</th>
                              <th scope="col" class="font-style-header text-center" >بواسطة</th>
                              <th scope="col" class="font-style-header text-center"></th>
                            </tr>
                          </thead>
                          <tbody >
                            <tr *ngFor="let item of Dontations;let i=index">
                              <td scope="row" class="font-style text-center" >{{i+1}}</td>
                              <td scope="row" class="font-style"><img width="40px" [tooltip]="item.companyName" height="40px" [src]="imgUrlServer+item.companyIcon"></td>
                              <td  class="font-style text-center">{{item.serial}}</td>
                              <td  class="font-style text-center">{{item.creationDate | date:"dd/MM/yyyy"}}</td>
                              <td  class="font-style text-center">{{item.date ? (item.date | date:"dd/MM/yyyy"): '----' }}</td>
                               <td  class="font-style text-center">{{item.totalAmount}}</td> 
                               <td  class="font-style text-center">{{item.donationStatus}}</td> 
                               <td  class="font-style text-center">{{item.customerName}}</td> 
                               <td  class="font-style text-center">{{item.collectorName}}</td> 
                               <td  class="font-style text-center">{{item.createdByName}}</td> 
                               <td  class="font-style  text-center" style="width: 115px;" [ngStyle]="{ 'background-color': item.companyColor  }" > 
                                 <button  style="color: rgba(3, 17, 40, 0.667);padding: 7px; background-color: transparent;" (click)="openModal(item.donationId)">
                                <i class="fa fa-commenting-o"  style="font-size: 18px !important" ></i>  
                                 </button>
                                 <button  style="color: red; padding: 7px; background-color: transparent;"   (click)="$event.stopPropagation(); GoToAccomplishment(item.donationId,i);" toolTipe="انجازات">
                                  <i class="fa fa-address-card-o"  style="font-size: 18px !important" ></i>  
                                   </button>
                                    
                                <button  style="color: rgba(35, 118, 241, 0.667);padding: 7px; background-color: transparent;" (click)="EditDonationDetails(i)">
                                  <i class="fa fa-pencil"  style="font-size: 18px !important" ></i>
                                </button>
                                </td> 
                            </tr>
                          </tbody>
                        </table>
                        <div class="d-flex justify-content-center">
                          <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
                            firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
                            (pageChanged)="pageChanged($event)">
                          </pagination>
                        </div>
<!--                    
                        <ngx-datatable class="bootstrap" [rows]="Dontations" [columns]="columns" [sortType]="SortType.multi"
                        [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50" [rowHeight]="50"
                        [limit]="10">
                    </ngx-datatable> -->
                      </div>           
               
                          </div>
                  </div>
                </div>
                
                <!-- <ngb-tabset (tabChange)="GetDonationMainTypeId($event)"  [destroyOnHide]="false" type="pills" class="tabbed-card w-75 bg-primary">
  
                  <ngb-tab  *ngFor="let tab of tabs; let index = index" [id]="tab.id"  [title]="tab.title" class="w-100">
                    <ng-template ngbTabContent>
                      <div class="btn btn-success pull-right" (click)="go()" >
                        <i class="fa fa-plus "></i> <span class="mx-3"> إضافة تبرع جديد</span>
                                          </div>
                      <div class="table-responsive">
                        <table class="table table-striped" style="width: 100%;">
                          <thead>
                            <tr class="bg-dark ">
                              <th scope="col" class="font-style-header" >#</th>
                              <th scope="col" class="font-style-header" >رقم التبرع</th>
                              <th scope="col" class="font-style-header" >تاريخ إنشاء التبرع</th>
                              <th scope="col" class="font-style-header" >تاريخ التحصيل المتوقع</th>
                              <th scope="col" class="font-style-header" >تاريخ التحصيل الفعلي</th>
                              <th scope="col" class="font-style-header">المبلغ</th>
                              <th scope="col" class="font-style-header" >حاله التبرع</th>
                              <th scope="col" class="font-style-header" >اسم المحصل</th>
                              <th scope="col" class="font-style-header">اعدادات</th>
                            </tr>
                          </thead>
                          <tbody >
                            <tr *ngFor="let item of DontationType.customerDonations;let i=index">
                              <th scope="row" class="font-style" >{{i+1}}</th>
                              <td  class="font-style">{{item.serial}}</td>
                              <td  class="font-style">{{item.creationDate | date:"shortDate"}}</td>
                              <td  class="font-style">{{item.date | date:"shortDate"}}</td>
                              <td  class="font-style">{{item.collectedDate | date:"shortDate"}}</td>
                               <td  class="font-style">{{item.totalAmount}}</td> 
                               <td  class="font-style">{{item.donationStatus}}</td> 
                               <td  class="font-style">{{item.collectorName}}</td> 
                               <td class="font-style chose" >
                                <button style="color: red; padding: 7px"(click)="DeleteDonationDetails(i)">
                                  <i class="fa fa-trash" ></i>
                                </button> 
                                
                                <button style="color: rgba(35, 118, 241, 0.667);padding: 7px" (click)="EditDonationDetails(i)">
                                  <i class="fa fa-pencil" ></i>
                                </button>
                            </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>                  </ng-template>
                  </ngb-tab>
                </ngb-tabset> -->
            </div>
           
          </div>
        </div>
      </div>
    </div>