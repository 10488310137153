<app-breadcrumb [title]="'تقارير مصادر التسويق'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'">
</app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
            <!-- < action="" [formGroup]="createReports"> -->
                <div class="card-header px-0 " style="padding-bottom: 5px;">
                    <div class="row m-0 mb-2">
                         <div class="col-12 col-md-12">
                          <div class="row"></div>
                              <div class="row">
                                <div class="col-12 col-md-6">
                                  <label for="validationCustom02"><span class="validation-required">*</span>  اسم الشركة</label>
                                <br>
                                  <label *ngIf="currentUser.roleId==10">{{currentUser.name}}</label>
                            <ng-select *ngIf="currentUser.roleId!=10"
                            class="js-example-basic-single col-sm-12 job-select2 form-group"
                              required=""
                              [(ngModel)]="ReportsInfo.companyId"
                              aria-label="Default select example"
                              (change)="SelectCompany($event.id)"
                              [items]="Companies"
                              bindLabel="name"
                              bindValue="id"
                              >
                            
                            </ng-select>
                                </div>
                                   <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <label for="">التبرعات المنشأة من تاريخ </label>
                                           <input type="date"  class="form-control"  [(ngModel)]="ReportsInfo.creationDateFrom">
                                        </div>
                                    </div>
                                    
                                    <div class="col-12 col-md-6">
                                        <div class="form-group">

                                            <label for=""> التبرعات المنشأة الي تاريخ</label>

                                          
                                            <input type="date"  class="form-control"  [(ngModel)]="ReportsInfo.creationDateTo">
                                        </div>
                                   </div>   
                                   <div class="col-12 col-md-6">
                                    <div class="form-group">

                                      <label for=""> التبرعات المحصله من تاريخ </label>
                                       <input type="date"  class="form-control"  [(ngModel)]="ReportsInfo.collectionDateFrom">



                                    </div>
                                </div>
                                
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                      
                                      <label for="">  التبرعات  المحصله  إلي  تاريخ </label>
                                       <input type="date"  class="form-control" [(ngModel)]="ReportsInfo.collectionDateTo">

                                     

                                    </div>
                                </div>        
                                <div class="col-12 col-md-6">
                                  <label for="">حالة التبرع   </label>
                                    <ng-select
                                    class="js-example-basic-single"
                                    aria-label="Default select example"
                                   (change)="donationStatus($event.id)"
                                    [(ngModel)]="ReportsInfo.donationStatus"
                                    [items]="GetLookupsGetDonation"
                                    bindLabel="title"
                                    bindValue="id"
                                    >
                                  >
                                  </ng-select>
                                </div>
                                <div class="col-12 col-md-6">
                                  <label for="">فئه التبرع</label>
                                    <ng-select
                                    class="js-example-basic-single"
                                    aria-label="Default select example"
                                     (change)="donationTypeid($event.id)"
                                     [(ngModel)]="ReportsInfo.donationTypeId"
                                     [items]="DonationType"
                                     bindLabel="title"
                                     bindValue="id"
                                  >
                                  </ng-select>
                                </div>
                               
                                <div class="col-12 col-md-6 ">
                                  <label for="">النشاط</label>
                                    <ng-select
                                    class="js-example-basic-single col-sm-12 job-select2 form-group"
                                    [(ngModel)]="ReportsInfo.sectorId"
                                    aria-label="Default select example"
                                    (change)="GetSectorGetById($event?.id)" 
                                    [items]="SectorGet"
                                    bindLabel="title"
                                    bindValue="id"
                                    >
                                  </ng-select>
                                </div>
                                <div class="col-12 col-md-6 ">
                                  <label for="">المشروعات</label>
                                    <ng-select
                                    class="js-example-basic-single"
                                    aria-label="Default select example"
                                    [(ngModel)]="ReportsInfo.sectorTypeId"
                                    (change)="GetSectorTypeGetById($event?.id)" 
                                    [items]="getType"
                                    bindLabel="title"
                                    bindValue="id"
                                    >
                                  </ng-select>
                                </div>
                                <div class="col-12 col-md-6 ">
                                  <label for="">اماكن المشروعات </label>
                                    <ng-select
                                    class="js-example-basic-single"
                                    aria-label="Default select example"
                                    (change)="GetSectorLocationGetById($event.id)" 
                                   [(ngModel)]="ReportsInfo.sectorLocationId"
                                   [items]="SectorLocation"
                                   bindLabel="title"
                                   bindValue="id"
                                    >
                                  </ng-select>
                                </div>
                                <div class="col-12 col-md-6 mt-4">
                                  <label for=""> مصدر اعلان</label>
                                    <ng-select
                                    class="js-example-basic-single"
                                    aria-label="Default select example"
                                    (change)="GetAnnouncementSourceGetById($event.id)"
                                    [(ngModel)]="ReportsInfo.sourceMarketId"
                                    [items]="getsourcemarket"
                                    bindLabel="title"
                                    bindValue="id"
                                    >
                                  </ng-select>
                                </div>
                        <div class="col-12 col-md-12">
                          
                            <div class="row">
                                
                            </div>
                        </div>         

                        
                </div>
                <div class="d-flex justify-content-end align-items-center buttom-text">
                  <button class="addnew-btn ng-star-inserted" style="margin-top: 4rem;" type="submit" (click)="Viewreports()">عرض التقرير</button>
                </div>
                
              </div>
            
          </div>
    </div>
</div>
        </div>
    </div>
</div>
