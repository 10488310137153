import { DatePipe } from '@angular/common';
import { SourceOfmarketingService } from './../../../../shared/API-Service/source-ofmarketing.service';
import { DonationRequestsService } from './../../../../shared/API-Service/donation-requests.service';
import { Component, OnInit } from '@angular/core';
import { CustomerServciesCalls } from '../../../../shared//Models/CustomerServciesCalls';
import { CallReasonApiService } from 'src/app/shared/API-Service/call-reason-api.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';

@Component({
  selector: 'app-customer-services-calls',
  templateUrl: './customer-services-calls.component.html',
  styleUrls: ['./customer-services-calls.component.css']
})
export class CustomerServicesCallsComponent implements OnInit {
  currentUser: any;
  Companies: any[];
  getEmployee: any[];
  CustomerServicesCalls= new CustomerServciesCalls();
  constructor(
    public datepipe:DatePipe,private router:Router,
    private SourceOfmarketingService:SourceOfmarketingService,
    private CompanyDataService:CompanyDataService
    ) { 

this.currentUser=JSON.parse(localStorage.getItem("RiskCurrentUser"))
if(this.currentUser.roleId==10){
  this.CustomerServicesCalls.companyId=this.currentUser.id
}
this.CustomerServicesCalls.reportCreatedBy=this.currentUser.name;
const date = new Date();
      this.CustomerServicesCalls.creationDateFrom=this.datepipe.transform(date, 'yyyy-MM-dd');
      this.CustomerServicesCalls.creationDateTo=this.datepipe.transform(date,'yyyy-MM-dd');
    }

  ngOnInit() {
    this.Getemployee();
     this.GetCompanies();
  }
  selectEmplyee(event){
    this.CustomerServicesCalls.employeeId = parseInt(event) 
    this.CustomerServicesCalls.employeeName = this.getEmployee.find(x => x.id == event).name
   }
  
  Getemployee(){
    this.SourceOfmarketingService.EmployeeGet().subscribe(res=>{
      this.getEmployee = res.data
    })
   }
   SelectCompany(CompanyId){
    this.CustomerServicesCalls.companyId=parseInt(CompanyId);

  }
  GetCompanies(){
    this.CompanyDataService.GetCompanies().subscribe(res=>{
      this.Companies=res.data
    })
  }
  Viewreports(){
    if(this.CustomerServicesCalls.companyId!=null&&this.CustomerServicesCalls.creationDateFrom &&this.CustomerServicesCalls.creationDateTo){
  if(this.CustomerServicesCalls.employeeId==null){
    this.CustomerServicesCalls.employeeId=null;
    this.CustomerServicesCalls.employeeName=""
  }

  localStorage["ReportName"]="EmployeesCallsReport";
  localStorage.setItem('ReportFilter',JSON.stringify(this.CustomerServicesCalls));

  this.router.navigate([]).then((result) => {
    window.open("/content/admin/call-start-end-report", '_blank');
  });
}
else{
  if (!this.CustomerServicesCalls.companyId){
    Swal.fire({
      icon:'error',
      title:'خطأ',
      text:' يجب اختيار الشركة'
    })
  }
   if (!this.CustomerServicesCalls.creationDateFrom){
    Swal.fire({
      icon:'error',
      title:'خطأ',
      text:' يجب اختيار بدايه تاريخ المكالمات'
    })
  }
  else  if (!this.CustomerServicesCalls.creationDateTo ){
    Swal.fire({
      icon:'error',
      title:'خطأ',
      text:' يجب اختيار نهايه تاريخ المكالمات'

    })
  }
}


}
}
