import { GenericResponse } from './../Models/GenericResponse';
import { environment } from './../../../environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SourceOfmarketingService {

  constructor(private http:HttpClient) { }


  GetLookupsGetDonationStatu(): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/Lookups/GetDonationStatus`);
  }

  GetDonationType(): Observable<GenericResponse<any>>{
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/DonationType/Get`);
  }

  GetSector(): Observable<GenericResponse<any>>{
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/Sector/Get`);
  }
  getSectorsBycompanyId(companyId:any): Observable<GenericResponse<any>> {

      return this.http.get<GenericResponse<any>>(
        `${environment.Server_URL}/Sector/Get?companyId=${companyId}`
      );
    }
  GetSectorGetById(governateid?:number): Observable<GenericResponse<any>> {

    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/SectorType/GetSectorTypesInSector?id=${governateid}`);
   
    
          }



   GetSectorLocationById(sectorid?:number): Observable<GenericResponse<any>>{
  
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/SectorLocation/GetSectorLocationInSectorTypes?id=${sectorid}`);
  
   }   
   
   
   EmployeeGet(): Observable<GenericResponse<any>>{
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/Employee/Get`);
   }

   GetsourceOfMarketing(): Observable<GenericResponse<any>>{
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/SourceOfMarkting/Get`);
   }

   GetAllsourceOfMarketingForReport(): Observable<GenericResponse<any>>{
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/SourceOfMarkting/Get?filter=false`);
   }
}
