export interface HttpDonationTypeCreate {
    title: string,
    isNotify: boolean,
    donationCategoryId?: number,
    donationMainTypeId: number,


}
export interface HttpDonationTypeRespons {
    id:number,
    title: string,
    isNotify: boolean,
    donationCategoryId?: number,
    donationMainTypeId: number,



}
export interface HttpDonationTypeDelete {
    id:number,
 



}




export class  DonationType {
    id?:number;
    title: string;
    isNotify: boolean;
    donationCategoryId?: number;
    donationMainTypeId: number;

   
}

