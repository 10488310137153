import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DonationRequestsService } from './../../../../shared/API-Service/donation-requests.service';
import {CollectorSmallDonation } from '../../../../shared//Models/CollectorSmallDonation';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-collector-small-donation',
  templateUrl: './collector-small-donation.component.html',
  styleUrls: ['./collector-small-donation.component.css']
})
export class CollectorSmallDonationComponent implements OnInit {
  Getcollectors: any;
  CollectorSmallDonation = new CollectorSmallDonation()
  currentUser: any;
  Companies: any[];
  constructor(
public datepipe:DatePipe,private router:Router,
private DonationRequestsService:DonationRequestsService,
private CompanyDataService:CompanyDataService
    ) {
      this.currentUser=JSON.parse(localStorage.getItem("RiskCurrentUser"))
      if(this.currentUser.roleId==10){
        this.CollectorSmallDonation.companyId=this.currentUser.id
      }
      const date = new Date();

      this.CollectorSmallDonation.dateFrom=this.datepipe.transform(date, 'yyyy-MM-dd');
      this.CollectorSmallDonation.dateTo=this.datepipe.transform(date,'yyyy-MM-dd')
     }

  ngOnInit(): void {
   this.getcollectors()
  this.GetCompanies()
    
  }

  getcollectors(){
    this.DonationRequestsService.Getcollectors().subscribe(response=>{

      this.Getcollectors = response.data
 

    })
  }
  SelectCompany(CompanyId){
    this.CollectorSmallDonation.companyId=parseInt(CompanyId);


  }
  GetCompanies(){
    this.CompanyDataService.GetCompanies().subscribe(res=>{
      this.Companies=res.data
    })
  }
Viewreports(){

  if(this.CollectorSmallDonation.companyId!=null&& this.CollectorSmallDonation.dateFrom  &&this.CollectorSmallDonation.dateTo){
this.CollectorSmallDonation.reportCreatedBy=this.currentUser.name;
if(this.CollectorSmallDonation.collectorId){
this.CollectorSmallDonation.collectorName= this.Getcollectors.filter(res=>res.id==this.CollectorSmallDonation.collectorId)[0].name
}
else{
  this.CollectorSmallDonation.collectorName=null
}
console.log(this.CollectorSmallDonation)
  localStorage["ReportName"]="CollectorSmallDonation";
  localStorage.setItem('ReportFilter',JSON.stringify(this.CollectorSmallDonation));

  this.router.navigate([]).then((result) => {
    window.open("/content/admin/call-start-end-report", '_blank');
  });
 
}
else{
  if(!this.CollectorSmallDonation.companyId){
    Swal.fire({
      icon:'error',
      title:'خطأ',
      text:'يجب اختيار الشركة'

    })
  }
else   if (!this.CollectorSmallDonation.dateFrom){
    Swal.fire({
      icon:'error',
      title:'خطأ',
      text:'يجب اختيار التبرعات المحصله من تاريخ'

    })
  }
  else  if (!this.CollectorSmallDonation.dateTo ){
    Swal.fire({
      icon:'error',
      title:'خطأ',
      text:'يجب اختيار التبرعات المحصله الي تاريخ'

    })
  }
 
}
}
}


