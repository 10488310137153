import { Component, OnInit } from '@angular/core';
import {  Router } from '@angular/router';
import { StatisticsService } from 'src/app/shared/API-Service/statistics.service';
@Component({
  selector: 'app-follow-up-calls-stats-admin',
  templateUrl: './follow-up-calls-stats-admin.component.html',
  styleUrls: ['./follow-up-calls-stats-admin.component.css']
})
export class FollowUpCallsStatsAdminComponent implements OnInit {
  counts: any;

  constructor(private StatisticsService:StatisticsService,private router:Router) { }
  ngOnInit(): void {
    this.GetCollectorDonationsCounts();
  }
  goToDetails(type){
    this.router.navigateByUrl("content/agent/follow-up-call-statsdetails-admin", {state:type});
  }
  GetCollectorDonationsCounts(){
    this.StatisticsService.GetTodayFollowUpCallsCountAdmin().subscribe((res:any)=>{
      
      this.counts=res.data
    })
  }


}
