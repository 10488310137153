import { Insertjobs, UpdateJobs } from '../../../../../shared//Models/insertjobs';
import { JobsService } from './../../../../../shared/API-Service/jobs.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InsertGovernorate } from 'src/app/shared/Models/InsertGovernorate';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-insert-jobs',
  templateUrl: './insert-jobs.component.html',
  styleUrls: ['./insert-jobs.component.css']
})
export class InsertJobsComponent implements OnInit {
  update:boolean;
  InsertForm: FormGroup;
  validated:boolean;
  constructor(private _formBuilder:FormBuilder,private router:Router,private JobsService:JobsService,private route:ActivatedRoute) { }

  ngOnInit(): void {
    if(this.route.snapshot.paramMap.get('id')){
      this.JobsService.title = localStorage.getItem("riskJobs");
      this.InitForm(this.JobsService.title)
      this.update = true;
    }else
    {
      this.update = false;
      
      this._InitForm();
    }
  }


  InitForm(title:string){
    this.InsertForm = this._formBuilder.group({
      title: [title, Validators.required],
    });
  }
  _InitForm(){
    this.InsertForm = this._formBuilder.group({
      title: ['', Validators.required],
    });
  }

  InsertJobs(){        
    this.JobsService.Insertjobs({title:this.InsertForm.get('title').value} as Insertjobs  ).subscribe(
      response=>{
        Swal.fire({
          icon: 'success',
          title: "تم إدخال الوظيفه بنجاح",
          showConfirmButton: false,
          timer: 1500
        })
        this.router.navigateByUrl("content/admin/ListJobs");
      },
      err=>{
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }

  UpdateJobs(){
    let id = +this.route.snapshot.paramMap.get('id');
    this.JobsService.updateJobs({Id: id ,title:this.InsertForm.get('title').value} as UpdateJobs).subscribe(
      response=>{
        Swal.fire({
          icon: 'success',
          title: "تم تعديل الوظيفه بنجاح",
          showConfirmButton: false,
          timer: 1500
        })
        this.router.navigateByUrl("content/admin/ListJobs");
        localStorage.removeItem("riskgovernorate");
      },
      err=>{
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )

  }



  onSubmit() {
    if (this.InsertForm.status == "VALID") {
      this.validated = false;
  
      if (this.update == true) {
        this.UpdateJobs();
      } else {
        this.InsertJobs();
      }
    } else {
      this.validated = true;
    }
  }

}
