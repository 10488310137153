export class ProjectDonationsReport {
    donationTypeId:number=null
	companyId:number=null
	sectorId:number=null
	sectorTypeId:number=null
	sectorLocationId:number=null
	reportCreatedBy:string='';
	donationTypeName:string='';
	sectorName:string='';
	sectorLocationName:string='';
	sectorTypeName:string='';
	dateFrom:string='';
	dateTo:string='';
}