export interface InsertSector {
    title:string;
    numberofDays:number;
    companyIds:[];
}
export interface UpdateSector {
    id:number;
    title:string;
    numberofDays:number;
    companyIds:[];

}
