export class Asset {
  
    name:string = '';
    cityId:any;
    address:string='';
    mobile:string='';
    DateOfBirth:string='';
    gender:string = ''

    
  }