<div class="container-fluid" >
  <div class="row">
    <div class="col-12"></div>
    <div class="col-12 d-flex justify-content-start" style="padding: 23px">
      <h3>العملاء</h3>
    </div>
    <div class="col-12 ">
      <div class="row">
        <ng-container *ngFor="let item of Clients">
          <div class="col-12 col-md-3">
          <div class="card">
           <div   
          (click)="getClientData(item.companyId)">
          
          <img [src]=" image+item.companyLogoPath " class="card-img-top" style="margin-top:20px ;max-height:165px;" alt="..." />
          <div class="card-body">
            <h5 class="card-title text-center" >{{item.companyName}}</h5>
          </div>
        </div>
      </div>
          </div>
        </ng-container>
       
      </div>
    </div>
  </div>
</div>
