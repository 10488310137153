import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CallReasonApiService } from 'src/app/shared/API-Service/call-reason-api.service';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { GenericResponse } from 'src/app/shared/Models/GenericResponse';
import { GetCallReason } from 'src/app/shared/Models/get-call-reason';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-CallReason',
  templateUrl: './CallReason.component.html',
  styleUrls: ['./CallReason.component.css']
})
export class CallReasonComponent extends BaseComponent implements OnInit {

  //#region  Declare Variables
  response: GenericResponse<GetCallReason>;
  Response_List: any;
  returnUpdatedRecord:any={};



  //#endregion

  //#region constructor
  constructor(private ApiService: CallReasonApiService,
    private router: Router) {super(); }
  //#endregion

  //#region  ng OnInit
  ngOnInit(): void {
    this.Response_List = [];
    this.GetCallReason();
  }
  //#endregion

  //#region Consume API's
 
  pageChanged(event:any){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;

    this.ApiService.GetCallReasonList(this.pager).subscribe(
      response => {
        this.response = response;
        this.Response_List = response.data;
        this.totalCount = this.Response_List.totalCount;
        this.Response_List=this.Response_List.items;
        
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
    // newLocl._ShipmentService.Get(data).subscribe((res)=>{
    //   this.response=res.data;
    //   this.totalItems = this.response.totalCount;
    //   this.response=this.response.items;
    // })
  }
  //#region  Get Call Reason
  GetCallReason() {

    this.ApiService.GetCallReasonList(this.pager).subscribe(
      response => {
        this.response = response;
        this.Response_List = response.data;
        this.totalCount = this.Response_List.totalCount;
      this.Response_List=this.Response_List.items;
        
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }
  //#endregion

  //#region  Delete Governoate
  Delete(id: number) {
    Swal.fire({
      title: ' تحذير !',
      text: "هل انت متأكد من حذف هذا العنصر ؟ ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7364f6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'حذف',
      cancelButtonText: 'إنهاء',
    })
      .then((result) => {

        if (result.isConfirmed) {
          
          this.ApiService.DeleteCallReason(id).subscribe(
            response => {
              this.GetCallReason();
              Swal.fire({
                icon: 'success',
                title: "تم حذف العنصر  بنجاح",
                showConfirmButton: false,
                timer: 1500
              })
              this.router.navigateByUrl("content/admin/Get-Call-Reason");
            },
            err => {
              Swal.fire({
                icon: 'error',
                title: 'خطأ',
                text: err.error.message,
              })
            }
          )

        } else {
          // Swal.fire(
          //   'Your appointment still active ',
          // );
        }
      });
  }
  //#endregion

  //#endregion

  //#region AddNew
  AddNew() {
    this.router.navigateByUrl("content/admin/insert-call-reason");
  }
  //#endregion

  //#region Governoate
  update(id: number,order: number, title: string,companytype:number[]) {
    this.ApiService.title = title;
    this.ApiService.order = order;
    this.ApiService.companytypeIds=companytype;
    this. ApiService.GetReasonById(id).subscribe(
      (data)=>{
       this.response.data = data.data;
      }
    )
    
    localStorage.setItem("reasonTitle",title)
    localStorage.setItem("reasonOrder",JSON.stringify(order))
    localStorage.setItem("companytypeIds",JSON.stringify(companytype))

    this.router.navigate(['content/admin/update-call-reason', id]);
  }
  //#endregion

}
