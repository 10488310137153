
<div class="container-fluid top-padding" style="height: 550px; ">
  <div class="text-right" style="padding: 5px; margin-top: 25px;">
    <label for="comment"> التعليق </label>
<!-- <textarea   [(ngModel)]="form.comment"
required="required" class="form-control textForArabic txtAria" placeholder="   ">
</textarea> -->
<input class="form-control input-txt-bx"  [(ngModel)]="form.comment" (keyup.enter)="InsertComment()" type="text" name="message-to-send"
placeholder="ضع تعليقك هنا">
<br>

<button  [disabled]="form.comment.length==0" class="btn btn-danger  btn-lg btn-block" (click)="InsertComment()"
> حفظ </button>
</div>
<!-- 
        <input      class="form-control"  (keyup)="applyFilter($event.target.value)" placeholder="بحث">
        <br> -->
        <div style="overflow-y: scroll; max-height: 400px;">
              <ng-container *ngFor="let item of AllComments; let i = index;"  >
          <!-- <div class="card cardStyle"> -->
      
          <!-- <div class="card-body" > -->
            <div class="social-chat" style="width: 97% !important;">
              <div class="your-msg">
               <div class="media">
                 <div class="media-body" style="width: 80%; word-wrap: break-word; margin: 0px;"><span class="f-w-500"> {{item.createdByName}}  <span>{{item.creationDate |  date:'medium'}} </span></span>
                   <p>{{item.comment}}</p>
                 </div>
               </div>
             <!-- </div>
             </div> -->
        </div>
          </div>
        <!-- <div class="your-msg">
          <div class="media"><img class="img-50 img-fluid m-r-20 rounded-circle" alt=""
              src="assets/images/user/1.jpg">
            <div class="media-body"><span class="f-w-500"> {{item.createdByName}}  <span>{{item.creationDate | date}} <i
                    class="fa fa-reply font-primary"></i></span></span>
              <p>{{item.comment}}</p>
            </div>
          </div>
        </div> -->

        
        </ng-container>
        </div>
      
        
    
      
  </div>
  
  