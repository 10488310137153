export class ProjectDonations {
    creationDateFrom:string = '';
    companyId:number=null;
    creationDateTo:string='';
    collectionDateFrom:string='';
    collectionDateTo:string='';
    donationStatus:number=null;
    donationStatusName:string=''
    donationTypeId:number=null;
    donationTypeName:string='';
    sectorId:number=null;
    sectorName:string='';
    sectorTypeId:number=null;
    sectorTypeName:string='';
    sectorLocationId:number=null;
    sectorLocationName:string='';
    reportCreatedBy:string='';
}