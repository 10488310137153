<app-breadcrumb [title]="'قائمة المسئولين'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'"></app-breadcrumb>

<div class="table-responsive">
    <table class="table  table-striped" style="width: 100%;">
      <thead>
        <tr class="bg-dark ">
          <th scope="col" class="font-style-header">#</th>
          <th scope="col" class="font-style-header">أسم الموظف</th>
          <th scope="col" class="font-style-header">أسم المستخدم</th>
          <th scope="col" class="font-style-header">رقم الموبايل</th>
          <th scope="col" class="font-style-header">الرقم القومى</th>
          <!-- <th scope="col" class="font-style-header" style="">العنوان</th> -->
          <th scope="col" class="font-style-header" ></th>
        </tr>
      </thead>
      <tbody >
        <!-- <tr *ngFor="let item of Employee_List;let i=index">
          <th scope="row" class="font-style" style="">{{i+1}}</th>
          <td style="" class="font-style">{{item.name}}</td>
          <td style="" class="font-style">{{item.userName}}</td>
          <td style="" class="font-style">{{item.mobile}}</td>
          <td style="" class="font-style">{{item.nationalId}}</td>
      

          <td class="font-style chose" >
          
       
            
            <button style="color: rgba(35, 118, 241, 0.667);padding: 7px" >
              <i class="fa fa-pencil" ></i>
            </button>
        </td>
        </tr> -->
      </tbody>
    </table>
  </div>
  <!-- (click)="updateEmployee(item.id,item)" -->