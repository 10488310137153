import { getCities } from "./getCities";
import { getAllCollectorEmployees } from "./Get-All-Collector-Employees";

export class GetCollectorsInCity {
     id: number;
     collectorId: number;
     cityId: number;
     City: getCities;
     CityTitle:string;
     CollectorEmployee:getAllCollectorEmployees;
     CollectorEmployeeTitle:string;
}
export class GetCollectorCity {
    id: number;
    collectorId: number;
    cityId: number;
    employeeName :string;
}
export class UpdateCity{
    id:number;
    employeeName :string;
    collectorId :number;
    cityId :number;
}
export class GetCollector {
  
    id: number;
    name: string;
  
}