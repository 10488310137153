<app-breadcrumb [title]="'اضافه إنجاز لمشروع'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'">
</app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header px-0 " style="padding-bottom: 5px;">
                    <form [formGroup]="insertForm" (ngSubmit)="Submit()">

                    <div class="row m-0 mb-2">
                        <div class="col-md-3">
                          <label for=""> الاسم
                          </label>
                            <div class="form-group">
                                
                               <input type="text"  class="form-control" formControlName="title" 
                               [tooltip]="fc.title.errors?.required ? ' الاسم مطلوب' : null" required
                               [isDisabled]="!((fc.title.errors?.required && fc.title.touched))"
                               containerClass="" [class.is-valid]="fc.title.valid && (fc.title.touched)"
                               [class.is-invalid]="fc.title.invalid && (fc.title.touched)"
                               >
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label for=""> الميديا
                            </label>
                              <div class="form-group">
                                  
                               
      <ng-select class="js-example-basic-single"
      aria-label="Default select example"
      formControlName="mediaTypeId" 
      (change)="getMediaId($event)"
      [items]="MediaTypes"
      bindLabel="title"
      bindValue="id" ngDefaultControl
      [tooltip]="fc.mediaTypeId.errors?.required ? ' الميديا مطلوب' : null" required
      [isDisabled]="!((fc.mediaTypeId.errors?.required && fc.mediaTypeId.touched))"
      containerClass="" [class.is-valid]="fc.mediaTypeId.valid && (fc.mediaTypeId.touched)"
      [class.is-invalid]="fc.mediaTypeId.invalid && (fc.mediaTypeId.touched)"
      >
      </ng-select>
                              </div>
                          </div>
                          <div class="col-md-3 text-center">
                            <label for=""> مكان المشروع
                            </label>
                              <div class="form-group">
                                  
                               
      <!-- <ng-select class="js-example-basic-single"
      aria-label="Default select example"
      formControlName="SectorLocationId" 
      [items]="SectorLocation"
      bindLabel="title"
      bindValue="id" ngDefaultControl
      [tooltip]="fc.SectorLocationId.errors?.required ? ' المشروع مطلوب' : null" required
      [isDisabled]="!((fc.SectorLocationId.errors?.required && fc.SectorLocationId.touched))"
      containerClass="" [class.is-valid]="fc.SectorLocationId.valid && (fc.SectorLocationId.touched)"
      [class.is-invalid]="fc.SectorLocationId.invalid && (fc.SectorLocationId.touched)"
      >
      </ng-select> -->
    <h6 >{{SectorLocationTitle}}</h6>                          
    </div>
                          </div>
                        <div class="col-md-3">
                          <label for="">الترتيب</label>
                            <div class="form-group">
                             
                               <input type="number"  class="form-control"  formControlName="order" 
                               [tooltip]="fc.order.errors?.required ? 'الترتيب مطلوب' : null" required
                               [isDisabled]="!((fc.order.errors?.required && fc.order.touched))"
                               containerClass="" [class.is-valid]="fc.order.valid && (fc.order.touched)"
                               [class.is-invalid]="fc.order.invalid && (fc.order.touched)"
                               >
                            </div>
                        </div>
                        <div *ngIf="haveImage" class="col-12 col-md-3">
                        <button type="button" *ngIf="MediaTypeId==7" class="btn btn-primary pull-right addbtn w-100 " color="primary" (click)="fileInput.click()">
                          <span>Upload Photo</span>
                          <input  #fileInput type="file"   
                          accept="image/*" (change)="onFileSelected($event,i)" style="display:none;" />
                        </button> 
                         <button type="button" *ngIf="MediaTypeId==11" class="btn btn-primary pull-right addbtn w-100 " color="primary" (click)="fileInput1.click()">
                          <span>Upload Video</span>
                          <input  #fileInput1 type="file"   
                          accept="video/*" (change)="onFileSelected($event,i)" style="display:none;" />
                          </button>  

                        </div>
                        <div class="row m-0" > 
                          <div class="image-area" style="margin: 8px;"  >
                              <img  *ngIf="imagesView &&MediaTypeId==7" src="{{imagesView}}"  height="100" width="100"  (click)="OpenImage(imagesView)">
                              <video *ngIf="imagesView &&MediaTypeId==11" width="320" height="240" controls>
                                <source src="{{imagesView}}" type="video/mp4">
                                Your browser does not support the video tag.
                              </video>
                            </div>
                            <a *ngIf="imagesView" class="remove-image" (click)="deleteimage(i)" style="display: inline;">&#215;</a>

                  </div>
                        <div *ngIf="!haveImage" class="col-12 col-md-12">
                            <label for=""> الرابط</label>
                            <div class="form-group">
                                <textarea type="text" min="0"  style="height: 100px;" class="form-control" formControlName="url" 
                                [tooltip]="fc.url.errors?.required ? ' الرابط مطلوب' : null" required
                                [isDisabled]="!((fc.url.errors?.required && fc.url.touched))"
                                containerClass="" [class.is-valid]="fc.url.valid && (fc.url.touched)"
                                [class.is-invalid]="fc.url.invalid && (fc.url.touched)"></textarea>
                            </div>
                        </div>
                        <div class="col-12 col-md-12">
                            <div *ngIf="update; else elseBlock">
                                <button class="btn btn-primary pull-right" type="submit" >
                                  تعديل
                                </button>
                              </div>
                  
                              <ng-template #elseBlock>
                                <button class="btn btn-primary pull-right" type="submit">
                                  إضافة
                                </button>
                              </ng-template>
                </div>
                </div>
                    </form>
    </div>
</div>
        </div>
    </div>
</div>