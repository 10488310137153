import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MediaType } from '../../../../shared//Models/MediaType';
import {MediaApiService} from 'src/app/shared/API-Service/media-api.service'

@Component({
  selector: 'app-insert-media-type',
  templateUrl: './insert-media-type.component.html',
  styleUrls: ['./insert-media-type.component.css']
})
export class InsertMediaTypeComponent implements OnInit {
  insertForm: FormGroup;
  MediaType = new MediaType()
  id: any;
  update: boolean;
  MediaTypes: any;
  ForProject:boolean;
  ForCustomer:boolean;
  constructor(private MediaApiService:MediaApiService, private _formBuilder: FormBuilder,private router: Router,private route: ActivatedRoute) {
    // this.ForCustomer=false;
    // this.ForProject=false;
   }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id")) {
      this.id =this.route.snapshot.paramMap.get("id")
    this.MediaApiService.GetMediaTypeById(this.id).subscribe(async (res:any)=>{
    this.MediaTypes=  await res.data;
    this.ForCustomer=this.MediaTypes.forCustomer
    this.ForProject=this.MediaTypes.forProject
      this.initForm(this.MediaTypes);

    })
      this.update = true;
    } else {
      this.initForm()
      this.update = false;
    }
  }
  initForm(MediaType?: MediaType) {
    this.insertForm = this._formBuilder.group({
      title: [MediaType?.title || '', Validators.required],
      order: [MediaType?.order || '', Validators.required],
      embedcode: [MediaType?.embedcode || '']
    });
  }

  get fc() {
    return this.insertForm.controls;
  }

  Submit(){
    if (this.insertForm.status == "VALID") {
      if (this.update == true) {
        this.UpdateMediaType();
      } else {
        this.InsertMediaType();
      }
    }
     else {
      this.insertForm.markAllAsTouched();
    }
  

  }
  ForCustomerCheck(e){
        if(e.target.checked!=true){
       this.ForCustomer=false;
    }
    else{
      this.ForCustomer=true;

    }
 
  }

  ForProjectCheck(e){
        if(e.target.checked!=true){
       this.ForProject=false;
    }
    else{
      this.ForProject=true;

    }
 
  }
  UpdateMediaType(){
    const data ={
      "id":this.id,
      "title": this.insertForm.get('title').value,
      "order": Number(this.insertForm.get('order').value),
      "embedcode": this.insertForm.get('embedcode').value,
      "forProject": this.ForProject,
      "forCustomer": this.ForCustomer
    }
    this.MediaApiService.UpdateMediaType(data).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم التعديل بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/media-types");
    },
      (err) => {
        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
  
      );
  }
  InsertMediaType(){
    const data ={
      "title": this.insertForm.get('title').value,
      "order": Number(this.insertForm.get('order').value),
      "embedcode": this.insertForm.get('embedcode').value,
      "forProject": this.ForProject,
      "forCustomer": this.ForCustomer
    }
    this.MediaApiService.CreateMediaType(data).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم الحفظ بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/media-types");

    },
      (err) => {
        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
            );
  }
}
