
<app-breadcrumb [title]="'تقرير عمولات موظفين التسويق'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'">
</app-breadcrumb>
<div class="card">
    <div class="container-fluid">
        <!-- <form action="" [formGroup]="createform"> -->
    <div class="row">
      <div class="col-md-4 col-12">
        <label for="validationCustom02">  الشهر</label>
        <!-- <input class="form-control"
        id="validationCustom02" type="number"
         [(ngModel)]="EmployeesCommissionReport.month"
        onlyNumbers> -->
        <ng-select 
        class="js-example-basic-single col-sm-12 job-select2 form-group"
          required=""
          aria-label="Default select example"
          (change)="SelectMonth($event?.name)"
          [items]="Months"
          bindLabel="name"
          bindValue="id"
           >
        
        </ng-select>
      </div>
        <div class="col-md-4 col-12">
            <label for="" style="margin: 5px;">السنه</label>
            <ng-select 
            class="js-example-basic-single col-sm-12 job-select2 form-group"
              required=""
              aria-label="Default select example"
              (change)="SelectYear($event?.name)"
              [items]="Years"
              bindLabel="name"
              bindValue="id"
               >
            
            </ng-select>
      
        </div>
        <div class="col-md-4 col-12">
 <div class="d-flex justify-content-center align-items-center buttom-text">
            <button class="addnew-btn" type="submit" (click)="submit()">عرض التقرير</button>
          </div>
        </div>
        
    </div>
<!-- </form> -->
</div>       
</div>
