import { UpdateAccomplishment } from './../Models/update-accomplishment';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment} from 'src/environments/environment.prod';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';

@Injectable({
  providedIn: 'root'
})
export class AchievementService {

  constructor(private http:HttpClient,private router:Router) { }


  GetAchievement(donationId): Observable<GenericResponseSingle<any>> {
    return this.http.get<GenericResponseSingle<any>>(`${environment.Server_URL}/Accomplishment/GetAccomplishments?donationId=${donationId}`);

  }

  AddAccomplishment(data?:UpdateAccomplishment): Observable<GenericResponseSingle<any>> {
    return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/Accomplishment/AddOrUpdateAccomplishment`, data);

  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }
}
