import { UpdateAccomplishment } from '../../../shared//Models/update-accomplishment';
import { Component, OnInit } from '@angular/core';
import { AchievementService } from './../../../shared/API-Service/achievement.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SmsTemplateService } from 'src/app/shared/API-Service/sms-template.service';
import { SendSMSComponent } from '../../admin/send-sms/send-sms.component';

@Component({
  selector: 'app-achievements',
  templateUrl: './achievements.component.html',
  styleUrls: ['./achievements.component.css']
})
export class AchievementsComponent implements OnInit {
id
achevement
_InsertAcheivement = new UpdateAccomplishment();
passdata
data:any
activePM:boolean = true
addcustomer:boolean;
closeResult = '';
  accomplishmentDescription: string;
  modal: boolean;
  DonationData: any;
  customermobile: any;
  SmsTemplates: any[];
  donationId: any;
  customerid: string;
  constructor(private SmsTemplateService : SmsTemplateService,private modalService: NgbModal,private achievementService:AchievementService,private ActivatedRoute:ActivatedRoute) {
//this.GetSMSTemplate();
    if(ActivatedRoute.snapshot.params.id){
      this.id = ActivatedRoute.snapshot.params.id
      this.modal=false;
    }
    else{
      this.id = localStorage.getItem("ModalData");
      this.modal=true;
      this.DonationData=JSON.parse(localStorage.getItem("DonationData"))
      this.customermobile=this.DonationData[0].customerMobile
      this.donationId=this.DonationData[0].donationId
    }
   }
   open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnInit(): void {
    this.achievementService.GetAchievement(this.id).subscribe(res=>{

  
      this.achevement = res.data
      if (res.data['accomplishmentDescription'] != null) {
        this.addcustomer = false
      }else{
        this.addcustomer = true
      }
    })
  }

  
  SendSMSTemplate(item?){
    localStorage.removeItem("donationId");
    localStorage.removeItem("customerid");
    if(item){

    localStorage.setItem("donationId",this.donationId);
    }
    else{
      localStorage.setItem("customerid",this.customerid);
    }
      //ModalComponent is component name where modal is declare
      const modalRef = this.modalService.open(SendSMSComponent);
      modalRef.result.then((result) => {
      
      }).catch((error) => {
    
      });
  }
  clickitem(item){     
    this.data = item.donationDetailId
    

  }


  addAccomplishment(item){
 Swal.fire({
              
              title: 'هل تريد اضافه انجاز جديد؟',
              showCancelButton: true,
              confirmButtonText: 'نعم',
              cancelButtonText: 'إلغاء',
              showLoaderOnConfirm: true,
              preConfirm: (id) => {
                this.passdata =  id;
                
     let accomplishmentDescription =  <HTMLInputElement>document.getElementById('accomplishmentDescription')
    let acivePMCheck = <HTMLInputElement>document.getElementById('pmActive');
    if (acivePMCheck.checked != true) {
      this._InsertAcheivement.customerInformed = false
    }else{
      this._InsertAcheivement.customerInformed = true
    }

var text= accomplishmentDescription.value.split('\n').join('\\n');
    this._InsertAcheivement.accomplishmentDescription = accomplishmentDescription.value
    this._InsertAcheivement.donationDetailId = this.data
    

    this.achievementService.AddAccomplishment(this._InsertAcheivement).subscribe(res=>{
      Swal.fire({
                  icon: 'success',
                  title: 'تم اضافه الانجاز بنجاح',
                  showConfirmButton: false,
                  timer: 1500
                })
        this.achievementService.reloadCurrentRoute()
    })
                
              }
            
            })

   
  }

}
