import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientApiService } from 'src/app/shared/API-Service/client-api.service';
import { CompaniesReport } from '../../../../shared//Models/companies-report';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-companies-report',
  templateUrl: './companies-report.component.html',
  styleUrls: ['./companies-report.component.css']
})
export class CompaniesReportComponent implements OnInit {
  Companies: any;
  currentUser: any;
  ReportsInfo = new CompaniesReport()

  constructor(private router: Router,public datepipe:DatePipe,private ClientApiService:ClientApiService) {
    this.currentUser = JSON.parse(localStorage.getItem("RiskCurrentUser"))
    this.ReportsInfo.reportCreatedBy = this.currentUser.name;
    const date = new Date();
    this.ReportsInfo.dateFrom=this.datepipe.transform(date, 'yyyy-MM-dd');
    this.ReportsInfo.dateTo=this.datepipe.transform(date,'yyyy-MM-dd');
   }
  GetClients() {
    this.ClientApiService.GetClient().subscribe(res => {
      this.Companies = res.data;
    })
  }

  selectedCompanies(event){
    
    this.ReportsInfo.companyId = event
    this.ReportsInfo.companyName = this.Companies.filter(x => x.id == event)[0].name

  }
  ngOnInit(): void {
    this.GetClients();
  }

  Viewreports() {
  
    if(this.ReportsInfo.companyId!=null&&this.ReportsInfo.dateFrom &&this.ReportsInfo.dateTo){
    localStorage["ReportName"] = "CompaniesReport";
    localStorage.setItem('ReportFilter', JSON.stringify(this.ReportsInfo));

    this.router.navigate([]).then((result) => {
      window.open("/content/admin/call-start-end-report", '_blank');
    });
  }
  else{
    if(!this.ReportsInfo.companyId){
      Swal.fire({
        icon:'error',
        title:'خطأ',
        text:'يجب اختيار الشركة'
  
      })
    }
  else   if (!this.ReportsInfo.dateFrom){
      Swal.fire({
        icon:'error',
        title:'خطأ',
        text:' يجب اختيار بدايه تاريخ المكالمات '
      })
    }
    else  if (!this.ReportsInfo.dateTo ){
      Swal.fire({
        icon:'error',
        title:'خطأ',
        text:' يجب اختيار نهايه تاريخ المكالمات '
  
      })
    }
}
}

}
