export class InsertBulkSms {
    name ?: String;
    DonationType?:number=null;
      Sectors? :number=null;
      SectorType?: number=null;
      SectorLocation?: number=null;
      Governorates?: number=null;
      Cities?:number=null;
      ClientType?: number=null;
      SourceMarket?: number=null;
      SmsTemplates:number=null;
}

