import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericResponse } from '../Models/GenericResponse';
import {getSectorLocation } from '../Models/GetSectorLocation';
import {GetSectorTypesInSector } from '../Models/GetSectorTypesInSector';

import { environment } from '../../../environments/environment.prod';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
import {  insertSectorLocation, UpdateSectorLocation } from '../Models/InsertSectorLocation';
import { GetSectorLocationInSectorTypes } from '../Models/GetSectorLocationInSectorTypes';

@Injectable({
  providedIn: 'root'
})
export class SectorLocationApiService {
  title:string;
  sectorTypeId:number;
  employeeId:number;
  constructor(private http:HttpClient) { }
  getSectorLocation(sectorTypeid?:number,data?:any): Observable<GenericResponse<GetSectorLocationInSectorTypes>> {
    if(sectorTypeid!=null)
    return this.http.get<GenericResponse<GetSectorLocationInSectorTypes>>(`${environment.Server_URL}/SectorLocation/GetSectorLocationInSectorTypes?id=${sectorTypeid}`);
    else
    return this.http.post<GenericResponse<GetSectorLocationInSectorTypes>>(`${environment.Server_URL}/SectorLocation/GetSectorLocationList`,data);
  }
  GetSectorLocationReportFilter(sectorTypeid?:number): Observable<GenericResponse<GetSectorLocationInSectorTypes>> {
    return this.http.get<GenericResponse<GetSectorLocationInSectorTypes>>(`${environment.Server_URL}/SectorLocation/GetSectorLocationReportFilter?id=${sectorTypeid}`);
  }
  GetSectorAllLocation(): Observable<GenericResponse<getSectorLocation>> {
    return this.http.get<GenericResponse<GetSectorLocationInSectorTypes>>(`${environment.Server_URL}/SectorLocation/Get`);
    
  }
  GetSectorLocationById (sectorLocationid?:number): Observable<GenericResponse<getSectorLocation>> {
    return this.http.get<GenericResponse<GetSectorLocationInSectorTypes>>(`${environment.Server_URL}/SectorLocation/GetById?id=${sectorLocationid}`);
    
  }

  InsertSectoeLocation(Data:insertSectorLocation): Observable<GenericResponseSingle<getSectorLocation>> {
    return this.http.post<GenericResponseSingle<getSectorLocation>>(`${environment.Server_URL}/SectorLocation/Create`,Data);
  }

  UpdateSectoeLocation(Data:UpdateSectorLocation): Observable<GenericResponseSingle<UpdateSectorLocation>> {
    return this.http.post<GenericResponseSingle<UpdateSectorLocation>>(`${environment.Server_URL}/SectorLocation/Update/`,Data);
  }

  DeleteSectorLocation(SectorLocationId:number): Observable<GenericResponseSingle<insertSectorLocation>> {
    return this.http.get<GenericResponseSingle<insertSectorLocation>>(`${environment.Server_URL}/SectorLocation/Delete?id=${SectorLocationId}`);
  }
}
