<app-breadcrumb [title]="'قائمة الدفاتر'" ></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header px-0" style="padding-bottom: 5px;">
              <div class="row m-0" >
                  <div class="col-12 col-md-9">
                    <h5 class="py-2">قائمة الدفاتر</h5>
                    <span  >  إعدادت قائمة الدفاتر موجودة بالنظام ( عرض - تعديل - حذف ) </span>
                  </div>
                  <div class="col-4 mt-3">
                    <div class="form-group">
                        <label for="name">اسم الشركة</label>
                        <ng-select  class="js-example-basic-single selectMargin"
                                formControlName="CompanyId"
                                aria-label="Default select example"
                                (change)="SelectCompany($event?.id)"
                                required="required"
                                [items]="Companies"
                                bindLabel="name"
                                bindValue="id"
                                 >
      
                        </ng-select>
                      </div>
                      </div>
                      <div class="col-4 col-md-4 mt-3 d-flex  align-items-center p-0">
                        <div class="btnSearch" (click)="Search()" >
                             <span class="mt-1">  بحث  </span>
                        </div>
                    </div>
                  <div class="col-4 col-md-4 d-flex justify-content-end align-items-center p-0">
                      <div  class="addnew-btn" appActionNew (click)="AddNew()">
                           
                            <i class="fa fa-plus "></i> <span class="mt-1">جديد</span>
                            <span class="tooltiptext">  إضافة دفتر جديدة</span>
                      </div>
                  </div>
              </div>
          
          </div>
          <div class="table-responsive ">
            <table class="table  table-striped" style="width: 100%;">
              <thead>
                <tr class="bg-dark ">
                  <th scope="col" class="font-style-header" >#</th>
                  <th scope="col" class="font-style-header" >ش</th>
                  <th scope="col" class="font-style-header" >اسم الشركة</th>
                  <th scope="col" class="font-style-header" >  رقم الدفتر </th>
                  <th scope="col" class="font-style-header" >  يبدأ ب </th>
                  <th scope="col" class="font-style-header" >  رقم ايصال من </th>
                  <th scope="col" class="font-style-header" >  رقم ايصال الي </th>
                  <th scope="col" class="font-style-header" >  النوع </th>
                  <th scope="col" class="font-style-header" > </th>
                </tr>
              </thead>
              <tbody >
                <tr *ngFor="let item of Receipts; let i = index;">
                  <td scope="row" class="font-style">{{i+1}}</td>
                  <td scope="row" class="font-style"><img  width="30px" height="30px" [src]="imgUrlServer+item.companyIcon"></td>
                  <td  class="font-style">{{item.companyName}}</td>
                  <td  class="font-style">{{item.bookNumber}}</td>
                  <td  class="font-style">{{item.prefix}}</td>
                  <td  class="font-style">{{item.receiptFrom}}</td>
                  <td  class="font-style">{{item.receiptTo}}</td>
                  <td  class="font-style">{{item.donationMainTypeName}}</td>
                  <td class="font-style" style="text-align: end; width: 8%;" [ngStyle]="{ 'background-color': item.companyColor  }"  >
                                          <button  href=""style="color: rgba(35, 118, 241, 0.667);padding: 7px; background-color: transparent;" appActionEdit (click)="Update(item.id)">
                          <i class="fa fa-pencil"></i>
                        </button>
                        <button  href=""style="color: red; padding: 7px; background-color: transparent;" appActionDelete (click)="Delete(item.id)">
                          <i class="fa fa-trash"></i>
                        </button>

                    </td>
                </tr>
              </tbody>
            </table>
            <br>
            <div class="d-flex justify-content-center">
                <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
                  firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
                  (pageChanged)="pageChanged($event)">
                </pagination>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>