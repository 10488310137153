import { Component, OnInit } from '@angular/core';
import { DxReportViewerModule } from 'devexpress-reporting-angular';
import { SourceOfmarketing } from 'src/app/shared/Models/source-ofmarketing';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-devViewer',
  templateUrl: './call-start-end-report.component.html',
  styleUrls: [
    "../../../../../../node_modules/jquery-ui/themes/base/all.css",

    "../../../../../../node_modules/devextreme/dist/css/dx.light.css",
    "../../../../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css",
    "../../../../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.light.css",
    "../../../../../../node_modules/devexpress-reporting/dist/css/dx-webdocumentviewer.css"
  ]
})
export class CallStartEndReportComponent {
  ReportsFiter = new SourceOfmarketing
  title = 'DXReportViewerSample';
  reportUrl: string = 'Reports/' + localStorage["ReportName"];
  //hostUrl: string = 'https://systemapi.azurewebsites.net/';
 hostUrl: string = 'https://systemapi.azurewebsites.net/';
  // Use this line if you use an ASP.NET MVC backend
  //invokeAction: string = "/WebDonncumentViewer/Invoke";
  // Use this line if you use an ASP.NET Core backend
  invokeAction: string = 'DXXRDV';
  parametersSubmitted(event) {
    var filter = localStorage.getItem("ReportFilter");
    this.ReportsFiter = JSON.parse(filter);
console.log(this.ReportsFiter)
console.log(event)

    // Object.keys(this.ReportsFiter).forEach
    //   (
        
    //     e => event.args.Parameters.filter(function (p) {return p.Key == e;  })[0].Value = this.ReportsFiter[e] != '' ? this.ReportsFiter[e] : null);
    //  }
    Object.keys(this.ReportsFiter).forEach(e => {
      const matchingParameters = event.args.Parameters.filter(p => p.Key === e);
      if (matchingParameters.length > 0) {
          const parameter = matchingParameters[0];
          parameter.Value = this.ReportsFiter[e] !== '' ? this.ReportsFiter[e] : null;
      } else {
        
      }
  });
}
}


