<div  *ngIf="LoadPage">
  <app-breadcrumb
[title]="'متابعة التبرعات'"
[items]="['Tables', 'Bootstrap Tables']"
[active_item]="'Basic'"
></app-breadcrumb>
<div class="card">
  <div class="card-header px-0" style="padding-bottom: 5px">
    <div class="row m-0">
    <div class="col-12 col-md-9">
    <h5 class="py-2">بحث</h5>
    </div>
    </div>
    </div>
    <div class="card-body">
      <div class="row">
    <div class="col-٦ col-md-6">
        <div class="form-group">
            <label for="">بدايه تاريخ التبرع </label>
           <input type="date"  class="form-control" [(ngModel)]="CollectedClosedDonations.donationDateFrom">
        </div>
    </div>
    <div class="col-12 col-md-6">
        <div class="form-group">

            <label for=""> نهايه تاريخ التبرع</label>

          
            <input type="date"  class="form-control"  [(ngModel)]="CollectedClosedDonations.donationDateTo">
        </div>
   </div>  
<div class="col-12 col-md-6">
    <label for="">بواسطه   </label>
      <ng-select
      class="js-example-basic-single"
      aria-label="Default select example"
     [(ngModel)]="CollectedClosedDonations.createdBy"
     [items]="Employees"
     bindLabel="name"
     bindValue="id"
      >                                 
    </ng-select>
  </div>

  <div class="col-12 col-md-6">
    <label for="">المحصل</label>
      <ng-select
      class="js-example-basic-single"
      aria-label="Default select example"
    [(ngModel)]="CollectedClosedDonations.collectorId"
     [items]="Collector"
     bindLabel="name"
     bindValue="id"
    >
    </ng-select>
  </div>
  
</div>
<div class="d-flex justify-content-end lign-items-center " >

  <button class="btn btn-primary"  style="margin-top: 41px;" type="submit" (click)="Search()">بحث</button>
</div>
    </div>
  </div>
<div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header px-0" style="padding-bottom: 5px;">
          <div class="col-xl-12 xl-100 col-lg-12">
            <div>
              <div>
                      <div class="table-responsive">
                        <table class="table" style="width: 100%">
                          <thead>
                            <tr class="bg-dark " >
                              <th scope="col" class="font-style-header text-center">#</th>
                              <th scope="col" class="font-style-header"  style="color: white;" >ش</th>
                              <th scope="col" class="font-style-header text-center">رقم التبرع</th>
                              <th scope="col" class="font-style-header text-center">تاريخ الإنشاء </th>
                              <th scope="col" class="font-style-header text-center">تاريخ التحصيل المتوقع </th>
                              <th scope="col" class="font-style-header text-center">تاريخ التحصيل الفعلي </th>
                              <th scope="col" class="font-style-header text-center">المبلغ</th>
                              <th scope="col" class="font-style-header text-center">حاله التبرع</th>
                              <th scope="col" class="font-style-header text-center">اسم العميل</th>
                              <th scope="col" class="font-style-header text-center">المدينة</th>
                              <th scope="col" class="font-style-header text-center">رقم العميل</th>
                              <th scope="col" class="font-style-header text-center">اسم المحصل</th>
                              <th scope="col" class="font-style-header text-center">بواسطة</th>
                              <!-- <th scope="col" class="font-style-header text-center">التعليقات</th>
                              <th scope="col" class="font-style-header text-center">الانجازات</th> -->
                              <th scope="col" class="font-style-header text-center"></th>

                            </tr>
                          </thead>
                          <tbody>
                            <ng-container *ngFor="let item of Dontations; let i = index;">
                              <tr (click)="OpenDetailes(i)" style="cursor:pointer; background-color: unset;">
                                <td scope="row" class="font-style">{{i+1}}</td>
                                <td scope="row" class="font-style"><img width="30px" height="30px" [src]="imgUrlServer+item.companyIcon"></td>
                                <td class="font-style text-center">{{item.donationSerial}}</td>
                                <td class="font-style text-center">{{item.creationDate | date:"dd/MM/yyyy"}}</td>
                                <td class="font-style text-center" style="width: 1%;">{{item.date | date:"dd/MM/yyyy"}}</td>
                                <td class="font-style text-center" style="width: 1%;">{{item.collectedDate ? (item.collectedDate | date:"dd/MM/yyyy"): '----' }}</td>
                                <td class="font-style text-center">{{item.totalAmount}}</td>
                                <td class="font-style text-center">{{item.statusName}}</td>
                                <td class="font-style text-center">{{item.customerName}}</td>
                                <td class="font-style" style="text-align: center;">{{item.cityName}}
                                  <br>
                                  <button style="color: #000;padding: 1px 2px;  background: transparent !important;" (click)="openAddress(item.customerAddress);$event.stopPropagation()">
                                    <i class="fa fa-home" style="font-size: 25px !important"></i>
                                  </button>
                                </td>
                                <td class="font-style" style="text-align: center;">{{item.mobile}}
                                  <br>
                                  <div ngbDropdown class="social-media" style="padding: 5px;">
                                    <a ngbDropdownToggle (click)="$event.stopPropagation()"><i class="fa fa-phone" style="color: green;"></i></a>
                                    <div ngbDropdownMenu aria-labelledby="dropdownConfig" >
                                      <a  (click)="$event.stopPropagation()"[href]="'tel:' + item.mobile" ngbDropdownItem>{{item.mobile}}</a>
                                          <ng-container  *ngFor="let mobile of item.mobiles">
                                            <a (click)="$event.stopPropagation()" [href]="'tel:' + mobile" ngbDropdownItem>{{mobile}}</a>
                                          </ng-container>
                                    </div>                
                                   <a [href]="'//wa.me/+2' + item.mobile" target="_blank"><i class="fa fa-whatsapp" style="padding: 0 17px; color: #2cc198;" ></i></a> 
                                   <i class="fa fa-comment" (click)="SendSMSTemplate(item)"></i>
                      
                                  </div>
                                </td>
                                <td class="font-style text-center">{{item.collectorName}}</td>
                                <td class="font-style text-center">{{item.createdByName}}</td>
                                <td class="font-style " style=" width: 60px; text-align: end;" [ngStyle]="{ 'background-color': item.companyColor  }"  >
                                  <button style="color: #000;padding: 1px 2px;  background: transparent !important;" (click)="openModal(item.donationId);$event.stopPropagation()">
                                    <i class="fa fa-commenting-o" style="font-size: 25px !important"></i>
                                  </button>
                                    <button *ngIf="item.status==3|| item.status==4" style="color: red; padding: 7px;background-color: transparent;"
                                 (click)="$event.stopPropagation(); GoToAccomplishment(item.donationId);" toolTipe="انجازات">
                                    <i class="fa fa-address-card-o"></i>
                                  </button>
                                  <i *ngIf="!item.ShowDetails" class="fa fa-angle-down colapse-icon" ></i>
                                  <i *ngIf="item.ShowDetails" class="fa fa-angle-up colapse-icon" ></i>
                                </td>
                              </tr>
                              <tr style="text-align: center;">
                                <td style="border-left: none; "></td>
                                <td style="border-left: none;border-right: none;"></td>
                                <td colspan="12" *ngIf="item.ShowDetails" style="border-right: none;">
                                  <table *ngIf="item.donationTypeName!='عيني'" style="width: 102.59%;
                                   margin-right: -8px;
                                  margin-bottom: 10px;">
                                    <thead>
                                    </thead>
                                    <tr class="bg-danger" style="color: black !important">
                                      <th scope="col" class="font-style-header text-center">#</th>
                                      <th scope="col" class="font-style-header text-center">فئه التبرع</th>
                                      <th scope="col" class="font-style-header text-center">نوع النشاط</th>
                                      <th scope="col" class="font-style-header text-center">نوع المشروع </th>
                                      <th scope="col" class="font-style-header text-center"> مكان المشروع</th>
                                      <th scope="col" class="font-style-header text-center">المبلغ</th>
                                      <th *ngIf="receiptNumView" scope="col" class="font-style-header text-center">رقم الفاتوره
                                      </th>
                                    </tr>
                                    <tbody style="background-color: white !important;">
                                      <tr *ngFor="let donation of item.details let i = index" style=" background-color: #e7e6e6;">
                                        <td scope="row" class="font-style text-center">{{i+1}}</td>
                                        <td class="font-style text-center">{{donation.donationTypeName}}</td>
                                        <td class="font-style text-center"> {{donation.sectorName}}</td>
                                        <td class="font-style text-center">{{donation.sectorTypeName}}</td>
                                        <td class="font-style text-center">{{donation.sectorLocationName}}</td>
                                        <td class="font-style text-center">{{donation.smallAmount}}</td>
                                        <td class="font-style text-center" *ngIf="receiptNumView" >{{donation.receiptNum}}</td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table *ngIf="item.donationTypeName=='عيني'" style="width: 102.59%;
                                  margin-right: -8px;
                                 margin-bottom: 10px;">
                                    <thead>
                                    </thead>
                                    <tr class="bg-danger" style="color: black;">
                                      <th scope="col" class="font-style-header text-center">#</th>
                                      <th scope="col" class="font-style-header text-center">فئه التبرع
                                      </th>
                                      <th scope="col" class="font-style-header text-center">نوع التبرع
                                      </th>
                                      <th scope="col" class="font-style-header text-center">العدد</th>
                                      <th *ngIf="receiptNumView" scope="col" class="font-style-header text-center">رقم الفاتوره
                                      </th>
                                    </tr>
                                    <tbody style="background-color: white !important;">
                                      <tr style="justify-content:initial"
                                        *ngFor="let donation of item.details let i = index" style=" background-color: #e7e6e6;">
                                        <td class="font-style text-center" scope="row">{{i+1}}</td>
                                        <td class="font-style text-center">{{donation.donationCategoryName}}</td>
                                        <td class="font-style text-center">{{donation.donationTypeName}}</td>
                                        <td class="font-style text-center">{{donation.quantity}}</td>
                                        <td class="font-style text-center" *ngIf="receiptNumView">{{donation.receiptNum}}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                        <br>
                        <div class="d-flex justify-content-center">
                          <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
                            firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
                            (pageChanged)="pageChanged($event)">
                          </pagination>
                        </div>

                      </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
