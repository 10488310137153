import { DatePipe } from '@angular/common';
import { SourceOfmarketingService } from './../../../../shared/API-Service/source-ofmarketing.service';
import { DonationRequestsService } from './../../../../shared/API-Service/donation-requests.service';
import { Component, OnInit } from '@angular/core';
import { CallReasonsCalls } from '../../../../shared/Models/CallReasonsCalls';
import { CallReasonApiService } from 'src/app/shared/API-Service/call-reason-api.service';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-call-reasons-calls-report',
  templateUrl: './call-reasons-calls-report.component.html',
  styleUrls: ['./call-reasons-calls-report.component.css']
})
export class CallReasonsCallsReportComponent implements OnInit {
  Getcollectors: any[];
  CallReasonsCalls= new CallReasonsCalls();
  SectorLocation: any;
  getType: any;
  SectorGet: any[];
  City_list: any;
  GetLookupsGetDonation: any;
  DonationType: any;
  getsourcemarket: any[];
  CallReason_List: any[];
  callType: { id: number; title: string; }[];
  currentUser: any;
  Companies: any[];
  getEmployee: any[];
  Answered:boolean=false;
  AnsweredList: any[];
  constructor(private DonationRequestsService:DonationRequestsService,
    private SourceOfmarketingService:SourceOfmarketingService,public datepipe:DatePipe,private router:Router,
    private CallReasonService: CallReasonApiService,
    private CompanyDataService:CompanyDataService
    ) { 
      this.CallReasonsCalls.answered=null
      this.callType=[
        {
        id:1,
        title:"صادرة"
        },
        {
            id:2,
            title:"واردة"
        }

]
this.AnsweredList=[
  {
    id:1,
    title:"تم الرد"
    },
    {
        id:2,
        title:"لم يتم الرد"
    }
]
this.currentUser=JSON.parse(localStorage.getItem("RiskCurrentUser"))
if(this.currentUser.roleId==10){
  this.CallReasonsCalls.companyId=this.currentUser.id
}
this.CallReasonsCalls.reportCreatedBy=this.currentUser.name;
const date = new Date();
      this.CallReasonsCalls.callDateFrom=this.datepipe.transform(date, 'yyyy-MM-dd');
      this.CallReasonsCalls.callDateTo=this.datepipe.transform(date,'yyyy-MM-dd');

    }
    SelectAnswered(AnsweredId){
    if(AnsweredId==1){
      this.CallReasonsCalls.answered=true
    }
    else if (AnsweredId==2){
      this.CallReasonsCalls.answered=false
    }
    else{
      this.CallReasonsCalls.answered=null

    }

    }
    SelectCompany(CompanyId){
      this.CallReasonsCalls.companyId=parseInt(CompanyId);

    }
    GetCompanies(){
      this.CompanyDataService.GetCompanies().subscribe(res=>{
        this.Companies=res.data
      })
    }
  ngOnInit(): void {
    this.GetCompanies()
   this.getcollectors();
   this.getsourcemarketing();
   this.GetCallReason();
   this.Getemployee();

  }
  selectEmplyee(event){
    this.CallReasonsCalls.createdById = parseInt(event) 
    this.CallReasonsCalls.createdByName = this.getEmployee.find(x => x.id == event).name
   }
  
  Getemployee(){
    this.SourceOfmarketingService.EmployeeGet().subscribe(res=>{
      this.getEmployee = res.data
    })
   }
  getcollectors(){
    this.DonationRequestsService.Getcollectors().subscribe(response=>{

      this.Getcollectors = response.data
    })
  }
  getsourcemarketing(){
    this.SourceOfmarketingService.GetAllsourceOfMarketingForReport().subscribe(response=>{
     
          this.getsourcemarket = response.data
    })
  }
  
  selectSourceMarket(event){
    if(event){
          this.CallReasonsCalls.sourceOfMarktingId = parseInt(event) 
    this.CallReasonsCalls.sourceOfMarketingName = this.getsourcemarket.find(x => x.id == event).title

    }
  }
  
  
  selectCallType(event){
    if(event){
          this.CallReasonsCalls.callTypeId = parseInt(event) 
    this.CallReasonsCalls.callTypeName = this.callType.find(x => x.id == event).title

    }
  }
  GetCallReason() {
    this.CallReasonService.GetCallReason().subscribe(
      response => {
        this.CallReason_List = response.data;        
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }

Viewreports(){
    if(this.CallReasonsCalls.companyId!=null&&this.CallReasonsCalls.callDateFrom &&this.CallReasonsCalls.callDateTo){
  
  if(this.CallReasonsCalls.callTypeId==null){
    this.CallReasonsCalls.callTypeId=null;
    this.CallReasonsCalls.callTypeName=""
  }
  if(this.CallReasonsCalls.sourceOfMarktingId==null){
    this.CallReasonsCalls.sourceOfMarktingId=null;
    this.CallReasonsCalls.sourceOfMarketingName=""
  }
  if(this.CallReasonsCalls.createdById==null){
    this.CallReasonsCalls.createdById=null;
    this.CallReasonsCalls.createdByName=""
  }

  localStorage["ReportName"]="CallReasonsCallsReport";
  localStorage.setItem('ReportFilter',JSON.stringify(this.CallReasonsCalls));

  this.router.navigate([]).then((result) => {
    window.open("/content/admin/call-start-end-report", '_blank');
  });
}
else{
  if(!this.CallReasonsCalls.companyId){
    Swal.fire({
      icon:'error',
      title:'خطأ',
      text:'يجب اختيار الشركة'

    })
  }
else   if (!this.CallReasonsCalls.callDateFrom){
    Swal.fire({
      icon:'error',
      title:'خطأ',
      text:' يجب اختيار بدايه تاريخ المكالمات'
    })
  }
  else  if (!this.CallReasonsCalls.callDateTo ){
    Swal.fire({
      icon:'error',
      title:'خطأ',
      text:' يجب اختيار نهايه تاريخ المكالمات'

    })
  }
}
}



}
