<ng-template #content let-modal>
	<div class="modal-header h-50">
		<h4 class="modal-title" id="modal-basic-title">الايصالات</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body p-0">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body p-0">
                <div class="table-responsive ">
                    <table class="table  table-striped" style="width: 100%;">
                      <thead>
                        <tr class="bg-dark ">
                          <th scope="col" class="font-style-header" >#</th>
                          <th scope="col" class="font-style-header" >  رقم الايصال </th>
                          <th scope="col" class="font-style-header" > حالة الايصال </th>
                        </tr>
                      </thead>
                      <tbody >
                        <tr *ngFor="let item of Receipts; let i = index;">
                          <td scope="row" class="font-style">{{i+1}}</td>
                          <td  class="font-style">{{item.number}}</td>
                          <td  class="font-style">{{item.receiptStatusName}}</td>
                     
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-center">
                     <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCountReceipts" previousText="&lsaquo;" nextText="&rsaquo;"
                       firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="ReceiptspageNumber"
                       (pageChanged)="pageChangedReceipts($event)">
                     </pagination>
                   </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
	</div>
	<!-- <div class="modal-footer">
		<button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
	</div> -->
</ng-template>
<app-breadcrumb [title]="'ربط الدفاتر بالمحصليين'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'">
</app-breadcrumb>
<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header px-0" style="padding-bottom: 5px;">
              <div class="row m-0" >
                  <div class="col-12 col-md-12 text-center" >
                    <h5 class="py-2"> المندوب : {{CollectorName}}</h5>
                    <!-- <span  >  إعدادت قائمة المحصلين الموجودة بالنظام ( عرض - تعديل - حذف ) </span> -->
                  </div>
          </div>
          <ngb-tabset (tabChange)="onTabChange($event)" class="tabset" #tabset id="tabset" #ctdTabset="ngbTabset"
          >
          
          <ngb-tab  title="إضافة دفاتر"
            class=" justify-content-around nav nav-pills nav-primary w-25">
            <!-- <ngb-tab title="Simple"> -->
          
          
          
            <ng-template ngbTabContent>
            
              <div class="table-responsive ">
                  <table class="table  table-striped" style="width: 100%;">
                    <thead>
                      <tr class="bg-dark ">
                        <th scope="col" class="font-style-header" >#</th>
                        <th scope="col" class="font-style-header" >  رقم الدفتر </th>
                        <th scope="col" class="font-style-header" >  النوع </th>
                        <th scope="col" class="font-style-header" >  الايصالات المستخدمة </th>
                        <th scope="col" class="font-style-header" > الايصالات الملغية </th>
                        <th scope="col" style="width: 20%;" class="font-style-header" >  الايصالات المتاحة </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let item of AllCollectorReceipts; let i = index;">
                        <td scope="row" class="font-style">{{i+1}}</td>
                        <td  class="font-style">{{item.bookNumber}}</td>
                        <td  class="font-style">{{item.donationMainTypeName}}</td>
                        <td  class="font-style">
                          <div class="row">
                              <div class="col-sm">           
                                    {{item.usedCount}} ايصال
                              </div>
                              <div class="col-sm" style="text-align: end;"> <button  href=""style="color: rgba(35, 118, 241, 0.667);padding: 7px; background-color: transparent;" (click)="openReceiptsAllBooks(content,item.id,2)">
                                  <i style="font-size: larger;" class="fa fa-eye"></i>
                                </button></div>
                             
                   
                          </div>
                        </td>
                        <td  class="font-style">
                          <div class="row">
                              <div class="col-sm">           
                                   {{item.cancelledCount}} ايصال
                              </div>
                              <div class="col-sm" style="text-align: end;"> <button  href=""style="color: rgba(35, 118, 241, 0.667);padding: 7px; background-color: transparent;" (click)="openReceiptsAllBooks(content,item.id,3)">
                                  <i style="font-size: larger;" class="fa fa-eye"></i>
                                </button></div>
                             
                   
                          </div>
          
                        </td>
                        <td  class="font-style" style="width: 15%;" >
                          <div class="row">
                              <div class="col-sm">           
                                   {{item.freeCount}} ايصال
                              </div>
                              <div class="col-sm" style="text-align: end;"> <button  href=""style="color: rgba(35, 118, 241, 0.667);padding: 7px; background-color: transparent;"(click)="CreateCollectorReceipts(item)">
                                  <i style="color: green;
                                  font-size: larger;" class="fa fa-plus"></i>
                                </button></div>
                             
                   
                          </div>
                         
                        </td>
                   
                      </tr>
                    </tbody>
                  </table>
                  <div class="d-flex justify-content-center">
                   <pagination  [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
                     firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="AllBookspageNumber"
                     (pageChanged)="pageChangedAllBooks($event)">
                   </pagination>
                 </div>
                </div>
          
          
          
            </ng-template>
          </ngb-tab>
          <ngb-tab  title="عرض وتعديل "
            class=" justify-content-around nav nav-pills nav-primary w-25">
            <!-- <ngb-tab title="Simple"> -->
          
          
          
            <ng-template ngbTabContent>
              <div class="table-responsive ">
                  <table class="table  table-striped" style="width: 100%;">
                    <thead>
                      <tr class="bg-dark ">
                        <th scope="col" class="font-style-header" >#</th>
                        <th scope="col" class="font-style-header" >  رقم الدفتر </th>
                        <th scope="col" class="font-style-header" >  النوع </th>
                        <th scope="col" class="font-style-header" >  الايصالات المستخدمة </th>
                        <th scope="col" class="font-style-header" > الايصالات الملغية </th>
                        <th scope="col" style="width: 20%;" class="font-style-header" >  الايصالات المتاحة </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let item of CollectorReceipts; let i = index;">
                        <td scope="row" class="font-style">{{i+1}}</td>
                        <td  class="font-style">{{item.bookNumber}}</td>
                        <td  class="font-style">{{item.donationMainTypeName}}</td>
                        <td  class="font-style">
                          <div class="row">
                              <div class="col-sm">           
                                   {{item.usedCount}} ايصال
                              </div>
                              <div class="col-sm" style="text-align: end;"> <button  href=""style="color: rgba(35, 118, 241, 0.667);padding: 7px; background-color: transparent;"(click)="openReceiptsCollectorBooks(content,item.id,2)">
                                  <i style="font-size: larger;" class="fa fa-eye"></i>
                                </button></div>
                             
                   
                          </div>
                        </td>
                        <td  class="font-style">
                          <div class="row">
                              <div class="col-sm">           
                                    {{item.cancelledCount}} ايصال
                              </div>
                              <div class="col-sm" style="text-align: end;"> <button  href=""style="color: rgba(35, 118, 241, 0.667);padding: 7px; background-color: transparent;"(click)="openReceiptsCollectorBooks(content,item.id,3)">
                                  <i style="font-size: larger;" class="fa fa-eye"></i>
                                </button></div>
                             
                   
                          </div>
          
                        </td>
                        <td  class="font-style" style="width: 15%;" >
                          <div class="row">
                              <div class="col-sm">           
                                      {{item.availableCount}} ايصال
                              </div>
                              <div class="col-sm" *ngIf="item.availableCount!=0" style="text-align: end;"> <button  href=""style="color: rgba(35, 118, 241, 0.667);padding: 7px; background-color: transparent;"(click)="Delete(item)">
                                  <i style="color: rgb(238, 6, 6);
                                  font-size: larger;" class="fa fa-times "></i>
                                </button></div>
                             
                   
                          </div>
                         
                        </td>
                   
                      </tr>
                    </tbody>
                  </table>
                  <div class="d-flex justify-content-center">
                   <pagination  *ngIf="!openReceiptsCollectorBooksCheck" [boundaryLinks]="true" [totalItems]="totalCountCollectorBooks" previousText="&lsaquo;" nextText="&rsaquo;"
                     firstText="&laquo;" lastText="&raquo;" [maxSize]="20" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="CollectorBookspageNumber"
                     (pageChanged)="pageChangedCollectorBooks($event)">
                   </pagination>
                 </div>
                </div>
          
          
          
            </ng-template>
          </ngb-tab>
          
          
          </ngb-tabset>
        </div>
      </div>
    </div>
  </div>
</div>
   <!-- <div class="card">
    <div class="card-body">
      <div class="row" style="    display: flex;
    justify-content: center;">
    <h3> المندوب : {{CollectorName}}</h3>

      </div>

    </div>
  </div>
   <div class="card">

</div> -->