import { Getjobs } from '../../../../../shared//Models/getjobs';
import { JobsService } from './../../../../../shared/API-Service/jobs.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { extend } from 'chartist';
import { BaseComponent } from 'src/app/shared/components/base/base.component';

@Component({
  selector: 'app-list-jobs',
  templateUrl: './list-jobs.component.html',
  styleUrls: ['./list-jobs.component.css']
})
export class ListJobsComponent extends BaseComponent implements OnInit {
  Response_List: any;
 
  constructor(private router:Router,private JobsService:JobsService) {
    super()
   }

  ngOnInit(): void {
    this.Response_List = [];
    this.getGovernoate()
  }

  pageChanged(event){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;  
    this.JobsService.GetJobsList(this.pager).subscribe(
      response => {
        this.Response_List = response.data;
        this.totalCount = this.Response_List.totalCount;
       this.Response_List=this.Response_List.items;
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
    // newLocl._ShipmentService.Get(data).subscribe((res)=>{
    //   this.response=res.data;
    //   this.totalItems = this.response.totalCount;
    //   this.response=this.response.items;
    // })
  }
  getGovernoate() {
  
    this.JobsService.GetJobsList(this.pager).subscribe(
      response => {
        this.Response_List = response.data;
       this.totalCount = this.Response_List.totalCount;
      this.Response_List=this.Response_List.items;
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }


  AddNew(){
    this.router.navigateByUrl("content/admin/InsertJobs");
  }


  Delete(id:number){
    Swal.fire({
      title: ' تحذير !',
      text: "هل انت متأكد من حذف هذا العنصر ؟ ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7364f6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'حذف',
      cancelButtonText: 'إنهاء',
    })
    .then((result) => {

      if (result.isConfirmed) {
          this.JobsService.deleteJobs(id).subscribe(
            response=>{
              
               Swal.fire({
                    icon: 'success',
                    title: "تم حذف نوع الوظيفه بنجاح",
                    showConfirmButton: false,
                    timer: 1500}) 
                   return  this.getGovernoate();
                  },
            err=>{
              Swal.fire({
                icon: 'error',
                title: 'خطأ',
                text: err.error.message,
              })
            }
          )
        
      } else {
        // Swal.fire(
        //   'Your appointment still active ',
        // );
      }
    }); 
  }


  update( id:number,title:string){
    this.JobsService.title = title;
    localStorage.setItem("riskJobs",title)
   this.router.navigate([`content/admin/updateJobs/${id}`]);
}


}
