import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericResponse } from '../Models/GenericResponse';
import { getSectorType } from '../Models/get-sector-type';
import { environment } from '../../../environments/environment.prod';
import { InsertClientType } from '../Models/insert-client-type';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
import {  GetAllSector} from '../Models/get-all-sector';
import {  insertSectorType, UpdateSectorType } from '../Models/insert-sector-type';
import { GetSectorTypesInSector } from '../Models/GetSectorTypesInSector';

@Injectable({
  providedIn: 'root'
})
export class SectorTypeApiService {
  title:string;
  sectorId:number;
  constructor(private http:HttpClient) { }
    
     
      //  getSectorType(): Observable<GenericResponse<GetSectorTypesInSector>> {
      //   return this.http.get<GenericResponse<GetSectorTypesInSector>>(`${environment.Server_URL}/SectorType/Get`);
      // }

      getSectorType(sectorid?:number,companyId?:any,filterByPM?:any,data?:any): Observable<GenericResponse<GetSectorTypesInSector>> {
        if(sectorid!=null){
          if(filterByPM!=null){
            return this.http.get<GenericResponse<GetSectorTypesInSector>>(`${environment.Server_URL}/SectorType/GetSectorTypesInSector?id=${sectorid}&companyId=${companyId}&filterByPM=${filterByPM}`);

          }
          else{
            return this.http.get<GenericResponse<GetSectorTypesInSector>>(`${environment.Server_URL}/SectorType/GetSectorTypesInSector?id=${sectorid}&companyId=${companyId}`);

          }

        }
        else if(data!=null){
                  return this.http.post<GenericResponse<GetSectorTypesInSector>>(`${environment.Server_URL}/SectorType/GetSectorTypeList`,data);

        }

        else{
return this.http.get<GenericResponse<GetSectorTypesInSector>>(`${environment.Server_URL}/SectorType/Get`,);
        }      


      }
      
      GetSectorTypeById(sectorTypeId?:number):Observable<GenericResponse<any>>{
        if(sectorTypeId!=null)
        return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/SectorType/GetById?id=${sectorTypeId}`);
        else
        return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/SectorType/Get`);
        
      }
    
      InsertSectorTypes(Data:insertSectorType): Observable<GenericResponseSingle<any>> {
        return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/SectorType/Create`,Data);
      }
  
      UpdateSectorTypes(Data:UpdateSectorType): Observable<GenericResponseSingle<UpdateSectorType>> {
        return this.http.post<GenericResponseSingle<UpdateSectorType>>(`${environment.Server_URL}/SectorType/Update/`,Data);
      }
  
      DeleteSectorTypes(SectorTypeId:number): Observable<GenericResponseSingle<insertSectorType>> {
        return this.http.get<GenericResponseSingle<insertSectorType>>(`${environment.Server_URL}/SectorType/Delete?id=${SectorTypeId}`);
        // change detete
      }
}
