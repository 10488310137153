import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AchievementsComponent } from 'src/app/components/Agent/achievements/achievements.component';
import { ProjectsService } from 'src/app/shared/API-Service/projects.service';
import { SectorLocationApiService } from 'src/app/shared/API-Service/sector-location-api.service';
import { SectorTypeApiService } from 'src/app/shared/API-Service/sector-type-api.service';
import { SectorsApiService } from 'src/app/shared/API-Service/sectors-api.service';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { CustomerAccomplishmentComponent } from '../../Media/customer-accomplishment/customer-accomplishment.component';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';
import { environment } from 'src/environments/environment.prod';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-all-projects',
  templateUrl: './all-projects.component.html',
  styleUrls: ['./all-projects.component.css']
})

export class AllProjectsComponent extends BaseComponent implements OnInit {
  Response_List: any;
  response: any;

  SearchForm: FormGroup;
  customerInformed:boolean = false
  Sector_Type_List: any[]=[];
  Sector_Type_id: any;
  Sectors:  any[]=[];
  SectorLocation: any[]=[];
  CallCustomerList=[{id:1,title:"الكل"},
  {id:2,title:"تم الاتصال"},
  {id:3,title:"لم يتم الاتصال"}
]
imgUrlServer=environment.Server_URL_Image+'/'
  Companies: any;
  CompanyId: any;
  constructor(private CompanyDataService:CompanyDataService ,private SectorLocationApiService:SectorLocationApiService,private SectorsApiService :SectorsApiService,public modalService: NgbModal,private router: Router,private ProjectsService:ProjectsService,private _formBuilder: FormBuilder,private sectorTypeApiService: SectorTypeApiService) { 
  super()
  }
  ngOnInit(): void {
     this.GetCompanies();
     this._InitForm();
      }
      GetSector(CompanyId:any){
        this.SectorsApiService.getSectors(CompanyId).subscribe(res=>{
          this.Sectors=res.data
        })
      }
      GetCompanies(){
        this.CompanyDataService.GetCompanies().subscribe(res=>{
          this.Companies=res.data
        })
      }
      _InitForm() {
        this.SearchForm = this._formBuilder.group({
          customerMobile: [null, Validators.required],
          donationSerial: [null, Validators.required],
          SectorTypeId: [null, Validators.nullValidator],
          SectorId: [null, Validators.nullValidator],
          CompanyId:[null,Validators.required],
          SectorLoctaionId: [null, Validators.nullValidator],
          CallCustomer:[null, Validators.nullValidator],
          DateFrom:[null, Validators.nullValidator],
          DateTo:[null, Validators.nullValidator],
        
        });
      }
      pageChanged(event:any){
        this.pageNumber = event.page;// -1 * pageSize;
        this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
        this.ProjectsService.GetAllProjectManagerDonation(this.pager).subscribe(
          response => {
            this.response = response;
            this.Response_List = response.data;
            this.totalCount = this.Response_List.totalCount;
            this.Response_List=this.Response_List.items;            
          },
          err => {
            Swal.fire({
              icon: 'error',
              title: 'خطأ',
              text: err.error.message,
            })
          }
        )
    
      }
      
      SelectCompany(CompanyId){
        this.CompanyId=CompanyId
        this.GetSector(CompanyId)
      }
      SelectSector(e){
        this.SectorLocation=[]
        this.Sector_Type_List=[]
        this.SearchForm.patchValue({
          SectorTypeId: null
        })
        this.SearchForm.patchValue({
          SectorLocationId: null
        })
        this.getSectorType(e);

      }
      getSectorType(SectorId) {
        this.sectorTypeApiService.getSectorType(SectorId,this.CompanyId).subscribe(
          response => {
            this.Sector_Type_List = response.data;
            // response.data.forEach(element => {
            //   this.Sector_Type_Dictionary[element.id] = element.title;            
            // });
          },
          err => {
            Swal.fire({
              icon: 'error',
              title: 'خطأ',
              text: err.error.message,
            })
          }
        )
      }
      getSectorLocation(SectorTypeId){
        this.SectorLocationApiService.getSectorLocation(SectorTypeId).subscribe(res=>{
          this.SectorLocation=res.data
        })
      }
    
      SelectedSectorType(event:any){
        this.Sector_Type_id = event;
        this.SectorLocation=[]
        this.SearchForm.patchValue({
          SectorLocationId: null
        })
        this.getSectorLocation(this.Sector_Type_id);
      }

      SelectedCallCustomerList(event:any){
        if(event==1){
          this.customerInformed=null;
        }
        else if (event==2){
          this.customerInformed=true
        }
        else{
          this.customerInformed=false
        }
      }
      onSubmit(){
        this.pageNumber=1
    this.pager.skipCount=0
    this.pager.customerInformed=this.customerInformed;
    if(!this.SearchForm.get("customerMobile").value){
      this.SearchForm.get("customerMobile").reset();
    }
    if(!this.SearchForm.get("donationSerial").value){
      this.SearchForm.get("donationSerial").reset();
    }
    if(!this.SearchForm.get("DateFrom").value){
      this.SearchForm.get("DateFrom").reset();
    }
    if(!this.SearchForm.get("DateTo").value){
      this.SearchForm.get("DateTo").reset();
    }
    this.pager.customerMobile=this.SearchForm.get("customerMobile").value
    this.pager.donationSerial= this.SearchForm.get("donationSerial").value
    this.pager.sectorId= this.SearchForm.get("SectorId").value
    this.pager.CompanyId=this.SearchForm.get('CompanyId').value
    this.pager.sectorTypeId=this.SearchForm.get("SectorTypeId").value
    this.pager.sectorLocationId= this.SearchForm.get("SectorLoctaionId").value
    this.pager.customerInformed= this.customerInformed;
    this.pager.dateFrom=this.SearchForm.get("DateFrom").value;
    this.pager.dateTo=this.SearchForm.get("DateTo").value;
        this.GetProjectManagerDonation(this.pager)
      }
      GetProjectManagerDonation(data) {
    
        this.ProjectsService.GetAllProjectManagerDonation(data).subscribe(
          response => {
            this.response = response;
            this.Response_List = response.data;
            this.totalCount = this.Response_List.totalCount;
            this.Response_List=this.Response_List.items;            
          },
          err => {
            Swal.fire({
              icon: 'error',
              title: 'خطأ',
              text: err.error.message,
            })
          }
        )
      }

     

    GoToCustomerAccomplishment(donationId,CompanyIcon,CompanyName,CompanyId){
  
      this.CompanyDataService.CompanyInfo.CompanyIcon=CompanyIcon
      this.CompanyDataService.CompanyInfo.CompanyName=CompanyName
      this.CompanyDataService.CompanyInfo.CompanyId=CompanyId
      localStorage.setItem("CompanyIcon",CompanyIcon)
      localStorage.setItem("CompanyName",CompanyName)
      localStorage.setItem("CompanyId",CompanyId)
      this.router.navigate(['content/admin/customer-accomplishment', donationId]);
    }
}
