<app-breadcrumb [title]="'تقرير المكالمات'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'">
</app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
            <!-- <form action="" [formGroup]="createReports"> -->
                <div class="card-header px-0 " style="padding-bottom: 5px;">
                    <div class="row m-0 mb-2">
                      <div class="col-12 col-md-4">
                        <label for="validationCustom02"><span class="validation-required">*</span>  اسم الشركة</label>
                        <br>
                        <label *ngIf="currentUser.roleId==10">{{currentUser.name}}</label>
                  <ng-select *ngIf="currentUser.roleId!=10"
                  class="js-example-basic-single col-sm-12 job-select2 form-group"
                    required=""
                    [(ngModel)]="GetCallCustomerDate.companyId"
                    aria-label="Default select example"
                    (change)="SelectCompany($event.id)"
                    [items]="Companies"
                    bindLabel="name"
                    bindValue="id"
                     >
                  
                  </ng-select>
                      </div>
                        <div class="col-md-4">
                          <label for=""> بدايه تاريخ المكالمات
                          </label>
                            <div class="form-group">
                                
                               <input type="date"  class="form-control" [(ngModel)]="GetCallCustomerDate.callDateFrom">
                            </div>
                        </div>
                        <div class="col-md-4">
                          <label for="">نهايه تاريخ المكالمات</label>
                            <div class="form-group">
                             
                               <input type="date"  class="form-control" [(ngModel)]="GetCallCustomerDate.callDateTo">
                            </div>
                        </div>
                  
                        <div class="col-12 col-md-4">
                            <label for=""> مصدر اعلان</label>
                            <div class="form-group">

                            <ng-select
                            class="js-example-basic-single"
                            aria-label="Default select example"
                            (change)="selectSourceMarket($event?.id)"
                            [(ngModel)]="GetCallCustomerDate.sourceOfMarktingId"
                            [items]="getsourcemarket"
                            bindLabel="title"
                            bindValue="id"
                            >
                          </ng-select>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <label>سبب المكالمة</label>
                            <div class="form-group">

                            <ng-select class="js-example-basic-single"
                            [(ngModel)]="GetCallCustomerDate.callReasonId"
                            (change)="selectCallReason($event?.id)"
                                aria-label="Default select example" 
                                [items]="CallReason_List"
                                bindLabel="title"
                                bindValue="id"
                                >
                            </ng-select>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <label>نوع المكالمة</label>
                            <div class="form-group">
                            <ng-select
                            class="js-example-basic-single"
                              [(ngModel)]="GetCallCustomerDate.callTypeId"
                              (change)="selectCallType($event.id)"
                              aria-label="Default select example"
                              [items]="callType"
                              bindLabel="title"
                              bindValue="id"
                              >
                            </ng-select>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 mb-3">
                          <div class="" style="display: flex; justify-content: start;"><label for="">اسم الموظف</label></div>
                          
                          <ng-select  class="js-example-basic-single" (change)="selectEmplyee($event.id)" [(ngModel)]="GetCallCustomerDate.createdById"
                          
                          [items]="getEmployee"
                          bindLabel="name"
                          bindValue="id"
                          >
                          </ng-select>
                      </div>
                      <div class="col-12 col-md-6 mb-3">
                        <div class="mb-xl-n3" style="display: flex; justify-content: start; "><label for="">حالة الرد</label></div>
                        <br>
                        <ng-select *ngIf="currentUser.roleId!=10"
                        class="js-example-basic-single col-sm-12 job-select2 form-group"
                          required=""
                          aria-label="Default select example"
                          (change)="SelectAnswered($event?.id)"
                          [items]="AnsweredList"
                          bindLabel="title"
                          bindValue="id"
                           >
                        
                        </ng-select>
                      </div>
                     
                    <div class="d-flex justify-content-end align-items-center buttom-text col-12 mb-3">
                      <button class="addnew-btn" type="submit" (click)="Viewreports()">عرض التقرير</button>
                    </div>
                </div>
            <!-- </form> -->
    </div>
</div>
        </div>
    </div>
</div>