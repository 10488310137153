import { displayPrevious } from './../../../../shared/data/charts/google-chart';
import { knob, ChartOptions } from './../../../../shared/data/dashboard/default';
import { Statsdata } from '../../../../shared//Models/statsdata';
import { StattsReportsService } from './../../../../shared/API-Service/statts-reports.service';

import { pieChart } from './../../../../shared/data/charts/ngx-charts';
import { Chart } from 'chart.js';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { StatisticsService } from 'src/app/shared/API-Service/statistics.service';
import { Agent_ReportModel } from 'src/app/shared/Models/Agent_ReportModel';
import { CallReason_ReportModel } from 'src/app/shared/Models/CallReason_ReportModel';
import { GraphData } from 'src/app/shared/Models/GraphData';
import { Stats_Report } from 'src/app/shared/Models/Stats_Report';
import * as chartData from '../../../../shared/data/dashboard/default'
import * as graphoptions from '../../../../shared/data/charts/confic'
import * as chartlists from '../../../../shared/data/charts/chart-list'
import * as googlecharts from '../../../../shared/data/charts/google-chart'

import { DatePipe } from '@angular/common';
import { calcPossibleSecurityContexts } from '@angular/compiler/src/template_parser/binding_parser';
import { Router } from '@angular/router';
import { state } from '@angular/animations';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-customer-stats',
  templateUrl: './customer-stats.component.html',
  styleUrls: ['./customer-stats.component.css']
})
export class CustomerStatsComponent implements OnInit {

  dateoftheday = new Date(); 
  thedata :any ;
  //====================================
  marketingstatedata:any;
  governoratesStats:any;
  sectorTypeStats:any;
  sectorLocationStats:any;
  //====================================
  labels:string [] = [];
  datacounts:number[] = [];
  totalamount:number[] = [];
  //====================================
  labelsgovernrates:string [] = [];
  datacountsgovernrates:number[] = [];
  totalamountgovernrates:number[] = [];
   //=====================================
  labelssectorTypeStats:string[] = [];
  datacountssectorTypeStats:number[] = [];
  totalamountsectorTypeStats:number[] = [];
  //=======================================
  labelssectorLocationStats:string[] = [];
  datacountssectorLocationStats:number[] = [];
  totalamountsectorLocationStats:number[] = [];
  //===========================================
  employeesstats:object [] ;
   fromday: boolean;
   frommonth: boolean;
   fromyear: boolean;
   data: { };
   Donations: any;
   err: boolean;
  inCallsCountcountsgovernrates: number[] = [];
  outCallsCountgovernrates: number[] = [];
  inCallsCountSourceMarket:number[] = [];
  outCallsCountSourceMarket: number[] = [];
  callreasonstatedata: any;
  inCallsCountCallReason:number[] = [];
  outCallsCountCountCallReason: number[] = [];
  callReasonsCallslabels:string [] = [];
  form={
    datefrom:null,
    dateto:null
  }
   constructor(private StattsReportsService:StattsReportsService,public datepipe: DatePipe,private router: Router){
    // Chart.defaults.ChartOptions.font = "bold";
  this.fromday=false;
  this.frommonth=false;
  this.fromyear=false;
   }

  ngOnInit(): void {

    this.getfromday();
    this.employeesstats = [];
  }
  GetNewInstanceFromArrys()
  {
 //====================================
 this.labels = [];
 this.datacounts= [];
 this.totalamount= [];
 //====================================
 this.labelsgovernrates= [];
 this.datacountsgovernrates= [];
 this.totalamountgovernrates = [];
  //=====================================
 this.labelssectorTypeStats = [];
 this.datacountssectorTypeStats = [];
 this.totalamountsectorTypeStats = [];
 //=======================================

 this.inCallsCountcountsgovernrates = [];
 this.outCallsCountgovernrates = [];
 this.inCallsCountSourceMarket = [];
 this.outCallsCountSourceMarket = [];
  }
 
 creatChart()
 {
 
   
   const myChart4 = new Chart("myChart4", {
     type: 'line',
     data: {
       
         labels: this.labelssectorLocationStats,
         datasets: [{
             label: 'العدد',
             data: this.datacountssectorLocationStats,
             borderColor: 'rgb(75, 192, 192)',
             
             borderWidth: 1.5
         },
         {
           label: 'اجمالي المبلغ',
           data: this.totalamountsectorLocationStats,
           borderColor: [
             'blue'
         ],
           borderWidth: 1
       }]
     },
     options: {
       title: {
         display: false,
         text: 'Color test'
       },
       legend: {
         position: 'top',
         display: true,
         fullWidth: true,
         labels: {
           fontSize: 15,
           fontFamily: 'Cairo',
           fontColor: 'grey',
         }
       },
       scales: {
         xAxes: [{
           gridLines: {
                 display: true,
                 
                 color: "#E6D7D7"
               },
           ticks: {
             beginAtZero: true,
             stepSize: 1 ,
             fontSize:15,
             fontFamily: 'Cairo',
             fontColor: "grey", // this here     
         }
         }],
         yAxes: [{
           gridLines: {
             display: true,
             color: "#E6D7D7"
           },
         }]
       }
     }
 
 });
 
   const myChart3 = new Chart("myChart3", {
     type: 'bar',
     data: {
       
      labels: this.callReasonsCallslabels,
      datasets: [{
          label: 'المكالمات الصادرة',
          data: this.outCallsCountCountCallReason,
          backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
          ],
          borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)'
          ],
          borderWidth: 1
      },
      {
        label: 'المكالمات الواردة',
        data: this.inCallsCountCallReason,
        backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)'
        ],
        borderWidth: 1
    }]
  },
  options: {
    title: {
      display: false,
      text: 'Color test'
    },
    legend: {
      position: 'top',
      display: true,
      fullWidth: true,
      labels: {
        fontSize: 15,
        fontFamily: 'Cairo',
        fontColor: 'grey',
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
              display: true,
              color: "#E6D7D7"
            },
        ticks: {
          beginAtZero: true,
          stepSize: 2 ,
          fontSize:15,
          fontFamily: 'Cairo',
          fontColor: "grey", // this here     
      }
      }],
      yAxes: [{
        gridLines: {
          display: true,
          color: "#E6D7D7"
        },
      }]
    }
  }
 
 });
 // this line is drawing new canvas with no data 
 document.getElementById("chartContainer").innerHTML = '&nbsp;';
 document.getElementById("chartContainer").innerHTML = '<canvas id="myChart2"></canvas>';
 // var ctxTopItems = document.getElementById("myChart2").getElementsByTagName("2d");
 console.log(this.inCallsCountcountsgovernrates)
    const myChart2 = new Chart("myChart2", {
      type: 'bar',
      data: {
        
          labels: this.labelsgovernrates,
          datasets: [{
              label: 'المكالمات الصادر',
              data: this.outCallsCountgovernrates,
              backgroundColor: [
                'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(153, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 100, 0.2)',
              ],
              borderColor: [
                  'rgba(153, 206, 86, 1)',
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)'
              ],
              borderWidth: 1
          },
          {
            label: 'المكالمات الوارده',
            data: this.inCallsCountcountsgovernrates,
            backgroundColor: [
              'rgba(153, 102, 255, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 200, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
            ],
            borderColor: [
              'rgba(153, 102, 255, 0.2)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)'
            ],
            borderWidth: 1
        }]
      },
      options: {
        title: {
          display: false,
          text: 'Color test'
        },
        legend: {
          position: 'top',
          display: true,
          fullWidth: true,
          labels: {
            fontSize: 15,
            fontFamily: 'Cairo',
            fontColor: 'grey',
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
                  display: true,
                  color: "#E6D7D7"
                },
            ticks: {
              beginAtZero: true,
              stepSize: 2 ,
              fontSize:15,
              fontFamily: 'Cairo',
              fontColor: "grey", // this here     
          }
          }],
          yAxes: [{
            gridLines: {
              display: true,
              color: "#E6D7D7"
            },
            ticks: {
              beginAtZero: true,
              stepSize: 2 ,
              fontSize:15,
              suggestedMin: 0.0, // Set the suggested minimum value for the y-axis
              fontFamily: 'Cairo',
              fontColor: "grey", // this here     
          }
          }]
        }
      }
  } );
 // this line is drawing new canvas with no data 
 document.getElementById("chartContainer1").innerHTML = '&nbsp;';
 document.getElementById("chartContainer1").innerHTML = '<canvas id="myChart1"></canvas>';
 
   const myChart = new Chart("myChart1", {
     type: 'bar',
     data: {
       
         labels: this.labels,
         datasets: [{
             label: 'المكالمات الصادرة',
             data: this.outCallsCountSourceMarket,
             backgroundColor: [
                 'rgba(255, 99, 132, 0.2)',
                 'rgba(54, 162, 235, 0.2)',
                 'rgba(255, 206, 86, 0.2)',
                 'rgba(75, 192, 192, 0.2)',
                 'rgba(153, 102, 255, 0.2)',
                 'rgba(255, 159, 64, 0.2)',
                 'rgba(255, 206, 86, 0.2)',
                 'rgba(75, 192, 192, 0.2)',
             ],
             borderColor: [
                 'rgba(255, 99, 132, 1)',
                 'rgba(54, 162, 235, 1)',
                 'rgba(255, 206, 86, 1)',
                 'rgba(75, 192, 192, 1)',
                 'rgba(153, 102, 255, 1)',
                 'rgba(255, 159, 64, 1)',
                 'rgba(255, 206, 86, 1)',
                 'rgba(75, 192, 192, 1)'
             ],
             borderWidth: 1
         },
         {
           label: 'المكالمات الواردة',
           data: this.inCallsCountSourceMarket,
           backgroundColor: [
               'rgba(255, 99, 132, 0.2)',
               'rgba(54, 162, 235, 0.2)',
               'rgba(255, 206, 86, 0.2)',
               'rgba(75, 192, 192, 0.2)',
               'rgba(153, 102, 255, 0.2)',
               'rgba(255, 159, 64, 0.2)',
               'rgba(255, 206, 86, 0.2)',
               'rgba(75, 192, 192, 0.2)',
           ],
           borderColor: [
               'rgba(255, 99, 132, 1)',
               'rgba(54, 162, 235, 1)',
               'rgba(255, 206, 86, 1)',
               'rgba(75, 192, 192, 1)',
               'rgba(153, 102, 255, 1)',
               'rgba(255, 159, 64, 1)',
               'rgba(255, 206, 86, 1)',
               'rgba(75, 192, 192, 1)'
           ],
           borderWidth: 1
       }]
     },
     options: {
       title: {
         display: false,
         text: 'Color test'
       },
       legend: {
         position: 'top',
         display: true,
         fullWidth: true,
         labels: {
           fontSize: 15,
           fontFamily: 'Cairo',
           fontColor: 'grey',
         }
       },
       scales: {
         xAxes: [{
           gridLines: {
                 display: true,
                 color: "#E6D7D7"
               },
           ticks: {
             beginAtZero: true,
             stepSize: 2 ,
             fontSize:15,
           
             fontFamily: 'Cairo',
             fontColor: "grey", // this here     
         }
         }],
         yAxes: [{
           gridLines: {
             display: true,
             color: "#E6D7D7"
           },
           ticks: {
            beginAtZero: true,
            stepSize: 2 ,
            fontSize:15,
            suggestedMin: 0.0, // Set the suggested minimum value for the y-axis
            fontFamily: 'Cairo',
            fontColor: "grey", // this here     
        }
         }]
       }
     }
 });
 }
 
 //for seif elfagal
 getfromday(){
 this.fromday=true;
 this.frommonth=false;
 this.fromyear=false;
   this.GetNewInstanceFromArrys();

  //  this.StattsReportsService.CallsStats().subscribe( (res) => {
     
  //    this.thedata = res.data;
  // //  this.employeesstats = this.thedata.employeesStats;
 
  //    this.governoratesStats = this.thedata.governoratesCalls;
  //    for(var i = 0 ; i < this.governoratesStats.length;i++){
  //      this.labelsgovernrates.push(this.governoratesStats[i].title);
  //      this.inCallsCountcountsgovernrates.push(this.governoratesStats[i].inCallsCount);
  //      this.outCallsCountgovernrates.push(this.governoratesStats[i].outCallsCount);
      
  //   }


  //    this.marketingstatedata = this.thedata.marketingSourceCalls;
  //    for(var i = 0 ; i < this.marketingstatedata.length;i++){
  //      this.labels.push(this.marketingstatedata[i].title);
  //      this.inCallsCountSourceMarket.push(this.marketingstatedata[i].inCallsCount );
  //      this.outCallsCountSourceMarket.push(this.marketingstatedata[i].outCallsCount);
      
  //   }
  //   this.callreasonstatedata = this.thedata.callReasonsCalls;
  //    for(var i = 0 ; i < this.callreasonstatedata.length;i++){
  //      this.callReasonsCallslabels.push(this.callreasonstatedata[i].title);
  //      this.inCallsCountCallReason.push(this.callreasonstatedata[i].inCallsCount );
  //      this.outCallsCountCountCallReason.push(this.callreasonstatedata[i].outCallsCount);
      
  //   }
  //    this.creatChart();
     
  //   })
 
 };
 
 getfrommonth(){
   this.frommonth=true;
   this.fromday=false;
   this.fromyear=false;
   this.StattsReportsService.RiskStats({"monthToDate" : this.dateoftheday}).subscribe( (res) => {
     this.GetNewInstanceFromArrys();
     this.thedata = res.data;
     this.employeesstats = this.thedata.employeesStats; 
     this.governoratesStats = this.thedata.governoratesStats;
     for(var i = 0 ; i < this.governoratesStats.length;i++){
       this.labelsgovernrates.push(this.governoratesStats[i].title);
       this.datacountsgovernrates.push(this.governoratesStats[i].donationsCount);
       this.totalamountgovernrates.push(this.governoratesStats[i].totalAmount);
      
    }
    
 
     this.sectorTypeStats = this.thedata.sectorTypeStats;
     for(var i = 0 ; i < this.sectorTypeStats.length;i++){
       this.labelssectorTypeStats.push(this.sectorTypeStats[i].title);
       this.datacountssectorTypeStats.push(this.sectorTypeStats[i].donationsCount);
       this.totalamountsectorTypeStats.push(this.sectorTypeStats[i].totalAmount);
       
    }
    
 
     this.sectorLocationStats = this.thedata.sectorLocationStats;
     for(var i = 0 ; i < this.sectorLocationStats.length;i++){
       this.labelssectorLocationStats.push(this.sectorLocationStats[i].title);
       this.datacountssectorLocationStats.push(this.sectorLocationStats[i].donationsCount);
       this.totalamountsectorLocationStats.push(this.sectorLocationStats[i].totalAmount);
    
    }
   
     this.marketingstatedata = this.thedata.marketingSourceStats;
     for(var i = 0 ; i < this.marketingstatedata.length;i++){
       this.labels.push(this.marketingstatedata[i].title);
       this.datacounts.push(this.marketingstatedata[i].donationsCount);
       this.totalamount.push(this.marketingstatedata[i].totalAmount);
       
    }
    
    this.creatChart();
 
   
    })
 
 }
 getfromyear(){
   this.fromyear=true;
  this.fromday=false;
  this.frommonth=false
 
   this.GetNewInstanceFromArrys();
 
   this.StattsReportsService.RiskStats({"yearToDate" : this.dateoftheday}).subscribe( (res) => {
    
     this.thedata = res.data;
     this.employeesstats = this.thedata.employeesStats; 
     this.governoratesStats = this.thedata.governoratesStats;
     for(var i = 0 ; i < this.governoratesStats.length;i++){
       this.labelsgovernrates.push(this.governoratesStats[i].title);
       this.datacountsgovernrates.push(this.governoratesStats[i].donationsCount);
       this.totalamountgovernrates.push(this.governoratesStats[i].totalAmount);
      
    }
    
 
     this.sectorTypeStats = this.thedata.sectorTypeStats;
     for(var i = 0 ; i < this.sectorTypeStats.length;i++){
       this.labelssectorTypeStats.push(this.sectorTypeStats[i].title);
       this.datacountssectorTypeStats.push(this.sectorTypeStats[i].donationsCount);
       this.totalamountsectorTypeStats.push(this.sectorTypeStats[i].totalAmount);
      
    }
   
 
     this.sectorLocationStats = this.thedata.sectorLocationStats;
     for(var i = 0 ; i < this.sectorLocationStats.length;i++){
       this.labelssectorLocationStats.push(this.sectorLocationStats[i].title);
       this.datacountssectorLocationStats.push(this.sectorLocationStats[i].donationsCount);
       this.totalamountsectorLocationStats.push(this.sectorLocationStats[i].totalAmount);
      
    }
   
     this.marketingstatedata = this.thedata.marketingSourceStats;
     for(var i = 0 ; i < this.marketingstatedata.length;i++){
       this.labels.push(this.marketingstatedata[i].title);
       this.datacounts.push(this.marketingstatedata[i].donationsCount);
       this.totalamount.push(this.marketingstatedata[i].totalAmount);
       
    }
   
     
    
    this.creatChart();
 
 
     
    })
 
 }
 getall(){
   this.GetNewInstanceFromArrys();
   this.StattsReportsService.RiskStats({ }).subscribe( (res) => {
     this.fromday=false;
     this.frommonth=false;
     this.fromyear=false;
     this.thedata = res.data;
     this.employeesstats = this.thedata.employeesStats;
     this.governoratesStats = this.thedata.governoratesStats;
     for(var i = 0 ; i < this.governoratesStats.length;i++){
       this.labelsgovernrates.push(this.governoratesStats[i].title);
       this.datacountsgovernrates.push(this.governoratesStats[i].donationsCount);
       this.totalamountgovernrates.push(this.governoratesStats[i].totalAmount);
       
    }
    
 
     this.sectorTypeStats = this.thedata.sectorTypeStats;
     for(var i = 0 ; i < this.sectorTypeStats.length;i++){
       this.labelssectorTypeStats.push(this.sectorTypeStats[i].title);
       this.datacountssectorTypeStats.push(this.sectorTypeStats[i].donationsCount);
       this.totalamountsectorTypeStats.push(this.sectorTypeStats[i].totalAmount);
       
    }
   
 
     this.sectorLocationStats = this.thedata.sectorLocationStats;
     for(var i = 0 ; i < this.sectorLocationStats.length;i++){
       this.labelssectorLocationStats.push(this.sectorLocationStats[i].title);
       this.datacountssectorLocationStats.push(this.sectorLocationStats[i].donationsCount);
       this.totalamountsectorLocationStats.push(this.sectorLocationStats[i].totalAmount);
    
    }
   
   
     this.marketingstatedata = this.thedata.marketingSourceStats;
     for(var i = 0 ; i < this.marketingstatedata.length;i++){
       this.labels.push(this.marketingstatedata[i].title);
       this.datacounts.push(this.marketingstatedata[i].donationsCount);
       this.totalamount.push(this.marketingstatedata[i].totalAmount);
       
    }
    
    
     
    this.creatChart();
     
   
 
    })
 }
  
 goToDetails(stateid){
   if(this.fromday){
     this.data = {
       today:this.dateoftheday,
       // monthToDate: "",
       // yearToDate: "",
       statuesId: stateid
     }
   }
   else  if(this.frommonth){
     this.data = {
       // today: "",
       monthToDate: this.dateoftheday,
       // yearToDate: "",
       statuesId: stateid
     }
   }
 
   else if(this.fromyear){ 
     this.data = {
       // today: "",
       // monthToDate: this.dateoftheday,
       yearToDate: this.dateoftheday,
       statuesId: stateid
     }
   }
 else{ 
   this.data = {
    
     statuesId: stateid
   }
 }
 this.StattsReportsService.GetDonationStatus(this.data).subscribe({
   next: (data) => {
    var res = data;
     if (res.success) {
       this.err = false;
       this.Donations=res.data
       this.router.navigateByUrl("content/admin/statsdetails",{state:{Donations:this.Donations}});
 
  //     localStorage.setItem('RiskuserMenu',this.Menu)
       
     } else {
       this.err = true;
     }
   },
   error: (error) => {
     this.err = true;
     Swal.fire({
      icon: 'error',
      title: 'خطأ',
      text: error.error.message,
    })
   },
 });
 
 }
 Search(){
  this.thedata=[];
  this.labelsgovernrates=[];
      this.inCallsCountcountsgovernrates=[];
      this.outCallsCountgovernrates=[];
      this.labels=[]
      this.inCallsCountSourceMarket=[];
      this.outCallsCountSourceMarket=[];
      this.callReasonsCallslabels=[];
      this.inCallsCountCallReason=[];
      this.outCallsCountCountCallReason=[];
  if(!this.form.datefrom){
    this.form.datefrom=null
  }
  if(!this.form.dateto){
    this.form.dateto=null
  }
   const data={
    "dateFrom": this.form.datefrom,
    "dateTo": this.form.dateto
   }
  this.StattsReportsService.CallsStats(data).subscribe(  {
    next:(res) =>{
      this.thedata = res.data;
      //  this.employeesstats = this.thedata.employeesStats;
     
         this.governoratesStats = this.thedata.governoratesCalls;
         for(var i = 0 ; i < this.governoratesStats.length;i++){
           this.labelsgovernrates.push(this.governoratesStats[i].title);
           this.inCallsCountcountsgovernrates.push(this.governoratesStats[i].inCallsCount);
           this.outCallsCountgovernrates.push(this.governoratesStats[i].outCallsCount);
          
        }
     
    
     
         this.marketingstatedata = this.thedata.marketingSourceCalls;
         for(var i = 0 ; i < this.marketingstatedata.length;i++){
           this.labels.push(this.marketingstatedata[i].title);
           this.inCallsCountSourceMarket.push(this.marketingstatedata[i].inCallsCount );
           this.outCallsCountSourceMarket.push(this.marketingstatedata[i].outCallsCount);
          
        }
        this.callreasonstatedata = this.thedata.callReasonsCalls;
         for(var i = 0 ; i < this.callreasonstatedata.length;i++){
           this.callReasonsCallslabels.push(this.callreasonstatedata[i].title);
           this.inCallsCountCallReason.push(this.callreasonstatedata[i].inCallsCount );
           this.outCallsCountCountCallReason.push(this.callreasonstatedata[i].outCallsCount);
          
        }
         this.creatChart();
         
    },
    error: (error) => {
    
      this.err = true;
      Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: error.error,
      })
    },
   }
   )
 }
}
