import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  EmployeeName: string = "";
  public openUser: boolean = false;
  constructor(private router: Router) {
    this.EmployeeName = localStorage.getItem('RiskuserName')
  }

  ngOnInit() {


  }
  // For Mobile Device
  toggleUser() {
    this.openUser = !this.openUser;
  }


  logout() {
    localStorage.clear();
    this.router.navigate(["/login"]);
  }


  ogin() {
    this.router.navigate(["/change-password"]);
  }
}
