import { Router } from '@angular/router';
import { Searchbutton } from './../Models/searchbutton';
import { Donationserial } from './../Models/donationserial';
import { UpdateCollector } from './../Models/update-collector';
import { CollectorManager } from './../Models/collector-manager';
import { environment } from './../../../environments/environment.prod';


import { GenericResponse } from 'src/app/shared/Models/GenericResponse';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
import { getAllCollectorEmployees } from '../Models/Get-All-Collector-Employees';

@Injectable({
  providedIn: 'root'
})
export class CollectorManagerService {

  constructor(private http:HttpClient,private router:Router) { }

 

  GetAllIsNotCollectedDonationsearch(data:Searchbutton): Observable<GenericResponse<CollectorManager>>{
   

    return this.http.post<GenericResponse<CollectorManager>>(`${environment.Server_URL}/Donation/GetOpenDonationAndHasCollector`,data);
  }
  GetAllIsNotCollectedDonationsearchList(data): Observable<GenericResponse<CollectorManager>>{
   

    return this.http.post<GenericResponse<CollectorManager>>(`${environment.Server_URL}/Donation/GetOpenDonationAndHasCollectorList`,data);
  }
    
  GetAllCollectorEmployees(): Observable<GenericResponse<getAllCollectorEmployees>>{
    return this.http.get<GenericResponse<getAllCollectorEmployees>>(`${environment.Server_URL}/Employee/GetAllCollectorEmployees`);
  }
  GetAllCollectorEmployeesList(data:any): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(`${environment.Server_URL}/Employee/GetAllCollectorEmployeesList`,data);
  }
  GetCollectorByCity(cityid:string): Observable<GenericResponseSingle<any>> {
    return this.http.get<GenericResponseSingle<any>>(`${environment.Server_URL}/CollectorCity/GetAllCollectorsInCity?cityId=${cityid}`);
  }
  DonationCollectorUpdateCollector(data:any): Observable<GenericResponse<UpdateCollector>>{
  return this.http.post<GenericResponse<UpdateCollector>>(`${environment.Server_URL}/DonationCollector/UpdateCollector`,data);
 }
 AssignCollectorToDonation(data:any): Observable<GenericResponse<any>>{
  return this.http.post<GenericResponse<any>>(`${environment.Server_URL}/Donation/AssignCollectorToDonation`,data);
 }
 reloadCurrentRoute() {
  let currentUrl = this.router.url;
  this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
  });
 
}


}
