import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GenericResponse } from '../Models/GenericResponse';
import { ReceiptBook, UpdateReceiptBook } from '../Models/ReceiptBook';
import { environment } from '../../../environments/environment.prod';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';

@Injectable({
  providedIn: 'root'
})
export class ReceiptBookService {
  public Data = new BehaviorSubject(null);
  constructor(private http:HttpClient) { }
  //#endregion

    //#region Options
    httpOptionsWithTocken = { headers: new HttpHeaders({ 'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhaG1lZGFiZHVsbGFoMjUwIiwianRpIjoiZDIwZjU0MGUtMjhiNy00YmNjLWE4ZDgtNzkxNzA2YzJmZDRhIiwiZW1haWwiOiJhaG1lZGFiZHVsbGFoQHlhaG9vLmNvbSIsInVpZCI6IjBiMzg5N2FiLTQ2ZmMtNGM0Yy04MTYyLTRiNDExZTY4OWE1NCIsInJvbGVzIjoiVVNFUiIsImV4cCI6MTYzODM2OTM3NSwiaXNzIjoiU2VjdXJlQXBpIiwiYXVkIjoiU2VjdXJlQXBpVXNlciJ9.55LorE6Fclj3buy1Qw8wZ6CEe_ifW5jxwHe25wHRWsQ', 'Accept': ' */*' }) };
    //#endregion

    GetReceiptBook(): Observable<GenericResponse<ReceiptBook>> {
      return this.http.get<GenericResponse<ReceiptBook>>(`${environment.Server_URL}/ReceiptBook/Get`);
    }
    GetReceiptBookByCompanyId(CompanyId:any): Observable<GenericResponse<ReceiptBook>> {
      return this.http.get<GenericResponse<ReceiptBook>>(`${environment.Server_URL}/ReceiptBook/Get?companyId=${CompanyId}`);
    }
    GetReceiptBookList(pager): Observable<GenericResponse<ReceiptBook>> {
      return this.http.post<GenericResponse<ReceiptBook>>(`${environment.Server_URL}/ReceiptBook/GetPaged`,pager);
    }
    GetReceiptStatus(): Observable<GenericResponse<ReceiptBook>> {
      return this.http.get<GenericResponse<ReceiptBook>>(`${environment.Server_URL}/Lookups/GetReceiptStatus`);
    }

    GetAllBooks(pager:any): Observable<GenericResponse<ReceiptBook>> {
      return this.http.post<GenericResponse<ReceiptBook>>(`${environment.Server_URL}/Receipt/GetAllBooks`,pager);
    }
    GetFreeReceipts(pager:any): Observable<GenericResponse<ReceiptBook>> {
      return this.http.post<GenericResponse<ReceiptBook>>(`${environment.Server_URL}/Receipt/GetFreeReceipts`,pager);
    }
    GetReceipts(pager:any): Observable<GenericResponse<ReceiptBook>> {
      return this.http.post<GenericResponse<ReceiptBook>>(`${environment.Server_URL}/Receipt/GetReceipts`,pager);
    }
    GetCancelledReceipts(pager:any): Observable<GenericResponse<ReceiptBook>> {
      return this.http.post<GenericResponse<ReceiptBook>>(`${environment.Server_URL}/Receipt/GetCancelledReceipts`,pager);
    }
    GetCollectorReceipts(pager:any): Observable<GenericResponse<ReceiptBook>> {
      return this.http.post<GenericResponse<ReceiptBook>>(`${environment.Server_URL}/Receipt/GetCollectorReceipts`,pager);
    }
    GetReceiptBookByid(id:any): Observable<GenericResponse<ReceiptBook>> {
      return this.http.get<GenericResponse<ReceiptBook>>(`${environment.Server_URL}/ReceiptBook/GetById?Id=${id}`);
    }
    InsertReceiptBook(Data:any): Observable<GenericResponseSingle<ReceiptBook>> {
      return this.http.post<GenericResponseSingle<ReceiptBook>>(`${environment.Server_URL}/ReceiptBook/Create`,Data);
    }
    UpdateReceiptBook(Data:any): Observable<GenericResponseSingle<UpdateReceiptBook>> {
      return this.http.post<GenericResponseSingle<UpdateReceiptBook>>(`${environment.Server_URL}/ReceiptBook/Update`,Data);
    }

    CancelReceipt(Data:any): Observable<GenericResponseSingle<any>> {
      return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/Receipt/CancelReceipt`,Data);
    }
    UncancelReceipt(Id:number): Observable<GenericResponseSingle<any>> {
      return this.http.get<GenericResponseSingle<any>>(`${environment.Server_URL}/Receipt/UncancelReceipt?id=${Id}`);
    }
    // UpdateCallReason(Data:UpdateCallReason): Observable<GenericResponseSingle<UpdateCallReason>> {
    //   return this.http.post<GenericResponseSingle<UpdateCallReason>>(`${environment.Server_URL}/CallReason/Update`,Data);
    // }
    GetCollectorAvailableReceipts(donationMainTypeId,companyId,collectorId?): Observable<GenericResponse<any>> {
      const params = new HttpParams()
      .set('donationMainTypeId',donationMainTypeId)
      .set('collectorId',collectorId)
      .set('companyId',companyId);
      return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/Receipt/GetCollectorAvailableReceipts`,{params});
    }
    DeleteReceiptBook(Id:number): Observable<GenericResponseSingle<ReceiptBook>> {
      return this.http.get<GenericResponseSingle<ReceiptBook>>(`${environment.Server_URL}/ReceiptBook/Delete?id=${Id}`);
      // change detete
    }
    DeleteCollectorReceipt(Id:number,CollectorId:number): Observable<GenericResponseSingle<any>> {
      const params = new HttpParams()
      .set('id', Id)
      .set('collectorId',CollectorId);
      return this.http.get<GenericResponseSingle<any>>(`${environment.Server_URL}/Receipt/DeleteCollectorReceipt`,{params});
    }
    CreateCollectorReceipts(Data:any): Observable<GenericResponseSingle<any>> {
      return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/Receipt/CreateCollectorReceipts`,Data);
    }
}
