
 <ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">إلغاء إيصال</h4>
		<button type="button"  class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
            <form [formGroup]="CancelReceiptForm" (ngSubmit)="CancelReceipt()">
          <div class="card">
            <div class="card-body">
                <div class="form-row">
                    <div class="col-12 mb-3">
                        <label> رقم الايصال الملغى</label>
                     <br>
                      <label> {{receiptNumFromDeatil}}</label>
        
                     
                    </div>
                  <div class="col-12 mb-3">
                    <label> رقم الايصال الجديد</label>
                    <ng-select
                    class="js-example-basic-single"
                    aria-label="Default select example"
                    formControlName="Receipts"
                    [items]="CollectorAvailableReceiptsNew"
                   bindLabel="number"
                   bindValue="id"
                   
                  >
                  </ng-select>
    
    
                 
                </div>
    
                </div>
    
            
                <div class="form-row">
                  <div class="col-12 mb-3">
                  <label for="validationCustom03"> سبب الالغاء </label>
                  <input class="form-control" type="text" 
                  formControlName='CancelReason' 
                  [tooltip]="fc.CancelReason.errors?.required ? ' سبب الالغاء مطلوب' : null" required
                  [isDisabled]="!((fc.CancelReason.errors?.required && fc.CancelReason.touched))"
                  containerClass="" [class.is-valid]="fc.CancelReason.valid && (fc.CancelReason.touched)"
                  [class.is-invalid]="fc.CancelReason.invalid && (fc.CancelReason.touched)"
                  >
    <!-- </textarea> -->
                </div>
                </div>
                  <button
                    class="btn btn-primary pull-right"
                    type="button"
                    (click)="CancelReceipt()"
                  >
                     حفظ
                  </button>
            </div>
          </div>
            </form>
        </div>
      </div>
    </div>
	</div>
	<!-- <div class="modal-footer">
		<button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
	</div> -->
</ng-template>
<app-breadcrumb [title]="pagetitle" [active_item]="'Form Wizard'"></app-breadcrumb>
<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="user-profile">
    <div class="row mt-5">
      <!-- user profile first-style start-->
      
      <div class="col-sm-12">
        <div class="card hovercard text-center">
       
          <div class="user-image">
            <div class="avatar"><img alt="Risk Logo" [src]="companyImage"></div>
            <!-- <div class="icon-wrapper"><i class="icofont icofont-pencil-alt-5"><input class="upload" type="file"
                   /></i></div> -->
          </div>
          <div class="info">
                  <div  (click)="back()" style="text-align: end; cursor: pointer;"  >
                <i   class='fa fa-reply' style="font-size: xx-large;"></i>
                  </div>

            <div class="row justify-content-between">
              <div class="col-sm-6 col-lg-4 order-sm-1 order-xl-0">
                <div class="row">
                  <div class="col-md-12">
                    <div class="ttl-info text-center">
                     
                          <h6 class="h4"> بواسطة     <i class="fa fa-heartbeat" aria-hidden="true"></i>
                          </h6>
                          <span>{{form.Employee}} </span>
                    </div>
                  </div>


              
                </div>
              </div>
              <div class="col-sm-12 col-lg-4 order-sm-0 order-xl-1">
                <div class="user-designation">
                    <i class="fa fa-user-circle fa-3x mt-2" aria-hidden="true"></i>

                    <div class="title ">  
                         
                        <h6 *ngIf="!editdonation" class="font-weight-bold">تبرع جديد</h6>
                        <h6 *ngIf="editdonation" class="font-weight-bold">تعديل التبرع ل</h6>

                        {{customername}}
                       
                    </div>
 
                 
                </div>
              </div>
              <div class="col-sm-6 col-lg-4 order-sm-2 order-xl-2">
                <div class="row">
                  <div class="col-md-12">
                    <div class="ttl-info text-center">
                        <h6 class="h4">الموبايل <i class="fa fa-phone"></i></h6>
                        <span>{{cusomtermobile}}</span>   
                           <br>
                           <span  *ngIf="cusomtermobile">
                                <a [href]="'tel:' + cusomtermobile" ><i class="fa fa-phone" style="color: green; font-size: 25px;"></i></a>
              
                            <a [href]="'//wa.me/+2' + cusomtermobile" target="_blank"><i class="fa fa-whatsapp" style="color: #2cc198; font-size: 25px;" ></i></a>               
                            <i class="fa fa-comment" (click)="SendSMSTemplate(donationid)" style=" color: black; font-size: 25px;"></i>

                           </span>
                         
                    </div>
                  </div>
                
                </div>
              </div>

            </div>
          <div>
            <h4 class="h4"></h4><span>{{serial}}</span>
            <h4 class="h4"></h4><span>{{cusomteraddress}}</span>
            
          </div>
            <br>
            <aw-wizard   *ngIf="!steps" [navBarDirection]="'right-to-left'" >
                <aw-wizard-step [awCompletedStep]="condition6" >
                    <ng-template awWizardStepTitle>
                      <p *ngIf="condition6" style="color: rgb(220, 18, 18);direction: ltr">تم الاغلاق</p>
                      <p *ngIf="!condition6" >اغلاق</p>
    
                    </ng-template>
                </aw-wizard-step>
                    <aw-wizard-step [awCompletedStep]="condition5" >
                        <ng-template awWizardStepTitle>
                          <p>تم ارسال الرساله</p>
                        </ng-template>
                    </aw-wizard-step>
                    <aw-wizard-step [awCompletedStep]="condition4" >
                        <ng-template awWizardStepTitle>
                          <p>تم التحصيل</p>
                        </ng-template>
                    </aw-wizard-step>
                    <aw-wizard-step [awCompletedStep]="condition3" >
    
                        <ng-template awWizardStepTitle>
                          <p>تم ارسال الرساله</p>
                        </ng-template>
                    </aw-wizard-step>
                    <aw-wizard-step  [awCompletedStep]="condition2" >
                        <ng-template awWizardStepTitle>
                          <p>تم الاستلام</p>
                        </ng-template>
                    </aw-wizard-step>
                    <aw-wizard-step [awCompletedStep]="condition1" >
                        <ng-template awWizardStepTitle>
                          <p>
                          مفتوح
                          </p>
                        </ng-template>
                    </aw-wizard-step>
              </aw-wizard>
          <aw-wizard   *ngIf="steps" [navBarDirection]="'right-to-left'" >
            <aw-wizard-step [awCompletedStep]="condition6" >
                <ng-template awWizardStepTitle>
                  <p *ngIf="condition6" style="color: rgb(220, 18, 18);direction: ltr">تم الاغلاق</p>
                  <p *ngIf="!condition6" >اغلاق</p>

                </ng-template>
            </aw-wizard-step>
                <aw-wizard-step [awCompletedStep]="condition5" >
                    <ng-template awWizardStepTitle>
                      <p>تم ارسال الرساله</p>
                    </ng-template>
                </aw-wizard-step>
                <aw-wizard-step [awCompletedStep]="condition4" >
                    <ng-template awWizardStepTitle>
                      <p>تم التحصيل</p>
                    </ng-template>
                </aw-wizard-step>
                <aw-wizard-step [awCompletedStep]="condition3" >

                    <ng-template awWizardStepTitle>
                      <p>تم ارسال الرساله</p>
                    </ng-template>
                </aw-wizard-step>
                <aw-wizard-step  [awCompletedStep]="condition2" >
                    <ng-template awWizardStepTitle>
                      <p>تم الاستلام</p>
                    </ng-template>
                </aw-wizard-step>
                <aw-wizard-step [awCompletedStep]="condition1" >
                    <ng-template awWizardStepTitle>
                      <p>
                      مفتوح
                      </p>
                    </ng-template>
                </aw-wizard-step>
          </aw-wizard>
          </div>
        </div>
      </div>   
    
      <!-- user profile first-style end-->
 
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->


<div class="container-fluid">
    <div class="row">

        <!-- // -->
        <div class="col-md-12">
            <div class="card">
            
                <div class=" ">
                    <form [formGroup]="CustomerDonationForm" (ngSubmit)="submitForm()">
                        <!-- Date Donation will be -->
                        <div class="card">
                            <div class="card-body">
                                <div class="form-group">
                                    <div class="row m-0">
                                        <div class="col-6 col-md-6">
                                            <label>تاريخ إنشاء التبرع </label>
                                            <input type="text" [(ngModel)]="DateNow" required="required"
                                                class="form-control" disabled  formControlName='Date'>
                                        </div>
                                        <div class="col-6 col-md-6">
                                            <label>تاريخ تحصيل التبرع المتوقع</label>
                                            <input type="date" class="form-control" [(ngModel)]="form.hiringDate"
                                                formControlName='hiringDate'>
                                            <!-- <ng-container
                                                *ngIf="CustomerDonationForm.get('hiringDateform').hasError('required') && CustomerDonationForm.get('hiringDateform').touched"
                                                class="invalid" style="color: red;">
                                                <span style="color: red;">
                                                    تاريخ تحصيل التبرع المتوقع مطلوب
                                                </span>
                                            </ng-container> -->
                                        </div>

                                    </div>
                                    <div class="row m-0">
                                        <div class="col-4 col-md-4 my-2">
                                            <label for="username">  مبلغ التبرع  </label>
                                            <input class="form-control" [(ngModel)]="form.totalAmount" onlyNumbers
                                                (ngModelChange)="ontotalAmountChange($event)" type="text"
                                                placeholder="" required="required" formControlName="totalAmount">
                                            <!-- onlyAlphabets -->
<!-- 
                                            <ng-container
                                                *ngIf="CustomerDonationForm.get('totalAmountform').hasError('required') && CustomerDonationForm.get('totalAmountform').touched"
                                                class="invalid" style="color: red;">
                                                <span style="color: red;">
                                                    مبلغ التبرع مطلوب
                                                </span>
                                            </ng-container> -->
                                        </div>
                                        <div class="col-4 col-md-4" style="margin-top: 7px;">
                                            <label> كيف</label>
                                            <ng-select  class="js-example-basic-single" [(ngModel)]="form.how" required="required"
                                                name="how" placeholder="إختر كيف عرفت المكان" formControlName="how"
                                                [items]="SourceMarket"
                                                bindLabel="title"
                                                bindValue="id"
                                                >
                                            </ng-select>
                                        </div>
                                        <div *ngIf="!editdonation &&donationMainTypeId!=3" class="col-4 col-md-4" style="margin-top: 7px;">
                                        <div>
                                            <label> اسم المندوب</label>
                                            <ng-select  class="js-example-basic-single" [attr.disabled]="disapled" [(ngModel)]="form.collectorname"
                                                required="required" name="job" placeholder="إختر المندوب"
                                                formControlName="collectorname"
                                                [items]="CollectorCity"
                                                bindLabel="employeeName"
                                                bindValue="collectorId"
                                                >
                                                <!-- <ng-option value="" selected>اختر </ng-option>
                                                <ng-option value="{{collectorcity.collectorId}}"
                                                    *ngFor="let collectorcity of CollectorCity">
                                                    {{collectorcity.employeeName}}
                                                </ng-option> -->
                                            </ng-select>



                                        </div>
                                    </div>
                                    <div *ngIf="editdonation && donationMainTypeId!=3" class="col-4 col-md-4" style="margin-top: 7px;">
                                        <label> اسم المندوب</label> 
                                        <h6 class="h4"> {{CollectorName}}</h6>
                                        
                                    </div>
                                        <div *ngIf="donationMainTypeId==3" class="col-4 col-md-4" style="margin-top: 7px;">
                                            <button type="button" class="btn btn-primary pull-right addbtn  " color="primary" (click)="fileInput.click()">
                                                <span>Upload Photos</span>
                                                <input #fileInput type="file"   
                                                accept="image/*" (change)="onFileSelected($event,i)" style="display:none;" />
                                              </button>  
                                        </div>
                                        <div *ngIf="donationMainTypeId==2" class="m-5" style="margin-top: 7px;">
                                            <button type="button" class="btn btn-primary pull-right addbtn mb-5  " color="primary" (click)="fileInput.click()">
                                                <span>Upload Photos</span>
                                                <input #fileInput type="file"   
                                                accept="image/*" (change)="onFileSelected($event,i)" style="display:none;" />
                                              </button>  
                                        </div>
                                        
                                    </div>
                                    <div class="row m-0" > 
                                        <div class="image-area" style="margin: 8px;"  >
                                            <a *ngIf="imagesView" class="remove-image" (click)="deleteimage(i)" style="display: inline;">&#215;</a>
                                            <img  *ngIf="imagesView" src="{{imagesView}}"  height="100" width="100"  (click)="OpenImage(imagesView)">
                                        </div>
                                </div>
                                    <div class="row m-0" hidden>
                                        <div class="col-12 col-md-12">
                                            <label for="comment"> تعليق </label>
                                            <textarea formControlName="comment" [(ngModel)]="form.comment"
                                                required="required" class="form-control textForArabic" placeholder="   "
                                                style="height: 120px">
                                  </textarea>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" *ngIf="donationMainTypeId!=2">
                            <div class="card-body" style="padding-bottom: 52px;">
                                <div class="form-group">
                                    <div class="row m-20">
                                        <div class="col-12 col-md-12" >
                                            <label> فئه التبرع</label>
                                            <ng-select  class="js-example-basic-single" name="donationtype" required="required"
                                                formControlName='donationtype' [(ngModel)]="form.donationtype"
                                                placeholder="إختر التبرع"
                                                [items]="DonationTypes"
                                                bindLabel="title"
                                                bindValue="id"
                                                >
                                            </ng-select>
                                        </div>
                                    </div>

                                    <div class="row m-20">
                                        <div class="col-12 col-md-12 " >
                                            <label> نوع النشاط</label>
                                            <ng-select  class="js-example-basic-single" formControlName='sector'
                                                required="required" (change)="onChangeSector($event.id)"
                                                name="sector" placeholder="إختر النشاط" [(ngModel)]="form.sector"
                                                [items]="sectors"
                                                bindLabel="title"
                                                bindValue="id"
                                                >
                                            </ng-select>
                                        </div>
                                    </div>
                                    </div>

                                    <div class="row m-20">

                                        <div class="col-12 col-md-12 " >
                                            <label> نوع  المشروع</label>
                                            <ng-select  class="js-example-basic-single" required="required"
                                                (change)="onChangeSectorType($event.id)" name="sectortype"
                                                placeholder="إختر نوع المشروع" formControlName='sectortype'
                                                [(ngModel)]="form.sectortype"
                                                [items]="SectorType"
                                                bindLabel="title"
                                                bindValue="id"
                                                >
                                            </ng-select>
                                        </div>
                                    </div>

                                    <div class="row m-20">
                                        <div class="col-12 col-md-12 " >
                                            <label> مكان المشروع</label>
                                            <ng-select  class="js-example-basic-single" name="sectorplace"
                                                placeholder="إختر مكان النشاط" required="required"
                                                formControlName='sectorplace' [(ngModel)]="form.sectorplace"
                                                [items]="SectorLocation"
                                                bindLabel="title"
                                                bindValue="id"
                                                >
                                            </ng-select>
                                        </div>
                                    </div>

                                    <div class="row m-20">
                                        <div class="col-12 col-md-12 " >
                                            <label for="username"> مبلغ التبرع</label>
                                            <input class="form-control" type="text" onlyNumbers [(ngModel)]="form.MoneyAmount"
                                                required="required" formControlName='MoneyAmount' placeholder=""
                                                required="required">
                                        </div>
                                    </div>

                                    <div  *ngIf="editdonation" class="row m-20">
                                        <div class="col-12 col-md-9 ">
                                            <label> رقم الايصال</label>
                                                <ng-select 
                                                *ngIf="receiptNumSelect"
                                                class="js-example-basic-single"
                                                aria-label="Default select example"
                                                formControlName="receiptNum"
                                                [items]="CollectorAvailableReceipts"
                                                [(ngModel)]="selectedReceipt"
                                                (change)="GetCollectorReceipts($event?.id)"
                                               bindLabel="number"
                                               bindValue="id"
                                              >
                                              </ng-select>
                                              <br>
                                              <label  *ngIf="!receiptNumSelect">{{receipt}}</label>
                                        </div>
                                        <div class="col-12 col-md-3 ">
                                            <button type="button" class="btn btn-danger pull-right addbtn "
                                            (click)="open(content)">إلغاء ايصال</button>
                                            <!-- <input class="form-control" type="text" [(ngModel)]="form.receiptNum"
                                               onlyNumbers required="required" formControlName='receiptNum' placeholder=""
                                                required="required">
                                                <span *ngIf="receiptNumlimit" style="color: #da2323;">يجب ان يكون اكثر من ٣ ارقام</span> -->
                        
                                        </div>
                                    </div>
                                    <div class="row m-20">
                                        <div class="col-12 col-md-12 ">
                                            <button type="button" *ngIf="!edit" class="btn btn-primary pull-right addbtn "
                                                (click)="Add()">اضافه</button>
                                            <button type="button" *ngIf="edit" class="btn btn-primary pull-right addbtn"
                                                (click)="EditOneDonationDetails()">تعديل</button>
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                        </div>
                    

                        <div class="card" *ngIf="donationMainTypeId==2">
                            <div class="card-body" style="padding-bottom: 52px;">
                                <div class="form-group">
                                    <div class="row m-20">
                                        <div class="col-12 col-md-12 " >
                                            <label> فئه التبرع</label>
                                            <ng-select  class="js-example-basic-single" formControlName='donationcat'
                                                required="required" (change)="onChangeDonationCat($event.id)"
                                                name="donationcat" placeholder="فئه التبرع" [(ngModel)]="form.donationcat"
                                                [items]="DonationCat"
                                                bindLabel="title"
                                                bindValue="id"
                                                >
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="row m-20">
                                        <div class="col-12 col-md-12 " >
                                            <label> نوع التبرع</label>
                                            <ng-select  class="js-example-basic-single" name="donationtypecat" required="required"
                                                formControlName='donationtypecat' [(ngModel)]="form.donationtypecat"
                                                placeholder="إختر التبرع"
                                                [items]="DonationTypes"
                                                bindLabel="title"
                                                bindValue="id"
                                                >
                                            </ng-select>
                                        </div>
                                    </div>
                                <div class="row m-20">
                                        <div class="col-12 col-md-12 ">
                                            <label for="username"> العدد</label>
                                            <input class="form-control" type="number" [(ngModel)]="form.Quantity"
                                                required="required" formControlName='Quantity' placeholder=""
                                                required="required">
                                        </div>
                                        </div>
                                        <div  *ngIf="editdonation" class="row m-20">
                                            <div class="col-12 col-md-9 ">
                                                <label> رقم الايصال</label>
                                                    <ng-select 
                                                    *ngIf="receiptNumSelect"
                                                    class="js-example-basic-single"
                                                    aria-label="Default select example"
                                                    formControlName="receiptNum"
                                                    [items]="CollectorAvailableReceipts"
                                                    [(ngModel)]="selectedReceipt"
                                                    (change)="GetCollectorReceipts($event?.id)"
                                                   bindLabel="number"
                                                   bindValue="id"
                                                  >
                                                  </ng-select>
                                                  <br>
                                                  <label  *ngIf=" !receiptNumSelect">{{receipt}}</label>
                                            </div>
                                            <div  class="col-12 col-md-3 ">
                                                    <button type="button"  class="btn btn-danger pull-right addbtn "
                                                    (click)="open(content)" >إلغاء ايصال</button>
                                            </div>
                                        </div>
                                        <div class="row m-20">
                                        <div class="col-12 col-md-12 " >
                                            <button type="button" *ngIf="!edit" class="btn btn-primary pull-right addbtn "
                                                (click)="Add()">اضافه</button>
                                            <button type="button" *ngIf="edit" class="btn btn-primary pull-right addbtn"
                                                (click)="EditOneDonationDetails()">تعديل</button>
                                        </div>
                                        </div>
                               
                                </div>
                            </div>
                        </div>



                        <div class="card" *ngIf="donationMainTypeId!=2">
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table  class="table table-striped" style="width: 100%;">
                                        <thead>
                                        </thead>
                                        <tr class="bg-dark ">
                                            <th scope="col" class="font-style-header">#</th>
                                            <th scope="col" class="font-style-header">فئه التبرع</th>
                                            <th scope="col" class="font-style-header">نوع النشاط</th>
                                            <th scope="col" class="font-style-header">نوع المشروع  </th>
                                            <th scope="col" class="font-style-header">   مكان المشروع</th>
                                            <th scope="col" class="font-style-header">المبلغ</th>
                                            <th *ngIf="receiptNumView" scope="col" class="font-style-header">رقم الايصال</th>
                                            <th scope="col" class="font-style-header">اعدادات</th>
                                        </tr>
                                        <tbody>
                                            <tr  *ngFor="let donation of DonationDetails let i = index">
                                                <td scope="row" class="font-style">{{i+1}}</td>
                                                <td>{{donation.donationtype}}</td>
                                                <td>{{donation.sector}}</td>
                                                <td>{{donation.sectortype}}</td>
                                                <td>{{donation.sectorplace}}</td>
                                                <td>{{donation.totalAmount}}</td>
                                                <td *ngIf="receiptNumView">{{donation.receiptNum}}</td>
                                                <td class="font-style text-center">
                                                    <button type="button" style="color: red; padding: 7px; background-color: transparent;"
                                                        (click)="DeleteDonationDetails(i)">
                                                        <i class="fa fa-trash"></i>
                                                    </button>

                                                    <button type="button" style="color: rgba(35, 118, 241, 0.667);padding: 7px;background-color: transparent;"
                                                        (click)="EditDonationDetails(i)">
                                                        <i class="fa fa-pencil"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div>

                                </div>
                            </div>
                        </div>
                        <div class="card" *ngIf="donationMainTypeId==2">
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table  class="table table-striped" style="width: 100%;">
                                        <thead>
                                        </thead>
                                        <tr class="bg-dark ">
                                            <th scope="col" style="text-align: center;" class="font-style-header">#</th>
                                            <th scope="col" style="text-align: center;" class="font-style-header">فئه التبرع</th>
                                            <th scope="col" style="text-align: center;" class="font-style-header">نوع التبرع</th>
                                            <th scope="col" style="text-align: center;" class="font-style-header">العدد</th>
                                        <th *ngIf="receiptNumView" scope="col" class="font-style-header">رقم الايصال</th> 
                                            <th scope="col" class="font-style-header">اعدادات</th>
                                        </tr>
                                        <tbody>
                                            <tr style="justify-content:initial" *ngFor="let donation of DonationDetails let i = index">
                                                <td style="text-align: center;" scope="row" class="font-style">{{i+1}}</td>
                                                <td style="text-align: center;">{{donation.donationcat}}</td>
                                                <td style="text-align: center;">{{donation.donationtypecat}}</td>
                                                <td style="text-align: center;">{{donation.quantity}}</td>
                                                 <td *ngIf="receiptNumView">{{donation.receiptNum}}</td> 
                                                <td style="text-align: center;" class="font-style text-center">
                                                    <button type="button" style="color: red; padding: 7px; background-color: transparent;"
                                                        (click)="DeleteDonationDetails(i)">
                                                        <i class="fa fa-trash"></i>
                                                    </button>

                                                    <button type="button" style="color: rgba(35, 118, 241, 0.667);padding: 7px; background-color: transparent;"
                                                        (click)="EditDonationDetails(i)">
                                                        <i class="fa fa-pencil"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div>

                                </div>
                            </div>
                        </div>
                        <div *ngIf="donationMainTypeId!=2" class="row m-0 text-center" >
                            <div class="col-3 col-md-3">
                                <h6 class="pb-2"> عدد التبرعات الفرعية  <br/><br/><span style="font-weight: bold;">{{ this.DonationDetails.length}}</span>  </h6>
                            </div>
                            <div class="col-3 col-md-3">
                                <h6 class="pb-2"> اجمالي التبرع المتوقع  <br/><br/><span style="font-weight: bold;">{{total}}</span>  </h6>
                            </div>
                            <div  class="col-3 col-md-3">
                                <h6 class="pb-2">اجمالي التبرعات الفرعية <br/><br/> <span style="font-weight: bold;">{{MoneyAmount}}</span> </h6>
                            </div>
                            <div class="col-3 col-md-3">
                                <h6 class="pb-2" >الفرق <br/><br/> <span style="font-weight: bold; color: red;">{{total - MoneyAmount}}</span> </h6>
                            </div>
                        </div>
                        

                        <div *ngIf="donationMainTypeId==2" class="row m-0 text-center" >
                            <div class="col-6 col-md-6">
                                <h6 class="pb-2"> عدد التبرعات الفرعية  <br/><br/><span style="font-weight: bold;">{{ this.DonationDetails.length}}</span>  </h6>
                            </div>
                            <div class="col-6 col-md-6">
                                <h6 class="pb-2"> اجمالي التبرع  <br/><br/><span style="font-weight: bold;">{{total}}</span>  </h6>
                            </div>
                        </div>
                        <div class="row m-0">
                            <!-- <button *ngIf="!editdonation" [disabled]="DonationDetails.length==0" class="btn btn-danger  btn-lg btn-block"
                            (click)="InsertCustomerDonation()">حفظ التبرع</button>
                            <button *ngIf="editdonation" [disabled]="DonationDetails.length==0" class="btn btn-danger  btn-lg btn-block"
                            (click)="UpdateCustomerDonation()"> تعديل التبرع</button> -->

                            <button  *ngIf="!editdonation" [disabled]="DonationDetails.length==0  || clicked" class="btn btn-danger  btn-lg btn-block"
                            >حفظ  </button>
                            <button *ngIf="editdonation" [disabled]="DonationDetails.length==0 || clicked" class="btn btn-danger  btn-lg btn-block"
                            > تعديل  </button>

                        </div>
                    </form>  
                      <button *ngIf="editdonation && UserId==CollectorId" 
                       [disabled]="DonationDetails.length==0 || clicked" (click)="UpdateCustomerDonation('updateAndCollect')" class="btn btn-success  btn-lg btn-block m-t-20"> تعديل وتحصيل </button>
                </div>


                <div>
                </div>
            </div>
        </div>
    </div>
</div>