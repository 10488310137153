<app-breadcrumb [title]="'قائمة انواع الفئات'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'">
</app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header px-0 " style="padding-bottom: 5px;">
                    <div class="row m-0 mb-2">
                        <div class="col-12">
                            <h5 class="py-2">انواع الفئات</h5>
             
                        </div>
                        <div class="col-3 mt-3">
                            <div class="form-group">
                                <label for="name">اسم الشركة</label>
                                <ng-select class="js-example-basic-single"
                                        aria-label="Default select example"
                                        (change)="SelectCompany($event?.id)"
                                        required="required"
                                        [items]="Companies"
                                        [(ngModel)]="CompanyId"
                                        bindLabel="name"
                                        bindValue="id"
                                         >
              
                                </ng-select>
                              </div>
                              </div>
                        <div class="col-3  mt-3">
                            <div class="form-group">
                                <label for="name">أختر  فئه النشاط</label>
                                <ng-select class="js-example-basic-single" #sectorSelect
                                    aria-label="Default select example" 
                                    (change)="SelectedSector($event?.id)"
                                    [items]="Sector_List"
                                    [(ngModel)]="Sector_id"
                                    bindLabel="title"
                                    bindValue="id"
                                    >
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-3 col-md-3 mt-3 d-flex  align-items-center p-0">
                            <div class="btnSearch" (click)="Search()" >
                                 <span class="mt-1">  بحث  </span>
                            </div>
                        </div>
                        <div class="col-3 col-md-3 mt-3 d-flex justify-content-end align-items-center p-0">
                            <div class="addnew-btn" (click)="AddNew()" appActionNew>
                                <i class="fa fa-plus "></i> <span class="mt-1">جديد</span>
                                <span class="tooltiptext"> إضافة نوع نشاط جديد</span>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table" style="width: 100%;">
                        <thead>
                            <tr class="bg-dark ">
                                <th scope="col" class="font-style-header" >#</th>
                                <th scope="col" class="font-style-header" >ش</th>
                                <th scope="col" class="font-style-header" >أسم النشاط </th>
                                <th scope="col" class="font-style-header" >أسم نوع النشاط </th>
                                <th scope="col" class="font-style-header" > </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of Filtered_List; let i = index;">
                                <td scope="row" class="font-style" >{{i+1}}</td>
                                <td scope="row" class="font-style"><img width="30px" height="30px" [src]="imgUrlServer+item.companyIcon"></td>
                                <td  class="font-style">{{item.sectorTitle}} </td>
                                <td  class="font-style">{{item.title}}</td>
                                <td class="font-style" style="text-align: end; width: 8%;"  [ngStyle]="{ 'background-color': item.companyColor  }">
                                  <button appActionEdit style="color: rgba(35, 118, 241, 0.667);padding: 7px; background-color: transparent;"(click)="update(item.id,item.title,item.sectorId)">
                                        <i class="fa fa-pencil" ></i>
                                    </button>            
                                    <button appActionDelete style="color: red; padding: 7px; background-color: transparent;"(click)="Delete(item.id)">
                                        <i class="fa fa-trash" ></i>
                                    </button>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br>
                    <div class="d-flex justify-content-center">
                      <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
                        firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
                        (pageChanged)="pageChanged($event)">
                      </pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>