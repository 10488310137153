export class insertSectorType {
  [x: string]: any;

    id?:number;

    title: string;
    numberofDays: number;
    companyId:number=null;
    sectorId: number=null;
    managersIds:any[]=[];
    cost:number=null
   
}
export class UpdateSectorType {
    id:number;
    title: string;
    companyId:number=null;
    sectorId: number;
    numberofDays: number;
    managersIds:[]=[];
    cost:number=null
}