import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericResponse } from '../Models/GenericResponse';
import { GetCompanyType } from '../Models/GetClientType'
import { environment } from '../../../environments/environment.prod';
import { InsertClientType, UpdateCompanyType } from '../Models/insert-client-type';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';


@Injectable({
  providedIn: 'root'
})

export class ClientTypeApiService {

  //#region Declare variables
  title:string;
  //#endregion
  //#region  constructor
  constructor(private http:HttpClient) { }
  //#endregion

    //#region Options
    httpOptionsWithTocken = { headers: new HttpHeaders({ 'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhaG1lZGFiZHVsbGFoMjUwIiwianRpIjoiZDIwZjU0MGUtMjhiNy00YmNjLWE4ZDgtNzkxNzA2YzJmZDRhIiwiZW1haWwiOiJhaG1lZGFiZHVsbGFoQHlhaG9vLmNvbSIsInVpZCI6IjBiMzg5N2FiLTQ2ZmMtNGM0Yy04MTYyLTRiNDExZTY4OWE1NCIsInJvbGVzIjoiVVNFUiIsImV4cCI6MTYzODM2OTM3NSwiaXNzIjoiU2VjdXJlQXBpIiwiYXVkIjoiU2VjdXJlQXBpVXNlciJ9.55LorE6Fclj3buy1Qw8wZ6CEe_ifW5jxwHe25wHRWsQ', 'Accept': ' */*' }) };
    //#endregion

    GetClientType(): Observable<GenericResponse<GetCompanyType>> {
      return this.http.get<GenericResponse<GetCompanyType>>(`${environment.Server_URL}/CompanyType/Get`);
    }
    GetClientTypeList(data:any): Observable<GenericResponse<GetCompanyType>> {
      return this.http.post<GenericResponse<GetCompanyType>>(`${environment.Server_URL}/CompanyType/GetCompanyTypeList`,data);
    }
    GetClientTypeById(id:number): Observable<GenericResponse<GetCompanyType>> {
      return this.http.get<GenericResponse<GetCompanyType>>(`${environment.Server_URL}/CompanyType/GetById?Id=${id}`);
    }
  
    InsertClientType(Data:InsertClientType): Observable<GenericResponseSingle<InsertClientType>> {
      return this.http.post<GenericResponseSingle<InsertClientType>>(`${environment.Server_URL}/CompanyType/Create`,Data);
    }

    UpdateClientType(Data:UpdateCompanyType): Observable<GenericResponseSingle<UpdateCompanyType>> {
      return this.http.post<GenericResponseSingle<UpdateCompanyType>>(`${environment.Server_URL}/CompanyType/Update`,Data);
    }

    DeleteClientType(ClientId:number): Observable<GenericResponseSingle<InsertClientType>> {
      return this.http.get<GenericResponseSingle<InsertClientType>>(`${environment.Server_URL}/CompanyType/Delete?id=${ClientId}`);
      // change detete
    }
}
