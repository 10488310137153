import { SectorLocationApiService } from 'src/app/shared/API-Service/sector-location-api.service';
import { SectorTypeApiService } from './../../../../shared/API-Service/sector-type-api.service';
import { DatePipe } from '@angular/common';
import { FormGroup, FormControl } from '@angular/forms';
import { DonationRequests } from '../../../../shared//Models/donation-requests';
import { SourceOfmarketingService } from './../../../../shared/API-Service/source-ofmarketing.service';
import { CitiesApiService } from 'src/app/shared/API-Service/cities-api.service';
import { GovernorateApiService } from 'src/app/shared/API-Service/governorate-api.service';
import { DonationRequestsService } from './../../../../shared/API-Service/donation-requests.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-donation-requests',
  templateUrl: './donation-requests.component.html',
  styleUrls: ['./donation-requests.component.css']
})
export class DonationRequestsComponent implements OnInit {
  Getcollectors
  activePM:boolean = false
  Governorate_List:any
  Filtered_List
  Response_List: any[];
  GetLookupsGetDonation
  DonationType
  SectorLocation
  SectorGet
  Governorate_Dictionary
  getType:any[]=[]
  City_list
  donationRequest = new DonationRequests()
  currentUser: any;
  Companies: any[];
  constructor( private CompanyDataService:CompanyDataService,private DonationRequestsService:DonationRequestsService,private governorateApiService:GovernorateApiService,private citiesApiService:CitiesApiService,
    private SourceOfmarketingService:SourceOfmarketingService,public datepipe:DatePipe,private router:Router,private SectorTypeApiService:SectorTypeApiService,
    private SectorLocationApiService:SectorLocationApiService
    ) {
      this.activePM=false;
      this.currentUser=JSON.parse(localStorage.getItem("RiskCurrentUser"))
       //  this.currentUser.roleId=10
       if(this.currentUser.roleId==10){
        this.donationRequest.companyId=this.currentUser.id
        this.GetSector(this.currentUser.id)
      }
      this.donationRequest.reportCreatedBy=this.currentUser.name;
      const date = new Date();
      this.donationRequest.creationDateFrom=this.datepipe.transform(date, 'yyyy-MM-dd');
      this.donationRequest.creationDateTo=this.datepipe.transform(date,'yyyy-MM-dd');
      this.donationRequest.collectionDateFrom=this.datepipe.transform(date, 'yyyy-MM-dd');
      this.donationRequest.collectionDateTo=this.datepipe.transform(date,'yyyy-MM-dd');
     }

  ngOnInit(): void {
   this.getcollectors()
   this.getgevernrate()
   this.Getcites()
   this.GetLookupsGetDonationStatu()
   this.GetDonationType()
   this.GetCompanies()
  }

  getcollectors(){
    this.DonationRequestsService.Getcollectors().subscribe(response=>{

      this.Getcollectors = response.data
    })
  }

  getgevernrate(){
    this.governorateApiService.GetGovernorate().subscribe(response=>{
      this.Governorate_List = response.data
    })
  }
  Getcites(){
     this.citiesApiService.GetCitiesWithGovernorate().subscribe(response=>{
        this.Filtered_List = response.data
        this.Response_List = response.data

     })
  }
  SelectCompany(CompanyId){
    this.donationRequest.companyId=parseInt(CompanyId);
    this.SectorGet =[];
    this.donationRequest.sectorId=null;
    this.donationRequest.sectorTypeId=null;
    this.donationRequest.sectorLocationId=null;
     this.GetSector(CompanyId);
  }
  GetCompanies(){
    this.CompanyDataService.GetCompanies().subscribe(res=>{
      this.Companies=res.data
    })
  }

  SelectedGovernorate(event:any){
    if (this.donationRequest.governoratId==null) {
      this.donationRequest.governoratId=null
      this.donationRequest.governoratName=''
       this.City_list=[];
     } 
     this.donationRequest.cityId = null
     this.donationRequest.cityName = ''
   
    this.citiesApiService.GetCitiesWithGovernorate(this.donationRequest.governoratId)
    .subscribe(
      response => {
        this.City_list = response.data;
       
      },
    )
    this.donationRequest.governoratId = parseInt(event)
    this.donationRequest.governoratName = this.Governorate_List.find(x => x.id == event).title
    // this.donationRequest.governoratName = this.Governorate_List.title
  }

  GetLookupsGetDonationStatu(){
    this.SourceOfmarketingService.GetLookupsGetDonationStatu().subscribe(response=>{
      this.GetLookupsGetDonation = response.data
    })
  }


  GetDonationType(){
    this.SourceOfmarketingService.GetDonationType().subscribe(response=>{
        this.DonationType = response.data
    })
  }

  

  GetSectorTypeGetById(event:any){
    if (this.donationRequest.sectorTypeId==-1) {
      this.donationRequest.sectorTypeId=null
      this.donationRequest.sectorTypeName=''
       this.SectorLocation=[];
     } 
     else{
        
    this.SectorLocationApiService.GetSectorLocationReportFilter(this.donationRequest.sectorTypeId).subscribe((response:any)=>{
           this.SectorLocation = response.data
     })
      this.donationRequest.sectorTypeId = parseInt(event)
      this.donationRequest.sectorTypeName = this.getType.find(x => x.id == event).title
     }
     this.donationRequest.sectorLocationId= null  
     this.donationRequest.sectorLocationName = ''
  
 }
 GetSectorLocationGetById(event){
  
  this.donationRequest.sectorLocationId = parseInt(event)
  this.donationRequest.sectorLocationName = this.SectorLocation.find(x => x.id == event).title

 }

 GetSector(companyId:any){
    this.SourceOfmarketingService.getSectorsBycompanyId(companyId).subscribe(res=>{
   this.SectorGet = res.data
  //  res.data.forEach(element => {
  //    this.Governorate_Dictionary[element.id] = element.title;            
  //  });
  })
}


GetSectorGetById(event){
  if (this.donationRequest.sectorId==null) {
    this.donationRequest.sectorId=null
    this.donationRequest.sectorName=''
    this.getType=[];
    this.SectorLocation=[];
   } 
  
  else{
      this.SectorTypeApiService.getSectorType(this.donationRequest.sectorId,this.donationRequest.companyId).subscribe((response: any)=>{
    this.getType = response.data
  })
  this.donationRequest.sectorId = parseInt(event)
  this.donationRequest.sectorName = this.SectorGet.find(x => x.id == event).title
  }
  this.donationRequest.sectorTypeId =null
  this.donationRequest.sectorTypeName=''
  this.donationRequest.sectorLocationId = null
  this.donationRequest.sectorLocationName = ''
}


getDonationRequests(event){
  this.donationRequest.collectorId = parseInt(event) 
  this.donationRequest.collectorName = this.Getcollectors.find(x => x.id == event).name
}

SelectedCity(event:any){

  
  this.donationRequest.cityId = parseInt(event) 
  this.donationRequest.cityName = this.City_list.find(x => x.id == event).title
 
}

getstatus(event){
  this.donationRequest.donationStatus = parseInt(event) 
  this.donationRequest.donationStatusName = this.GetLookupsGetDonation.find(x => x.id == event).title
}

getDonationType(event){
  this.donationRequest.donationTypeId = parseInt(event) 
  this.donationRequest.donationTypeName = this.DonationType.find(x => x.id == event).title
}

submit(){

  let acivePMCheck = <HTMLInputElement>document.getElementById('pmActive');
  if (acivePMCheck.checked != true) {
    this.donationRequest.isReceivedApproved = null
  }else{
    this.donationRequest.isReceivedApproved= true
  }

if (this.donationRequest.collectorId ==null) {
  this.donationRequest.collectorId = null
  this.donationRequest.collectorName = ''

}

if (this.donationRequest.governoratId==null) {
  this.donationRequest.governoratId= null;
  this.donationRequest.governoratName = ''


}

if(this.donationRequest.cityId==null){
  this.donationRequest.cityId = null
  this.donationRequest.cityName = ''
}

if (this.donationRequest.donationStatus ==null) {
  this.donationRequest.donationStatus = null
  this.donationRequest.donationStatusName = ''
}


if (this.donationRequest.donationTypeId==null) {
  this.donationRequest.donationTypeId = null
  this.donationRequest.donationTypeName = ''

}

if (this.donationRequest.sectorId ==null ) {
  this.donationRequest.sectorId=null
  
  this.donationRequest.sectorName=''

}

if(this.donationRequest.sectorTypeId==null){
  this.donationRequest.sectorTypeId =null
  this.donationRequest.sectorTypeName=''

}

if( this.donationRequest.sectorLocationId==null){
  this.donationRequest.sectorLocationId =null
  this.donationRequest.sectorLocationName=''
  
}



// 



  localStorage["ReportName"]="CollectorDonationsReport";
  localStorage.setItem('ReportFilter',JSON.stringify(this.donationRequest));

  this.router.navigate([]).then((result) => {
    window.open("/content/admin/call-start-end-report", '_blank');
  });
}
Viewreports(){
  if(this.donationRequest.companyId!=null&&this.donationRequest.creationDateFrom &&this.donationRequest.creationDateTo ){
this.submit()
 
}
else if (this.donationRequest.companyId!=null&&this.donationRequest.collectionDateFrom &&this.donationRequest.collectionDateTo){
  this.submit()

}
else{
  if(!this.donationRequest.companyId){
    Swal.fire({
      icon:'error',
      title:'خطأ',
      text:'يجب اختيار الشركة'

    })
  }
else   if (!this.donationRequest.creationDateFrom){
    Swal.fire({
      icon:'error',
      title:'خطأ',
      text:' يجب اختيار تاريخ من والى تبرعات منشأة او محصلة'
    })
  }
  else  if (!this.donationRequest.creationDateTo ){
    Swal.fire({
      icon:'error',
      title:'خطأ',
      text:' يجب اختيار تاريخ من والى تبرعات منشأة او محصلة'

    })
  }
  else  if (!this.donationRequest.collectionDateFrom ){
    Swal.fire({
      icon:'error',
      title:'خطأ',
      text:' يجب اختيار تاريخ من والى تبرعات منشأة او محصلة'

    })
  }
  else  if (!this.donationRequest.collectionDateTo ){
    Swal.fire({
      icon:'error',
      title:'خطأ',
      text:' يجب اختيار تاريخ من والى تبرعات منشأة او محصلة'

    })
  }
 
}

}

}
