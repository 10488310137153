import { Component, Input, OnInit } from '@angular/core';
import { SmsTemplateService } from 'src/app/shared/API-Service/sms-template.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-send-sms',
  templateUrl: './send-sms.component.html',
  styleUrls: ['./send-sms.component.css']
})
export class SendSMSComponent implements OnInit {
  SmsTemplates: any[];
  customerid: any;
  MessageId: any;
  donationId: any;
  @Input() CompanyId: string;
  constructor(private SmsTemplateService : SmsTemplateService) { }

  ngOnInit(): void {
 
    this.GetSMSTemplate();
  
  if(localStorage.getItem("donationId")){
    this.donationId=  localStorage.getItem("donationId");
    }
    else if (localStorage.getItem("customerid")){
   this.customerid=   Number(localStorage.getItem("customerid"));
    }
    
  }
  send(){
    if(this.MessageId){
      if(this.customerid){
        let data ={
        "smsTemplateId": Number(this.MessageId),
        "customerId": this.customerid,
        "companyId":this.CompanyId
    
      } 
       this.SmsTemplateService.SendSMS(data).subscribe(res=>{
      Swal.fire(
        'تم الارسال!',
        'تم ارسال الرسالة بنجاح.',
        'success'
      )
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      })
      }
      else{
        let data ={
          "smsTemplateId": Number(this.MessageId),
          "donationId": this.donationId,
          "companyId":this.CompanyId
      
        } 
         this.SmsTemplateService.SendSMS(data).subscribe(res=>{
        Swal.fire(
          'تم الارسال!',
          'تم ارسال الرسالة بنجاح.',
          'success'
        )
        },
        err => {
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: err.error.message,
          })
        })
      }
    }
    else{
      
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: 'يجب اختيار الرسالة',
        })
      }
    
   
          
   
  }
  GetSMSTemplate(){
    if(this.CompanyId){
         this.SmsTemplateService.getSmsTemplates(this.CompanyId).subscribe(res=>{
        this.SmsTemplates=res.data;
      })
    }
   else{
    this.SmsTemplateService.getSmsTemplates().subscribe(res=>{
      this.SmsTemplates=res.data;
    })
   }
    
  }
  SelectedMessage(id){
this.MessageId=id;
  }
}
