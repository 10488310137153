import { SectorLocationApiService } from './../../../../shared/API-Service/sector-location-api.service';
import { SectorTypeApiService } from './../../../../shared/API-Service/sector-type-api.service';
import { Router } from '@angular/router';
import { SourceOfmarketingService } from './../../../../shared/API-Service/source-ofmarketing.service';
import { Component, OnInit } from '@angular/core';
import { ProjectDonationsTypesReport } from '../../../../shared//Models/ProjectDonationsTypesReport';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-project-full-state-report',
  templateUrl: './project-full-state-report.component.html',
  styleUrls: ['./project-full-state-report.component.css']
})
export class ProjectFullStateReportComponent implements OnInit {
  ProjectDonationsTypesReport= new ProjectDonationsTypesReport();
  GetLookupsGetDonation
  DonationType
  SectorGet
  getType
  SectorLocation
  currentUser: any;
  Companies: any[];
  constructor(private CompanyDataService:CompanyDataService,public datepipe:DatePipe, private SourceOfmarketingService:SourceOfmarketingService,private router:Router,private SectorTypeApiService:SectorTypeApiService,private SectorLocationApiService:SectorLocationApiService) {
    this.currentUser=JSON.parse(localStorage.getItem("RiskCurrentUser"))
    if(this.currentUser.roleId==10){
      this.ProjectDonationsTypesReport.companyId=this.currentUser.id
      this.GetSector(this.currentUser.id)
    }
    const date = new Date();
    this.ProjectDonationsTypesReport.dateFrom=this.datepipe.transform(date, 'yyyy-MM-dd');
    this.ProjectDonationsTypesReport.dateTo=this.datepipe.transform(date,'yyyy-MM-dd');
   }

   GetLookupsGetDonationStatu(){
    this.SourceOfmarketingService.GetLookupsGetDonationStatu().subscribe(response=>{
  
      this.GetLookupsGetDonation = response.data
    })
  }
  ngOnInit(): void {
    this.GetLookupsGetDonationStatu()
    this.GetDonationType()
    this.GetCompanies()
  }
  GetDonationType(){
    this.SourceOfmarketingService.GetDonationType().subscribe(response=>{
        this.DonationType = response.data
      
    })
  }

  GetSector(CompanyId:any){
    this.SourceOfmarketingService.getSectorsBycompanyId(CompanyId).subscribe(res=>{
     this.SectorGet = res.data
    
    })
 }
 SelectCompany(CompanyId){
  this.ProjectDonationsTypesReport.companyId=parseInt(CompanyId);
  this.SectorGet =[];
  this.ProjectDonationsTypesReport.sectorId=null;
  this.ProjectDonationsTypesReport.sectorTypeId=null;
  this.ProjectDonationsTypesReport.sectorLocationId=null;
   this.GetSector(CompanyId);
}
GetCompanies(){
  this.CompanyDataService.GetCompanies().subscribe(res=>{
    this.Companies=res.data
  })
}

 GetSectorGetById(event:any){
  if (this.ProjectDonationsTypesReport.sectorId==null) {
    this.ProjectDonationsTypesReport.sectorId=null
    this.ProjectDonationsTypesReport.sectorName=''
     this.ProjectDonationsTypesReport.sectorTypeId = null
   this.ProjectDonationsTypesReport.sectorTypeName=''
   this.ProjectDonationsTypesReport.sectorLocationId = null
   this.ProjectDonationsTypesReport.sectorLocationName = '';
   this.getType=[];
   this.SectorLocation=[]
   } 
 else{
      this.SectorTypeApiService.getSectorType(this.ProjectDonationsTypesReport.sectorId,this.ProjectDonationsTypesReport.companyId).subscribe(response=>{

    this.getType = response.data
  })

  this.ProjectDonationsTypesReport.sectorId = parseInt(event)
  this.ProjectDonationsTypesReport.sectorName = this.SectorGet.find(x => x.id == event).title
 }

}

GetSectorTypeGetById(event:any){

  if (this.ProjectDonationsTypesReport.sectorTypeId==null) {
    this.ProjectDonationsTypesReport.sectorTypeId=null
    this.ProjectDonationsTypesReport.sectorTypeName=''
      this.ProjectDonationsTypesReport.sectorLocationId = null
   this.ProjectDonationsTypesReport.sectorLocationName = ''
   this.SectorLocation=[];
   } 
   else{
     this.SectorLocationApiService.GetSectorLocationReportFilter(this.ProjectDonationsTypesReport.sectorTypeId).subscribe((response:any)=>{

   this.SectorLocation = response.data
 })

 this.ProjectDonationsTypesReport.sectorTypeId = parseInt(event)
  this.ProjectDonationsTypesReport.sectorTypeName = this.getType.find(x => x.id == event).title
   }


   
}




donationTypee(event){
  this.ProjectDonationsTypesReport.donationTypeId = parseInt(event) 
  this.ProjectDonationsTypesReport.donationTypeName = this.DonationType.find(x => x.id == event).title

 }

 loaction(event){
  this.ProjectDonationsTypesReport.sectorLocationId = parseInt(event) 
  this.ProjectDonationsTypesReport.sectorLocationName = this.SectorLocation.find(x => x.id == event).title
 }


  

  Viewreports(){
    if(this.ProjectDonationsTypesReport.companyId!=null&&this.ProjectDonationsTypesReport.dateFrom &&this.ProjectDonationsTypesReport.dateTo){
    this.ProjectDonationsTypesReport.reportCreatedBy=this.currentUser.name;
if (this.ProjectDonationsTypesReport.donationTypeId==null) {
  this.ProjectDonationsTypesReport.donationTypeId =null
  this.ProjectDonationsTypesReport.donationTypeName=''
}

if (this.ProjectDonationsTypesReport.sectorId ==null ) {
  this.ProjectDonationsTypesReport.sectorId=null 
  this.ProjectDonationsTypesReport.sectorName=''
}

if(this.ProjectDonationsTypesReport.sectorTypeId==null){
  this.ProjectDonationsTypesReport.sectorTypeId=null
  this.ProjectDonationsTypesReport.sectorTypeName=''
}

if(this.ProjectDonationsTypesReport.sectorLocationId==null){
  this.ProjectDonationsTypesReport.sectorLocationId=null
  this.ProjectDonationsTypesReport.sectorLocationName=''

}
    localStorage["ReportName"]="ProjectDonationsTypesReport";
    localStorage.setItem('ReportFilter',JSON.stringify(this.ProjectDonationsTypesReport));
  
    this.router.navigate([]).then((result) => {
      window.open("/content/admin/call-start-end-report", '_blank');
    });
  }
  else{
    if(!this.ProjectDonationsTypesReport.companyId){
      Swal.fire({
        icon:'error',
        title:'خطأ',
        text:'يجب اختيار الشركة'
  
      })
    }
  else   if (!this.ProjectDonationsTypesReport.dateFrom){
      Swal.fire({
        icon:'error',
        title:'خطأ',
        text:' يجب اختيار التبرعات من تاريخ '
      })
    }
    else  if (!this.ProjectDonationsTypesReport.dateTo ){
      Swal.fire({
        icon:'error',
        title:'خطأ',
        text:' يجب اختيار التبرعات الى تاريخ '
  
      })
    }
}
}


}
