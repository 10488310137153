<app-breadcrumb [title]="'قائمة أنواع العملاء'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header px-0" style="padding-bottom: 5px;">
              <div class="row m-0" >
                  <div class="col-12 col-md-9">
                    <h5 class="py-2">أنواع العملاء</h5>
                    <span style="font-size: 16px;">  إعدادت أنواع العملاء الموجودة بالنظام ( عرض - تعديل - حذف ) </span>
                  </div>
                  <div class="col-12 col-md-3 d-flex justify-content-end align-items-center p-0">
                      <div appActionNew class="addnew-btn" (click)="AddNew()">
                        <i class="fa fa-plus "></i> <span class="mt-1">جديد</span>
                        <span class="tooltiptext"> إضافة نوع جديد</span>
                           
                      </div>
                  </div>
              </div>
          
          </div>
          <div class="table-responsive">
            <table class="table" style="width: 100%;">
              <thead>
                <tr class="bg-dark ">
                  <th scope="col" class="font-style-header" >#</th>
                  <th scope="col" class="font-style-header">نوع العميل</th>
                  <th scope="col" class="font-style-header"> </th>
                </tr>
              </thead>
              <tbody >
                <tr *ngFor="let item of Client_Type_List; let i = index; ">
                  <td scope="row" class="font-style" >{{i+1}}</td>
                  <td  class="font-style">{{item.title}}</td>
                  <td class="font-style " style="width: 8%;" > 
                     <Button appActionEdit class=""style="color: rgba(35, 118, 241, 0.667);padding: 7px;background-color: transparent;" (click)="updateClient(item.id, item.title)" > <i class="fa fa-pencil" ></i>
                        </Button>
                        <button appActionDelete class=""style="color: red; padding: 7px;background-color: transparent;"
                                (click)="DeleteClient(item.id)">
                                <i class="fa fa-trash" ></i>
                        </button>
                      
                        </td>
                </tr>
              </tbody>
            </table>
            <br>
            <div class="d-flex justify-content-center">
              <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
                firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
                (pageChanged)="pageChanged($event)">
              </pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          ...
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>