<app-breadcrumb [title]="'إدارة مسئولى النظام'" [active_item]="'Form Wizard'"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding-bottom: 5px !important">
          <div *ngIf="update; else elseBlock1">
            <h5 class="pb-2">تعديل مسئول موجود</h5>
            يمكنك تعديل مسئول أو أكثر فى قائمة مسئولى النظام
          </div>
          <ng-template #elseBlock1>
            <h5 class="pb-2">إضافة مسئول جديد</h5>
            <span style="font-size: 14px">
              يمكنك إضافة مسئول أو أكثر فى قائمة المسئولين
            </span>
          </ng-template>
        </div>
        <div class="card-body">
          <form class="needs-validation" [formGroup]="EmployeeForm" (ngSubmit)="onSubmit()" novalidate
            [ngClass]="{ 'was-validated': validated }">
            <!-- name -->

            <div class="form-row">
              <div class="col-lg-4 col-md-6 mb-3">
                <label for="validationCustom01">
                  <span class="validation-required">*</span>
                  أسم الموظف بالكامل</label>
                <input class="form-control" id="validationCustom01" type="text" placeholder="" required=""
                  formControlName="name" [(ngModel)]="employeeInfo.name" /><!-- onlyAlphabets -->
                <div class="valid-feedback">حسنًا</div>
              </div>

              <!-- username -->
              <div class="col-lg-4 col-md-6 mb-3">
                <label for="validationCustom02">
                  <span class="validation-required">*</span> أسم المستخدم</label>
                <input class="form-control" id="validationCustom02" type="text" placeholder="" required="required"
                  formControlName="userName" [(ngModel)]="employeeInfo.userName" /><!-- onlyAlphabets -->

                <div class="valid-feedback">حسنًا</div>
              </div>

              <!-- password -->
              <div *ngIf="!update" class="col-lg-4 col-md-6 mb-3">
                <label for="Password"> كلمة المرور</label>

                <input class="form-control" id="validationCustom02" type="password" placeholder=""
                  formControlName="passwordHash" [(ngModel)]="employeeInfo.passwordHash" />
              </div>

              <!-- mobile -->
              <div class="col-lg-4 col-md-6 mb-3">
                <label for="validationCustom05"><span class="validation-required">*</span> رقم الموبايل</label>
                <input class="form-control" id="validationCustom05" onlyNumbers type="text" placeholder=""
                  required="required" formControlName="mobile" [(ngModel)]="employeeInfo.mobile"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  maxlength="11" minlength="11" />


              </div>

              <div class="col-lg-4 col-md-6 mb-3">
                <label for="mobilenumber2">
                  <span class="validation-required">*</span> رقم الموبايل 2</label>
                <input class="form-control" id="mobilenumber2" onlyNumbers type="text" placeholder=""
                  formControlName="mobile1" [(ngModel)]="employeeInfo.mobile1"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  maxlength="11" minlength="11" />
              </div>

              <div class="col-lg-4 col-md-6 mb-3">
                <label for="mobilenumber3"><span class="validation-required">*</span> رقم الموبايل 3</label>
                <input class="form-control" id="mobilenumber3" onlyNumbers type="text" placeholder=""
                  formControlName="mobile2" [(ngModel)]="employeeInfo.mobile2"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  maxlength="11" minlength="11" />
              </div>

              <div class="col-lg-4 col-md-6 mb-3">
                <label for="mobilenumber4"><span class="validation-required">*</span> رقم الموبايل 4</label>
                <input class="form-control" id="mobilenumber4" onlyNumbers type="text" placeholder=""
                  formControlName="mobile3" [(ngModel)]="employeeInfo.mobile3"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  maxlength="11" minlength="11" />
              </div>

              <!-- Job -->

              <div class="col-lg-4 col-md-6 mb-3">
                <label> <span class="validation-required">*</span> الوظيفة</label>

                <ng-select class="js-example-basic-single" name="job" placeholder="إختر الوظيفة" formControlName="jobId"
                  [(ngModel)]="employeeInfo.jobId" required="" [items]="Jobs_List" bindLabel="title" bindValue="id">
                </ng-select>
              </div>
              <!-- national id -->
              <div class="col-lg-4 col-md-6 mb-3">
                <label for="validationCustom04"><span class="validation-required">*</span> الرقم القومى</label>
                <input class="form-control" id="validationCustom04" type="text" placeholder=" " required="required"
                  formControlName="nationalId" [(ngModel)]="employeeInfo.nationalId"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  maxlength="14" minlength="14" />
              </div>

              <!-- address -->
              <div class="col-12 mb-3">
                <label for="validationCustom06"><span class="validation-required">*</span> العنوان</label>
                <textarea formControlName="address" id="validationCustom06" required=""
                  class="form-control textForArabic" placeholder="   " [(ngModel)]="employeeInfo.address"
                  style="height: 120px"></textarea>
                <div class="valid-feedback">حسنًا</div>
              </div>

              <!-- City -->

              <!-- Role -->

              <div class="col-lg-4 col-md-6 mb-3">
                <label for="validationCustom07"><span class="validation-required">*</span> تحديد المسؤليه</label>
                <ng-select class="js-example-basic-single" formControlName="roleId" [(ngModel)]="employeeInfo.roleId"
                  aria-label="Default select example" id="validationCustom07" onlyAlphabetsselected required="required"
                  [items]="Role_List" bindLabel="name" bindValue="id">
                </ng-select>
              </div>

              <!-- Military Status -->

              <div class="col-lg-4 col-md-6 mb-3">
                <label> <span class="validation-required">*</span> الموقف من التجنيد</label>
                <ng-select class="js-example-basic-single" formControlName="militaryStatusId"
                  aria-label="Default select example" [(ngModel)]="employeeInfo.militaryStatusId" required="required"
                  [items]="Military_List" bindLabel="title" bindValue="id">
                </ng-select>
              </div>

              <!-- Marital Status -->

              <div class="col-lg-4 col-md-6 mb-3">
                <label> <span class="validation-required">*</span> الحالة الإجتماعية</label>
                <ng-select class="js-example-basic-single" formControlName="maritalStatusId"
                  aria-label="Default select example" [(ngModel)]="employeeInfo.maritalStatusId" required="required"
                  [items]="Marital_List" bindLabel="title" bindValue="id">
                  <ng-option value=null>إختر الحالة الإجتماعية</ng-option>
                  <ng-option *ngFor="let obj of Marital_List" value="{{obj.id}}">
                    {{ obj.title }}
                  </ng-option>
                </ng-select>
              </div>

              <!-- Relegion -->

              <div class="col-lg-4 col-md-6 mb-3">
                <label> <span class="validation-required">*</span> الديانة</label>
                <ng-select class="js-example-basic-single" [(ngModel)]="employeeInfo.religionId" required="required"
                  formControlName="religionId" aria-label="Default select example" [items]="Religion_List"
                  bindLabel="title" bindValue="id">
                </ng-select>
              </div>

              <!-- Nationality -->

              <div class="col-lg-4 col-md-6 mb-3">
                <label> <span class="validation-required">*</span> الجنسية</label>
                <ng-select class="js-example-basic-single" formControlName="nationalityId"
                  aria-label="Default select example" [(ngModel)]="employeeInfo.nationalityId" required="required"
                  [items]="Nationality_List" bindLabel="title" bindValue="id">
                </ng-select>

              </div>

              <div class="col-lg-4 col-md-6 mb-3">
                <label for="validationCustom09"><span class="validation-required">*</span> المحافظة</label>
                <ng-select class="js-example-basic-single" id="validationCustom09" formControlName="governorateId"
                  [(ngModel)]="employeeInfo.governoratId" required="required" aria-label="Default select example"
                  (change)="SelectedGovernorate($event?.id)" [items]="Governorate_List" bindLabel="title"
                  bindValue="id">
                </ng-select>

              </div>

              <!-- City -->
              <div class="col-lg-4 col-md-6 mb-3">
                <label for="validationCustom08">
                  <span class="validation-required">*</span> المدينة</label>
                <ng-select #citySelect class="js-example-basic-single" id="validationCustom08" formControlName="cityId"
                  [(ngModel)]="employeeInfo.cityId" aria-label="Default select example" required="required"
                  [items]="City_list" bindLabel="title" bindValue="id">
                </ng-select>
                <div class="valid-feedback">حسنًا</div>
              </div>

              <!-- Gender -->

              <div class="col-lg-4 col-md-6 mb-3">
                <label> <span class="validation-required">*</span> النوع</label>
                <ng-select name="" id="" class="js-example-basic-single" formControlName="genderId" required="required"
                  [(ngModel)]="employeeInfo.genderId" [items]="gender" bindLabel="title" bindValue="id">
                  <!-- <ng-option value=null>إختر النوع</ng-option>
<ng-option value="1">ذكر</ng-option>
<ng-option value="2">أنثى</ng-option> -->
                </ng-select>

              </div>

              <!-- Birth Date -->

              <div class="col-lg-4 col-md-6 mb-3">
                <label> <span class="validation-required">*</span> تاريخ الميلاد</label>
                <input type="date" class="form-control" formControlName="birthdate" required="required"
                  [(ngModel)]="employeeInfo.birthdate" />
              </div>

              <!-- Hiring Date -->

              <div class="col-4 col-md-6 mb-3">
                <label>تاريخ التعين</label>
                <input type="date" class="form-control" formControlName="hiringDate" required="required"
                  [(ngModel)]="employeeInfo.hiringDate" />
              </div>
              <div class="col-4 col-md-6 mb-3 text-center">
                <label for="">غير نشط</label>
                <br>
                <label class="switch">
                  <input type="checkbox" id="inactive" (change)="inactiveChange()" [checked]="inactive == true" />
                  <span class="slider round"></span>
                </label>
              </div>
              <div class="col-6 mb-3">
                <label for="validationCustom01"><span class="validation-required">*</span> العملاء</label>

                <ng-multiselect-dropdown id="validationCustom02=3" placeholder=" أختر نوع عميل أو أكتر    "
                  [settings]="dropdownSettings" formControlName="Companies" [data]="dropdownList"
                  [(ngModel)]="selectedItems">
                </ng-multiselect-dropdown>
              </div>
              <div class="col-lg-6 col-md-6 mb-3">
                <label for="validationCustom07"><span class="validation-required">*</span> المكتب</label>
                <ng-select class="js-example-basic-single" formControlName="OfficeId"
                  [(ngModel)]="employeeInfo.officeId" aria-label="Default select example" id="validationCustom07"
                  onlyAlphabetsselected required="required" [items]="Offices" bindLabel="title" bindValue="id">
                </ng-select>
              </div>
              <!-- comment -->
              <div class="col-12 mb-3">
                <label for="comment"> تعليق </label>
                <textarea formControlName="comment" [(ngModel)]="employeeInfo.comment"
                  class="form-control textForArabic" placeholder="   " style="height: 120px"></textarea>
              </div>
            </div>

            <!-- image -->

            <div class="form-row col-12 mt-5 d-flex justify-content-around">
              <div class="col-xl-2">
                <div class="card">
                  <div class="product-box">
                    <div class="product-img">
                      <img src="{{ imgemployeePic }}" class="image-style w-100"
                        onerror="this.src='../../../../../../assets/images/user/user.png'" />
                      <input type="file" formControlName="employeePic" #file id="getLogo" style="display: none"
                        [(ngModel)]="employeeInfo.employeePic" (change)="getEmployeePhoto($event)" />
                    </div>
                    <div class="product-details text-center">
                      <button type="button" onclick="document.getElementById('getLogo').click()"
                        class="btn btn-primary d-block">
                        صوره الموظفي
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- resumePic -->
              <div class="col-xl-2">
                <div class="card">
                  <div class="product-box">
                    <div class="product-img">
                      <img src="{{ imgResumePic }}" class="image-style w-100"
                        onerror="this.src='../../../../../../assets/images/user/user.png'" />
                      <input type="file" formControlName="resumePic" #file id="resumePic" style="display: none"
                        [(ngModel)]="employeeInfo.resumePic" (change)="getresumePic($event)" />
                    </div>
                    <div class="product-details text-center">
                      <button type="button" onclick="document.getElementById('resumePic').click()"
                        class="btn btn-primary d-block">
                        صورة المؤهل الدراسي
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- militaryCertificatePic -->
              <div class="col-xl-2">
                <div class="card">
                  <div class="product-box">
                    <div class="product-img">
                      <img src="{{ imgMilitaryCertificatePic }}" class="image-style w-100"
                        onerror="this.src='../../../../../../assets/images/user/user.png'" />
                      <input type="file" formControlName="militaryCertificatePic" #file id="militaryCertificatePic"
                        style="display: none" [(ngModel)]="employeeInfo.militaryCertificatePic"
                        (change)="getmilitaryCertificatePic($event)" />
                    </div>
                    <div class="product-details text-center">
                      <button type="button" onclick="document.getElementById('militaryCertificatePic').click()"
                        class="btn btn-primary d-block">
                        صورة شهادة التجنيد
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- graduationCertificatePic -->
              <div class="col-xl-2">
                <div class="card">
                  <div class="product-box">
                    <div class="product-img">
                      <img src="{{ imgGraduationCertificatePic }}" class="image-style w-100"
                        onerror="this.src='../../../../../../assets/images/user/user.png'" />
                      <input type="file" formControlName="graduationCertificatePic" #file id="otherPaperPic1"
                        style="display: none" [(ngModel)]="employeeInfo.graduationCertificatePic"
                        (change)="getgraduationCertificatePic($event)" />
                    </div>
                    <div class="product-details text-center">
                      <button type="button" onclick="document.getElementById('otherPaperPic1').click()"
                        class="btn btn-primary d-block">
                        صور أخرى
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-2">
                <div class="card">
                  <div class="product-box">
                    <div class="product-img">
                      <img src="{{ imgOtherPaperPic }}" class="image-style w-100"
                        onerror="this.src='../../../../../../assets/images/user/user.png'" />
                      <input type="file" formControlName="otherPaperPic" #file id="otherPaperPic2" style="display: none"
                        [(ngModel)]="employeeInfo.otherPaperPic" (change)="getotherPaperPic($event)" />
                    </div>
                    <div class="product-details text-center">
                      <button type="button" onclick="document.getElementById('otherPaperPic2').click()"
                        class="btn btn-primary d-block">
                        صور أخرى
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end form row -->
            </div>
            <!-- end form row -->

            <div *ngIf="update; else elseBlock">
              <button class="btn btn-primary pull-right">تعديل</button>
            </div>

            <ng-template #elseBlock>
              <button class="btn btn-primary pull-right">إضافة</button>
            </ng-template>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>