<app-breadcrumb [title]="'قائمة موظفي ومدن التحصيل'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'">
</app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header px-0 " style="padding-bottom: 5px;">
                    <div class="row m-0 mb-2">
                        <div class="col-12">
                            <h5 class="py-2">القائمه</h5>
                            <span> إعدادت موظفي ومدن التحصيل الموجودة بالنظام ( عرض - تعديل - حذف ) </span>
                        </div>
                        <div class="col-12 col-md-9 mt-3">
                            <!-- <div class="form-group">
                                <label>أختر المدينة</label>
                                <ng-select class="form-control "  formControlName="CityId"
                                    aria-label="Default select example" (change)="SelectedCity($event)">
    
                                    <ng-option value="" selected disabled>أختر المدينة </ng-option>
                                    <ng-option value="-1">الكل </ng-option>
                                    <ng-option *ngFor="let obj of City_List" value="{{ obj.id }}">
                                        <h3 class="selected-value"> {{ obj.title }} </h3>
                                    </ng-option>
                                </ng-select>
                            </div> -->
                    
                        </div>
                        <!-- <div class="col-12 col-md-3 mt-3 d-flex justify-content-end align-items-center p-0">
                            <div class="addnew-btn" (click)="AddNew()">
                                <i class="fa fa-plus "></i> <span class="mt-1">جديد</span>
                                <span class="tooltiptext"> إضافة  موظف تحصيل ومدينه</span>
                                
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table" style="width: 100%;">
                        <thead>
                            <tr class="bg-dark ">
                                <th scope="col" class="font-style-header" >#</th>
                                <th scope="col" class="font-style-header" >موظف التحصيل </th>
                                <th scope="col" class="font-style-header" > </th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- <ng-container *ngIf="City_id!=-1"> -->
                                <tr *ngFor="let item of AllCollector;let i=index">
                                <td scope="row" class="font-style">{{i+1}} </td>
                        
                                <td   class="font-style">{{item.name}} </td>

                                <td class="font-style " style="width: 8%; text-align: end;">
                                       <button  appActionEdit href=""style="color: rgba(35, 118, 241, 0.667);padding: 7px; background-color: transparent;"(click)="update(item)">
                                        <i class="fa fa-pencil" ></i>
                                    </button>
                                    <button  appActionDelete href=""style="color: red; padding: 7px; background-color: transparent;"(click)="Delete(item.id)">
                                        <i class="fa fa-trash" ></i>
                                    </button>
                                   
                                </td>
                            </tr>
                        <!-- </ng-container> -->

                        </tbody>
                        <!-- <tbody>
                            <ng-container *ngIf="City_id==-1">
                            
                            <tr *ngFor="let item of AllCollector;let i=index">
                                <th scope="row" class="font-style" style="width: 20%;">{{i+1}} </th>
                        
                                <td  style="width: 60%;" class="font-style">{{item.name}} </td>

                                <td class="font-style chose" style="width: 20%;">
                         
                                      <button appActionEdit href=""style="color: rgba(35, 118, 241, 0.667);padding: 7px"(click)="update(item.collectorId)">
                                        <i class="fa fa-pencil" ></i>
                                    </button>
                                </td>
                            </tr>
                        </ng-container>
                        </tbody> -->
                    </table>
                    <div class="d-flex justify-content-center">
                        <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
                          firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
                          (pageChanged)="pageChanged($event)">
                        </pagination>
                      </div>
                </div>
            </div>
        </div>
    </div>
</div>