import { CustomerInfo } from '../../../../shared//Models/customer-info';
import { CitiesApiService } from 'src/app/shared/API-Service/cities-api.service';
import { GovernorateApiService } from 'src/app/shared/API-Service/governorate-api.service';
import { Component, OnInit } from '@angular/core';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';
import { Router } from '@angular/router';@Component({
  selector: 'app-customer-info-report',
  templateUrl: './customer-info-report.component.html',
  styleUrls: ['./customer-info-report.component.css']
})
export class CustomerInfoReportComponent implements OnInit {
  Governorate_List:any
  Filtered_List
  Response_List: any[];
  GetLookupsGetDonation
  Governorate_Dictionary
  getType:any[]=[]
  City_list
  CustomerInfo = new CustomerInfo()
  currentUser: any;
  Companies: any[];
  constructor(private CompanyDataService:CompanyDataService,private governorateApiService:GovernorateApiService,private citiesApiService:CitiesApiService,private router:Router,

    ) {
      this.currentUser=JSON.parse(localStorage.getItem("RiskCurrentUser"))
      this.CustomerInfo.reportCreatedBy=this.currentUser.name;
     }

  
     ngOnInit(): void {
      this.getgevernrate()
      this.Getcites()
      this.GetCompanies()
     }
     SelectCompany(CompanyId){
      this.CustomerInfo.companyId=parseInt(CompanyId);
      this.CustomerInfo.companyName=this.Companies.find(s=>s.id==CompanyId).name;
    }
    GetCompanies(){
      this.CompanyDataService.GetCompanies().subscribe(res=>{
        this.Companies=res.data
      })
    }
  
   
     getgevernrate(){
       this.governorateApiService.GetGovernorate().subscribe(response=>{
         this.Governorate_List = response.data
       })
     }
     Getcites(){
        this.citiesApiService.GetCitiesWithGovernorate().subscribe(response=>{
           this.Filtered_List = response.data
           this.Response_List = response.data
   
        })
     }
   
     SelectedGovernorate(event:any){
       if (this.CustomerInfo.governorateId==null) {
         this.CustomerInfo.governorateId=null
         this.CustomerInfo.governorateName=''
          this.City_list=[];
        } 
        this.CustomerInfo.cityId = null
        this.CustomerInfo.cityName = ''
      
       this.citiesApiService.GetCitiesWithGovernorate(this.CustomerInfo.governorateId)
       .subscribe(
         response => {
           this.City_list = response.data;
          
         },
       )
       this.CustomerInfo.governorateId = parseInt(event)
       this.CustomerInfo.governorateName = this.Governorate_List.find(x => x.id == event).title
       // this.donationRequest.governoratName = this.Governorate_List.title
     }
 
   SelectedCity(event:any){
   
     
     this.CustomerInfo.cityId = parseInt(event) 
     this.CustomerInfo.cityName = this.City_list.find(x => x.id == event).title
    
   }
   

  
   
   Viewreports(){
     
   if (this.CustomerInfo.governorateId==null) {
     this.CustomerInfo.governorateId= null;
     this.CustomerInfo.governorateName = ''
   
   
   }
   
   if(this.CustomerInfo.cityId==null){
     this.CustomerInfo.cityId = null
     this.CustomerInfo.cityName = ''
   }
   if(this.CustomerInfo.vip==null)
   {
    this.CustomerInfo.vip=false;
   }
   
     localStorage["ReportName"]="CustomersInfoReport";
     localStorage.setItem('ReportFilter',JSON.stringify(this.CustomerInfo));
   
     this.router.navigate([]).then((result) => {
       window.open("/content/admin/call-start-end-report", '_blank');
     });
    
   }

}
