<app-breadcrumb
  [title]="'تغيير كلمة المرور للموظف'"
  [items]="['Tables', 'Bootstrap Tables']"
  [active_item]="'Basic'"
>
</app-breadcrumb>
<form [formGroup]="insertForm" (ngSubmit)="onSubmit()">

<div class="container-fluid">
  <div class="card">
    <div class="card-body">
      <div class="row">
    <div class="col-12 col-md-12">
        <div class="" style="display: flex; justify-content: start;"><label for="">اسم الموظف</label></div>
        
        <ng-select  class="js-example-basic-single" (change)="ChangeEmplyee($event.id)" 
        [items]="Employees"
        formControlName="Employee"
        bindLabel="name"
        bindValue="id"
        [tooltip]="fc.Employee.errors?.required ? 'أسم العميل مطلوب' : null" required
        [isDisabled]="!((fc.Employee.errors?.required && fc.Employee.touched))"
        containerClass="" [class.is-valid]="fc.Employee.valid && (fc.Employee.touched)"
        [class.is-invalid]="fc.Employee.invalid && (fc.Employee.touched)"
        >
        </ng-select>
    </div>
    <div class="col-12 col-md-6 mt-4">
        <div class="" style="display: flex; justify-content: start;"><label for=""> كلمة المرور الجديدة </label></div>
          <div class="form-group">
              
             <input type="password" formControlName="Password"  class="form-control" 
             [tooltip]="fc.Password.errors?.required ? 'كلمة المرور مطلوبة' : null" required
             [isDisabled]="!((fc.Password.errors?.required && fc.Password.touched))"
             containerClass="" [class.is-valid]="fc.Password.valid && (fc.Password.touched)"
             [class.is-invalid]="fc.Password.invalid && (fc.Password.touched)">
          </div>
    </div>
    <div class="col-12 col-md-6 mt-4">
        <div class="" style="display: flex; justify-content: start;"><label for=""> إعادة كلمة المرور</label></div>
          <div class="form-group">
              
             <input type="password" formControlName="ConfirmPassword" class="form-control"
             [tooltip]="fc.ConfirmPassword.errors?.required ? 'يجب كتابة كلمة المرور مرة اخرى' : null" required
             [isDisabled]="!((fc.ConfirmPassword.errors?.required && fc.ConfirmPassword.touched))"
             containerClass="" [class.is-valid]="fc.ConfirmPassword.valid && (fc.ConfirmPassword.touched)"
             [class.is-invalid]="fc.ConfirmPassword.invalid && (fc.ConfirmPassword.touched)">
             <div *ngIf="fc.ConfirmPassword.touched && fc.ConfirmPassword.invalid && fc.ConfirmPassword.errors.confirmedValidator" class="alert alert-danger">
              <div *ngIf="fc.ConfirmPassword.errors.confirmedValidator">كلمة المرور غير مطابقة</div>
            </div>      
            </div>
    </div>
      <div class="col-12 col-md-12 mt-4" >

        <button class="btn btn-primary pull-left"  type="submit">حفظ</button>
      </div>
      <!-- <button class="addnew-btn " type="submit">
        حفظ
      </button> -->
  </div>
    </div>
  </div>
  
</div>
</form>
