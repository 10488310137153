
 <!-- <ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">ربط المحصلين بالايصالات</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <form class="needs-validation" [formGroup]="InsertForm" (ngSubmit)="onSubmit()" novalidate [ngClass]="{'was-validated': validated}">
    
                <div class="form-row">
                  <div class="col-12 mb-3">
    
                  <label for="validationCustom01">  المحصل</label>
                  <ng-select
          class="js-example-basic-single"
          aria-label="Default select example"
          formControlName="Collector"
          [items]="Collector"
         bindLabel="name"
         bindValue="id"
        >
        </ng-select>
                  
    
    
                 
                </div>
    
                </div>
    
            
                <div class="form-row">
                  <div class="col-12 mb-3">
                  <label for="validationCustom03"> خصص دفاتر لهذا المحصل </label>
    
                  <ng-multiselect-dropdown
                  [formControl]="InsertForm.controls['Receipts']"
                  id="validationCustom02=3"
                    placeholder="أختر دفتر أو أكتر من القائمة"
                    [settings]="dropdownSettings"
                    
                    [data]="Receipts"
                    [(ngModel)]="selectedItems"
                   
                  >
                  <ng-option value="{{receipt.id}}" *ngFor="let receipt of Receipts" >
                    {{ receipt.bookNumber }}
                  </ng-option>
                  </ng-multiselect-dropdown>
                
                </div>
                </div>
                <div *ngIf="update; else elseBlock">
                  <button
                    class="btn btn-primary pull-right"
                    type="submit"
                  
                   
                  >
                    تعديل
                  </button>
                </div>
                <ng-template #elseBlock>
                  <button
                    class="btn btn-primary pull-right"
                    type="submit"
                  
                    
                  >
                     حفظ
                  </button>
                </ng-template>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
	</div>

</ng-template> -->
 <app-breadcrumb [title]="'ربط المحصلين بالايصالات'" ></app-breadcrumb>
 <div class="container-fluid">
     <div class="row">
       <div class="col-sm-12">
         <div class="card">
           <div class="card-header px-0" style="padding-bottom: 5px;">
               <div class="row m-0" >
                   <div class="col-12 col-md-9">
                     <h5 class="py-2"> قائمة المحصلين</h5>
                     <span  >  إعدادت قائمة المحصلين الموجودة بالنظام ( عرض - تعديل - حذف ) </span>
                   </div>
                   <!-- <div class="col-12 col-md-3 d-flex justify-content-end align-items-center p-0">
                       <div  class="addnew-btn" (click)="open(content)">
                            
                             <i class="fa fa-plus "></i> <span class="mt-1">جديد</span>
                             <span class="tooltiptext">  ربط ايصال جديد بمحصل</span>
                       </div>
                   </div>
               </div>
               <div class="form-row">
                <div class="col-12 mb-3">
  
                <label for="validationCustom01">  المحصل</label>
                <ng-select
        class="js-example-basic-single"
        aria-label="Default select example"
        formControlName="Collector"
        [items]="Collector"
        (change)="GetCollectorReceipts($event?.id)"
       bindLabel="name"
       bindValue="id"
      >
      </ng-select>
                
  
  
               
              </div>
  
              </div> -->
           </div>
           <div class="table-responsive">
            <table class="table" style="width: 100%;">
                <thead>
                    <tr class="bg-dark ">
                        <th scope="col" class="font-style-header" >#</th>
                        <th scope="col" class="font-style-header" >موظف التحصيل </th>
                        <th scope="col" class="font-style-header" > </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container >
                        <tr *ngFor="let item of Collector;let i=index">
                        <td scope="row" class="font-style">{{i+1}} </td>
                
                        <td   class="font-style">{{item.name}} </td>

                        <td class="font-style " style="width: 8%; text-align: end;">
                               <button appActionEdit href=""style="color: rgba(35, 118, 241, 0.667);padding: 7px; background-color: transparent;"(click)="Edit(item)">
                                <i class="fa fa-pencil" ></i>
                            </button>
                            <!-- <button href=""style="color: red; padding: 7px; background-color: transparent;"(click)="Delete(item.id)">
                                <i class="fa fa-trash" ></i>
                            </button> -->
                           
                        </td>
                    </tr>
                </ng-container>

                </tbody>
                <tbody>
                 
                  
                </tbody>
            </table>
            <div class="d-flex justify-content-center">
              <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
                firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
                (pageChanged)="pageChanged($event)">
              </pagination>
            </div>
        </div>
         </div>
       </div>
     </div>
   </div>
 </div>