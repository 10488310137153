import { Router } from '@angular/router';
import { DonationCategoryService } from './../../../../shared/API-Service/donation-category.service';
import { DonationCategory } from '../../../../shared//Models/donation-category';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import Swal from "sweetalert2";

@Component({
  selector: 'app-insert-donation-category',
  templateUrl: './insert-donation-category.component.html',
  styleUrls: ['./insert-donation-category.component.css']
})
export class InsertDonationCategoryComponent implements OnInit ,OnDestroy{
InsertForm:FormGroup      
update: boolean = false;
validated: boolean;

donationCategory=new DonationCategory()

  constructor(private fb:FormBuilder,private _DonationCategoryService:DonationCategoryService, private _router:Router) { }

  containsNumber(str) {
    const specialChars = /[/\d/٠١٢٣٤٥٦٧٨٩]/;
    return specialChars.test(str);
  }
  //#endregion
  containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }
  ngOnInit(): void {

    this.InsertForm=this.fb.group({

      title:['', Validators.required  ]
    })

    this._DonationCategoryService.Data.subscribe((res) => {
  
      if (this._DonationCategoryService.Data.getValue() != null) {
        this.donationCategory = res;

        this.update = true;

      } else {

        // this.InsertForm.reset();
      }
    });
 
  
  }

  ngOnDestroy() {
    this._DonationCategoryService.Data.next(null);

  }

  onSubmit() {
    // this.validated = !this.validated

    // this.validated = !this.validated
  //   if(this.containsSpecialChars(this.InsertForm.get("title").value)==true){
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'خطأ',
  //       text: "لا يمكن كتابه علامات ",
  //     })
  //   }
  //   else if (this.containsNumber(this.InsertForm.get("title").value)==true){
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'خطأ',
  //       text: "لا يمكن كتابه ارقام ",
  //     })
  //   }
  //  else
    if (this.InsertForm.status == "VALID") {
      this.validated = false;

      if (this.update == true) {
        this.Edit();
      } else {
        this.insertData();
      }
    } else {
      this.validated = true;
    }
  }



    // Insert-Donation-Type
    insertData() {
  
      this._DonationCategoryService.post(this.donationCategory).subscribe((res) => {
        if (res.success) {
          this.InsertForm.reset();
  
          Swal.fire({
            icon: "success",
            title: "تم إدخال النوع بنجاح",
            showConfirmButton: false,
            timer: 1500,
          });
  
          this._router.navigate(["/content/admin/ViewDonationCategory"]);
        } else {
        
  
          Swal.fire({
            icon: "error",
            title: "خطاء في إدخال البيانات ",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    }



    Edit() {
      this._DonationCategoryService.Put(this.donationCategory).subscribe((res) => {
        if (res) {
          this.InsertForm.reset();
          this._DonationCategoryService.Data.next(null);
  
          Swal.fire({
            icon: "success",
            title: "تم التعديل   بنجاح",
            showConfirmButton: false,
            timer: 1500,
          });
  
          this._router.navigate(["/content/admin/ViewDonationCategory"]);
          this._DonationCategoryService.Data.next(null);
        } else {
         
  
          Swal.fire({
            icon: "error",
            title: "خطاء في تعديل البيانات ",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    }
  
}
