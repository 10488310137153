import { Component, OnInit,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnMode, SortType } from '@swimlane/ngx-datatable';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';
import { DontaionsApiService } from 'src/app/shared/API-Service/donations-api-service';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { environment } from 'src/environments/environment.prod';
import Swal from 'sweetalert2';
import { StattsReportsService } from './../../../../shared/API-Service/statts-reports.service';
import { CommentModalComponent } from 'src/app/components/Agent/comment-modal/comment-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-statdetails',
  templateUrl: './statdetails.component.html',
  styleUrls: ['./statdetails.component.css']
})
export class StatdetailsComponent extends BaseComponent implements OnInit {
  DontationType:any[];
  customerinfo:any;
  customerid:any;
  Dontations: any[]=[];
  rows = [];
  imgUrlServer=environment.Server_URL_Image+'/'

  columns = [{ name: '#' }, { name: 'رقم التبرع' }, { name: 'تاريخ إنشاء التبرع' },{ name: 'تاريخ التحصيل الفعلي' },{ name: 'المبلغ' },{ name: 'حاله التبرع' },{ name: 'اسم المحصل' },{ name: 'الانجازات' },{ name: '' }];

  ColumnMode = ColumnMode;
  SortType = SortType;
  Descending: boolean;
  status: any;
  err: boolean;
  data: any;
  DontationsFromStats: any;
  param: { [k: string]: any; };

  constructor(public modalService: NgbModal,private StattsReportsService:StattsReportsService, private CompanyDataService:CompanyDataService,  private router: Router,private DonationApiService:DontaionsApiService) {
    super();
    this.Descending=false
    const param = this.router.getCurrentNavigation().extras.state;
    this.param= param
if(param){
  this.data=param.data;
  if(!param.FromDonationEdit){
  this.Dontations=param.Donations.items;
  this.totalCount=param.Donations.totalCount
  
  this.DontationsFromStats=param.Donations
  }
 

console.log(this.Dontations);
console.log(this.data);
}

   }
 async  GetDonationStatus(data){
  this.StattsReportsService.GetDonationStatus(data).subscribe({
    next: async (data) => {
     var res =await data;
      if (res.success) {
        this.err = false;
        this.DontationsFromStats=res.data
        this.Dontations=await res.data.items;
        this.Dontations=[...this.Dontations]
        this.totalCount=res.data.totalCount
  
   //     localStorage.setItem('RiskuserMenu',this.Menu)
        
      } else {
        this.err = true;
      }
    },
    error: (error) => {
      this.err = true;
      Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: error.error.message,
      })
    },
  });
}
 async ngOnInit() {
  if(this.param.FromDonationEdit){
     await this.GetDonationStatus(this.data)
  }
   
    var res
    this.DonationApiService.GetLookupsDonationStatus().subscribe({
      next: (data) => {
        res = data;
        if (res.messageCode == 200) {
          this.err = false;
          this.status = res.data;
        } else {
          this.err = true;
        }
      },
      error: (error) => {
        this.err = true;
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: error.error.message,
        })
      },
    });
  }
  pageChanged(event:any){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.data.maxResultCount=this.pager.maxResultCount;
    this.data.skipCount=this.pager.skipCount
    this.StattsReportsService.GetDonationStatus(this.data).subscribe({
      next: (data) => {
       var res = data;
        if (res.success) {
          this.err = false;
          this.Dontations=res.data.items
          this.totalCount=res.data.totalCount    
     //     localStorage.setItem('RiskuserMenu',this.Menu)
          
        } else {
          this.err = true;
        }
      },
      error: (error) => {
        this.err = true;
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: error.error.message,
        })
      },
    });
  }
openComment(index){
  let comment
  this.Dontations[index].comment
  if(!this.Dontations[index].comment){
       comment="لا يوجد تعليق"
  }
  else{
    comment=this.Dontations[index].comment
  }

  Swal.fire({
    title:  "<h5 style='color:#7364f6; font-weight:bold'>" + 'التعليق' + "</h5>",
    text: comment,
  })
  // Swal.fire(this.Dontations[index].comment)
}
sortData(name){
  if(!this.Descending){
    this.Dontations.sort((a, b) => a[name] > b[name] ? 1 : a[name] < b[name] ? -1 : 0)
  this.Descending=true;
}
else{
  this.Dontations.sort((a, b) => a[name] > b[name] ? -1 : a[name] < b[name] ? 1 : 0)
  this.Descending=false;
}
}
EditDonationDetails(index){
  this.CompanyDataService.CompanyInfo.CompanyId=this.Dontations[index].companyId;
  this.CompanyDataService.CompanyInfo.CompanyIcon=this.Dontations[index].companyIcon
  const DonationId=this.Dontations[index].donationId
  this.router.navigateByUrl("content/agent/updateCusomterDonation",{ state: {donationid:DonationId, statedetails: this.data,DontationsFromStats:this.DontationsFromStats} });
}
openModal(donationId) {
  //ModalComponent is component name where modal is declare
  localStorage.removeItem("ModalData");
  localStorage.setItem("ModalData",donationId);
  const modalRef = this.modalService.open(CommentModalComponent);
  modalRef.result.then((result) => {

  }).catch((error) => {
    
  });
}
GoToAccomplishment(id,index){
  this.CompanyDataService.CompanyInfo.CompanyId=this.Dontations[index].companyId;
  this.CompanyDataService.CompanyInfo.CompanyIcon=this.imgUrlServer+this.Dontations[index].companyIcon
  this.CompanyDataService.CompanyInfo.CompanyId=this.Dontations[index].companyId;
  this.CompanyDataService.CompanyInfo.CompanyName=this.Dontations[index].companyName

  localStorage.setItem("CompanyIcon",this.imgUrlServer+this.Dontations[index].companyIcon)
  localStorage.setItem("CompanyName",this.Dontations[index].companyName)
  localStorage.setItem("CompanyId",this.Dontations[index].companyId)
  // this.router.navigate( ['/content/admin/customer-accomplishment', {id: id}]);
  this.router.navigate(['/content/admin/customer-accomplishment/',id]);
}
}
