import { SmsTemplateService } from './../../../../shared/API-Service/sms-template.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';

@Component({
  selector: 'app-addsms-template',
  templateUrl: './addsms-template.component.html',
  styleUrls: ['./addsms-template.component.css']
})
export class AddsmsTemplateComponent implements OnInit {
  formData = new FormData();
  donationStatus
  data: any;
  update: boolean;
  form= {
    smscontent: "",
 description:"",
 donationStatusId:"",
 companyId:""
}
  Companies: any[];
  constructor(private CompanyDataService:CompanyDataService,private SmsTemplateService:SmsTemplateService,private router: Router,) {
    this.update=false;
    const param = this.router.getCurrentNavigation().extras.state;
    if(param){
      this.data=param.data;
      this.form.smscontent=this.data.smscontent
      this.form.description=this.data.description
      this.form.donationStatusId=this.data.donationStatusId
      this.form.companyId=this.data.companyId
      this.InsertForm.patchValue({
        donationStatusId:this.data.donationStatusId,
      });
      this.update=true;
    }
    else{
      this.form.companyId=this.CompanyDataService.CompanyInfo.CompanyId
      this.update=false;
    }
   }

  ngOnInit(): void {
    this.GetCompanies();
    this.SmsTemplateService.GetLookupsDonationStatus().subscribe(response=>{
      this.donationStatus = response.data
    })
  }
  
  get fc() {
    return this.InsertForm.controls;
  }
  InsertForm= new FormGroup({
    smscontent: new FormControl('', Validators.required),
    description:new FormControl('', Validators.required),
    companyId:new FormControl('', Validators.required),
  })

  GetCompanies(){
    this.CompanyDataService.GetCompanies().subscribe(res=>{
      this.Companies=res.data
    })
  }
  SelectCompany(e){

  }
  onSubmit(){
    if (this.InsertForm.status == "VALID") {
      let data = {
        smscontent:this.InsertForm.get('smscontent')?.value,
        description:this.InsertForm.get('description')?.value,
        donationStatusId:null,
        companyId: this.InsertForm.get('companyId')?.value
      }
      if (this.update == true) {
       // this.Edit();
      } else {
        this.SmsTemplateService.PostSmsTemplates(data).subscribe(
          (res) =>{
               if (res) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: ' تم الاضافه',
              showConfirmButton: false,
              timer: 1500,
              
            
            })
            this.router.navigateByUrl("content/admin/SMSTemplate");
            //this.SmsTemplateService.reloadCurrentRoute()
          }
       
          },(err) => {
          
            Swal.fire({
              icon: "error",
              title: "خطأ",
              text: err.error.message,
            });
          }
         )
      }
     
  }
   else {
    this.InsertForm.markAllAsTouched();
  }
  
  }
}
