import { FormsModule } from '@angular/forms';


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientTypeComponent } from './lookups/ClientType/client-type/client-type.component';
import { AdminRoutingModule } from './admin-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ArchwizardModule } from 'angular-archwizard';
import { ListClientTypeComponent } from './lookups/ClientType/list-client-type/list-client-type.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { GovernorateComponent } from './lookups/Governorate/insert-Governorate/insert-Governorate.component';
import { ListGovernorateComponent } from './lookups/Governorate/list-governorate/list-governorate.component';
import { ListCitiesComponent } from './lookups/Cities/list-cities/list-cities.component';
import { InsertCitiesComponent } from './lookups/Cities/insert-cities/insert-cities.component';
import { CallReasonComponent } from './lookups/CallReason/CallReason/CallReason.component';
import { InsertCallReasonComponent } from './lookups/CallReason/InsertCallReason/InsertCallReason.component';
import { SourceMarketComponent } from './lookups/SourceMarketing/source-market/source-market.component';
import { InsertSourceMarketComponent } from './lookups/SourceMarketing/insert-source-market/insert-source-market.component';
import { ClientComponent } from './users/clients/Client/Client.component';
import { InsertClientComponent } from './users/clients/InsertClient/InsertClient.component';
import { RouterModule } from '@angular/router';
import { InsertEmployeeComponent } from './users/employee/insert-employee/insert-employee.component';
import { EmployeeComponent } from './users/employee/List-Employees/employee.component';
import { CustomerServiceComponent } from './users/Customer-Service/customer-service/customer-service.component';
import { InsertCustomerServiceComponent } from './users/Customer-Service/InsertCustomerService/InsertCustomerService.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ClientAgentComponent } from './Reports/client-agent/client-agent.component';
import { NgxPrintElementModule } from 'ngx-print-element';
import { ClientsComponent } from './Reports/clients/clients.component';
import { ClientCallComponent } from './Reports/client-call/client-call.component';
import { ClientCallDetailsComponent } from './Reports/client-call-details/client-call-details.component';
//import { CallStartEndReportComponent } from './Reports/Call_Start_End/call-start-end-report.component';
import { StatsComponent } from './statistics/stats/stats.component';
import { AgentListStatComponent } from './statistics/agent-list-stat/agent-list-stat.component';
import { CallReasonStatComponent } from './statistics/call-reason-stat/call-reason-stat.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { InsertSectorComponent } from '../sector/insert-sector/insert-sector.component';
import { ListSectorComponent } from '../sector/list-sector/list-sector.component';
import { InsertSectorTypeComponent } from './lookups/SectorType/insert-sector-type/insert-sector-type.component';
import { ListSectorTypeComponent } from './lookups/SectorType/list-sector-type/list-sector-type.component';
// DonationMainType
import { ListSectorLocationComponent } from './lookups/SectorLocation/list-sector-location/list-sector-location.component';
import { InsertSectorLocationComponent } from './lookups/SectorLocation/insert-sector-location/insert-sector-location.component';
import { InsertCollectorCityComponent } from './lookups/CollectorCity/insert-collector-city/insert-collector-city.component';
import { ListCollectorCityComponent } from './lookups/CollectorCity/list-collector-city/list-collector-city.component';

import { DxReportViewerModule } from 'devexpress-reporting-angular';
import { DonationMainTypeComponentComponent } from './Donation/donation-main-type-component/donation-main-type-component.component';
import { InsertDonationMTypeComponent } from './Donation/insert-donation-m-type/insert-donation-m-type.component';
// DonationType
import { InsertDonationTypeComponent } from './DonationType/insert-donation-type/insert-donation-type.component';
import { ViewDonationTypeComponent } from './DonationType/view-donation-type/view-donation-type.component';
// DonationCategory
import { DonationCategoryComponent } from './DonationCategory/donation-category/donation-category.component';
import { InsertDonationCategoryComponent } from './DonationCategory/insert-donation-category/insert-donation-category.component';
import { SmsTemplateComponent } from './sms/sms-template/sms-template.component';
import { AddsmsTemplateComponent } from './sms/addsms-template/addsms-template.component';
import { AllPermissionComponent } from './Permissions/all-permission/all-permission.component';
import { SourceofmarketingComponent } from './Reports/sourceofmarketing/sourceofmarketing.component';
import { DonationRequestsComponent } from './Reports/donation-requests/donation-requests.component';
import { CollectorBatchesComponent } from './Reports/collector-batches/collector-batches.component';
import { AddPermissionComponent } from './Permissions/add-permission/add-permission.component';
import { SearchReportsComponent } from './Reports/search-reports/search-reports.component';
import { SmallDonationReportsComponent } from './Reports/small-donation-reports/small-donation-reports.component';

import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartistModule } from 'ng-chartist';
import { CollectorDonationsComponent } from './Reports/collector-donations/collector-donations.component';
import { SidebarComponent } from 'src/app/shared/components/sidebar/sidebar.component';
import { KnobModule } from "@xmlking/ngx-knob";
import { InsertJobsComponent } from './users/jobs/insert-jobs/insert-jobs.component';
import { ListJobsComponent } from './users/jobs/list-jobs/list-jobs.component';
import { BulkSMSComponent } from './sms/bulk-sms/bulk-sms.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { StatdetailsComponent } from './statistics/statdetails/statdetails.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AllBatchesComponent } from './Reports/all-batches/all-batches.component';
import { CollectorSmallDonationComponent } from './Reports/collector-small-donation/collector-small-donation.component';
import { GetCallCustomerDateComponent } from './Reports/get-call-customer-date/get-call-customer-date.component';
import { DefaultComponent } from './default/default.component';
import { MyProjectsComponent } from './Projects/my-projects/my-projects.component';
import { AllProjectsComponent } from './Projects/all-projects/all-projects.component';
import { SendSMSComponent } from './send-sms/send-sms.component';
import { DonationsReportComponent } from './Reports/donations-report/donations-report.component';
import { FollowDonationsComponent } from './follow-donations/follow-donations.component';
import { CustomerStatsComponent } from './statistics/customer-stats/customer-stats.component';
import { SourcemarketDonationsReportComponent } from './Reports/sourcemarket-donations-report/sourcemarket-donations-report.component';
import { ProjectDonationsReportComponent } from './Reports/project-donations-report/project-donations-report.component';
import { InsertReceiptBookComponent } from './ReceiptBook/insert-receipt-book/insert-receipt-book.component';
import { ListReceiptBookComponent } from './ReceiptBook/list-receipt-book/list-receipt-book.component';
import {FielderrorModule} from '../../shared/fielderror/fielderror.module';
import { CollectorReceiptsComponent } from './ReceiptBook/collector-receipts/collector-receipts.component';
import { ReceiptReportComponent } from './Reports/receipt-report/receipt-report.component';
import { SourceMarketCustomersComponent } from './Reports/source-market-customers/source-market-customers.component';
import { CompaniesReportComponent } from './Reports/companies-report/companies-report.component'
import {ChangeEmployeePasswordComponent} from '../admin/users/employee/change-employeepassword/change-employeepassword.component';
import { CollectorReceiptsEditComponent } from './ReceiptBook/collector-receipts-edit/collector-receipts-edit.component';
import { MediaTypeComponent } from './Media/media-type/media-type.component';
import { InsertMediaTypeComponent } from './Media/insert-media-type/insert-media-type.component';
import { InsertProjectMediaComponent } from './Media/project-accomplishment/insert-project-media/insert-project-media.component';
import { CustomerAccomplishmentComponent } from './Media/customer-accomplishment/customer-accomplishment.component'
import {ListProjectAccomplishmentComponent} from './Media/project-accomplishment/list-project-accomplishment/list-project-accomplishment.component';
import { AccomplishmentmediaCustomerComponent } from './Media/accomplishmentmedia-customer/accomplishmentmedia-customer.component';
import { CustomerInfoReportComponent } from './Reports/customer-info-report/customer-info-report.component';
import { DonationsStatusReportComponent } from './Reports/donations-status-report/donations-status-report.component';
import { ReceiptBookReportComponent } from './Reports/receipt-book-report/receipt-book-report.component'
// import { ColorPickerModule } from 'ngx-color-picker';
import { ColorSketchModule } from 'ngx-color/sketch';
import { ColorBlockModule } from 'ngx-color/block'; // <color-block></color-block>
import { ColorChromeModule } from 'ngx-color/chrome';
import { ColorSwatchesModule } from 'ngx-color/swatches';
import { ColorPickerModule } from 'ngx-color-picker';
import { ListOfficesComponent } from './Offices/list-offices/list-offices.component';
import { InsertOfficeComponent } from './Offices/insert-office/insert-office.component';
import { CommissionSettingComponent } from './commission-setting/commission-setting.component';
import { EmployeesCommissionReportComponent } from './Reports/employees-commission-report/employees-commission-report/employees-commission-report.component';
import { ChangeDonationCreatorComponent } from './change-donation-creator/change-donation-creator.component';
import { followUpCallReportComponent } from './Reports/follow-up-call-report/follow-up-call-report.component';
import { CallReasonsCallsReportComponent } from './Reports/call-reasons-calls-report/call-reasons-calls-report.component';
import {CustomerServicesCallsComponent} from './Reports/customer-services-calls/customer-services-calls.component'
import {ProjectFullStateReportComponent} from './Reports/project-full-state-report/project-full-state-report.component'
@NgModule({
  declarations: [
    ClientTypeComponent,
    ListClientTypeComponent,
    GovernorateComponent,
    ListGovernorateComponent,
    ListCitiesComponent,
    InsertCitiesComponent,
    CallReasonComponent,
    InsertCallReasonComponent,
    SourceMarketComponent,
    InsertSourceMarketComponent,
    ClientComponent,
    InsertClientComponent,
    InsertEmployeeComponent,
    InsertEmployeeComponent,
    EmployeeComponent,
    CustomerServiceComponent,
    InsertCustomerServiceComponent,
    ClientAgentComponent,
    ClientsComponent,
    ClientCallComponent,
    ClientCallDetailsComponent,
    //CallStartEndReportComponent,
    StatsComponent,
    AgentListStatComponent,
    CallReasonStatComponent,
    InsertSectorComponent,
    ListSectorComponent,
    InsertSectorTypeComponent,
    ListSectorTypeComponent,
    ListSectorLocationComponent,
    InsertSectorLocationComponent,
    InsertCollectorCityComponent,
    ListCollectorCityComponent,
    DonationMainTypeComponentComponent,
    InsertDonationMTypeComponent,
    InsertDonationTypeComponent,
    ViewDonationTypeComponent,
    DonationCategoryComponent,
    InsertDonationCategoryComponent,
    AddsmsTemplateComponent,
    SmsTemplateComponent,
    AllPermissionComponent,
    SourceofmarketingComponent,
    DonationRequestsComponent,
    CollectorBatchesComponent,
    AddPermissionComponent,
    SearchReportsComponent,
    SmallDonationReportsComponent,
    CollectorDonationsComponent,
    InsertJobsComponent,
    ListJobsComponent,
    BulkSMSComponent,
    StatdetailsComponent,
    AllBatchesComponent,
    DonationsReportComponent,
    CollectorSmallDonationComponent,
    GetCallCustomerDateComponent,
    CustomerServicesCallsComponent,
    ProjectFullStateReportComponent,
    DefaultComponent,
    MyProjectsComponent,
    AllProjectsComponent,
    SendSMSComponent,
    FollowDonationsComponent,
    CustomerStatsComponent,
    SourcemarketDonationsReportComponent,
    ProjectDonationsReportComponent,
    InsertReceiptBookComponent,
    ListReceiptBookComponent,
    CollectorReceiptsComponent,
    ChangeEmployeePasswordComponent,

    ReceiptReportComponent,
    SourceMarketCustomersComponent,
    CompaniesReportComponent,

    ChangeEmployeePasswordComponent,
     CollectorReceiptsEditComponent,
     MediaTypeComponent,
     InsertMediaTypeComponent,
     InsertProjectMediaComponent,
     CustomerAccomplishmentComponent,
     ListProjectAccomplishmentComponent,
     AccomplishmentmediaCustomerComponent,
     CustomerInfoReportComponent,
     DonationsStatusReportComponent,
     ReceiptBookReportComponent,
     ListOfficesComponent,
     InsertOfficeComponent,
     CommissionSettingComponent,
     EmployeesCommissionReportComponent,
     ChangeDonationCreatorComponent,
     followUpCallReportComponent,
     CallReasonsCallsReportComponent,
     
   
 
    


    
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    DxReportViewerModule,
    SharedModule,
    FormsModule,
    ArchwizardModule,
    SweetAlert2Module,
    RouterModule,
    NgApexchartsModule,
    NgxPrintElementModule,
    Ng2GoogleChartsModule,
    NgxChartsModule,
    ChartistModule,
    NgxPaginationModule,
    FielderrorModule,
    NgSelectModule,
    KnobModule,
    NgxDatatableModule,
    NgMultiSelectDropDownModule.forRoot(),
    ColorSketchModule,
    ColorBlockModule,
    ColorChromeModule,
    ColorSwatchesModule,
    ColorPickerModule,
    
  ],
  exports:[ClientTypeComponent]
})
export class AdminModule { }
