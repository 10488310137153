<app-breadcrumb [title]="'إدارة أنواع العملاء'"  [active_item]="'Form Wizard'"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding-bottom:5px !important ;">
            <div *ngIf="update; else elseBlock1">
              <h5 class="pb-2">تعديل نوع عميل موجود</h5>
              يمكنك تعديل نوع عميل أو أكثر فى قائمة أنواع العملاء 
            </div>
          <ng-template #elseBlock1>
            <h5 class="pb-2">إضافة نوع عميل جديد</h5>
              يمكنك إضافة نوع عميل أو أكثر فى قائمة أنواع العملاء 
            </ng-template>

        </div>
        <div class="card-body">
 
          <form class="needs-validation" [formGroup]="ClientTypeForm" (ngSubmit)="onSubmit()" novalidate [ngClass]="{'was-validated': validated}">

            <div class="col-12 mb-3">
                  <label for="validationCustom01">نوع العميل</label>
                  <input class="form-control" id="validationCustom01" type="text" placeholder="ادخل نوع العميل " required="required"
                    formControlName="firstName"
                  required=""
                  ><!-- onlyAlphabets -->
                <div class="valid-feedback">حسنًا</div>
                    
              
                </div>
                <div *ngIf="update; else elseBlock">
                    <button  class="btn btn-primary pull-right" 
                    type="submit"
                  >
                    تعديل
                 </button>
                </div>
                <ng-template #elseBlock>
                    <button  class="btn btn-primary pull-right" 
                    type="submit"
            >
                    إضافة
                 </button>
                </ng-template>
               
              </form>
        </div>
      </div>
    </div>
  </div>
</div>