import { Router } from '@angular/router';
import { Aleumula } from './../Models/aleumula';
import { Recieve } from './../Models/recieve';
import { State } from './../Models/State';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericResponse } from '../Models/GenericResponse';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  constructor(private http:HttpClient,private router:Router) { }

  GetCollectedDonations(): Observable<GenericResponse<State>> {
    return this.http.get<GenericResponse<State>>(`${environment.Server_URL}/DonationCollector/GetCollectorOpenDonations`);
  }

  GetCollectedDonationsList(data): Observable<GenericResponse<State>> {
    return this.http.post<GenericResponse<State>>(`${environment.Server_URL}/DonationCollector/GetCollectorOpenDonationsList`,data);
  }

  GetUpdateIsReceived(data:Recieve): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(`${environment.Server_URL}/DonationCollector/UpdateIsReceived`,data);
  }
 
  
  GetGetCollectorReceived(){
    return this.http.get<GenericResponse<State>>(`${environment.Server_URL}/DonationCollector/GetCollectorReceivedDonations`);
  }
  GetGetCollectorReceivedList(data){
    return this.http.post<GenericResponse<State>>(`${environment.Server_URL}/DonationCollector/GetCollectorReceivedDonationsList`,data);
  }

  PostDonationCollectorUpdateIsReceived(data:Recieve): Observable<GenericResponse<any>>{
    return this.http.post<GenericResponse<any>>(`${environment.Server_URL}/DonationCollector/UpdateIsCollected`,data);
  }



  GetCollectorCollectedDonations(companyId:any){
    return this.http.get<GenericResponse<State>>(`${environment.Server_URL}/DonationCollector/GetCollectorCollectedDonations?companyId=${companyId}`);
  }
  GetCollectorCollectedDonationsList(data:any){
    return this.http.post<GenericResponse<State>>(`${environment.Server_URL}/DonationCollector/GetCollectorCollectedDonationsList`,data);
  }

  PostDonationCollectorUpdateTransportationAndAllowance(data:any): Observable<GenericResponse<any>>{
    return this.http.post<GenericResponse<any>>(`${environment.Server_URL}/DonationCollector/UpdateTransportationAndAllowance`,data);
  }


  PostBatchCloseBatch(data:any): Observable<GenericResponse<any>>{
    return this.http.post<GenericResponse<any>>(`${environment.Server_URL}/Batch/CloseBatch`,data);
  }

  UpdateClosedBatch(data:any): Observable<GenericResponse<any>>{
    return this.http.post<GenericResponse<any>>(`${environment.Server_URL}/Batch/Update`,data);
  }
  
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
   
  }

}
