import { Router } from '@angular/router';
import { BatchesReports } from '../../../../shared//Models/batches-reports';
import { FormGroup, FormControl } from '@angular/forms';
import { DonationRequestsService } from './../../../../shared/API-Service/donation-requests.service';
import { Component, OnInit } from '@angular/core';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-collector-batches',
  templateUrl: './collector-batches.component.html',
  styleUrls: ['./collector-batches.component.css']
})
export class CollectorBatchesComponent implements OnInit {
  Getcollectors
  BatchesReport = new BatchesReports()
  currentUser: any;
  Companies: any[];
  constructor(private CompanyDataService:CompanyDataService,private DonationRequestsService:DonationRequestsService,private router:Router) {
    this.currentUser=JSON.parse(localStorage.getItem("RiskCurrentUser"));
    if(this.currentUser.roleId==10){
      this.BatchesReport.companyId=this.currentUser.id
    }
    this.BatchesReport.reportCreatedBy=this.currentUser.name;
   }

  ngOnInit(): void {
    this.GetCompanies();
    this.getcollectors()
  }

  getcollectors(){
    this.DonationRequestsService.Getcollectors().subscribe(response=>{
          
      this.Getcollectors = response.data
    })
  }

  SelectCompany(CompanyId){
    this.BatchesReport.companyId=parseInt(CompanyId);
    }
  GetCompanies(){
    this.CompanyDataService.GetCompanies().subscribe(res=>{
      this.Companies=res.data
    })
  }
  getCollector(event){
   
  

    this.BatchesReport.collectorId = parseInt(event) 
    this.BatchesReport.colletorName = this.Getcollectors.find(x => x.id == event).name
   }


  batchsubmit(){
if(this.BatchesReport.companyId!=null){
  if (this.BatchesReport.collectorId==null) {
      this.BatchesReport.collectorId=null
      this.BatchesReport.colletorName=''

     
     }
     localStorage["ReportName"]="CollectorBatchesReport";
     localStorage.setItem('ReportFilter',JSON.stringify(this.BatchesReport));
   
     this.router.navigate([]).then((result) => {
       window.open("/content/admin/call-start-end-report", '_blank');
     });
}
else{
  Swal.fire({
    icon:'error',
    title:'خطأ',
    text:'يجب اختيار الشركة'

  })
} 
  }

}
