import { Component, OnInit } from '@angular/core';
import { SourcemarketCustomers } from '../../../../shared//Models/sourcemarket-customers';
import { Router } from '@angular/router';
import { CitiesApiService } from 'src/app/shared/API-Service/cities-api.service';
import { GovernorateApiService } from 'src/app/shared/API-Service/governorate-api.service';
import { SourceOfmarketingService } from '../../../../shared/API-Service/source-ofmarketing.service';
import { CallReasonApiService } from 'src/app/shared/API-Service/call-reason-api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-source-market-customers',
  templateUrl: './source-market-customers.component.html',
  styleUrls: ['./source-market-customers.component.css']
})
export class SourceMarketCustomersComponent implements OnInit {
  ReportsInfo = new SourcemarketCustomers();
  Governorate_List: any;
  City_list: any[];
  getsourcemarket: any[];
  CallReason_List:any
  currentUser: any;
    
  constructor( private CallReasonService: CallReasonApiService,private SourceOfmarketingService:SourceOfmarketingService,private governorateApiService:GovernorateApiService,private citiesApiService:CitiesApiService,private router:Router
   ) {
    this.currentUser=JSON.parse(localStorage.getItem("RiskCurrentUser"))
    this.ReportsInfo.reportCreatedBy=this.currentUser.name;
    }

  ngOnInit(): void {
    this.getgevernrate();
    this.getsourcemarketing();
    this.GetCallReason();
  }
  getgevernrate(){
    this.governorateApiService.GetGovernorate().subscribe(response=>{
      this.Governorate_List = response.data
    })
  }
  SelectedsourceOfMarketing(event){
    this.ReportsInfo.sourceOfMarketingId = parseInt(event) 
    this.ReportsInfo.sourceOfMarketingName = this.getsourcemarket.find(x => x.id == event).title
  }
  SelectedcallReason(event){
    this.ReportsInfo.callReasonId = parseInt(event) 
    this.ReportsInfo.callReasonName = this.CallReason_List.find(x => x.id == event).title
  }
  Selectedcity(event){
    this.ReportsInfo.cityId = parseInt(event) 
    this.ReportsInfo.cityName = this.City_list.find(x => x.id == event).title

  }
   SelectedGovernorate(event:any){
   if (this.ReportsInfo.governoratId== null) {
    this.ReportsInfo.governoratId=null
    this.ReportsInfo.governoratName=''
    this.ReportsInfo.cityId = null
    this.ReportsInfo.cityName = ''
    this.City_list=[];
   
   } 
 
   else{    
     this.citiesApiService.GetCitiesWithGovernorate(this.ReportsInfo.governoratId)
    .subscribe(
      response => {
        this.City_list = response.data;
       
      },
    )
  
    this.ReportsInfo.governoratId = parseInt(event)
    this.ReportsInfo.governoratName = this.Governorate_List.find(x => x.id == event).title
  }
  
      
  
  
    }
  
  getsourcemarketing(){
      this.SourceOfmarketingService.GetAllsourceOfMarketingForReport().subscribe(response=>{
  
            this.getsourcemarket = response.data
      })
    }
    GetCallReason() {
      this.CallReasonService.GetCallReason().subscribe(
        response => {
          this.CallReason_List = response.data;        
        },
        err => {
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: err.error.message,
          })
        }
      )
    }
    Viewreports() {
      if (this.ReportsInfo.sourceOfMarketingId == null) {
                  Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: "يجب اختيار مصدر الاعلان",
          })
      }
      else if (this.ReportsInfo.governoratId == null) {
        Swal.fire({
  icon: 'error',
  title: 'خطأ',
  text: "يجب اختيار المحافظه ",
})
}
     else{
      if (this.ReportsInfo.governoratId == null) {
        this.ReportsInfo.governoratId = null
        this.ReportsInfo.governoratName = ''
      }
      if (this.ReportsInfo.cityId == null) {
        this.ReportsInfo.cityId = null
        this.ReportsInfo.cityName = ''
      }
      if (this.ReportsInfo.callReasonId == null) {
        this.ReportsInfo.callReasonId = null
        this.ReportsInfo.callReasonName = ''
      }
        localStorage["ReportName"] = "SourceMarketCustomers";
      localStorage.setItem('ReportFilter', JSON.stringify(this.ReportsInfo));
  
      this.router.navigate([]).then((result) => {
        window.open("/content/admin/call-start-end-report", '_blank');
      });
    }
     }
     
}
