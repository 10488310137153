<app-breadcrumb [title]="' تقرير الرسوم البيانية'" ></app-breadcrumb>

<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header p-3 ">
          <div class=" course-card" *ngIf="currentUser.roleId!=10">
            <div class="card-header card-no-border">
              <div  class="header-top">
                <h5 class="m-0">الشركات</h5>
                
              </div>
            </div>
            <div  class="card-body pt-0">
              <div  class="course-main-card row">
                <div  class="course-wrapper m-0 col-2" style="width: 140px;" (click)="selectCompany(item.id)"  *ngFor="let item of Companies; let i = index;">
                  <div  class="course-icon-box " >
                    <div  class="icon-wrap" [ngClass]="{ 'selected-item': item.id === CompanyId }"><img 
                        alt="clock vector"   [src]="imgUrlServer+item.icon" alt="{{item.name}}"></div>
                        <!-- <img
                       class="arrow-bg"
                      src="assets/images/dashboard/1.png" *ngIf="item.id !== CompanyId" style="width: 140px;" alt="{{item.name}}"> -->
                  </div>
                  <h6 [ngClass]="{ 'selected-FontColor': item.id === CompanyId }" class="f-11">{{item.name}}</h6>
                </div>

              </div>
            </div>
          </div>
          <h3>تقرير التبرعات</h3>
          <div class="container mt-5 d-flex justify-content-center">
            <div class="btn-group " style=" margin-left: -21px;" role="group" aria-label="Basic example">
              <button [ngClass]="{ 'DateChooseActive': fromday==true }" type="button" active class="btn  bg-white" autofocus (click)="getfromday()"><i
                  class="fa fa-calendar px-1"></i> اليوم</button>
              <button [ngClass]="{ 'DateChooseActive': frommonth==true }" type="button"  class="btn " (click)="getfrommonth()"><i
                  class="fa fa-calendar px-1"></i>الشهر</button>
              <button [ngClass]="{ 'DateChooseActive': fromyear==true }" type="button" class="btn " (click)="getfromyear()"><i
                  class="fa fa-calendar px-1"></i>السنة</button>
              <button [ngClass]="{ 'DateChooseActive': formalldata==true }" type="button" class="btn " (click)="getall()"><i
                  class="fa fa-calendar px-1"></i>الكل</button>
            </div>
          </div>

          <!-- <div class="col-lg-3 ">
              <button type="button" class="btn btn-outline-danger txt-dark " (click)="getfromday()">اليوم<i class="fa fa-calendar"></i> </button>
            </div>
            <div class="col-md-3 "><button type="button" class="btn btn-outline-danger " (click)="getfrommonth()">
              الشهر<i class="fa fa-calendar"></i>
            </button></div>
            <div class="col-md-3 "><button type="button" class="btn btn-outline-danger " (click)="getfromyear()">السنة</button></div>
            <div class="col-md-3 "><button type="button" class="btn btn-outline-danger " (click)="getall()">الكل</button></div>
          </div> -->
        </div>
      </div>
      <!-- <i class="fa fa-calendar"></i> -->


      <!-- 

                <div class="col-xl-3 col-sm-6 box-col-6">
                  <div class="card ecommerce-widget">
                    <div class="card-body support-ticket-font">
                      <div class="row">
                        <div class="col-5" style="text-align: right;"><h4>التبرعات المفتوحة </h4>
                          <h3 class="total-num counter" class="counter"></h3>
                        </div>
                        <div class="col-7" style="margin-top: -50px;">
                          <div class="text-md-right">
                            <ul>
                              <li class="text-primary">عدد التبرعات <span class="product-stts  ml-2">
                                {{thedata?.donationsStats.totalCount}}
                              </span>
                            </li>
                              <li class="text-primary">اجمالي التبرعات<span class="product-stts ml-2">
                                {{thedata?.donationsStats.totalAmount}}
                             </span>
                            </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                        <div class="col-12 mb-0">
                          <div class="text-md-right row">
                            <div class="col-lg-4">
                              <span style="font-size: 40px;" class="p-1 text-primary">{{thedata?.donationsStats.collectedCount}}</span><span style="font-size: 12px;color: grey;">تبرع</span>
                             </div>
                             <div class="col-lg-1">
                              <p style="font-size: 30px;">/</p>
                             </div>
                             <div class="col-lg-6 ">
                              <span style="font-size: 20px;" class="p-1">{{thedata?.donationsStats.collectedAmount}}</span> <span style="font-size: 15px;color: grey;">ج . م</span> 
                             </div>
                          </div>
                        </div>
                      </div>

                      <div class="progress-showcase mt-0">
                        <div class="progress sm-progress-bar">
                          <div class="progress-bar bg-primary" role="progressbar" style="width: 100%" aria-valuenow="25"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->



      <div class="card-body ">
        <div class="row">

          <!-- ===================================================================================================== -->
          <div class="col-xl-3 col-sm-6 box-col-6">
            <div class="card ecommerce-widget">
              <div class="card-body support-ticket-font">
                <div style="text-align: right !important;">
                  <h3 class="text-primary">التبرعات المفتوحة </h3>
                </div>
                <div class="row">
                  <div class="col-12 numbers">
                    <div class="text-md-right">
                      <ul style="text-align: right !important; ">
                        <li class="">عدد التبرعات
                          <span class="product-stts  ml-2" style="font-size: 15px !important; font-weight:bold;">
                            {{thedata?.donationsStats.totalCount}}
                          </span>
                        </li>
                        <li>اجمالي التبرعات<span class="product-stts ml-2"
                            style="font-size: 15px !important; font-weight:bold;">
                            {{thedata?.donationsStats.totalAmount}}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <ul class="list-inline " style="text-align: right !important; margin-top: -30px; padding: 0 23px">
                <li class="list-inline-item p-2 text-primary" style="font-size: 30px !important;">
                  {{thedata?.donationsStats.openCount}}<span class="p-1"
                    style="font-size: 10px !important;">تبرع</span>/</li>
                <li class="list-inline-item text-success fw-bold" style="font-size: 20px !important;">
                  {{thedata?.donationsStats.openAmount}}<span class="p-1" style="font-size: 10px !important;">ج .
                    م</span></li>
              </ul>
              <div class="progress-showcase mt-0">
                <div class="progress sm-progress-bar">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 100%" aria-valuenow="25"
                    aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <button appActionNew (click)="goToDetails(1)" class="btn  btn-primary  btn-lg btn-block">التفاصيل</button>

              </div>
            </div>
          </div>


          <!-- ============================================ -->

          <div class="col-xl-3 col-sm-6 box-col-6">
            <div class="card ecommerce-widget">
              <div class="card-body support-ticket-font">
                <div style="text-align: right !important;">
                  <h3 class="text-danger">التبرعات المستلمة </h3>
                </div>
                <div class="row">
                  <div class="col-12 numbers">
                    <div class="text-md-right">
                      <ul style="text-align: right !important; ">
                        <li class="">عدد التبرعات
                          <span class="product-stts  ml-2" style="font-size: 15px !important; font-weight:bold;">
                            {{thedata?.donationsStats.totalCount}}
                          </span>
                        </li>
                        <li>اجمالي التبرعات<span class="product-stts ml-2"
                            style="font-size: 15px !important; font-weight:bold;">
                            {{thedata?.donationsStats.totalAmount}}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <ul class="list-inline " style="text-align: right !important; margin-top: -30px; padding: 0 23px">
                <li class="list-inline-item p-2 text-danger" style="font-size: 30px !important;">
                  {{thedata?.donationsStats.receivedCount}}<span class="p-1"
                    style="font-size: 10px !important;">تبرع</span>/</li>
                <li class="list-inline-item text-success fw-bold" style="font-size: 20px !important;">
                  {{thedata?.donationsStats.receivedAmount}}<span class="p-1" style="font-size: 10px !important;">ج .
                    م</span></li>
              </ul>
              <div class="progress-showcase mt-0">
                <div class="progress sm-progress-bar">
                  <div class="progress-bar bg-danger" role="progressbar" style="width: 100%" aria-valuenow="25"
                    aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <button appActionNew (click)="goToDetails(2)" class="btn btn-danger  btn-lg btn-block">التفاصيل</button>

              </div>

            </div>

          </div>


          <!-- ============================================ -->

          <div class="col-xl-3 col-sm-6 box-col-6">
            <div class="card ecommerce-widget">
              <div class="card-body support-ticket-font">
                <div style="text-align: right !important;">
                  <h3 class="txt-info">التبرعات المحصلة </h3>
                </div>
                <div class="row">
                  <div class="col-12 numbers">
                    <div class="text-md-right">
                      <ul style="text-align: right !important; ">
                        <li class="">عدد التبرعات
                          <span class="product-stts  ml-2" style="font-size: 15px !important; font-weight:bold;">
                            {{thedata?.donationsStats.totalCount}}
                          </span>
                        </li>
                        <li>اجمالي التبرعات<span class="product-stts ml-2"
                            style="font-size: 15px !important; font-weight:bold;">
                            {{thedata?.donationsStats.totalAmount}}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <ul class="list-inline " style="text-align: right !important; margin-top: -30px; padding: 0 23px">
                <li class="list-inline-item p-2 txt-info" style="font-size: 30px !important;">
                  {{thedata?.donationsStats.collectedCount}}<span class="p-1"
                    style="font-size: 10px !important;">تبرع</span>/</li>
                <li class="list-inline-item text-success fw-bold" style="font-size: 20px !important;">
                  {{thedata?.donationsStats.collectedAmount}}<span class="p-1" style="font-size: 10px !important;">ج .
                    م</span></li>
              </ul>
              <div class="progress-showcase mt-0">
                <div class="progress sm-progress-bar">
                  <div class="progress-bar bg-info" role="progressbar" style="width: 100%" aria-valuenow="25"
                    aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <button appActionNew (click)="goToDetails(3)" class="btn btn-info  btn-lg btn-block">التفاصيل</button>

              </div>
            </div>
          </div>

          <!-- ============================================ -->
          <div class="col-xl-3 col-sm-6 box-col-6">
            <div class="card ecommerce-widget">
              <div class="card-body support-ticket-font">
                <div style="text-align: right !important;">
                  <h3 class="text-success">التبرعات المغلقة </h3>
                </div>
                <div class="row">
                  <div class="col-12 numbers">
                    <div class="text-md-right">
                      <ul style="text-align: right !important; ">
                        <li class="">عدد التبرعات
                          <span class="product-stts  ml-2" style="font-size: 15px !important; font-weight:bold;">
                            {{thedata?.donationsStats.totalCount}}
                          </span>
                        </li>
                        <li>اجمالي التبرعات<span class="product-stts ml-2"
                            style="font-size: 15px !important; font-weight:bold;">
                            {{thedata?.donationsStats.totalAmount}}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <ul class="list-inline " style="text-align: right !important; margin-top: -30px; padding: 0 23px">
                <li class="list-inline-item p-2 text-success" style="font-size: 30px !important;">
                  {{thedata?.donationsStats.colsedCount}}<span class="p-1"
                    style="font-size: 10px !important;">تبرع</span>/</li>
                <li class="list-inline-item text-success fw-bold" style="font-size: 20px !important;">
                  {{thedata?.donationsStats.colsedAmount}}<span class="p-1" style="font-size: 10px !important;">ج .
                    م</span></li>
              </ul>
              <div class="progress-showcase mt-0">
                <div class="progress sm-progress-bar">
                  <div class="progress-bar bg-success" role="progressbar" style="width: 100%" aria-valuenow="25"
                    aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <button appActionNew (click)="goToDetails(4)" class="btn btn-success  btn-lg btn-block">التفاصيل</button>

              </div>
            </div>
          </div>



        </div>
      </div>
      <!-- ======================================================================= -->


      <!-- ================================== -->






      <!-- ======================================================================================== -->






      <!-- ======================================================================================================== -->
      <!-- ============================== FINISHED THE CARDS ====================================================== -->
      <!-- ======================================================================================================== -->

      <!-- <div class="col-xl-3 col-sm-4 box-col-6">
              <div class="card  ecommerce-widget">
                <div class="card-body support-ticket-font">
                  <div class="row">
                    <div class="col-lg-5 text-primary">
                      <h5>التبرعات المحصلة </h5>
                     </div> 
                     <div class="col-lg-5 offset-lg-1">
                      <p >عدد التبرعات {{thedata?.donationsStats.totalCount}}</p>
                      <p >اجمالي التبرعات {{thedata?.donationsStats.totalAmount}}</p>
                     </div>
                     <div class="col-lg-4">
                      <span style="font-size: 20px;" class="p-1">{{thedata?.donationsStats.collectedCount}}</span><span style="font-size: 12px;color: grey;">تبرع</span>
                     </div>
                     <div class="col-lg-1">
                      <p style="font-size: 30px;">/</p>
                     </div>
                     <div class="col-lg-6 ">
                      <span style="font-size: 20px;">{{thedata?.donationsStats.collectedAmount}}</span> <span style="font-size: 15px;color: grey;">ج . م</span> 
                     </div>

                  </div>
                  <div class="progress-showcase mt-0">
                    <div class="progress sm-progress-bar">
                      <div class="progress-bar bg-primary" role="progressbar" style="width: 100%" aria-valuenow="25"
                        aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->


      <!-- ======================================================================================================== -->
      <!-- ============================== START THE GRAPH (محافظات)====================================================== -->
      <!-- ======================================================================================================== -->


      <div class="container mt-5">
        <div class="row ">
          <div class="col-md-12">
            <h1>محافظات اكثر تبرعاً</h1><br>
            <div class=" " id="chartContainer"><br>

              <canvas id="myChart2"></canvas>
            </div>

          </div>
        </div>
      </div>


      <!-- ======================================================================================================== -->
      <!-- ============================== START THE GRAPH (كيف)====================================================== -->
      <!-- ======================================================================================================== -->

      <div class="container ">
        <div class="row">
          <div class="col-md-12">
            <h1>كيف</h1><br>
            <div class=" " id="chartContainer1"><br>

              <canvas id="myChart1" width="1200" height="700"></canvas>
            </div>

          </div>
        </div>
      </div>
      <!-- ======================================================================================================== -->
      <!-- ============================== FINISHED THE GRAPH ====================================================== -->
      <!-- ======================================================================================================== -->
      <!-- ======================================================================================================== -->
      <!-- ============================== START THE GRAPH (المشروعات)====================================================== -->
      <!-- ======================================================================================================== -->

      <div class="container ">
        <div class="row">
          <div class="col-md-12">
            <div class=" "><br>
              <h1>المشروعات الاكثر تبرعاً</h1><br>
              <div class=" " id="chartContainer2"><br>

              <canvas id="myChart3" width="1200" height="700"></canvas>
            </div>

          </div>
        </div>
      </div>
      <!-- ======================================================================================================== -->
      <!-- ============================== FINISHED THE GRAPH ====================================================== -->
      <!-- ======================================================================================================== -->
      <!-- ======================================================================================================== -->
      <!-- ============================== START THE GRAPH (اماكن لمشروعات)====================================================== -->
      <!-- ======================================================================================================== -->

      <div class="container ">
        <div class="row">
          <div class="col-md-12">
            <div class=" "><br>
              <h1>اماكن المشروعات الاكثر تبرعاً</h1><br>
              <div class=" " id="chartContainer3"><br>

              <canvas id="myChart4" width="1200" height="700"></canvas>
            </div>

          </div>
        </div>
      </div>
      <!-- ======================================================================================================== -->
      <!-- ============================== FINISHED THE GRAPHS ====================================================== -->
      <!-- ======================================================================================================== -->
      <!-- ======================================================================================================== -->
      <!-- ============================== Start THE TABLE ====================================================== -->
      <!-- ======================================================================================================== -->






      <div class="col-sm-12 mt-5">
        <div class="card">
          <div class="card-header">
            <h3>الموظفين</h3>
          </div>
          <div class="table-responsive">
            <table class="table table-dark">
              <thead>
                <tr>
                  <th scope="col"> </th>
                  <th scope="col">الأسم</th>
                  <th scope="col">رقم الهاتف</th>
                  <th scope="col">اجمالي المكالمات</th>
                  <th scope="col">المكالمات الواردة</th>
                  <th scope="col">المكالمات الصادرة </th>
                  <th scope="col"> عدد التبرعات </th>
                  <th scope="col"> اجمالي التبرعات </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let one of employeesstats; let y = index">
                  <td scope="row">{{y + 1}}</td>
                  <td>{{one.name}}</td>
                  <td>{{one.mobile}}</td>
                  <td>{{one.allCalls}}</td>
                  <td>{{one.inCalls}}</td>
                  <td>{{one.outCalls}}</td>
                  <td>{{one.donationCount}}</td>
                  <td>{{one.donationAmount}}</td>
                  <!-- <td></td>
                        <td></td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>