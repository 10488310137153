<div class="  modal-dialog-full" role="document">
  <div class="modal-content modal-content-full">
<div class="modal-header  full-width" >
    <h4 class="modal-title">اختار شركة</h4>
    

    <!-- <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button> -->
  </div>
  <input      class="form-control m-10 w-auto"  (keyup)="applyFilter($event.target.value)" placeholder="بحث">
<br>
  <div class="modal-body custom-scrollbar"  style="max-height: 400px; overflow-y: auto; ">
   
        <div class="row">

        <ng-container *ngFor="let item of Clients">
 
        <div class="col-md-4">
          <div class="card">
           <div   
          (click)="getClientId(item)">
          
          <img [src]=" image+item.companyLogoPath " class="card-img-top" style="margin-top:20px ;max-height:165px;" alt="..." />
          <div class="card-body">
            <h5 class="card-title text-center" style="color: black;">{{item.companyName}}</h5>
          </div>
        </div>
      </div>
          </div>
      
      
    </ng-container>
  </div>
  </div>
  </div>
  </div>