import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientTypeApiService } from 'src/app/shared/API-Service/client-type-api.service';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { GenericResponse } from 'src/app/shared/Models/GenericResponse';
import { GetCompanyType } from 'src/app/shared/Models/GetClientType';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-client-type',
  templateUrl: './list-client-type.component.html',
  styleUrls: ['./list-client-type.component.css']
})
export class ListClientTypeComponent extends BaseComponent implements OnInit {

  //#region  Declare Variables
  response: GenericResponse<GetCompanyType>;
  Client_Type_List: any;
  //#endregion

  //#region constructor
  constructor(private clientTypeApiService: ClientTypeApiService , private router:Router) { super()}
  //#endregion

  //#region  ng OnInit
  ngOnInit(): void {
    this.Client_Type_List = [];
    this.getClientType();
  }
  //#endregion

  pageChanged(event:any){
    var res
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.clientTypeApiService.GetClientTypeList(this.pager).subscribe(
      response => {
        this.response = response;
        this.Client_Type_List = response.data;
         this.totalCount = this.Client_Type_List.totalCount;
     this.Client_Type_List=this.Client_Type_List.items;
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
    // newLocl._ShipmentService.Get(data).subscribe((res)=>{
    //   this.response=res.data;
    //   this.totalItems = this.response.totalCount;
    //   this.response=this.response.items;
    // })
  }
  //#region Consume API's

  //#region  Get Client Types
  getClientType() {

    this.clientTypeApiService.GetClientTypeList(this.pager).subscribe(
      response => {
        this.response = response;
        this.Client_Type_List = response.data;
        this.totalCount = this.Client_Type_List.totalCount;
        this.Client_Type_List=this.Client_Type_List.items;
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }
  //#endregion

  //#region  Delete Client Type
  DeleteClient(id:number){
    Swal.fire({
      title: ' تحذير !',
      text: "هل انت متأكد من حذف هذا العنصر ؟ ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7364f6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'حذف',
      cancelButtonText: 'إنهاء',
    })
    .then((result) => {

      if (result.isConfirmed) {
          this.clientTypeApiService.DeleteClientType(id).subscribe(
            response=>{
              this.getClientType();
               Swal.fire({
                    icon: 'success',
                    title: "تم حذف نوع عميل بنجاح",
                    showConfirmButton: false,
                    timer: 1500}) 
                    this.router.navigateByUrl("content/admin/Get-client-type");
                  },
            err=>{
              Swal.fire({
                icon: 'error',
                title: 'خطأ',
                text: err.error.message,
              })
            }
          )
        
      } else {
        // Swal.fire(
        //   'Your appointment still active ',
        // );
      }
    }); 
  }
  //#endregion
  
  //#endregion

  //#region AddNew
  AddNew(){
    this.router.navigateByUrl("content/admin/client-type");
  }
  //#endregion

  //#region updateClient
  updateClient(id:number,title:string){
  
    localStorage.setItem("clientTypeTitle",title);
    this.router.navigate(['content/admin/update-client-type',id]);

  }
  //#endregion

}
