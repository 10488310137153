<app-breadcrumb [title]="'تقرير حالات التبرعات اليومي'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'">
</app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header px-0 " style="padding-bottom: 5px;">
                    <div class="row m-0 mb-2">
                        <div class="col-12 col-md-12">
                          <label for="validationCustom02"><span class="validation-required">*</span>  اسم الشركة</label>
                          <br>
                            <label *ngIf="currentUser.roleId==10">{{currentUser.name}}</label>
                      <ng-select *ngIf="currentUser.roleId!=10"
                      class="js-example-basic-single col-sm-12 job-select2 form-group"
                        required=""
                        [(ngModel)]="ReportsInfo.companyId"
                        aria-label="Default select example"
                        (change)="SelectCompany($event.id)"
                        [items]="Companies"
                        bindLabel="name"
                        bindValue="id"
                         >
                      
                      </ng-select>
                          </div>
                        <div class="col-md-6">
                          <label for=""> بدايه تاريخ حالات التبرعات
                          </label>
                            <div class="form-group">
                                
                               <input type="date"  class="form-control" [(ngModel)]="ReportsInfo.dateFrom">
                            </div>
                        </div>
                        <div class="col-md-6">
                          <label for="">نهايه تاريخ حالات التبرعات</label>
                            <div class="form-group">
                             
                               <input type="date"  class="form-control" [(ngModel)]="ReportsInfo.dateTo">
                            </div>
                        </div>

                        <div class="col-12 col-md-12">
                    <div class="d-flex justify-content-end align-items-center buttom-text">
                      <button class="addnew-btn" type="submit" (click)="Viewreports()">عرض التقرير</button>
                    </div>
                </div>
                </div>
    </div>
</div>
        </div>
    </div>
</div>