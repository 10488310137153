import { DatePipe } from '@angular/common';
import { SourceOfmarketingService } from '../../../../shared/API-Service/source-ofmarketing.service';
import { DonationRequestsService } from '../../../../shared/API-Service/donation-requests.service';
import { Component, OnInit } from '@angular/core';
import { FollowUpCalls } from '../../../../shared/Models/FollowUpCalls';
import { CallReasonApiService } from 'src/app/shared/API-Service/call-reason-api.service';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-follow-up-call-report',
  templateUrl: './follow-up-call-report.component.html',
  styleUrls: ['./follow-up-call-report.component.css']
})
export class followUpCallReportComponent implements OnInit {
  FollowUpCalls= new FollowUpCalls();
  currentUser: any;
  Companies: any[];
  getEmployee: any[];
  constructor(private DonationRequestsService:DonationRequestsService,
    private SourceOfmarketingService:SourceOfmarketingService,public datepipe:DatePipe,private router:Router,
    private CallReasonService: CallReasonApiService,
    private CompanyDataService:CompanyDataService
    ) { 
    
this.currentUser=JSON.parse(localStorage.getItem("RiskCurrentUser"))
if(this.currentUser.roleId==10){
  this.FollowUpCalls.companyId=this.currentUser.id
}
this.FollowUpCalls.reportCreatedBy=this.currentUser.name;
const date = new Date();
      this.FollowUpCalls.callDateFrom=this.datepipe.transform(date, 'yyyy-MM-dd');
      this.FollowUpCalls.callDateTo=this.datepipe.transform(date,'yyyy-MM-dd');

    }
    SelectCompany(CompanyId){
      this.FollowUpCalls.companyId=parseInt(CompanyId);

    }
    GetCompanies(){
      this.CompanyDataService.GetCompanies().subscribe(res=>{
        this.Companies=res.data
      })
    }
  ngOnInit(): void {
    this.GetCompanies()
   this.Getemployee();

  }
  selectEmplyee(event){
    this.FollowUpCalls.employeeId = parseInt(event) 
    this.FollowUpCalls.employeeName = this.getEmployee.find(x => x.id == event).name
   }
  
  Getemployee(){
    this.SourceOfmarketingService.EmployeeGet().subscribe(res=>{
      this.getEmployee = res.data
    })
   }
 


Viewreports(){
    if(this.FollowUpCalls.companyId!=null&&this.FollowUpCalls.callDateFrom &&this.FollowUpCalls.callDateTo){
 
  if(this.FollowUpCalls.employeeId==null){
    this.FollowUpCalls.employeeId=null;
    this.FollowUpCalls.employeeName=""
  }
 
  localStorage["ReportName"]="FollowUpCallsReport";
  localStorage.setItem('ReportFilter',JSON.stringify(this.FollowUpCalls));

  this.router.navigate([]).then((result) => {
    window.open("/content/admin/call-start-end-report", '_blank');
  });
}
else{
  if(!this.FollowUpCalls.companyId){
    Swal.fire({
      icon:'error',
      title:'خطأ',
      text:'يجب اختيار الشركة'

    })
  }
else   if (!this.FollowUpCalls.callDateFrom){
    Swal.fire({
      icon:'error',
      title:'خطأ',
      text:' يجب اختيار بدايه تاريخ المكالمات'
    })
  }
  else  if (!this.FollowUpCalls.callDateTo ){
    Swal.fire({
      icon:'error',
      title:'خطأ',
      text:' يجب اختيار نهايه تاريخ المكالمات'

    })
  }
}
}

}
