import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agent-list-stat',
  templateUrl: './agent-list-stat.component.html',
  styleUrls: ['./agent-list-stat.component.css']
})
export class AgentListStatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
