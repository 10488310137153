// import { stringify } from "@angular/compiler/src/util";
// import { Injectable, OnDestroy } from "@angular/core";
// import { Subject, BehaviorSubject, fromEvent } from "rxjs";
// import { takeUntil, debounceTime } from "rxjs/operators";
// import { Router } from "@angular/router";
// import { Roles } from "../Models/Roles";

// // Menu
// // Menu
// export interface Menu {
// 	headTitle1?: string,
// 	headTitle2?: string,
// 	path?: string;
// 	title?: string;
// 	icon?: string;
// 	type?: string;
// 	badgeType?: string;
// 	badgeValue?: string;
// 	active?: boolean;
// 	bookmark?: boolean;
// 	children?: Menu[];
// }

// @Injectable({
// 	providedIn: 'root'
// })



// export class NavService implements OnDestroy {

// 	private unsubscriber: Subject<any> = new Subject();
// 	public  screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);
	
// 	// Search Box
// 	public search: boolean = false;

// 	// Language
// 	public language: boolean = false;
	
// 	// Mega Menu
// 	public megaMenu: boolean = false;
// 	public levelMenu: boolean = false;
// 	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

// 	// For Horizontal Layout Mobile
// 	public horizontal: boolean = window.innerWidth < 991 ? false : true;

// 	// Collapse Sidebar
// 	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

// 	// Full screen
// 	public fullScreen: boolean = false;

// 	public Role:any=localStorage.getItem("RiskRole");
// 	public RoleItem = 1  // private admin only
// 	public Roledonation = 8  // private admin only

// 	constructor(private router: Router) {
// 		// this.Role  = localStorage.getItem("RiskRole");
// 		this.setScreenWidth(window.innerWidth);
// 		fromEvent(window, 'resize').pipe(
// 			debounceTime(1000),
// 			takeUntil(this.unsubscriber)
// 		).subscribe((evt: any) => {
// 			this.setScreenWidth(evt.target.innerWidth);
// 			if (evt.target.innerWidth < 991) {
// 				this.collapseSidebar = true;
// 				this.megaMenu = false;
// 				this.levelMenu = false;
// 			}
// 			if(evt.target.innerWidth < 1199) {
// 				this.megaMenuColapse = true;
// 			}
// 		});
// 		if(window.innerWidth < 991) { // Detect Route change sidebar close
// 			this.router.events.subscribe(event => { 
// 				this.collapseSidebar = true;
// 				this.megaMenu = false;
// 				this.levelMenu = false;
// 			});
// 		}
// 	}

// 	ngOnDestroy() {
// 		this.unsubscriber.next();
// 		this.unsubscriber.complete();
// 	}

// 	private setScreenWidth(width: number): void {
// 		this.screenWidth.next(width);
// 	}


//   //Role: string = localStorage.getItem("RiskRole");

 

//   // MENUITEMS: Menu[] = [
//   //   {
//   //     headTitle1: "لوحة التحكم ",
//   //     headTitle2: "إدارة المحتويات الموجودة",
//   //   },
//   //   {
//   //     title: "خدمة العملاء",
//   //     icon: "home",
//   //     type: "sub",
//   //     badgeType: "success",
//   //     active: true,
//   //     children: [
//   //       { path: "agent/main", title: "الشركات", type: "link" },
//   //       { path: "agent/stat", title: "إحصائيات", type: "link" },
//   //       // { path: 'agent/Customer', title: 'تسجيل بيانات', type: 'link' },
//   //     ],
//   //   },
//   // ];
//   MENUITEMS: Menu[] = [
// 	{
// 		headTitle1: 'لوحة التحكم ', headTitle2: 'إدارة المحتويات الموجودة',
// 	},
// 	{
// 		title: 'خدمة العملاء', icon: 'home', type: 'sub', badgeType: 'success', active: true, children: [
// 			{ path: 'agent/main', title: 'الشركات', type: 'link' },
// 			{ path: 'agent/stat', title: 'إحصائيات', type: 'link' },
// 			// { path: 'agent/Customer', title: 'تسجيل بيانات', type: 'link' },
// 		]
// 	}
// ];
// //   MENUITEMS: Menu[] = [
// // 		{
// // 			headTitle1: 'لوحة التحكم ', headTitle2: 'إدارة المحتويات الموجودة',
// // 		},
// // 		{
// // 			title: 'خدمة العملاء', icon: 'home', type: 'sub', badgeType: 'success', active: true, children: [
// // 				{ path: 'agent/main', title: 'الشركات', type: 'link' },
// // 				{ path: 'agent/stat', title: 'إحصائيات', type: 'link' },
				
// // 				{
// // 					title: 'التبرعات', icon: 'activity', type: 'sub', badgeType: 'success', active: true, children: [
// // 						{ path: 'agent/CustomerDonation', title: ' التبرعات الخاصه بالعميل', type: 'link' },				
// // 					]
// // 				},
// // 				// { path: 'agent/Customer', title: 'تسجيل بيانات', type: 'link' },
// // 			]
// // 		}
// // 	];
// 	DonationITEMS: Menu[] = [
// 		{
// 			headTitle1: 'لوحة التحكم ', headTitle2: 'إدارة المحتويات الموجودة',
// 			title: ' التبرعات', icon: 'home', type: 'sub', badgeType: 'success', active: true, children: [
// 				{ path: 'donation/unreceived', title: 'عرض التبرعات الغير مستلمه', type: 'link' },
// 				{ path: 'donation/received', title: 'عرض التبرعات المستلمه', type: 'link' },
// 				{ path: 'donation/donations_close', title: 'اغلاق التبرعات', type: 'link' },
				
// 			]
// 		},
		
// 	];

	
//   MENUITEMS_Admin: Menu[] = [
//     {
//       headTitle1: "لوحة التحكم ",
//       headTitle2: "إدارة المحتويات الموجودة",
//     },
//     {
//       title: "التقارير",
//       icon: "archive",
//       type: "sub",
//       badgeType: "success",
//       active: true,
//       children: [
//         { path: "admin/client-report", title: "العملاء", type: "link" },
//         {
//           path: "admin/client-agent-report",
//           title: "العملاء - موظفى الخدمة",
//           type: "link",
//         },
//         {
//           path: "admin/client-call-report",
//           title: "العملاء - المكالمات",
//           type: "link",
//         },
//         {
//           path: "admin/call-start-end-report",
//           title: "ملخص المكالمات",
//           type: "link",
//         },
//       ],
//     },
//     // {
//     // 	title: 'الإحصائيات', icon: 'home', type: 'sub', badgeType: 'success', active: true, children: [
//     // 		{ path: 'admin/statistics/stats', title: 'المكالمات', type: 'link' },
//     // 		{ path: 'admin/statistics/agents', title: 'مكالمات العملاء', type: 'link' },
//     // 		{ path: 'admin/statistics/CallReason', title: ' أسباب المكالمات', type: 'link' },
//     // 	]
//     // },
//     {
//       title: "مسئولى النظام",
//       icon: "home",
//       type: "sub",
//       badgeType: "success",
//       active: true,
//       children: [
//         { path: "admin/InsertEmployee", title: "إضافة مسئول", type: "link" },
//         { path: "admin/GetEmployee", title: "قائمة المسئولين", type: "link" },
//       ],
//     },
//     {
//       title: "موظفى خدمة العملاء",
//       icon: "phone-call",
//       type: "sub",
//       badgeType: "success",
//       active: true,
//       children: [
//         {
//           path: "admin/InsertCustomerService",
//           title: "إضافة موظف",
//           type: "link",
//         },
//         {
//           path: "admin/GetCustomerService",
//           title: " موظفى خدمة العملاء",
//           type: "link",
//         },
//       ],
//     },
//     {
//       title: "إدارة العملاء",
//       icon: "users",
//       type: "sub",
//       badgeType: "success",
//       active: true,
//       children: [
//         { path: "admin/InsertClient", title: "إضافة عميل", type: "link" },
//         { path: "admin/GetClient", title: "قائمة العملاء", type: "link" },
//       ],
//     },
//     {
//       title: "إدارة أنواع العملاء",
//       icon: "home",
//       type: "sub",
//       badgeType: "success",
//       active: true,
//       children: [
//         { path: "admin/client-type", title: "إضافة نوع عميل", type: "link" },
//         {
//           path: "admin/Get-client-type",
//           title: "قائمة أنواع العملاء",
//           type: "link",
//         },
//       ],
//     },
//     {
//       title: "إدارة المحافظات",
//       icon: "home",
//       type: "sub",
//       badgeType: "success",
//       active: true,
//       children: [
//         {
//           path: "admin/insert-governorate",
//           title: "إضافة محافظة",
//           type: "link",
//         },
//         {
//           path: "admin/Get-governorate",
//           title: "قائمة المحافظات",
//           type: "link",
//         },
//       ],
//     },
//     {
//       title: "إدارة المدن",
//       icon: "home",
//       type: "sub",
//       badgeType: "success",
//       active: true,
//       children: [
//         { path: "admin/insert-city", title: "إضافة مدينة", type: "link" },
//         { path: "admin/Get-cities", title: "قائمة المدن", type: "link" },
//       ],
//     },
//     {
//       title: "إدارة أسباب المكالمات",
//       icon: "check-square",
//       type: "sub",
//       badgeType: "success",
//       active: true,
//       children: [
//         {
//           path: "admin/insert-call-reason",
//           title: "أسباب المكالمات",
//           type: "link",
//         },
//         {
//           path: "admin/Get-Call-Reason",
//           title: "قائمة أسباب المكالمات",
//           type: "link",
//         },
//       ],
//     },
//     {
//       title: "إدارة مصدر التسويق",
//       icon: "bar-chart-2",
//       type: "sub",
//       badgeType: "success",
//       active: true,
//       children: [
//         {
//           path: "admin/InsertSourceMarket",
//           title: "مصدر التسويق",
//           type: "link",
//         },
//         {
//           path: "admin/GetSourceMarket",
//           title: "قائمة مصادر التسويق",
//           type: "link",
//         },
//       ],
//     },
//     {
//       title: "إدارة  النشاط ",
//       icon: "activity",
//       type: "sub",
//       badgeType: "success",
//       active: true,
//       children: [
//         { path: "admin/InsertSector", title: "مصدر النشاط", type: "link" },
//         { path: "admin/ListSector", title: "قائمة مصادر النشاط", type: "link" },
//       ],
//     },
//     {
//       title: "إدارة  انواع الانشطه ",
//       icon: "activity",
//       type: "sub",
//       badgeType: "success",
//       active: true,
//       children: [
//         {
//           path: "admin/Insert-Sector-type",
//           title: " نوع النشاط",
//           type: "link",
//         },
//         {
//           path: "admin/Get-sector-type",
//           title: "قائمة انواع الانشطه ",
//           type: "link",
//         },
//       ],
//     },
//     {
//       title: "النوع الرئيسي للتبرع",
//       icon: "activity",
//       type: "sub",
//       badgeType: "success",
//       active: true,
//       children: [
//         {
//           path: "admin/Get-Donation-Reason",
//           title: "  انواع التبرع ",
//           type: "link",
//         },
//         {
//           path: "admin/Inser-Donation-Reason/1",
//           title: " نوع التبرع",
//           type: "link",
//         },
//       ],
//     },
	
//   ];

//   MEGAMENUITEMS: Menu[] = [
//     {
//       title: "Error Pages",
//       type: "sub",
//       active: true,
//       children: [
//         {
//           path: "javascript:void(0);",
//           title: "Error Page 400",
//           type: "extLink",
//         },
//         {
//           path: "javascript:void(0);",
//           title: "Error Page 401",
//           type: "extLink",
//         },
//         {
//           path: "javascript:void(0);",
//           title: "Error Page 403",
//           type: "extLink",
//         },
//         {
//           path: "javascript:void(0);",
//           title: "Error Page 404",
//           type: "extLink",
//         },
//         {
//           path: "javascript:void(0);",
//           title: "Error Page 500",
//           type: "extLink",
//         },
//         {
//           path: "javascript:void(0);",
//           title: "Error Page 503",
//           type: "extLink",
//         },
//       ],
//     },
//     {
//       title: "Authentication",
//       type: "sub",
//       active: false,
//       children: [
//         { path: "javascript:void(0);", title: "Login Simple", type: "extLink" },
//         {
//           path: "javascript:void(0);",
//           title: "Login BG Image",
//           type: "extLink",
//         },
//         {
//           path: "javascript:void(0);",
//           title: "Login BG Video",
//           type: "extLink",
//         },
//         {
//           path: "javascript:void(0);",
//           title: "Simple Register",
//           type: "extLink",
//         },
//         {
//           path: "javascript:void(0);",
//           title: "Register BG Image",
//           type: "extLink",
//         },
//         {
//           path: "javascript:void(0);",
//           title: "Register BG Video",
//           type: "extLink",
//         },
//       ],
//     },
//     {
//       title: "Usefull Pages",
//       type: "sub",
//       active: false,
//       children: [
//         { path: "javascript:void(0);", title: "Search Pages", type: "extLink" },
//         { path: "javascript:void(0);", title: "Unlock User", type: "extLink" },
//         {
//           path: "javascript:void(0);",
//           title: "Forgot Password",
//           type: "extLink",
//         },
//         {
//           path: "javascript:void(0);",
//           title: "Reset Password",
//           type: "extLink",
//         },
//         { path: "javascript:void(0);", title: "Maintenance", type: "extLink" },
//       ],
//     },
//     {
//       title: "Email templates",
//       type: "sub",
//       active: false,
//       children: [
//         {
//           path: "http://admin.pixelstrap.com/cuba/theme/basic-template.html",
//           title: "Basic Email",
//           type: "extTabLink",
//         },
//         {
//           path: "http://admin.pixelstrap.com/cuba/theme/email-header.html",
//           title: "Basic With Header",
//           type: "extTabLink",
//         },
//         {
//           path: "http://admin.pixelstrap.com/cuba/theme/template-email.html",
//           title: "Ecomerce Template",
//           type: "extTabLink",
//         },
//         {
//           path: "http://admin.pixelstrap.com/cuba/theme/template-email-2.html",
//           title: "Email Template 2",
//           type: "extTabLink",
//         },
//         {
//           path: "http://admin.pixelstrap.com/cuba/theme/ecommerce-templates.html",
//           title: "Ecommerce Email",
//           type: "extTabLink",
//         },
//         {
//           path: "http://admin.pixelstrap.com/cuba/theme/email-order-success.html",
//           title: "Order Success",
//           type: "extTabLink",
//         },
//       ],
//     },
//     {
//       title: "Coming Soon",
//       type: "sub",
//       active: false,
//       children: [
//         {
//           path: "javascript:void(0);",
//           title: "Coming Simple",
//           type: "extLink",
//         },
//         {
//           path: "javascript:void(0);",
//           title: "Coming BG Image",
//           type: "extLink",
//         },
//         {
//           path: "javascript:void(0);",
//           title: "Coming BG Video",
//           type: "extLink",
//         },
//       ],
//     },
//   ];
//   LEVELMENUITEMS: Menu[] = [
//     {
//       path: "javascript:void(0);",
//       title: "File Manager",
//       icon: "git-pull-request",
//       type: "extLink",
//     },
//     {
//       title: "Users",
//       icon: "users",
//       type: "sub",
//       active: false,
//       children: [
//         {
//           path: "javascript:void(0);",
//           title: "All Users",
//           icon: "users",
//           type: "extLink",
//         },
//         {
//           path: "javascript:void(0);",
//           title: "User Profile",
//           icon: "users",
//           type: "extLink",
//         },

//         {
//           path: "javascript:void(0);",
//           title: "Edit Profile",
//           icon: "users",
//           type: "extLink",
//         },
//       ],
//     },

// 	// Array
// 	// Array
// 	items = new BehaviorSubject<Menu[]>(this.Role == this.RoleItem?this.MENUITEMS_Admin:this.MENUITEMS && this.Role== this.Roledonation?this.DonationITEMS:this.MENUITEMS);
	
	
// 	megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
// 	levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);

//     {
//       path: "javascript:void(0);",
//       title: "Calender",
//       icon: "calendar",
//       type: "extLink",
//     },

//     {
//       path: "javascript:void(0);",
//       title: "Social App",
//       icon: "zap",
//       type: "extLink",
//     },
//   ];
//   // Array
// //   items = new BehaviorSubject<Menu[]>(
// //     this.Role == this.RoleItem ? this.MENUITEMS_Admin : this.MENUITEMS
// //   );
// //   megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
// //   levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);
// // }
// }
import { stringify } from '@angular/compiler/src/util';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Roles } from '..//Models/Roles';
import { PermissionsApiService } from 'src/app/shared/API-Service/Permissions-api.service';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})



export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public  screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);
	
	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;
	
	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// Full screen
	public fullScreen: boolean = false;

	public Role:any=localStorage.getItem("RiskRole");
	public RoleItem = 1  // private admin only
	public Roledonation = 8  // private collector only
	public RoledCollectorManager  =  9 // private CollectorManager  only
  err: boolean;
  MenuByRoleId: any;

	constructor(private router: Router,private PermissionsApiService : PermissionsApiService) {
		// this.Role  = localStorage.getItem("RiskRole");
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if(evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if(window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => { 
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}
    // this.PermissionsApiService.GetByRoleId().subscribe({
    //   next: (data) => {
    //    var res = data;
    //     if (res.messageCode == 200) {
    //       this.err = false;
    //       this.MenuByRoleId=res.data
    //     } else {
    //       this.err = true;
    //     }
    //   },
    //   error: (error) => {
    //     this.err = true;
    //   },
    // });
	}

	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}


  MENUITEMS_Admin: Menu[] = [
    {
      headTitle1: "لوحة التحكم ",
      headTitle2: "إدارة المحتويات الموجودة",
    },
    {
      title: "التقارير",
      icon: "archive",
      type: "sub",
      badgeType: "success",
      //active: true,
      children: [
        { path: "admin/client-report", title: "العملاء", type: "link" },
        {
          path: "admin/client-agent-report",
          title: "العملاء - موظفى الخدمة",
          type: "link",
        },
        {
          path: "admin/client-call-report",
          title: "العملاء - المكالمات",
          type: "link",
        },
        {
          path: "admin/call-start-end-report",
          title: "ملخص المكالمات",
          type: "link",
        },
        {
          path: "admin/sourceOfMarketing-report",
          title: " مصدر التسويق",
          type: "link",
        },
        {
          path: "admin/donation-requests",
          title: " مطلوب التبرع",
          type: "link",
        },
        {
          path: "admin/collector-batches",
          title: "  المحصل",
          type: "link",
        },
        {
          path: "admin/search-reports",
          title: "  البحث عن تقرير",
          type: "link",
        },
        {
          path: "admin/donation-reports",
          title: "   التبرعات ",
          type: "link",
        },
        {
          path: "admin/CollectorDonations-reports",
          title: "   تقرير التبرعات ",
          type: "link",
        },
       
      ],
    },
    // {
    // 	title: 'الإحصائيات', icon: 'home', type: 'sub', badgeType: 'success', active: true, children: [
    // 		{ path: 'admin/statistics/stats', title: 'المكالمات', type: 'link' },
    // 		{ path: 'admin/statistics/agents', title: 'مكالمات العملاء', type: 'link' },
    // 		{ path: 'admin/statistics/CallReason', title: ' أسباب المكالمات', type: 'link' },
    // 	]
    // },
    {
      title: "موظفي النظام",
      icon: "home",
      type: "sub",
      badgeType: "success",
      //active: true,
      children: [
        { path: "admin/InsertEmployee", title: "إضافة موظف", type: "link" },
        { path: "admin/GetEmployee", title: "قائمة الموظفين ", type: "link" },
      ],
    },
    // {
    //   title: "موظفى خدمة العملاء",
    //   icon: "phone-call",
    //   type: "sub",
    //   badgeType: "success",
    //   active: true,
    //   children: [
    //     {
    //       path: "admin/InsertCustomerService",
    //       title: "إضافة موظف",
    //       type: "link",
    //     },
    //     {
    //       path: "admin/GetCustomerService",
    //       title: " موظفى خدمة العملاء",
    //       type: "link",
    //     },
    //   ],
    // },
    {
      title: "إدارة العملاء",
      icon: "users",
      type: "sub",
      badgeType: "success",
     // active: true,
      children: [
        { path: "admin/InsertClient", title: "إضافة عميل", type: "link" },
        { path: "admin/GetClient", title: "قائمة العملاء", type: "link" },
      ],
    },
    {
      title: "إدارة الوظائف",
      icon: "users",
      type: "sub",
      badgeType: "success",
     // active: true,
      children: [
        { path: "admin/InsertJobs", title: "إضافة وظيفه", type: "link" },
        { path: "admin/ListJobs", title: "قائمة العملاء", type: "link" },
      ],
    },
    {
      title: "إدارة أنواع العملاء",
      icon: "home",
      type: "sub",
      badgeType: "success",
     // active: true,
      children: [
        { path: "admin/client-type", title: "إضافة نوع عميل", type: "link" },
        {
          path: "admin/Get-client-type",
          title: "قائمة أنواع العملاء",
          type: "link",
        },
      ],
    },
    {
      title: "إدارة المحافظات",
      icon: "home",
      type: "sub",
      badgeType: "success",
      //active: true,
      children: [
        {
          path: "admin/insert-governorate",
          title: "إضافة محافظة",
          type: "link",
        },
        {
          path: "admin/Get-governorate",
          title: "قائمة المحافظات",
          type: "link",
        },
      ],
    },
    {
      title: "إدارة المدن",
      icon: "home",
      type: "sub",
      badgeType: "success",
     // active: true,
      children: [
        { path: "admin/insert-city", title: "إضافة مدينة", type: "link" },
        { path: "admin/Get-cities", title: "قائمة المدن", type: "link" },
      ],
    },
    {
      title: "إدارة أسباب المكالمات",
      icon: "check-square",
      type: "sub",
      badgeType: "success",
     // active: true,
      children: [
        {
          path: "admin/insert-call-reason",
          title: "أسباب المكالمات",
          type: "link",
        },
        {
          path: "admin/Get-Call-Reason",
          title: "قائمة أسباب المكالمات",
          type: "link",
        },
      ],
    },
    {
      title: "إدارة مصدر التسويق",
      icon: "bar-chart-2",
      type: "sub",
      badgeType: "success",
   //   active: true,
      children: [
        {
          path: "admin/InsertSourceMarket",
          title: "مصدر التسويق",
          type: "link",
        },
        {
          path: "admin/GetSourceMarket",
          title: "قائمة مصادر التسويق",
          type: "link",
        },
      ],
    },
    {
      title: "التبرعات  الرئيسيه",
      icon: "activity",
      type: "sub",
      badgeType: "success",
      //active: true,
      children: [
        {
          path: "admin/Inser-Donation-Reason/create",
          title: " نوع التبرع",
          type: "link",
        },
        {
          path: "admin/Get-Donation-Reason",
          title: " قائمه التبرعات ",
          type: "link",
        },
       
      ],
    },
    {
      title:" انواع التبرعات",
          icon: "activity",
          type: "sub",
          badgeType: "success",
          //active: true,
          children: [
            
            {
              path: "admin/CreateDonationType",
              title: " نوع التبرع",
              type: "link",
            },
            {
              path: "admin/ViewDonationType",
              title: " انواع التبرعات ",
              type: "link",
            },
          ],
        },
    {
      title: "إدارة  النشاط ",
      icon: "activity",
      type: "sub",
      badgeType: "success",
      //active: true,
      children: [
        { path: "admin/InsertSector", title: " النشاط", type: "link" },
        { path: "admin/ListSector", title: "قائمة الانشطه", type: "link" },
      ],
    },
    {
      title: "  انواع الانشطه ",
      icon: "activity",
      type: "sub",
      badgeType: "success",
      //active: true,
      children: [
        {
          path: "admin/Insert-Sector-type",
          title: " نوع النشاط",
          type: "link",
        },
        {
          path: "admin/Get-sector-type",
          title: "قائمة انواع الانشطه ",
          type: "link",
        },
      ],
    },
    {
      title: "إدارة  اماكن  الانشطه ",
      icon: "activity",
      type: "sub",
      badgeType: "success",
      //active: true,
      children: [
        {
          path: "admin/Insert-Sector-Location",
          title: " مكان  النشاط",
          type: "link",
        },
        {
          path: "admin/Get-sector-Location",
          title: "قائمة اماكن الانشطه ",
          type: "link",
        },
      ],
    },
    // {
    //   title: "التبرعات  الرئيسيه",
    //   icon: "activity",
    //   type: "sub",
    //   badgeType: "success",
     
    //   children: [
    //     {
    //       path: "admin/Inser-Donation-Reason/create",
    //       title: " نوع التبرع",
    //       type: "link",
    //     },
    //     {
    //       path: "admin/Get-Donation-Reason",
    //       title: " قائمه التبرعات ",
    //       type: "link",
    //     },
       
    //   ],
    // },
    // {
    //   title:" انواع التبرعات",
    //       icon: "activity",
    //       type: "sub",
    //       badgeType: "success",
    //       //active: true,
    //       children: [
            
    //         {
    //           path: "admin/CreateDonationType/create",
    //           title: " نوع التبرع",
    //           type: "link",
    //         },
    //         {
    //           path: "admin/ViewDonationType",
    //           title: " انواع التبرعات ",
    //           type: "link",
    //         },
    //       ],
    //     },
    {
  title:" انواع فئات الانشطه",
      icon: "activity",
      type: "sub",
      badgeType: "success",
      //active: true,
      children: [
        
        {
          path: "admin/CreateDonationCategory",
          title: " فئه التبرع",
          type: "link",
        },
        {
          path: "admin/ViewDonationCategory",
          title: " انواع فئه الانشطه ",
          type: "link",
        },
      ],
    },
    {
      title: "إدارة  موظفي  ومدن التخصيل ",
      icon: "activity",
      type: "sub",
      badgeType: "success",
      //active: true,
      children: [
        {
          path: "admin/Insert-Collector-City",
          title: "   المحصل ومدينه تحصيله",
          type: "link",
        },
        {
          path: "admin/Get-Collector-City",
          title: "قائمة موظفي ومدن التحصيل  ",
          type: "link",
        },
      ],
    },
    {
      title: "الرسائل النصيه ",
      icon: "activity",
      type: "sub",
      badgeType: "success",
      //active: true,
      children: [
        {
          path: "admin/addSMSTemplate",
          title:  "  اضافه رساله جديده ",
          type: "link",
        },
        {
          path: "admin/SMSTemplate",
          title:  "  قائمة الرسائل ",
          type: "link",
        },
        
      ],
    },
    {
      title: "صلاحيات ",
      icon: "activity",
      type: "sub",
      badgeType: "success",
      //active: true,
      children: [
        // {
        //   path: "admin/addPermissions",
        //   title:  "  اضافه صلاحيات ",
        //   type: "link",
        // },
        {
          path: "admin/Permissions",
          title:  "  قائمة الصلاحيات ",
          type: "link",
        },
        
      ],
    },
  
  ];

	MENUITEMS: Menu[] = [
		{
			headTitle1: 'لوحة التحكم ', headTitle2: 'إدارة المحتويات الموجودة',
		},
		{
			title: 'خدمة العملاء', icon: 'home', type: 'sub', badgeType: 'success', active: true, children: [
				{ path: 'agent/main', title: 'الشركات', type: 'link' },
				{ path: 'agent/stat', title: 'إحصائيات', type: 'link' },
				{
										title: 'التبرعات', icon: 'activity', type: 'sub', badgeType: 'success', active: true, children: [
					 						{ path: 'agent/CustomerDonation', title: ' التبرعات الخاصه بالعميل', type: 'link' },				
				 					]
								},
				// { path: 'agent/Customer', title: 'تسجيل بيانات', type: 'link' },
			]
		}
	];
	DonationITEMS: Menu[] = [
		{
			headTitle1: 'لوحة التحكم ', headTitle2: 'إدارة المحتويات الموجودة',
			title: ' التبرعات', icon: 'home', type: 'sub', badgeType: 'success', active: true, children: [
				{ path: 'donation/unreceived', title: 'عرض التبرعات الغير مستلمه', type: 'link' },
				{ path: 'donation/received', title: 'عرض التبرعات المستلمه', type: 'link' },
				{ path: 'donation/donations_close', title: 'اغلاق التبرعات', type: 'link' },
				
			]
		},
    {
			title: 'تبرعات امر التوريد', icon: 'home', type: 'sub', active: false, children: [
				{ path: 'donation/cashs/1', title: 'النقدي', type: 'link' },
				{ path: 'donation/cash/2', title: 'العيني', type: 'link' },
				
			]
		},
		
	];
	CollectorManagerITEMS: Menu[] = [
		{
			headTitle1: 'لوحة التحكم ', headTitle2: 'إدارة المحتويات الموجودة',
			title: ' قائمه المندوب', icon: 'home', type: 'sub', badgeType: 'success', active: true, children: [
				{ path: 'collectormanager/change-delegate', title: 'تغير المندوب', type: 'link' },
				{ path: 'collectormanager/close-requisition', title: 'اغلاق طلب التوريد', type: 'link' },
				
				
			]
		},
		
	];

	

	MEGAMENUITEMS: Menu[] = [
		{
			title: 'Error Pages', type: 'sub', active: true, children: [
				{ path: 'javascript:void(0);', title: 'Error Page 400', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 401', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 403', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 404', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 500', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 503', type: 'extLink' },
			]
		},
		{
			title: 'Authentication', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'Login Simple', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Login BG Image', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Login BG Video', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Simple Register', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Register BG Image', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Register BG Video', type: 'extLink' }
			]
		},
		{
			title: 'Usefull Pages', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'Search Pages', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Unlock User', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Forgot Password', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Reset Password', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Maintenance', type: 'extLink' }
			]
		},
		{
			title: 'Email templates', type: 'sub', active: false, children: [
				{ path: 'http://admin.pixelstrap.com/cuba/theme/basic-template.html', title: 'Basic Email', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/email-header.html', title: 'Basic With Header', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/template-email.html', title: 'Ecomerce Template', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/template-email-2.html', title: 'Email Template 2', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/ecommerce-templates.html', title: 'Ecommerce Email', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/email-order-success.html', title: 'Order Success', type: 'extTabLink' }
			]
		},
		{
			title: 'Coming Soon', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'Coming Simple', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Coming BG Image', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Coming BG Video', type: 'extLink' }
			]
		},
	];

	LEVELMENUITEMS: Menu[] = [
		{
			path: 'javascript:void(0);', title: 'File Manager', icon: 'git-pull-request', type: 'extLink'
		},
		{
			title: 'Users', icon: 'users', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'All Users', icon: 'users', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'User Profile', icon: 'users', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Edit Profile', icon: 'users', type: 'extLink' },
			]
		},
		{ path: 'javascript:void(0);', title: 'Bookmarks', icon: 'heart', type: 'extLink' },
		{ path: 'javascript:void(0);', title: 'Calender', icon: 'calendar', type: 'extLink' },
		{ path: 'javascript:void(0);', title: 'Social App', icon: 'zap', type: 'extLink' }
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.Role == this.RoleItem?this.MENUITEMS_Admin:this.MENUITEMS && this.Role== this.Roledonation?this.DonationITEMS:this.MENUITEMS&&
		this.Role == this.RoledCollectorManager? this.CollectorManagerITEMS:this.MENUITEMS 
		);
	
	
	megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
	levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);

}
