export class ProjectMedia {
    title:string= ''
    order:number=null
    embedcode:string= ''
    mediaTypeId:number=null
    sectorLocationId:number=null
    url:string=''
}
export class UpdateProjectMedia {
    id:number=null
    title:string= ''
    order:number=null
    embedcode:string= ''
    mediaTypeId:number=null
    SectorLocationId:number=null
    url:string=''



}