<app-breadcrumb [title]="'إدارة العملاء'" [active_item]="'Form Wizard'"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding-bottom: 5px !important">
          <div *ngIf="update; else elseBlock1">
            <h5 class="pb-2">تعديل بيانات عميل موجود</h5>
            يمكنك تعديل بيانات عميل أو أكثر فى قائمة العملاء
          </div>
          <ng-template #elseBlock1>
            <h5 class="pb-2">إضافة عميل جديد</h5>
            يمكنك إضافة عميل أو أكثر فى قائمة العملاء
          </ng-template>
        </div>

        <form class="needs-validation" [formGroup]="InsertForm" (ngSubmit)="onSubmit()" novalidate
          [ngClass]="{ 'was-validated': validated }">
          <!-- image -->
          <div class="row">
            <div class="col-md-6">
              <div class="col-12 my-3 d-flex justify-content-center">
                <img style="width: 150px; height: 150px;" src="{{ imgURL }}"
                  onerror="this.src='../../../../../../assets/images/user/user.png'" />
              </div>

              <div class="col-12 ChangeProfilePhoto-block d-flex justify-content-center">
                <!--  -->
                <input type="file" formControlName="companyImg" #file accept="image/*" id="getFile"
                  style="display: none" (change)="preview(file.files)" required=""
             />

                <button type="button" onclick="document.getElementById('getFile').click()"
                  class="btn btn-primary d-block">
                  اختيار صورة البروفايل
                </button>
              </div>
            </div>
            <div class="col-md-6">
              <div class="col-12 my-3 d-flex justify-content-center">
                <img style="width: 150px; height: 150px;" src="{{ imgIconURL }}"
                  onerror="this.src='../../../../../../assets/images/user/logo.png'" />
              </div>
              <div class="col-12 ChangeProfilePhoto-block d-flex justify-content-center">
                <input type="file" formControlName="CompanyIcon" #file1 accept="image/*" id="getFile1"
                  style="display: none" (change)="previewIcon(file1.files)" required=""
               />

                <button type="button" onclick="document.getElementById('getFile1').click()"
                  class="btn btn-primary d-block">
                  اختيار لوجو العميل
                </button>
              </div>
            </div>
          </div>

          <div class="card-body container row col-12">
            <div class="row">
              <!-- name -->
              <div class="col-md-6">
                <div class="form-group">
                  <label for="name"><span class="validation-required">*</span> أسم العميل </label>
                  <input class="form-control" id="name" type="text" [(ngModel)]="companyInfo.name"
                    placeholder="ادخل اسم العميل" formControlName="name" required="" 
                    [tooltip]="fc.name.errors?.required ? ' مطلوب' : null" required
                    [isDisabled]="!((fc.name.errors?.required && fc.name.touched))"
                    containerClass="" [class.is-valid]="fc.name.valid && (fc.name.touched)"
                    [class.is-invalid]="fc.name.invalid && (fc.name.touched)" 
                    />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="name"><span class="validation-required">*</span> الاسم المختصر </label>
                  <input class="form-control" id="name" type="text" [(ngModel)]="companyInfo.shortName"
                    placeholder="ادخل الاسم المختصر" formControlName="ShortName" required=""
                    [tooltip]="fc.ShortName.errors?.required ? ' مطلوب' : null" required
                    [isDisabled]="!((fc.ShortName.errors?.required && fc.ShortName.touched))"
                    containerClass="" [class.is-valid]="fc.ShortName.valid && (fc.ShortName.touched)"
                    [class.is-invalid]="fc.ShortName.invalid && (fc.ShortName.touched)" />
                </div>
              </div>
              <!-- username -->
              <div class="col-md-6">
                <div class="form-group">
                  <label> <span class="validation-required">*</span> أسم المستخدم </label>
                  <input class="form-control" type="text" placeholder="" required="" formControlName="username"
                    [(ngModel)]="companyInfo.userName" 
                    [tooltip]="fc.username.errors?.required ? ' مطلوب' : null" required
                    [isDisabled]="!((fc.username.errors?.required && fc.username.touched))"
                    containerClass="" [class.is-valid]="fc.username.valid && (fc.username.touched)"
                    [class.is-invalid]="fc.username.invalid && (fc.username.touched)"/>
                </div>
              </div>

              <!-- Emai -->
              <div class="col-md-6">
                <div class="form-group">
                  <label><span class="validation-required">*</span>البريد الاكتروني </label>
                  <input class="form-control" type="email" placeholder="" formControlName="email"
                    [(ngModel)]="companyInfo.email" required=""  
                    [tooltip]="fc.email.errors?.required ? ' مطلوب' : null" required
                    [isDisabled]="!((fc.email.errors?.required && fc.email.touched))"
                    containerClass="" [class.is-valid]="fc.email.valid && (fc.email.touched)"
                    [class.is-invalid]="fc.email.invalid && (fc.email.touched)"/>
                </div>
              </div>

              <!-- password -->
              <div class="col-md-6">
                <div class="form-group">
                  <div *ngIf="!update">
                    <!-- <label> <span class="validation-required">*</span> كلمة المرور </label> -->
                    <label for="Password"> كلمة المرور</label>

                    <input class="form-control" type="password" formControlName="PasswordHash"
                      [(ngModel)]="companyInfo.PasswordHash" placeholder="" required=""
                      [tooltip]="fc.PasswordHash.errors?.required ? ' مطلوب' : null" required
                      [isDisabled]="!((fc.PasswordHash.errors?.required && fc.PasswordHash.touched))"
                      containerClass="" [class.is-valid]="fc.PasswordHash.valid && (fc.PasswordHash.touched)"
                      [class.is-invalid]="fc.PasswordHash.invalid && (fc.PasswordHash.touched)" />
                  </div>

                  <!-- <ng-container class="invalid" style="color: red" *ngIf="
                      InsertcompanyInfo.get('password').hasError('required') &&
                      InsertcompanyInfo.get('password').touched">
                    <span style="color: red;">
                      كلمة المرور مطلوب
                    </span>
                  </ng-container> -->

                </div>
              </div>

              <!-- mobile -->
              <div class="col-md-6">
                <div class="form-group">
                  <label><span class="validation-required">*</span> رقم الموبايل </label>
                  <input onlyNumbers class="form-control" type="text" placeholder="ادخل رقم الموبايل" formControlName="mobile"
                    [(ngModel)]="companyInfo.mobile"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    maxlength="11" minlength="11" required=""
                    [tooltip]="fc.mobile.errors?.required ? ' مطلوب' : null" required
                    [isDisabled]="!((fc.mobile.errors?.required && fc.mobile.touched))"
                    containerClass="" [class.is-valid]="fc.mobile.valid && (fc.mobile.touched)"
                    [class.is-invalid]="fc.mobile.invalid && (fc.mobile.touched)"
                    />
                </div>
              </div>

              <!-- GovernorateId -->
              <div class="col-md-6">
                <div class="form-group">
                  <label><span class="validation-required">*</span> المحافظة</label>
                  <ng-select class="js-example-basic-single" formControlName="GovernoratId"
                    [(ngModel)]="companyInfo.governoratId" aria-label="Default select example" required=""
                    (change)="SelectedGovernorate($event.id)" [items]="Governorate_List" bindLabel="title"
                    bindValue="id"
                    [tooltip]="fc.GovernoratId.errors?.required ? ' مطلوب' : null" required
                    [isDisabled]="!((fc.GovernoratId.errors?.required && fc.GovernoratId.touched))"
                    containerClass="" [class.is-valid]="fc.GovernoratId.valid && (fc.GovernoratId.touched)"
                    [class.is-invalid]="fc.GovernoratId.invalid && (fc.GovernoratId.touched)"
                    [style.border]="fc.GovernoratId.touched ? (fc.GovernoratId.invalid ? '1px solid #dc3545 !important' : (fc.GovernoratId.valid && fc.GovernoratId.touched ? '1px solid #28a745' : 'none')) : 'none'">
                  </ng-select>
                </div>
              </div>

              <!-- cityId -->
              <div class="col-md-6">
                <div class="form-group">
                  <label><span class="validation-required">*</span> المدينة</label>
                  <ng-select class="js-example-basic-single" formControlName="cityId" [(ngModel)]="companyInfo.cityId"
                    aria-label="Default select example" required="" [items]="cities_List" bindLabel="title"
                    bindValue="id"
                    [tooltip]="fc.cityId.errors?.required ? ' مطلوب' : null" required
                    [isDisabled]="!((fc.cityId.errors?.required && fc.cityId.touched))"
                    containerClass="" [class.is-valid]="fc.cityId.valid && (fc.cityId.touched)"
                    [class.is-invalid]="fc.cityId.invalid && (fc.cityId.touched)"
                    [style.border]="fc.cityId.touched ? (fc.cityId.invalid ? '1px solid #dc3545 !important' : (fc.cityId.valid && fc.cityId.touched ? '1px solid #28a745' : 'none')) : 'none'">
                    >
                  </ng-select>
                </div>
              </div>

              <!-- clientTypeId -->
              <div class="col-md-6">
                <div class="form-group">
                  <label><span class="validation-required">*</span> نوع العميل</label>
                  <ng-select class="js-example-basic-single" formControlName="companyTypeId"
                    [(ngModel)]="companyInfo.companyTypeId" aria-label="Default select example"
                    [items]="Client_Type_List" bindLabel="title" bindValue="id" required=""
                    [tooltip]="fc.companyTypeId.errors?.required ? ' مطلوب' : null" required
                    [isDisabled]="!((fc.companyTypeId.errors?.required && fc.companyTypeId.touched))"
                    containerClass="" [class.is-valid]="fc.companyTypeId.valid && (fc.companyTypeId.touched)"
                    [class.is-invalid]="fc.companyTypeId.invalid && (fc.companyTypeId.touched)"
                    [style.border]="fc.companyTypeId.touched ? (fc.companyTypeId.invalid ? '1px solid #dc3545 !important' : (fc.companyTypeId.valid && fc.companyTypeId.touched ? '1px solid #28a745' : 'none')) : 'none'">
                  </ng-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label><span class="validation-required">*</span> مرسل الرسايل </label>
                  <input class="form-control" type="text" placeholder="مرسل الرسايل" formControlName="smsSender"
                    [(ngModel)]="companyInfo.smsSender"
                   required="" 
                   [tooltip]="fc.smsSender.errors?.required ? ' مطلوب' : null" required
                   [isDisabled]="!((fc.smsSender.errors?.required && fc.smsSender.touched))"
                   containerClass="" [class.is-valid]="fc.smsSender.valid && (fc.smsSender.touched)"
                   [class.is-invalid]="fc.smsSender.invalid && (fc.smsSender.touched)"/>
                </div>
              </div>
              <!-- address -->
              <!-- <div class="col-md-6">
                <textarea formControlName="address" class="form-control textForArabic" placeholder="  "
                  style="height: 120px">
                </textarea>
              </div> -->
              <div class="col-md-6 row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label><span class="validation-required">*</span> اللون </label>
                    <!-- <input type="color" formControlName="Color" [(colorPicker)]="selectedColor" [style.background]="selectedColor" /> -->
                    <!-- <input [(colorPicker)]="selectedColor" [style.background]="selectedColor"/> -->
                    <input class="form-control"     id="name" type="text" [(ngModel)]="companyInfo.color"
                    placeholder="ادخل اللون" formControlName="color" required=""
                    [tooltip]="fc.color.errors?.required ? ' مطلوب' : null" required
                    [isDisabled]="!((fc.color.errors?.required && fc.color.touched))"
                    containerClass="" [class.is-valid]="fc.color.valid && (fc.color.touched)"
                    [class.is-invalid]="fc.color.invalid && (fc.color.touched)"/>
                  </div>
                </div>
                <div >
                  <div style="width: 220px;">
                    <color-sketch [(color)]="selectedColor" vertical  (onChangeComplete)="changeComplete($event)" ></color-sketch>
                  </div>
                </div>
                
               

                
              </div>
              <div class="col-12 mb-3">
                <label for="address"> <span class="validation-required">*</span> العنوان</label>
                <textarea formControlName="address" [(ngModel)]="companyInfo.address" id="address"
                  class="form-control textForArabic" placeholder="" required="" style="height: 120px"
                  [tooltip]="fc.address.errors?.required ? ' مطلوب' : null" required
                    [isDisabled]="!((fc.address.errors?.required && fc.address.touched))"
                    containerClass="" [class.is-valid]="fc.address.valid && (fc.address.touched)"
                    [class.is-invalid]="fc.address.invalid && (fc.address.touched)"
                  ></textarea>
              </div>
            </div>

            <!-- Actions -->
            <div class="col-12">
              <div *ngIf="update; else elseBlock">
                <button class="btn btn-primary pull-right" type="submit">تعديل</button>
              </div>

              <ng-template #elseBlock>
                <button class="btn btn-primary pull-right" type="submit">إضافة</button>
              </ng-template>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>