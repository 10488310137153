import { Statsdata } from './../Models/statsdata';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { GenericResponse } from '../Models/GenericResponse';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';

@Injectable({
  providedIn: 'root'
})
export class StattsReportsService {

  constructor(private http:HttpClient) { }

  getstate(data:Statsdata): Observable<any>{
    return this.http.get<any>(`${environment.Server_URL}/Stats/Get?creationDateFrom=${data.creationDateFrom}&creationDateTo=${data.creationDateTo}`);
  }
  
  RiskStats(data:any): Observable<any> {
    return this.http.post<any>(`${environment.Server_URL}/Stats/RiskStats` , data);
    }

    CallsStats(data?:any): Observable<any> {
      return this.http.post<any>(`${environment.Server_URL}/Stats/CallsStats`,data);
      }


    GetDonationStatus(data:any): Observable<GenericResponseSingle<any>> {
      return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/Stats/DonationStatus`,data);
    }
}
