<app-breadcrumb [title]="'اضافه دفتر جديد'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'">
</app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header px-0 " style="padding-bottom: 5px;">
                    <form [formGroup]="insertForm" (ngSubmit)="Submit()">

                    <div class="row m-0 mb-2">
                      <div class="col-3 mb-3" *ngIf="!update">
                            
                            <label for="validationCustom02">اسم الشركة</label>
                    
                            <ng-select
                            class="js-example-basic-single col-sm-12 job-select2 form-group"
                              required=""
                              formControlName="companyId"
                              aria-label="Default select example"
                              (change)="SelectCompany($event.id)"
                              [items]="Companies"
                              bindLabel="name"
                              bindValue="id"
                               >
                            
                            </ng-select>
                          </div>
                          <div class="col-3 mb-3" *ngIf="update">
                            
                            <label for="validationCustom02">اسم الشركة</label>
                            <br>
                            <label for="validationCustom02">{{Receipt.companyName}}</label>
                          </div>
                        <div class="col-md-3">
                          <label for=""> رقم الدفتر
                          </label>
                            <div class="form-group">
                                
                               <input type="text"  class="form-control" formControlName="bookNumber" 
                               [tooltip]="fc.bookNumber.errors?.required ? ' الاسم مطلوب' : null" required
                               [isDisabled]="!((fc.bookNumber.errors?.required && fc.bookNumber.touched))"
                               containerClass="" [class.is-valid]="fc.bookNumber.valid && (fc.bookNumber.touched)"
                               [class.is-invalid]="fc.bookNumber.invalid && (fc.bookNumber.touched)"
                               >
                            </div>
                        </div>
                        <div class="col-md-3">
                          <label for="">يبدأ ب</label>
                            <div class="form-group">
                             
                               <input type="text"  class="form-control"  formControlName="prefix" 
                               [tooltip]="fc.prefix.errors?.required ? 'يبدأ ب مطلوب' : null" required
                               [isDisabled]="!((fc.prefix.errors?.required && fc.prefix.touched))"
                               containerClass="" [class.is-valid]="fc.prefix.valid && (fc.prefix.touched)"
                               [class.is-invalid]="fc.prefix.invalid && (fc.prefix.touched)"
                               >
                            </div>
                        </div>

                        <div class="col-12 col-md-3">
                            <label for=""> رقم ايصال من</label>
                            <div class="form-group">
                                <input type="number" min="0"  class="form-control" formControlName="receiptFrom" 
                                [tooltip]="fc.receiptFrom.errors?.required ? ' الرقم من مطلوب' : null" required
                                [isDisabled]="!((fc.receiptFrom.errors?.required && fc.receiptFrom.touched))"
                                containerClass="" [class.is-valid]="fc.receiptFrom.valid && (fc.receiptFrom.touched)"
                                [class.is-invalid]="fc.receiptFrom.invalid && (fc.receiptFrom.touched)">
                            </div>
                        </div>
                        <div class="col-12 col-md-3">
                            <label for=""> رقم ايصال الي</label>
                            <div class="form-group">
                                <input type="number" min="0"  class="form-control" formControlName="receiptTo" 
                                [tooltip]="fc.receiptTo.errors?.required ? ' الرقم الى مطلوب' : null" required
                                [isDisabled]="!((fc.receiptTo.errors?.required && fc.receiptTo.touched))"
                                containerClass="" [class.is-valid]="fc.receiptTo.valid && (fc.receiptTo.touched)"
                                [class.is-invalid]="fc.receiptTo.invalid && (fc.receiptTo.touched)">
                            </div>
                        </div>
                        <div class="col-3 mb-3">
                          <label for="validationCustom02">نوع التبرع</label>
                    
                          <ng-select
                          class="js-example-basic-single col-sm-12 job-select2 form-group"
                            required=""
                            [items]="DonationMTypes"
                            bindLabel="title" 
                            bindValue="id"
                            formControlName="donationMainTypeId"
                          >
                          </ng-select>
                          <!-- <label *ngIf="fc.donationMainTypeId.invalid">نوع التبرع مطلوب</label> -->
                    
                       
                        </div>
                        <div class="col-12 col-md-12">
                            <div *ngIf="update; else elseBlock">
                                <button class="btn btn-primary pull-right" type="submit" >
                                  تعديل
                                </button>
                              </div>
                  
                              <ng-template #elseBlock>
                                <button class="btn btn-primary pull-right" type="submit">
                                  إضافة
                                </button>
                              </ng-template>
                </div>
                </div>
                    </form>
    </div>
</div>
        </div>
    </div>
</div>