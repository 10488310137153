<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" > اضافة الانجاز</h4>
      <button type="button" class="close"  (click)="modalclose()"  data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
               <div class="row"  style=" padding: 30px;">
                 <div  *ngFor="let item of AccomplishmentImage let i =index" style="width: 2%;" class="col-4">
                  <!-- <img class="m-3" src="../../../../../assets/images/User.jpg"> -->
                  <div class="image-area text-center bg-white" style="margin: 8px;"  >
                    <img   *ngIf="item.mediaTypeId==7"  [src]="item.url"  height="200" width="200"  (click)="OpenImage(item.url)">
                    <video *ngIf="item.mediaTypeId==11"  height="200" width="200" controls>
                      <source [src]="item.url" >
                      Your browser does not support the video tag.
                    </video>
                    <a  class="remove-image" appActionDelete (click)="deleteAccomplishmentMedia(item.id)" style="display: inline;">&#215;</a>
                    <br>
                    <p style="font-size: medium;">
                      {{item.title}}</p>

                  </div>
                 </div>
               </div>
  
</div>
        </div>
        </div>
     
    </div>
 
  </ng-template>
  <app-breadcrumb [title]="'قائمة الانجازات للعميل'" ></app-breadcrumb>

  <div class="container-fluid top-padding">

  </div>
  
<div class="container-fluid">
 
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header px-0" style="padding-bottom: 5px;">
         
          <div class="table-responsive ">
            <table class="table  table-striped" style="width: 100%;">
              <thead>
                <tr class="bg-dark ">
                  <th scope="col" class="font-style-header" >#</th>
                  <th scope="col" class="font-style-header" >  نوع الميديا </th>
                  <th scope="col" class="font-style-header" >  الاسم </th>
                  <th scope="col" class="font-style-header" >  الترتيب </th>
                  <th scope="col" class="font-style-header" >  الرابط </th>
                  <th scope="col" class="font-style-header" >  تاريخ الانشاء </th>
                  <!-- <th scope="col" class="font-style-header" >  الكود المسجل </th> -->
                  <!-- <th scope="col" class="font-style-header" > </th> -->
                </tr>
              </thead>
              <tbody >
                <ng-container *ngFor="let item of accomplishmentMedia; let i = index;">
                <tr *ngFor="let media of item.media; let i2 = index;" >
                  
                  <td scope="row" class="font-style text-center">{{i2+1}}</td>
                  <td  class="font-style text-center">{{media.mediaTypeName}}</td>
                  <td  class="font-style text-center">{{media.title}}</td>
                  <td  class="font-style text-center">{{media.order}}</td>
                  <td  class="font-style text-center" style="width: 20%; text-align: center;">
                    <img class="mt-2 mb-2" *ngIf="item.mediaTypeId==7" src="{{media.url}}"  width="150" height="150"  (click)="OpenImage(media.url)">
                    <video *ngIf="item.mediaTypeId==11" width="200" height="200" controls>
                      <source class="mt-2 mb-2" src="{{media.url}}" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>
                    <br>
                    <button class="btn btn-primary mb-2"(click)="copyUrl(media.url)">نسخ الرابط</button>
                  </td>
                  <td  class="font-style">{{media.creationDate | date:"dd/MM/yyyy"}}</td>      
                  <!-- <td class="font-style" style="text-align: end; width: 8%;" >
                                          <button  href=""style="color: rgba(35, 118, 241, 0.667);padding: 7px; background-color: transparent;" (click)="open(content,item);">
                          <i class="fa fa-upload"></i>
                        </button>
                    </td> -->
                </tr>
              </ng-container>

              </tbody>
            </table>
            <br>
            <!-- <div class="d-flex justify-content-center">
                <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
                  firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
                  (pageChanged)="pageChanged($event)">
                </pagination>
              </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>