import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { PermissionsApiService } from "../API-Service/Permissions-api.service";
import Swal from "sweetalert2";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CreateSideBarMenuService {
  public New: boolean;
  public menuRolus: any[] = [];
  Role: any;
  err: boolean;
  MenuByRoleId: any[] = [];
  MenuArry: any[] = [];
  children: any[] = [];
  children2: any[] = [];
  defaultUrl: any = "/content/admin/DefaultComponent";
  constructor(private PermissionsApiService: PermissionsApiService) {}
 
  async CreateSideBarMenu(): Promise<string> {
    debugger;
    this.Role = localStorage.getItem("RiskRole");
    this.New = true;
    let isReturn = false;
    this.menuRolus = JSON.parse(localStorage.getItem("RiskmenuRolus") || "[]");
   debugger
    if (this.menuRolus.length <= 0) {
      try {
        this.MenuArry=[];
        this.children=[];
        this.children2=[];
        this.MenuByRoleId=[];
        const res: any =
          await this.PermissionsApiService.GetByRoleId().toPromise();
      
        if (res.messageCode == 200) {
          this.err = false;
          if (res.data.defaultMenuUrl === null) {
            res.data.defaultMenuUrl = "/content/admin/DefaultComponent";
          }
          this.defaultUrl = res.data.defaultMenuUrl;
          this.MenuByRoleId = res.data.menus;
          this.MenuByRoleId.forEach((element) => {
            this.children = [];
            element.childMenu.forEach((child) => {
              const action4 = child.actions.filter((x) => x.actionId == 4)[0];
              this.children.push({
                MenuAction: child.actions,
                actionId: action4 != undefined ? action4.actionId : null,
                path: child.url,
                title: child.name,
                type: "link",
              });
              this.children2.push({
                MenuAction: child.actions,
                actionId: action4 != undefined ? action4.actionId : null,
                path: child.url,
                title: child.name,
                type: "link",
              });
            });
            this.MenuArry.push({
              Veiw: element.Perrmission,
              Parentid: element.parentId,
              title: element.name,
              path: element.url,
              children: this.children,
              icon: element.icon,
            });
          });
          localStorage.setItem("MenuByRoleId", JSON.stringify(this.children2));
          this.menuRolus.push({
            headTitle1: "لوحة التحكم ",
            headTitle2: "إدارة المحتويات الموجودة",
          });
          this.MenuArry.forEach((element) => {
            this.menuRolus.push({
              title: element.title,
              icon: element.icon,
              type: "sub",
              badgeType: "success",
              Veiw: element.Veiw,
              children: element.children,
            });
          });
          localStorage.setItem("RiskmenuRolus", JSON.stringify(this.menuRolus));
          return this.defaultUrl;
        } else {
          this.err = true;
          return this.defaultUrl;
        }
      } catch (error) {
        this.err = true;
        Swal.fire({ icon: "error", title: "خطأ", text: error.error.message });
        return this.defaultUrl;
      }
    } else {
      return this.defaultUrl;
    }
  }
}
