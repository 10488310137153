import { Component, OnInit } from '@angular/core';
import { DonationsStatusReport } from '../../../../shared//Models/DonationsStatusReport';
import { Router } from '@angular/router';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-donations-status-report',
  templateUrl: './donations-status-report.component.html',
  styleUrls: ['./donations-status-report.component.css']
})
export class DonationsStatusReportComponent implements OnInit {
  ReportsInfo = new DonationsStatusReport()
  currentUser: any;
  Companies: any;
  constructor(private CompanyDataService:CompanyDataService, public datepipe:DatePipe,private router: Router) { 
    this.currentUser=JSON.parse(localStorage.getItem("RiskCurrentUser"))
    if(this.currentUser.roleId==10){
      this.ReportsInfo.companyId=this.currentUser.id
      
    }
      this.ReportsInfo.reportCreatedBy=this.currentUser.name;
      const date = new Date();
      this.ReportsInfo.dateFrom=this.datepipe.transform(date, 'yyyy-MM-dd');
      this.ReportsInfo.dateTo=this.datepipe.transform(date,'yyyy-MM-dd');

  }
  Viewreports(){
    if(this.ReportsInfo.companyId!=null&&this.ReportsInfo.dateFrom &&this.ReportsInfo.dateTo){
    localStorage["ReportName"]="DonationsStatusReport";
    localStorage.setItem('ReportFilter',JSON.stringify(this.ReportsInfo));
  
    this.router.navigate([]).then((result) => {
      window.open("/content/admin/call-start-end-report", '_blank');
    });

  }
  else{
    if(!this.ReportsInfo.companyId){
      Swal.fire({
        icon:'error',
        title:'خطأ',
        text:'يجب اختيار الشركة'
  
      })
    }
  else   if (!this.ReportsInfo.dateFrom){
      Swal.fire({
        icon:'error',
        title:'خطأ',
        text:' يجب اختيار بدايه تاريخ حالات التبرعات '
      })
    }
    else  if (!this.ReportsInfo.dateTo ){
      Swal.fire({
        icon:'error',
        title:'خطأ',
        text:' يجب اختيار نهايه تاريخ حالات التبرعات '
  
      })
    }
}

   
  }
  SelectCompany(CompanyId){
    this.ReportsInfo.companyId=parseInt(CompanyId);
  }
  GetCompanies(){
    this.CompanyDataService.GetCompanies().subscribe(res=>{
      this.Companies=res.data
    })
  }
  ngOnInit(): void {
    this.GetCompanies()
  }

}
