<app-breadcrumb [title]="'قائمة الميديا'" ></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header px-0" style="padding-bottom: 5px;">
              <div class="row m-0" >
                  <div class="col-12 col-md-9">
                    <h5 class="py-2">قائمة الميديا</h5>
                    <span  >  إعدادت قائمة الميديا الموجودة بالنظام ( عرض - تعديل - حذف ) </span>
                  </div>
                  <div class="col-12 col-md-3 d-flex justify-content-end align-items-center p-0">
                      <div appActionNew class="addnew-btn"  (click)="AddNew()">
                           
                            <i class="fa fa-plus "></i> <span class="mt-1">جديد</span>
                            <span class="tooltiptext">  إضافة ميديا جديدة</span>
                      </div>
        
              </div>

              <!-- <div class="row m-0 mb-2"> -->
                  <div class="col-md-6  mt-5 mb-2">
                    <h5 for=""> مكان المشروع : {{SectorLocationTitle}}
                    </h5>
                   
      
                  </div>
    
   
             
        <!-- </div> -->
          
          </div>
          <div class="table-responsive ">
            <table class="table  table-striped" style="width: 100%;">
              <thead>
                <tr class="bg-dark ">
                  <th scope="col" class="font-style-header" >#</th>
                  <th scope="col" class="font-style-header" >  الاسم </th>
                  <th scope="col" class="font-style-header" >  نوع الميديا </th>
                  <th scope="col" class="font-style-header" >  الترتيب </th>
                  <th scope="col" class="font-style-header" >  التاريخ </th>
                  <th scope="col" class="font-style-header" >  الرابط </th>
                  <th scope="col" appActionDelete class="font-style-header" > </th>
                </tr>
              </thead>
              <tbody >
                  <ng-container *ngFor="let item of ProjectMedia; let i = index;" >
                <tr style="height: 40px;">
                  <td scope="row" class="font-style">{{i+1}}</td>
                  <td  class="font-style">{{item.title}}</td>
                  <td  class="font-style">{{item.mediaTypeName}}</td>
                  <td  class="font-style">{{item.order}}</td>
                  <td  class="font-style">{{item.creationDate | date:"dd/MM/yyyy"}}</td>
                  <td *ngIf="item.mediaTypeName!='Picture'" class="font-style"><a href="{{item.url}}" target="_blank">فتح الرابط</a> </td>
                  <td *ngIf="item.mediaTypeName=='Picture'" class="font-style"> <img  src="{{serverImageUrl}}{{item.url}}"  height="100" width="100"  (click)="OpenImage(item.url)"></td>
                  <!-- <td  class="font-style touchspin1 text-center">{{item.embedcode}}</td> -->
                  <td appActionDelete class="font-style" style="text-align: end; width: 8%;" >
                                          <button  href=""style="color: red;padding: 7px; background-color: transparent;"(click)="Delete(item.id)">
                          <i class="fa fa-trash"></i>
                        </button>

                    </td>
                </tr>
            </ng-container>
              </tbody>
            </table>
            <br>
            <!-- <div class="d-flex justify-content-center">
                <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
                  firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
                  (pageChanged)="pageChanged($event)">
                </pagination>
              </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>