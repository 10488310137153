import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StatisticsService } from 'src/app/shared/API-Service/statistics.service';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-follow-up-call-statsdetails-admin',
  templateUrl: './follow-up-call-statsdetails-admin.component.html',
  styleUrls: ['./follow-up-call-statsdetails-admin.component.css']
})
export class FollowUpCallStatsdetailsAdminComponent extends BaseComponent implements OnInit  {

  imgUrlServer=environment.Server_URL_Image+'/'

  details: boolean;
  AllCollectorTodayDonations: any;
  CollectorDonations: any;
  TodayFollowUpCalls: any;
  param: any;
  constructor(private StatisticsService :StatisticsService,private router: Router) { 
   super()
    this.details=false;
       this.param = this.router.getCurrentNavigation().extras.state;
    
  }


  ngOnInit(): void {
    // if(this.param=="todayFollowUpCallsCount"){
    //   this.GetTodayFollowUpAllCalls();
    // }
    // else
     if(this.param=="doneCount"){
      this.GetdoneCountFollowUpCalls()
    }
    else if(this.param=="notDoneCount"){
this.GetnotDoneCountFollowUpCalls()
    }
  
  }
  GetTodayFollowUpAllCalls(){
    this.StatisticsService.GetTodayFollowUpAllCallsAdmin(this.pager).subscribe((res:any)=>{
      this.TodayFollowUpCalls = res.data.items
      this.totalCount = res.data.totalCount;
    })
  }
  GetdoneCountFollowUpCalls(){
    this.StatisticsService.GetTodayFollowUpDoneCallsAdmin(this.pager).subscribe((res:any)=>{
      this.TodayFollowUpCalls = res.data.items
      this.totalCount = res.data.totalCount;
    })
  }
  GetnotDoneCountFollowUpCalls(){
    this.StatisticsService.GetTodayFollowUpNotDoneCallsAdmin(this.pager).subscribe((res:any)=>{
      this.TodayFollowUpCalls = res.data.items
      this.totalCount = res.data.totalCount;
    })
  }
  
  

   pageChanged(event:any){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount; 
    if(this.param=="todayFollowUpCallsCount"){
      this.GetTodayFollowUpAllCalls();
    }
    else if(this.param=="doneCount"){
      this.GetdoneCountFollowUpCalls()
    }
    else if(this.param=="notDoneCount"){
this.GetnotDoneCountFollowUpCalls()
    }
  }

}
