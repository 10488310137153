import { Customer } from '../Models/Customer';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
import { environment } from '../../../environments/environment.prod';


@Injectable({
  providedIn: 'root'
})

export class CollectorApiService {


    //#endregion
    
    //#region  constructor
    constructor(private http:HttpClient) { }
    //#endregion
  
      //#region Options
      httpOptionsWithTocken = { headers: new HttpHeaders({ 'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhaG1lZGFiZHVsbGFoMjUwIiwianRpIjoiZDIwZjU0MGUtMjhiNy00YmNjLWE4ZDgtNzkxNzA2YzJmZDRhIiwiZW1haWwiOiJhaG1lZGFiZHVsbGFoQHlhaG9vLmNvbSIsInVpZCI6IjBiMzg5N2FiLTQ2ZmMtNGM0Yy04MTYyLTRiNDExZTY4OWE1NCIsInJvbGVzIjoiVVNFUiIsImV4cCI6MTYzODM2OTM3NSwiaXNzIjoiU2VjdXJlQXBpIiwiYXVkIjoiU2VjdXJlQXBpVXNlciJ9.55LorE6Fclj3buy1Qw8wZ6CEe_ifW5jxwHe25wHRWsQ', 'Accept': ' */*' }) };
      //#endregion
  
      GetCollectorByCity(cityid:string): Observable<GenericResponseSingle<any>> {
        return this.http.get<GenericResponseSingle<any>>(`${environment.Server_URL}/CollectorCity/GetAllCollectorsInCity?cityId=${cityid}`);
      }
      GetCollectorDonationsCounts(): Observable<GenericResponseSingle<any>> {
                return this.http.get<GenericResponseSingle<any>>(`${environment.Server_URL}/DonationCollector/GetCollectorDonationsCounts`);
      }

      GetCollectorTodayDonations(data): Observable<GenericResponseSingle<any>> {
        return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/Donation/GetCollectorTodayDonations`,data);
}
}
