<div class="media profile-media"  (click)="toggleUser()">
  <!-- <img class="b-r-10" src="assets/images/dashboard/profile.jpg" alt=""> -->
  <img class="b-r-10" src="assets/images/dashboard/userprofile.png" width="37" height="37">

  <div class="media-body pt-2" >
    <span> مرحبا يا {{EmployeeName}} </span>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div"  [class.active]="openUser">
  <li><a><app-feather-icons [icon]="'user'"></app-feather-icons><span>حسابى </span></a></li>
  <!-- <li><a><app-feather-icons [icon]="'mail'" ></app-feather-icons><span>البريد</span></a></li>
  <li><a><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>مهماتى</span></a></li>
  <li><a><app-feather-icons [icon]="'settings'"></app-feather-icons><span>الاعدادات</span></a></li> -->
  <li (click)="ogin()"><a><app-feather-icons [icon]="'log-in'"></app-feather-icons><span> تغير كلمه المرور</span></a></li>
  <li (click)="logout()"><a><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>سجل خروج</span></a></li>
</ul> 