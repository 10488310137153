<app-breadcrumb [title]="'تبرعات المحصلين'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'">
</app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
            <!-- <form action="" [formGroup]="createReports"> -->
                <div class="card-header px-0 " style="padding-bottom: 5px;">
                    <div class="row m-0 mb-2">
                      <div class="col-12 col-md-4">
                        <label for="validationCustom02"><span class="validation-required">*</span>  اسم الشركة</label>
                        <br>
                        <label *ngIf="currentUser.roleId==10">{{currentUser.name}}</label>
                  <ng-select *ngIf="currentUser.roleId!=10"
                  class="js-example-basic-single col-sm-12 job-select2 form-group"
                    required=""
                    [(ngModel)]="donationRequest.companyId"
                    aria-label="Default select example"
                    (change)="SelectCompany($event.id)"
                    [items]="Companies"
                    bindLabel="name"
                    bindValue="id"
                     >
                  
                  </ng-select>
                      </div>

                        <div class="col-md-4 col-sm-6">
                          <label for="">اسم المحصل</label>
                            <ng-select
                            class="js-example-basic-single"
                            aria-label="Default select example"
                            (change)="getDonationRequests($event.id)"
                            [(ngModel)]="donationRequest.collectorId"
                            [items]="Getcollectors"
                            bindLabel="name"
                            bindValue="id"
                          >
                          </ng-select>
                        </div>
                        <div class="col-md-4">
                          <label for=""> بدايه تاريخ التبرعات المنشأة
                          </label>
                            <div class="form-group">
                                
                               <input type="date"  class="form-control" [(ngModel)]="donationRequest.creationDateFrom">
                            </div>
                        </div>
                        <div class="col-md-4">
                          <label for="">نهايه تاريخ التبرعات المنشأة</label>
                            <div class="form-group">
                             
                               <input type="date"  class="form-control" [(ngModel)]="donationRequest.creationDateTo">
                            </div>
                        </div>
                        <div class="col-md-4">
                          <label for=""> بدايه تاريخ التبرعات المحصلة
                          </label>
                            <div class="form-group">
                                
                               <input type="date"  class="form-control" [(ngModel)]="donationRequest.collectionDateFrom">
                            </div>
                        </div>
                        <div class="col-md-4">
                          <label for="">نهايه تاريخ التبرعات المحصلة</label>
                            <div class="form-group">
                             
                               <input type="date"  class="form-control" [(ngModel)]="donationRequest.collectionDateTo">
                            </div>
                        </div>
                      
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>أختر المحافظة </label>
                                   
                                <ng-select
                                class="js-example-basic-single"
                                  aria-label="Default select example"
                                 
                                  [(ngModel)]="donationRequest.governoratId"
                                  (change)="SelectedGovernorate($event?.id)"
                                  [items]="Governorate_List"
                                  bindLabel="title"
                                  bindValue="id"
                                >
                                </ng-select>
                              </div>
                            
                                
                           
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>أختر مدينه </label>
                                   
                                <ng-select
                                class="js-example-basic-single"
                                  aria-label="Default select example"
                                  
                                  [(ngModel)]="donationRequest.cityId"
                                  (change)="SelectedCity($event.id)"
                                  [items]="City_list"
                                  bindLabel="title"
                                  bindValue="id"
                                >
                                </ng-select>
                              </div>
                            
                                
                           
                        </div>

                        <div class="col-12 col-md-4">
                          <label for="">حالة التبرع</label>
                            <ng-select
                            class="js-example-basic-single"
                            aria-label="Default select example"
                            [(ngModel)]="donationRequest.donationStatus"
                            (change)="getstatus($event.id)"
                            [items]="GetLookupsGetDonation"
                            bindLabel="title"
                            bindValue="id"
                            
                          >
                          </ng-select>
                        </div>
                        <div class="col-12 col-md-4">
                          <label for="">فئه التبرع</label>
                            <ng-select
                            class="js-example-basic-single"
                            aria-label="Default select example"
                            [(ngModel)]="donationRequest.donationTypeId"
                             (change)="getDonationType($event.id)"
                             [items]="DonationType"
                             bindLabel="title"
                             bindValue="id"
                          >
                          </ng-select>
                        </div>
                    
                        <div class="col-12 col-md-4">
                          <label for="">النشاط </label>
                            <ng-select
                            class="js-example-basic-single"
                            aria-label="Default select example"
                            (change)="GetSectorGetById($event?.id)" 
                            [(ngModel)]="donationRequest.sectorId"
                            [items]="SectorGet"
                            bindLabel="title"
                            bindValue="id"
                            >
                          </ng-select>
                        </div>
                        <div class="col-12 col-md-4">
                          <label for=""> المشروعات</label>
                            <ng-select
                            class="js-example-basic-single"
                            aria-label="Default select example"
                           [(ngModel)]="donationRequest.sectorTypeId"
                            (change)="GetSectorTypeGetById($event?.id)" 
                            [items]="getType"
                            bindLabel="title"
                            bindValue="id"
                            >
                          </ng-select>
                        </div>
                        <div class="col-12 col-md-4">
                          <label for=""> اماكن المشروعات</label>
                            <ng-select
                            class="js-example-basic-single"
                            aria-label="Default select example"
                            [(ngModel)]="donationRequest.sectorLocationId"
                            [items]="SectorLocation"
                            bindLabel="title"
                            bindValue="id"
                           (change)="GetSectorLocationGetById($event.id)"
                            >
                          </ng-select>
                        </div>
                        

                        <div class="col-12 col-md-4">
                         
                          <label for="" >الموافقه عليها ؟</label>
                          <br>
                          <label class="switch">
                                    <input type="checkbox" id="pmActive" [checked]="activePM == true" />
                                    <span class="slider round"></span>
                                  </label>
                                
                           
                        </div>                      
                       
                        
                        
                       
                         
               
                        
                    </div>
                    <div class="d-flex justify-content-end align-items-center buttom-text">
                      <button class="addnew-btn" type="submit" (click)="Viewreports()">عرض التقرير</button>
                    </div>
                </div>
            <!-- </form> -->
    </div>
</div>
    </div>
</div>
