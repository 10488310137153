import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientApiService } from 'src/app/shared/API-Service/client-api.service';
import { EmployeeApiService } from 'src/app/shared/API-Service/employee-api.service';
import { GenericResponse } from 'src/app/shared/Models/GenericResponse';
import { GetClient } from 'src/app/shared/Models/GetClient';
import { GetEmployee } from 'src/app/shared/Models/GetEmployee';
import { IdNameList } from 'src/app/shared/Models/IdNameList';
import { Roles } from 'src/app/shared/Models/Roles';
import Swal from 'sweetalert2';
import { SmsTemplateService } from 'src/app/shared/API-Service/sms-template.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SendSMSComponent } from '../../../send-sms/send-sms.component';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { OfficesService } from 'src/app/shared/API-Service/offices.service';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent extends BaseComponent implements OnInit {


  //#region  Declare Variables
  response: any[]=[];
  Employee_List: any;
  dropdownList: GetClient[];
  SmsTemplates: any[];
  customerid: string;
  Employee_ListFilter: any[]=[]
  //#endregion
  EmpName:any;
  OfficeId:any;
  ActiveId:any
   Active=[
    {id:1,name:'نشط'},
    {id:2,name:'غير نشط'}
   ]
  Offices: any[];
  ActiveState: any;
  //#region constructor
  constructor(private OfficesService:OfficesService ,public modalService: NgbModal,private SmsTemplateService : SmsTemplateService,private ApiService: EmployeeApiService , private router:Router,private clientApiService: ClientApiService,) { 
   super();
    this.getClientType();
    //this.GetSMSTemplate();
    this.GetOffices();
  }
  //#endregion

  //#region  ng OnInit
  ngOnInit(): void {
    
    this.Employee_List = [];
    this.GetEmployee();
    // this.GetEmployee(Roles.Admin);
  }
  //#endregion
  GetOffices(){
    this.OfficesService.GetOffices().subscribe(res=>{
        this.Offices=res.data
    })
}
  

  SendSMSTemplate(item?){
    localStorage.removeItem("customerid");
      localStorage.setItem("customerid",item.id);
    
      //ModalComponent is component name where modal is declare
      const modalRef = this.modalService.open(SendSMSComponent);
      modalRef.result.then((result) => {
       
      }).catch((error) => {
    
      });
  }

  //#region  Get Employee
  GetEmployee() {
    // this.page=0;
    this.pageNumber=1
    this.pager.skipCount=0
    this.pager.name=this.EmpName
    this.pager.officeId=this.OfficeId
    if(this.ActiveId==1){
      this.ActiveState=false;
    }
    else if(this.ActiveId==2){
      this.ActiveState=true
    }
    else{
      this.ActiveState=null;
    }
    this.pager.inactive=this.ActiveState
   
    this.ApiService.GetEmployeeList(this.pager).subscribe(
      response => {
        this.response = response.data;
        this.Employee_List = response.data;   
        this.totalCount = this.Employee_List.totalCount;
        this.Employee_List=this.Employee_List.items;
        // this.Employee_ListFilter=response.data;
        // this.Employee_ListFilter=this.Employee_ListFilter.items;
      },
      err => {
        // console.log(err.error);
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }

  track(index, item) {
    return item.id;
  }
  //#endregion
  pageChanged(event:any){
    if(this.ActiveId==1){
      this.ActiveState=false;
    }
    else if(this.ActiveId==2){
      this.ActiveState=true
    }
    else{
      this.ActiveState=null;
    }
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount; 


    this.ApiService.GetEmployeeList(this.pager).subscribe(
      response => {
        this.response = response.data;
        this.Employee_List = response.data;   
        this.totalCount = this.Employee_List.totalCount;
        this.Employee_List=this.Employee_List.items;
        // this.Employee_ListFilter=response.data;
        // this.Employee_ListFilter=this.Employee_ListFilter.items;
      },
      err => {
        // console.log(err.error);
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
    // newLocl._ShipmentService.Get(data).subscribe((res)=>{
    //   this.response=res.data;
    //   this.totalItems = this.response.totalCount;
    //   this.response=this.response.items;
    // })
  }
  //#region  Delete Employee 
  DeleteEmployee(id:string){    
    Swal.fire({
      title: ' تحذير !',
      text: "هل انت متأكد من حذف هذا العنصر ؟ ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7364f6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'حذف',
      cancelButtonText: 'إنهاء',
    })
    .then((result) => {

      if (result.isConfirmed) {
          this.ApiService.DeleteEmployee(id).subscribe(
            response=>{
              // this.GetEmployee(Roles.Admin);
               Swal.fire({
                    icon: 'success',
                    title: "تم حذف الموظف بنجاح",
                    showConfirmButton: false,
                    timer: 1500}) 
                     this.GetEmployee();
                  },
            err=>{
              Swal.fire({
                icon: 'error',
                title: 'خطأ',
                text: err.error.message,
              })
            }
          )
        
      } else {
        // Swal.fire(
        //   'Your appointment still active ',
        // );
      }
    }); 
  }
  //#endregion
  
  //#endregion

  //#region Add New Employee
  NavigateToInsert(){
    this.router.navigateByUrl("content/admin/InsertEmployee");
  }
  //#endregion
  getClientType() {
    this.clientApiService.GetClient().subscribe(
           (response) => {
           this.dropdownList = response.data;
           localStorage.setItem('ClientType',JSON.stringify(this.dropdownList));
           },
           (err) => {
            Swal.fire({
              icon: 'error',
              title: 'خطأ',
              text: err.error.message,
            })
           }
           );
   }
  //#region update Employee
 // updateEmployee(id:string,model:GetEmployee){
    updateEmployee(id:number){
  //  this.ApiService.Employee = model;
    //localStorage.setItem("RiskEmployeeData",JSON.stringify(this.ApiService.Employee));
   
    this.router.navigate(['content/admin/updateEmployee',id]);
  }
  //#endregion


}
