<app-breadcrumb [title]="'تقرير المحصلين'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'">
</app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
            <!-- <form action="" [formGroup]="createReports"> -->
                <div class="card-header px-0 " style="padding-bottom: 5px;">
                    <div class="row m-0 mb-2">
                      <div class="col-12 col-md-4">
                        <label for="validationCustom02"><span class="validation-required">*</span>  اسم الشركة</label>
                      <br>
                        <label *ngIf="currentUser.roleId==10">{{currentUser.name}}</label>
                  <ng-select *ngIf="currentUser.roleId!=10"
                  class="js-example-basic-single col-sm-12 job-select2 form-group"
                    required=""
                    [(ngModel)]="CollectorSmallDonation.companyId"
                    aria-label="Default select example"
                    (change)="SelectCompany($event.id)"
                    [items]="Companies"
                    bindLabel="name"
                    bindValue="id"
                     >
                  
                  </ng-select>
                      </div>
                        
                        <div class="col-md-4">
                          <label for=""> التبرعات  المحصله   من تاريخ
                          </label>
                            <div class="form-group">
                                
                               <input type="date"  class="form-control" [(ngModel)]="CollectorSmallDonation.dateFrom">
                            </div>
                        </div>
                        <div class="col-md-4">
                          <label for="">التبرعات المحصله  إلي  تاريخ</label>
                            <div class="form-group">
                             
                               <input type="date"  class="form-control" [(ngModel)]="CollectorSmallDonation.dateTo">
                            </div>
                        </div>

                    </div>

                    <div class="row m-0 mb-2">
                      <div class="col-md-4 col-sm-6">
                        <label for="">اسم المحصل</label>
                          <ng-select
                          class="js-example-basic-single"
                          aria-label="Default select example" 
                          [(ngModel)]="CollectorSmallDonation.collectorId"
                          [items]="Getcollectors"
                          bindLabel="name"
                          bindValue="id"
                        >
                        </ng-select>
                      </div>
                      <div class="col-md-4 col-sm-6">
                        <label for="">رقم الايصال من</label>
                        <input maxlength="9" type="text" onlyNumbers class="form-control" [(ngModel)]="CollectorSmallDonation.receiptNumFrom">
                      </div>
                      <div class="col-md-4">
                        <label for=""> رقم الايصال الى
                        </label>
                          <div class="form-group">
                             <input maxlength="9" type="text" onlyNumbers class="form-control" [(ngModel)]="CollectorSmallDonation.receiptNumTo">
                          </div>
                      </div>
                      <div class="col-md-4">
                        <label for="">
                        </label>
                        <div class="buttom-text" style="margin-top: 6px;">
                          <button class="addnew-btn" type="submit" (click)="Viewreports()">عرض التقرير</button>
                        </div>
                      </div>
                  </div>
                   
                </div>
            <!-- </form> -->
    </div>
</div>
