export class MediaType {
    title:string= ''
    order:number=null
    embedcode:string= ''



}
export class UpdateMediaType {
    id:number=null
    title:string= ''
    order:number=null
    embedcode:string= ''




}