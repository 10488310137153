export class InsertEmployee {
Id?: number;
nationalId: string;
name: string;
mobile: string;
address: string;
userName: string;
roleId?: number=null;
cityId?: number=null;
departmentId?: string;
jobId?: number;
hiringDate?: string;
comment?: string;
genderId?: number;
religionId?: number=null;
maritalStatusId?: number=null;
militaryStatusId?: number=null;
birthdate?: string;
nationalityId?: number=null;
mobile1?: string = "";
mobile2?: string = "";
mobile3?: string = "";
otherData?: string;
passwordHash?: string;
employeePic?: string;
resumePic?: string;
militaryCertificatePic?: string;
graduationCertificatePic?: string;
otherPaperPic?: string;
governoratId?: number=null;
officeId?:number=null
companies?: any = [];
picture?: string;
resumeUrl?: string;
militaryCertificateUrl?: string;
graduationCertificateUrl?: string;
otherPaperUrl?: string;
}
export class GetEmployeeById {
id: number;
nationalId: string;
name: string;
mobile: string;
address: string;
userName: string;
roleId?: number;
cityId?: number;
departmentId?: string;
jobId?: number;
hiringDate?: string;
comment?: string;
genderId?: number=null;
religionId?: number;
maritalStatusId?: number;
militaryStatusId?: number=null;
birthDate?: string;
nationalityId?: number;
ClientTypes?: any;
mobile1?: string;
mobile2?: string;
mobile3?: string;
otherData?: string;
passwordHash?: string;
employeePic?: string;
resumePic?: string;
militaryCertificatePic?: string;
graduationCertificatePic?: string;
otherPaperPic?: string;
GovernoratId?: number;
officeId:number
companies?: any = [];
picture?: string;
resumeUrl?: string;
militaryCertificateUrl?: string;
graduationCertificateUrl?: string;
otherPaperUrl?: string;
}
