import { displayPrevious } from './../../../../shared/data/charts/google-chart';
import { knob, ChartOptions } from './../../../../shared/data/dashboard/default';
import { Statsdata } from '../../../../shared//Models/statsdata';
import { StattsReportsService } from './../../../../shared/API-Service/statts-reports.service';

import { pieChart } from './../../../../shared/data/charts/ngx-charts';
import { Chart } from 'chart.js';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { StatisticsService } from 'src/app/shared/API-Service/statistics.service';
import { Agent_ReportModel } from 'src/app/shared/Models/Agent_ReportModel';
import { CallReason_ReportModel } from 'src/app/shared/Models/CallReason_ReportModel';
import { GraphData } from 'src/app/shared/Models/GraphData';
import { Stats_Report } from 'src/app/shared/Models/Stats_Report';
import * as chartData from '../../../../shared/data/dashboard/default'
import * as graphoptions from '../../../../shared/data/charts/confic'
import * as chartlists from '../../../../shared/data/charts/chart-list'
import * as googlecharts from '../../../../shared/data/charts/google-chart'

import { DatePipe } from '@angular/common';
import { calcPossibleSecurityContexts } from '@angular/compiler/src/template_parser/binding_parser';
import { Router } from '@angular/router';
import { state } from '@angular/animations';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';
import { environment } from 'src/environments/environment.prod';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})

export class StatsComponent  extends BaseComponent  implements OnInit,OnDestroy  {
  imgUrlServer=environment.Server_URL_Image+'/'

 
 dateoftheday = new Date(); 
 thedata :any ;
 //====================================
 marketingstatedata:any;
 governoratesStats:any;
 sectorTypeStats:any;
 sectorLocationStats:any;
 //====================================
 labels:string [] = [];
 datacounts:number[] = [];
 totalamount:number[] = [];
 //====================================
 labelsgovernrates:string [] = [];
 datacountsgovernrates:number[] = [];
 totalamountgovernrates:number[] = [];
  //=====================================
 labelssectorTypeStats:string[] = [];
 datacountssectorTypeStats:number[] = [];
 totalamountsectorTypeStats:number[] = [];
 //=======================================
 labelssectorLocationStats:string[] = [];
 datacountssectorLocationStats:number[] = [];
 totalamountsectorLocationStats:number[] = [];
 //===========================================
 employeesstats:object [] ;
  fromday: boolean;
  frommonth: boolean;
  fromyear: boolean;
  data: { };
  Donations: any;
  err: boolean;
  Companies: any[];
  CompanyId: any;
  SearchData ={
    "today":null,
    "monthToDate": null,
    "yearToDate":null,
    "companyId":null
  }
  formalldata: boolean;
  currentUser: any;
 constructor(private CompanyDataService :CompanyDataService,private StattsReportsService:StattsReportsService,public datepipe: DatePipe,private router: Router){
  // Chart.defaults.ChartOptions.font = "bold";
  super();
this.fromday=false;
this.frommonth=false;
this.fromyear=false;
this.currentUser=JSON.parse(localStorage.getItem("RiskCurrentUser"))
if(this.currentUser.roleId==10){
  this.CompanyId=this.currentUser.id
  this.SearchData.companyId=this.currentUser.id;
  this.getfromday()
}
 }
 
 
 
ngOnDestroy(): void {
}


ngOnInit(): void {
  if(this.currentUser.roleId!=10){
    this.GetCompanies();
  }
  

  this.employeesstats = [];
 
}
 GetNewInstanceFromArrys()
 {
//====================================
this.labels = [];
this.datacounts= [];
this.totalamount= [];
//====================================
this.labelsgovernrates= [];
this.datacountsgovernrates= [];
this.totalamountgovernrates = [];
 //=====================================
this.labelssectorTypeStats = [];
this.datacountssectorTypeStats = [];
this.totalamountsectorTypeStats = [];
//=======================================
this.labelssectorLocationStats = [];
this.datacountssectorLocationStats = [];
this.totalamountsectorLocationStats = [];
 }
 GetCompanies(){
  this.CompanyDataService.GetCompanies().subscribe(res=>{
    this.Companies=res.data   
    this.CompanyId=this.Companies[0].id
    this.SearchData.companyId=this.Companies[0].id;
    this.getfromday();
  })
}
selectCompany(CompanyId:any){
  this.CompanyId=CompanyId
this.SearchData.companyId=CompanyId;
if(this.fromday){
  this.getfromday()
}
else if(this.frommonth){
  this.getfrommonth()
}
else if (this.fromyear){
  this.getfromyear
}
else if (this.formalldata){
this.getall(this.SearchData)
}
}
creatChart()
{
  document.getElementById("chartContainer3").innerHTML = '&nbsp;';
  document.getElementById("chartContainer3").innerHTML = '<canvas id="myChart4"></canvas>';
  
  const myChart4 = new Chart("myChart4", {
    type: 'bar',
    data: {
      
        labels: this.labelssectorLocationStats,
        datasets: [{
            label: 'العدد',
            data: this.datacountssectorLocationStats,
            backgroundColor: [
              'rgba(153, 102, 255, 0.2)',
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(153, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 100, 0.2)',
            ],
            borderColor: [
                'rgba(153, 206, 86, 1)',
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)'
            ],
            
            borderWidth: 1.5
        },
        {
          label: 'اجمالي المبلغ',
          data: this.totalamountsectorLocationStats,
          backgroundColor: [
            'rgba(153, 102, 255, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 200, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
          ],
          borderColor: [
            'rgba(153, 102, 255, 0.2)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)'
          ],
          borderWidth: 1
      }]
    },
    options: {
      title: {
        display: false,
        text: 'Color test'
      },
      legend: {
        position: 'top',
        display: true,
        fullWidth: true,
        labels: {
          fontSize: 15,
          fontFamily: 'Cairo',
          fontColor: 'grey',
        }
      },
      scales: {
        xAxes: [{
          gridLines: {
                display: true,
                
                color: "#E6D7D7"
              },
          ticks: {
            beginAtZero: true,
            stepSize: 1 ,
            fontSize:15,
            fontFamily: 'Cairo',
            fontColor: "grey", // this here     
        }
        }],
        yAxes: [{
          gridLines: {
            display: true,
            color: "#E6D7D7"
          },
        }]
      }
    }

});
document.getElementById("chartContainer2").innerHTML = '&nbsp;';
document.getElementById("chartContainer2").innerHTML = '<canvas id="myChart3"></canvas>';
  const myChart3 = new Chart("myChart3", {
    type: 'bar',
    data: {
      
        labels: this.labelssectorTypeStats,
        datasets: [{
            label: 'العدد',
            data: this.datacountssectorTypeStats,
            backgroundColor: [
              'rgba(153, 102, 255, 0.2)',
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(153, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 100, 0.2)',
            ],
            borderColor: [
                'rgba(153, 206, 86, 1)',
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)'
            ],
            
            borderWidth: 1.5
        },
        {
          label: 'اجمالي المبلغ',
          data: this.totalamountsectorTypeStats,
          backgroundColor: [
            'rgba(153, 102, 255, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 200, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
          ],
          borderColor: [
            'rgba(153, 102, 255, 0.2)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)'
          ],
          borderWidth: 1
      }]
    },
    options: {
      title: {
        display: false,
        text: 'Color test'
      },
      legend: {
        position: 'top',
        display: true,
        fullWidth: true,
        labels: {
          fontSize: 15,
          fontFamily: 'Cairo',
          fontColor: 'grey',
        }
      },
      scales: {
        xAxes: [{
          gridLines: {
                display: true,
                color: "#E6D7D7"
              },
          ticks: {
            beginAtZero: true,
            stepSize: 2 ,
            fontSize:15,
            fontFamily: 'Cairo',
            fontColor: "grey", // this here     
        }
        }],
        yAxes: [{
          gridLines: {
            display: true,
            color: "#E6D7D7"
          },
        }]
      }
    }

});
// this line is drawing new canvas with no data 
document.getElementById("chartContainer").innerHTML = '&nbsp;';
document.getElementById("chartContainer").innerHTML = '<canvas id="myChart2"></canvas>';
// var ctxTopItems = document.getElementById("myChart2").getElementsByTagName("2d");

  const myChart2 = new Chart("myChart2", {
    type: 'bar',
    data: {
      
        labels: this.labelsgovernrates,
        datasets: [{
            label: 'العدد',
            data: this.datacountsgovernrates,
            backgroundColor: [
              'rgba(153, 102, 255, 0.2)',
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(153, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 100, 0.2)',
            ],
            borderColor: [
                'rgba(153, 206, 86, 1)',
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)'
            ],
            borderWidth: 1
        },
        {
          label: 'اجمالي المبلغ',
          data: this.totalamountgovernrates,
          backgroundColor: [
            'rgba(153, 102, 255, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 200, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
          ],
          borderColor: [
            'rgba(153, 102, 255, 0.2)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)'
          ],
          borderWidth: 1
      }]
    },
    options: {
      title: {
        display: false,
        text: 'Color test'
      },
      legend: {
        position: 'top',
        display: true,
        fullWidth: true,
        labels: {
          fontSize: 15,
          fontFamily: 'Cairo',
          fontColor: 'grey',
        }
      },
      scales: {
        xAxes: [{
          gridLines: {
                display: true,
                color: "#E6D7D7"
              },
          ticks: {
            beginAtZero: true,
            stepSize: 2 ,
            fontSize:15,
            fontFamily: 'Cairo',
            fontColor: "grey", // this here     
        }
        }],
        yAxes: [{
          gridLines: {
            display: true,
            color: "#E6D7D7"
          },
        }]
      }
    }
} );
// this line is drawing new canvas with no data 
document.getElementById("chartContainer1").innerHTML = '&nbsp;';
document.getElementById("chartContainer1").innerHTML = '<canvas id="myChart1"></canvas>';

  const myChart = new Chart("myChart1", {
    type: 'bar',
    data: {
      
        labels: this.labels,
        datasets: [{
            label: 'العدد',
            data: this.datacounts,
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)'
            ],
            borderWidth: 1
        },
        {
          label: 'اجمالي المبلغ',
          data: this.totalamount,
          backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
          ],
          borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)'
          ],
          borderWidth: 1
      }]
    },
    options: {
      title: {
        display: false,
        text: 'Color test'
      },
      legend: {
        position: 'top',
        display: true,
        fullWidth: true,
        labels: {
          fontSize: 15,
          fontFamily: 'Cairo',
          fontColor: 'grey',
        }
      },
      scales: {
        xAxes: [{
          gridLines: {
                display: true,
                color: "#E6D7D7"
              },
          ticks: {
            beginAtZero: true,
            stepSize: 2 ,
            fontSize:15,
            fontFamily: 'Cairo',
            fontColor: "grey", // this here     
        }
        }],
        yAxes: [{
          gridLines: {
            display: true,
            color: "#E6D7D7"
          },
        }]
      }
    }
});
}

//for seif elfagal
getfromday(){
this.fromday=true;
this.frommonth=false;
this.fromyear=false;
this.formalldata=false

this.SearchData.monthToDate=null;
this.SearchData.yearToDate=null;
this.SearchData.companyId=this.CompanyId;
this.SearchData.today=this.dateoftheday
  this.GetNewInstanceFromArrys();
  this.StattsReportsService.RiskStats(this.SearchData).subscribe( (res) => {
    
    this.thedata = res.data;
   this.employeesstats = this.thedata.employeesStats;

    this.governoratesStats = this.thedata.governoratesStats;
    for(var i = 0 ; i < this.governoratesStats.length;i++){
      this.labelsgovernrates.push(this.governoratesStats[i].title);
      this.datacountsgovernrates.push(this.governoratesStats[i].donationsCount);
      this.totalamountgovernrates.push(this.governoratesStats[i].totalAmount);
     
   }
   

    this.sectorTypeStats = this.thedata.sectorTypeStats;
    for(var i = 0 ; i < this.sectorTypeStats.length;i++){
      this.labelssectorTypeStats.push(this.sectorTypeStats[i].title);
      this.datacountssectorTypeStats.push(this.sectorTypeStats[i].donationsCount);
      this.totalamountsectorTypeStats.push(this.sectorTypeStats[i].totalAmount);
    
   }
   

   this.sectorLocationStats = this.thedata.sectorLocationStats;
   for(var i = 0 ; i < this.sectorLocationStats.length;i++){
     this.labelssectorLocationStats.push(this.sectorLocationStats[i].title);
     this.datacountssectorLocationStats.push(this.sectorLocationStats[i].donationsCount);
     this.totalamountsectorLocationStats.push(this.sectorLocationStats[i].totalAmount);
    
  }
 
    this.marketingstatedata = this.thedata.marketingSourceStats;
    for(var i = 0 ; i < this.marketingstatedata.length;i++){
      this.labels.push(this.marketingstatedata[i].title);
      this.datacounts.push(this.marketingstatedata[i].donationsCount);
      this.totalamount.push(this.marketingstatedata[i].totalAmount);
     
   }

    this.creatChart();
    
   })

};

getfrommonth(){
  this.frommonth=true;
  this.fromday=false;
  this.fromyear=false;
  this.formalldata=false

  this.SearchData.today=null;
  this.SearchData.yearToDate=null;
  this.SearchData.companyId=this.CompanyId;
  this.SearchData.monthToDate=this.dateoftheday;
  this.StattsReportsService.RiskStats(this.SearchData).subscribe( (res) => {
    this.GetNewInstanceFromArrys();
    this.thedata = res.data;
    this.employeesstats = this.thedata.employeesStats;
    this.governoratesStats = this.thedata.governoratesStats;
    for(var i = 0 ; i < this.governoratesStats.length;i++){
      this.labelsgovernrates.push(this.governoratesStats[i].title);
      this.datacountsgovernrates.push(this.governoratesStats[i].donationsCount);
      this.totalamountgovernrates.push(this.governoratesStats[i].totalAmount);
     
   }
   

    this.sectorTypeStats = this.thedata.sectorTypeStats;
    for(var i = 0 ; i < this.sectorTypeStats.length;i++){
      this.labelssectorTypeStats.push(this.sectorTypeStats[i].title);
      this.datacountssectorTypeStats.push(this.sectorTypeStats[i].donationsCount);
      this.totalamountsectorTypeStats.push(this.sectorTypeStats[i].totalAmount);
      
   }
   

    this.sectorLocationStats = this.thedata.sectorLocationStats;
    for(var i = 0 ; i < this.sectorLocationStats.length;i++){
      this.labelssectorLocationStats.push(this.sectorLocationStats[i].title);
      this.datacountssectorLocationStats.push(this.sectorLocationStats[i].donationsCount);
      this.totalamountsectorLocationStats.push(this.sectorLocationStats[i].totalAmount);
   
   }
  
    this.marketingstatedata = this.thedata.marketingSourceStats;
    for(var i = 0 ; i < this.marketingstatedata.length;i++){
      this.labels.push(this.marketingstatedata[i].title);
      this.datacounts.push(this.marketingstatedata[i].donationsCount);
      this.totalamount.push(this.marketingstatedata[i].totalAmount);
      
   }
   
   this.creatChart();

  
   })

}

getfromyear(){
  this.fromyear=true;
 this.fromday=false;
 this.frommonth=false
 this.formalldata=false
 this.SearchData.today=null;
 this.SearchData.monthToDate=null;
 this.SearchData.companyId=this.CompanyId;
 this.SearchData.yearToDate=this.dateoftheday;
  this.GetNewInstanceFromArrys();

  this.StattsReportsService.RiskStats(this.SearchData).subscribe( (res) => {
   
    this.thedata = res.data;
    this.employeesstats = this.thedata.employeesStats;

    this.governoratesStats = this.thedata.governoratesStats;
    for(var i = 0 ; i < this.governoratesStats.length;i++){
      this.labelsgovernrates.push(this.governoratesStats[i].title);
      this.datacountsgovernrates.push(this.governoratesStats[i].donationsCount);
      this.totalamountgovernrates.push(this.governoratesStats[i].totalAmount);
     
   }
   

    this.sectorTypeStats = this.thedata.sectorTypeStats;
    for(var i = 0 ; i < this.sectorTypeStats.length;i++){
      this.labelssectorTypeStats.push(this.sectorTypeStats[i].title);
      this.datacountssectorTypeStats.push(this.sectorTypeStats[i].donationsCount);
      this.totalamountsectorTypeStats.push(this.sectorTypeStats[i].totalAmount);
     
   }
  

    this.sectorLocationStats = this.thedata.sectorLocationStats;
    for(var i = 0 ; i < this.sectorLocationStats.length;i++){
      this.labelssectorLocationStats.push(this.sectorLocationStats[i].title);
      this.datacountssectorLocationStats.push(this.sectorLocationStats[i].donationsCount);
      this.totalamountsectorLocationStats.push(this.sectorLocationStats[i].totalAmount);
     
   }
  
    this.marketingstatedata = this.thedata.marketingSourceStats;
    for(var i = 0 ; i < this.marketingstatedata.length;i++){
      this.labels.push(this.marketingstatedata[i].title);
      this.datacounts.push(this.marketingstatedata[i].donationsCount);
      this.totalamount.push(this.marketingstatedata[i].totalAmount);
      
   }
  
    
   
   this.creatChart();


    
   })

}
getall(data?){
  this.fromday=false;
    this.frommonth=false;
    this.fromyear=false;
    this.formalldata=true
  if(data){
    this.SearchData=data;
  }
  else{
    this.SearchData.monthToDate=null;
    this.SearchData.today=null;
    this.SearchData.yearToDate=null;
  }
  this.GetNewInstanceFromArrys();
  this.StattsReportsService.RiskStats(this.SearchData).subscribe( (res) => {
    
    this.thedata = res.data;
    this.employeesstats = this.thedata.employeesStats;
    this.governoratesStats = this.thedata.governoratesStats;
    for(var i = 0 ; i < this.governoratesStats.length;i++){
      this.labelsgovernrates.push(this.governoratesStats[i].title);
      this.datacountsgovernrates.push(this.governoratesStats[i].donationsCount);
      this.totalamountgovernrates.push(this.governoratesStats[i].totalAmount);
      
   }
   

    this.sectorTypeStats = this.thedata.sectorTypeStats;
    for(var i = 0 ; i < this.sectorTypeStats.length;i++){
      this.labelssectorTypeStats.push(this.sectorTypeStats[i].title);
      this.datacountssectorTypeStats.push(this.sectorTypeStats[i].donationsCount);
      this.totalamountsectorTypeStats.push(this.sectorTypeStats[i].totalAmount);
      
   }
  

    this.sectorLocationStats = this.thedata.sectorLocationStats;
    for(var i = 0 ; i < this.sectorLocationStats.length;i++){
      this.labelssectorLocationStats.push(this.sectorLocationStats[i].title);
      this.datacountssectorLocationStats.push(this.sectorLocationStats[i].donationsCount);
      this.totalamountsectorLocationStats.push(this.sectorLocationStats[i].totalAmount);
   
   }
  
  
    this.marketingstatedata = this.thedata.marketingSourceStats;
    for(var i = 0 ; i < this.marketingstatedata.length;i++){
      this.labels.push(this.marketingstatedata[i].title);
      this.datacounts.push(this.marketingstatedata[i].donationsCount);
      this.totalamount.push(this.marketingstatedata[i].totalAmount);
      
   }
   
   
    
   this.creatChart();
    
  

   })
}
 
goToDetails(stateid){
  if(this.fromday){
    this.data = {
      today:this.dateoftheday,
      // monthToDate: "",
      // yearToDate: "",
      statuesId: stateid,
      companyId:this.CompanyId
    }
  }
  else  if(this.frommonth){
    this.data = {
      // today: "",
      monthToDate: this.dateoftheday,
      // yearToDate: "",
      
      statuesId: stateid,
      companyId:this.CompanyId
    }
  }

  else if(this.fromyear){
    this.data = {
      // today: "",
      // monthToDate: this.dateoftheday,
      yearToDate: this.dateoftheday,
      statuesId: stateid,
      companyId:this.CompanyId
    }
  }
else{
  this.data = {
   
    statuesId: stateid
    ,
      companyId:this.CompanyId
  }
}
this.data['maxResultCount']=this.pager.maxResultCount;
    this.data['skipCount']=this.pager.skipCount;
this.StattsReportsService.GetDonationStatus(this.data).subscribe({
  next: (data) => {
   var res = data;
    if (res.success) {
      this.err = false;
      this.Donations=res.data
      this.router.navigateByUrl("content/admin/statsdetails",{state:{Donations:this.Donations,data:this.data}});

 //     localStorage.setItem('RiskuserMenu',this.Menu)
      
    } else {
      this.err = true;
    }
  },
  error: (error) => {
    this.err = true;
    Swal.fire({
      icon: 'error',
      title: 'خطأ',
      text: error.error.message,
    })
  },
});

}

}


