export class ReceiptBook {
    companyId:number=null
    bookNumber:string= ''
    prefix:string= ''
    receiptFrom:string= ''
    receiptTo:string= ''
    donationMainTypeId:number=null

}
export class UpdateReceiptBook {
    id:number=null
    companyId:number=null
    bookNumber:string= ''
    prefix:string= ''
    receiptFrom:string= ''
    receiptTo:string= ''
    donationMainTypeId:number=null


}