import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DontaionsApiService } from 'src/app/shared/API-Service/donations-api-service';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ProjectMedia } from '../../../../shared//Models/ProjectMedia';
import {MediaApiService} from 'src/app/shared/API-Service/media-api.service'
import { SectorTypeApiService } from 'src/app/shared/API-Service/sector-type-api.service';
import { CustomerApiService } from 'src/app/shared/API-Service/customer-api.service';
import { environment } from '../../../../../environments/environment.prod';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { delay, map, withLatestFrom } from 'rxjs/operators';
import { ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ChoosecompanyModalComponent } from 'src/app/shared/components/ChooseCompanyModal/choosecompany-modal/choosecompany-modal.component';
import {CompanyDataService} from 'src/app/shared/API-Service/company-data.service'
import { ReceiptBookService } from 'src/app/shared/API-Service/receipt-book.service';
import { SendSMSComponent } from '../../send-sms/send-sms.component';

@Component({
  selector: 'app-customer-accomplishment',
  templateUrl: './customer-accomplishment.component.html',
  styleUrls: ['./customer-accomplishment.component.css']
})

export class CustomerAccomplishmentComponent extends BaseComponent implements OnInit {
  modalRef: BsModalRef;

  @ViewChild('fileInput')
  myInputVariable: ElementRef;
  CustomerProjectDonation: any[]=[];
  CustomerId: any;
  mobile:any;
  donationDetalisId: any;
  closeResult: string;
  insertForm: FormGroup;
  MediaType = new ProjectMedia()
  id: any;
  update: boolean;
  MediaTypes: any;
  SectorType: any;
  images: any[]=[];
  imagesView: string | ArrayBuffer;
  haveImage: boolean;
  CreatMediaProjectPicForm: any = new FormData();
  customerinfo: any;
  newAccomplishment:boolean
  AccomplishmentImage: any[]=[];
  SectorTypeName: any;
  sectorTypeId: any;
  receiptNum:any;
  receiptBookId:any
  donationSerial:any;
  donationDetailSerial:any;
  ShowPhoto: boolean;
  mediaTypeId: any;
  disabled:boolean;
  Loader:boolean;
  CompanyId: number;
  LoadPage:boolean;
  imgUrlServer=environment.Server_URL_Image+'/'
  companyImage: any;
  companyName: any;
  GetReceiptBooks: any
  receiptNumDisable: boolean =true;
  BookNum: any;
  ReceiptRequierd:boolean
  constructor(private ChooseCompanyModalService: BsModalService, private ReceiptBookService:ReceiptBookService,private CustomerApiService:CustomerApiService,private SectorTypeApiService:SectorTypeApiService,
    private MediaApiService:MediaApiService,
     private router: Router,private route: Router,
     private _formBuilder: FormBuilder,public modalService: NgbModal,
     private DontaionsApiService :DontaionsApiService,private ActivatedRoute:ActivatedRoute,
     private CompanyDataService:CompanyDataService) {super();
    this.newAccomplishment=false
    }

  ngOnInit(): void {
    if(this.ActivatedRoute.snapshot.params.id){
      this.LoadPage=true;
      this.id = this.ActivatedRoute.snapshot.params.id;
      
      this.CompanyId= this.CompanyDataService.CompanyInfo.CompanyId;
      this.companyImage=this.CompanyDataService.CompanyInfo.CompanyIcon;
      this.companyName=this.CompanyDataService.CompanyInfo.CompanyName
      
      if(!this.CompanyDataService.CompanyInfo.CompanyId){
        this.CompanyId= Number(localStorage.getItem("CompanyId")) 
        this.companyImage= localStorage.getItem("CompanyIcon")
        this.companyName= localStorage.getItem("CompanyName")
      }
console.log(this.CompanyDataService.CompanyInfo)
this.GetCustomerProjectDonation()
//this.GetMediaType();
    this.initForm();
    this.getReceiptBooks(this.CompanyId)
    }
else{
  this.OpenModal()
}

 
    
  }

  getReceiptBooks(companyId){
    this.ReceiptBookService.GetReceiptBookByCompanyId(companyId).subscribe(response=>{
          
      this.GetReceiptBooks = response.data
    })
  }
  OpenModal(){
    const modalConfig = {
      class: 'modal-dialog-centered modal-lg', // Add your desired width class here
      ignoreBackdropClick: true
    };
    this.modalRef=this.ChooseCompanyModalService.show(ChoosecompanyModalComponent, modalConfig);
    this.modalRef.content.modalClosed.subscribe((company: any) => {
      this.LoadPage=true;
      this.CompanyId=company.companyId;
      this.companyImage=this.imgUrlServer+company.companyLogoPath;
      this.companyName=company.companyName
      this.getReceiptBooks(this.CompanyId)
      this.GetCustomerProjectDonation()
      //this.GetMediaType();
    this.initForm();
    });
  }
  getReceiptBook(event){
  
    if(event){
      this.receiptNumDisable=false
      this.BookNum=event.bookNumber
        }
    else{
      this.ReceiptRequierd=false;
      this.receiptNumDisable=true
      this.BookNum=null
      this.receiptNum=null;
    }
    
  }
  New(){
    this.newAccomplishment=true;
  }
  receiptNumChange(){
    if(this.receiptNum){
      this.ReceiptRequierd=false;
    }
  }
  GetCustomerProjectDonation(){
    if(this.BookNum && !this.receiptNum){
      this.ReceiptRequierd=true
          }
          else{
              this.pager.skipCount=0
              this.pageNumber=1
              this.totalCount=0
    this.customerinfo=[];
    this.CustomerProjectDonation=[]
    const data={
      "maxResultCount": this.pager.maxResultCount,
      "skipCount": this.pager.skipCount,
      "companyId":this.CompanyId
    }
    if(this.mobile){
      data['mobile']=this.mobile
    }
    if(this.CustomerId){
      data['customerId']=Number(this.CustomerId)
    }
    if(this.id){
      data['donationId']=Number(this.id)
    }
    if(this.BookNum){
      data['receiptNum']=this.BookNum+'-'+this.receiptNum

    }

    if(this.donationSerial){
      data['donationSerial']=this.donationSerial
    }
    if(this.donationDetailSerial){
      data['donationDetailSerial']=this.donationDetailSerial
    }

    this.DontaionsApiService.GetCustomerProjectDonation(data).subscribe(res=>{
      if(res.data){
        this.CustomerProjectDonation=[]
           this.CustomerProjectDonation=res.data.donations?.items;
      this.totalCount=res.data.donations?.totalCount;
      if(res.data.customer){
        this.customerinfo=[]
        this.customerinfo=res.data.customer;
      }
      }
   
    })
          }
  
  }
  pageChanged(e){
    this.pageNumber = e.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    const data={
      "maxResultCount": this.pager.maxResultCount,
      "skipCount": this.pager.skipCount,
      "companyId":this.CompanyId
    }
    if(this.mobile){
      data['mobile']=this.mobile
    }
    if(this.CustomerId){
      data['customerId']=Number(this.CustomerId)
    }
    if(this.id){
      data['donationId']=Number(this.id)
    }
    if(this.BookNum){
      data['receiptNum']=this.BookNum+'-'+this.receiptNum
    }

    if(this.donationSerial){
      data['donationSerial']=this.donationSerial
    }
    if(this.donationDetailSerial){
      data['donationDetailSerial']=this.donationDetailSerial
    }
    this.DontaionsApiService.GetCustomerProjectDonation(data).subscribe(res=>{
      this.CustomerProjectDonation=[]
      this.customerinfo=[]
      this.CustomerProjectDonation=res.data.donations.items;
      this.totalCount=res.data.donations.totalCount;
      this.customerinfo=res.data.customer;
    })
  }
  get fc() {
    return this.insertForm.controls;
  }
  initForm() {
    this.insertForm = this._formBuilder.group({
      title: [ '', Validators.required],
      order: ['', Validators.required],
    });
  }
  open(content,item) {
    this.AccomplishmentImage=[];
    this.donationDetalisId=item.id;
    this.SectorTypeName=item.sectorTypeName;
    this.sectorTypeId=item.sectorTypeId;
    this.getAccomplishmentMedia(item.id);
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true,size: 'xl'  }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  private getDismissReason(reason: any): string {
  
    if (reason === ModalDismissReasons.ESC) {

      this.insertForm.reset();
      this.donationDetalisId="";
      this.newAccomplishment=false;
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.insertForm.reset();
      this.donationDetalisId="";
      this.newAccomplishment=false;
      return 'by clicking on a backdrop';
    } else {
      this.insertForm.reset();
      this.donationDetalisId="";
      this.newAccomplishment=false;
      return `with: ${reason}`;
    }
  }

  modalclose(){
    this.insertForm.reset();
    this.donationDetalisId="";
    this.newAccomplishment=false;
    this.modalService.dismissAll();
  }
  GetCustomerById(){
    this.CustomerApiService.GetByIdCustomer(this.CustomerId).subscribe(res=>{
      this.customerinfo=res.data;
    })
  }
  Search(){
    this.id=null;
    this.CustomerId=null
 this.GetCustomerProjectDonation();

    
  }


  getMediaId(e){
    if(e.id==7){
      this.haveImage=true;
    }
    else{
      this.haveImage=false;
    }
     }
  getAccomplishmentMedia(donationDetalisId){
    this.MediaApiService.GetAccomplishmentMedia(donationDetalisId).subscribe(res=>{
      this.AccomplishmentImage=res.data;
      this.AccomplishmentImage=this.AccomplishmentImage.filter(x=>x.mediaTypeId==7||x.mediaTypeId==11);
      this.AccomplishmentImage= this.AccomplishmentImage.sort((a, b) => a.order - b.order);
      this.AccomplishmentImage.forEach(element => {
        element.url=environment.Server_URL_Image+'/'+element.url
      });
      if(this.AccomplishmentImage.length!=0){
      const index = this.AccomplishmentImage.length -1
      const NextOrder=this.AccomplishmentImage[index].order + 1
      this.insertForm.get('order').setValue(NextOrder) 
      }

    })
  } 
     onFileSelected(event, element) {
       // this.element = element
     //   this.haveImage = true;
       let filesAmount = event.target.files.length;
       let files = event.target.files;
   
       var reader = new FileReader();
       // this.imagePath = files;   
       const video = "video";
       if(files[0].type?.includes(video)){
         this.ShowPhoto=false;
       }
       else{
        this.ShowPhoto=true;
       }
       reader.onload = (_event) => {
         this.images.push(files[0]);
         this.imagesView = reader.result;
       }
       reader.onloadend = (_event) => {
        event.target.value = null; // Reset the value of the file input element
      };
      reader.readAsDataURL(files[0]);

     }
     SendSMSTemplate(item?){
      localStorage.removeItem("donationId");
      localStorage.removeItem("customerid");
      if(item){
      localStorage.setItem("donationId",item.donationId);
      }
      else{
        localStorage.setItem("customerid",this.CustomerId);
      }
        //ModalComponent is component name where modal is declare
     
        
        const modalRef = this.modalService.open(SendSMSComponent);
        modalRef.componentInstance.CompanyId = this.CompanyId;
  
        modalRef.result.then((result) => {
       
        }).catch((error) => {
       
        });
    }
     deleteimage(imgindex) {
       this.images.splice(imgindex, 1);
       this.imagesView=null
   
     }
     deleteAccomplishmentMedia(id:any){
      
      Swal.fire({
        title: " تحذير !",
        text: "هل انت متأكد من حذف هذه الصورة  ؟ ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: '#7364f6',
        cancelButtonColor: "#d33",
        confirmButtonText: "حذف",
        cancelButtonText: "رجوع",
      }).then((result) => {
        if (result.isConfirmed) {
          this.MediaApiService.DeleteAccomplishmentMedia(id).subscribe(
            (res) => {
              this.getAccomplishmentMedia(this.donationDetalisId)
              Swal.fire({
                icon: "success",
                title: "تم الحذف بنجاح",
                showConfirmButton: false,
                timer: 1500,
                });
            },
            (err) => {
              Swal.fire({
                icon: "error",
                title: "خطأ",
                text: err.error,
              });
            }
          );
        } else {
          // Swal.fire(
          //   'Your appointment still active ',
          // );
        }
      });
      

     }
     OpenImage(imagesView){
       Swal.fire({
         imageUrl: imagesView,
         imageHeight: 400,
         imageAlt: 'A tall image',
         confirmButtonText:"الغاء"
       })
     }
    //  GetMediaType(){
    //    this.MediaApiService.GetMediaType().subscribe(res=>{
        
    //      this.MediaTypes=res.data;
    //      this.MediaTypes= this.MediaTypes.sort((a, b) => a.order - b.order);
    //    })
    //  }
   
     GetSectorType(){
       this.SectorTypeApiService.getSectorType().subscribe(res=>{
         this.SectorType=res.data
       })
     }




  Submit(){
      if (this.insertForm.status == "VALID") {
        this.disabled=true;
        this.InsertAccomplishmentMediaPic();
    }
     else {
      this.insertForm.markAllAsTouched();
    }
  }


 
  InsertAccomplishmentMediaPic(){
    if(!this.ShowPhoto){
      this.mediaTypeId=11
    }
    else{
      this.mediaTypeId=7
    }
    this.images.forEach(element => {
      this.CreatMediaProjectPicForm.append('Image', element)
    });
    this.CreatMediaProjectPicForm.set('title',this.insertForm.get('title').value)
    this.CreatMediaProjectPicForm.set('order',this.insertForm.get('order').value)
    this.CreatMediaProjectPicForm.set('mediaTypeId',this.mediaTypeId)
    this.CreatMediaProjectPicForm.set('sectorTypeId', Number(this.sectorTypeId))
    this.CreatMediaProjectPicForm.set('donationDetailId', this.donationDetalisId)
    this.Loader=true;
    this.MediaApiService.CreateAccomplishmentMediaPic(this.CreatMediaProjectPicForm).subscribe(
      (response:any) => {
      Swal.fire({
      icon: "success",
      title: "تم الحفظ بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.AccomplishmentImage.push({"id":response.data.id,"url":this.imagesView,"order":this.insertForm.get('order').value,"title":this.insertForm.get('title').value,"mediaTypeId":this.mediaTypeId})
      this.insertForm.reset();
      this.CreatMediaProjectPicForm.delete('Image')
    this.CreatMediaProjectPicForm.delete('title')
    this.CreatMediaProjectPicForm.delete('order')
    this.CreatMediaProjectPicForm.delete('mediaTypeId')
    this.CreatMediaProjectPicForm.delete('sectorTypeId')
    this.CreatMediaProjectPicForm.delete('donationDetailId')
      this.imagesView=null;
      this.images=[];
      this.haveImage=false;
      this.disabled=false;
      this.Loader=false
   //   this.modalService.dismissAll()
      // this.router.navigateByUrl("content/admin/media-types");

    },
      (err) => {
        Swal.fire({
          icon: "error",
          title:err.error.message
          });
          this.disabled=false;
          this.Loader=false;
      }
            );
  }
}
