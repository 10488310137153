import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ReceiptBookService } from '../../../../shared/API-Service/receipt-book.service';
import { CollectorManagerService } from 'src/app/shared/API-Service/collector-manager.service';
import Swal from 'sweetalert2';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-collector-receipts',
  templateUrl: './collector-receipts.component.html',
  styleUrls: ['./collector-receipts.component.css']
})
export class CollectorReceiptsComponent extends BaseComponent implements OnInit {
  InsertForm: FormGroup;
  selectedItems: any[]=[];
  closeResult = '';
  form:{
    collector:"",
    receipts:[]
  }
  dropdownSettings: IDropdownSettings = {};
  Receipts: any[]=[];
  update:boolean;
  validated: boolean;
  Collector: any;
  ReceiptsIds: any[]=[];
  CollectorReceipts:any[]=[];
  AllCollectorReceipts: any;
  CollectorId: any;
  SelectCollector: boolean;

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private ReceiptBookService :ReceiptBookService,
    private CollectorManagerService:CollectorManagerService
    ) {
      super();
      // this.dropdownSettings = {
      //   singleSelection: false,
      //   idField: "id",
      //   textField: "number",
      //   selectAllText: "تحديد الكل",
      //   unSelectAllText: "الغاء الكل",
      //   allowSearchFilter: true,
      //   };
    }
  ngOnInit(): void {
    this.GetCollectors();
  }
  pageChanged(event: any){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.CollectorManagerService.GetAllCollectorEmployeesList(this.pager).subscribe((res:any) => {
      this.Collector = res.data.items;
      this.totalCount=res.data.totalCount
    })
    
 

  }
  GetCollectors(){
    this.CollectorManagerService.GetAllCollectorEmployeesList(this.pager).subscribe((res:any) => {
      this.Collector = res.data.items;
      this.totalCount=res.data.totalCount
    })
  }
  // InitForm(form?) {

  //   this.InsertForm = this._formBuilder.group({
  //   Collector: [form?.collector||'', Validators.required],
  //   Receipts: [this.selectedItems, Validators.required],
  //   });
  //   }
    // open(content) {
    //   this.GetFreeReceipts();
    //   this.InsertForm.get('Collector').setValue(this.CollectorId)
    //   this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
    //     (result) => {
    //       this.closeResult = `Closed with: ${result}`;
    //     },
    //     (reason) => {
    //       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    //     },
    //   );
    // }
    Edit(item){
       localStorage.removeItem("CollectorName")
      this.router.navigate([`content/admin/edit-collector-receipts`,item.id]);
      localStorage.setItem('CollectorName',item.name);
    }

}
