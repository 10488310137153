import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Offices} from '../../../../shared//Models/Offices'
import { MediaType } from '../../../../shared//Models/MediaType';
import Swal from 'sweetalert2';
import { OfficesService } from 'src/app/shared/API-Service/offices.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-insert-office',
  templateUrl: './insert-office.component.html',
  styleUrls: ['./insert-office.component.css']
})
export class InsertOfficeComponent implements OnInit {
  InsertForm: FormGroup;
  Offices=new Offices()
  //MediaType = new MediaType()
  maxDate: Date;
  update: boolean;
  dropdownList: any = [];
  selectedItems: any = [];
  // callClient: CallClient[] = [];
  companyTypeId: any = [];
  returnedObject: any = {};
  validated: boolean;
  Office: any;
  id: any;
  disableBtn:boolean
  constructor( private route: ActivatedRoute,private router: Router,private _formBuilder: FormBuilder,private OfficesService:OfficesService) {
    this.InitForm()
   }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id")) {
   
      this.id =this.route.snapshot.paramMap.get("id")
    this.OfficesService.GetOfficeById(this.id).subscribe(async (res:any)=>{
    this.Office=  await res.data;
    this.InitForm(this.Office);
    })
      this.update = true;
    } else {
      this.InitForm()
      this.update = false;
    }
  }
  InitForm(office?:Offices) {
    this.InsertForm = this._formBuilder.group({
    Title: [office?.title, Validators.required],
    address: [office?.address, Validators.required],
    });
    }
    get fc() {
      return this.InsertForm.controls;
    }
  onSubmit() {
    
    if (this.InsertForm.status == "VALID") {
    this.validated = false;
    if (this.update == true) {
    this.UpdateOffice();
    } else {
    this.InsertOffice();
    }
    } else {
    this.validated = true;
    }
    }
    UpdateOffice(){
      this.disableBtn=true;
      const data ={
        "id":this.id,
        "title": this.InsertForm.get('Title').value,
        "address":this.InsertForm.get('address').value,
      }
      this.OfficesService.UpdateOffice(data).subscribe(
        (response) => {
        Swal.fire({
        icon: "success",
        title: "تم التعديل بنجاح",
        showConfirmButton: false,
        timer: 1500,
        });
        this.disableBtn=false;
        this.router.navigateByUrl("content/admin/GetOffices");
      },
        (err) => {
          this.disableBtn=false;

          Swal.fire({
            icon: "error",
            title:err.error.message
            });
        }
    
        );
    }
    InsertOffice(){
      this.disableBtn=true;

      const data ={
        "title": this.InsertForm.get('Title').value,
        "address":this.InsertForm.get('address').value,
      }
      this.OfficesService.InsertOffice(data).subscribe(
        (response) => {
        Swal.fire({
        icon: "success",
        title: "تم الحفظ بنجاح",
        showConfirmButton: false,
        timer: 1500,
        });
        this.disableBtn=false;
        this.router.navigateByUrl("content/admin/GetOffices");
  
      },
        (err) => {
          this.disableBtn=false;

          Swal.fire({
            icon: "error",
            title:err.error.message
            });
        }
              );
    }
   
}
