import { DonationCategory } from '../../../../shared//Models/donation-category';
import { Router } from '@angular/router';
import { DonationCategoryService } from './../../../../shared/API-Service/donation-category.service';
import { Component, OnInit } from '@angular/core';
import Swal from "sweetalert2";
import { BaseComponent } from 'src/app/shared/components/base/base.component';

@Component({
  selector: 'app-donation-category',
  templateUrl: './donation-category.component.html',
  styleUrls: ['./donation-category.component.css']
})
export class DonationCategoryComponent extends BaseComponent implements OnInit {
  response:DonationCategory[]

  constructor (private _DonationCategoryService:DonationCategoryService, private _router:Router) {super() }


  ngOnInit(): void {

    this.getData()
  }

  pageChanged(event:any){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    var res;
    this._DonationCategoryService.GetDonationCategoryList(this.pager).subscribe({
      next: (respnose) => {
        res = respnose;
        this.response = res.data.items;
        this.totalCount=res.data.totalCount
      },
    });
   
  }
  getData() {
    var res;
    this._DonationCategoryService.GetDonationCategoryList(this.pager).subscribe({
      next: (respnose) => {
        res = respnose;
        this.response = res.data.items;
        this.totalCount=res.data.totalCount
      },
    });
  }

  track(index, item) {
    return item.id;
  }

  Delete(id: number) {
    Swal.fire({
      title: " تحذير !",
      text: "هل انت متأكد من حذف هذا العنصر ؟ ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#7364f6',
      cancelButtonColor: "#d33",
      confirmButtonText: "حذف",
      cancelButtonText: "رجوع",
    }).then((result) => {
      if (result.isConfirmed) {
        this._DonationCategoryService.Delete(id).subscribe(
          (res) => {
            this.getData();
            Swal.fire({
              icon: "success",
              title: "تم حذف الموظف بنجاح",
              showConfirmButton: false,
              timer: 1500,
            });
          },
          (err) => {
            Swal.fire({
              icon: "error",
              title: "خطأ",
              text: err.error,
            });
          }
        );
      } else {
        // Swal.fire(
        //   'Your appointment still active ',
        // );
      }
    });

}



Update(data) {
  this._router.navigate([`/content/admin/updateDonationCategory`]);
  this._DonationCategoryService.Data.next(data);
}
}
