import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { GenericResponse } from '../Models/GenericResponse';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
import { environment } from './../../../environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class CompanyDataService {
CompanyInfo={
  CompanyId:null as any,
  CompanyName:null as any,
  CompanyColor:null as any,
  CompanyIcon:null as any
  
}
NewUpdateCustomerDonation:any
previousUrl:any;
private companyIdSource = new BehaviorSubject<number>(null);
companyId$ = this.companyIdSource.asObservable();

setCompanyId(companyId: number) {
  this.companyIdSource.next(companyId);
}
  constructor(private http:HttpClient,private router:Router) { }
  GetCompanies(dataFalse?:any): Observable<GenericResponse<any>> {
    if(dataFalse){
      return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/Company/Get?filter=false`);

    }
    else{
      return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/Company/Get`);

    }
  }
}
