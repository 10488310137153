<app-breadcrumb [title]="'قائمة مصادر  التسويق'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header px-0" style="padding-bottom: 5px;">
              <div class="row m-0" >
                  <div class="col-12 col-md-9">
                    <h5 class="py-2"> من اين عرفتنا</h5>
                    <span >  إعدادت من اين عرفتنا     ( عرض - تعديل - حذف ) </span>
                  </div>
                  <div class="col-12 col-md-3 d-flex justify-content-end align-items-center p-0">
                      <div class="addnew-btn" (click)="AddNew()" appActionNew>
                            <i class="fa fa-plus "></i> <span class="mt-1">جديد</span>
                        <span class="tooltiptext">  عن طريق </span>
                      </div>
                  </div>
              </div>
          
          </div>
          <div class="table-responsive">
            <table class="table" style="width: 100%;">
              <thead>
                <tr class="bg-dark ">
                  <th scope="col" class="font-style-header" >#</th>
                  <th scope="col" class="font-style-header" >   عن طريق  </th>
                  <th scope="col" class="font-style-header" > </th>
                </tr>
              </thead>
              <tbody >
                <tr *ngFor="let item of Response_List;let i=index">
                  <td scope="row" class="font-style" >{{i+1}}</td>
                  <td  class="font-style">{{item.title}}</td>
                  <td class="font-style " style="width: 8%; text-align: center;">
                    <button appActionEdit href=""style="color: rgba(35, 118, 241, 0.667);padding: 7px; background-color: transparent;"  (click)="update(item.id,item.order, item.title)">
                      <i class="fa fa-pencil" ></i>
                    </button>
                    <button appActionDelete href=""style="color: red; padding: 7px; background-color: transparent;"   (click)="Delete(item.id)">
                          <i class="fa fa-trash" ></i>
                     </button>
                    </td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-center">
              <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
                firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
                (pageChanged)="pageChanged($event)">
              </pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>