
export class InsertClient {
    Id?: number;
    LogoPath: string;
    cityId: number=null;
    governoratId: number=null;
    companyTypeId: number=null;
    name: string;
    mobile: string;
    address: string;
    userName : string;
    passwordHash ?:string;
    email:string;
    shortName:string;
    companyIcon: string;
    color:string;
    smsSender:string;



    // 
    // LogoPath: string;
    // cityId: number;
    // companyTypeId: number;
    // name: string;
    // mobile: string;
    // address: string;
    // userName: string;
    // passwordHash:string
    // email: string;
    // 
}


