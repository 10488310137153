<app-breadcrumb [title]="'اضافه  ميديا جديده'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'">
</app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header px-0 " style="padding-bottom: 5px;">
                    <form [formGroup]="insertForm" (ngSubmit)="Submit()">

                    <div class="row m-0 mb-2">
                        <div class="col-md-3">
                          <label for=""> الاسم
                          </label>
                            <div class="form-group">
                                
                               <input type="text"  class="form-control" formControlName="title" 
                               [tooltip]="fc.title.errors?.required ? ' الاسم مطلوب' : null" required
                               [isDisabled]="!((fc.title.errors?.required && fc.title.touched))"
                               containerClass="" [class.is-valid]="fc.title.valid && (fc.title.touched)"
                               [class.is-invalid]="fc.title.invalid && (fc.title.touched)"
                               >
                            </div>
                        </div>
                        <div class="col-md-3">
                          <label for="">الترتيب</label>
                            <div class="form-group">
                             
                               <input type="number"  class="form-control"  formControlName="order" 
                               [tooltip]="fc.order.errors?.required ? 'الترتيب مطلوب' : null" required
                               [isDisabled]="!((fc.order.errors?.required && fc.order.touched))"
                               containerClass="" [class.is-valid]="fc.order.valid && (fc.order.touched)"
                               [class.is-invalid]="fc.order.invalid && (fc.order.touched)"
                               >
                            </div>
                        </div>

                        <div class="col-12 col-md-3">
                            <label for=""> الكود</label>
                            <div class="form-group">
                                <input type="text" min="0"  class="form-control" formControlName="embedcode" 
                                [tooltip]="fc.embedcode.errors?.required ? ' الكود مطلوب' : null" required
                                [isDisabled]="!((fc.embedcode.errors?.required && fc.embedcode.touched))"
                                containerClass="" [class.is-valid]="fc.embedcode.valid && (fc.embedcode.touched)"
                                [class.is-invalid]="fc.embedcode.invalid && (fc.embedcode.touched)">
                            </div>
                        </div>
                        <div class="col-12 col-md-1">
                          <div class="form-group">
                              <label for="" >للمشروع</label>
                              <br>
                              <label class="switch">
                                        <input type="checkbox" id="forproject"  (change)="ForProjectCheck($event)" [checked]="ForProject==true"  />
                                        <span class="slider round"></span>
                                      </label>                          </div>
                                      
                      </div>
                      <div class="col-12 col-md-1">
                        <div class="form-group">
                            <label for="" >للعميل</label>
                            <br>
                            <label class="switch">
                                      <input type="checkbox" id="forcustomer" (change)="ForCustomerCheck($event)" [checked]="ForCustomer==true" />
                                      <span class="slider round"></span>
                                    </label>                          </div>
                                    
                    </div>
                        <div class="col-12 col-md-12">
                            <div *ngIf="update; else elseBlock">
                                <button class="btn btn-primary pull-right" type="submit" >
                                  تعديل
                                </button>
                              </div>
                  
                              <ng-template #elseBlock>
                                <button class="btn btn-primary pull-right" type="submit">
                                  إضافة
                                </button>
                              </ng-template>
                </div>
                </div>
                    </form>
    </div>
</div>
        </div>
    </div>
</div>