<div class="container-fluid">
    <div class="row">

        <!-- // -->
        <div class="col-md-12">
            <div class="card">
            
                <div class=" ">
                    <form [formGroup]="PermissionForm" (ngSubmit)="onSubmit()">
                        <!-- Date Donation will be -->
                        <div class="card">
                            <div class="card-body">
                                <div class="form-group">
                                    <div class="row m-0">
                                        <div class="col-6 col-md-6">
                                            <label for="name">  الاسم</label>
                                            <input class="form-control" [(ngModel)]="form.name"
                                                 type="text"
                                                placeholder="" required="required" formControlName="name">
                                        </div>
                                        <div class="col-6 col-md-6">
                                            <label> الادوار</label>
                                            <!-- <ng-select class="form-control" [(ngModel)]="form.role" required="required"
                                                name="how" placeholder="إختر الادوار" formControlName="role">
                                                <ng-option disabled>choose from items</ng-option>
                                                <ng-option [value]="role.id"
                                                    *ngFor="let role of Roles">
                                                    {{role.name}}
                                                </ng-option>
                                            </ng-select> -->

                                            <ng-multiselect-dropdown
                                            formControlName="role"
                                            id="validationCustom02=3"
                                              placeholder="أختر الدور أو أكتر من القائمة"
                                              [settings]="dropdownSettings"
                                              [(ngModel)]="form.role"
                                              [data]="Roles"                                             
                                            >
                                            <ng-option [value="{{role.id}}" *ngFor="let role of Roles" >
                                              {{ role.name }}
                                            </ng-option>
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>

                                    <div class="row m-0">
                                        <div class="col-12 col-md-12">
                                            <label for="comment"> الوصف </label>
                                            <textarea formControlName="description" [(ngModel)]="form.description"
                                                required="required" class="form-control textForArabic" placeholder="   "
                                                style="height: 120px">
                                  </textarea>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" >
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table  class="table table-striped " style="width: 100%;">
                                        <thead>
                                        <tr class="bg-dark" >
                                            <th s scope="col" style="text-align: center; width: 60%; " class="font-style-header">اسم الصفحه</th>
                                            <ng-container *ngFor="let action of Actions let i = index">
                                            <th scope="col" style="text-align: left;" class="font-style-header">{{action.name}}</th>
                                            </ng-container>
                                        </tr>
                                    </thead>

                                        <tbody>
                                            <tr  *ngFor="let menu of Menus let menuindex = index"  [ngClass]="{'mcolor': menu.parentId==null}" >
                                                <td style="text-align: center; width: 75%;" [ngClass]="{'mcolor2': menu.parentId==null}" scope="row" class="font-style">{{menu.name}}</td>
                                                <ng-container *ngFor="let action of Actions let i = index">
                                                <td style="text-align: center;" [ngClass]="{'mcolor': menu.parentId==null}"> 
                                                  <ng-container *ngFor="let actionm of menu.actions let actionmindex = index">
                                                    <input
                                                    type="checkbox"
                                                    *ngIf="action.id==actionm.actionId"
                                                    [checked]="actionm.inPermission"
                                                    (change)="onCheckboxChange($event,actionm.menuActionId,menuindex,actionmindex)" 
                                                    [id]="actionm.actionId"> 
                                                 </ng-container>

                                                    </td>
                                                </ng-container>
 
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <br>


                                <div>

                                </div>
                            </div>
                        </div>

                        
                        <div class="row m-0">
                            <!-- <button *ngIf="!editdonation" [disabled]="DonationDetails.length==0" class="btn btn-danger  btn-lg btn-block"
                            (click)="InsertCustomerDonation()">حفظ التبرع</button>
                            <button *ngIf="editdonation" [disabled]="DonationDetails.length==0" class="btn btn-danger  btn-lg btn-block"
                            (click)="UpdateCustomerDonation()"> تعديل التبرع</button> -->

                            <!-- <button  *ngIf="!editdonation" [disabled]="DonationDetails.length==0" class="btn btn-danger  btn-lg btn-block"
                            >حفظ التبرع</button>
                            <button *ngIf="editdonation" [disabled]="DonationDetails.length==0" class="btn btn-danger  btn-lg btn-block"
                            > تعديل التبرع</button> -->

                                  <button  *ngIf="!editPermission" class="btn btn-danger  btn-lg btn-block">حفظ</button>
                                  <button *ngIf="editPermission"  class="btn btn-danger  btn-lg btn-block"> تعديل </button> 
                        </div>
                    </form>
                </div>


                <div>
                </div>
            </div>
        </div>
    </div>
</div>