<app-breadcrumb
  [title]="'تقرير حالات المشروعات نقدى'"
  [items]="['Tables', 'Bootstrap Tables']"
  [active_item]="'Basic'"
>
</app-breadcrumb>

<div class="container-fluid">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6">
          <label for="validationCustom02"><span class="validation-required">*</span>  اسم الشركة</label>
        <br>
          <label *ngIf="currentUser.roleId==10">{{currentUser.name}}</label>
      <ng-select *ngIf="currentUser.roleId!=10"
      class="js-example-basic-single col-sm-12 job-select2 form-group"
      required=""
      [(ngModel)]="ProjectDonations.companyId"
      aria-label="Default select example"
      (change)="SelectCompany($event.id)"
      [items]="Companies"
      bindLabel="name"
      bindValue="id"
       >
      
      </ng-select>
        </div>
    <div class="col-12 col-md-6">
        <div class="form-group">
            <label for="">  التبرعات من تاريخ  </label>
           <input type="date"  class="form-control" [(ngModel)]="ProjectDonations.dateFrom">
        </div>
    </div>
    <div class="col-12 col-md-6">
        <div class="form-group">

            <label for="">   التبرعات الى تاريخ </label>

          
            <input type="date"  class="form-control"  [(ngModel)]="ProjectDonations.dateTo">
        </div>
   </div>  
  <div class="col-12 col-md-6">
    <label for="">نوع التبرع</label>
      <ng-select
      class="js-example-basic-single"
      aria-label="Default select example"
    [(ngModel)]="ProjectDonations.donationTypeId"
     (change)="donationTypee($event.id)"
     [items]="DonationType"
     bindLabel="title"
     bindValue="id"
    >
    </ng-select>
  </div>
 
  <div class="col-12 col-md-4">
    <label for="">النشاط</label>
      <ng-select
      class="js-example-basic-single"
      aria-label="Default select example"
      [(ngModel)]="ProjectDonations.sectorId"
      (change)="GetSectorGetById($event?.id)" 
      [items]="SectorGet"
      bindLabel="title"
      bindValue="id"
      >
    </ng-select>
  </div>
  <div class="col-12 col-md-4 ">
    <label for="">نوع النشاط</label>
      <ng-select
      class="js-example-basic-single"
      aria-label="Default select example"
      [(ngModel)]="ProjectDonations.sectorTypeId"
      (change)="GetSectorTypeGetById($event?.id)" 
      [items]="getType"
      bindLabel="title"
      bindValue="id"
      >
    </ng-select>
  </div>


  <div class="col-12 col-md-4 ">
    <label for="">موقع النشاط</label>
      <ng-select
      class="js-example-basic-single"
      aria-label="Default select example"
   [(ngModel)]="ProjectDonations.sectorLocationId"
     (change)="loaction($event.id)"
     [items]="SectorLocation"
     bindLabel="title"
     bindValue="id"
      >
    </ng-select>
  </div>
    


 
 
</div>
<div class="d-flex justify-content-end lign-items-center " >

  <button class="addnew-btn" style="margin-top: 41px;" type="submit" (click)="Viewreports()">عرض التقرير</button>
</div>
    </div>
  </div>
  
</div>

