<app-breadcrumb [title]="'إدارة موظفى خدمة العملاء'" [active_item]="'Form Wizard'"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header" style="padding-bottom:5px !important ;">
                    <div *ngIf="update; else elseBlock1">
                        <h5 class="pb-2">تعديل موظف موجود</h5>
                        يمكنك تعديل موظف أو أكثر فى قائمة موظفى خدمة العملاء
                    </div>
                    <ng-template #elseBlock1>
                        <h5 class="pb-2">إضافة موظف جديد</h5>
                        يمكنك إضافة موظف خدمة عملاء أو أكثر فى قائمة موظفى خدمة العملاء
                    </ng-template>

                </div>
                <div class="card-body">
        
          <form class="needs-validation" [formGroup]="EmployeeForm" (ngSubmit)="onSubmit()" novalidate [ngClass]="{'was-validated': validated}">


                        <!-- name -->
                        <div class="form-group">
                            <label for="name"> <span class="validation-required">*</span>  أسم الموظف</label>
                            <input class="form-control" id="name" type="text" placeholder="" required="required"
                                formControlName="name"><!-- onlyAlphabets -->

                        
                        </div>

                        <!-- username -->
                        <div class="form-group">
                            <label for="username"><span class="validation-required">*</span>   أسم المستخدم</label>
                            <input class="form-control" id="username" type="text" placeholder=" hhh"
                                required="required" formControlName="username"><!-- onlyAlphabets -->

                        
                        </div>

                        <!-- password -->
                        <div class="form-group">
                           
                            <div *ngIf="update; else elseBlock11">
                                <label for="Password"> <span class="validation-required">*</span>  تعديل كلمة المرور</label>
                            </div>
                            <ng-template #elseBlock11>
                                <label for="Password"> <span class="validation-required">*</span>  كلمة المرور</label>
                            </ng-template>
                            <input class="form-control" id="Password" type="password" placeholder="  "
                                formControlName="password">

                            <!-- <ng-container class="invalid" 
                                *ngIf="EmployeeForm.get('password').hasError('required') && EmployeeForm.get('password').touched">
                                <span style="color: red;">
                                    كلمة المرور مطلوبة
                                </span>
                            </ng-container> -->
                        </div>

                        <!-- national id -->
                        <div class="form-group">
                            <label for="nationalid"><span class="validation-required">*</span>   الرقم القومى</label>
                            <input class="form-control" id="nationalid" type="text" placeholder=""
                                required="required" formControlName="nationalId"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                maxlength="14" minlength="14">
 
                        </div>

                        <!-- mobile -->
                        <div class="form-group">
                            <label for="mobilenumber"> <span class="validation-required">*</span>  رقم الموبايل</label>
                            <input class="form-control" id="mobilenumber" type="text" placeholder="  "
                                required="required" formControlName="mobile"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                maxlength="11" minlength="11">

                
                        </div>

                        <!-- address -->
                        <div class="form-group">
                            <label for="address"> <span class="validation-required">*</span>  العنوان</label>
                            <input class="form-control textForArabic" id="address" type="text" placeholder="  "
                                required="required" formControlName="address"><!-- onlyAlphabets -->

                         
                        </div>


                        <div class="form-group">
                            <label><span class="validation-required">*</span>    إضافة عملاء  لهذا الموظف  </label>
                            <ng-multiselect-dropdown
                            formControlName="Clients"
                            required=""
                            placeholder=""
                            [settings]="dropdownSettings"
                            [data]="dropdownList"
                            [(ngModel)]="selectedItems"
                            (ngModelChange)="onItemSelect($event)"
                            (onSelectAll)="onSelectAll($event)">
                    </ng-multiselect-dropdown>
                        </div>

                        <div *ngIf="update; else elseBlock">
                            <button class="btn btn-primary pull-right" type="submit"  
                                >
                                تعديل
                            </button>
                        </div>

                        <ng-template #elseBlock>
                            <button class="btn btn-primary pull-right" type="submit"  
                         >
                                إضافة
                            </button>
                        </ng-template>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>