<app-breadcrumb [title]="'  تغير موظفى التبرعات  '" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'">
</app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header px-0 " style="padding-bottom: 5px;">
                    <div class="row m-0 mb-2">
                        <div class="col-12">
                            <h5 class="py-2">التبرعات لتغير المنشئ</h5>
                            <span > إعدادت التبرعات لتغير المنشئ الموجودة بالنظام ( عرض - تعديل - حذف ) </span>
                        </div>
                        <div class="col-12 col-md-3 mt-3">
                          <div class="form-group">
                            <label for="name">اسم الشركة</label>
                            <ng-select class="js-example-basic-single"
                                  [(ngModel)]="FilterData.CompanyId"
                                    aria-label="Default select example"
                                    required="required"
                                    [items]="Companies"
                                    bindLabel="name"
                                    bindValue="id"
                                     >
                          
                            </ng-select>
                          </div>
                          </div>
                        <div class="col-12 col-md-3 mt-3">
                            <div class="form-group">
                                <label>رقم التبرع</label>
                                <input type="text"  class="form-control" [(ngModel)]="FilterData.donationSerial">
                            </div>
                        </div>
                    
                        <div class="col-12 col-md-3 mt-3">
                            <div class="form-group">
                                <label>تاريخ من</label>
                                <input type="date"  class="form-control" [(ngModel)]="FilterData.donationDateFrom">

                            </div>
                        </div>
                        <div class="col-12 col-md-3 mt-3">
                            <div class="form-group">
                                <label>تاريخ الي</label>
                                <input type="date"  class="form-control" [(ngModel)]="FilterData.donationDateTo">
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mt-3">
                          <div class="form-group">
                              <label>بواسطه</label>
                              <ng-select  class="js-example-basic-single"
                                placeholder="بواسطه" 
                                [(ngModel)]="FilterData.donationCreatedBy"
                                [items]="AllEmployees"
                                bindLabel="name"
                                bindValue="id"
                                >
                              </ng-select>

                          </div>
                      </div>
                    
                        <div class="col-12 col-md-3 mt-3">
                          <div class="form-group">
                              <label>تاريخ المكالمة من</label>
                              <input type="date"  class="form-control" [(ngModel)]="FilterDataCallHistory.dateFrom">

                          </div>
                      </div>
                      <div class="col-12 col-md-3 mt-3">
                          <div class="form-group">
                              <label>تاريخ المكالمة الي</label>
                              <input type="date"  class="form-control" [(ngModel)]="FilterDataCallHistory.dateTo">
                          </div>
                      </div>
                      
                        <div class="col-12 col-md-3 mt-3 d-flex justify-content-end align-items-center">
                            <button class="btn btn-primary pull-right" type="button" (click)="Search()">بحث</button>

                        </div>
                    </div>
                </div>
                <ng-template #content let-modal>
                    <div class="modal-header">
                      <h4 class="modal-title" > هل تريد تغير منشئ التبرع ؟</h4>
                      <button type="button" class="close"  (click)="modal.close('Save click')"  data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                    
                      <ng-select class="js-example-basic-single"
                      aria-label="Default select example"
                      [items]="Employees"
                      bindLabel="name"
                      bindValue="id" ngDefaultControl
                    [(ngModel)]="EmployeeId"
                      >
                      </ng-select>
                     
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" (click)="modal.close('Save click')" >رجوع</button>
                      <button type="button" class="btn btn-primary"  (click)="ChangeDonationCreator();">حفظ</button>
                    </div>
                  </ng-template>
                <!-- <div class="table-responsive">
                    <table class="table" style="width: 100%;">
                        <thead>
                            <tr class="bg-dark ">
                                <th scope="col" class="font-style-header" >#</th>
                                <th scope="col" class="font-style-header" ></th>
                                <th scope="col" class="font-style-header" >رقم التبرع</th>
                                <th scope="col" class="font-style-header" >نوع التبرع</th>
                                <th scope="col" class="font-style-header" >تاريخ الانشاء</th>
                                <th scope="col" class="font-style-header" >بواسطه</th>
                                <th scope="col" class="font-style-header" >اسم العميل</th>
                                <th scope="col" class="font-style-header" >موبايل العميل</th>
                                <th scope="col" class="font-style-header" >المندوب</th>
                                <th scope="col" class="font-style-header" >حالة التبرع</th>
                                <th scope="col" class="font-style-header" > </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of DonationForChangeCreatorList; let i = index; ">
                                <td scope="row" class="font-style" >{{i+1}}</td>
                                <td scope="row" class="font-style"><img width="30px" height="30px" [src]="imgUrlServer+item.companyIcon"></td>
                                <td  class="font-style">{{item.creationDate | date:"dd/MM/yyyy"}}</td>
                                <td  class="font-style">{{item.donationSerial}}</td>
                                <td  class="font-style">{{item.donationTypeName}}</td>
                                <td  class="font-style">{{item.createdByName}}</td>
                                <td  class="font-style">{{item.customerName}}</td>
                                <td  class="font-style">{{item.mobile}}</td>
                                <td  class="font-style">{{item.collectorName}}</td>
                                <td  class="font-style">{{item.statusName}}</td>
                                <td class="font-style" style="text-align: end;width: 8%;">
                                       <button  href=""style="color: rgba(35, 118, 241, 0.667);padding: 7px; background-color: transparent;"(click)="open(content,item.id);">
                                        <i class="fa fa-pencil" ></i>
                                    </button>
                                    <button  href=""style="color: red; padding: 7px; background-color: transparent; "(click)="GoToCustomerCallHistory(item.customerId)">
                                        <i class="fa fa-history" ></i>
                                    </button>
                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br>
                    <div class="d-flex justify-content-center">
                        <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
                          firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
                          (pageChanged)="pageChanged($event)">
                        </pagination>
                      </div>
                </div> -->
                <div class="table-responsive">
                    <table class="table" style="width: 100%">
                      <thead>
                        <tr class="bg-dark ">
                            <th scope="col" class="font-style-header" >#</th>
                            <th scope="col" class="font-style-header" ></th>
                            <th scope="col" class="font-style-header" >رقم التبرع</th>
                            <th scope="col" class="font-style-header" >نوع التبرع</th>
                            <th scope="col" class="font-style-header" >تاريخ الانشاء</th>
                            <th scope="col" class="font-style-header" >بواسطه</th>
                            <th scope="col" class="font-style-header" >اسم العميل</th>
                            <th scope="col" class="font-style-header" >موبايل العميل</th>
                            <th scope="col" class="font-style-header" >المندوب</th>
                            <th scope="col" class="font-style-header" >حالة التبرع</th>
                            <th scope="col" class="font-style-header" > </th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let item of DonationForChangeCreatorList; let i = index;">
                          <tr  style="cursor:pointer; background-color: unset;" >
                            <td scope="row" class="font-style" >{{i+1}}</td>
                            <td scope="row" class="font-style"><img width="30px" height="30px" [src]="imgUrlServer+item.companyIcon"></td>
                            <td  class="font-style">{{item.donationSerial}}</td>
                            <td  class="font-style">{{item.donationTypeName}}</td>
                            <td  class="font-style">{{item.creationDate | date:"dd/MM/yyyy"}}</td>
                            <td  class="font-style">{{item.createdByName}}</td>
                            <td  class="font-style">{{item.customerName}}</td>
                            <td  class="font-style">{{item.mobile}}</td>
                            <td  class="font-style">{{item.collectorName}}</td>
                            <td  class="font-style">{{item.statusName}}</td>
                            <td class="font-style" style="text-align: end;width: 8%;" [ngStyle]="{ 'background-color': item.companyColor  }">
                           
                                   <button  href=""style="color: rgba(35, 118, 241, 0.667);padding: 7px; background-color: transparent;"(click)="open(content,item.id,item.companyId,item.createdBy);">
                                    <i class="fa fa-pencil" ></i>
                                </button>
                                <button *ngIf="item.ShowDetails" href=""style="font-size: larger padding: 7px; background-color: transparent; "(click)="OpenDetailes(i,item.customerId)">
                                  <i class="fa fa-angle-up colapse-icon" aria-hidden="true"></i>
                                </button>
                                <button *ngIf="!item.ShowDetails" href=""style="font-size: larger padding: 7px; background-color: transparent; "(click)="OpenDetailes(i,item.customerId)">
                                  <i class="fa fa-angle-down colapse-icon" aria-hidden="true"></i>
                                </button>
                                </td>
                          </tr>
                          <tr style="text-align: center;">
                            <td style="border-left: none; "></td>
                            <td style="border-left: none;border-right: none;"></td>
                            <td colspan="12" *ngIf="item.ShowDetails" style="border-right: none;">
                              <table  style="width: 102.59%;
                              margin-right: -8px;
                             margin-bottom: 10px;">
                                <thead>
                                </thead>
                                <tr class="bg-danger" style="color: black; justify-content:initial">
                                  <th scope="col" class="font-style-header" >#</th>
                                  <th scope="col" class="font-style-header" ></th>
                                  <th scope="col" class="font-style-header" >اسم العميل</th>
                                  <th scope="col" class="font-style-header" >تاريخ إنشاء المكالمة</th>
                                  <th scope="col" class="font-style-header" >سبب المكالمة</th>
                                  <th scope="col" class="font-style-header" >نوع المكالمة</th>
                                  <th scope="col" class="font-style-header" >مصدر التسويق</th>
                                  <th scope="col" class="font-style-header" >اسم موظف الشركة</th>
                                </tr>
                                <tbody >
                                  <tr *ngFor="let item of CustomerCallHistory; let i = index; " style="justify-content:initial">
                                    <td scope="row" class="font-style" >{{i+1}}</td>
                                    <td scope="row" class="font-style"><img width="30px" height="30px" [src]="imgUrlServer+item.companyIcon"></td>
                                    <td  class="font-style">{{item.customerName }}</td>
                                    <td  class="font-style">{{item.creationDate | date:"dd/MM/yyyy"}}</td>
                                    <td  class="font-style">{{item.callReasonTitle}}</td>
                                    <td  class="font-style">{{item.callTypeName}}</td>
                                    <td  class="font-style">{{item.sourceMarketName}}</td>
                                    <td  class="font-style">{{item.companyEmployeeName}}</td>
                                    <!-- <td class="font-style" style="text-align: end;width: 8%;">
                                           <button  href=""style="color: rgba(35, 118, 241, 0.667);padding: 7px; background-color: transparent;"(click)="open(content,item.id);">
                                            <i class="fa fa-pencil" ></i>
                                        </button>
                                        <button  href=""style="color: red; padding: 7px; background-color: transparent; "(click)="Delete(item.id)">
                                            <i class="fa fa-trash" ></i>
                                        </button>
                                        
                                    </td> -->
                                </tr>
                                  <!-- <tr style="justify-content:initial"
                                    *ngFor="let donation of item.details let i = index" style=" background-color: #e7e6e6;">
                                    <td class="font-style text-center" scope="row">{{i+1}}</td>
                                    <td class="font-style text-center">{{donation.donationCategoryName}}</td>
                                    <td class="font-style text-center">{{donation.donationTypeName}}</td>
                                    <td class="font-style text-center">{{donation.quantity}}</td>
                                    <td class="font-style text-center" *ngIf="receiptNumView">{{donation.receiptNum}}</td>
                                  </tr> -->
                                </tbody>
                              </table>
                              <div class="d-flex justify-content-center">
                                <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCountCallHistory" previousText="&lsaquo;" nextText="&rsaquo;"
                                  firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="maxResultCountCallHistory" [(ngModel)]="pageNumberCallHistory"
                                  (pageChanged)="pageChangedCallHistory($event)">
                                </pagination>
                              </div>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                    <br>
                    <div class="d-flex justify-content-center">
                      <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
                        firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
                        (pageChanged)="pageChanged($event)">
                      </pagination>
                    </div>

                  </div>
            </div>
        </div>
    </div>
</div>