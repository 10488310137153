<app-breadcrumb
  [title]="'إدارة العمولات'"
  [active_item]="'Form Wizard'"
></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding-bottom: 5px !important ">
            <h5 class="pb-2"> تعديل العمولات</h5>
            يمكنك  تعديل العمولات
        </div>
        <div class="card-body">
          <form class="needs-validation" [formGroup]="InsertForm" (ngSubmit)="onSubmit()" novalidate [ngClass]="{'was-validated': validated}">

            <div class="form-row">
              <div class="col-12 mb-3">

              <label for="validationCustom01">  عمولة المكالمة الناجحة</label>
              <input class="form-control" type="number" required="" formControlName="newCustomerCommissionValue" id="validationCustom01" />


             
            </div>

            </div>

            <div class="form-row">
              
              <div class="col-12 mb-3">
                <label for="validationCustom02">نسبه عمولة تبرع  جديد للعميل</label>

                <div class="input-group mb-3">
              <input class="form-control" 
              
               type="number" required="" formControlName="newCustomerDonationCommissionPercent" id="validationCustom02" />
              <div class="input-group-append">
                <span class="input-group-text">%</span>
              </div>
            </div>
 </div>
            </div>
            <div class="form-row">
                <div class="col-12 mb-3">
                  <label for="validationCustom02">نسبه عمولة تبرع  قديم للعميل</label>
                  <div class="input-group mb-3">
                <input class="form-control"   type="number" required="" formControlName="oldCustomerDonationCommissionPercent" id="validationCustom02" />
                <div class="input-group-append">
                  <span class="input-group-text">%</span>
                </div>
              </div> 
                </div>
              </div>

            <div class="form-row">
              <div class="col-12 mb-3">

              <label for="validationCustom01">اجمالي تبرعات العميل المميز</label>
              <input class="form-control" type="number" required="" formControlName="VipTotalDonations" id="validationCustom01" />


             
            </div>

            </div>
            <button
                class="btn btn-primary pull-right"
                type="button"
                (click)="onSubmit()"
              >
                تعديل
              </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

