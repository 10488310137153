<app-breadcrumb
  [title]="'إدارة أسباب المكالمات'"
  [active_item]="'Form Wizard'"
></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding-bottom: 5px !important ">
          <div *ngIf="update; else elseBlock1">
            <h5 class="pb-2">تعديل سبب موجودة</h5>
            يمكنك تعديل سبب أو أكثر فى أسباب المكالمات
          </div>
          <ng-template #elseBlock1>
            <h5 class="pb-2">إضافة سبب جديد</h5>
            يمكنك إضافة سبب أو أكثر فى أسباب المكالمات
          </ng-template>
        </div>
        <div class="card-body">
          <form class="needs-validation" [formGroup]="InsertForm" (ngSubmit)="onSubmit()" novalidate [ngClass]="{'was-validated': validated}">

            <div class="form-row">
              <div class="col-12 mb-3">

              <label for="validationCustom01">  السبب</label>
              <input
                class="form-control"
                id="validationCustom01"
                type="text"
                placeholder=""
                required=""
                formControlName="Title"
              />
              


             
            </div>

            </div>

            <div class="form-row">
              <div class="col-12 mb-3">

              <label for="validationCustom02">الترتيب</label>
              <input class="form-control" type="text" formControlName="Order" id="validationCustom02" />

              <!-- <ng-container
                class="invalid"
                style="color: red"
                *ngIf="
                  InsertForm.get('Order').hasError('required') &&
                  InsertForm.get('Order').touched
                "
              >
                الترتيب مطلوب</ng-container
              > -->
            </div> 
            </div>
            <div class="form-row">
              <div class="col-12 mb-3">
              <label for="validationCustom03"> خصص نوع العملاء لهذا السبب </label>

              <ng-multiselect-dropdown
              [formControl]="InsertForm.controls['ClientTypes']"
              id="validationCustom02=3"
                placeholder="أختر نوع عميل أو أكتر من القائمة"
                [settings]="dropdownSettings"
                
                [data]="dropdownList"
                [(ngModel)]="selectedItems"
               
              >
              <ng-option value="{{company.id}}" *ngFor="let company of dropdownList" >
                {{ company.title }}
              </ng-option>
              </ng-multiselect-dropdown>
            
            </div>
            </div>
            <div *ngIf="update; else elseBlock">
              <button
                class="btn btn-primary pull-right"
                type="submit"
              
               
              >
                تعديل
              </button>
            </div>
            <ng-template #elseBlock>
              <button
                class="btn btn-primary pull-right"
                type="submit"
              
                
              >
                إضافة
              </button>
            </ng-template>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

