import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericResponse } from '../Models/GenericResponse';
import { environment } from '../../../environments/environment.prod';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
@Injectable({
  providedIn: 'root'
})
export class MediaApiService {
  httpOptionsWithTocken = { headers: new HttpHeaders({ 'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhaG1lZGFiZHVsbGFoMjUwIiwianRpIjoiZDIwZjU0MGUtMjhiNy00YmNjLWE4ZDgtNzkxNzA2YzJmZDRhIiwiZW1haWwiOiJhaG1lZGFiZHVsbGFoQHlhaG9vLmNvbSIsInVpZCI6IjBiMzg5N2FiLTQ2ZmMtNGM0Yy04MTYyLTRiNDExZTY4OWE1NCIsInJvbGVzIjoiVVNFUiIsImV4cCI6MTYzODM2OTM3NSwiaXNzIjoiU2VjdXJlQXBpIiwiYXVkIjoiU2VjdXJlQXBpVXNlciJ9.55LorE6Fclj3buy1Qw8wZ6CEe_ifW5jxwHe25wHRWsQ', 'Accept': ' */*' }) };

  constructor(private http:HttpClient) { }

  GetMediaType(): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/MediaType/Get`);
  }
  GetProjectMediaType(): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/MediaType/GetProjectMediaType`);
  }
  GetMediaTypeById(id:any): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/MediaType/GetById?id=${id}`);
  }
  GetAccomplishmentMedia(id:any): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/AccomplishmentMedia/Get?donationDetailId=${id}`);
  }
  GetCustomerAccomplishmentMedia(id:any): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/AccomplishmentMedia/GetByDonation?donationId=${id}`);
  }
  CreateMediaType(data:any): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(`${environment.Server_URL}/MediaType/Create`,data);
  }
  CreateProjectMedia(data:any): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(`${environment.Server_URL}/ProjectMedia/Create`,data);
  }

  CreateProjectMediaPic(data:any): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(`${environment.Server_URL}/ProjectMedia/CreateFile`,data);
  }
  GetProjectMedia(id:any): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/ProjectMedia/GetProjectMedia?sectorLocationId=${id}`);
  }

  Get(id:any): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/ProjectMedia/Get?sectorLocationId=${id}`);
  }
  GetProjectMediaById(id:any): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/ProjectMedia/GetById?Id=${id}`);
  }
  CreateAccomplishmentMedia(data:any): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(`${environment.Server_URL}/AccomplishmentMedia/Create`,data);
  }
  CreateAccomplishmentMediaPic(data:any): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(`${environment.Server_URL}/AccomplishmentMedia/CreateFile`,data);
  }
  UpdateMediaType(data:any): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(`${environment.Server_URL}/MediaType/Update`,data);
  }

  DeleteProjectMedia(Id:any): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/ProjectMedia/Delete?id=${Id}`);
  }

  DeleteAccomplishmentMedia(Id:number): Observable<GenericResponseSingle<any>> {
    return this.http.get<GenericResponseSingle<any>>(`${environment.Server_URL}/AccomplishmentMedia/Delete?id=${Id}`);
  }
}
