<app-breadcrumb [title]="'قائمة المدن'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'">
</app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header px-0 " style="padding-bottom: 5px;">
                    <div class="row m-0 mb-2">
                        <div class="col-12">
                            <h5 class="py-2">المدن</h5>
                            <span > إعدادت المدن الموجودة بالنظام ( عرض - تعديل - حذف ) </span>
                        </div>
                        <div class="col-12 col-md-9 mt-3">
                            <div class="form-group">
                                <label>أختر المحافظة</label>
                                <ng-select  class="js-example-basic-single"
                                  placeholder="أختر المحافظة" (change)="SelectedGovernorate($event?.id)"
                                  [items]="Governorate_List"
                                  bindLabel="title"
                                  bindValue="id"
                                  >
                                </ng-select>
                                <!-- <ng-select class="form-control " formControlName="GovernorateId" data-live-search="true"
                                    aria-label="Default select example" (change)="SelectedGovernorate($event)">
    
                                    <ng-option value="" selected disabled>أختر المحافظة </ng-option>
                                    <ng-option value="-1">الكل </ng-option>
                                    <ng-option *ngFor="let obj of Governorate_List" value="{{ obj.id }}">
                                        <h3 class="selected-value"> {{ obj.title }} </h3>
                                    </ng-option>
                                </ng-select> -->
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mt-3 d-flex justify-content-end align-items-center p-0">
                            <div appActionNew class="addnew-btn" (click)="AddNew()">
                                <i class="fa fa-plus "></i> <span class="mt-1">جديد</span>
                                <span class="tooltiptext"> إضافة مدينة جديدة</span>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table" style="width: 100%;">
                        <thead>
                            <tr class="bg-dark ">
                                <th scope="col" class="font-style-header" >#</th>
                                <th scope="col" class="font-style-header" >أسم المحافظة</th>
                                <th scope="col" class="font-style-header" >أسم المدينة</th>
                                <th scope="col" class="font-style-header" > </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of Filtered_List; let i = index; ">
                                <td scope="row" class="font-style" >{{i+1}}</td>
                                <td  class="font-style">{{item.governoratTitle}} </td>
                                <td  class="font-style">{{item.title}}</td>
                                <td class="font-style" style="text-align: end;width: 8%;">
                                       <button appActionEdit href=""style="color: rgba(35, 118, 241, 0.667);padding: 7px; background-color: transparent;"(click)="update(item.id,item.governorateId, item.title)">
                                        <i class="fa fa-pencil" ></i>
                                    </button>
                                    <button appActionDelete href=""style="color: red; padding: 7px; background-color: transparent; "(click)="Delete(item.id)">
                                        <i class="fa fa-trash" ></i>
                                    </button>
                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br>
                    <div class="d-flex justify-content-center">
                        <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
                          firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
                          (pageChanged)="pageChanged($event)">
                        </pagination>
                      </div>
                </div>
            </div>
        </div>
    </div>
</div>