import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNgSelectValidator]'
})
export class NgSelectValidatorDirective {

  constructor(private el: ElementRef, private control: NgControl, private renderer: Renderer2) {}
  @HostListener('focus', ['$event.target'])
  onFocus() {
    this.setBorder();
  }

  @HostListener('blur', ['$event.target'])
  onBlur() {
    this.setBorder();
  }
  private setBorder() {
    if (this.control.touched) {
      const borderColorClass = this.control.invalid ? 'border-invalid' : this.control.valid ? 'border-valid' : '';
      this.updateBorderStyle(borderColorClass);
    }
  }

  private updateBorderStyle(className: string) {
    const element = this.el.nativeElement;
    const classList = element.classList;
    classList.remove('border-invalid', 'border-valid');
    if (className) {
      classList.add(className);
    }
  }
}
