import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericResponse } from '../Models/GenericResponse';
import { environment } from '../../../environments/environment.prod';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
import { getCities } from '../Models/getCities';
import { GetCallReason } from '../Models/get-call-reason';
import { InsertCall } from '../Models/InsertCall';
import { GetCall } from '../Models/GetCall';

@Injectable({
  providedIn: 'root'
})

export class CallApiService {

      //#region Declare variables
      title:string;
      order:number;
      //#endregion
      
      //#region  constructor
      constructor(private http:HttpClient) { }
      //#endregion
    
        //#region Options
        httpOptionsWithTocken = { headers: new HttpHeaders({ 'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhaG1lZGFiZHVsbGFoMjUwIiwianRpIjoiZDIwZjU0MGUtMjhiNy00YmNjLWE4ZDgtNzkxNzA2YzJmZDRhIiwiZW1haWwiOiJhaG1lZGFiZHVsbGFoQHlhaG9vLmNvbSIsInVpZCI6IjBiMzg5N2FiLTQ2ZmMtNGM0Yy04MTYyLTRiNDExZTY4OWE1NCIsInJvbGVzIjoiVVNFUiIsImV4cCI6MTYzODM2OTM3NSwiaXNzIjoiU2VjdXJlQXBpIiwiYXVkIjoiU2VjdXJlQXBpVXNlciJ9.55LorE6Fclj3buy1Qw8wZ6CEe_ifW5jxwHe25wHRWsQ', 'Accept': ' */*' }) };
        //#endregion
    
        GetCall(): Observable<GenericResponse<GetCall>> {
          return this.http.get<GenericResponse<GetCall>>(`${environment.Server_URL}/Call`);
        }
      
        InsertCall(Data ?:InsertCall): Observable<GenericResponseSingle<GetCall>> {
          return this.http.post<GenericResponseSingle<GetCall>>(`${environment.Server_URL}/Call/Create`,Data);
        }
        GetCallRequest(data:any): Observable<GenericResponseSingle<any>> {
          return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/CallRequest/GetAllPaged`,data);
        }

        
        MarkAsDone(id:any): Observable<GenericResponseSingle<any>> {
          return this.http.get<GenericResponseSingle<any>>(`${environment.Server_URL}/CallRequest/MarkAsDone?id=${id}`);
        }
        DeleteCall(Id:number): Observable<GenericResponseSingle<InsertCall>> {
          return this.http.get<GenericResponseSingle<InsertCall>>(`${environment.Server_URL}/CallReason/${Id}`);
          // change detete
        }
}
