import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericResponse } from '../Models/GenericResponse';
import { environment } from '../../../environments/environment.prod';
import { getAllCollectorEmployees } from '../Models/Get-All-Collector-Employees';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
@Injectable({
  providedIn: 'root'
})
export class GetAllCollectorEmployeesApiService {
 title:string;
  constructor(private http:HttpClient) { }
  GetCollector(): Observable<GenericResponse<getAllCollectorEmployees>> {
    return this.http.get<GenericResponse<getAllCollectorEmployees>>(`${environment.Server_URL}/Employee/GetAllCollectorEmployees`);
  }
  GetCollectorByid(id): Observable<GenericResponse<getAllCollectorEmployees>> {
    return this.http.get<GenericResponse<getAllCollectorEmployees>>(`${environment.Server_URL}/Employee//Employee/GetById?Id=${id}`);
  }
  GetCollectorByCity(cityid:string): Observable<GenericResponseSingle<any>> {
    return this.http.get<GenericResponseSingle<any>>(`${environment.Server_URL}/CollectorCity/GetAllCollectorsInCity?cityId=${cityid}`);
  }
}
