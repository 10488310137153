import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeApiService } from 'src/app/shared/API-Service/employee-api.service';
import { GenericResponse } from 'src/app/shared/Models/GenericResponse';
import { GetClient } from 'src/app/shared/Models/GetClient';
import { GetEmployee } from 'src/app/shared/Models/GetEmployee';
import { Roles } from 'src/app/shared/Models/Roles';
import Swal from 'sweetalert2';
import { SmsTemplateService } from 'src/app/shared/API-Service/sms-template.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SendSMSComponent } from '../../../send-sms/send-sms.component';
import { BaseComponent } from 'src/app/shared/components/base/base.component';

@Component({
  selector: 'app-customer-service',
  templateUrl: './customer-service.component.html',
  styleUrls: ['./customer-service.component.css']
})
export class CustomerServiceComponent extends BaseComponent implements OnInit {


  //#region  Declare Variables
  response: GenericResponse<GetEmployee>;
  Employee_List: any;
  SmsTemplates: any[];
  customerid: string;
  //#endregion

  //#region constructor
  constructor(public modalService: NgbModal,private SmsTemplateService : SmsTemplateService,private ApiService: EmployeeApiService , private router:Router) {
    super();
    //this.GetSMSTemplate();
   }
  //#endregion

  //#region  ng OnInit
  ngOnInit(): void {
    this.Employee_List = [];
    this.GetEmployee(Roles.Agent);
  }
  //#endregion
 

  SendSMSTemplate(item?){
    localStorage.removeItem("donationId");
    localStorage.removeItem("customerid");
    if(item){

    localStorage.setItem("donationId",item.donationId);
    }
    else{
      localStorage.setItem("customerid",this.customerid);
    }
      //ModalComponent is component name where modal is declare
      const modalRef = this.modalService.open(SendSMSComponent);
      modalRef.result.then((result) => {
    
      }).catch((error) => {
      
      });
  }
  //#region Consume API's

  pageChanged(event:any){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.ApiService.GetEmployeeList(this.pager).subscribe(
      response => {
        this.response = response;
        this.Employee_List = response.data;      
        this.totalCount = this.Employee_List.totalCount;
        this.Employee_List=this.Employee_List.items;  
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
    // newLocl._ShipmentService.Get(data).subscribe((res)=>{
    //   this.response=res.data;
    //   this.totalItems = this.response.totalCount;
    //   this.response=this.response.items;
    // })
  }
  //#region  Get Employee
  GetEmployee(role:string) {
    
    this.ApiService.GetEmployeeList(this.pager).subscribe(
      response => {
        this.response = response;
        this.Employee_List = response.data;      
        this.totalCount = this.Employee_List.totalCount;
        this.Employee_List=this.Employee_List.items;    
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }
  //#endregion

  //#region  Delete Employee 
  DeleteEmployee(id:string){    
    Swal.fire({
      title: ' تحذير !',
      text: "هل انت متأكد من حذف هذا العنصر ؟ ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7364f6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'حذف',
      cancelButtonText: 'إنهاء',
    })
    .then((result) => {

      if (result.isConfirmed) {
          this.ApiService.DeleteEmployee(id).subscribe(
            response=>{
              // this.GetEmployee(Roles.Agent);
               Swal.fire({
                    icon: 'success',
                    title: "تم حذف الموظف بنجاح",
                    showConfirmButton: false,
                    timer: 1500}) 
                  },
            err=>{
              Swal.fire({
                icon: 'error',
                title: 'خطأ',
                text: err.error.message,
              })
            }
          )
        
      } else {
        // Swal.fire(
        //   'Your appointment still active ',
        // );
      }
    }); 
  }
  //#endregion
  
  //#endregion

  //#region Add New Employee
  NavigateToInsert(){
    this.router.navigateByUrl("/content/admin/InsertCustomerService");
  }
  //#endregion

  //#region update Employee
  updateEmployee(id:string,model:GetEmployee){
    this.ApiService.Employee = model;
    localStorage.setItem("RiskCustomerServiceData",JSON.stringify(model));
    
    this.router.navigate(['content/admin/updateCustomerService',model.id]);
  }
  //#endregion

}
