

<!-- (click)='onSubmit()'  -->






<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding-bottom: 5px !important">
          <div *ngIf="update; else elseBlock1">
            <h5 class="pb-2">تعديل فئه تبرع موجود</h5>
            يمكنك تعديل فئه تبرع  فى قائمة   النظام
          </div>
          <ng-template #elseBlock1>
            <h5 class="pb-2">إضافة فئه تبرع جديد</h5>
            <span style="font-size: 14px">
              يمكنك إضافة فئه تبرع في   قائمة
            </span>
          </ng-template>
        </div>
        <div class="card-body">
          <form class="needs-validation" [formGroup]="InsertForm" (ngSubmit)="onSubmit()" novalidate [ngClass]="{'was-validated': validated}">
 
            <div class="form-row">
              <!-- title -->
          
              <div class="col-12 mb-3">
                <label for="validationCustom01"><span class="validation-required">*</span>  فئه التبرع العيني</label>
                <input
                  class="form-control"
                  id="validationCustom01"
                  type="text"
                  formControlName="title"
                  placeholder="اكتب فئه تبرع"
                  required=""
                  [(ngModel)]="donationMType.title" 
                />
              </div>
              <!-- selcet -->
          
              <div class="col-12 mb-3">
                <label for="validationCustom02"> <span class="validation-required">*</span>  التبرعات الرئيسيه</label>
          
                <ng-select
                class="js-example-basic-single col-sm-12 job-select2 form-group"
                  id="validationCustom02"
                  type="text"
                  required=""
                  onlyAlphabetsselected
                  [items]="items"
                  bindLabel="title" 
                  bindValue="id"
                  [(ngModel)]="donationMType.donationMainTypeId"
                  (change)="chenged($event.id)"
                  formControlName="donationMainTypeId"
                >
                </ng-select>
          
             
              </div>
          
              <!-- selcet donation Category Id -->
          
              <div class="col-12 mb-3" *ngIf="chenge">
                <label for="validationCustom03"><span class="validation-required">*</span>     فئه التبرع العيني  </label>
          
                <ng-select
                class="js-example-basic-single"
                  id="validationCustom03"
                  type="text"
                  placeholder=" "
                  
                  onlyAlphabetsselected
                  [(ngModel)]="donationMType.donationCategoryId"
                  formControlName="donationCategoryId"
                  [items]="Categorys"
                  bindLabel="title"
                  bindValue="id"
                >
                </ng-select>
              </div>
         
          
  
            </div>
          
            <div class="form-group">
              <div class="form-check form-checked">
                <div class="checkbox p-0">
                  <input
                    class="form-check-input"
                    id="invalidCheck"
                    type="checkbox"
                    [(ngModel)]="donationMType.isNotify"
                    formControlName="isNotify"
                  />
                  <label class="form-check-label" for="invalidCheck">
                    خاصيه التنبيه
                  </label>
                </div>
          
              </div>
            </div>
          
            <ng-template #elseBlock>
              <button class="btn btn-primary pull-right" type="submit" >إضافة</button>
            </ng-template>
            <div *ngIf="update; else elseBlock">
              <button class="btn btn-primary pull-right" type="submit">تعديل</button>
            </div>
            </form>
           
        </div>
      </div>
    </div>
  </div>
</div>













 







 



<!-- 
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding-bottom: 5px !important">
          <div *ngIf="update; else elseBlock1">
            <h5 class="pb-2">تعديل النوع موجود</h5>
            يمكنك تعديل النوع  فى قائمة   النظام
          </div>
          <ng-template #elseBlock1>
            <h5 class="pb-2">إضافة النوع جديد</h5>
            <span style="font-size: 14px">
              يمكنك إضافة النوع   قائمة
            </span>
          </ng-template>
        </div>
        <div class="card-body">
          <form   [formGroup]="InsertForm" novalidate [ngClass]="{'was-validated': false}">
            <div class="form-group">
              <label for="validationCustom01" class="type-label">النوع</label>
              <input id="validationCustom01" type="text" placeholder="First name" required="" onlyAlphabets [(ngModel)]="donationMType.title"   class="form-control"  formControlName="title"
                id="title" />
              <div class="valid-feedback">Looks good!</div>
            </div>

            <div class="form-group">
              <label for="exampleFormControlSelect1" class="type-label">معرف الشخص المحدد</label>
              <ng-select class="form-control" id="exampleFormControlSelect1" selected
                [(ngModel)]="donationMType.donationMainTypeId" (change)="chenged($event)"
                formControlName="donationMainTypeId"
                >
                <ng-option disabled></ng-option>
                <ng-option *ngFor="let item of items; let i = index" [ngValue]="item.id">
                  {{ item.title }}
                </ng-option>
              </ng-select>
            </div>

            <div class="form-group" *ngIf="chenge">
              <label for="exampleFormControlSelect1" class="type-label">فئة التبرع
              </label>
              <ng-select class="form-control" id="exampleFormControlSelect1" selected
              formControlName="donationCategoryId"
                [(ngModel)]="donationMType.donationCategoryId">
                <ng-option disabled></ng-option>
                <ng-option *ngFor="let item of Categorys; let i = index" [ngValue]="item.id">
                  {{ item.title }}
                </ng-option>
              </ng-select>
            </div>


            <div class="form-group form-checked">
              <input type="checkbox" class="bg-dark text-info" [(ngModel)]="donationMType.isNotify"
              formControlName="isNotify"
                id="flexCheckDefault" />

              <label for="flexCheckDefault"> خاصيه التنبيه </label>
            </div>



            <ng-template #elseBlock>
              <button class="btn btn-primary pull-right" type="submit"  (click)="onSubmit()" >
                إضافة
              </button>
            </ng-template>
            <div *ngIf="update; else elseBlock">
              <button class="btn btn-primary pull-right" type="submit"  (click)="onSubmit()">
                تعديل
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div> -->

