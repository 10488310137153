import { Component, OnInit } from '@angular/core';
import { stringify } from '@angular/compiler/src/util';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SectorLocationApiService } from 'src/app/shared/API-Service/sector-location-api.service';
import { getSectorType } from 'src/app/shared//Models/get-sector-type';
import {insertSectorLocation,UpdateSectorLocation  } from 'src/app/shared//Models/InsertSectorLocation';
import Swal from 'sweetalert2';
import { SectorTypeApiService } from '../../../../../shared/API-Service/sector-type-api.service';
import { EmployeeApiService } from 'src/app/shared/API-Service/employee-api.service';
import { SectorsApiService } from 'src/app/shared/API-Service/sectors-api.service';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';

@Component({
  selector: 'app-insert-sector-location',
  templateUrl: './insert-sector-location.component.html',
  styleUrls: ['./insert-sector-location.component.css']
})
export class InsertSectorLocationComponent implements OnInit {
  InsertForm: FormGroup; 
  maxDate: Date;
  update: boolean;
  Sector_Type_Dictionary:{[Id:number]:string} = {}
  Sector_Type_List:any[];
  Sector_Type_id:number;
  SectorType:string;
  returnedSectorLocationObj:any ={}
  SectorLocationInfo: any;
  Sectortypeid: any;
  inactive:boolean ;
  Companies: any[];
  Sector_List:any;
  CompanyId: any;
  constructor(private _formBuilder: FormBuilder,
    private toaster: ToastrService,
    private sectorLocationApiService:SectorLocationApiService,
    private EmployeeApiService:EmployeeApiService,
    private sectorTypeApiService: SectorTypeApiService,
    private router: Router,
    private route: ActivatedRoute,
    private sectorsApiService: SectorsApiService,
    private CompanyDataService :CompanyDataService) {
      this.inactive=false;
      this.maxDate = new Date();
      this._InitForm();
      if(this.route.snapshot.paramMap.get('id')){
        //   this.sectorTypeApiService.getSectorType(null,null,null,null).subscribe(
        //   (response:any) => {
        //     this.Sector_Type_List = response.data;
           
        //     // response.data.forEach(element => {
        //     //   this.Sector_Type_Dictionary[element.id] = element.title;            
        //     // });
        //   },
        //   err => {
        //     // Swal.fire({
        //     //   icon: 'error',
        //     //   title: 'خطأ',
        //     //   text: err.error,
        //     // })
        //   }
        // )
        this.update=true;
    this.sectorLocationApiService.GetSectorLocationById(Number(this.route.snapshot.paramMap.get('id'))).subscribe(res=>{
        this.SectorLocationInfo=res.data;        
        this.CompanyId=this.SectorLocationInfo.companyId
      this.getSector(this.SectorLocationInfo.companyId)
      this.getSectorType(this.SectorLocationInfo.sectorId);
        this.InsertForm.patchValue({
          SectorId:this.SectorLocationInfo.sectorId
          })
          this.InsertForm.patchValue({
            CompanyId:this.SectorLocationInfo.companyId
            })
        this.Sectortypeid=this.SectorLocationInfo.sectorTypeId;
        this.inactive=this.SectorLocationInfo.inactive;
      })
      }
      else{
        this.update=false;
      }
  
    }

    containsNumber(str) {
      const specialChars = /[/\d/٠١٢٣٤٥٦٧٨٩]/;
      return specialChars.test(str);
    }
    //#endregion
    containsSpecialChars(str) {
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      return specialChars.test(str);
    }

   
  ngOnInit(): void {
    this.Sector_Type_id=-1;
    this.GetCompanies();
    this.sectorLocationApiService.title = localStorage.getItem("SectorTypetitle");
    this.sectorLocationApiService.sectorTypeId = +localStorage.getItem("sectorTypeId")
    if (this.route.snapshot.paramMap.get('id')) {
     
      this.InitForm(this.sectorLocationApiService.title)
      this.InsertForm.patchValue({
        SectorTypeId:this.sectorLocationApiService.sectorTypeId
        })
        this.InsertForm.patchValue({
          employeeId:this.sectorLocationApiService.employeeId
          })
      

       
      this.update = true;
    } else {
      this.update = false;
      this._InitForm();
      this.SectorType = "أختر نوع النشاط ";
    }
  }
  inactiveChange(){
    if(this.inactive){
      this.inactive=false;
    }
    else{
          this.inactive=true;
    }   

  }
  InitForm(title: string) {
    this.InsertForm = this._formBuilder.group({
      Title: [title, Validators.required],
      CompanyId:["", Validators.required],
      SectorId:["", Validators.required],
      SectorTypeId: [-1, Validators.nullValidator],
    });
  }
  _InitForm() {
    this.InsertForm = this._formBuilder.group({
      Title: ['', Validators.required],
      CompanyId:["", Validators.required],
      SectorId:["", Validators.required],
      SectorTypeId: ['', Validators.nullValidator]
    });
  }
  SelectCompany(CompanyId){
    this.InsertForm.get("SectorId").reset()
    this.InsertForm.get("SectorTypeId").reset()
    this.CompanyId=CompanyId
    this.getSector(CompanyId);
  }
  GetCompanies(){
    this.CompanyDataService.GetCompanies().subscribe(res=>{
      this.Companies=res.data
    })
  }
  getSector(CompanyId?:any) {
    this.sectorsApiService.getSectors(CompanyId).subscribe(
      response => {
        
        this.Sector_List = response.data;
        // response.data.forEach(element => {
        //   this.Governorate_List[element.id] = element.title;
        // });
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }
  SelectedSector(event: any){
    this.InsertForm.get("SectorTypeId").reset()
    this.getSectorType(event)
  }
  getSectorType(sectorId) {
    this.sectorTypeApiService.getSectorType(sectorId,this.CompanyId).subscribe(
      response => {
        this.Sector_Type_List = response.data;
        // response.data.forEach(element => {
        //   this.Sector_Type_Dictionary[element.id] = element.title;            
        // });
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }

  InsertSectorLocation() {
    // console.log("this.InsertForm.get('GovernorateId') : ",this.InsertForm.get('GovernorateId').value);
    // if(this.containsSpecialChars(this.InsertForm.get("Title").value)==true){
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'خطأ',
    //     text: "لا يمكن كتابه علامات ",
    //   })
    // }
    // else if (this.containsNumber(this.InsertForm.get("Title").value)==true){
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'خطأ',
    //     text: "لا يمكن كتابه ارقام ",
    //   })
    // }
  //  else
    if(this.Sector_Type_id == -1){
      Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: "أختر نوع النشاط أولا",
      })
    }
    else
    {
      let data ={
        title: this.InsertForm.get('Title').value,
        sectorTypeId:this.Sector_Type_id,
        inactive:this.inactive
      }
      this.sectorLocationApiService.InsertSectoeLocation(data as insertSectorLocation).subscribe(
        response => {
          Swal.fire({
            icon: 'success',
            title: "تم إدخال نوع النشاط بنجاح",
            showConfirmButton: false,
            timer: 1500
          })
          this.router.navigateByUrl("content/admin/Get-sector-Location");
        },
        err => {
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: err.error.message,
          })
        }
      )
    }
  }
  //#region Selected Sector Type
  SelectedSectorType(event:any){
    this.Sector_Type_id = event;
  }
  
  UpdateSectorLocation() {
    // if(this.containsSpecialChars(this.InsertForm.get("Title").value)==true){
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'خطأ',
    //     text: "لا يمكن كتابه علامات ",
    //   })
    // }
    // else if (this.containsNumber(this.InsertForm.get("Title").value)==true){
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'خطأ',
    //     text: "لا يمكن كتابه ارقام ",
    //   })
    // }

    // else {
        let Id = +this.route.snapshot.paramMap.get('id');
 
   // console.log('Title : ',this.InsertForm.get('Title').value);
     this.sectorLocationApiService.GetSectorLocationById(Id).subscribe(
      respnse=>{respnse.data}
     )
     if(this.route.snapshot.paramMap.get('id')&&this.Sector_Type_id==-1){
      this.Sector_Type_id =this.SectorLocationInfo.sectorTypeId;
     }
     let data ={
      id: Id,
      title: this.InsertForm.get('Title').value,
      sectorTypeId:this.Sector_Type_id,
      inactive:this.inactive
    }
    this.sectorLocationApiService.UpdateSectoeLocation(data as UpdateSectorLocation).subscribe(
      response => {
        Swal.fire({
          icon: 'success',
          title: "تم تعديل موقع النشاط  بنجاح",
          showConfirmButton: false,
          timer: 1500,
          
        })
        this.router.navigateByUrl("content/admin/Get-sector-Location");
        localStorage.removeItem("SectorTypetitle");
        localStorage.removeItem("sectorTypeId");
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
    }
  
  // }

  }
  


