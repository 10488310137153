import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import {OfficesService} from 'src/app/shared/API-Service/offices.service'
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-list-offices',
  templateUrl: './list-offices.component.html',
  styleUrls: ['./list-offices.component.css']
})
export class ListOfficesComponent extends BaseComponent implements OnInit {
  Offices: any;

  constructor(private router: Router,private OfficesService:OfficesService) { super()
  
    
  }

  ngOnInit(): void {
  this.GetOfficesList();
  }

  AddNew(){
    this.router.navigateByUrl("content/admin/InsertOffice");
  }
  pageChanged(event:any){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;

    this.OfficesService.GetOfficesList(this.pager).subscribe(
      (res:any) => {
        this.Offices=res.data.items;
        this.totalCount = res.data.totalCount;
        
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
   
  }
  update(id){
    this.router.navigate([`content/admin/update-office`, id]);
  }
  GetOfficesList(){
    this.OfficesService.GetOfficesList(this.pager).subscribe((res:any)=>{
      this.Offices=res.data.items
      this.totalCount=res.data.totalCount;
    })
  }
  Delete(id: number) {
    Swal.fire({
      title: ' تحذير !',
      text: "هل انت متأكد من حذف هذا العنصر ؟ ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7364f6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'حذف',
      cancelButtonText: 'إنهاء',
    })
      .then((result) => {

        if (result.isConfirmed) {
          
          this.OfficesService.DeleteOffice(id).subscribe(
            response => {
              this.GetOfficesList();
              Swal.fire({
                icon: 'success',
                title: "تم حذف المكتب بنجاح",
                showConfirmButton: false,
                timer: 1500
              })
            },
            err => {
              Swal.fire({
                icon: 'error',
                title: 'خطأ',
                text: err.error.message,
              })
            }
          )

        } else {
          // Swal.fire(
          //   'Your appointment still active ',
          // );
        }
      });
  }
}
