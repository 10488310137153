import { GetCustomerDonations } from './../Models/get-customer-donations';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
import { environment } from '../../../environments/environment.prod';
import { CustomerDonations } from '../Models/Customer-Donations';
import { EditCustomerDonations } from '../Models/Edit-Customer-Donations';

@Injectable({
  providedIn: 'root'
})

export class DontaionsApiService {

  //#region Declare variables
  title: string;
  CustomerData: any;
  mobile: string;
  //#endregion

  //#region  constructor
  constructor(private http: HttpClient) { }
  //#endregion

  //#region Options
  httpOptionsWithTocken = { headers: new HttpHeaders({ 'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhaG1lZGFiZHVsbGFoMjUwIiwianRpIjoiZDIwZjU0MGUtMjhiNy00YmNjLWE4ZDgtNzkxNzA2YzJmZDRhIiwiZW1haWwiOiJhaG1lZGFiZHVsbGFoQHlhaG9vLmNvbSIsInVpZCI6IjBiMzg5N2FiLTQ2ZmMtNGM0Yy04MTYyLTRiNDExZTY4OWE1NCIsInJvbGVzIjoiVVNFUiIsImV4cCI6MTYzODM2OTM3NSwiaXNzIjoiU2VjdXJlQXBpIiwiYXVkIjoiU2VjdXJlQXBpVXNlciJ9.55LorE6Fclj3buy1Qw8wZ6CEe_ifW5jxwHe25wHRWsQ', 'Accept': ' */*' }) };
  //#endregion


  InsertCustomrDonation(form: any): Observable<GenericResponseSingle<CustomerDonations>> {
    return this.http.post<GenericResponseSingle<CustomerDonations>>(`${environment.Server_URL}/Donation/Create`, form);
  }
  InsertCustomrDonationForm(form: any): Observable<CustomerDonations> {
    return this.http.post<CustomerDonations>(`${environment.Server_URL}/Donation/CreateFormData`, form);
  }
  UpdateCustomrDonation(form: any): Observable<GenericResponseSingle<EditCustomerDonations>> {
    return this.http.post<GenericResponseSingle<EditCustomerDonations>>(`${environment.Server_URL}/Donation/UpdateFormData`, form);
  }
  GetDonationsMainType(): Observable<GenericResponseSingle<any>> {
    return this.http.get<GenericResponseSingle<any>>(`${environment.Server_URL}/DonationMainType/Get`);
  }
  GetDonationTypesInDonationMainType(DonationMainTypeId): Observable<GenericResponseSingle<any>> {
    return this.http.get<GenericResponseSingle<any>>(`${environment.Server_URL}/DonationType/GetDonationTypesInDonationMainType?donationMainTypeId=${DonationMainTypeId}`);
  }
  GetCustomerProjectDonation(data): Observable<GenericResponseSingle<any>> {
    return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/Donation/GetCustomerDonation`, data);
  }
  GetDonationByid(DonationId): Observable<GenericResponseSingle<any>> {
    return this.http.get<GenericResponseSingle<any>>(`${environment.Server_URL}/Donation/GetById?id=${DonationId}`);
  }
  GetLookupsDonationStatus(): Observable<GenericResponseSingle<any>> {
    return this.http.get<GenericResponseSingle<any>>(
      `${environment.Server_URL}/Lookups/GetDonationStatus`
    );
  }
  DeleteDonationImage(donationId:string): Observable<GenericResponseSingle<any>> {
    return this.http.get<GenericResponseSingle<any>>(`${environment.Server_URL}/DonationImage/Delete?donationId=${donationId}`);
    // change detete
  }
  

  GetCollectedClosedDonations(data: any): Observable<GenericResponseSingle<any>> {
    return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/Donation/GetCollectedClosedDonations`, data);
  }
  getSearch(data: GetCustomerDonations): Observable<GenericResponseSingle<any>> {
    return this.http.get<GenericResponseSingle<any>>(`${environment.Server_URL}/Customer/GetCustomerDonations?donationMainType=${data.donationMainType}&mobile=${data.mobile}&receiptNum=${data.receiptNum}`);
  }

}
