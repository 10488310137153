import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GovernorateApiService } from 'src/app/shared/API-Service/governorate-api.service';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { GenericResponse } from 'src/app/shared/Models/GenericResponse';
import { GetGovernorate } from 'src/app/shared/Models/GetGovernorate';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-governorate',
  templateUrl: './list-governorate.component.html',
  styleUrls: ['./list-governorate.component.css']
})
export class ListGovernorateComponent extends BaseComponent implements OnInit {

  //#region  Declare Variables
  response: GenericResponse<GetGovernorate>;
  Response_List:any;

  //#endregion

  //#region constructor
  constructor( private governorateApiService:GovernorateApiService , private router:Router) {super(); }
  //#endregion

  //#region  ng OnInit
  ngOnInit(): void {
    this.Response_List = [];
    this.getGovernoate();
  }
  //#endregion

  //#region Consume API's
  track(index, item) {
    return item.id;
  }
  pageChanged(event:any){
    var res
    let newLocl =this;
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;

    this.governorateApiService.GetGovernorateList(this.pager).subscribe(
      response => {
        this.response = response;
        this.Response_List = response.data;
          this.totalCount = this.Response_List.totalCount;
       this.Response_List=this.Response_List.items;
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
    // newLocl._ShipmentService.Get(data).subscribe((res)=>{
    //   this.response=res.data;
    //   this.totalItems = this.response.totalCount;
    //   this.response=this.response.items;
    // })
  }
  //#region  get Governoate
  getGovernoate() {
    this.governorateApiService.GetGovernorateList(this.pager).subscribe(
      response => {
        this.response = response;
        this.Response_List = response.data;
        this.totalCount = this.Response_List.totalCount;
       this.Response_List=this.Response_List.items;
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }
  //#endregion

  //#region  Delete Governoate
  Delete(id:number){
    Swal.fire({
      title: ' تحذير !',
      text: "هل انت متأكد من حذف هذا العنصر ؟ ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7364f6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'حذف',
      cancelButtonText: 'إنهاء',
    })
    .then((result) => {

      if (result.isConfirmed) {
          this.governorateApiService.DeleteGovernorate(id).subscribe(
            response=>{
              this.getGovernoate();
               Swal.fire({
                    icon: 'success',
                    title: "تم حذف نوع المحافظة بنجاح",
                    showConfirmButton: false,
                    timer: 1500}) 
                    this.router.navigateByUrl("content/admin/Get-governorate");
                  },
            err=>{
              Swal.fire({
                icon: 'error',
                title: 'خطأ',
                text: err.error.message,
              })
            }
          )
        
      } else {
        // Swal.fire(
        //   'Your appointment still active ',
        // );
      }
    }); 
  }
  //#endregion
  
  //#endregion

  //#region AddNew
  AddNew(){
    this.router.navigateByUrl("content/admin/insert-governorate");
  }
  //#endregion

  //    old 

  //#region Governoate
  // update(id:number,title:string){
    // this.governorateApiService.title = title;
    // localStorage.setItem("riskgovernorate",title)
    // this.router.navigate(['content/admin/update-governorate',id]);

    
    update( id:number,title:string){

      this.governorateApiService.title = title;
      localStorage.setItem("riskgovernorate",title)
     this.router.navigate([`content/admin/update-governorate/${id}`]);
  }
  //#endregion

}
