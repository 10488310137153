import { Component, OnInit } from '@angular/core';
import { SourceOfmarketingDonationsReport } from '../../../../shared//Models/sourcemarket-donations-report';
import { SourceOfmarketingService } from './../../../../shared/API-Service/source-ofmarketing.service';
import { Router } from '@angular/router';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-sourcemarket-donations-report',
  templateUrl: './sourcemarket-donations-report.component.html',
  styleUrls: ['./sourcemarket-donations-report.component.css']
})
export class SourcemarketDonationsReportComponent implements OnInit {
  ReportsInfo = new SourceOfmarketingDonationsReport()
  getsourcemarket: any[];
  currentUser: any;
  Companies: any[];
  constructor(private CompanyDataService:CompanyDataService, public datepipe:DatePipe,private SourceOfmarketingService:SourceOfmarketingService,   private router: Router,
 ) {
      this.currentUser=JSON.parse(localStorage.getItem("RiskCurrentUser"))
      if(this.currentUser.roleId==10){
        this.ReportsInfo.companyId=this.currentUser.id
      }
      this.ReportsInfo.reportCreatedBy=this.currentUser.name;
      const date = new Date();
      this.ReportsInfo.dateFrom=this.datepipe.transform(date, 'yyyy-MM-dd');
      this.ReportsInfo.dateTo=this.datepipe.transform(date,'yyyy-MM-dd');

     }
  ngOnInit(): void {
    this.getsourcemarketing();
    this.GetCompanies();
  }
  SelectCompany(CompanyId){
    this.ReportsInfo.companyId=parseInt(CompanyId);
  }
  GetCompanies(){
    this.CompanyDataService.GetCompanies().subscribe(res=>{
      this.Companies=res.data
    })
  }
  getsourcemarketing(){
    this.SourceOfmarketingService.GetAllsourceOfMarketingForReport().subscribe(response=>{
     
          this.getsourcemarket = response.data
    })
  }
  selectSourceMarket(event){
    this.ReportsInfo.sourceMarketId = parseInt(event) 
    this.ReportsInfo.sourceMarketName = this.getsourcemarket.find(x => x.id == event).title

  }
  Viewreports(){
    if(this.ReportsInfo.companyId!=null&&this.ReportsInfo.dateFrom &&this.ReportsInfo.dateTo){
      if(this.ReportsInfo.sourceMarketId ==null){
      this.ReportsInfo.sourceMarketId=null
      this.ReportsInfo.sourceMarketName=''
     
    }
    localStorage["ReportName"]="SourceMarketClosedDonations";
    localStorage.setItem('ReportFilter',JSON.stringify(this.ReportsInfo));
  
    this.router.navigate([]).then((result) => {
      window.open("/content/admin/call-start-end-report", '_blank');
    });

    }
    
    else{
      if(!this.ReportsInfo.companyId){
        Swal.fire({
          icon:'error',
          title:'خطأ',
          text:'يجب اختيار الشركة'
    
        })
      }
    else   if (!this.ReportsInfo.dateFrom){
        Swal.fire({
          icon:'error',
          title:'خطأ',
          text:' يجب اختيار بدايه تاريخ المكالمات'
        })
      }
      else  if (!this.ReportsInfo.dateTo ){
        Swal.fire({
          icon:'error',
          title:'خطأ',
          text:' يجب اختيار نهايه تاريخ المكالمات'
    
        })
      }
    

   
  }
}
}