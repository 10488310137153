import { Component, OnInit, ViewChild } from '@angular/core';
import { ReceiptBookService } from '../../../../shared/API-Service/receipt-book.service';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-collector-receipts-edit',
  templateUrl: './collector-receipts-edit.component.html',
  styleUrls: ['./collector-receipts-edit.component.css']
})
export class CollectorReceiptsEditComponent extends BaseComponent implements OnInit {
  SelectCollector: boolean;
  CollectorId: any;
  CollectorReceipts: any[] = [];
  AllCollectorReceipts: any[] = [];
  ReceiptsIds: any[] = [];
  totalCountCollectorBooks: any;
  @ViewChild('ctdTabset') ctdTabset;
  tabs = [];
  @ViewChild('tabset', { static: true }) tabset: any;
  CollectorName: string;
  Receipts: any;
  ReceiptsAllBooks: any;
  closeResult: string;
  totalCountReceipts: any;
  ReceiptspageNumber: any;
  CollectorBookspageNumber:any;
  AllBookspageNumber:any;
  activeTabId: any;
  AddTab: boolean;
  EditTab: boolean;
  constructor(private modalService: NgbModal, private ReceiptBookService: ReceiptBookService, private route: ActivatedRoute) {
    super()
    this.CollectorId = this.route.snapshot.paramMap.get("id")
    this.CollectorName = localStorage.getItem("CollectorName");
  

  }
  onTabChange($event: any) {
    if($event.nextId=="ngb-tab-0"){
      this.AddTab=true
      this.EditTab=false
    }
    else{
      this.AddTab=false
      this.EditTab=true
    }

    this.activeTabId = $event.nextId;
   
  }
  ngOnInit(): void {
    this.GetAllBooks();
    this.GetCollectorBooks();

  }
  pageChangedAllBooks(event: any) {
       this.AllBookspageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.AllBookspageNumber - 1) * this.pager.maxResultCount;
    this.pager.collectorId = null;
    this.pager.receiptBookId = null;
    this.pager.receiptStatusId =null;
    this.ReceiptBookService.GetFreeReceipts(this.pager).subscribe((res: any) => {
      this.AllCollectorReceipts = res.data.items;
      this.totalCount = res.data.totalCount;

    })
    
   
  }

  pageChangedCollectorBooks(event: any) {
    this.CollectorBookspageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.CollectorBookspageNumber - 1) * this.pager.maxResultCount;
    this.pager.collectorId = this.CollectorId
    this.pager.receiptStatusId =null;
    this.pager.receiptBookId = null;
    this.ReceiptBookService.GetAllBooks(this.pager).subscribe((res: any) => {
      this.CollectorReceipts = res.data.items;
      this.totalCountCollectorBooks = res.data.totalCount;
    })
  }

  pageChangedReceipts(event: any) {
    this.ReceiptspageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.ReceiptspageNumber - 1) * this.pager.maxResultCount;
    
    // if(this.AddTab){
    //   this.pager.collectorId = null;
    // }
    // else{
    //   this.pager.collectorId = this.CollectorId;
    // }
    this.ReceiptBookService.GetReceipts(this.pager).subscribe((res:any) => {
      this.Receipts = res.data.items;
      this.totalCountReceipts=res.data.totalCount;

    })
    
    
    
  }
  Delete(item) {
    const id = item.id
    var Deletetext= "هل انت متأكد من حذف الايصالات المتاحة من هذا الدفتر من المندوب  "+this.CollectorName+' ؟'

    Swal.fire({
      title: " تحذير !",
      text: Deletetext,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#7364f6',
      cancelButtonColor: "#d33",
      confirmButtonText: "حذف",
      cancelButtonText: "رجوع",
    }).then((result) => {
      if (result.isConfirmed) {
        this.ReceiptBookService.DeleteCollectorReceipt(id, this.CollectorId).subscribe(
          (res) => {
            //  this.GetCollectorReceipts(this.CollectorId);
            this.pager.skipCount=0;
            Swal.fire({
              icon: "success",
              title: "تم حذف الايصال بنجاح",
              showConfirmButton: false,
              timer: 1500,
            });
            this.GetCollectorBooks();
            this.GetAllBooks();
            if(this.CollectorReceipts.length==0){
              this.GetCollectorBooks();
            }
            if(this.AllCollectorReceipts.length==0){
              this.GetAllBooks();
            }
          },
          (err) => {
            Swal.fire({
              icon: "error",
              title: "خطأ",
              text: err.error.message,
            });
          }
        );
      } else {
        // Swal.fire(
        //   'Your appointment still active ',
        // );
      }
    });
  }
  GetAllBooks() {
    this.totalCountCollectorBooks=1;
    
    this.pager.collectorId = null;
    this.pager.receiptBookId = null;
    this.pager.receiptStatusId =null;
    this.ReceiptBookService.GetFreeReceipts(this.pager).subscribe((res: any) => {
      this.AllCollectorReceipts = res.data.items;
      this.totalCount = res.data.totalCount;

    })
  }
  GetCollectorBooks() {
    this.pager.collectorId = this.CollectorId
    this.pager.receiptBookId = null;
    this.pager.receiptStatusId =null;
    this.ReceiptBookService.GetAllBooks(this.pager).subscribe((res: any) => {
      this.CollectorReceipts = res.data.items;
      this.totalCountCollectorBooks = res.data.totalCount;
    })
  }
  GetReceiptsAllBooks(id, receiptStatusId) {
    this.Receipts=[]
    this.pageNumber=1;
    this.pager.skipCount=0;
   this.pager.receiptBookId = id;
   this.pager.receiptStatusId = receiptStatusId;
    this.pager.collectorId = null;
    this.ReceiptBookService.GetReceipts(this.pager).subscribe((res:any) => {
      this.Receipts = res.data.items;
      this.totalCountReceipts=res.data.totalCount;

    })
  }
  GetReceiptsCollectorBooks(id, receiptStatusId) {
    this.Receipts=[]
    this.pageNumber=1;
    this.pager.skipCount=0;
    this.pager.receiptBookId = id;
    this.pager.receiptStatusId = receiptStatusId;
    this.pager.collectorId = this.CollectorId
    this.ReceiptBookService.GetReceipts(this.pager).subscribe((res:any) => {
      this.Receipts = res.data.items;
      this.totalCountReceipts=res.data.totalCount;

    })
  }

  openReceiptsAllBooks(content, id, receiptStatusId) {
    this.pager.skipCount=0;
    this.ReceiptspageNumber=1;
    this.GetReceiptsAllBooks(id, receiptStatusId);
    this.modalService.open(content, {centered:true, ariaLabelledBy: 'modal-basic-title' ,size:'xl'}).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }
  openReceiptsCollectorBooks(content, id, receiptStatusId) {
    this.pager.collectorId=this.CollectorId
    this.pager.skipCount=0;
    this.ReceiptspageNumber=1
    
    this.GetReceiptsCollectorBooks(id, receiptStatusId);

    this.modalService.open(content, {centered:true, ariaLabelledBy: 'modal-basic-title' ,size:'xl'}).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }
  getDismissReason(reason?: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  CreateCollectorReceipts(item) {
    var createtext= "هل انت متأكد من إضافه الايصالات المتاحة من هذا الدفتر الي المندوب  "+this.CollectorName+' ؟'
    Swal.fire({
      title: " تحذير !",
      text: createtext,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#7364f6',
      cancelButtonColor: "#d33",
      confirmButtonText: "إضافة",
      cancelButtonText: "رجوع",
    }).then((result) => {
      if (result.isConfirmed) {
        this.ReceiptsIds = [];
        this.ReceiptsIds.push(item.id)
        const data =
        {
          "collectorId": this.CollectorId,
          "receiptBooks": this.ReceiptsIds

        }
        this.ReceiptBookService.CreateCollectorReceipts(data).subscribe(res => {
          Swal.fire({
            icon: "success",
            title: "تمت الاضافه بنجاح",
            showConfirmButton: false,
            timer: 1500,
          });
          this.pager.skipCount=0;
           this.AllBookspageNumber=1
          this.GetAllBooks();
          this.GetCollectorBooks();
          // if(this.CollectorReceipts.length==0){
          //   this.GetCollectorBooks();
          // }
          // if(this.AllCollectorReceipts.length==0){
          //   this.GetAllBooks();
          // }
        }, err => {
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: err.error.message,
          })
        })

      } else {
        // Swal.fire(
        //   'Your appointment still active ',
        // );
      }
    });

  }
}
