import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {MediaApiService} from 'src/app/shared/API-Service/media-api.service'
import { BaseComponent } from 'src/app/shared/components/base/base.component';
@Component({
  selector: 'app-media-type',
  templateUrl: './media-type.component.html',
  styleUrls: ['./media-type.component.css']
})
export class MediaTypeComponent extends BaseComponent implements OnInit {
  MediaTypes: any;
  pageNumber:any;
  totalCount:any;
  constructor(private MediaApiService :MediaApiService,private router:Router) { 
    super();

  }

  ngOnInit(): void 
  {
    this.GetMediaType();
  }
  GetMediaType(){
    this.MediaApiService.GetMediaType().subscribe(res=>{
     
      this.MediaTypes=res.data;
      this.MediaTypes= this.MediaTypes.sort((a, b) => a.order - b.order);
    })
  }

  pageChanged(e){

  }
  AddNew(){
    this.router.navigate([`content/admin/insert-media-types`]);
  }

  Update(id){
    this.router.navigate([`content/admin/update-media-types`, id]);
  }
}
