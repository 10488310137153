import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericResponse } from '../Models/GenericResponse';
import { GetGovernorate } from '../Models/GetGovernorate';
import { environment } from '../../../environments/environment.prod';
import { InsertGovernorate, UpdateGovernorate } from '../Models/InsertGovernorate';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
import { GetJob } from '../Models/GetJob';

@Injectable({
  providedIn: 'root'
})
export class JobApiService {

  constructor(private http : HttpClient) { }
  GetJob(): Observable<GenericResponse<GetJob>> {
    return this.http.get<GenericResponse<GetJob>>(`${environment.Server_URL}/Job/get`);
  }
}
