import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {CommissionSetting} from '../../../shared//Models/CommissionSetting'
import Swal from 'sweetalert2';
import { CommissionSettingService } from 'src/app/shared/API-Service/commission-setting.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-commission-setting',
  templateUrl: './commission-setting.component.html',
  styleUrls: ['./commission-setting.component.css']
})
export class CommissionSettingComponent implements OnInit {
  InsertForm: FormGroup;
  CommissionSettingData=new CommissionSetting()
  validated: boolean;
  CommissionSetting: any;
  newCustomerDonationCommissionPercent:any
  newCustomerDonationCommissionPercentValue: any;
  oldCustomerDonationCommissionPercentValue: any;
  constructor( private route: ActivatedRoute,private router: Router,private _formBuilder: FormBuilder,private CommissionSettingService:CommissionSettingService) {
    this.InitForm();
   }
  ngOnInit(): void {
    this.CommissionSettingService.GetCommissionSetting().subscribe(async (res:any)=>{
    this.CommissionSetting=  await res.data;

    
    this.InitForm(this.CommissionSetting);
    })
  }

  InitForm(CommissionSetting?:CommissionSetting) {
    this.InsertForm = this._formBuilder.group({
    newCustomerCommissionValue: [CommissionSetting?.newCustomerCommissionValue||0, Validators.required],
    newCustomerDonationCommissionPercent: [parseFloat((CommissionSetting?.newCustomerDonationCommissionPercent *100).toFixed(3)).toString()||0, Validators.required],
    oldCustomerDonationCommissionPercent: [parseFloat((CommissionSetting?.oldCustomerDonationCommissionPercent * 100).toFixed(3)).toString()||0, Validators.required],
    VipTotalDonations:  [CommissionSetting?.vipcustomerDonationsAmount||0, Validators.required],
  });
    }
    get fc() {
      return this.InsertForm.controls;
    }
  onSubmit() {
    if (this.InsertForm.status == "VALID") {
    this.validated = false;
  
    this.CreateOrUpdateCommissionSetting();
    
    } else {
    this.validated = true;
    }
    }

    CreateOrUpdateCommissionSetting(){
      const data ={
        "newCustomerCommissionValue": this.InsertForm.get('newCustomerCommissionValue').value,
        "newCustomerDonationCommissionPercent": this.InsertForm.get('newCustomerDonationCommissionPercent').value / 100,
        "oldCustomerDonationCommissionPercent": this.InsertForm.get('oldCustomerDonationCommissionPercent').value / 100,
        "vipcustomerDonationsAmount": this.InsertForm.get('VipTotalDonations').value
      }
      this.CommissionSettingService.CreateOrUpdate(data).subscribe(
        (response) => {
        Swal.fire({
        icon: "success",
        title: "تم الحفظ بنجاح",
        showConfirmButton: false,
        timer: 1500,
        });
   //     this.router.navigateByUrl("content/admin/GetOffices");
  
      },
        (err) => {
          Swal.fire({
            icon: "error",
            title:err.error.message
            });
        }
              );
    }
   

}
