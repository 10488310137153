<app-breadcrumb [title]="'قائمة موظفى خدمة العملاء'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header px-0" style="padding-bottom: 5px;">
              <div class="row m-0" >
                  <div class="col-12 col-md-9">
                    <h5 class="py-2">موظفى خدمة العملاء </h5>
                    <span >  إعدادت موظفى خدمة العملاء الموجودة بالنظام   </span>
                  </div>
                  <div class="col-12 col-md-3 d-flex justify-content-end align-items-center p-0">
                      <div class="addnew-btn" (click)="NavigateToInsert()">
                          
                            <i class="fa fa-plus "></i> <span class="mt-1">جديد</span>
                        <span class="tooltiptext">  إضافة موظف جديد</span>
                      </div>
                  </div>
              </div>
          
          </div>
          <div class="table-responsive">
            <table class="table table-striped" style="width: 100%;">
              <thead>
                <tr class="bg-dark ">
                  <th scope="col" class="font-style-header" >#</th>
                  <th scope="col" class="font-style-header" >أسم الموظف</th>
                  <th scope="col" class="font-style-header" >أسم المستخدم</th>
                  <th scope="col" class="font-style-header" >رقم الموبايل</th>
                  <th scope="col" class="font-style-header" >الرقم القومى</th>
                  <!-- <th scope="col" class="font-style-header" >العنوان</th> -->
                  <th scope="col" class="font-style-header" > </th>
                </tr>
              </thead>
              <tbody >
                <tr *ngFor="let item of Employee_List; let i = index;">
                  <td scope="row" class="font-style" >{{i+1}}</td>
                  <td  class="font-style">{{item.name}}</td>
                  <td  class="font-style">{{item.userName}}</td>
                  <td class="font-style" style="text-align: center;">{{item.mobile}}
                    <br>
                    <div ngbDropdown class="social-media" style="padding: 5px;">
                      <a ngbDropdownToggle (click)="$event.stopPropagation()"><i class="fa fa-phone" style="color: green;"></i></a>
                      <div ngbDropdownMenu aria-labelledby="dropdownConfig" >
                        <a  (click)="$event.stopPropagation()"[href]="'tel:' + item.mobile" ngbDropdownItem>{{item.mobile}}</a>
                            <ng-container  *ngFor="let mobile of item.mobiles">
                              <a (click)="$event.stopPropagation()" [href]="'tel:' + mobile" ngbDropdownItem>{{mobile}}</a>
                            </ng-container>
                      </div>                      
                     <a [href]="'//wa.me/+2' + item.mobile" target="_blank"><i class="fa fa-whatsapp" style="padding: 0 17px; color: #2cc198;" ></i></a>               
                     <i class="fa fa-comment" (click)="SendSMSTemplate(item)"></i>

        
                    </div>
                  </td> 
                  <td  class="font-style">{{item.nationalId}}</td>
                  <!-- <td  class="font-style">{{item.address}}</td> -->

                  <td class="font-style chose" >

                    <a style="color: rgba(35, 118, 241, 0.667);padding: 7px" (click)="updateEmployee(item.id,item)">
                      <i class="fa fa-pencil" ></i>
                    </a>
                      <a href="http://" style="color:red ;padding: 7px" (click)="DeleteEmployee(item.id)">
                      <i class="fa fa-trash" ></i>
                      </a>
                 
                </td>
                </tr>
              </tbody>
            </table>
            <br>
            <div class="d-flex justify-content-center">
              <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
                firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
                (pageChanged)="pageChanged($event)">
              </pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>