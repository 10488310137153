export class ProjectDonationsTypesReport {
    donationTypeId:number=null as any
	companyId:number=null as any
	sectorId:number=null as any
	sectorTypeId:number=null as any
	sectorLocationId:number=null as any
	reportCreatedBy:string='';
	donationTypeName:string='';
	sectorName:string='';
	sectorLocationName:string='';
	sectorTypeName:string='';
	dateFrom:string='';
	dateTo:string='';
}