
export class DonationsReports {
   creationDateFrom:string = ''
   creationDateTo:string = ''
   collectionDateFrom:string = ''
   collectionDateTo:string = ''
   companyId:number=null
   donationStatus:number=null
   donationStatusName:string=''
   donationTypeId:number=null
   donationTypeName:string=''

   sectorId:number=null
   sectorName:string=''
   sectorTypeId:number=null
   sectorTypeName:string=''
   
   sectorLocationId:number=null
   sectorLocationName:string=''

   collectorId:number=null
   collectorName:string=''

   customerName:string=''

   customerMobile:string=''

  
   customerGender:number=null
   customerGenderName:string=''

   customerGovernoratId:number=null
   customerGovernoratName:string=''

   customerCityId:number=null
   custmerCityName:string=''
   

   sourceMarketId:number=null
   sourceMarketName:string=''
   createdBy: number=null
   createdByName: string=''
   reportCreatedBy:string=''

}



