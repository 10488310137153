<app-breadcrumb [title]="'مصادر تسويق العميل'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'">
</app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header px-0 " style="padding-bottom: 5px;">
                    <div class="row m-0 mb-2">
                        <div class="col-12 col-md-6">
                            <label for=""> مصدر الاعلان</label>
                            <div class="form-group">

                            <ng-select
                            class="js-example-basic-single"
                            aria-label="Default select example"
                            [(ngModel)]="ReportsInfo.sourceOfMarketingId"
                            [items]="getsourcemarket"
                            (change)="SelectedsourceOfMarketing($event.id)"
                            bindLabel="title"
                            bindValue="id"
                            >
                          </ng-select>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                          <label for=""> المحافظه</label>
                          <div class="form-group">

                          <ng-select
                          class="js-example-basic-single"
                          aria-label="Default select example"
                          [(ngModel)]="ReportsInfo.governoratId"
                          (change)="SelectedGovernorate($event.id)"
                          [items]="Governorate_List"
                          bindLabel="title"
                          bindValue="id"
                          >
                        </ng-select>
                          </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <label for=""> المدينه</label>
                        <div class="form-group">

                        <ng-select
                        class="js-example-basic-single"
                        aria-label="Default select example"
                        [(ngModel)]="ReportsInfo.cityId"
                        (change)="Selectedcity($event.id)"
                        [items]="City_list"
                        bindLabel="title"
                        bindValue="id"
                        >
                      </ng-select>
                        </div>
                    </div>
                        <div class="col-12 col-md-6">
                            <label for=""> سبب المكالمه</label>
                            <div class="form-group">

                            <ng-select
                            class="js-example-basic-single"
                            aria-label="Default select example"
                            [(ngModel)]="ReportsInfo.callReasonId"
                            [items]="CallReason_List"
                            (change)="SelectedcallReason($event.id)"
                            bindLabel="title"
                            bindValue="id"
                            >
                          </ng-select>
                            </div>
                        </div>

                        

                       
                        <div class="col-12 col-md-12">
                    <div class="d-flex justify-content-end align-items-center buttom-text">
                      <button class="addnew-btn" type="submit" (click)="Viewreports()">عرض التقرير</button>
                    </div>
                </div>
                </div>
    </div>
</div>
        </div>
    </div>
</div>