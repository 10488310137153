import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Server_URL } from 'src/environments/environment';
import { GenericResponse } from '../Models/GenericResponse';
import { LoginResponse } from '../Models/LoginResponse';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RiskAuthenticationService {
  isAuthenticated = false;
 
  token = localStorage.getItem('RiskAuthorization');
  
  constructor(private http: HttpClient, private router: Router) {
    this.isAuthenticated =this.token!=null?true:false
  }
  
  login(data: any): Observable<any> {
    return this.http.post(`${Server_URL}/User/login`, data, {
      headers: new HttpHeaders({ Authorization: `Bearer ${this.token}` }),
    });
    
    
  }

  logout() {
    this.isAuthenticated = false;
    localStorage.removeItem("token");
    this.router.navigate(['']);
    

  }
  getIsAuthenticated(): boolean {
    return this.isAuthenticated;
  }
  getToken() {
    return localStorage.getItem('RiskAuthorization');
  }

  RequestLogin(obj:any): Observable<GenericResponse<LoginResponse>> {
    return this.http.post<GenericResponse<LoginResponse>>(`${Server_URL}/user/Login`,obj);
  }
  ChangeEmpPass(obj:any): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(`${Server_URL}/User/ResetPassword`,obj);
  }

  verifyLogin(obj: any): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(
      `${Server_URL}/User/VefiryOTP`,
      obj
    );
  }
}