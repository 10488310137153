import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DontaionsApiService } from 'src/app/shared/API-Service/donations-api-service';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ProjectMedia } from '../../../../shared//Models/ProjectMedia';
import {MediaApiService} from 'src/app/shared/API-Service/media-api.service'
import { SectorTypeApiService } from 'src/app/shared/API-Service/sector-type-api.service';
import { CustomerApiService } from 'src/app/shared/API-Service/customer-api.service';
import { environment } from '../../../../../environments/environment.prod';
import {Clipboard} from '@angular/cdk/clipboard';
import { ToastrService } from 'ngx-toastr';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';

@Component({
  selector: 'app-accomplishmentmedia-customer',
  templateUrl: './accomplishmentmedia-customer.component.html',
  styleUrls: ['./accomplishmentmedia-customer.component.css']
})
export class AccomplishmentmediaCustomerComponent implements OnInit {

  CustomerProjectDonation: any;
  CustomerId: any;
  mobile:any;
  donationDetalisId: any;
  closeResult: string;
  insertForm: FormGroup;
  MediaType = new ProjectMedia()
  id: any;
  update: boolean;
  MediaTypes: any;
  SectorType: any;
  images: any[]=[];
  imagesView: string | ArrayBuffer;
  haveImage: boolean;
  CreatMediaProjectPicForm: any = new FormData();
  customerinfo: any;
  newAccomplishment:boolean
  AccomplishmentImage: any[]=[];
  SectorTypeName: any;
  sectorTypeId: any;
  receiptNum:any;
  donationSerial:any;
  donationDetailSerial:any;
  ShowPhoto: boolean;
  mediaTypeId: any;
  accomplishmentMedia: any[]=[];
  constructor(private CompanyDataService:CompanyDataService,private toastr: ToastrService,private clipboard: Clipboard,private CustomerApiService:CustomerApiService,private SectorTypeApiService:SectorTypeApiService,
    private MediaApiService:MediaApiService,
     private router: Router,private route: Router,
     private _formBuilder: FormBuilder,public modalService: NgbModal,
     private DontaionsApiService :DontaionsApiService,private ActivatedRoute:ActivatedRoute) {
    this.newAccomplishment=false
    }

  ngOnInit(): void {
    if(this.ActivatedRoute.snapshot.params.id){
      this.id = this.ActivatedRoute.snapshot.params.id;
      //this.CompanyDataService.NewUpdateCustomerDonation=true
      this.getAccomplishmentMedia(this.id)

    }
    this.initForm();
  }
  New(){
    this.newAccomplishment=true;
  }
  
  get fc() {
    return this.insertForm.controls;
  }
  initForm() {
    this.insertForm = this._formBuilder.group({
      title: [ '', Validators.required],
      order: ['', Validators.required],
    });
  }
  open(content,item) {
    this.AccomplishmentImage=[];
    this.donationDetalisId=item.id;
    this.SectorTypeName=item.sectorTypeName;
    this.sectorTypeId=item.sectorTypeId;
    this.getAccomplishmentMedia(item.id);
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true,size: 'xl'  }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  private getDismissReason(reason: any): string {
  
    if (reason === ModalDismissReasons.ESC) {

      this.insertForm.reset();
      this.donationDetalisId="";
      this.newAccomplishment=false;
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.insertForm.reset();
      this.donationDetalisId="";
      this.newAccomplishment=false;
      return 'by clicking on a backdrop';
    } else {
      this.insertForm.reset();
      this.donationDetalisId="";
      this.newAccomplishment=false;
      return `with: ${reason}`;
    }
  }

  modalclose(){
    this.insertForm.reset();
    this.donationDetalisId="";
    this.newAccomplishment=false;
    this.modalService.dismissAll();
  }
 
  

  copyUrl(url) {
    this.clipboard.copy(url);
    this.toastr.success('تم نسخ الرابط');
  }
 
  getAccomplishmentMedia(donationId){
    this.MediaApiService.GetCustomerAccomplishmentMedia(donationId).subscribe((res:any)=>{
      this.accomplishmentMedia=res.data.accomplishmentMedia;
      this.accomplishmentMedia.forEach(element => {
        element.media.forEach(media => {
          media.url=environment.Server_URL_Image+'/'+media.url
        });
      });

    })
  } 
     onFileSelected(event, element) {
       // this.element = element
     //   this.haveImage = true;
       let filesAmount = event.target.files.length;
       let files = event.target.files;
   
       var reader = new FileReader();
       // this.imagePath = files;   
       const video = "video";
       if(files[0].type.includes(video)){
         this.ShowPhoto=false;
       }
       else{
        this.ShowPhoto=true;
       }
       reader.readAsDataURL(files[0]);
       reader.onload = (_event) => {
         this.images.push(files[0]);
         this.imagesView = reader.result;
       }
   
   
     }
   
     deleteimage(imgindex) {
       this.images.splice(imgindex, 1);
       this.imagesView=null
   
     }
     deleteAccomplishmentMedia(id:any){
      
      Swal.fire({
        title: " تحذير !",
        text: "هل انت متأكد من حذف هذه الصورة  ؟ ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: '#7364f6',
        cancelButtonColor: "#d33",
        confirmButtonText: "حذف",
        cancelButtonText: "رجوع",
      }).then((result) => {
        if (result.isConfirmed) {
          this.MediaApiService.DeleteAccomplishmentMedia(id).subscribe(
            (res) => {
              this.getAccomplishmentMedia(this.donationDetalisId)
              Swal.fire({
                icon: "success",
                title: "تم الحذف بنجاح",
                showConfirmButton: false,
                timer: 1500,
                });
            },
            (err) => {
              Swal.fire({
                icon: "error",
                title: "خطأ",
                text: err.error,
              });
            }
          );
        } else {
          // Swal.fire(
          //   'Your appointment still active ',
          // );
        }
      });
      

     }
     OpenImage(imagesView){
       Swal.fire({
         imageUrl: imagesView,
         imageHeight: 400,
         imageAlt: 'A tall image',
         confirmButtonText:"الغاء"
       })
     }
     GetMediaType(){
       this.MediaApiService.GetMediaType().subscribe(res=>{
        
         this.MediaTypes=res.data;
         this.MediaTypes= this.MediaTypes.sort((a, b) => a.order - b.order);
       })
     }
   
     GetSectorType(){
       this.SectorTypeApiService.getSectorType().subscribe(res=>{
         this.SectorType=res.data
       })
     }




  Submit(){
      if (this.insertForm.status == "VALID") {
        this.InsertAccomplishmentMediaPic();
    }
     else {
      this.insertForm.markAllAsTouched();
    }
  }


 
  InsertAccomplishmentMediaPic(){
    if(!this.ShowPhoto){
      this.mediaTypeId=11
    }
    else{
      this.mediaTypeId=7
    }
    this.images.forEach(element => {
      this.CreatMediaProjectPicForm.append('Image', element)
    });
    this.CreatMediaProjectPicForm.append('title',this.insertForm.get('title').value)
    this.CreatMediaProjectPicForm.append('order',this.insertForm.get('order').value)
    this.CreatMediaProjectPicForm.append('mediaTypeId',this.mediaTypeId)
    this.CreatMediaProjectPicForm.append('sectorTypeId', Number(this.sectorTypeId))
    this.CreatMediaProjectPicForm.append('donationDetailId', this.donationDetalisId)
    this.MediaApiService.CreateAccomplishmentMediaPic(this.CreatMediaProjectPicForm).subscribe(
      (response:any) => {
      Swal.fire({
      icon: "success",
      title: "تم الحفظ بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.AccomplishmentImage.push({"id":response.data.id,"url":this.imagesView,"order":this.insertForm.get('order').value,"title":this.insertForm.get('title').value,"mediaTypeId":this.mediaTypeId})
      this.insertForm.reset();
      this.CreatMediaProjectPicForm.delete('Image')
    this.CreatMediaProjectPicForm.delete('title')
    this.CreatMediaProjectPicForm.delete('order')
    this.CreatMediaProjectPicForm.delete('mediaTypeId')
    this.CreatMediaProjectPicForm.delete('sectorTypeId')
    this.CreatMediaProjectPicForm.delete('donationDetailId')
      this.imagesView=null;
      this.images=[];
      this.haveImage=false;
   //   this.modalService.dismissAll()
      // this.router.navigateByUrl("content/admin/media-types");

    },
      (err) => {
        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
            );
  }

}
