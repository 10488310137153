import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericResponse } from '../Models/GenericResponse';
import { GetEmployee } from '../Models/GetEmployee';
import { environment } from '../../../environments/environment.prod';
import { InsertEmployee,GetEmployeeById } from '../Models/InsertEmployee';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
import { UpdateClient } from '../Models/UpdateClient';
import { Assign_ClientCustomer } from '../Models/Assign_ClientCustomer';
import { ResponseRegister } from '../Models/ResponseRegister';
import { IdName } from '../Models/IdName';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class EmployeeApiService {

     //#region Declare variables
     Employee:GetEmployee;

     //#endregion
     //#region  constructor
     
     constructor(private http:HttpClient) { }
     //#endregion
   
   
       GetEmployee(companyId?): Observable<GenericResponse<GetEmployee>> {
        if(!companyId){
       return this.http.get<GenericResponse<GetEmployee>>(`${environment.Server_URL}/Employee/get`);
        }
     else{
      return this.http.get<GenericResponse<GetEmployee>>(`${environment.Server_URL}/Employee/Get?companyId=${companyId}`);

     }
       }

      
       GetProjectManagerEmployees(): Observable<GenericResponse<GetEmployee>> {
        return this.http.get<GenericResponse<GetEmployee>>(`${environment.Server_URL}/Employee/GetProjectManagerEmployees`);
      }

GetProjectManagerEmployeesaWait(): Promise<GenericResponse<GetEmployee>> {
  return new Promise<GenericResponse<GetEmployee>>((resolve, reject) => {
    this.http.get<GenericResponse<GetEmployee>>(`${environment.Server_URL}/Employee/GetProjectManagerEmployees`)
      .pipe(
        map(response => {
          resolve(response);
        })
      )
      .subscribe(
        () => {},
        error => {
          reject(error);
        }
      );
  });
}

       GetEmployeeList(data:any): Observable<GenericResponse<GetEmployee>> {
        return this.http.post<GenericResponse<GetEmployee>>(`${environment.Server_URL}/Employee/GetAllEmployeeList`,data);
      }
     
       GetClientRelated(): Observable<GenericResponse<Assign_ClientCustomer>> {
         return this.http.get<GenericResponse<Assign_ClientCustomer>>(`${environment.Server_URL}/Employee/GetEmployeeCompanies`);
       }
       
       GetEmployeeById(Id:number) :Observable<InsertEmployee> {
        return this.http.get<InsertEmployee>(`${environment.Server_URL}/Employee/GetById?id=${Id}`);
      }
       


       GetClientsRelatedWithAgent(id:string): Observable<GenericResponse<IdName>> {
         return this.http.get<GenericResponse<IdName>>(`${environment.Server_URL}/CustomerService/GetClientsRelatedWithAgent?AgentID=${id}`);
       }
     
       InsertEmployee(form:any): Observable<ResponseRegister> {
         return this.http.post<ResponseRegister>(`${environment.Server_URL}/Employee/Create`,form);
       }

       InsertEmployees(data:InsertEmployee): Observable<InsertEmployee> {
        return this.http.post<InsertEmployee>(`${environment.Server_URL}/Employee/Create`,data)
      }

       AssignCustomerToClient(data:Assign_ClientCustomer[]): Observable<GenericResponseSingle<InsertEmployee>> {
         return this.http.post<GenericResponseSingle<InsertEmployee>>(`${environment.Server_URL}/CustomerService`,data);
       }
 
       UpdateEmployee(form:any): Observable<GenericResponseSingle<ResponseRegister>> {
         return this.http.post<GenericResponseSingle<ResponseRegister>>(`${environment.Server_URL}/Employee/Update`,form);
       }
   
       DeleteEmployee(id:string): Observable<GenericResponseSingle<InsertEmployee>> {
         return this.http.get<GenericResponseSingle<InsertEmployee>>(`${environment.Server_URL}/Employee/Delete?id=${id}`);
         // change detete
       }
}
