import { SectorLocationApiService } from './../../../../shared/API-Service/sector-location-api.service';
import { SectorTypeApiService } from './../../../../shared/API-Service/sector-type-api.service';
import { Router } from '@angular/router';
import { SourceOfmarketingService } from './../../../../shared/API-Service/source-ofmarketing.service';
import { SourceMarketApiService } from './../../../../shared/API-Service/source-market-api.service';
import { CollectorReports } from '../../../../shared//Models/collector-reports';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-collector-donations',
  templateUrl: './collector-donations.component.html',
  styleUrls: ['./collector-donations.component.css']
})
export class CollectorDonationsComponent implements OnInit {
  collectorReports= new CollectorReports()
  GetLookupsGetDonation
  DonationType
  SectorGet
  getType
  SectorLocation
  constructor(private SourceOfmarketingService:SourceOfmarketingService,private router:Router,private SectorTypeApiService:SectorTypeApiService,private SectorLocationApiService:SectorLocationApiService) { }

  ngOnInit(): void {
    this.GetLookupsGetDonationStatu()
    this.GetDonationType()
    this.GetSector()
  }
  GetLookupsGetDonationStatu(){
    this.SourceOfmarketingService.GetLookupsGetDonationStatu().subscribe(response=>{
  
      this.GetLookupsGetDonation = response.data
    })
  }

  GetDonationType(){
    this.SourceOfmarketingService.GetDonationType().subscribe(response=>{
        this.DonationType = response.data
      
    })
  }

  GetSector(){
    this.SourceOfmarketingService.GetSector().subscribe(res=>{
     this.SectorGet = res.data
    
    })
 }

 GetSectorGetById(event:any){
  if (this.collectorReports.sectorId==null) {
    this.collectorReports.sectorId=null
    this.collectorReports.sectorName=''
   
   } 
   this.collectorReports.sectorTypeId = null
   this.collectorReports.sectorTypeName=''
   this.collectorReports.sectorLocationId = null
   this.collectorReports.sectorLocationName = ''
   this.SectorTypeApiService.getSectorType(this.collectorReports.sectorId).subscribe(response=>{

    this.getType = response.data
  })

  this.collectorReports.sectorId = parseInt(event)
  this.collectorReports.sectorName = this.SectorGet.find(x => x.id == event).title
}

GetSectorTypeGetById(event:any){

  if (this.collectorReports.sectorTypeId==null) {
    this.collectorReports.sectorTypeId=null
    this.collectorReports.sectorTypeName=''
   
   } 
   this.collectorReports.sectorLocationId = null
   this.collectorReports.sectorLocationName = ''

   this.SectorLocationApiService.getSectorLocation(this.collectorReports.sectorTypeId).subscribe((response:any)=>{

   this.SectorLocation = response.data
 })

 this.collectorReports.sectorTypeId = parseInt(event)
  this.collectorReports.sectorTypeName = this.getType.find(x => x.id == event).title
}


  donationStatus(event){
    this.collectorReports.donationStatus = parseInt(event) 
    this.collectorReports.donationStatusName = this.GetLookupsGetDonation.find(x => x.id == event).title
}

donationTypee(event){
  this.collectorReports.donationTypeId = parseInt(event) 
  this.collectorReports.donationTypeName = this.DonationType.find(x => x.id == event).title

 }

 loaction(event){
  this.collectorReports.sectorLocationId = parseInt(event) 
  this.collectorReports.sectorLocationName = this.getType.find(x => x.id == event).title
 }


  

  Viewreports(){

if (this.collectorReports.donationStatus==null) {
  this.collectorReports.donationStatus =null
  this.collectorReports.donationStatusName=''

}


if (this.collectorReports.donationTypeId==null) {
  this.collectorReports.donationTypeId =null
  this.collectorReports.donationTypeName=''
}

if (this.collectorReports.sectorId ==null ) {
  this.collectorReports.sectorId=null 
  this.collectorReports.sectorName=''
}

if(this.collectorReports.sectorTypeId==null){
  this.collectorReports.sectorTypeId=null
  this.collectorReports.sectorTypeName=''
}

if(this.collectorReports.sectorLocationId==null){
  this.collectorReports.sectorLocationId=null
  this.collectorReports.sectorLocationName=''

}
    localStorage["ReportName"]="DonationsReport";
    localStorage.setItem('ReportFilter',JSON.stringify(this.collectorReports));
  
    this.router.navigate([]).then((result) => {
      window.open("/content/admin/call-start-end-report", '_blank');
    });
  }
}
