<div *ngIf="LoadPage">
<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" > اضافة الانجاز</h4>
      <button type="button" class="close"  (click)="modalclose()"  data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-12">
              <button *ngIf="!newAccomplishment" class="btn btn-success" appActionNew (click)="New()">جديد</button>
                <div *ngIf="newAccomplishment" class="card">
                    <div class="card-header px-0 " style="padding-bottom: 5px;">
                        <form [formGroup]="insertForm" (ngSubmit)="Submit()">
    
                        <div class="row m-0 mb-2">
                            <div class="col-md-6">
                              <label for=""> الاسم
                              </label>
                                <div class="form-group">
                                    
                                   <input type="text"  class="form-control" formControlName="title" 
                                   [tooltip]="fc.title.errors?.required ? ' الاسم مطلوب' : null" required
                                   [isDisabled]="!((fc.title.errors?.required && fc.title.touched))"
                                   containerClass="" [class.is-valid]="fc.title.valid && (fc.title.touched)"
                                   [class.is-invalid]="fc.title.invalid && (fc.title.touched)"
                                   >
                                </div>
                            </div>
                            <!-- <div class="col-md-6">
                                <label for=""> الميديا
                                </label>
                                  <div class="form-group">
                                      
                                   
          <ng-select class="js-example-basic-single"
          aria-label="Default select example"
          formControlName="mediaTypeId" 
          (change)="getMediaId($event)"
          [items]="MediaTypes"
          bindLabel="title"
          bindValue="id" ngDefaultControl
          [tooltip]="fc.mediaTypeId.errors?.required ? ' الميديا مطلوب' : null" required
          [isDisabled]="!((fc.mediaTypeId.errors?.required && fc.mediaTypeId.touched))"
          containerClass="" [class.is-valid]="fc.mediaTypeId.valid && (fc.mediaTypeId.touched)"
          [class.is-invalid]="fc.mediaTypeId.invalid && (fc.mediaTypeId.touched)"
          >
          </ng-select>
                                  </div>
                              </div> -->
                              <div class="col-md-6">
                                <label for=""> المشروع
                                </label>
                                  <div class="form-group">
                                    <label for=""> {{SectorTypeName}}
                                    </label>    

                                  </div>
                              </div>
                            <div class="col-md-6">
                              <label for="">الترتيب</label>
                                <div class="form-group">
                                 
                                   <input type="number"  class="form-control"  formControlName="order" 
                                   [tooltip]="fc.order.errors?.required ? 'الترتيب مطلوب' : null" required
                                   [isDisabled]="!((fc.order.errors?.required && fc.order.touched))"
                                   containerClass="" [class.is-valid]="fc.order.valid && (fc.order.touched)"
                                   [class.is-invalid]="fc.order.invalid && (fc.order.touched)"
                                   >
                                </div>
                            </div>
                            <div  class="col-md-6">
                            <button  [disabled]="disabled"  type="button" class="btn btn-primary  addbtn w-100 h-50 mt-4 " color="primary" (click)="fileInput.click()">
                              <span>Upload Media</span>
                              <input #fileInput type="file"   
                              accept="video/*,image/*" (change)="onFileSelected($event,i)" style="display:none;" />
                            </button>  
                            </div>
                            <div class="row m-0" > 
                              <div class="image-area" style="margin: 8px;"  >
                                
                                  <img  *ngIf="imagesView && ShowPhoto" src="{{imagesView}}"  height="100" width="100"  (click)="OpenImage(imagesView)">
                                  <video *ngIf="imagesView && !ShowPhoto" width="320" height="240" controls>
                                    <source src="{{imagesView}}" type="video/mp4">
                                    Your browser does not support the video tag.
                                  </video>
                                </div>
                                
                                <a *ngIf="imagesView" class="remove-image" (click)="deleteimage(i)" style="display: inline;">&#215;</a>

                      </div>
                    

                            <!-- <div *ngIf="!haveImage" class="col-12 col-md-6">
                                <label for=""> الرابط</label>
                                <div class="form-group">
                                    <textarea type="text" min="0"  class="form-control" formControlName="url" 
                                    [tooltip]="fc.url.errors?.required ? ' الرابط مطلوب' : null" required
                                    [isDisabled]="!((fc.url.errors?.required && fc.url.touched))"
                                    containerClass="" [class.is-valid]="fc.url.valid && (fc.url.touched)"
                                    [class.is-invalid]="fc.url.invalid && (fc.url.touched)"></textarea>
                                </div>
                            </div> -->
                    </div>
                        </form>  
                        <div *ngIf="Loader"  style="height: 0px !important; text-align: start !important;" class="loader-box">
                          <div  class="loader-3" ></div>
                        </div>
                        <br>
                              <button type="button" class="btn btn-secondary float-left mr-3" [disabled]="disabled" (click)="modalclose()" >رجوع</button>
        <button  type="button" style="height: 35px;" class="btn btn-primary float-left" [disabled]="disabled" (click)="Submit();">حفظ</button>
    
        

        </div>
    </div>
            </div>
            <!-- <div class="modal-footer">
   
            </div> -->
          <div class="col-sm-12">
            <div class="card">
               <div class="row"  style=" padding: 30px;">
                 <div  *ngFor="let item of AccomplishmentImage let i =index" style="width: 2%;" class="col-4">
                  <!-- <img class="m-3" src="../../../../../assets/images/User.jpg"> -->
                  <div class="image-area text-center bg-white" style="margin: 8px;"  >
                    <img   *ngIf="item.mediaTypeId==7"  [src]="item.url"  height="200" width="200"  (click)="OpenImage(item.url)">
                    <video *ngIf="item.mediaTypeId==11"  height="200" width="200" controls>
                      <source [src]="item.url" >
                      Your browser does not support the video tag.
                    </video>
                    <a  class="remove-image" appActionDelete (click)="deleteAccomplishmentMedia(item.id)" style="display: inline;">&#215;</a>
                    <br>
                    <p style="font-size: medium;">
                      {{item.title}}</p>

                  </div>
                 </div>
               </div>
  
</div>
        </div>
        </div>
     
    </div>
 
  </ng-template>
  <app-breadcrumb [title]="'قائمة الانجازات للعميل'" ></app-breadcrumb>

  <div class="container-fluid top-padding">
    <div class="card">
      <div class="card-body">
        <div class="row" >
          <div class="col-lg-3 col-sm-12 ">
            <div class="form-group">
              <label for="">بحث برقم الموبايل</label>
              <input type="text" class="form-control"  [(ngModel)]="mobile" (keyup.enter)="Search()" 
                id="serial">
            </div>
          </div>
          <div class="col-lg-2 col-sm-12 ">
            <div class="form-group">
              <label for="">بحث برقم الايصال</label>
              <input type="text"  onlyNumbers class="form-control text-end" [disabled]="receiptNumDisable" [(ngModel)]="receiptNum" (keyup.enter)="Search()" 
                id="serial"
                (ngModelChange)="receiptNumChange()"
                [style.border]="ReceiptRequierd ? (ReceiptRequierd ? '1px solid #dc3545 !important' : (!ReceiptRequierd  ? '1px solid #28a745' : '1px solid #ced4da')) : '1px solid #ced4da'">
            </div>
          </div>
          <div class="receiptDash">
            <span>-</span>
          </div>
          <div class="col-lg-2 col-sm-12 ">
            <div class="form-group">
              <label for="" style="margin: 5px;">رقم الدفتر</label>
              <ng-select
              class="js-example-basic-single"
              aria-label="Default select example"
            [(ngModel)]="receiptBookId"
            [items]="GetReceiptBooks"
            bindLabel="bookNumber"
            bindValue="id"
              
            
            (change)="getReceiptBook($event)"
            >
  
            </ng-select>
            </div>
          </div>
          <div class="col-lg-3 col-sm-12 ">
            <div class="form-group">
              <label for="">بحث برقم التبرع الرئيسي</label>
              <input type="text" class="form-control"  [(ngModel)]="donationSerial" (keyup.enter)="Search()" 
                id="serial">
            </div>
          </div>
         
  
        </div>
  <div class="row">
    <div class="col-lg-4 col-sm-12 ">
      <div class="form-group">
        <label for="">بحث برقم التبرع الفرعي</label>
        <input type="text" class="form-control"  [(ngModel)]="donationDetailSerial" (keyup.enter)="Search()" 
          id="serial">
      </div>
    </div>

    <div class="col-lg-8 col-sm-12" style="text-align: end; padding: 23px;">
      <button id="search" style="    color: #fff;
    background: #7366ff;
    font-size: 19px;
    padding: 5px 26px;" (click)="Search()">بحث</button>
    </div>
  </div>
      </div>
    </div>
  </div>
  
<app-breadcrumb [title]="'بيانات العميل'+' ('+companyName+') '" ></app-breadcrumb>

<div class="container-fluid">
  <div class="user-profile">
    <div class="row">
      <!-- user profile first-style start-->

      <div class="col-sm-12">
        <div class="card hovercard text-center">
          <div class="user-image">
            <div class="avatar">
              <!-- <img alt="Risk Logo" src="../../../../assets/Risk Logo 200 x 200/Risk-Logo-200-x-200.png"> -->
              <img alt="Risk Logo" [src]="companyImage">
              <!-- <img alt="Risk Logo" src="../../../../assets/Risk Logo 200 x 200/Risk-Logo-200-x-200-02.png"> -->

            </div>
            <!-- <div class="icon-wrapper"><i class="icofont icofont-pencil-alt-5"><input class="upload" type="file"
                   /></i></div> -->
          </div>
          <div class="info">
            <div class="row d-flex justify-content-center">
              <div class="col-lg-2 col-md-6">
                <div class="ttl-info " style="width: max-content;">
                  <h6> المحافظه <i class="fa fa-home" aria-hidden="true"></i>

                  </h6>
                  <span>{{customerinfo?.governoratName}}</span>

                </div>
              </div>
              <div class="col-lg-2 col-md-6">
                <div class="ttl-info t" style="width: max-content;">
                  <h6>المدينه <i class="fa fa-home" aria-hidden="true"></i>
                  </h6>
                  <span>{{customerinfo?.cityName}}</span>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 ">
                <div class="user-designation">

                  <i class="icofont icofont-business-man-alt-2 fa-3x mt-2"></i>

                  <div class="title ">
                    <span *ngIf="customerinfo?.vip"><i  class="fa fa-star Gold" aria-hidden="true"></i></span>

                    <span>
                      {{customerinfo?.name}}
                    </span>


                  </div>

                  <div class="desc mt-2">
                  </div>
                </div>
              </div>
               <div ngbDropdown class="col-lg-2 col-md-6">
                <div class="ttl-info number-div">
                  <h6> الرقم
                  <br> <br>{{customerinfo?.mobile}}    
                         <br>
                         <br>
                  <span class="contact-bttn">
                    <a [href]="'//wa.me/+2' + customerinfo?.mobile" *ngIf="customerinfo?.mobile" target="_blank" class="whatssapp-iconA"><i class="fa fa-whatsapp whatssapp-icon" aria-hidden="true"></i></a>
                     <a ngbDropdownToggle (click)="$event.stopPropagation()" *ngIf="customerinfo?.mobile"><i class="fa fa-phone" style="color: green;"></i></a>
                     <div ngbDropdownMenu aria-labelledby="dropdownConfig" >
                       <a  (click)="$event.stopPropagation()"[href]="'tel:' + customerinfo?.mobile" ngbDropdownItem>{{customerinfo?.mobile}}</a>
                           <ng-container  *ngFor="let mobile of customerinfo?.customerPhones">
                             <a (click)="$event.stopPropagation()" [href]="'tel:' + mobile" ngbDropdownItem>{{mobile}}</a>
                           </ng-container>
                     </div>
                  <i class="fa fa-comment" style="color: black; font-size: 20px; cursor: pointer;" *ngIf="customerinfo?.mobile" (click)="SendSMSTemplate()"></i>  
               
                  </span> 
                   </h6>
                  
                  <br>
                </div>
              </div>
              <div class="col-lg-2 col-md-6">

              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-xxl-12 ">
                <div class="ttl-info " style="width: max-content;">
                  <h6> العنوان<i class="fa fa-address-card-o" aria-hidden="true"></i>
                  </h6>
                  <span class="responsive-font-example">{{customerinfo?.address}}</span>

                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
 
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header px-0" style="padding-bottom: 5px;">
         
          <div class="table-responsive ">
            <table class="table  table-striped" style="width: 100%;">
              <thead>
                <tr class="bg-dark ">
                  <th scope="col" class="font-style-header" >#</th>
                  <th scope="col" class="font-style-header"  style="color: white;" >ش</th>
                  <th scope="col" class="font-style-header" >  رقم التبرع </th>
                  <th scope="col" class="font-style-header" >  رقم التبرع الفرعي </th>
                  <th scope="col" class="font-style-header" >  تاريخ التحصيل </th>
                  <th scope="col" class="font-style-header" >  تاريخ الانجاز </th>
                  <th scope="col" class="font-style-header" >  نوع التبرع </th>
                  <th scope="col" class="font-style-header" >  النشاط </th>
                  <th scope="col" class="font-style-header" >  المشروع </th>
                  <th scope="col" class="font-style-header" >  مكان المشروع </th>
                  <th scope="col" class="font-style-header" >  مبلغ التبرع </th>
                  <th scope="col" class="font-style-header" >  رقم الايصال </th>
                  <!-- <th scope="col" class="font-style-header" >  الكود المسجل </th> -->
                  <th scope="col" class="font-style-header" > </th>
                </tr>
              </thead>
              <tbody >
                <tr *ngFor="let item of CustomerProjectDonation; let i = index;">
                  <td scope="row" class="font-style">{{i+1}}</td>
                  <td scope="row" class="font-style"><img width="30px" height="30px" [src]="imgUrlServer+item.companyIcon"></td>
                  <td  class="font-style">{{item.donationSerial}}</td>
                  <td  class="font-style">{{item.serial}}</td>
                  <td  class="font-style">{{item.collectionDate | date:"dd/MM/yyyy"}}</td>
                  <td  class="font-style">{{item.accomplishmentDate | date:"dd/MM/yyyy"}}</td>
                  <td  class="font-style">{{item.donationTypeName}}</td>
                  <td  class="font-style">{{item.sectorName}}</td>      
                  <td  class="font-style">{{item.sectorTypeName}}</td> 
                  <td  class="font-style">{{item.sectorLocationName}}</td>             
                  <td  class="font-style">{{item.smallAmount}}</td>
                  <td  class="font-style">{{item.receiptNum}}</td>
                  <td class="font-style" style="text-align: end; width: 8%;" [ngStyle]="{ 'background-color': item.companyColor  }"  >
                                          <button  style="padding: 7px; background-color: transparent;" [ngStyle]="{'color': !item.canAddAccomplishment  ? 'darkgray':'rgba(35, 118, 241, 0.667)' }" [disabled]="!item.canAddAccomplishment" (click)="open(content,item);">
                          <i class="fa fa-upload"></i>
                        </button>
                    </td>
                </tr>
              </tbody>
            </table>
            <br>
            <div class="d-flex justify-content-center">
                <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
                  firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
                  (pageChanged)="pageChanged($event)">
                </pagination>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>