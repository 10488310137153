import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CitiesApiService } from 'src/app/shared/API-Service/cities-api.service';
import { GovernorateApiService } from 'src/app/shared/API-Service/governorate-api.service';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { GenericResponse } from 'src/app/shared/Models/GenericResponse';
import { getCities } from 'src/app/shared/Models/getCities';
import { getCitiesWithGovernorate } from 'src/app/shared/Models/getCitiesWithGovernorate';
import { GetGovernorate } from 'src/app/shared/Models/GetGovernorate';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-cities',
  templateUrl: './list-cities.component.html',
  styleUrls: ['./list-cities.component.css']
})
export class ListCitiesComponent extends BaseComponent implements OnInit {

  //#region  Declare Variables
  response: getCitiesWithGovernorate[];
  Response_List: getCities[];
  Filtered_List: any;
  Govern_id: number;
  Governorate_List: GetGovernorate[];
  paginationview: boolean;
  GovernorateList: any[]=[];
  //#endregion

  //#region constructor
  constructor(private citiesApiService: CitiesApiService,
    private governorateApiService: GovernorateApiService,
    private router: Router) { 
      super();
      this.paginationview=true;
    }
  //#endregion

  //#region  ng OnInit
  ngOnInit(): void {
    this.Response_List = [];
    this.getGovernoate();
    this.GetCities();
  }
  //#endregion

  //#region Consume API's

  pageChanged(event:any){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;

    this.citiesApiService.GetCitiesWithGovernorate(null,this.pager).subscribe(
      response => {
        this.response = response.data;
        // console.log("----- : ",this.response[0].governorate.title);
        
        this.Response_List = response.data;
        this.Filtered_List = response.data;
         this.totalCount = this.Filtered_List.totalCount;
       this.Filtered_List=this.Filtered_List.items;
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
    // newLocl._ShipmentService.Get(data).subscribe((res)=>{
    //   this.response=res.data;
    //   this.totalItems = this.response.totalCount;
    //   this.response=this.response.items;
    // })
  }
  //#region  get Governoate
  GetCities() {

    this.citiesApiService.GetCitiesWithGovernorate(null,this.pager).subscribe(
      response => {
        this.response = response.data;
        // console.log("----- : ",this.response[0].governorate.title);
        
        this.Response_List = response.data;
        this.Filtered_List = response.data;
        this.totalCount = this.Filtered_List.totalCount;
        this.Filtered_List=this.Filtered_List.items;
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }
  //#endregion

  //#region  Delete Governoate
  Delete(id: number) {
    Swal.fire({
      title: ' تحذير !',
      text: "هل انت متأكد من حذف هذا العنصر ؟ ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7364f6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'حذف',
      cancelButtonText: 'إنهاء',
    })
      .then((result) => {

        if (result.isConfirmed) {
          
          this.citiesApiService.DeleteCities(id).subscribe(
            response => {
              this.GetCities();
              Swal.fire({
                icon: 'success',
                title: "تم حذف نوع المدينة بنجاح",
                showConfirmButton: false,
                timer: 1500
              })
              this.router.navigateByUrl("content/admin/Get-cities");
            },
            err => {
              Swal.fire({
                icon: 'error',
                title: 'خطأ',
                text: err.error.message,
              })
            }
          )

        } else {
          // Swal.fire(
          //   'Your appointment still active ',
          // );
        }
      });
  }
  //#endregion

  //#endregion

  //#region AddNew
  AddNew() {
    this.router.navigateByUrl("content/admin/insert-city");
  }
  //#endregion

  //#region Governoate
  update(cityid: number,governid: number, title: string) {
    this.citiesApiService.title = title;
    this.citiesApiService.GovernorateId = governid;
    localStorage.setItem("Governoratetitle",title);
    localStorage.setItem("GovernorateId",JSON.stringify(governid));
    this.router.navigate(['content/admin/update-city', cityid]);
  }
  //#endregion

  //#region Selected Governorate
  SelectedGovernorate(event: any) {
    this.pageNumber=1;
    this.pager.skipCount=0
    this.Govern_id = event;
    if(this.Govern_id !=-1){
      this.pager.governorateId=this.Govern_id;
      this.GetCities();
    }
    else{
      this.pager.governorateId=null
      this.GetCities();

    }
   
  
  }
  //#endregion

  //#region  get Governoate
  getGovernoate() {
    this.governorateApiService.GetGovernorate().subscribe(
      response => {
        
        this.Governorate_List = response.data;
        // response.data.forEach(element => {
        //   this.Governorate_List[element.id] = element.title;
        // });
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }
  //#endregion


}
