<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-7 p-0" *ngIf="!showOtp">
      <div class="login-card">
        <div>
          <div style="margin-bottom: 20px;">
            <img src="../assets/images/logo/Risk-Logo-786-x-140.png">
            <!-- <a class="logo text-start" href="index.html"> -->
              <!-- <img
                class="img-fluid for-light"
                src="../assets/images/logo/Logo_bigger.png"
                alt="looginpage" />
                <img
                class="img-fluid for-dark"
                src="../assets/images/logo/logo_dark.png"
                alt="looginpage"/> -->
              <!-- </a> -->
          </div>
          <div class="login-main">
            <form class="theme-form" [formGroup]="InsertForm">
              <div style="text-align: center">
                <h4>تسجيل الدخول</h4>
                <p>أدخل أسم المستخدم وكلمة المرور</p>
              </div>
              <div class="form-group">
                <label class="col-form-label">أسم المستخدم</label>
                <input
                  class="form-control"
                  type="email"
                  required=""
                  placeholder="UserName"
                  formControlName="username"
                />
              </div>
              <div class="form-group">
                <label class="col-form-label">كلمة المرور</label>
                <div class="form-input position-relative">
                  <input
                    class="form-control"
                    [type]=" showPassword ? 'text' : 'password' "
                    required=""
                    placeholder="*********"
                    formControlName="passowrd"
                  />
                  <label class="btn showPass" (click)='showHidePassword()'> {{ showPassword ? 'Hide' : 'Show' }} Password</label>
                  <br>
                </div>
              </div>
              <div class="form-group mb-0">
                <div class="checkbox p-0">
                  <input id="checkbox1" type="checkbox" />
                  <label class="text-muted" for="checkbox1"
                    >تذكرنى</label
                  >
                </div>
                <!--  [disabled]="!InsertForm.valid" -->
                <button class="btn btn-primary btn-block w-100" 
                         type="submit"
                        
                         (click)="Login()">
                تسجيل دخول
                </button>
              </div>
              <!-- <h6 class="text-muted mt-4 or">Or Sign in with</h6>
                 <div class="social mt-4">
                   <div class="btn-showcase"><a class="btn btn-light" href="https://www.linkedin.com/login" target="_blank"><i class="txt-linkedin" data-feather="linkedin"></i> LinkedIn </a><a class="btn btn-light" href="https://twitter.com/login?lang=en" target="_blank"><i class="txt-twitter" data-feather="twitter"></i>twitter</a><a class="btn btn-light" href="https://www.facebook.com/" target="_blank"><i class="txt-fb" data-feather="facebook"></i>facebook</a></div>
                 </div> -->
              <!-- <p class="mt-4 mb-0 text-center">Don't have account?<a class="ms-2" href="sign-up.html">Create Account</a></p> -->
            </form>
          </div>
          <div style="text-align: center;">
            <span><br>
              © Risk 2022 All Rights Reserved<br> Developed & Powered by wecancity.com
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-7 p-0" *ngIf="showOtp">
      <div class="login-card">
        <div>
          <div style="margin-bottom: 20px;">
            <img src="../assets/images/logo/Risk-Logo-786-x-140.png">
            <!-- <a class="logo text-start" href="index.html"> -->
              <!-- <img
                class="img-fluid for-light"
                src="../assets/images/logo/Logo_bigger.png"
                alt="looginpage" />
                <img
                class="img-fluid for-dark"
                src="../assets/images/logo/logo_dark.png"
                alt="looginpage"/> -->
              <!-- </a> -->
          </div>
          <div class="login-main">
            <form class="theme-form" style="
    direction: ltr;" >
              <!-- <div style="text-align: center">
                <h4>تسجيل الدخول</h4>
                <p>أدخل أسم المستخدم وكلمة المرور</p>
              </div> -->

              <div class="form-group">
                <label class="col-form-label pt-0">Enter OTP</label>
              
                <div class=" my-2" >
                
                 <ng-otp-input  #ngOtpInputRef (onInputChange)="onOtpChange($event)"  [config]="config"></ng-otp-input>
                 <span  class="o-t-p">
                  <ng-container *ngIf="otp">
                    Entered otp :-{{typeOtp}}
                  </ng-container>
                  </span>

                </div>
              </div>
         

              <div class="form-group my-3">
                
                <button class="btn btn-primary btn-block" type="submit" (click)="SendVerify()">{{'Done'|translate}}</button>
              </div> 
              <!-- <h6 class="text-muted mt-4 or">Or Sign in with</h6>
                 <div class="social mt-4">
                   <div class="btn-showcase"><a class="btn btn-light" href="https://www.linkedin.com/login" target="_blank"><i class="txt-linkedin" data-feather="linkedin"></i> LinkedIn </a><a class="btn btn-light" href="https://twitter.com/login?lang=en" target="_blank"><i class="txt-twitter" data-feather="twitter"></i>twitter</a><a class="btn btn-light" href="https://www.facebook.com/" target="_blank"><i class="txt-fb" data-feather="facebook"></i>facebook</a></div>
                 </div> -->
              <!-- <p class="mt-4 mb-0 text-center">Don't have account?<a class="ms-2" href="sign-up.html">Create Account</a></p> -->
            </form>
            
            <div class="text-center mt-4 mb-4"  *ngIf="showSendOtp"> <span class="reset-password-link">{{"If don't receive OTP?"|translate}}  <a class="btn-link text-danger"(click)="Login()">{{"Resend"|translate}} </a></span></div>
            <div class="text-center mt-4 mb-4"  *ngIf="!showSendOtp"> <span class="reset-password-link">{{"Resend code in"|translate}}  <a class="btn-link text-danger ">{{time +" "}}{{"  "}} {{"secondly"|translate}}</a></span></div>
          </div>
          <div style="text-align: center;">
            <span><br>
              © Risk 2022 All Rights Reserved<br> Developed & Powered by wecancity.com
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-5 p-0 bg-info d-none d-sm-block">
      <img width="100%" height="100%"
        src="../assets/images/logo/Risk-Loop-Image.png"
        alt="looginpage"
      />
    </div>
 
  </div>
</div>

      <!-- spinner -->
      <!-- <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
          <p style="color: white"> {{ 'spinner' | translate }} </p>  
          </ngx-spinner>  -->

          <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
               <p class="loading">Loading Awesomeness...</p>
          </ngx-spinner>