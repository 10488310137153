import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { SmsTemplateService } from './../../../../shared/API-Service/sms-template.service';
import Swal from 'sweetalert2';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ChoosecompanyModalComponent } from 'src/app/shared/components/ChooseCompanyModal/choosecompany-modal/choosecompany-modal.component';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';
import { environment } from 'src/environments/environment.prod';
import { BaseComponent } from 'src/app/shared/components/base/base.component';

@Component({
  selector: 'app-sms-template',
  templateUrl: './sms-template.component.html',
  styleUrls: ['./sms-template.component.css']
})
export class SmsTemplateComponent extends BaseComponent implements OnInit {
  Filtered_List
  passdata:any;
  modalRef: BsModalRef;
  LoadPage: boolean;
  CompanyId: any;
  imgUrlServer=environment.Server_URL_Image+'/'
  Companies: any[];

  constructor(private CompanyDataService:CompanyDataService,private ChooseCompanyModalService: BsModalService,private smstemplate:SmsTemplateService,private router:Router) { super();}

  ngOnInit(): void {
    // const modalConfig = {
    //   class: 'modal-dialog-centered modal-lg', // Add your desired width class here
    //   ignoreBackdropClick: true
    // };
    // this.modalRef=this.ChooseCompanyModalService.show(ChoosecompanyModalComponent, modalConfig);
    // this.modalRef.content.modalClosed.subscribe((company: any) => {
    //   this.LoadPage=true;
    //   this.CompanyId=company.companyId  
    //   this.CompanyDataService.CompanyInfo.CompanyId=this.CompanyId
    
    // });
    this.GetSmsTemplates();
    this.GetCompanies()
    
  }
  Search(){
   this.pager.companyId=this.CompanyId
   this.GetSmsTemplates()
  }
  GetCompanies(){
    this.CompanyDataService.GetCompanies().subscribe(res=>{
      this.Companies=res.data
    })
  }
GetSmsTemplates(){
  this.smstemplate.GetSmsTemplatesPagintion(this.pager).subscribe((res:any)=>{
    this.Filtered_List=res.data.items
    this.totalCount=res.data.totalCount
  }
  ) 
}
  pageChanged(event:any){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    var res;
    this.GetSmsTemplates();
   
  }
  newmessage(){
    this.router.navigateByUrl("content/admin/addSMSTemplate");
  }

  
  deleteitem(item){
    Swal.fire({
      position: 'center',   
      title: 'هل انت متاكد من مسح الرساله  ',
      showCancelButton: true,
      confirmButtonText: 'نعم',
      cancelButtonText: 'لا',
      showLoaderOnConfirm: true,
      preConfirm: (phone) => {
        this.passdata = phone;
        this.smstemplate.DeleteSmsTemplate(item).subscribe(response=>{
          if (response) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: ' تم المسح',
              showConfirmButton: false,
              timer: 1500,
              
            
            })
           this.smstemplate.reloadCurrentRoute()
          }
        })
      }
  })
}


  Update( item: any) {
    let data = item;
    this.router.navigateByUrl("content/admin/addSMSTemplate",{state:{data:data}});
  }



}
