import { Router } from "@angular/router";
import { DonationMTypeApiService } from "../../../../shared/API-Service/donation-m-type-api.service";
import { Component, OnInit } from "@angular/core";
import { HttpDonationMType } from "src/app/shared//Models/donation-m-type";

import Swal from "sweetalert2";

@Component({
  selector: "app-donation-main-type-component",
  templateUrl: "./donation-main-type-component.component.html",
  styleUrls: ["./donation-main-type-component.component.css"],
})
export class DonationMainTypeComponentComponent implements OnInit {
  update: boolean = false;
  response: HttpDonationMType[];

  constructor(
    public _DonationMTypeApiService: DonationMTypeApiService,

    private _router: Router
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    var res;
    this._DonationMTypeApiService.get().subscribe({
      next: (respnose) => {
        res = respnose;
        this.response = res.data;
      },
    });
  }

  track(index, item) {
    return item.id;
  }

  Delete(id: number) {
    Swal.fire({
      title: " تحذير !",
      text: "هل انت متأكد من حذف هذا العنصر ؟ ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#7364f6',
      cancelButtonColor: "#d33",
      confirmButtonText: "حذف",
      cancelButtonText: "رجوع",
    }).then((result) => {
      if (result.isConfirmed) {
        this._DonationMTypeApiService.Delete(id).subscribe(
          (res) => {
            this.getData();
            Swal.fire({
              icon: "success",
              title: "تم حذف الموظف بنجاح",
              showConfirmButton: false,
              timer: 1500,
            });
          },
          (err) => {
            Swal.fire({
              icon: "error",
              title: "خطأ",
              text: err.error,
            });
          }
        );
      } else {
        // Swal.fire(
        //   'Your appointment still active ',
        // );
      }
    });
  }
  Update( item: any) {
    let data = item;
    this._router.navigate([`/content/admin/Inser-Donation-Reason/${item.id}`]);
    this._DonationMTypeApiService.Data.next(data);
        
  }
}
