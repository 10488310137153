import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[appActionEdit]'
})
export class ActionEditDirective {

  actions: any[]=[];

  constructor(private el: ElementRef  ,private renderer: Renderer2,private router: Router) { 
    let loadUserMenu=  JSON.parse(localStorage.getItem("MenuByRoleId"));
    let MenuByRoleId=loadUserMenu;
    if(MenuByRoleId!==null)
    {
   
   MenuByRoleId=MenuByRoleId.filter(x=>x.path==this.router.url)
  if( MenuByRoleId.length>0)
  {
  this.actions =MenuByRoleId[0]?.MenuAction;
  if(this.actions.filter(x=>x.actionId==2).length===0){
    this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
  }
  }
  else
  {
    let url
    const currentUrl = this.router.url;
    const pattern = /\/DisplayData\/\d+/;
     pattern.test(currentUrl);
     
    // const currentUrl = this.router.url;
    if(pattern.test(currentUrl)){
      url="/content/agent/DisplayData/mobile"
      MenuByRoleId=loadUserMenu.filter(x=>x.path==url)
     if( MenuByRoleId.length!=0)
   {
     this.actions =MenuByRoleId[0]?.MenuAction;
     if(this.actions.filter(x=>x.actionId==2).length===0){
       this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
     }
   }
   else{
     this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
   }
   }
    else{
      url=this.router.url
      let newurl;
      let routsplit= url.split('/');
      for (let index = 1; index < routsplit.length-1; index++) {
       if(index>1)
       {
       newurl = newurl+'/'+routsplit[index];
       }
       else
       {
         newurl = '/'+routsplit[index];
       }
      }
        MenuByRoleId=loadUserMenu.filter(x=>x.path==newurl)
       if( MenuByRoleId!=null)
     {
     this.actions =MenuByRoleId[0]?.MenuAction;
     if(this.actions){
     if(this.actions.filter(x=>x.actionId==2).length===0){
       this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
     }
   }
      
     }
    }
 
}
    }
  
  //  this.el.nativeElement.style.background = 'red';
    }

}
