
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgentComponent } from './agent/agent.component';
import { CustomerDonationComponent } from './customer-donation/customer-donation.component';
import { DealWithCustomerComponent } from './deal-with-customer/deal-with-customer.component';
import { DisplayCustomerDataComponent } from './display-customer-data/display-customer-data.component';
import { NewCusomterDonationComponent } from './new-cusomter-donation/new-cusomter-donation.component';
import { StatisticsAgentComponent } from './statistics-agent/statistics-agent.component';
import { AchievementsComponent } from './achievements/achievements.component';
import { AuthGuardGuard } from 'src/app/shared/services/auth-guard.guard';
import { FollowupCallStatsComponent } from './follow-up-calls-stats/followup-call-stats/followup-call-stats.component';
import { FollowupCallStatDetailsComponent } from './follow-up-calls-stats/followup-call-stat-details/followup-call-stat-details.component';
import { FollowUpCallStatsdetailsAdminComponent } from './follow-up-calls-stats/follow-up-call-statsdetails-admin/follow-up-call-statsdetails-admin.component';
import { FollowUpCallsStatsAdminComponent } from './follow-up-calls-stats/follow-up-calls-stats-admin/follow-up-calls-stats-admin.component';
const routes: Routes = [
  {
    path: '',children:[
      {
        canActivate: [AuthGuardGuard],
        path:'main', component: AgentComponent
      },
      {
        canActivate: [AuthGuardGuard],
        path:'stataistic', component: StatisticsAgentComponent
      },
      
      {
        canActivate: [AuthGuardGuard],
        path:'Customer', component: DealWithCustomerComponent
      },
      {
        canActivate: [AuthGuardGuard],
        path:'Customer/:id', component: DealWithCustomerComponent
      },
      {
        canActivate: [AuthGuardGuard],
        path:'DisplayData/:id/:Mobile', component: DisplayCustomerDataComponent
      },
      {
        canActivate: [AuthGuardGuard],
        path:'Achievements/:id', component: AchievementsComponent
      },
      {
        canActivate: [AuthGuardGuard],
        path:'CustomerDonation', component: CustomerDonationComponent
      },
      {
        canActivate: [AuthGuardGuard],
        path:"NewCusomterDonation",component:NewCusomterDonationComponent
      },
      {
        canActivate: [AuthGuardGuard],
        path:"updateCusomterDonation",component:NewCusomterDonationComponent
      },
      {
        canActivate: [AuthGuardGuard],
        path:"follow-up-call-stats",component:FollowupCallStatsComponent
      },
       {
        canActivate: [AuthGuardGuard],
        path:"follow-up-call-statsdetails",component:FollowupCallStatDetailsComponent
      },
      {
         canActivate: [AuthGuardGuard],
        path:"follow-up-call-stats-admin",component:FollowUpCallsStatsAdminComponent
      },
       {
        canActivate: [AuthGuardGuard],
        path:"follow-up-call-statsdetails-admin",component:FollowUpCallStatsdetailsAdminComponent
      }
    ]
  }
];


@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)]


})
export class AgentRoutingModule { }
