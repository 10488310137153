import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SectorTypeApiService } from 'src/app/shared/API-Service/sector-type-api.service';
import { SectorsApiService } from '../../../../../shared/API-Service/sectors-api.service';
import { GenericResponse } from 'src/app/shared/Models/GenericResponse';
import {getSectorType  } from 'src/app/shared/Models/get-sector-type';
import { insertSectorType,UpdateSectorType } from 'src/app/shared/Models/insert-sector-type';
import { GetAllSector } from 'src/app/shared/Models/get-all-sector';
import{GetSectorTypesInSector} from 'src/app/shared/Models/GetSectorTypesInSector'
import Swal from 'sweetalert2';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { environment } from 'src/environments/environment.prod';
@Component({
  selector: 'app-list-sector-type',
  templateUrl: './list-sector-type.component.html',
  styleUrls: ['./list-sector-type.component.css']
})
export class ListSectorTypeComponent extends BaseComponent implements OnInit {
  response: GetSectorTypesInSector[];
  Response_List: getSectorType[];
  Filtered_List: any;
  Sector_id: number;
  Sector_List: any;
  paginationview: boolean;
  Companies: any[];
  CompanyId: any;
  imgUrlServer=environment.Server_URL_Image+'/'

  @ViewChild('sectorSelect') sectorSelect: NgSelectComponent;
  constructor(private sectorTypeApiService: SectorTypeApiService,
    private sectorsApiService: SectorsApiService,
    private router: Router,
    private CompanyDataService :CompanyDataService) { 
      super()
      this.paginationview=true
    }

    ngOnInit(): void {
      
      this.Response_List = [];
      this.GetCompanies();
     this.GetSectorTypes();
    }
    track(index, item) {
      return item.id;
    }
    pageChanged(event:any){
      this.pageNumber = event.page;// -1 * pageSize;
      this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
      this.sectorTypeApiService.getSectorType(null,null,null,this.pager).subscribe(
        response => {
          this.response = response.data;
          // console.log("----- : ",this.response[0].governorate.title);
          
          this.Response_List = response.data;
          this.Filtered_List = response.data;
          this.totalCount = this.Filtered_List.totalCount;
          this.Filtered_List=this.Filtered_List.items;
        },
        err => {
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: err.error.message,
          })
        }
      )
      // newLocl._ShipmentService.Get(data).subscribe((res)=>{
      //   this.response=res.data;
      //   this.totalItems = this.response.totalCount;
      //   this.response=this.response.items;
      // })
    }
    Search(){
      this.pageNumber=1
    this.pager.skipCount=0
     
   
       this.pager.sectorId=this.Sector_id
      
      
      this.pager.companyId=this.CompanyId
      
      this.GetSectorTypes();
     
      
    }
    GetSectorTypes() {
      // this.pager.companyId=companyId
      // this.pager.sectorId=sectorID
      this.sectorTypeApiService.getSectorType(null,null,null,this.pager).subscribe(
      
        response => {
          this.response = response.data;
          // console.log("----- : ",this.response[0].governorate.title);
          
          this.Response_List = response.data;
          this.Filtered_List = response.data;
          this.totalCount = this.Filtered_List.totalCount;
          this.Filtered_List=this.Filtered_List.items;
        },
        err => {
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: err.error.message,
          })
        }
      )
    }
    SelectCompany(CompanyId){
      this.CompanyId=CompanyId
      this.sectorSelect.clearModel();
      this.Sector_List=null
      this.getSector(CompanyId);
    }
    GetCompanies(){
      this.CompanyDataService.GetCompanies().subscribe(res=>{
        this.Companies=res.data
      })
    }
    getSector(CompanyId:any) {
      this.sectorsApiService.getSectors(CompanyId).subscribe(
        response => {
          this.Sector_List = response.data;
          // response.data.forEach(element => {
          //   this.Governorate_List[element.id] = element.title;
          // });
        },
        err => {
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: err.error.message,
          })
        }
      )
    }
    Delete(id: number) {
      Swal.fire({
        title: ' تحذير !',
        text: "هل انت متأكد من حذف هذا العنصر ؟ ",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7364f6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'حذف',
        cancelButtonText: 'إنهاء',
      })
        .then((result) => {
  
          if (result.isConfirmed) {
            this.sectorTypeApiService.DeleteSectorTypes(id).subscribe(
              response => {
                this.GetSectorTypes();
                Swal.fire({
                  icon: 'success',
                  title: "تم حذف نوع التبرع بنجاح",
                  showConfirmButton: false,
                  timer: 1500
                })
                this.router.navigateByUrl("content/admin/Get-sector-type");
              },
              err => {
                Swal.fire({
                  icon: 'error',
                  title: 'خطأ',
                  text: err.error.message,
                })
              }
            )
  
          } else {
            // Swal.fire(
            //   'Your appointment still active ',
            // );
          }
        });
        
}



AddNew() {
  this.router.navigateByUrl("content/admin/Insert-Sector-type");
}
update(sectorTypeId: number, title: string,sectorId: number  ) {
  this.sectorTypeApiService.title = title;
  this.sectorTypeApiService.sectorId = sectorId;
 
  // this.router.navigate(['content/admin/Update-Sector-type/${id}']);
  this.router.navigate(['content/admin/Update-Sector-type/',sectorTypeId]);

}
//#endregion



 SelectedSector(event: any) {
  
 this.Sector_id = event;

  }

}

///////////////////////

