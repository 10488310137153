
import { Insertjobs, UpdateJobs } from './../Models/insertjobs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';


@Injectable({
  providedIn: 'root'
})
export class JobsService {
  title:string;
  constructor(private http:HttpClient) { }

  Insertjobs(Data:Insertjobs): Observable<GenericResponseSingle<any>> {
    return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/Job/Create`,Data);
  }

  GetJobs(): Observable<GenericResponseSingle<any>> {
    return this.http.get<GenericResponseSingle<any>>(`${environment.Server_URL}/Job/Get`);
  }

  GetJobsList(data:any): Observable<GenericResponseSingle<any>> {
    return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/Job/GetJobList`,data);
  }
  deleteJobs(jobsId:number): Observable<GenericResponseSingle<any>>{
    return this.http.get<GenericResponseSingle<any>>(`${environment.Server_URL}/Job/Delete?id=${jobsId}`);
  }


  updateJobs(Data:UpdateJobs): Observable<GenericResponseSingle<UpdateJobs>> {
    return this.http.post<GenericResponseSingle<UpdateJobs>>(`${environment.Server_URL}/Job/Update`,Data);
  }
}
