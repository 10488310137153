<app-breadcrumb
  [title]="'إدارة النشاط '"
  [active_item]="'Form Wizard'"
></app-breadcrumb>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding-bottom: 5px !important">
          <div *ngIf="update; else elseBlock1">
            <h5 class="pb-2">تعديل نشاط موجود</h5>
            يمكنك تعديل نشاط أو أكثر فى قائمة الانشطه
          </div>
          <ng-template #elseBlock1>
            <h5 class="pb-2">إضافة نشاط جديد</h5>
            يمكنك إضافة  نشاط أو أكثر فى قائمة الانشطه
          </ng-template>
        </div>
        <div class="card-body">
          <form  class="needs-validation"  [formGroup]="insertSectorForm"  (ngSubmit)="onSubmit()"  novalidate [ngClass]="{'was-validated': validated}" >
            <div class="form-row">
              <div class="col-6 mb-3">
                <label for="validationCustom01">  أسم النشاط </label>
      
              <input class="form-control"
              id="validationCustom01" type="text"
                placeholder="ادخل اسم النشاط" 
                required=""
                
              formControlName="title" ><!-- onlyAlphabets -->
              
           
          </div>

         
        </div>

        <div class="form-row">
     
              </div>
       
            <div *ngIf="update; else elseBlock">
              <button
                class="btn btn-primary pull-right"
                type="submit"
                
               
              >
                تعديل
              </button>
            </div>
            <ng-template #elseBlock>
              <button
                class="btn btn-primary pull-right"
                type="submit"
                
              >
                إضافة
              </button>
            </ng-template>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div *ngFor="let option of options; let i = index" class="option">
  <label for="option_{{ i }}"></label>

  <input
    type="radio"
    name="option"
    id="option_{{ i }}"
    [value]="option"
    [(ngModel)]="selectorsColor"
  />
</div>
<div [ngSwitch]="selectorsColor">
  <p *ngSwitchCase="'insert'">ادخال</p>
  <p *ngSwitchCase="'update'">تعديل</p>
</div>

<section id="InsertForm" *ngIf="selectorsColor == 'insert'">
  <form [formGroup]="insertSectorForm" (ngSubmit)="InitForm(insertSectorForm)">
    <div>
      <label style="color: red"> أسم النشاط </label>
      <input
        [class.is-valid]="insertSectorForm.get('title')?.status == 'VALID'"
        type="text"
        title="title"
        id="title"
        class="form-control"
        formControlName="title"
      />

      <div
        class="alert alert-danger px-3 p-0"
        *ngIf="
          insertSectorForm.get('title')?.errors != null &&
          insertSectorForm.get('title')?.touched
        "
      >
        <p *ngIf="insertSectorForm.get('title')?.errors?.required">
          أسم النشاط مطلوب
        </p>
        <p *ngIf="insertSectorForm.get('title')?.errors?.minlength">
          First Name minLength is 4
        </p>
      </div>
    </div>
    <div>
      <label for="numberofDays">رقم :</label>

      <input
        [class.is-valid]="
          insertSectorForm.get('numberofDays')?.status == 'VALID'
        "
        type="text"
        name="numberofDays"
        id="numberofDays"
        class="form-control"
        formControlName="numberofDays"
      />
      <div
        class="alert alert-danger px-3 p-0"
        *ngIf="
          insertSectorForm.get('numberofDays')?.errors != null &&
          insertSectorForm.get('numberofDays')?.touched
        "
      >
        <p *ngIf="insertSectorForm.get('numberofDays')?.errors?.required">
          الرقم مطلوب
        </p>
      </div>
    </div>
    <button
      [disabled]="insertSectorForm.invalid"
      class="btn btn-info w-100 my-3"
    >
      Send
    </button>
  </form>
</section>
<section id="UpdateForm" *ngIf="selectorsColor == 'update'">
  <form [formGroup]="updateSectorForm" (ngSubmit)="EditForm(updateSectorForm)">
    <div>
      <label style="color: red"> أسم النشاط </label>
      <input
        [class.is-valid]="insertSectorForm.get('title')?.status == 'VALID'"
        type="text"
        title="title"
        id="title"
        class="form-control"
        formControlName="title"
      />

      <div
        class="alert alert-danger px-3 p-0"
        *ngIf="
          insertSectorForm.get('title')?.errors != null &&
          insertSectorForm.get('title')?.touched
        "
      >
        <p *ngIf="insertSectorForm.get('title')?.errors?.required">
          أسم النشاط مطلوب
        </p>
        <p *ngIf="insertSectorForm.get('title')?.errors?.minlength">
          First Name minLength is 4
        </p>
      </div>
    </div>
    <div>
      <label for="numberofDays">رقم :</label>

      <input
        [class.is-valid]="
          insertSectorForm.get('numberofDays')?.status == 'VALID'
        "
        type="text"
        name="numberofDays"
        id="numberofDays"
        class="form-control"
        formControlName="numberofDays"
      />
      <div
        class="alert alert-danger px-3 p-0"
        *ngIf="
          insertSectorForm.get('numberofDays')?.errors != null &&
          insertSectorForm.get('numberofDays')?.touched
        "
      >
        <p *ngIf="insertSectorForm.get('numberofDays')?.errors?.required">
          الرقم مطلوب
        </p>
      </div>
    </div>
    <div>
      <label for="id">المفاح :</label>

      <input
        [class.is-valid]="insertSectorForm.get('id')?.status == 'VALID'"
        type="text"
        name="id"
        id="id"
        class="form-control"
        formControlName="id"
      />
      <div
        class="alert alert-danger px-3 p-0"
        *ngIf="
          insertSectorForm.get('id')?.errors != null &&
          insertSectorForm.get('id')?.touched
        "
      >
        <p *ngIf="insertSectorForm.get('id')?.errors?.required">الرقم مطلوب</p>
      </div>
    </div>
    <button
      [disabled]="insertSectorForm.invalid"
      class="btn btn-info w-100 my-3"
    >
      Update Sector
    </button>
  </form>
</section> -->
