<app-breadcrumb
  [title]="'قائمه    فئات التبرع'"
  [active_item]="'Form Wizard'"
></app-breadcrumb>

<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <a routerLink="/content/admin/CreateDonationType" class="d-block">
        <button class="btn btn-success pull-right" type="button" appActionNew>
          <span class="m-2">جديد</span> <i class="fa fa-plus"></i>
        </button>
      </a>
      <br>
    </div>
    <div class="col-sm-12 ">
      <div class="card">
        <div class="table-responsive ">
          <table class="table w-100  table-striped"  >
            <thead>
              <tr class="bg-dark text-left" >
                <th scope="col" class="text-light">#</th>
                <th scope="col" class="text-light">نوع فئه التبرع</th>
                <th scope="col" class="text-light"> نوع التبرع</th>
                <th scope="col" class="text-light">خاصيه التنبيه </th>
                <th scope="col" class="text-light">  </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of response; let i = index; trackBy: track" class="text-left">
           
                <td  class="font-style">{{i+1  }}</td>
                <td  class="font-style">{{ item.title }}</td>
                <td class="font-style">{{ item.donationMainTypeName }}</td>
                <td class="font-style text-center " style="width: 10%;">
                  
                   <input  type="checkbox"  [checked]="item.isNotify==true" class="checkbox"  disabled   [value]="item.isNotify" id="flexCheckDefault">
                   <!-- <input type="checkbox" id="check" *ngIf="item.isNotify==false" class="form-check-input"   disabled [value]="item.isNotify" id="flexCheckDefault"> -->
                </td>
                <td class="font-style " style="text-align: end; width: 8%;">
       <button
                  class="btn  " appActionEdit

                    style="color: rgba(35, 118, 241, 0.667); padding: 7px; background-color: transparent;"
                    (click)="Update(item)"
                  >
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button
                  appActionDelete
                  class="btn "
                
                  (click)="Delete(item.id)"
                  style="color: red; padding: 7px; background-color: transparent;"

                
                  >
                  <i class="fa fa-trash"></i>

                </button>
           
                
                </td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-center">
            <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
              firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
              (pageChanged)="pageChanged($event)">
            </pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


