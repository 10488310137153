import { Component, OnInit } from '@angular/core';
import {GetAllCollectorEmployeesApiService} from 'src/app/shared/API-Service/get-all-collector-employees-api.service';
import{CollectorCityApiService}from 'src/app/shared/API-Service/collector-city-api.service'
import { ActivatedRoute, Router } from '@angular/router';
import { CitiesApiService } from 'src/app/shared/API-Service/cities-api.service';
import { GenericResponse } from 'src/app/shared//Models/GenericResponse';
import { getCities } from 'src/app/shared/Models/getCities';
import { getAllCollectorEmployees } from 'src/app/shared//Models/Get-All-Collector-Employees';
import{GetCollectorCity, UpdateCity} from 'src/app/shared//Models/Get-collector-city'
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { InsertCollectorCity, UpdateCollectorCity } from 'src/app/shared//Models/InsertCollectorCity';
@Component({
  selector: 'app-insert-collector-city',
  templateUrl: './insert-collector-city.component.html',
  styleUrls: ['./insert-collector-city.component.css']
})
export class InsertCollectorCityComponent implements OnInit {
  Collector_List: any[]=[];
  Collector_id: number;
  //City_id:number;//  
  City_Ids: number[]=[];

  InsertForm: FormGroup;
  maxDate: Date;
  update: boolean;
  Collector_Dictionary:{[Id:number]:string} = {}
  Collector:string;
  City_List: getCities[];
  City:string;
  dropdownSettings: IDropdownSettings = {};
  CityDropdownlist: any;
  selectedCities: any=[]=[];
  ReturnedcollectorCity : UpdateCity[] ;
  CollectorID: any;
  form = {
    CollectorID:[],
    CityId:""
  };
  CruCollector: any[]=[];
  cityId: any;
  name: any;
  id: any;
  // Govern_id:number;
  // Governorate:string;
  constructor(private _formBuilder: FormBuilder,
    private toaster: ToastrService,
    private citiesApiService: CitiesApiService,
    private collectorCityApiService:CollectorCityApiService,
    private getAllCollectorEmployeesApiService :GetAllCollectorEmployeesApiService,
    private router: Router,
    private route: ActivatedRoute) { this.maxDate = new Date();

      this._InitForm();
//     this.CityDropdownlist=  JSON.parse(localStorage.getItem('City_List'))
// if(!JSON.parse(localStorage.getItem('City_List'))){

//}
     
      //this.update=false;
     const param = this.router.getCurrentNavigation().extras.state;
    if(param){
      this.update=true;
 this.name=param.name
     this.id=param.id;
     this.GetAllCitiesCollector(this.id);
   
     // this.getCollectorBycityId(param.cityId)
     
    }
    else{

      this.update=false;
this.getCollector();

    }
    }
    GetAllCitiesCollector(id){
      this.citiesApiService.GetCities().subscribe(
        response => {
          this.CityDropdownlist = response.data;    
          this.collectorCityApiService.GetAllCitiesCollector(id).subscribe(response=>{
            response.data.forEach(e =>{
                    const CityDropdownlistfilter= this.CityDropdownlist.filter((sub: any) => sub.id ==e.cityId)[0];
                this.selectedCities.push(CityDropdownlistfilter)
            })
            this.InsertForm.patchValue({
             CityId: this.selectedCities,
           });
          })              
        },
        err => {
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: err.error.message,
          })
        }
      )
         
    }
    

  ngOnInit(): void {
   

    
    this.Collector_id = -1;
   // this.City_Ids=-1;
    // this.getCollector();

    //this.GetCities();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'title',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };
  


      

      this.InitForm(this.citiesApiService.title,this.collectorCityApiService.title)
      // this.citiesApiService.GetCities().subscribe(
      //   response => {
      //     this.City = response.data.find(x=>x.id == this.citiesApiService.GovernorateId)?.title;
      //   },
      //   err => {
      //     // Swal.fire({
      //     //   icon: 'error',
      //     //   title: 'خطأ',
      //     //   text: err.error,
      //     // })
      //   }
      // )
      this.getAllCollectorEmployeesApiService.GetCollector().subscribe(
        response => {
          this.Collector = response.data.find(x=>x.id==this.collectorCityApiService.CollectorId)?.name;
        },
      )
    //   this.update = true;
    // //  this.update = false;
    //   this._InitForm();
    //   this.City="اختر المدينه";
    //   this.Collector = "أختر المحصل";
    // }
    
  }
  InitForm(title: string,collector:string) {
    this.InsertForm = this._formBuilder.group({
      Title: [title, Validators.required],
 
      CityId:[this.selectedCities,Validators.required],
      CollectorID:[this.SelectedCollector,Validators.required]

    });
    // this.InsertForm = this._formBuilder.group({
    //   Title: [title, Validators.required],
    //   CollectorID: ['-1', Validators.nullValidator],
    // });
  }
  _InitForm() {
    this.InsertForm = this._formBuilder.group({
      Title: ['', Validators.required],
      CityId:[this.selectedCities,Validators.required],
      // CollectorID: [this.SelectedCollector.name, Validators.required],
      CollectorID:[this.SelectedCollector,Validators.required]


    });
    // this.InsertForm = this._formBuilder.group({
    //   Title: ['', Validators.required],
    //   CollectorID: ['', Validators.nullValidator],
    // });
  }
  SelectedCollector(event: any) {
    this.Collector_id = event.target.value;
    
  
  }
  
 
  GetCities() {
    this.citiesApiService.GetCities().subscribe(
      response => {
        this.CityDropdownlist = response.data;                
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }
  getCollectorBycityId(id) {
    this.update=true;
    this.getAllCollectorEmployeesApiService.GetCollectorByCity(id).subscribe(
      response => {
        response.data.forEach(element => {
          this.Collector_List.push({name:element.employeeName,id:element.id});
        });
      //  this.Collector_List = response.data;
        this.CruCollector=this.Collector_List.filter(x=>x.collectorId==this.CollectorID);
        this.form.CollectorID=this.CruCollector[0].CollectorID;
        this.InsertForm.patchValue({
          CollectorID: this.CruCollector[0].CollectorID
      });
      this.InsertForm.get('CollectorID').updateValueAndValidity();
        // response.data.forEach(element => {
        //   this.Governorate_List[element.id] = element.title;
        // });
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }
  onOpen() {
   
  }
  getCollector() {
    this.update=false;
    this.getAllCollectorEmployeesApiService.GetCollector().subscribe(
      response => {
        response.data.forEach(element => {
          this.Collector_List.push({name:element.name,id:element.id});
        });
        // this.Collector_List = response.data;
        if(this.CollectorID){
          this.CruCollector=this.Collector_List.filter(x=>x.id==this.CollectorID)
        }
        // response.data.forEach(element => {
        //   this.Governorate_List[element.id] = element.title;
        // });
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }
  InsertCollectorCity()
  {
    
    this.InsertForm.get('CityId').value.forEach(element => {
      this.City_Ids.push(element.id);
    })
    // this.collectorCityApiService.InsertCollectorCity({ cities:this.City_Ids ,employeeId:this.InsertForm.get('CollectorID').value} as InsertCollectorCity  ).subscribe(
      // this.collectorCityApiService.InsertCollectorCity({ cities:this.City_Ids ,employeeId:this.InsertForm.get('CollectorID').value} as InsertCollectorCity  ).subscribe(
        this.collectorCityApiService.InsertCollectorCity({ cities:this.City_Ids ,employeeId:this.CollectorID} as InsertCollectorCity  ).subscribe(

      // response=>{
        
        response=>{
          Swal.fire({
            icon: 'success',
            title: "تم إدخال موظف ومدينه التحصيل بنجاح",
            showConfirmButton: false,
            timer: 1500
          })
          this.router.navigateByUrl("content/admin/Get-Collector-City");
        },
        err=>{
        }
    )


    //////////////
    //this.City_Ids=this.selectedCities;



    // this.InsertForm.get('CityID').value.forEach(element => {
          
    //   this.City_Ids.push(element.id);
      
    // })


    // this.InsertForm.get('City').value.forEach(element => {
          
    //   this.City_Ids.push(element.id);
    //   this.City_Ids.push(element.id);

    // })

  
    // if(this.City_Ids == -1 ||this.Collector_id==-1){
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'خطأ',
    //     text: "أختر المدينه وموظف التحصيل أولا",
    //   })
    // }
    // // }else if (this.Collector_id==-1)
    // // {
    // //   Swal.fire({
    // //     icon: 'error',
    // //     title: 'خطأ',
    // //     text: "أختر موظف التحصيل أولا",
    // //   })
    // // }
    // else
    // {
   
    // this.collectorCityApiService.InsertCollectorCity( {cities :this.City_Ids,employeeId: this.Collector_id }as InsertCollectorCity  ).subscribe(
    //   // response=>{
        
    //     response=>{
    //      // console.log("the selected city ",this.City_Ids)
    //       Swal.fire({
    //         icon: 'success',
    //         title: "تم إدخال موظف ومدينه التحصيل  بنجاح",
    //         showConfirmButton: false,
    //         timer: 1500
    //       })
    //       this.router.navigateByUrl("content/admin/Get-Collector-City");
    //     },
    //     err=>{
         
    //     }
          
    //     // this.callClient =[];
    
    // )
    // }
  }
  UpdateCollectorities()
  {
    let id = +this.route.snapshot.paramMap.get('id');
    this.collectorCityApiService.GetAllCitiesCollector(id).subscribe(
    
       response => {
        this.Collector_id = response.data['collectorId'];
       
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
      // (data){
      //   this.City_Ids=
      // }
    )
    

      this.InsertForm.get('CityId').value.forEach(element => {
      this.City_Ids.push(element.id);
    
})
    this.collectorCityApiService.UpdateCollectorCity({
     //  id :+this.route.snapshot.paramMap.get('id'),
       //collectorId: this.InsertForm.get('CollectorID').value,
       employeeId: this.id,
       cities:this.City_Ids
     //governorateId:this.Govern_id

    } as UpdateCollectorCity).subscribe(
      response => {
        Swal.fire({
          icon: 'success',
          title: "تم تعديل موظف ومدينه التحصيل بنجاح",
          showConfirmButton: false,
          timer: 1500,
         // Govern_id: response.Data.governorateId
          
        })
        this.router.navigateByUrl("content/admin/Get-Collector-City");
        
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }

  onItemSelect(item: any) {
   
  }

  onSelectAll(items: any) {
    //console.log(items);
  }

}
