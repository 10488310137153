import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-statistics-agent',
  templateUrl: './statistics-agent.component.html',
  styleUrls: ['./statistics-agent.component.css']
})
export class StatisticsAgentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
