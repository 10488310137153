import { Router } from '@angular/router';
import { EmployeesCommissionReport } from '../../../../../shared//Models/EmployeesCommissionReport';
import { FormGroup, FormControl } from '@angular/forms';
import { DonationRequestsService } from '../../../../../shared/API-Service/donation-requests.service';
import { Component, OnInit } from '@angular/core';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-employees-commission-report',
  templateUrl: './employees-commission-report.component.html',
  styleUrls: ['./employees-commission-report.component.css']
})
export class EmployeesCommissionReportComponent implements OnInit {
  Getcollectors
  EmployeesCommissionReport = new EmployeesCommissionReport()
  currentUser: any;
  Companies: any[];
  selectedYear: string;
  showCalendar: boolean = false;
  Months=[{
    id:1,
    name:'1'
  },
  {
    id:2,
    name:'2'
  },{
    id:3,
    name:'3'
  },{
    id:4,
    name:'4'
  },{
    id:5,
    name:'5'
  },{
    id:6,
    name:'6'
  },{
    id:7,
    name:'7'
  },{
    id:8,
    name:'8'
  },{
    id:9,
    name:'9'
  },{
    id:10,
    name:'10'
  },{
    id:11,
    name:'11'
  },{
    id:12,
    name:'12'
  },

]
Years=[{
  id:1,
  name:'2021'
},
{
  id:2,
  name:'2022'
},{
  id:3,
  name:'2023'
},{
  id:4,
  name:'2024'
},{
  id:5,
  name:'2025'
},{
  id:6,
  name:'2026'
},{
  id:7,
  name:'2027'
},{
  id:8,
  name:'2028'
},{
  id:9,
  name:'2029'
},{
  id:10,
  name:'2030'
}
]
  constructor(private router:Router) {
    this.currentUser=JSON.parse(localStorage.getItem("RiskCurrentUser"));
    this.EmployeesCommissionReport.reportCreatedBy=this.currentUser.name;

    
   }
   SelectMonth(event){
    this.EmployeesCommissionReport.month=event
   }
   
   SelectYear(event){
    this.EmployeesCommissionReport.year=event
   }
  ngOnInit(): void {
 
  }

  onKeyDown(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
    if (!allowedKeys.includes(event.key) || this.selectedYear.toString().length >= 4) {
      event.preventDefault();
    }
  }

  submit(){
    
  if(this.EmployeesCommissionReport.month&&this.EmployeesCommissionReport.year){

     localStorage["ReportName"]="EmployeesCommissionReport";
     localStorage.setItem('ReportFilter',JSON.stringify(this.EmployeesCommissionReport));
   
     this.router.navigate([]).then((result) => {
       window.open("/content/admin/call-start-end-report", '_blank');
     });

  }
  else{
    Swal.fire({
      icon:'error',
      title:'خطأ',
      text:'يجب اختيار الشهر والسنه'
  
    })
  }

  }

}
