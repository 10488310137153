
<app-breadcrumb [title]="'إدارة الوظائف'"  [active_item]="'Form Wizard'"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
            <div class="card-header" style="padding-bottom:5px !important ;">
                <div *ngIf="update; else elseBlock1">
                  <h5 class="pb-2">تعديل وظيفه موجودة</h5>
                 
                </div>
              <ng-template #elseBlock1>
                <h5 class="pb-2">إضافة وظيفه جديده</h5>
                
                </ng-template>
    
            </div>

            <div class="card-body">
                <form  [formGroup]="InsertForm" (ngSubmit)="onSubmit()" [ngClass]="{'was-validated': validated}">
                    <div class="col-12 mb-3">
                        <label for="validationCustom01">أسم الوظيفه</label>
                        <input class="form-control" id="validationCustom01" type="text" 
                          formControlName="title" 
                          required=""><!-- onlyAlphabets -->
                          
                          <div class="valid-feedback">حسنًا</div>
      
                      </div>

                      <div *ngIf="update; else elseBlock">
                        <button  class="btn btn-primary pull-right" 
                        type="submit"
                      >
                        تعديل
                     </button>
                    </div>
                    <ng-template #elseBlock>
                        <button  class="btn btn-primary pull-right" 
                        type="submit"
              >
                        إضافة
                     </button>
                    </ng-template>
                </form>
            </div>
            </div>
      </div>
     
    </div>
</div>    