import { Component, ViewEncapsulation, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { Roles } from '../../Models/Roles';
import { PermissionsApiService } from 'src/app/shared/API-Service/Permissions-api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {
  public New: boolean;
  public iconSidebar;
  public menuItems: any[];
  public menuRolus: any[] = [];
  public donationRolus: any[];
  public url: any;
  public fileurl: any;
  // For Horizontal Menu
  public margin: any = 0;
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;

  Role: any;
  err: boolean;
  MenuByRoleId: any[] = [];
  Menu: any[] = [];
  MenuArry: any[] = [];
  children: any[] = [];
  actionId: any;
  MenuAction4: any[] = [];
  children2: any[] = [];
  MenuArry2: any[] = [];
  menuRolus2: any[] = [];

  constructor(private router: Router, public navServices: NavService, private PermissionsApiService: PermissionsApiService,
    public layout: LayoutService) {
    this.Role = localStorage.getItem('RiskRole');
    this.New = true;
    debugger
    this.menuRolus = JSON.parse(localStorage.getItem('RiskmenuRolus'));

    // if (this.menuRolus.length <= 1) {
    //   this.PermissionsApiService.GetByRoleId().subscribe({
    //     next: (data) => {
    //       var res = data;
    //       if (res.messageCode == 200) {
    //         this.err = false;
            
    //         this.MenuByRoleId = res.data.menus
    //         this.MenuByRoleId.forEach(element => {
    //           this.children = [];
    //           element.childMenu.forEach(child => {

    //             const action4 = child.actions.filter(x => x.actionId == 4)[0];
    //             this.children.push({ MenuAction: child.actions, actionId: action4 != undefined ? action4.actionId : null, path: child.url, title: child.name, type: "link" });
    //             this.children2.push({ MenuAction: child.actions, actionId: action4 != undefined ? action4.actionId : null, path: child.url, title: child.name, type: "link" });
    //           });


    //           this.MenuArry.push({ Veiw: element.Perrmission, Parentid: element.parentId, title: element.name, path: element.url, children: this.children, icon: element.icon });
    //         })

    //         localStorage.setItem("MenuByRoleId", JSON.stringify(this.children2));

    //         this.menuRolus.push(
    //           {
    //             headTitle1: "لوحة التحكم ",
    //             headTitle2: "إدارة المحتويات الموجودة",
    //           },
    //         );
    //         this.MenuArry.forEach(element => {

    //           this.menuRolus.push(
    //             //   {
    //             //   headTitle1: "لوحة التحكم ",
    //             //   headTitle2: "إدارة المحتويات الموجودة",
    //             // },
    //             {
    //               title: element.title,
    //               icon: element.icon,
    //               type: "sub",
    //               badgeType: "success",
    //               Veiw: element.Veiw,
    //               children: element.children
    //             },
    //           );

    //         });




    //         //    this.menuRolus2.push(
    //         //     {
    //         //     headTitle1: "لوحة التحكم ",
    //         //     headTitle2: "إدارة المحتويات الموجودة",
    //         //   },
    //         //   );
    //         //  this.MenuArry2.forEach(element => {

    //         //         this.menuRolus2.push(
    //         //   //   {
    //         //   //   headTitle1: "لوحة التحكم ",
    //         //   //   headTitle2: "إدارة المحتويات الموجودة",
    //         //   // },
    //         //     {
    //         //       title: element.title,
    //         //       icon:element.icon,
    //         //       type: "sub",
    //         //       badgeType: "success",
    //         //       Veiw: element.Veiw,
    //         //       children: element.children
    //         //     },
    //         //   );

    //         //  });



    //         //             this.PermissionsApiService.GetMenu().subscribe({
    //         //               next: (data) => {
    //         //                var res = data;
    //         //                 if (res.messageCode == 200) {
    //         //                   this.err = false;
    //         //                   this.Menu=res.data;

    //         //                   this.MenuByRoleId.forEach(Menuelement => {
    //         //                  const action4=   Menuelement.actions.filter(x=>x.actionId==4);
    //         //                  if(action4.length!=0){


    //         //                   this.MenuAction4.push({Name:Menuelement.name
    //         //                   })
    //         //                  }
    //         //                   });

    //         //                   this.MenuByRoleId.forEach(element => {

    //         //                     this.children.push({MenuAction:element.MenuAction,actionId:element.actionId,path: element.url, title: element.name, type: "link" });
    //         //                     this.children2.push({MenuAction:element.MenuAction,actionId:element.actionId, path: element.url, title: element.name, type: "link" });
    //         //                     this.MenuArry2.push({Veiw:element.Perrmission,Parentid:element.parentId,title: element.ParentName,path: element.url,children: this.children2, icon:element.icon});
    //         //                     this.MenuArry.push({Veiw:element.Perrmission,Parentid:element.parentId,title: element.ParentName,path: element.url,children: this.children, icon:element.icon});


    //         //                  let haveView=  this.MenuAction4.filter(x=>x.Name == element.name);
    //         //                  if(haveView.length!=0){
    //         //                   element["haveView"]=true;
    //         //                  }
    //         //                  else{
    //         //                   element["haveView"]=false;
    //         //                  }
    //         //                     if(element.parentId!=null){
    //         //                       const ParentName =this.Menu.filter(c=>c.id==element.parentId)
    //         //                       element["ParentName"]=ParentName[0].name;
    //         //                     } 
    //         //                     const MenuAction4 = this.MenuAction4.filter(x=>x.Name==element.name);

    //         //                     this.actionId = element.actions.filter(x=>x.actionId==4);

    //         //                     if(this.actionId.length!=0){
    //         //                       element["MenuAction"]=MenuAction4[0].Name;
    //         //                       element["actionId"]=this.actionId[0].actionId;
    //         //                     }
    //         //                     else{

    //         //                       element["actionId"]=null;

    //         //                     }
    //         //                     const SameParentName = this.MenuArry.filter(x=>x.Parentid==element.parentId);
    //         //                     const SameParentName2 = this.MenuArry2.filter(x=>x.Parentid==element.parentId);


    //         //                     if(this.actionId.length!=0){
    //         //                       if(SameParentName.length!=0){
    //         //                         if(element.haveView&&this.actionId.length==0){
    //         //                        this.children2.push({MenuAction:element.MenuAction,actionId:element.actionId, path: element.url, title: element.name, type: "link" })
    //         //                         }
    //         //                         else{
    //         //                this.children.push({MenuAction:element.MenuAction,actionId:element.actionId,path: element.url, title: element.name, type: "link" })
    //         //                         }
    //         //                        }
    //         //                        else{
    //         //                         if(element.haveView&&this.actionId.length==0){
    //         //                           this.children2=[{MenuAction:element.MenuAction,actionId:element.actionId, path: element.url, title: element.name, type: "link" }]

    //         //                         }
    //         //                         else{
    //         //                          this.children=[{MenuAction:element.MenuAction,actionId:element.actionId, path: element.url, title: element.name, type: "link" }]
    //         //                         }
    //         //                          this.MenuArry.push({Veiw:element.Perrmission,Parentid:element.parentId,title: element.ParentName,path: element.url,children: this.children, icon:element.icon})
    //         //                         }

    //         //                         if(SameParentName2.length!=0){
    //         //                  this.children2.push({MenuAction:element.MenuAction,actionId:element.actionId,path: element.url, title: element.name, type: "link" })

    //         //                          }
    //         //                          else{
    //         //                             this.children2=[{MenuAction:element.MenuAction,actionId:element.actionId, path: element.url, title: element.name, type: "link" }]
    //         //                            this.MenuArry2.push({Veiw:element.Perrmission,Parentid:element.parentId,title: element.ParentName,path: element.url,children: this.children2, icon:element.icon})
    //         //                           }
    //         //                     }
    //         //                     else {
    //         //                       if(SameParentName.length!=0){
    //         //                         if(element.haveView&&this.actionId.length==0){
    //         //                           this.children2=[{MenuAction:element.MenuAction,actionId:element.actionId, path: element.url, title: element.name, type: "link" }]

    //         //                         }
    //         //                         else{
    //         //                            this.children.push({MenuAction:element.MenuAction,actionId:element.actionId, path: element.url, title: element.name, type: "link" })

    //         //                         }
    //         //                       }
    //         //                        else{
    //         //                         if(element.haveView&&this.actionId.length==0){
    //         //                           this.children2=[{MenuAction:element.MenuAction,actionId:element.actionId, path: element.url, title: element.name, type: "link" }]

    //         //                         }
    //         //                         else{
    //         //                            this.children=[{MenuAction:element.MenuAction,actionId:element.actionId,path: element.url, title: element.name, type: "link" }]

    //         //                         }
    //         //                          this.MenuArry.push({Veiw:element.Perrmission,Parentid:element.parentId,title: element.ParentName,path: element.url,children: this.children, icon:element.icon})
    //         //                        }




    //         //                        if(SameParentName2.length!=0){
    //         //                           this.children2.push({MenuAction:element.MenuAction,actionId:element.actionId, path: element.url, title: element.name, type: "link" })
    //         //                       }
    //         //                        else{
    //         //                           this.children2=[{MenuAction:element.MenuAction,actionId:element.actionId, path: element.url, title: element.name, type: "link" }]
    //         //                          this.MenuArry2.push({Veiw:element.Perrmission,Parentid:element.parentId,title: element.ParentName,path: element.url,children: this.children2, icon:element.icon})
    //         //                        }
    //         //                     }
    //         //                   });

    //         //                   this.menuRolus.push(
    //         //                     {
    //         //                     headTitle1: "لوحة التحكم ",
    //         //                     headTitle2: "إدارة المحتويات الموجودة",
    //         //                   },
    //         //                   );
    //         //                  this.MenuArry.forEach(element => {

    //         //                         this.MenuByRoleId.push(
    //         //                   //   {
    //         //                   //   headTitle1: "لوحة التحكم ",
    //         //                   //   headTitle2: "إدارة المحتويات الموجودة",
    //         //                   // },
    //         //                     {
    //         //                       title: element.title,
    //         //                       icon:element.icon,
    //         //                       type: "sub",
    //         //                       badgeType: "success",
    //         //                       Veiw: element.Veiw,
    //         //                       children: element.children
    //         //                     },
    //         //                   );

    //         //                  });




    //         //                  this.menuRolus2.push(
    //         //                   {
    //         //                   headTitle1: "لوحة التحكم ",
    //         //                   headTitle2: "إدارة المحتويات الموجودة",
    //         //                 },
    //         //                 );
    //         //                this.MenuArry2.forEach(element => {

    //         //                       this.menuRolus2.push(
    //         //                 //   {
    //         //                 //   headTitle1: "لوحة التحكم ",
    //         //                 //   headTitle2: "إدارة المحتويات الموجودة",
    //         //                 // },
    //         //                   {
    //         //                     title: element.title,
    //         //                     icon:element.icon,
    //         //                     type: "sub",
    //         //                     badgeType: "success",
    //         //                     Veiw: element.Veiw,
    //         //                     children: element.children
    //         //                   },
    //         //                 );

    //         //                });

    //         //                   // localStorage.setItem('RiskuserMenu',this.MenuByRoleId)
    //         localStorage.setItem('RiskmenuRolus', JSON.stringify(this.menuRolus))
    //         //                   const uniqueValues = new Set(this.MenuByRoleId.map(v => v.ParentName));
    //         // // if (uniqueValues.size < this.MenuByRoleId.length) {
    //         // // }
    //         // //                 } else {
    //         // //                   this.err = true;
    //         // //                 }
    //         // //               },
    //         // //               error: (error) => {
    //         // //                 this.err = true;
    //         // //               },
    //         //             });
    //         // localStorage.setItem('RiskuserMenu',this.MenuByRoleId)


    //       } else {
    //         this.err = true;
    //       }
    //     },
    //     error: (error) => {
    //       this.err = true;
    //       Swal.fire({
    //         icon: 'error',
    //         title: 'خطأ',
    //         text: error.error.message,
    //       })
    //     },
    //   }
    //   );
    // }



    // this.UserRole =  Roles.Agent;
    this.navServices.items.subscribe((res) => {
      // this.menuRolus = res;



      // this.navServices.itemss.subscribe((res)=>{
      //   this.donationRolus = res
      // })


      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          res.filter(items => {
            if (items.path === event.url) {
              this.setNavActive(items);
            }
            if (!items.children) { return false; }
            items.children.filter(subItems => {
              if (subItems.path === event.url) {
                this.setNavActive(subItems);
              }
              if (!subItems.children) { return false; }
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url) {
                  this.setNavActive(subSubItems);
                }
              });
            });
          });
        }
      });
    });
  }

  ngOnInit(): void {

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth - 500;
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuRolus.forEach(a => {
        if (this.menuRolus.includes(item)) {
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }

  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }
  checking(menuItem: any) {
    if (menuItem?.type === 'sub' && menuItem.children.filter(x => x.actionId == 4).length>0)
      return true;
    else
      return false

  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }

  counter(i: number) {
    return new Array(i);
  }

}
