import { HttpDonationTypeCreate, HttpDonationTypeRespons, HttpDonationTypeDelete } from './../Models/http-donation-type-select';
import { Injectable } from '@angular/core';
import { GenericResponse } from "./../Models/GenericResponse";
import { Observable, BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment as evn } from "../../../environments/environment.prod";

@Injectable({
  providedIn: 'root'
})
export class DonationTypeApiService {
  public Data = new BehaviorSubject(null);
  constructor(private http: HttpClient) {}
  get() {
    return this.http.get(
      `${evn.Server_URL}/DonationType/Get`
    ) as Observable<HttpDonationTypeCreate>;
  }

  //Donation Category/Get
  getDonationCategory() {
    return this.http.get(
      `${evn.Server_URL}/DonationCategory/Get`
    ) as Observable<any>;
  }
  GetDonationTypeList(data:any): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(`${evn.Server_URL}/DonationType/GetDonationTypeList`,data);
  }
 
  getById(id) {
    return this.http.get(
      `${evn.Server_URL}/DonationType/GetById
      `,id
    ) as Observable<any>;
  }
  
  post(data: HttpDonationTypeCreate): Observable<GenericResponse<HttpDonationTypeCreate>> {
    return this.http.post<GenericResponse<HttpDonationTypeCreate>>(
      `${evn.Server_URL}/DonationType/Create`,
      data
    );
  }
  
  Put(data: any): Observable<GenericResponse<HttpDonationTypeRespons>> {
    return this.http.post<GenericResponse<HttpDonationTypeRespons>>(
      `${evn.Server_URL}/DonationType/Update`,
      data
    );
  }

  
  Delete(data:number): Observable<GenericResponse<HttpDonationTypeDelete>> {
    return this.http.get<GenericResponse<HttpDonationTypeDelete>>(
      `${evn.Server_URL}/DonationType/Delete?id=${data}`
      
    );
  }
 

}
// HttpDonationTypeSelect