export class FollowUpCalls{
    companyId:number=null as any;
    callDateFrom:string = '';
    callDateTo:string = '';
    reportCreatedBy:string='';
    employeeId:number=null as any;
    employeeName:string=''
}





