export class CustomerInfo {
    governorateId:number= null
    governorateName:string=''
    cityId:number= null
    cityName:string=''
    reportCreatedBy:string='';
    companyId:number= null
    fromDate :Date =null
    toDate :Date =null
    vip:boolean=false
    companyName:string='اكرام'
}