<app-breadcrumb [title]="'تقرير مكالمات خدمة العملاء'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'">
</app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
            <!-- <form action="" [formGroup]="createReports"> -->
                <div class="card-header px-0 " style="padding-bottom: 5px;">
                    <div class="row m-0 mb-2">
                      <div class="col-12 col-md-4">
                        <label for="validationCustom02"><span class="validation-required">*</span>  اسم الشركة</label>
                        <br>
                        <label *ngIf="currentUser.roleId==10">{{currentUser.name}}</label>
                  <ng-select *ngIf="currentUser.roleId!=10"
                  class="js-example-basic-single col-sm-12 job-select2 form-group"
                    required=""
                    [(ngModel)]="CustomerServicesCalls.companyId"
                    aria-label="Default select example"
                    (change)="SelectCompany($event.id)"
                    [items]="Companies"
                    bindLabel="name"
                    bindValue="id"
                     >
                  
                  </ng-select>
                      </div>
                        <div class="col-md-4">
                          <label for="">  تاريخ المكالمات من
                          </label>
                            <div class="form-group">
                                
                               <input type="date"  class="form-control" [(ngModel)]="CustomerServicesCalls.creationDateFrom">
                            </div>
                        </div>
                        <div class="col-md-4">
                          <label for=""> تاريخ المكالمات الي</label>
                            <div class="form-group">
                             
                               <input type="date"  class="form-control" [(ngModel)]="CustomerServicesCalls.creationDateTo">
                            </div>
                        </div>
        
                        <div class="col-4  mb-3">
                          <div class="" style="display: flex; justify-content: start;"><label for="">اسم الموظف</label></div>
                          
                          <ng-select  class="js-example-basic-single" (change)="selectEmplyee($event.id)" [(ngModel)]="CustomerServicesCalls.employeeId"
                          
                          [items]="getEmployee"
                          bindLabel="name"
                          bindValue="id"
                          >
                          </ng-select>
                      </div>
                    <div class="d-flex justify-content-end align-items-center buttom-text col-12 mb-3">
                      <button class="addnew-btn" type="submit" (click)="Viewreports()">عرض التقرير</button>
                    </div>
                </div>
            <!-- </form> -->
    </div>
</div>
        </div>
    </div>
</div>