export class GetCallCustomerDate{
    callReasonId:number=null;
    companyId:number=null;
    sourceOfMarktingId:number=null;
    callTypeId:number=null;
    callDateFrom:string = '';
    callDateTo:string = '';
    callReasonName:string = '';
    callTypeName:string = '';
    sourceOfMarketingName:string = '';
    reportCreatedBy:string='';
    createdByName:string='';
    createdById:number=null;
    answered:boolean
 
}





