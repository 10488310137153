import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { ChangePassWord } from '../Models/change-pass-word';
import { GenericResponse } from '../Models/GenericResponse';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';

@Injectable({
  providedIn: 'root'
})
export class ChangepasswordService {

  constructor(private http:HttpClient) { }

    
  changepassword(Data:ChangePassWord): Observable<GenericResponseSingle<any>> {
    
    return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/User/ChangePassword?oldPassword=${Data.oldPassword}&newPassword=${Data.newPassword}`,Data);
  }

}

