<app-breadcrumb [title]="'إرسال رساله لمجموعة عملاء'" [active_item]="'Form Wizard'"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                        <h5 class="pb-2">إرسال رساله لمجموعة عملاء </h5>
                        يمكنك إرسال رساله لمجموعة من العملاء
                </div>

                <form class="needs-validation" [formGroup]="InsertForm" (ngSubmit)="onSubmit()" novalidate
                    [ngClass]="{ 'was-validated': validated }">


                    <div class="card-body container row col-12">
                        <div class="row">
                            <!-- name -->
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="name"> أسم العميل </label>
                                    <input class="form-control" id="name" type="text" placeholder="ادخل اسم العميل"
                                        formControlName="name" required="" />
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>انواع فئات التبرع</label>
                                    <ng-select class="js-example-basic-single" formControlName="DonationType" [(ngModel)]="BulkSms.DonationType"
                                        aria-label="Default select example" required=""
                                        [items]="DonationType"
                                        bindLabel="title"
                                        bindValue="id"
                                        >
                                    </ng-select>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label> إداره النشاط</label>
                                    <ng-select class="js-example-basic-single" formControlName="Sectors" [(ngModel)]="BulkSms.Sectors"
                                        aria-label="Default select example" required="" (change)="SelectedSectors($event.id)"
                                        [items]="Sectors"
                                        bindLabel="title"
                                        bindValue="id"
                                        >
                                    </ng-select>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>إداره المشروعات</label>
                                    <ng-select class="js-example-basic-single" formControlName="SectorType" [(ngModel)]="BulkSms.SectorType"
                                        aria-label="Default select example" required="" (change)="SelectedSectorType($event.id)"
                                        [items]="SectorType"
                                        bindLabel="title"
                                        bindValue="id"
                                        >
                                    </ng-select>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>إداره اماكن المشروعات</label>
                                    <ng-select class="js-example-basic-single"formControlName="SectorLocation" [(ngModel)]="BulkSms.SectorLocation"
                                        aria-label="Default select example" required=""
                                        [items]="SectorLocation"
                                        bindLabel="title"
                                        bindValue="id"
                                        >
                                    </ng-select>
                                </div>
                            </div>

                            <!-- GovernorateId -->
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>المحافظة</label>
                                    <ng-select class="js-example-basic-single" formControlName="Governorates" [(ngModel)]="BulkSms.Governorates"
                                        aria-label="Default select example" required=""
                                        (change)="SelectedGovernorate($event.id)"
                                        [items]="Governorates"
                                        bindLabel="title"
                                        bindValue="id"
                                        >
                                    </ng-select>
                                </div>
                            </div>

                            <!-- cityId -->
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label> المدينة</label>
                                    <ng-select class="js-example-basic-single" formControlName="Cities" [(ngModel)]="BulkSms.Cities"
                                        aria-label="Default select example" required=""
                                        [items]="Cities"
                                        bindLabel="title"
                                        bindValue="id"
                                        >
                                    </ng-select>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>نوع العميل</label>
                                    <ng-select class="js-example-basic-single" formControlName="ClientType"  [(ngModel)]="BulkSms.ClientType"
                                        aria-label="Default select example" required="">
                                        <ng-option selected value=null selected>أختر نوع </ng-option>
                                        <ng-option value="{{1}}">ذكر </ng-option>
                                        <ng-option value="{{2}}">انثي</ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label> كيف</label>
                                    <ng-select class="js-example-basic-single" formControlName="SourceMarket" [(ngModel)]="BulkSms.SourceMarket"
                                        aria-label="Default select example" required=""
                                        [items]="SourceMarket"
                                        bindLabel="title"
                                        bindValue="id"
                                        >
                                    </ng-select>
                                </div>
                            </div>


                            <!-- address -->
                            <!-- <div class="col-md-6">
                <textarea formControlName="address" class="form-control textForArabic" placeholder="  "
                  style="height: 120px">
                </textarea>
              </div> -->

                        </div>

                        <!-- Actions -->
                        <div class="col-12">
                            <button class="btn btn-primary pull-right" type="submit">بحث</button>
                        </div>
                    </div>
                    <div class="table-responsive ">
                        <table class="table w-100  table-striped"  >
                            <thead>
                                <tr class="bg-dark " >
                                    <th scope="col" class="font-style-header" style="color: white;">#</th>
                                    <th scope="col" class="font-style-header" style="color: white;">اسم العميل</th>
                                    <th scope="col" class="font-style-header" style="color: white;">رقم الموبايل</th>
                                    <th scope="col" class="font-style-header" style="color: white;">العنوان</th>
                                    <th scope="col" class="font-style-header" style="color: white;">تاريخ الميلاد</th>
                                    <!-- <th scope="col" class="font-style-header"   >العنوان</th> -->
                                    <!-- <th scope="col" class="font-style-header"></th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of customers; let i = index">
                                    <td scope="row" class="font-style">{{ i + 1 }}</td>
                                    <td class="font-style">{{ item.name }}</td>
                                    <td class="font-style" style="text-align: center;">{{item.mobile}}
                                        <br>
                                        <div ngbDropdown class="social-media" style="padding: 5px;">
                                            <a ngbDropdownToggle (click)="$event.stopPropagation()"><i class="fa fa-phone" style="color: green;"></i></a>
                                            <div ngbDropdownMenu aria-labelledby="dropdownConfig" >
                                              <a  (click)="$event.stopPropagation()"[href]="'tel:' + item.mobile" ngbDropdownItem>{{item.mobile}}</a>
                                                  <ng-container  *ngFor="let mobile of item.mobiles">
                                                    <a (click)="$event.stopPropagation()" [href]="'tel:' + mobile" ngbDropdownItem>{{mobile}}</a>
                                                  </ng-container>
                                            </div>
                                         <a [href]="'//wa.me/+2' + item.mobile" target="_blank"><i class="fa fa-whatsapp" style="padding: 0 17px; color: #2cc198;" ></i></a>               
                                         <!-- <i class="fa fa-comment"></i> -->
                            
                                        </div>
                                      </td>   
                                                                       <td class="font-style">{{ item.address }}</td>
                                    <td class="font-style">{{ item.dateOfBirth | date:'dd/MM/yyyy'}}</td>
                                    <!-- <td    class="font-style">{{item.address}}</td> -->

                                    <!-- <td class="font-style chose">
                                        <button  style="color: red; padding: 7px"
                                            (click)="DeleteEmployee(item.id)">
                                            <i class="fa fa-trash"></i>
                                        </button>

                                        <button  style="color: rgba(35, 118, 241, 0.667); padding: 7px"
                                            (click)="updateEmployee(item.id, item)">
                                            <i class="fa fa-pencil"></i>
                                        </button>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                             <div class="form-group">
                        <label>الرساله المراد ارسالها</label>
                        <ng-select class="js-example-basic-single" formControlName="SmsTemplates" [(ngModel)]="BulkSms.SmsTemplates"
                            aria-label="Default select example" required=""
                            [items]="SmsTemplates"
                            bindLabel="smscontent"
                            bindValue="id"
                            >
                        </ng-select>
                    </div>
                        </div>
                        <div class="col-md-12">
                            <button class="btn btn-primary pull-right" [disabled]="customers.length==0" (click)="send()" type="button">ارسال</button>
                       </div>
                    </div>
                   
                </form>
            </div>
        </div>
    </div>
</div>