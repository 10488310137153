<app-breadcrumb
  [title]="'القائمه   التبرعات'"
  [active_item]="'Form Wizard'"
></app-breadcrumb>

<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <a routerLink="/content/admin/Inser-Donation-Reason/create" class="d-block">
        <button appActionNew class="btn btn-primary pull-right" type="button">
          <span class="m-2">جديد</span> <i class="fa fa-plus"></i>
        </button>
      </a>
    </div>
    <div class="col-sm-12">
      <div class="card">
        <div class="table-responsive">
          <table class="table" style="width: 100%">
            <thead>
              <tr class="bg-dark text-left" >
                <td scope="col" class="text-light">#</td>

                <th scope="col" class="text-light">  نوع التبرع</th>
                <th scope="col" class="text-light"> </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of response; let i = index; trackBy: track">
                <td style="width: 35%" class="font-style">{{i+1  }}</td>

           
                <td style="width: 35%" class="font-style">{{ item.title }}</td>
       <td class="font-style  chose" style="width: 100%">

                  <button
                
                  class="btn  pull-right"
                  
                  (click)="Delete(item.id)"
                  style="color: red; padding: 7px"

                
                  >
                  <i class="fa fa-trash"></i>

                </button>
           
                  <button
                  class="btn  pull-right"
                  
                    style="color: rgba(35, 118, 241, 0.667); padding: 7px"
                    (click)="Update(item)"
                  >
                    <i class="fa fa-pencil"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

 