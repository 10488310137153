import { DonationMTypeApiService } from "./../../../../shared/API-Service/donation-m-type-api.service";
import { Component, OnInit, Injector } from "@angular/core";
import Swal from "sweetalert2";

import { FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
@Component({
  selector: "app-insert-donation-m-type",
  templateUrl: "./insert-donation-m-type.component.html",
  styleUrls: ["./insert-donation-m-type.component.css"],
})
export class InsertDonationMTypeComponent implements OnInit {
  title = new FormControl("", Validators.required);

  validate: boolean;

  // Variables
  update: boolean = false;
  dataValue: {} = {};

  constructor(
    private _DonationMTypeApiService: DonationMTypeApiService,
    private _router: Router,
    private _ActivatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this._DonationMTypeApiService.Data.subscribe((res) => {
      if (this._DonationMTypeApiService.Data.getValue() != null) {

        this.dataValue = res.id;
        this.title.patchValue(res.title);
        this.update = true;
      } else {
        // this.title.reset();
      }
    });
    const type = this._ActivatedRoute.snapshot.params["id"];

    if (type == "create") {
      this.update = false;
      this.title.reset();
    } else {
      this.update = true;
    }
  }
  containsNumber(str) {
    const specialChars = /[/\d/٠١٢٣٤٥٦٧٨٩]/;
    return specialChars.test(str);
  }
  //#endregion
  containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }
  onSubmit() {
  //   if(this.containsSpecialChars(this.title.value)==true){
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'خطأ',
  //       text: "لا يمكن كتابه علامات ",
  //     })
  //   }
  //   else if (this.containsNumber(this.title.value)==true){
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'خطأ',
  //       text: "لا يمكن كتابه ارقام ",
  //     })
  //   }
  // else
    if (this.title.valid) {
      this.validate = false;


      if (this.update == true) {
        this.Edit();
      } else {
        this.insertData();
      }

      //
    } else {
      this.validate = true;
    }
  }

  insertData() {
    const obj = { title: this.title.value };

    this._DonationMTypeApiService.post(obj).subscribe((res) => {
      if (res) {
        this.title.reset();
        Swal.fire({
          icon: "success",
          title: "تم إدخال النوع بنجاح",
          showConfirmButton: false,
          timer: 1500,
        });

        this._router.navigate(["/content/admin/Get-Donation-Reason"]);
      } else {
    
      }
    });
  }
  Edit() {
    this.title.value;

    const obj = { id: this.dataValue, title: this.title.value };

    this._DonationMTypeApiService.Put(obj).subscribe((res) => {
      if (res) {
        Swal.fire({
          icon: "success",
          title: "تم التعديل   بنجاح",
          showConfirmButton: false,
          timer: 1500,
        });
        this.title.reset();
        this._router.navigate(["/content/admin/Get-Donation-Reason"]);
        this._DonationMTypeApiService.Data.next(null);
      } else {
     
      }
    });
  }
}
