<app-breadcrumb
  [title]="'إدارة المكاتب'"
  [active_item]="'Form Wizard'"
></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding-bottom: 5px !important ">
          <div *ngIf="update; else elseBlock1">
            <h5 class="pb-2">تعديل مكتب موجودة</h5>
            يمكنك تعديل مكتب أو أكثر فى المكاتب
          </div>
          <ng-template #elseBlock1>
            <h5 class="pb-2">إضافة مكتب جديد</h5>
            يمكنك إضافة مكتب أو أكثر فى المكاتب
          </ng-template>
        </div>
        <div class="card-body">
          <form class="needs-validation" [formGroup]="InsertForm" (ngSubmit)="onSubmit()" novalidate [ngClass]="{'was-validated': validated}">

            <div class="form-row">
              <div class="col-12 mb-3">

              <label for="validationCustom01">  الاسم</label>
              <input
                class="form-control"
                id="validationCustom01"
                type="text"
                placeholder=""
                required=""
                formControlName="Title"
              />
              


             
            </div>

            </div>

            <div class="form-row">
              <div class="col-12 mb-3">

              <label for="validationCustom02">العنوان</label>
              <input class="form-control" type="text" required="" formControlName="address" id="validationCustom02" />
            </div> 
            </div>
    
            <div *ngIf="update; else elseBlock">
              <button
                class="btn btn-primary pull-right"
                type="button"
                (click)="onSubmit()"               
                [disabled]="disableBtn"
                >
                تعديل
              </button>
            </div>
            <ng-template #elseBlock>
              <button
                class="btn btn-primary pull-right"
                type="button"
                (click)="onSubmit()"
                [disabled]="disableBtn"
              >
                إضافة
              </button>
            </ng-template>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

