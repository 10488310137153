import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ProjectMedia } from '../../../../../shared//Models/ProjectMedia';
import {MediaApiService} from 'src/app/shared/API-Service/media-api.service'
import { SectorTypeApiService } from 'src/app/shared/API-Service/sector-type-api.service';
import { CustomerApiService } from 'src/app/shared/API-Service/customer-api.service';
import { environment } from 'src/environments/environment.prod';
import { SectorLocationApiService } from 'src/app/shared/API-Service/sector-location-api.service';

@Component({
  selector: 'app-insert-project-media',
  templateUrl: './insert-project-media.component.html',
  styleUrls: ['./insert-project-media.component.css']
})
export class InsertProjectMediaComponent implements OnInit {
  insertForm: FormGroup;
  MediaType = new ProjectMedia()
  id: any;
  update: boolean;
  MediaTypes: any;
  SectorLocation: any[]=[];
  images: any[]=[];
  imagesView: string | ArrayBuffer;
  haveImage: boolean;
  CreatMediaProjectPicForm: any = new FormData();
  ProjectMediaById: any;
  SectorLocationId: any;
  SectorLocationTitle: any;
  MediaTypeId: any;
  constructor(private CustomerApiService:CustomerApiService,private SectorLocationApiService:SectorLocationApiService,private MediaApiService:MediaApiService, private _formBuilder: FormBuilder,private router: Router,private route: ActivatedRoute) {
    this.GetMediaType();
    this.GetSectorLocations();
    this.haveImage=false;
   }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id")) {
      this.id =this.route.snapshot.paramMap.get("id")
      this.SectorLocationId=this.route.snapshot.paramMap.get("sectorlocationid");
    this.MediaApiService.GetProjectMediaById(this.id).subscribe(async (res:any)=>{
    this.ProjectMediaById=  await res.data;
    if(this.ProjectMediaById.mediaTypeId==7){
      this.haveImage=true;
      this.imagesView=environment.Server_URL_Image+this.ProjectMediaById.url
    }
    this.initForm(this.ProjectMediaById);

    })
      this.update = true;
    } else {
      this.SectorLocationId=this.route.snapshot.paramMap.get("sectorlocationid");
      this.initForm()
      this.update = false;
    }
    this.GetMediaType();
    this.GetSectorLocations();
    this.GetCustomerByMobile("01556279190")
      this.initForm()
    
  }
  GetCustomerByMobile(mobile){
    this.CustomerApiService.GetCustomerByphone(mobile).subscribe(res=>{
    })
    }
  initForm(ProjectMedia?:ProjectMedia) {
    this.insertForm = this._formBuilder.group({
      title: [ ProjectMedia?.title||'', Validators.required],
      order: [ProjectMedia?.order||'', Validators.required],
      mediaTypeId: [ProjectMedia?.mediaTypeId|| '', Validators.required],
      // SectorLocationId: [ProjectMedia?.sectorLocationId|| '', Validators.required],
      url: [ProjectMedia?.url|| '', Validators.required],
    });
  }
  getMediaId(e){
 this.MediaTypeId=e.id
 if(e.id==7||e.id==11){
   this.haveImage=true;
  
 }
 else{
   this.haveImage=false;
 }
  }

  onFileSelected(event, element) {
    // this.element = element
  //   this.haveImage = true;
    let filesAmount = event.target.files.length;
    let files = event.target.files;

    var reader = new FileReader();
    // this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.images.push(files[0]);
      this.imagesView = reader.result;
    }


  }

  deleteimage(imgindex) {
    this.images.splice(imgindex, 1);
    this.imagesView=""

  }

  OpenImage(imagesView){
    Swal.fire({
      imageUrl: imagesView,
      imageHeight: 400,
      imageAlt: 'A tall image',
      confirmButtonText:"الغاء"
    })
  }
  GetMediaType(){
    this.MediaApiService.GetProjectMediaType().subscribe(res=>{
     
      this.MediaTypes=res.data;
      this.MediaTypes= this.MediaTypes.sort((a, b) => a.order - b.order);
    })
  }


  GetSectorLocations(){
    this.SectorLocationApiService.GetSectorAllLocation().subscribe(res=>{
      this.SectorLocation=res.data
  this.SectorLocationTitle=this.SectorLocation.filter(x=>x.id==this.SectorLocationId)[0].title
    })
  }
  get fc() {
    return this.insertForm.controls;
  }
  UpdateMediaProject(){

  }
  Submit(){
    debugger
    if (this.update == true) {
      this.UpdateMediaProject();
    } else {
      if(this.haveImage){
        this.insertForm.get('url').clearValidators();
        this.insertForm.get('url').updateValueAndValidity();
        if (this.insertForm.status == "VALID") {
          this.InsertMediaProjectPic();
      }
       else {
        this.insertForm.markAllAsTouched();
      }
    
      }
      else{
        if (this.insertForm.status == "VALID") {
          this.InsertMediaProject();
      }
       else {
        this.insertForm.markAllAsTouched();
      }
    
      }
    }
  
 
  

  }
 
  InsertMediaProject(){
    const data ={
      "title": this.insertForm.get('title').value,
      "order": Number(this.insertForm.get('order').value),
      "url": this.insertForm.get('url').value,
      "mediaTypeId":Number(this.insertForm.get('mediaTypeId').value),
      "sectorLocationId": Number(Number(this.SectorLocationId)),

    }
    this.MediaApiService.CreateProjectMedia(data).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم الحفظ بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigate(['content/admin/list-project-accomplishment/',this.SectorLocationId]);

      this.insertForm.reset();


    },
      (err) => {
        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
            );
  }

  InsertMediaProjectPic(){
   
    this.images.forEach(element => {
      this.CreatMediaProjectPicForm.set('Image', element)
    });
    this.CreatMediaProjectPicForm.set('title',this.insertForm.get('title').value)
    this.CreatMediaProjectPicForm.set('order',this.insertForm.get('order').value)
    this.CreatMediaProjectPicForm.set('mediaTypeId',Number(this.insertForm.get('mediaTypeId').value))
    this.CreatMediaProjectPicForm.set('SectorLocationId', Number(this.SectorLocationId))
    this.MediaApiService.CreateProjectMediaPic(this.CreatMediaProjectPicForm).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم الحفظ بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigate(['content/admin/list-project-accomplishment/',this.SectorLocationId]);
      this.insertForm.reset();
      this.imagesView=""
      this.images=[];
      this.haveImage=false;
    },
      (err) => {
        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
            );
  }
}
