import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {AllBatches } from '../../../../shared//Models/AllBatches';
import { CompanyDataService } from 'src/app/shared/API-Service/company-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-all-batches',
  templateUrl: './all-batches.component.html',
  styleUrls: ['./all-batches.component.css']
})
export class AllBatchesComponent implements OnInit {
  AllBatches = new AllBatches()
  currentUser: any;
  Companies: any;
  constructor(private CompanyDataService:CompanyDataService,private router:Router) {

    this.currentUser=JSON.parse(localStorage.getItem("RiskCurrentUser"))
    if(this.currentUser.roleId==10){
      this.AllBatches.companyId=this.currentUser.id
    }
    this.AllBatches.reportCreatedBy=this.currentUser.name;
   }

  ngOnInit(): void {
    this.GetCompanies()

  }
  SelectCompany(CompanyId){
    this.AllBatches.companyId=parseInt(CompanyId);
  }
  GetCompanies(){
    this.CompanyDataService.GetCompanies().subscribe(res=>{
      this.Companies=res.data
    })
  }
  Viewreports(){
    if(this.AllBatches.companyId!=null){
      localStorage["ReportName"]="AllBatches";
      localStorage.setItem('ReportFilter',JSON.stringify(this.AllBatches));
    
      this.router.navigate([]).then((result) => {
        window.open("/content/admin/call-start-end-report", '_blank');
      });
     
    }
    else{
      Swal.fire({
        icon:'error',
        title:'خطأ',
        text:'يجب اختيار الشركة'
  
      })
    }
  }
  
    
}
