import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericResponse } from '../Models/GenericResponse';
import { GetClient } from '../Models/GetClient';
import { environment } from '../../../environments/environment.prod';
import { InsertClient } from '../Models/InsertClient';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
@Injectable({
  providedIn: 'root'
})
export class ChangeDonationCreatorService {

 
  constructor(private http:HttpClient) { }
  GetDonationForChangeCreatorList(data:any): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(`${environment.Server_URL}/Donation/GetDonationForChangeCreatorList`,data);
  }
  ChangeDonationCreator(data:any): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(`${environment.Server_URL}/Donation/ChangeDonationCreator`,data);
  }
  GetCustomerCallHistory(data:any): Observable<GenericResponse<GetClient>> {
    return this.http.post<GenericResponse<GetClient>>(`${environment.Server_URL}/Call/GetCustomerCallHistory`,data);
  }

}
