
<app-breadcrumb [title]="' متابعة المكالمات'" ></app-breadcrumb>
<div class="card-body ">
    <div class="row">

<!-- ===================================================================================================== -->
<!-- <div class="col-xl-3 col-sm-6 box-col-6">
<div class="card ecommerce-widget" style="height: 250px;">
<div class="card-body support-ticket-font">
  <div class="row">
    <div class="col-12">
      <div   style="text-align: right !important;"><h3 class="text-primary">مكالمات المتابعه اليوم</h3></div>
      <br>
    <br>
      </div>
  </div>
<div class="row">
<div class="col-12 numbers" >
<div class="text-md-right" >
  <ul style="text-align: right !important; ">
  </ul>
</div>
</div>
</div>
</div>

<ul class="list-inline " style="text-align: right !important; margin-top: 22px;">
<li class="list-inline-item p-2 text-primary" style="font-size: 30px !important;"> {{counts?.todayFollowUpCallsCount}}<span class="p-1" style="font-size: 10px !important;">مكالمه</span></li>
</ul>
<div class="progress-showcase mt-0">
<div class="progress sm-progress-bar">
<div class="progress-bar bg-primary" role="progressbar" style="width: 100%" aria-valuenow="25"
  aria-valuemin="0" aria-valuemax="100"></div>

</div>
<div appActionNew>
 <a  (click)="goToDetails('todayFollowUpCallsCount')" class="d-block">
<button  class="btn  btn-primary  btn-lg btn-block">التفاصيل</button>
</a> 
</div>

</div>
</div>
</div> -->




<div class="col-xl-3 col-sm-6 box-col-6">
  <div class="card ecommerce-widget" style="height: 250px;">
  <div class="card-body support-ticket-font">
    <div class="row">
      <div class="col-12">
        <div   style="text-align: right !important;"><h3 class="text-primary">مكالمات تم متابعتها</h3></div>
        <br>
      <br>
        </div>
    </div>
  <div class="row">
  <div class="col-12 numbers" >
  <div class="text-md-right" >
    <ul style="text-align: right !important; ">
    </ul>
  </div>
  </div>
  </div>
  </div>
  
  <ul class="list-inline " style="text-align: right !important; margin-top: 22px;">
  <li class="list-inline-item p-2 text-primary" style="font-size: 30px !important;"> {{counts?.doneCount}}<span class="p-1" style="font-size: 10px !important;">مكالمه</span></li>
  </ul>
  <div class="progress-showcase mt-0">
  <div class="progress sm-progress-bar">
  <div class="progress-bar bg-primary" role="progressbar" style="width: 100%" aria-valuenow="25"
    aria-valuemin="0" aria-valuemax="100"></div>
  
  </div>
  <div appActionNew>
   <a  (click)="goToDetails('doneCount')"  class="d-block">
  <button  class="btn  btn-primary  btn-lg btn-block">التفاصيل</button>
  </a> 
  </div>
  
  </div>
  </div>
  </div>




  <div class="col-xl-3 col-sm-6 box-col-6">
    <div class="card ecommerce-widget" style="height: 250px;">
    <div class="card-body support-ticket-font">
      <div class="row">
        <div class="col-12">
          <div   style="text-align: right !important;"><h3 class="text-primary">مكالمات لم تتم متابعتها</h3></div>
          <br>
        <br>
          </div>
      </div>
    <div class="row">
    <div class="col-12 numbers" >
    <div class="text-md-right" >
      <ul style="text-align: right !important; ">
      </ul>
    </div>
    </div>
    </div>
    </div>
    
    <ul class="list-inline " style="text-align: right !important; margin-top: 22px;">
    <li class="list-inline-item p-2 text-primary" style="font-size: 30px !important;"> {{counts?.notDoneCount}}<span class="p-1" style="font-size: 10px !important;">مكالمه</span></li>
    </ul>
    <div class="progress-showcase mt-0">
    <div class="progress sm-progress-bar">
    <div class="progress-bar bg-primary" role="progressbar" style="width: 100%" aria-valuenow="25"
      aria-valuemin="0" aria-valuemax="100"></div>
    
    </div>
    <div appActionNew>
     <a  (click)="goToDetails('notDoneCount')" class="d-block">
    <button  class="btn  btn-primary  btn-lg btn-block">التفاصيل</button>
    </a> 
    </div>
    
    </div>
    </div>
    </div>
</div>
</div>