
export class CallClient {
    CallReasonId: number;
    ClientTypeId: number;
}
export class CompanyType{
    companytype :number;  
}



