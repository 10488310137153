export class CollectorReports {
    creationDateFrom:string = ''
	creationDateTo:string = ''
	collectionDateTo:string = ''
	collectionDateFrom:string = ''
	donationStatus:number=null
	donationStatusName:string= ''
	donationTypeId:number=null
	donationTypeName:string= ''
	sectorId:number= null
	sectorName:string= ''
	sectorLocationId:number=null
	sectorLocationName:string= ''
	sectorTypeId:number= null
	sectorTypeName:string= ''
}
