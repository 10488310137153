<app-breadcrumb
[title]="'قائمة المسئولين'"
[items]="['Tables', 'Bootstrap Tables']"
[active_item]="'Basic'"
></app-breadcrumb>
<div class="container-fluid">
<div class="row">
<div class="col-sm-12">
<div class="card">
<div class="card-header px-0" style="padding-bottom: 5px">
<div class="row m-0">
<div class="col-12 col-md-9">
<h5 class="py-2">المسئولين</h5>
<span style="font-size: 14px"> إعدادت مسئولى النظام </span>
</div>
<div class="col-12 col-md-3 d-flex justify-content-end align-items-center p-0">
<div appActionNew class="addnew-btn" (click)="NavigateToInsert()">
<i class="fa fa-plus"></i> <span class="mt-1">جديد</span>
<span class="tooltiptext"> إضافة مسئول جديد</span>
</div>
</div>
</div>
</div>
<div class="row m-10">
  <div class="col-3"> 
    <input 
      class="form-control" (keyup.enter)="GetEmployee()" [(ngModel)]="EmpName" placeholder="بحث">
    </div>
  <div class="col-3"> 
    <ng-select
    name=""
    id=""
    class="js-example-basic-single"
    required="required"
    [(ngModel)]="OfficeId"
    [items]="Offices"
    bindLabel="title"
    bindValue="id"
    >
    </ng-select>
  
  </div>
 
    <div class="col-3"> 
      <ng-select
      name=""
      id=""
      class="js-example-basic-single"
      [(ngModel)]="ActiveId"
      [items]="Active"
      bindLabel="name"
      bindValue="id"
      >
    </ng-select>
    </div>
  <div class="col-3">
<button class="btn btn-primary btn-h"  (click)="GetEmployee()">بحث</button>

  </div>

</div>

<div class="table-responsive">
<table class="table table-striped" style="width: 100%">
<thead>
<tr class="bg-dark">
<th scope="col" class="font-style-header">#</th>
<th scope="col" class="font-style-header">أسم الموظف</th>
<th scope="col" class="font-style-header">أسم المستخدم</th>
<th scope="col" class="font-style-header">رقم الموبايل</th>
<th scope="col" class="font-style-header">الرقم القومى</th>
<th scope="col" class="font-style-header">المكتب</th>
<th scope="col" class="font-style-header">الحالة</th>
<!-- <th scope="col" class="font-style-header"   >نشط</th> -->
<th scope="col" class="font-style-header"></th>
</tr>
</thead>
<tbody>
<tr *ngFor="let item of Employee_List; let i = index;">
<td scope="row" class="font-style">{{ i + 1 }}</td>
<td class="font-style">{{ item.name }}</td>
<td class="font-style">{{ item.userName }}</td>
<td class="font-style" style="text-align: center;">{{item.mobile}}
  <br>
  <div ngbDropdown class="social-media" style="padding: 5px;">
    <a ngbDropdownToggle (click)="$event.stopPropagation()"><i class="fa fa-phone" style="color: green;"></i></a>
    <div ngbDropdownMenu aria-labelledby="dropdownConfig" >
      <a  (click)="$event.stopPropagation()"[href]="'tel:' + item.mobile" ngbDropdownItem>{{item.mobile}}</a>
          <ng-container  *ngFor="let mobile of item.mobiles">
            <a (click)="$event.stopPropagation()" [href]="'tel:' + mobile" ngbDropdownItem>{{mobile}}</a>
          </ng-container>
    </div> 
      <a [href]="'//wa.me/+2' + item.mobile" target="_blank"><i class="fa fa-whatsapp" style="padding: 0 17px; color: #2cc198;" ></i></a>               
   <i class="fa fa-comment" (click)="SendSMSTemplate(item)"></i>

  </div>
</td> 
<td class="font-style">{{ item.nationalId }}</td>
<td class="font-style">{{ item.officeTitle }}</td>
<td class="font-style" *ngIf="item.inactive">غير نشط</td>
<td class="font-style" *ngIf="!item.inactive">نشط</td>

<!-- <td class="font-style"  style="text-align: center;">
  <input
                      class="form-check-input"
                      id="invalidCheck"
                      type="checkbox"
                      [(ngModel)]="donationMType.isNotify"
                      formControlName="isNotify"
                    />
                  </td> -->
<!-- <td    class="font-style">{{item.address}}</td> -->

<td class="font-style" style="text-align: end; width: 8%;">
  <button
appActionEdit
style="color: rgba(35, 118, 241, 0.667); padding: 7px;background-color: transparent;"
(click)="updateEmployee(item.id, item)"
>
<i class="fa fa-pencil"></i>
</button>
<button 
appActionDelete
style="color: red; padding: 7px; background-color: transparent;"
(click)="DeleteEmployee(item.id)"
>
<i class="fa fa-trash"></i>
</button>



</td>
</tr>
</tbody>
</table>
<br>
<div class="d-flex justify-content-center">
  <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
    firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
    (pageChanged)="pageChanged($event)">
  </pagination>
</div>
</div>
</div>
</div>
</div>
</div>
