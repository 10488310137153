import { Component, ElementRef, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EmployeeApiService } from 'src/app/shared/API-Service/employee-api.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-choosecompany-modal',
  templateUrl: './choosecompany-modal.component.html',
  styleUrls: ['./choosecompany-modal.component.css']
})
export class ChoosecompanyModalComponent implements OnInit {
  Clients:any[];
  @Output() modalClosed = new EventEmitter<number>();
  image:any = environment.Server_URL_Image;

  ClientsForFilter: any[]=[];
  RouteData: any;
  constructor(private elementRef: ElementRef, private renderer: Renderer2,private router:Router,private ApiService:EmployeeApiService,public modalRef: BsModalRef) { }
  applyFilter(event){
    this.Clients = this.ClientsForFilter.filter((ele: any) => {
      return ele.companyName.toString().toLowerCase().includes(event.toLowerCase())
  
    });
  } 
   ngOnInit(): void {
     
    const currentNavigation = this.router.getCurrentNavigation();
    if(currentNavigation && currentNavigation.extras && currentNavigation.extras.state){
      this.RouteData=this.router.getCurrentNavigation().extras.state
        }
    
    this.GetClient()

  }
  getClientId(company): void {
    company.RouteData=this.RouteData
    // Code to close the modal
    this.modalClosed.emit(company);
    this.modalRef.hide();
      
  }

  GetClient(){
    this.ApiService.GetClientRelated().subscribe(
      (res:any)=>{
       
        this.Clients = res.data; 
        this.ClientsForFilter=res.data;       
      },
      (err)=>{
          // console.log(err);
          
      }
    )
  }

}
