import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportsApiService } from 'src/app/shared/API-Service/reports-api.service';
import { GovernorateApiService } from 'src/app/shared/API-Service/governorate-api.service';
import { Client_Agent_Report } from 'src/app/shared/Models/Client_Agent_Report';
import { GenericResponse } from 'src/app/shared/Models/GenericResponse';
import { getCities } from 'src/app/shared/Models/getCities';
import { getCitiesWithGovernorate } from 'src/app/shared/Models/getCitiesWithGovernorate';
import { GetGovernorate } from 'src/app/shared/Models/GetGovernorate';
import Swal from 'sweetalert2';
import { IdName } from 'src/app/shared/Models/IdName';
import { SmsTemplateService } from 'src/app/shared/API-Service/sms-template.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SendSMSComponent } from '../../send-sms/send-sms.component';

@Component({
  selector: 'app-client-agent',
  templateUrl: './client-agent.component.html',
  styleUrls: ['./client-agent.component.css']
})
export class ClientAgentComponent implements OnInit {

  //#region  Declare Variables
  Filtered_List: getCities[];
  ClientList: IdName[];
  Client_Agent_Report: Client_Agent_Report[];
  SmsTemplates: any;
  customerid: string;

  //#endregion

  //#region constructor
  constructor(public modalService: NgbModal, private ApiService: ReportsApiService,private router: Router,private SmsTemplateService : SmsTemplateService) {
   //this.GetSMSTemplate();
   }
  //#endregion

  //#region  ng OnInit
  ngOnInit(): void {
    this.Client_Id_Name();
  }
  //#endregion


  SendSMSTemplate(item?){
    localStorage.removeItem("donationId");
    localStorage.removeItem("customerid");
    if(item){

    localStorage.setItem("donationId",item.donationId);
    }
    else{
      localStorage.setItem("customerid",this.customerid);
    }
      //ModalComponent is component name where modal is declare
      const modalRef = this.modalService.open(SendSMSComponent);
      modalRef.result.then((result) => {
  
      }).catch((error) => {
      
      });
  }
  //#region Consume API's

  //#region  Get Client_Agent_Report
  GetClient_Agent_Report(id:number) {
    this.ApiService.Client_Agent_Report(id).subscribe(
      response => {
        this.Client_Agent_Report = response.data;
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }
  //#endregion

  //#region  Get Client_Agent_Report
  Client_Id_Name() {
    this.ApiService.Client_Id_Name().subscribe(
      response => {
        this.ClientList = response.data;
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.error.message,
        })
      }
    )
  }
  //#endregion

  //#endregion

  //#region Selected Governorate
  SelectedChange(event: any) {
    this.GetClient_Agent_Report(event);
  }
  //#endregion


}
