import { Injectable, Injector } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { RiskAuthenticationService } from './shared/API-Service/RiskAuthentication.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService  implements HttpInterceptor{

  constructor(private injector: Injector) { }
  intercept(req:HttpRequest<any>, next:HttpHandler) {
    let authService = this.injector.get(RiskAuthenticationService)
    let tokenizedReq = req.clone({
      setHeaders:{Authorization:`Bearer ${authService.getToken()}`

      }
    });
    return next.handle(tokenizedReq)
  }
}
