import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {PermissionsApiService} from 'src/app/shared/API-Service/Permissions-api.service'
import Swal from 'sweetalert2';

@Component({
  selector: 'app-all-permission',
  templateUrl: './all-permission.component.html',
  styleUrls: ['./all-permission.component.css']
})
export class AllPermissionComponent implements OnInit {
  response: any;
  err: boolean;
  actions: any[]=[];
  new: boolean;
  view: boolean;
  newbutton: boolean;
  viewbutton: boolean;
  deletebutton: boolean;
  editbutton: boolean;
  condition:true;
  page: number ;
  constructor(private PermissionsApiService : PermissionsApiService,private router: Router) {
// let MenuByRoleId=  JSON.parse(localStorage.getItem("MenuByRoleId"));
// this.condition=true;
//  MenuByRoleId=MenuByRoleId.filter(x=>x.url==this.router.url)
// this.actions =MenuByRoleId[0].actions;
// if(this.actions.filter(x=>x.name=="جديد").length>0){
//   this.newbutton=true;
// }
// if(this.actions.filter(x=>x.name=="حدف").length>0){
//   this.deletebutton=true;
// }
// if(this.actions.filter(x=>x.name=="عرض").length>0){
//   this.viewbutton=true;
// }
// if(this.actions.filter(x=>x.name=="تعديل").length>0){
//   this.editbutton=true;
// }
  }

  ngOnInit(): void {
this.GetAllPermissions();
  }
  GetAllPermissions(){
    var res;
    this.PermissionsApiService.GetPermissions().subscribe({
      next: (data) => {
        res = data;
        if (res.messageCode == 200) {
          this.err = false;
          this.response=res.data
        } else {
          this.err = true;
        }
      },
      error: (error) => {
        this.err = true;
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: error.error.message,
        })
      },
    });
  }
  Update(item){
  this.router.navigate(['content/admin/updatePermissions/',item.id]);
}

getPage(page){
  var res
  let newLocl =this;
  const skipCount= 10 * (page - 1)
  const data={
    "maxResultCount": 10,
    "skipCount":skipCount
  }
  this.PermissionsApiService.GetPermissions().subscribe({
    next: (data) => {
      res = data;
      if (res.messageCode == 200) {
        this.err = false;
        this.response=res.data
      } else {
        this.err = true;
      }
    },
    error: (error) => {
      this.err = true;
      Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: error.error.message,
      })
    },
  });
  // newLocl._ShipmentService.Get(data).subscribe((res)=>{
  //   this.response=res.data;
  //   this.totalItems = this.response.totalCount;
  //   this.response=this.response.items;
  // })
}


Delete(id){
  Swal.fire({
    title: " تحذير !",
    text: "هل انت متأكد من حذف هذا العنصر ؟ ",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: '#7364f6',
    cancelButtonColor: "#d33",
    confirmButtonText: "حذف",
    cancelButtonText: "رجوع",
  }).then((result) => {
    if (result.isConfirmed) {
      var res;
  this.PermissionsApiService.DeletePermissions(id).subscribe({
    next: (data) => {
      res = data;
      if (res.messageCode == 200) {
        this.err = false;
        Swal.fire({
          icon: "success",
          title: "تم حذف الصلاحية بنجاح",
          showConfirmButton: false,
          timer: 1500,
        });
        this.GetAllPermissions();
      } else {
        this.err = true;
      }
    },
    error: (error) => {
      this.err = true;
      Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: error.error.message,
      })
    },
  });
    } else {
      // Swal.fire(
      //   'Your appointment still active ',
      // );
    }
  });
  
}
}
