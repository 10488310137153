import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerApiService } from 'src/app/shared/API-Service/customer-api.service';
import { EmployeeApiService } from 'src/app/shared/API-Service/employee-api.service';
import { environment } from 'src/environments/environment.prod';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.css']
})
export class AgentComponent implements OnInit {
  regex :any =/^[0-9]/

  constructor( private ApiService:EmployeeApiService,
              private customerApiService:CustomerApiService,
              private router:Router) {    this.imgURL = "../../../assets/images/statics/personAvatar.png";
            }
  imgURL:string ;
  Clients:any[];
  MobileRes:any;
  passdata:any;
   image:any = environment.Server_URL_Image
          
  ngOnInit(): void {
    localStorage.removeItem("Risk_Customer_Data");
    // this.GetClientRelated(localStorage.getItem('RiskAuthorization'));
    this.GetClientRelated()
  }

  getClientData(companyId:number){
    (async () => {

      // const { value: fruit } = 
      await Swal.fire({
        title: 'أدخل رقم الموبايل',
        input: 'text',
        inputAttributes: {
         
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'تحقق',
        cancelButtonText: 'لا',
        showLoaderOnConfirm: true,
        
        inputPlaceholder: '010..',
        inputValidator: (value) => {
          return new Promise((resolve) => {
          const phone  = this.regex.test(value)
            this.passdata=value
            if (phone === true) {

                  
        localStorage.removeItem("dataCall")
         localStorage.removeItem("apeersearch")
          this.router.navigate(["/content/agent/DisplayData/"+companyId,this.passdata]);
        //this.customerApiService.GetCustomerByphone(this.passdata).subscribe((res:any)=>{
        
         // if (res.success == true && res.data != null) {
        //     this.customerApiService.CustomerData = res["data"]
             
        // //    localStorage.setItem("Risk_Customer_Data",JSON.stringify( res["data"]));
        //     localStorage.setItem("Risk_CallDetals",JSON.stringify(res["data"]["customerCalls"]));
         
        //     localStorage.setItem("dataCall",JSON.stringify( res["data"]["id"]))
            
           //}
         
       // })
              resolve(null)
            } else {
              resolve('يجب عليك اتدخال الرقم فقطs :)')
            }
          })
        }
      })
      
      // if (fruit) {
      //   Swal.fire(`You selected: ${fruit}`)
      // }
      
      })()
  }
  GetClientRelated(){
    this.ApiService.GetClientRelated().subscribe(
      (res:any)=>{
       
        this.Clients = res.data;        
      },
      (err)=>{
          // console.log(err);
          
      }
    )
  }

}
