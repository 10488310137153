import { Component, OnInit } from '@angular/core';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/internal/operators/map';
import {CommentServicesService} from '../../../shared/API-Service/comment-services.service'
@Component({
  selector: 'app-comment-modal',
  templateUrl: './comment-modal.component.html',
  styleUrls: ['./comment-modal.component.css']
})
export class CommentModalComponent implements OnInit {
  closeResult = '';
  getDonation:any
  donationId: any;
  AllComments: any[]=[]
  form = {
    comment: "",
  };
  AllCommentsfilter: any;
  currentUser: any;
  constructor(private modalService: NgbModal,private CommentServicesService :CommentServicesService) {
    this.currentUser=JSON.parse(localStorage.getItem("RiskCurrentUser"))
    this.donationId=localStorage.getItem("ModalData")
this.GetDonationComment(this.donationId);
   }
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  
  }
GetDonationComment(donationId){
  this.CommentServicesService.GetDonationComments(donationId).subscribe((res:any)=>{
    this.AllComments=res.data.reverse();
    this.AllComments=[...this.AllComments];
    this.AllCommentsfilter=res.data.reverse();
    
  })
}

applyFilter(event) {
  this.AllComments = this.AllCommentsfilter.filter((ele: any) => {
    return ele.donationId.toString().toLowerCase().includes(event.toLowerCase())
    || ele.createdByName.toLowerCase().includes(event.toLowerCase())
    || ele.creationDate.toLowerCase().includes(event.toLowerCase())
    || ele.comment.toLowerCase().includes(event.toLowerCase())
  });
}

InsertComment(){
  
let data=  {
    "comment": this.form.comment,
    "donationId": this.donationId
  }
  this.CommentServicesService.InsertDonationComment(data).subscribe(res=>{
    const comment={
      createdByName:this.currentUser.name,
      creationDate:new Date().toISOString(),
      comment:data.comment,
    }
    this.AllComments.unshift(comment);
    this.AllComments=[...this.AllComments];
  //  this.GetDonationComment(this.donationId);
  })
  this.form.comment="";
 
}
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnInit(): void {
  }

}
