export class SourcemarketCustomers {
    sourceOfMarketingId:number=null
    callReasonId:number=null
    governoratId:number=null
    cityId:number=null
    callReasonName:string=''
    cityName:string=''
    governoratName:string=''
    sourceOfMarketingName:string=''
    reportCreatedBy:string=''
}
