<app-breadcrumb [title]="'تقارير الايصالات'" [items]="['Tables', 'Bootstrap Tables']" [active_item]="'Basic'">
</app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header px-0 " style="padding-bottom: 5px;">
                    <div class="row m-0 mb-2">
                        <div class="col-4 col-md-6">
                            <label for="validationCustom02"> اسم الشركة</label>
                            <br>
                            <label *ngIf="currentUser.roleId==10">{{currentUser.name}}</label>
                      <ng-select *ngIf="currentUser.roleId!=10"
                      class="js-example-basic-single col-sm-12 job-select2 form-group"
                        required=""
                        aria-label="Default select example"
                        (change)="SelectCompany($event?.id)"
                        [items]="Companies"
                        bindLabel="name"
                        bindValue="id"
                         >
                      
                      </ng-select>
                          </div>
                        <div class="col-12 col-md-6">
                            <label for=""> الدفتر</label>
                            <div class="form-group">

                            <ng-select
                            class="js-example-basic-single"
                            aria-label="Default select example"
                            [(ngModel)]="ReportsInfo.bookIds"
                            (change)="selectbook($event?.id)"
                            [items]="ReceiptBooks"
                            bindLabel="bookNumber"
                            bindValue="id"
                            >
                          </ng-select>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <label for=""> حالة الايصال</label>
                            <div class="form-group">

                            <ng-select
                            class="js-example-basic-single"
                            aria-label="Default select example"
                            [(ngModel)]="ReportsInfo.receiptStatusId"
                            [items]="ReceiptStatus"
                            (change)="selectstatus($event.id)"
                            bindLabel="status"
                            bindValue="id"
                            >
                          </ng-select>
                            </div>
                        </div>
                        <div class="col-12 col-md-12">
                    <div class="d-flex justify-content-end align-items-center buttom-text">
                      <button class="addnew-btn" type="submit" (click)="Viewreports()">عرض التقرير</button>
                    </div>
                </div>
                </div>
    </div>
</div>
        </div>
    </div>
</div>